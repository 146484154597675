import i18n from 'i18next';

export const getTranslationKeyForFieldHint = (hint: string) => {
  const enRes = i18n.getResourceBundle('en', '');
  if (!enRes || !enRes.fields) {
    return hint;
  }
  const fieldTranslationKey = Object.keys(enRes.fields)
    .find(key => enRes.fields[key]?.toLowerCase() === hint?.toLowerCase());

  return fieldTranslationKey ? 'fields.' + fieldTranslationKey : hint;
}

export const getTranslationKeyForFieldsGroup = (name: string) => {
  const enRes = i18n.getResourceBundle('en', '');
  if (!enRes || !enRes.field_groups) {
    return name;
  }
  const groupTranslationKey = Object.keys(enRes.field_groups)
    .find(key => enRes.field_groups[key]?.toLowerCase() === name?.toLowerCase());

  return groupTranslationKey ? 'field_groups.' + groupTranslationKey : name;
}

export const getTranslationKeyForButtonLabel = (label: string, processSysName: string) => {
  const enRes = i18n.getResourceBundle('en', '');
  if (!enRes || !enRes?.buttons) {
    return label;
  }

  let processButtonsTranslations = {};
  const processTranslationKey = `constructor-${processSysName}`;
  if (Object.keys(enRes).includes(processTranslationKey)) {
    processButtonsTranslations = Object.keys(enRes[processTranslationKey])
      .reduce((acc, key) =>
          key.startsWith('buttons')
          ? { ...acc, [key]: enRes[processTranslationKey][key] }
          : acc
        , {});
  }

  const processSpecificLabelTranslationKey = Object.keys(processButtonsTranslations)
    .find(key => processButtonsTranslations[key]?.toLowerCase() === label?.toLowerCase());
  const labelTranslationKey = Object.keys(enRes?.buttons)
    .find(key => enRes?.buttons[key]?.toLowerCase() === label?.toLowerCase());

  return processSpecificLabelTranslationKey
         ? `${processTranslationKey}.${processSpecificLabelTranslationKey}`
         : labelTranslationKey
           ? 'buttons.' + labelTranslationKey
           : label;
}

export const getTranslationKeyForTaskHistory = (label: string) => {
  const enRes = i18n.getResourceBundle('en', '');
  if (!enRes || !enRes.task_history) {
    return label;
  }
  const labelTranslationKey = Object.keys(enRes.task_history)
    .find(key => enRes.task_history[key]?.toLowerCase() === label?.toLowerCase());

  return labelTranslationKey ? 'task_history.' + labelTranslationKey : label;
}

export const getTranslationKeyForTaskHistoryInPast = (label: string) => {
  const enRes = i18n.getResourceBundle('en', '');
  if (!enRes || !enRes.task_history) {
    return label;
  }
  const labelTranslationKey = Object.keys(enRes.task_history)
    .find(key => enRes.task_history[key]?.toLowerCase() === label?.toLowerCase());

  return labelTranslationKey ? 'task_history_past.' + labelTranslationKey : label;
}

export const getTranslationKeyForTaskStatus = (label: string) => {
  const enRes = i18n.getResourceBundle('en', '');
  if (!enRes || !enRes.task_statuses) {
    return label;
  }
  const labelTranslationKey = Object.keys(enRes.task_statuses)
    .find(key => enRes.task_statuses[key]?.toLowerCase() === label?.toLowerCase());

  return labelTranslationKey ? 'task_statuses.' + labelTranslationKey : label;
}

export const getTranslationKeyForProcessStartError = (message: string) => {
  const enRes = i18n.getResourceBundle('en', '');
  if (!enRes || !enRes?.process_start_errors) {
    return message;
  }
  const messageTranslationKey = Object.keys(enRes?.process_start_errors)
    .find(key => enRes?.process_start_errors[key]?.toLowerCase() === message?.toLowerCase());

  return messageTranslationKey ? 'process_start_errors.' + messageTranslationKey : message;
}
