import { makeStyles } from '@material-ui/core';

const CARD_WIDTH = 280;

export default makeStyles(({ spacing, palette }) => ({
  wrapper: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '&:not(:first-of-type)': {
      paddingTop: spacing(10),

      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: '50%',

        height: 40,
        borderLeft: '1px dashed #A9A9B8',
      },

      '& > $processStepAddStep': {
        position: 'absolute',
        top: 8,
        left: 'calc(50% - 11px)',
        marginLeft: -0.5,

        display: 'block',
        height: 24,
        width: 24,

        backgroundColor: '#FFFFFF !important',
        border: '1px solid rgba(38, 40, 66, 0.12) !important',
        borderRadius: '50% !important',
        transition: '0.3s background-color ease',

        '&:hover': {
          backgroundColor: '#F5F8FA !important',
        },

        '&:focus': {
          backgroundColor: '#E6ECF1 !important',
        },
      },
    },
  },
  wrapperPreview: {
    '&:not(:first-of-type)': {
      paddingTop: '20px !important',
    }
  },
  wrapperError: {
    zIndex: 10,
    marginBottom: -6,
  },
  wrapperWithoutAddButton: {
    paddingTop: '20px !important',
  },
  processStepAddStep: {

  },
  processStepAddConditionStep: {
    display: 'block',
    height: 24,
    width: 24,
    zIndex: 10,

    backgroundColor: '#FFFFFF !important',
    border: '1px solid rgba(38, 40, 66, 0.12) !important',
    borderRadius: '50% !important',
    transition: '0.3s background-color ease',

    '&:hover': {
      backgroundColor: '#F5F8FA !important',
    },

    '&:focus': {
      backgroundColor: '#E6ECF1 !important',
    },
  },
  processStepAddStepIcon: {
    height: 12,
    width: 12,
    // marginLeft: -0.5,
    // marginTop: -0.5,
  },
  processStepCard: {
    background: '#FFFFFF',
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: 12,
    padding: spacing(4, 5, 4.5, 5),
    width: CARD_WIDTH,
    position: 'relative',
    zIndex: 1,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',

    cursor: 'pointer',
    transition: '0.3s border-color ease, 0.3s box-shadow ease',

    '&:hover': {
      border: '1px solid #007994',
    },
  },
  processStepCardActive: {
    borderColor: '#007994',
    borderWidth: '2px !important',
  },
  processStepCardError: {
    borderColor: '#D6331F',
    borderWidth: 2,

    "&:hover": {
      border: "2px solid #D6331F"
    }
  },
  processStepCardErrorMessage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: spacing(1),
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#D6331F',
    position: 'relative',
    zIndex: 10,
    '& img': {
      marginRight: spacing(1.25),
      height: 13,
      width: 13,
    },
  },
  processStepCardTitle: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#262842',
  },
  processStepCardName: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#262842',
  },
  processStepCardNamePlaceholder: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#8B8C9E'
  },
  processStepCardText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#262842',
  },
  processStepCardBranches: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 4,
    marginTop: 8,
    '& p': {
      marginTop: 2.5,
    },
    '& span': {
      color: '#007994',
    }
  },
  stepConditionIcon: {

  },
  processStepCardTextEmpty: {
    color: '#8B8C9E',
  },
  processStepCardIcon: {
    height: 20,
    width: 20,
    '& > path': {
      fill: '##8B8C9E !important',
    },
  },
  parallelAssigneesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#DEF7FC66',
    borderRadius: 8,
    width: 'calc(100% + 8px)',
    padding: 8,
    marginLeft: -8,
    marginTop: 10,
  },
  parallelAssigneesItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 10,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  parallelAssigneesTitle: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#6D6E85',
    marginBottom: 10,
  },
  processStepAssigneeWrapper: {
    display: 'flex',
  },
  processStepAssigneeCount: {
    color: '#101F2B',
    fontSize: 12,
    fontWeight: 500,
    marginLeft: spacing(2),
  },
  processStepAssigneesTooltipPopper: {
    pointerEvents: 'all',
  },
  processStepAssigneesTooltip: {
    maxHeight: 180,
    padding: 10,
    fontSize: 12,
    fontWeight: 500,
    color: palette.grey[50],
  },
  tooltipContent: {
    height: 160,
    width: 160,
    maxHeight: 160,
    maxWidth: 160,
    overflowY: 'auto',
    '& ul': {
      margin: 0,
      padding: 0,
      paddingLeft: 20,
    },
  },
  topLine: {
    position: 'relative',
    height: 21,
    zIndex: 10,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '50%',

      height: 20,
      borderLeft: '1px dashed #A9A9B8',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 20,
      left: `calc(${CARD_WIDTH}px / 2)`,

      width: `calc(100% - ${CARD_WIDTH}px)`,
      height: 1,
      borderTop: '1px dashed #A9A9B8',
    },
  },
  bottomLine: {
    position: 'relative',
    height: 1,
    zIndex: 10,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: -20,
      left: '50%',

      height: 20,
      width: 20,
      marginLeft: -10,
      zIndex: 1,
      backgroundColor: '#F7F8FC',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: `calc(${CARD_WIDTH}px / 2)`,

      width: `calc(100% - ${CARD_WIDTH}px)`,
      height: 1,
      borderTop: '1px dashed #A9A9B8',
    },
  },
  branchLabel: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    padding: spacing(3, 4),
    color: '#6D6E85',
    backgroundColor: '#F7F8FC',
    zIndex: 1
  },
  leftBranchLabel: {
    position: 'absolute',
    left: 220,
    top: 3,
  },
  rightBranchLabel: {
    position: 'absolute',
    right: 220,
    top: 3,
  },
  conditionCardWrapperWithError: {
    zIndex: 2,
    marginBottom: -6,
  },
  stepCardPlaceholder: {
    width: 400,
    minWidth: 400,
    minHeight: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  branchesWrapper: {
  },
  branchCardsWrapper: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    gap: 48,
    justifyContent: 'center',
    alignItems: 'stretch',
    zIndex: 20,
  },
  branchWrapper: {
    position: 'relative',
    '&:before': {
      content: '""',
      height: 'calc(100%)',
      width: 1,
      position: 'absolute',
      top: 0,
      left: '50%',
      borderLeft: '1px dashed #A9A9B8',
    },
  },
  addBranchStepButtonWrapper: {
    position: 'relative',
    height: 40,
    width: '100%',
  },
  addBranchStepButtonWrapperPreview: {
    height: '20px !important',
  },
  addBranchStepButton: {
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 11px)',
    marginLeft: -0.5,
    zIndex: 1,

    display: 'block',
    height: 24,
    width: 24,

    backgroundColor: '#FFFFFF !important',
    border: '1px solid rgba(38, 40, 66, 0.12) !important',
    borderRadius: '50% !important',
    transition: '0.3s background-color ease',

    '&:hover': {
      backgroundColor: '#F5F8FA !important',
    },

    '&:focus': {
      backgroundColor: '#E6ECF1 !important',
    }
  }
}));
