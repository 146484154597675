import React, { useCallback, useEffect, useMemo } from 'react';

import { Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { setActiveStep, useRequestActiveStep, useSkippedTaskSteps, useTaskState } from 'store/requests';

import { DocumentDetails } from '@dar-dms/utils';
import { useDispatch } from 'react-redux';
import { UserType } from 'types';
import DocumentCard from '../../../../components/DocumentsView/DocumentCard';
import FormattedValues from './FormattedValues';
import UserList from '../../../../components/UserList';
import useStyles from './useStyles';
import useTaskDocuments from '../../../../components/TaskDetails/components/Tabs/DocumentsTab/useTaskDocuments';

interface SummaryStepProps {
  selectedWatchers: UserType[];
  values: { [key: string]: string };
  formMethods: any;
}

export const SummaryStep = ({ values, selectedWatchers, formMethods }: SummaryStepProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeStep } = useRequestActiveStep();
  const { skippedSteps } = useSkippedTaskSteps();

  const { data: bpmTask, documents } = useTaskState();

  const goToPreviousStep = useCallback(() => {
    let previousStep = activeStep - 1;
    while (skippedSteps.includes(previousStep + 1)) {
      previousStep -= 1;
    }
    dispatch(setActiveStep(previousStep));
  }, [dispatch, skippedSteps, activeStep]);

  const {
    visibleDocuments,
    groupedDocuments,
    currentLoadingDocumentId,
    currentDocumentId,
    documentViewMode,
    documentsForSignature,
    signedDocuments,
    changeCurrentDocument,
    setCurrentDocumentId,
    setCurrentLoadingDocumentId,
    openDocumentForSign,
    previewDocument,
    downloadDocument,
    onSignComplete,
    beforeDocumentSign,
    setGoBackToFormAfterClose,
    handleDocumentClose,
  } = useTaskDocuments({ formMethods, goToPreviousStep });

  useEffect(() => {
    if (groupedDocuments?.sign?.length > 0 && currentDocumentId < 0 && groupedDocuments.sign.some((doc) => !doc.isSigned)) {
      openDocumentForSign(groupedDocuments.sign[0].docflowDocumentId);
      setGoBackToFormAfterClose(true);
    }
  }, [groupedDocuments, currentDocumentId]);

  const groupNameOldTranslationKeyPrefix = `constructor-${bpmTask.processSysName}.states.${bpmTask.stepSysName}.fieldGroups.`;
  const groupNameTranslationKeyPrefix = `constructor-${bpmTask.processSysName}.fieldGroups.`;
  const currentStateGroupsKeys = useMemo(() => Object.keys(bpmTask.currentStateGroups), [bpmTask]);
  const filteredCurrentStateGroypsKeys = useMemo(() => currentStateGroupsKeys.filter((groupListKey) => !groupListKey.includes('#hidden')), [
    currentStateGroupsKeys,
  ]);
  return (
    <>
      <Box
        component="article"
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.summaryStepWrapper}
      >
        {filteredCurrentStateGroypsKeys.map((el, index) => (
          <>
            <Box component="section" className={classes.group} key={el}>
              <Typography variant="h5">
                {t(groupNameOldTranslationKeyPrefix + el.toLowerCase().replaceAll(' ', '-'), {
                  defaultValue: t(groupNameTranslationKeyPrefix + el.toLowerCase().replaceAll(' ', '-'), {
                    defaultValue: el,
                  }),
                })}
              </Typography>
              <Box component="table" className={classes.table}>
                {bpmTask.currentStateGroups[el].map((value) => {
                  const { name, sysName, hint, component, componentParams } = value;
                  const params = componentParams ? JSON.parse(componentParams) : {};
                  const hintTranslationKey = `constructor-${bpmTask.processSysName}.states.${
                    bpmTask.stepSysName
                  }.attributes.${sysName.replaceAll('::', '-')}.hint`;
                  const hintTranslationNewFormatKey = `constructor-${bpmTask.processSysName}.attributes.${sysName.replaceAll(
                    '::',
                    '-'
                  )}.hint`;
                  return (
                    (!!hint || component === 'file') &&
                    component !== 'hidden-field' && (
                      <tr>
                        {values && (
                          <>
                            {!!hint && (
                              <td>{t(hintTranslationKey, { defaultValue: t(hintTranslationNewFormatKey, { defaultValue: hint }) })}</td>
                            )}
                            {!params?.groupParams ? (
                              <td>
                                <FormattedValues
                                  value={values[name]}
                                  values={values}
                                  name={name}
                                  hint={hint}
                                  component={component}
                                  params={params}
                                  sysName={sysName}
                                />
                              </td>
                            ) : (
                              <FormattedValues
                                value={values[name]}
                                values={values}
                                name={name}
                                hint={hint}
                                component={component}
                                params={params}
                                sysName={sysName}
                              />
                            )}
                          </>
                        )}
                      </tr>
                    )
                  );
                })}
              </Box>
            </Box>
            {index === filteredCurrentStateGroypsKeys.length - 1 && (
              <Box component="section" className={classes.group}>
                <Typography variant="h5">{t('Watchers.watchers')}</Typography>
                <Box component="table" className={classes.table}>
                  <tr>
                    <td>{t('Watchers.processWatchers')}</td>
                    <td>
                      <UserList readOnly users={selectedWatchers} />
                    </td>
                  </tr>
                </Box>
              </Box>
            )}
          </>
        ))}
      </Box>
      {visibleDocuments.length > 0 && (
        <Box
          component="article"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="flex-start"
          className={classes.summaryStepWrapper}
        >
          <Box component="section" className={classes.group} key={'documents'}>
            <Typography variant="h5">{t('new_request.summary.documents.title')}</Typography>
            <table className={classes.documentsTable}>
              {groupedDocuments?.sign.length > 0 && (
                <>
                  <tr>
                    <td>{t('new_request.summary.documents.signingRequired')}</td>
                  </tr>
                  <tr className={classes.documentTableList}>
                    {groupedDocuments?.sign?.map((document) => (
                      <td>
                        <DocumentCard
                          documentId={document.docflowDocumentId}
                          documentName={document.name}
                          fileType={'pdf'}
                          size={document.size}
                          creationDate={document.dataCreate}
                          stepName={t(`constructor-${bpmTask.processSysName}.actions.${bpmTask.currentAction.sysName}.name`, {
                            defaultValue: bpmTask.currentAction.name,
                          })}
                          needSignature={true}
                          hasSignatures={document.isSigned || document.signings.some((v) => v.status)}
                          isSigned={document?.isSigned}
                          isFullySigned={document.signings.every(
                            (signing) => signing.status || (signing.stepOrder === bpmTask.currentAction.stepperOrder && document.isSigned)
                          )}
                          isPublished={document?.isPublished}
                          isLoading={document.docflowDocumentId === currentLoadingDocumentId}
                          onSign={openDocumentForSign}
                          onPreview={previewDocument}
                          onDownload={downloadDocument}
                        />
                      </td>
                    ))}
                  </tr>
                </>
              )}

              {currentDocumentId >= 0 && (
                <DocumentDetails
                  id={currentDocumentId}
                  isPreview={documentViewMode === 'view'}
                  documentsList={documentsForSignature.includes(currentDocumentId || currentLoadingDocumentId) ? documentsForSignature : []}
                  signedDocumentsList={signedDocuments}
                  handleCurrentDocumentChange={changeCurrentDocument}
                  onSignSuccess={onSignComplete}
                  beforeSign={beforeDocumentSign}
                  onClose={handleDocumentClose}
                />
              )}

              {groupedDocuments?.view.length > 0 && (
                <>
                  <tr>
                    <td>{t('new_request.summary.documents.noSignature')}</td>
                  </tr>
                  <tr className={classes.documentTableList}>
                    {groupedDocuments?.view?.map((document) => (
                      <td>
                        <DocumentCard
                          documentId={document.docflowDocumentId}
                          documentName={document.name}
                          fileType={'pdf'}
                          size={document.size}
                          creationDate={document.dataCreate}
                          stepName={t(`constructor-${bpmTask.processSysName}.actions.${bpmTask.currentAction.sysName}.name`, {
                            defaultValue: bpmTask.currentAction.name,
                          })}
                          needSignature={false}
                          hasSignatures={document.isSigned || document.signings.some((v) => v.status)}
                          isSigned={false}
                          isFullySigned={document.signings.every(
                            (signing) => signing.status || (signing.stepOrder === bpmTask.currentAction.stepperOrder && document.isSigned)
                          )}
                          isLoading={document.docflowDocumentId === currentLoadingDocumentId}
                          isPublished={document?.isPublished}
                          onPreview={previewDocument}
                          onDownload={downloadDocument}
                        />
                      </td>
                    ))}
                  </tr>
                </>
              )}
            </table>
          </Box>
        </Box>
      )}
    </>
  );
};
