import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import {
  useTaskState,
} from 'store/requests';
import {
  useApprovalsWatchList,
  useSelectedReviewedCurrentTask,
} from 'store/approvals';
import {
  readTask,
  readTaskAttachments,
  readTaskComments,
  useCustomModalState,
  useReadStatus,
} from 'store/main';
import { useUserProfile, useUsersRole } from 'hooks';
import {
  getProcessWatchers,
  updateTaskAttachmentsReadStatus,
  updateTaskCommentsReadStatus,
  updateTaskReadStatus,
} from 'api/requests';

import {
  ATTACHMENTS_TAB_NAME,
  INFORMATION_TAB_NAME,
  LINKS_TAB_NAME,
  DOCUMENTS_TAB_NAME
} from '../components/Tabs/FormTab/constants';
import { DataViewTab, BpmTaskExtraTab } from '../types';
import { TaskParametersType } from 'types';

import DocsTabIcon from 'assets/images/icons/docs-tab-icon.svg';
import AttachmentsTabIcon from 'assets/images/icons/attachments-icon-new.svg';
import CommentsTabIcon from 'assets/images/icons/comments-tab-icon.svg';
import LinksTabIcon from 'assets/images/icons/links-tab-icon.svg';
import HistoryTabIcon from 'assets/images/icons/history-tab-icon.svg';
import { COMMENTS_TAB_NAME } from '../components/Tabs/CommentsTab/TaskComments/constants';
import { CombinedStepType, StepType } from '../components/Tabs/HistoryTab/history.types';
import { CHANGE_ASSIGNEE_AVAILABLE_ROUTES } from '../components/TaskDetailsContent';

const TABS: DataViewTab[] = [
  {
    id: 22,
    name: 'comments',
    label: 'Comments',
    icon: CommentsTabIcon,
    enabled: true,
    hasTooltip: true,
  },
  {
    id: 21,
    name: 'attachments',
    label: 'Attachments',
    icon: AttachmentsTabIcon,
    enabled: true,
    hasTooltip: true,
  },
  {
    id: 23,
    name: 'links',
    label: 'Links',
    icon: LinksTabIcon,
    enabled: false,
    hasTooltip: true,
  },
  {
    id: 20,
    name: 'documents',
    label: 'Documents',
    icon: DocsTabIcon,
    enabled: true,
    hasTooltip: true,
  },
  {
    id: 24,
    name: 'history',
    label: 'History',
    icon: HistoryTabIcon,
    enabled: true,
    hasTooltip: true,
  },
];

const useTaskDataView = ({ steps }: { steps?: (StepType & CombinedStepType)[] }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const routeMatch = useRouteMatch();

  const { id: profileId } = useUserProfile();
  const { hasAccessToPromoteRequests, isUserAdmin } = useUsersRole();
  const { data: bpmTask, loading, initialTab, documents } = useTaskState();
  const { open: customModalOpen } = useCustomModalState();
  const readStatuses = useReadStatus();
  const selectedReviewedApprovalCurrentStepId = useSelectedReviewedCurrentTask();

  const scrollbarsRef = useRef(null);

  const [tabsList, setTabsList] = useState<DataViewTab[]>(TABS);
  const [activeTab, setActiveTab] = useState<string>(initialTab || COMMENTS_TAB_NAME);
  const [tabsWithErrors, setTabsWithErrors] = useState<string[]>([]);
  const [errorMessageLines, setErrorMessageLines] = useState<('fields' | 'documents')[]>([]);
  const [isShownPersonalInfo, setIsShownPersonalInfo] = useState(true);

  const [unreadTabs, setUnreadTabs] = useState<string[]>([]);

  const [watchers, setWatchers] = useState([]);
  const isCurrentUserWatcher = useMemo(() => watchers.some(item => item?.id === profileId), [watchers, profileId]);
  const queryParameters = queryString.parse(location.search);

  const updateActiveTab = (name: string) => {
    if (queryParameters.type === COMMENTS_TAB_NAME) {
      setActiveTab(COMMENTS_TAB_NAME);
    } else {
      setActiveTab(name);
    }
  };

  const taskId = useMemo((): string => {
    return queryParameters?.taskId as string;
  }, [queryParameters]);

  useEffect(() => {
    if (bpmTask) {
      getProcessWatchers(bpmTask.processInstanceId).then((data) => {
        setWatchers(data);
      }).catch((error) => {
        console.error(error);
      })
    }
  }, [bpmTask]);

  useEffect(() => {
    if (loading) return;
    if (bpmTask) {
      const extraTabs: DataViewTab[] = bpmTask.taskDetailsTabs.map(
        ({ index, description }: BpmTaskExtraTab) => ({
          id: index,
          name: description.toLowerCase(),
          label: description === 'Information' ? t('task_data_view.tabs.information') : description,
          enabled: true,
          hasTooltip: false,
        }),
      );

      const hasLinksTabFields = Object.keys(bpmTask.currentStateGroups)
        .map((groupKey: string): boolean =>
          bpmTask.currentStateGroups[groupKey].some((field: TaskParametersType) => {
            const componentParams = field?.componentParams
                                    ? JSON.parse(field?.componentParams)
                                    : {};
            return !!componentParams?.showInLinksTab;
          }),
        )
        .some((value: boolean) => value);

      const defaultTabs = [...TABS];

      const linksTabIndex = defaultTabs.findIndex(
        ({ name }: Partial<DataViewTab>) => name === LINKS_TAB_NAME,
      );
      const linksTab = defaultTabs[linksTabIndex];
      linksTab.enabled = hasLinksTabFields;
      defaultTabs[linksTabIndex] = linksTab;

      const sortedExtraTabs = extraTabs.sort((tab1: DataViewTab, tab2: DataViewTab) => tab1.id - tab2.id);

      let activeTabs = [...sortedExtraTabs, ...defaultTabs].filter((tab: DataViewTab) => tab.enabled);

      if (documents.length === 0) {
        activeTabs = activeTabs.filter(tab => tab.name !== 'documents');
      }

      if (isUserAdmin && (CHANGE_ASSIGNEE_AVAILABLE_ROUTES.includes(routeMatch.path))) {
        const isParticipant = steps?.some(step => step.assignee === profileId || step.initiator === profileId) || bpmTask.businessTask.candidateUsers?.includes(profileId);

        if (
          bpmTask?.isInitiator(profileId, hasAccessToPromoteRequests) ||
          bpmTask?.isAssignee(profileId, hasAccessToPromoteRequests) ||
          bpmTask?.isAssigneeCandidate(profileId, hasAccessToPromoteRequests) ||
          isParticipant
        ) {
          setTabsList(activeTabs);
          updateActiveTab('history');
          setIsShownPersonalInfo(true);
        } else {
          setTabsList(TABS.filter(tab => tab.name === 'history'));
          updateActiveTab('history');
          setIsShownPersonalInfo(false);
        }

        return;
      }

      setTabsList(activeTabs);

    }
  }, [bpmTask, isUserAdmin, loading, profileId, setTabsList, steps, documents, t]);

  useEffect(() => {
    if (bpmTask) {
      setUnreadTabs(() => []);
      const documentsForSign = documents?.filter(document => document.signRequiredSteps.includes(bpmTask.currentAction.stepperOrder));
      if (documentsForSign?.some(doc => !doc.isSigned) && bpmTask.isAssignee(profileId, false)) {
        setUnreadTabs(tabs => [...tabs, DOCUMENTS_TAB_NAME]);
      }

      const isInitiator = bpmTask?.initiator === profileId;
      const isAssignee = bpmTask?.isAssignee(profileId);

      const observerTaskUnread = (isCurrentUserWatcher && !!bpmTask?.businessTask?.badges?.observer
        ?.map(userBadges => userBadges?.taskRead)
        ?.find(v => v?.startsWith(profileId) && v?.endsWith('true')));
      const observerCommentUnread = (isCurrentUserWatcher && !!bpmTask?.businessTask?.badges?.observer
        ?.map(userBadges => userBadges?.commentRead)
        ?.find(v => v?.startsWith(profileId) && v?.endsWith('true')));

      if (
        (isInitiator && bpmTask?.businessTask?.badges?.initiator?.commentRead) ||
        (isAssignee && bpmTask?.businessTask?.badges?.assignee?.commentRead) || 
        (observerCommentUnread)) {
          setUnreadTabs(tabs => [...tabs, COMMENTS_TAB_NAME]);
      }
      if (
        (isInitiator && bpmTask?.businessTask?.badges?.initiator?.attachmentRead) ||
        (isAssignee && bpmTask?.businessTask?.badges?.assignee?.attachmentRead)) {
          setUnreadTabs(tabs => [...tabs, ATTACHMENTS_TAB_NAME]);
      }
      if (
        (isInitiator && bpmTask?.businessTask?.badges?.initiator?.taskRead) ||
        (isAssignee && bpmTask?.businessTask?.badges?.assignee?.taskRead) || 
        (observerTaskUnread)) {
          setUnreadTabs(tabs => [...tabs, INFORMATION_TAB_NAME]);
      }
    }
  }, [hasAccessToPromoteRequests, profileId, bpmTask, setUnreadTabs, documents, isCurrentUserWatcher]);

  const setTabCounter = useCallback(
    (tabName: string, value: number | null) => {
      const tabsListCopy = [...tabsList];
      const foundTabIndex = tabsListCopy.findIndex(
        ({ name }: Partial<DataViewTab>) => name === tabName,
      );

      if (foundTabIndex >= 0) {
        const foundTab = tabsListCopy[foundTabIndex];
        foundTab.label = value === null ? '' : `(${value})`;
        tabsListCopy[foundTabIndex] = foundTab;

        setTabsList(tabsListCopy);
      }
    },
    [tabsList, setTabsList],
  );

  const handleTabChange = useCallback((name: string) => {
    if (queryParameters.type) {
      delete queryParameters.type;
      history.replace({
        search: queryString.stringify(queryParameters),
      });
    }
    updateActiveTab(name);
    scrollbarsRef?.current?.scrollToTop();
  }, [activeTab]);

  useEffect(() => {
    return () => {
      setTabsList(TABS);
    };
  }, []);

  const handleTabReadStatusUpdate = async (tab: string) => {
    // hide badge in route
    if (location.pathname === "/approvals/reviewed") return;

    const badges = bpmTask?.businessTask?.badges || {};

    const isAssignee = bpmTask.isAssignee(profileId);
    const shouldUpdateTaskReadStatus = 
      (badges?.assignee?.taskRead && isAssignee) || 
      (badges?.initiator?.taskRead && bpmTask.initiator === profileId) || 
      (bpmTask.isAssigneeCandidate(profileId) && !!badges?.candidateUsers
          ?.map(userBadges => userBadges?.taskRead)
          ?.find(v => v?.startsWith(profileId) && v?.endsWith('true'))) ||
      (isCurrentUserWatcher && !!badges?.observer
        ?.map(userBadges => userBadges?.taskRead)
        ?.find(v => v?.startsWith(profileId) && v?.endsWith('true')));

    const shouldUpdateCommentsReadStatus = 
      (badges?.assignee?.commentRead && isAssignee) || 
      (badges?.initiator?.commentRead && bpmTask.initiator === profileId) || 
      (bpmTask.isAssigneeCandidate(profileId) && !!badges?.candidateUsers
          ?.map(userBadges => userBadges?.commentRead)
          ?.find(v => v?.startsWith(profileId) && v?.endsWith('true'))) ||
      (isCurrentUserWatcher && !!badges?.observer
        ?.map(userBadges => userBadges?.commentRead)
        ?.find(v => v?.startsWith(profileId) && v?.endsWith('true')));

    const shouldUpdateAttachmentsReadStatus = 
      (badges?.assignee?.attachmentRead && isAssignee) || 
      (badges?.initiator?.attachmentRead && bpmTask.initiator === profileId) || 
      (bpmTask.isAssigneeCandidate(profileId) && !!badges?.candidateUsers
          ?.map(userBadges => userBadges?.attachmentRead)
          ?.find(v => v?.startsWith(profileId) && v?.endsWith('true'))) ||
      (isCurrentUserWatcher && !!badges?.observer
        ?.map(userBadges => userBadges?.attachmentRead)
        ?.find(v => v?.startsWith(profileId) && v?.endsWith('true')));

    try {
      if (shouldUpdateTaskReadStatus && !readStatuses.tasks.includes(bpmTask?.taskId || taskId)) {
        await updateTaskReadStatus(bpmTask?.taskId || taskId);
        dispatch(readTask(bpmTask?.taskId || taskId));
      }
      if (selectedReviewedApprovalCurrentStepId && !readStatuses.tasks.includes(selectedReviewedApprovalCurrentStepId)) {
        await updateTaskReadStatus(selectedReviewedApprovalCurrentStepId);
        dispatch(readTask(selectedReviewedApprovalCurrentStepId));
      }
      if (badges?.assignee?.taskRead && !readStatuses.tasks.includes(bpmTask?.taskId || taskId)) {
        await updateTaskReadStatus(bpmTask?.taskId || taskId);
        dispatch(readTask(bpmTask?.taskId || taskId));
      }
    } catch (error) {
      console.log('Error updating task read status');
    }
    if (tab === COMMENTS_TAB_NAME) {
      try {
        if (shouldUpdateCommentsReadStatus && !readStatuses.comments.includes(bpmTask?.taskId || taskId)) {
          await updateTaskCommentsReadStatus(bpmTask?.taskId || taskId);
          dispatch(readTaskComments(bpmTask?.taskId || taskId));
        }
        if (selectedReviewedApprovalCurrentStepId && !readStatuses.comments.includes(selectedReviewedApprovalCurrentStepId)) {
          await updateTaskCommentsReadStatus(selectedReviewedApprovalCurrentStepId);
          dispatch(readTaskComments(selectedReviewedApprovalCurrentStepId));
        }
      } catch (error) {
        console.log('Error updating task comments read status');
      }
    }
    if (tab === ATTACHMENTS_TAB_NAME) {
      try {
        if (shouldUpdateAttachmentsReadStatus && !readStatuses.attachments.includes(bpmTask?.taskId || taskId)) {
          await updateTaskAttachmentsReadStatus(bpmTask?.taskId || taskId);
          dispatch(readTaskAttachments(bpmTask?.taskId || taskId));
        }
        if (selectedReviewedApprovalCurrentStepId && !readStatuses.attachments.includes(selectedReviewedApprovalCurrentStepId)) {
          await updateTaskAttachmentsReadStatus(selectedReviewedApprovalCurrentStepId);
          dispatch(readTaskAttachments(selectedReviewedApprovalCurrentStepId));
        }
      } catch (error) {
        console.log('Error updating task attachments read status');
      }
    }
  };

  useEffect(() => {
    updateActiveTab(initialTab);
    handleTabReadStatusUpdate(initialTab);
  }, [loading, bpmTask]);

  useEffect(() => {
    if (activeTab !== initialTab) {
      handleTabReadStatusUpdate(activeTab);
    }
  }, [activeTab, taskId, bpmTask]);

  useEffect(() => {
    if (queryParameters.type === COMMENTS_TAB_NAME) {
      setActiveTab(COMMENTS_TAB_NAME);
    }
  }, [queryParameters])

  return {
    watchers,
    customModalOpen,
    tabsList,
    tabsWithErrors,
    unreadTabs,
    handleTabChange,
    activeTab,
    setActiveTab,
    setTabCounter,
    setTabsWithErrors,
    scrollbarsRef,
    isShownPersonalInfo,
    errorMessageLines,
    setErrorMessageLines,
  };
};

export default useTaskDataView;
