import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  useSubmitSnackbarState,
  setSubmitSnackbarParams,
  clearSelectedRequest,
  clearTaskData,
  getMyRequestsAction,
  useCreateAfterSubmit,
  useProcessDialogState,
  openCreateDialogAction,
  setCreateRequestAction,
  setTaskLoading,
  useRequestListState,
  showDarkOverlay,
  setDraftsListRefreshStatus,
} from 'store/requests';
import {
  clearSelectedApproval,
  getApprovalsAction,
  useApprovalsListState, clearSelectedReviewedCurrentTask,
} from 'store/approvals';
import { setCustomModalParams } from 'store/main';
import { useFiltersState } from 'store/search';
import { useRequestParams } from 'hooks';

import {
  APPROVE_ASSIGN,
  REASSIGN,
  TAKE_ON_A_TASK,
} from 'pages/Task/TaskForm/constants';

import CloseIcon from 'assets/images/icons/close-icon.svg';
import useStyles from './useStyles';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={0} variant="filled" {...props} />;
}

type Props = {
  startNewProcess?: () => void;
};

export const SubmitSnackbar = ({ startNewProcess }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open, action } = useSubmitSnackbarState();
  const createNewTask = useCreateAfterSubmit();
  const dialogOpen = useProcessDialogState();
  const [message, setMessage] = useState('');
  const { pathname } = useLocation();

  const oldOpenState = useRef(open);
  const location = useLocation();

  const filtersParams = useFiltersState();

  const { pageSize: requestsPageSize } = useRequestListState();
  const {
    pageSize: approvalsPageSize
  } = useApprovalsListState();

  const isRequestsCompleted = useMemo(() =>
      pathname.includes('requests') && pathname.includes('completed'),
    [pathname]);

  const {
    getRequestParameters: getRequestParametersForRequests,
    urlParams: urlParamsRequests,
  } = useRequestParams({
    completed: isRequestsCompleted,
    pageSize: requestsPageSize,
    currentPage: 1,
  });

  const {
    getRequestParameters: getRequestParametersForApprovals,
    urlParams: urlParamsApprovals,
  } = useRequestParams({
    completed: false,
    currentPage: 1,
    pageSize: approvalsPageSize,
    type: 'approvals',
  });

  const actionMap = useMemo(
    () => ({
      Create: { message:  t('success_messages.create') },
      Submit: { message: t('success_messages.submit') },
      'Send to Paragraph EDO': {
        message:  t('success_messages.send_to_paragraph_edo'),
      },
      Done: { message: t('success_messages.done') },
      Vote: { message:  t('success_messages.vote') },
      [APPROVE_ASSIGN]: {
        message: t('success_messages.approve'),
      },
      [REASSIGN]: {
        message: t('success_messages.reassign'),
      },
      [TAKE_ON_A_TASK]: {
        message:  t('success_messages.take_on_task'),
      },
      Approve: {
        message: t('success_messages.approve'),
      },
      'Mass Approve': {
        message:  t('success_messages.mass_approve'),
      },
      Sign: {
        message:  t('success_messages.sign'),
      },
      Rework: {
        message: t('success_messages.rework'),
      },
      'Mass Rework': {
        message:  t('success_messages.mass_rework'),
      },
      Reject: { message: t('success_messages.reject')},
      'Mass Reject': { message:  t('success_messages.mass_reject') },
      Stop: { message:  t('success_messages.stop') },
      Restart: { message:  t('success_messages.restart') },
      Canceled: { message:  t('success_messages.canceled') },
      default: { message: t('success_messages.delete') },
    }),
    [],
  );

  const handleClose = () => {
    dispatch(
      setSubmitSnackbarParams({
        open: false,
      }),
    );
  };

  // fix requests/approvals list not updated when page changed before snackbar hiding
  useEffect(() => {
    oldOpenState.current = false;
  }, [pathname]);

  useEffect(() => {
    setMessage(actionMap[action]?.message || actionMap.default.message);
  }, [action, actionMap, filtersParams]);

  useEffect(() => {
    if (open && oldOpenState.current !== open) {
      dispatch(showDarkOverlay(false));
      dispatch(setCustomModalParams({ open: false }));
      dispatch(clearSelectedRequest());
      dispatch(clearSelectedApproval());
      dispatch(clearSelectedReviewedCurrentTask());
      dispatch(clearTaskData());

      if (location.pathname === "/requests/active"){
        const myRequestParametersTemp = getRequestParametersForRequests(
          urlParamsRequests,
        );
        const myRequestParameters = {
          ...myRequestParametersTemp,
          ...filtersParams,
          archive: false,
          completed: false,
          pending: true,
          taskCompleted: false,
        };
        dispatch(getMyRequestsAction(myRequestParameters));
      }

      if (location.pathname === "/approvals/active"){
        setTimeout(() => {
          const approvalsParametersTemp = getRequestParametersForApprovals(
            urlParamsApprovals,
          );

          const approvalsParameters = {
            ...approvalsParametersTemp, ...{
              ...filtersParams,
              pagination: {
                ...approvalsParametersTemp.pagination,
                ...(filtersParams?.pagination || {}),
                size: approvalsPageSize,
                ...(filtersParams?.pagination
                    ? { page: filtersParams?.pagination?.page }
                    : {}),

              },
            },
          };

          dispatch(getApprovalsAction({ params: approvalsParameters }));
        })
      }

      dispatch(setDraftsListRefreshStatus(true));

      if (createNewTask && action.includes('Submit')) {
        if (startNewProcess) {
          dispatch(setTaskLoading());
          startNewProcess();
        }
      } else if (!createNewTask && dialogOpen) {
        dispatch(openCreateDialogAction(false));
        dispatch(setCreateRequestAction(null));
      }
    }
  }, [open]);

  useEffect(() => {
    return () => {
      handleClose()
    }
  }, [])


  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={2000}
      onClose={handleClose}
    >
      <Alert
        className={classes.root}
        severity={'success'}
        icon={false}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <img className={classes.closeIcon} src={CloseIcon} alt="close"/>
          </IconButton>
        }
      >
        {open ? message : null}
      </Alert>
    </Snackbar>
  );
};
