import styled from 'styled-components';
import {theme} from '@dartech/dms-ui';

export const listItemHeight = '44px';

const Attachments = styled.div`
  .AttachmentsWrapper {
    height: auto;
    // min-width: 490px;
    /* border: 1px dashed #b7c3cc; */
    border-radius: 4px;
    box-sizing: content-box;
  }
  .AttachmentsBig {
    border: 2px dashed rgba(38, 40, 66, 0.12);

    &:hover {
      border: 2px solid ${theme.palette.primary[200]};
    }
  }
  .AttachmentsWrapperWide {
    width: 608px;
  }
  .AttachmentsWrapperEmpty {
    height: 240px;
    background-color: ${theme.palette.grey[100]};
  }
  .AttachmentsWithError {
    .AttachmentUploadCompact{
      border: 2px ${theme.palette.error[600]} solid;
    }

    &.AttachmentsBig {
      border: 2px ${theme.palette.error[600]} solid;
    }
  }
  .AttachmentsCompact {
    display: flex;
    flex-direction: column-reverse;
    border: none;

    &:hover {
      border: none;
    }
  }
  .AttachmentsError {
    margin-top: 4px;
    font-size: 12px;
    text-align: left;
    color: ${theme.palette.error[600]};
    margin-bottom: -8px;
  }
  .AttachmentsCompactDashed {
    width: auto;
  }
  .CompactDashedAttachmentsError {
    margin-top: 4px;
    font-size: 12px;
    text-align: left;
    color: ${theme.palette.error[600]};
    margin-bottom: -8px;
  }
`;

export const Styled = {
  Attachments
};
