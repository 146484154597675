import { Dispatch, SetStateAction, ReactElement } from "react";

export enum StatusType  {
  PENDING = "pending",
  RESOLVED = "resolved",
  REJECTED = "rejected"
}

export type TemplateDocumentStatus = StatusType.PENDING | StatusType.RESOLVED | StatusType.REJECTED;

export type TemplateViewerHookProps = {
  displayStep?: number;
  params: {
    fileId: string;
    attachmentAttributeSysName: string;
    fileNameTemplate: string;
    storage?: string;
    alwaysActiveButtons?: string;
  },
  setButtonDisabled: (value: boolean) => void;
  setAlwaysActiveButtons: Dispatch<SetStateAction<any[]>>;
}

export type TemplateViewerProps = TemplateViewerHookProps & {
  name: string;
  label: string;
  isActive: boolean;
  displayStep?: number;
}

export type TemplateViewerHookResponse = {
  isUploadLoading: boolean;
  uploadErrorMessage: string;
  uploadHintMessage: string;
  isAttachmentExists: boolean;
  errorMessage: string | null;
  templateDocumentURL: string | null;
  templateDocumentStatus: TemplateDocumentStatus,
  uploadFileToGoogleDrive: () => Promise<void>
}

export type RequestParameters = {
  format: string;
  fileId?: string;
  parameters: {
    [key: string]: string
  }
}

export type FormValues = {
  [key: string]: string;
}

export type Content = {
  [key: string]: () => ReactElement
};
