import { makeStyles } from '@material-ui/core';

type Props = {
  isFilterOn: boolean
}

export default makeStyles(() => ({
  root: ({isFilterOn}: Props) => {
    return {
      display: "flex",
      flexDirection: isFilterOn ? "row" : "column",
      flexGrow: 1,
      justifyContent: isFilterOn ?  "center" : null,
      alignItems: isFilterOn ? "center" : null,

      background: isFilterOn ? "#fff" : "#F7F8FC",
    }
  },
  templateProcessesContentTitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.2,
    color: '#6D6E85',
    marginTop: 16
  },
  templateProcessesContentSubtitle: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#8A96A1',
    marginTop: 4
  },
  emptyProcessesTitle: {
    marginTop: 40,

    textAlign: "center",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "24px",

    color: "#262842"
  },
  emptyProcessesDescription: {
    marginTop: 4,

    textAlign: "center",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "18px"
  },
  createFromScratchCardWrapper: {
    paddingLeft: 24,
    marginTop: 8
  },
  templatesContent: {
    padding: "40px 24px 24px 24px",
  },
  createFromTemplate: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "24px",

    color: "#262842"
  },
  showAllTemplates: {
    color: "#007994",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    marginLeft: 12,
    cursor: "pointer"
  },
  templateCards: {
    marginTop: 20,
    display: 'grid',
    gap: 20,
    // gridTemplateColumns: "repeat(auto-fill,minmax(250px, 290px))"
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))"

  },
  categoryTitle: {
    color: "#6D6E85",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    marginTop: 24
  },
}));
