import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Tab, Tabs, Typography } from "@mui/material";
import { HISTORY_TAB_NAME, INFORMATION_TAB_NAME } from "components/TaskDetails/components/Tabs/FormTab/constants";
import { useTranslation } from "react-i18next";
import { FieldsListCard } from "../../FieldsListCard";
import {
  FIELDS,
  NOT_REQUIRED_EDITABLE_FIELD_SUFFIX, READONLY_FIELD_SUFFIX,
} from '../../ProcessSetupSidePanel/ProcessSetupSidePanel.constants';
import { TemplatesTableRow } from "pages/Templates/components/TemplatesTable/TemplatesTable.types";
import { createProcessFromTemplate } from "api/requests";
import { NotificationManager } from 'react-notifications';
import { useHistory } from "react-router-dom";
import { ProcessStepList } from "../../ProcessStepList";
import { useSetupTemplateProcessDialog } from "../../SetupTemplateProcessesDialog/hooks/useSetupTemplateProcessDialog";
import InfoIcon from 'assets/images/icons/vehicle_car.svg'
import { Language, ProcessStep } from "pages/TemplateProcesses/TemplateProcesses.types";
import classNames from 'classnames';
import { useDraggable as useDraggableScroll } from 'react-use-draggable-scroll';
import useStyles from '../useStyles'

type Props = {
  data: TemplatesTableRow,
  handleClose: (hasToCloseTemplateListDialog?: boolean) => void;
  setIsLoading: (v: boolean) => void;
}

export const TemplateInfo = ({data, handleClose, setIsLoading}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const gridPathRef = useRef(null);
  const { events: draggableScrollEvents } = useDraggableScroll(gridPathRef);

  const [activeTab, setActiveTab] = useState(INFORMATION_TAB_NAME);

  const createProcess = async () => {
    setIsLoading(true)

    try {
      const response = await createProcessFromTemplate(data);
      handleClose(true);
      history.replace(`/template-processes/${response.processSysName}?useTemplate=true`, undefined);

    } catch (error) {
      NotificationManager.error(t(error?.message || 'errors.somethingIsWrong'), `${t('customProcesses.notifications.error')}!`);
    } finally {
      setIsLoading(false);
    }
  }

  const {
    allProcessStepsFields,
    initiateProcessSteps,
  } = useSetupTemplateProcessDialog({
    isOpen: false,
    initialValues: data,
    publishedVersionValues: data,
    templateSettings: null,
    haveTemplateSettingsChanged: false,
    onClose: () => {},
    onFormSubmit: () => {},
    onBackButtonClick: () => {},
    setTemplateSettings: () => {},
    needResetProcessForm: false,
    setNeedResetProcessForm: null,
    handleAutostartActionsClick: null,
    handlePublishedProcessesLimitDialogOpen: null,
  });

  const attributes = useMemo(() => {
    const attrs = []
    data.steps.forEach(step => {
      step.attributes.forEach(attr => {
        const isExists = attrs.some(a => a.name === attr.name)
        if(!isExists) {
          const attributeParams = data.generateAttributes.find(a => a.name === attr.name);
          attrs.push({
            ...attr,
            sysName: attr.sysName.replace(READONLY_FIELD_SUFFIX, '').replace(NOT_REQUIRED_EDITABLE_FIELD_SUFFIX, ''),
            type: attributeParams?.type || '',
          })
        }
      })
    })
    return attrs
  }, [data])

  const {
    conditions: previewConditions,
    parallelStepsGroups: previewParallelStepsGroups,
    processSteps: previewProcessSteps,
  } = useMemo(() =>
      initiateProcessSteps(data)
    , []);

  const templateInfo = useMemo<{
    description: string,
  }>(() => {
    if (!data) return null;
    let info = data.languages[0]
    const foundLanguage = data.languages.find(attr => attr.language === i18n.language)

    if (foundLanguage){
      info = foundLanguage;
    }

    return info

  }, [data])

  return  <>
    <Tabs
      value={activeTab}
      className={classes.tabs}
      onChange={(_, value) => {
        setActiveTab(value);
      }}
    >
      <Tab
        className={classes.tab}
        value={INFORMATION_TAB_NAME}
        label={t('task_data_view.tabs.information')}
      />

      <Tab
        className={classes.tab}
        value={HISTORY_TAB_NAME}
        label={t('task_data_view.tabs.flowPreview')}
      />
    </Tabs>

    <div
      className={classes.content}
      {...draggableScrollEvents}
      ref={gridPathRef}>
      {activeTab === INFORMATION_TAB_NAME && <div className={classes.tabContent}>

        <Typography component="h3">
          <img src={InfoIcon} alt=""/>
          {t('templates.templateDescription')}
        </Typography>

        <Typography component="h4">
          {t('new_request.groups.general.title')}
        </Typography>

        <Typography component="p">
          {templateInfo.description}
        </Typography>

        <Typography component="h4">
          {t('customProcesses.processForm.customFieldsTitle')}
        </Typography>

        <div className={classes.fields}>
          {attributes.map(attr => {
            const foundField = FIELDS.find(field => field.key === attr.sysName);

            if (foundField) {
              let name = attr.attributeLanguageDTO[0].name;
              const foundTranslation = attr.attributeLanguageDTO?.find(attr => attr.language === i18n.language);
              if (foundTranslation) {
                name = foundTranslation.hint
              } else {
                name = attr.attributeLanguageDTO?.[0]?.hint
              }

              return <FieldsListCard
                field={foundField}
                rowType
                name={name}
                type={attr?.type}
              />;
            }
          })}
        </div>
      </div>}

      {activeTab === HISTORY_TAB_NAME &&
        <div className={classNames(classes.tabContent, classes.historyTab)}>
          <ProcessStepList
            isTemplatePreview
            isReadonlyView
            isEditDisabled
            processIntegrations={data?.integration?.split(';').filter(Boolean) || []}
            currentLanguage={i18n.language as Language}
            processSteps={previewProcessSteps as unknown as ProcessStep[]}
            conditions={previewConditions}
            parallelBranchesGroups={previewParallelStepsGroups}
            documents={[]}
            handleParallelStepsGroupSelect={() => {}}
            handleAddProcessConditionStep={() => {}}
            handleAddParallelStepsGroup={() => {}}
            allProcessStepsFields={allProcessStepsFields}
            handleAddPreviousStep={() => {}}
            handleAddProcessStep={() => {}}
            handleParallelBranchStepAdd={() => {}}
            handleConditionBranchStepAdd={() => {}}
            currentStepIndex={null}
            currentConditionId={null}
            currentParallelBranchesGroupId={null}
            selectedItemType={null}
            formErrors={[]}
            departmentsList={[]}
            directManagerSteps={[]}
            performerSelectionSteps={[]}
            isAutostartActive={false}
            handleProcessSelect={() => {}}
            handleConditionSelect={null}
            openBranchStepsLimitReachedDialog={() => {}}
            openStepsLimitReachedDialog={() => {}}
          />
        </div>}
    </div>

  <div className={classes.footer}>
    <Button variant="contained" color="secondary" onClick={() => handleClose()}>
      {t('filters.filter_button_close')}
    </Button>

    <Button variant="contained" color="primary" onClick={createProcess}>
      {t('templates.buttons.useTemplate')}
    </Button>
  </div>
</>
}
