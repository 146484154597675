import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import { AppTextField, Spinner } from 'components';
import { TemplateProcess } from '../../TemplateProcesses.types';

import { WorkspaceSelect } from './WorkspaceSelect';
import useCopyProcessDialogStyles from './CopyProcessDialog.useStyles';
import { MAX_NAME_LENGTH } from '../../TemplateProcesses.constants';

import CloseIcon from 'assets/images/icons/close-icon-thin-light-grey.svg';
import WarningIcon from 'assets/images/icons/warning-sign.svg';
import { useUserProfile } from '../../../../hooks';

interface CopyProcessDialogProps {
  processData: Partial<TemplateProcess>,
  isOpen: boolean;
  onClose: () => void;
  onCopy: (processCopyName: string, selectedWorkspaces: string[], refreshProcessesList: boolean, actionType: 'copy' | 'duplicate') => Promise<boolean>;
  onCopyAndOpen: (processCopyName: string, targetWorkspaceId: string, targetWorkspaceName: string) => void;
}

const COPY_SUFFIX = ' - copy';

export const CopyProcessDialog = ({
  processData,
  isOpen,
  onClose,
  onCopy,
  onCopyAndOpen,
}: CopyProcessDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useCopyProcessDialogStyles();
  const { companyId } = useUserProfile();

  const [selectedWorkspaces, setSelectedWorkspaces] = useState<string[]>([]);
  const [targetWorkspaceName, setTargetWorkspaceName] = useState<string>('');
  const [processCopyName, setProcessCopyName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleConfirm = async () => {
    setLoading(() => true);
    const isSuccess = await onCopy(processCopyName, selectedWorkspaces, false, 'copy');
    setLoading(() => false);
    if (isSuccess) {
      onClose();
    }
  };

  const handleNameChange = (value: string) => {
    setProcessCopyName(value);
  };

  const isOnlyCurrentWorkspaceSelected = useMemo(() =>
    selectedWorkspaces?.length === 1 && selectedWorkspaces[0] === companyId
  , [selectedWorkspaces, companyId]);

  useEffect(() => {
    if (isOpen) {
      setLoading(() => false);
      setProcessCopyName(processData?.processName + COPY_SUFFIX);
      setSelectedWorkspaces([]);
    }
  }, [processData, isOpen]);

  return (
    <Modal open={isOpen} disableEnforceFocus>
      <Box className={classes.modalBody}>
        <div className={classes.modalHeader}>
          <Typography className={classes.title}>
            {t('customProcesses.copyProcessDialog.title')}
          </Typography>

          <IconButton onClick={onClose} disabled={loading}>
            <img src={CloseIcon} alt={'Close copy process modal'} className={classes.closeIcon}/>
          </IconButton>
        </div>

        <div className={classes.modalContent}>
          <AppTextField
            label={t('customProcesses.copyProcessDialog.fields.name')}
            placeholder={''}
            className={cn(classes.nameInput, classes.nameInputRequired)}
            fullWidth
            value={processCopyName}
            disabled={loading}
            onChange={(e) => handleNameChange(e.target.value)}
            error={processCopyName?.trim().length === 0 || processCopyName?.length > MAX_NAME_LENGTH}
            helperText={
              <>
                {processCopyName?.trim().length === 0
                 ? <div className={classes.errorMessage}>
                   <img src={WarningIcon} alt="warning"/>
                   <span>{t('customProcesses.creationPage.processFormErrors.requiredField')}</span>
                 </div>
                 : null
                }
                {processCopyName?.length > MAX_NAME_LENGTH
                 ? <div
                   className={cn(classes.errorMessage, classes.errorMessageTwoSides)}>
                   <div>
                     <img src={WarningIcon} alt="warning"/>
                     <span>{t('customProcesses.creationPage.processFormErrors.valueTooLong')}</span>
                   </div>

                   <div>
                     {processCopyName?.length || 0}/{MAX_NAME_LENGTH}
                   </div>
                 </div>
                 : null
                }
              </>
            }
          />

          <WorkspaceSelect
            onSelect={values => {
              setSelectedWorkspaces(values.map(w => w.id));
              setTargetWorkspaceName(values.length
                                     ? values[0]?.organization?.shortName || values[0]?.organization?.name || ''
                                     : '');
            }}
            label={t('customProcesses.copyProcessDialog.fields.workspace')}
            value={selectedWorkspaces}
            selectedValuesLimit={3}
            disabled={loading}
          />

          <div className={classes.infoCard}>
            <p>{t('customProcesses.copyProcessDialog.infoCard.topMessage')}</p>
            <ul>
              {!isOnlyCurrentWorkspaceSelected && <li>
                {t('customProcesses.copyProcessDialog.infoCard.fieldsLine')}
              </li>
              }
              {!isOnlyCurrentWorkspaceSelected && <li>
                {t('customProcesses.copyProcessDialog.infoCard.assigneesLine')}
              </li>
              }
              <li>
                {t('customProcesses.copyProcessDialog.infoCard.conditionsLine')}
              </li>
            </ul>
            <p>{t('customProcesses.copyProcessDialog.infoCard.bottomMessage')}</p>
          </div>
        </div>

        <div className={classes.modalFooter}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.modalButton}
            disabled={loading}
            onClick={onClose}>
            {t('template_delete_modal.button_cancel', { defaultValue: 'Cancel' })}
          </Button>

          {selectedWorkspaces.length === 1 &&
            <Button
              variant="contained"
              color="secondary"
              className={classes.modalButton}
              disabled={loading || processCopyName.trim().length === 0 || processCopyName?.length > MAX_NAME_LENGTH}
              onClick={() => onCopyAndOpen(processCopyName, selectedWorkspaces[0], targetWorkspaceName)}
            >
              {t('customProcesses.copyProcessDialog.copyAndOpenButton', { defaultValue: 'Copy & Open' })}
            </Button>
          }

          <Button
            variant="contained"
            color="primary"
            className={classes.modalButton}
            onClick={handleConfirm}
            endIcon={loading ? <Spinner size={14} absolute={false} white buttonVersion/> : null}
            disabled={processCopyName.trim().length === 0 || processCopyName?.length > MAX_NAME_LENGTH || selectedWorkspaces.length === 0 || loading}>
            {t('customProcesses.copyProcessDialog.copyButton', { defaultValue: 'Copy' })}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
