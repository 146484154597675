import React, { memo, ReactElement } from 'react';
import { FormControl, IconButton, InputAdornment } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Controller, useFormContext } from 'react-hook-form';
import { theme } from '@dartech/dms-ui';
import cn from 'classnames';

import { AppTextField } from 'components';
import useTextarea from './useTextarea';

import useStyles from './useStyles';

type TextAreaProps = {
  name: string;
  label: string;
  rules: { [key: string]: string };
  params: {
    max?: number | string;
    isMultiline?: boolean;
    size?: 's' | 'm' | 'l';
    placeholder?: string;
  };
  isActive: boolean;
  isDisabled: boolean;
};

export const TextArea = memo(({
  name,
  label,
  isActive,
  isDisabled,
  rules,
  params,
}: TextAreaProps): ReactElement => {
  const classes = useStyles();

  const { watch, control, formState: { errors } } = useFormContext();

  const {
    hasInput,
    isMultiline,
    maxRows,
    componentRules,
    handleOnClear,
    handleOnChange,
  } = useTextarea({
    name,
    params,
    rules,
  });

  const clearIconAdornment = (hasInput && !isDisabled) ? (
    <InputAdornment position="end">
      <IconButton type="button" onClick={handleOnClear} size="small">
        <CloseIcon
          fontSize="small"
          style={{ fill: theme.palette.grey[500] }}
        />
      </IconButton>
    </InputAdornment>
  ) : null;

  return (
    <Controller
      name={name}
      control={control}
      rules={componentRules}
      render={({ field: { value } }) => (
        <>
          <label>{label}</label>
          <FormControl fullWidth>
            <AppTextField
              className={cn({
                [classes.multilineSmall]: isMultiline && maxRows === 2,
                [classes.multilineMedium]: isMultiline && maxRows === 4,
                [classes.multilineLarge]: isMultiline && maxRows === 6,
              })}
              size="medium"
              placeholder={params?.placeholder || ''}
              value={watch(name)}
              defaultValue={value}
              disabled={!isActive || isDisabled}
              multiline={!!isMultiline}
              rows={maxRows}
              rowsMax={maxRows}
              inputProps={{ 'data-selenium': name }}
              InputProps={{ endAdornment: (!isDisabled && isActive) && clearIconAdornment }}
              error={!!errors[name]}
              helperText={!!errors[name] && errors[name].message}
              onChange={handleOnChange}
            />
          </FormControl>
        </>
      )}
    />
  );
});
