import React from 'react'
import { Typography, Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { environment } from 'environments/environment';
import useStyles from './useStyles';

interface Props {
  icon: string,
  title: string,
  message: string,
  notFullScreen?: boolean
}

// TODO - mode to utils

export const ErrorPage = ({ icon, title, message, notFullScreen }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles({notFullScreen});

  const handleBackButtonClick = () => {
    window.location.href = environment.baseUrl + '/overview';
  }

  return (
    <Box className={classes.ErrorPage}>
      <Box className={classes.ErrorPageContent}>
        <div className={classes.errorInfoWrapper}>
          <img className={classes.icon} src={icon} alt={'No access icon'}/>
          <Typography className={classes.title}>{title}</Typography>
          {!!message && <Typography className={classes.message}>{message}</Typography>}
        </div>
        <Button onClick={handleBackButtonClick} className={classes.backButton} color={'primary'}>
          {t('errorPage.backButton')}
        </Button>
      </Box>
    </Box>
  )
}
