export const HINT_MESSAGES = {
  APPROVE_PDF_FILE: 'form_components.templateViewer.approvePdf',
  SUCCESS_APPROVE_PDF_FILE: 'form_components.templateViewer.approvePdfSuccess'
}

export const ERROR_MESSAGES = {
  CREATE_FILE_INSTANCE_ID_NOT_FOUND: 'form_components.templateViewer.errors.fileInstanceIdNotFound',
  UPLOAD_FILE_AUTH: 'form_components.templateViewer.errors.googleAuthError',
  UPLOAD_FILE_PS: 'form_components.templateViewer.errors.PSError',
}

export const REPLACEABLE_PART_START = '{{';
export const REPLACEABLE_PART_END = '}}';
export const MODIFIER_DELIMITER = '!';
export const MODIFIERS: {[key: string]: (value: string) => string} = {
  'upper': (value: string): string => value.toUpperCase(),
  'lower': (value: string): string => value.toLowerCase(),
  'nospace': (value: string): string => value.replaceAll(' ', '_'),
}

export const TEMPLATE_DEFAULT_FORMAT = 'PDF';
export const DEBOUNCE_DELAY = 1000; // в миллисекундах
export const FORM_VALUES_KEYS_EXCEPTION = ['urgentReason', 'urgentSwitch'];
export const DEFAULT_ERROR_MESSAGE = 'form_components.templateViewer.errors.generationDefault';
export const DEFAULT_FILE_NAME = 'document.pdf'
