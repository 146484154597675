import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { openCreateDialogAction } from 'store/requests';

import NoRequests from "assets/images/icons/no-requests.svg";
import NoApprovals from "assets/images/icons/no-approvals.svg";
import NoResultsByFilters from "assets/images/icons/no-results-by-filters.svg";

import useStyles from './useStyles';

type Props = {
  message?: string;
  additionalMessage?: string;
  type?: "requests";
  noResultsWithFilters?: boolean
};

export const NoContentMessage = ({ message, additionalMessage, type, noResultsWithFilters }: Props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openCreateDialog = useCallback(() => {
    dispatch(openCreateDialogAction(true));
  }, [dispatch]);

  const imageSrc = useMemo(() => {
    let src = NoApprovals;

    if(type === "requests") {
      src = NoRequests;
    }

    if(noResultsWithFilters){
      src = NoResultsByFilters
    }

    return src

  }, [noResultsWithFilters, type])

  const messageContent = useMemo(() => {
    if (message) return message
    if (noResultsWithFilters) return t("AdminPanel.no_results")
  }, [message, noResultsWithFilters, t])

  const additionalMessageContent = useMemo(() => {
    if (additionalMessage) return additionalMessage
    if (noResultsWithFilters) return t("AdminPanel.no_results_description")
  }, [additionalMessage, noResultsWithFilters, t])

  return (
    <Box className={classes.root}>

      <Box color="text.hint">
        <Box display='flex' justifyContent='center'>
          <img src={imageSrc} alt="No data" height={100} width={100} />
        </Box>

        <Typography variant="h4" className={classes.mainMessage}>{messageContent}</Typography>
        {(additionalMessage || noResultsWithFilters) &&  <Typography className={classes.additionalMessage} variant="body2">{additionalMessageContent}</Typography>}

      {
        type === "requests" &&
        <Button
          className={classes.newRequestButton}
          color="primary"
          startIcon={<AddIcon/>}
          onClick={openCreateDialog}
        >
          {t('Processes.Request')}
        </Button>
      }
      </Box>
    </Box>
  );
};
