import { commonExceptionHandler } from '../handlers';
import { baseV1ApiClient } from '../clients';
import { getProfileCompanyDataFromLocalStorage } from 'utils/user';

export const getWorkCalendar = async (year): Promise<{days: { [key: string]: any }[]}> => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: `/new-hcms/work-calendar/company-id/${companyId}`,
      params: {
        year
      },
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const getUserWorkspacesList = async (): Promise<{[key: string]: any}> => {
  try {
    const { id: userId } = JSON.parse(localStorage.getItem('profile'));

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: `hcms/main-api/structure/${userId}/workspaces`,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const getWorkspaceToken = async (workspaceId: string): Promise<{[key: string]: any}> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: `hcms/main-api/workspace/${workspaceId}/token`,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}
