import {makeStyles} from "@material-ui/core";

export default makeStyles(({ spacing, palette }) => ({
  rowCard: {
    display: "flex",
    flexDirection: 'row',
    alignItems: "center",
    gap: 8,
    padding: "12px 8px",
    borderRadius: 8,
    background: "#F7F8FC",
  },
  card: {
    width: 123,
    height: 123,
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(38, 40, 66, 0.08)',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transitionDuration: '300ms',
    transitionProperty: 'background, border',

    '&:hover': {
      backgroundColor: '#F7F8FC',
      border: '2px solid #007994',
    }
  },
  cardDragged: {
    background: 'rgba(255, 255, 255, 0.8)'
  },
  dragIndicator: {
    width: 16,
    marginTop: spacing(3.75),
  },
  rowIconWrapper: {
    width: 32,
    height: 32,
    '& path, & svg path': {
      fill: '#8B8C9E !important'
    }
  },
  iconWrapper: {
    width: 24,
    height: 24,
    marginTop: spacing(4.5)
  },
  icon: {
    maxWidth: '100%',
    maxHeight: '100%',
    margin: '0 auto'
  },
  name : {
    marginTop: spacing(2.5),
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    textAlign: 'center',
    color: '#262842',
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  rowFieldInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  rowFieldName: {
    fontWeight: 400,
    fontSize: 14,
    color: '#262842',
  },
  secondaryName: {
    color: "#6D6E85",
    fontSize: 12,
    fontWeight: 400
  },
  cardTooltipPopper: {
    pointerEvents: 'all',
  },
  cardTooltip: {
    color: '#F7F8FC',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    maxWidth: 240,
    width: 'fit-content',
    padding: '8px 12px',
    borderRadius: 10,
  },
}));
