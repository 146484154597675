import React, { memo } from 'react';
import cn from 'classnames';
import { FormControlLabel, Switch as MuiSwitch } from '@material-ui/core';
import { useFormContext, Controller } from 'react-hook-form';
import { theme } from '@dartech/dms-ui';
import { ThemeProvider } from '@material-ui/core';

import useBpmSwitchTimeOff from './hooks/useBpmSwitchTimeOff';
import useBpmSwitchOnBoarding from './hooks/useBpmSwitchOnboarding';

import useStyles from './useStyles';
import blueCheckIcon from 'assets/images/icons/blue-check.svg';

type Props = {
  name?: string;
  hint?: string;
  variantWithLabels?: boolean;
};

theme.overrides = {
  ...theme.overrides,
  MuiIconButton: {
    root: {
      //fix incorrect position of circle in toggle (moved to bottom right corner)
      '&:not(.MuiRadio-root):not(.MuiCheckbox-root):not(.MuiSwitch-switchBase):not(.MuiAutocomplete-clearIndicator)': {
        padding: 0,
      },
    },
  },
  MuiFormControlLabel: {
    root: {
      marginLeft: 0,
    },
    label: {
      paddingLeft: theme.spacing(3),
    },
  },
};

export const Switch = memo(({ hint, name, variantWithLabels = false }: Props) => {
  const classes = useStyles();
  const { control } = useFormContext();

  const { isAdaptationSalaryEquals } = useBpmSwitchOnBoarding();
  const { isTimeOffNotHRUser } = useBpmSwitchTimeOff({ name });

  if (isAdaptationSalaryEquals || isTimeOffNotHRUser) return null;

  return (
    <ThemeProvider theme={theme}>
      <Controller
        control={control}
        name={name}
        data-selenium={name}
        render={({ field: { onChange, value, onBlur, ref }}) =>
          variantWithLabels ? (
            <>
              <div className={classes.root} onClick={() => onChange(!value)}>
                <div
                  className={classes.switchRoot}
                >
                  <span className={classes.yesLabel}>Yes</span>
                </div>
                <div
                  className={cn(classes.switchRootOverlay, {
                    [classes.switchRootOverlayYes]: value,
                  })}
                >
                  <span className={classes.noLabel}>No</span>
                </div>
                <div
                  className={cn(classes.coverBlock, {
                    [classes.coverBlockYes]: value,
                  })}
                >
                  <img className={cn(classes.checkIcon, {[classes.checkIconShown]: value})} src={blueCheckIcon}/>
                </div>
              </div>
            </>
          ) : (
            <FormControlLabel
              label={hint || null}
              style={{position: 'relative'}}
              control={
                <MuiSwitch
                  onBlur={onBlur}
                  checked={value}
                  inputRef={ref}
                  onChange={(e) => onChange(e.target.checked)}
                />
              }
            />
          )
        }
      />
    </ThemeProvider>
  );
});
