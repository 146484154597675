import React, { ReactNode, ReactElement } from 'react'
import { Typography } from '@material-ui/core';

import useStyles from './useStyles';
import classNames from 'classnames';

type ContentHeaderProps = {
  title: string;
  children?: ReactNode;
  className?: string;
}

export const ContentHeader = ({ children, title, className }: ContentHeaderProps): ReactElement => {
  const classes = useStyles();


  return (
    <header className={classNames(classes.root, className)}>
      <Typography className={classes.title} variant="h1">{title}</Typography>
      {children}
    </header>
  )
}
