import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import {
  Box,
  Button,
  DialogContent,
  Typography,
  DialogActions,
  Dialog,
  ButtonBase,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { SelectUsers } from 'components/User/SelectUsers';

import { useTaskState } from 'store/requests';
import { getProfileCompanyDataFromLocalStorage } from 'utils/user';

import {
  CLOSE_SELECT_EMPLOYEE_POPOVER_ACTION,
  SELECT_EMPLOYEE_POPOVER_ACTION,
} from '../constants';

import CloseIcon from 'assets/images/icons/close-icon.svg';
import useStyles from './useStyles';

type Props = {
  open?: boolean;
  isCommentRequired?: boolean;
  showOnlyPossiblePerformers?: boolean;
  onClick: (value: any) => void;
  onEmployeeChange?: (username: string) => void;
  onCommentChange?: (value: string) => void;
  submitAction?: string;
};

export const SelectEmployeeDialog = ({
  open = false,
  isCommentRequired = true,
  showOnlyPossiblePerformers = false,
  onClick,
  onEmployeeChange,
  onCommentChange,
  submitAction = SELECT_EMPLOYEE_POPOVER_ACTION,
}: Props) => {
  const [employee, setEmployee] = useState(null);
  const [comment, setComment] = useState('');
  const { t } = useTranslation();

  const { data: bpmTask } = useTaskState();
  const { id: profileCompanyId } = getProfileCompanyDataFromLocalStorage();
  const companyId = bpmTask.values['companyId'] || profileCompanyId;

  const handleEmployeeChange = (user) => {
    setEmployee(user || '');
    onEmployeeChange(user ? user.id : '');
  };

  const handleCommentChange = (message: string) => {
    setComment(message);
    onCommentChange(message);
  };

  const isSubmitButtonDisabled = useMemo(
    () =>
      !(employee && employee.id) ||
      !(!isCommentRequired || (isCommentRequired && comment.length)),
    [employee, isCommentRequired, comment]
  );

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => onClick({ action: CLOSE_SELECT_EMPLOYEE_POPOVER_ACTION })}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 12
        }
      }}

    >
      <Box
        component="header"
        py={4}
        px={6}
        display="flex"
        justifyContent="space-between"
        className={classes.header}
      >
        <Typography variant="h4">{t('assign_modal.title')}</Typography>
        <ButtonBase
          onClick={() =>
            onClick({ action: CLOSE_SELECT_EMPLOYEE_POPOVER_ACTION })
          }
        >
          <img src={CloseIcon} alt="Close" />
        </ButtonBase>
      </Box>

      <DialogContent>
        <Box mb={2} className={classes.requiredInputWrapper}>
          <SelectUsers
            onSelect={(user) => handleEmployeeChange(user)}
            label={t('assign_modal.field_employee')}
            margin="normal"
            value={employee}
            companyId={companyId}
          />
        </Box>
        <Box
          mb={4}
          className={cn({ [classes.requiredInputWrapper]: isCommentRequired })}
        >
          <TextField
            multiline
            rows={4}
            rowsMax={4}
            label={t('assign_modal.field_comment')}
            placeholder=""
            className={classes.commentInput}
            fullWidth
            onChange={(e) => handleCommentChange(e.target.value)}
          />
        </Box>
      </DialogContent>

      <DialogActions
        className={classes.footer}
      >
        <Button
          color="secondary"
          onClick={() =>
            onClick({ action: CLOSE_SELECT_EMPLOYEE_POPOVER_ACTION })
          }
        >
          {t('assign_modal.button_cancel')}
        </Button>

        <Button
          color="primary"
          onClick={() => onClick({ action: submitAction })}
          disabled={isSubmitButtonDisabled}
        >
          {t('assign_modal.button_submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
