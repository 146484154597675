import { makeStyles } from '@material-ui/core';

type styleProps = {
  isStepCompleted: boolean;
  isLastStep: boolean;
  isFutureStep: boolean;
  isParallelStep: boolean;
  isOpenedStep: boolean;
  isBranchStep: boolean;
};

export default makeStyles(({ spacing, palette }) => ({
  historyTabContentWrapper: {
    paddingTop: spacing(4),
  },
  historyItemWrapper: {
    position: 'relative',
    marginLeft: spacing(6),
    paddingRight: spacing(6),
    // '&:first-child': {
    //   marginTop: spacing(4),
    // },
    paddingBottom: ({isBranchStep}: styleProps) => isBranchStep ? 8 : 0,
  },
  historyItemWrapperNoTimeline: {
    position: 'relative',
    marginLeft: '0 !important',
    paddingRight: '0 !important',
    '&:first-child': {
      marginTop: '0 !important',
    },
  },
  historyItemWrapperTopBranchStep: {
    // cover extra part of branches block timeline
    '&:not(:last-child)::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: -60,
      width: 8,
      height: 'calc(100% + 8px)',
      background: '#F0F0F7'
    },
  },
  // historyItemWrapperTopBranchStepWithLineCover: {
  //
  // }
  historyItemWrapperBranchStep: {
    position: 'relative',
    '&:first-child': {
      marginTop: '0 !important',
    },
    // line from branch to branches block timeline
    '&:last-child::before': {
      content: '""',
      position: 'absolute',
      top: 8,
      left: -56,
      width: 16,
      height: 1,
      background: '#A9A9B8'
    },
    // arrow on the line from branch to branches block timeline
    '&:last-child::after': {
      content: '""',
      position: 'absolute',
      top: 5,
      left: -47,
      width: 7,
      height: 7,
      borderRadius: 1,
      borderTop: '1px solid #A9A9B8',
      borderRight: '1px solid #A9A9B8',
      transform: 'rotate(45deg)'
    }
  },
  timelinePart: {
    position: 'absolute',
    left: -26,
    top: 0,
    height: '100%',
  },
  timelinePoint: {
    width: 15,
    height: 15,
    display: 'flex',
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    zIndex: 10,
    '& img': {
      width: 15,
    },
  },
  timelineLine: {
    height: '100%',
    width: 1,
    background: ({ isFutureStep, isBranchStep }: styleProps) =>
      isFutureStep
      ? 'repeating-linear-gradient(to bottom, transparent, transparent 5px, #A9A9B8 5px, #A9A9B8 10px)'
      : isBranchStep
        ? '#A9A9B8'
        : '#007994',
    position: 'absolute',
    left: 7,
  },
  historyItemData: {
    paddingBottom: ({isBranchStep}: styleProps) => isBranchStep ? 0 : spacing(6),
    position: 'relative',
  },
  historyItemDataNoTimeline: {
    paddingBottom: '0 !important',
  },
  historyItemDataFutureStep: {
    '& p, & h6': {
      color: '#A9A9B8 !important',
    },
  },
  taskName: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    color: '#262842',
    padding: 0,
    marginBottom: spacing(2),
  },
  taskDescription: {
    marginTop: spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  taskStatus: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#8B8C9E',
    marginTop: 4,
  },
  taskStatusWaitingApproval: {
    fontWeight: 400,
    color: '#8B8C9E',
  },
  taskStatusApprove: {
    fontWeight: 600,
    color: '#2DB77B',
  },
  taskStatusRework: {
    fontWeight: 600,
    color: '#FFA530',
  },
  taskStatusReject: {
    fontWeight: 600,
    color: '#D6331F',
  },
  possibleAssigneesListTitle: {
    fontSize: 12,
    fontWeight: 400,
    color: '#465564',
  },
  multipleAssigneesUser: {
    '&:not(:last-child)': {
      marginBottom: spacing(3)
    }
  },
  assigneeText: {
    fontSize: 12,
    color: '#465564',
  },
  parallelStepInfoWrapper: {
    '&:not(:last-child)': {
      marginBottom: spacing(3)
    }
  },
  multipleAssigneesLabel: {
    marginTop: -4,
    marginBottom: spacing(3),
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#8B8C9E',
    display: 'block'
  },
  editBtn: {
    borderRadius: "50%"
  }
}));
