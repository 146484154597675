import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { CancelOutlined, BlockOutlined, RefreshOutlined, CheckCircleOutlined, DeleteOutline, Sync } from '@mui/icons-material';

import { setDocumentSignatureErrorStatus, useTaskState } from 'store/requests';
import { useUserProfile, useUsersRole } from 'hooks';
import { getTranslationKeyForButtonLabel } from 'utils/translations';
import { getTaskHistoryV2 } from 'api/requests';
import useTaskDocuments from 'components/TaskDetails/components/Tabs/DocumentsTab/useTaskDocuments';

import { SUBMIT_AND_CREATE_ANOTHER_ACTION, GENERATE_PDF_ACTION } from '../../constants';
import { EDS_DEFAULT_ATTRIBUTES } from '../../Eds/EdsConstants';
import { TaskHistoryType } from 'components/TaskDetails/components/Tabs/HistoryTab/history.types';

import ExportIcon from 'assets/images/icons/arrow_export_up_regular.svg';
import ReworkIcon from 'assets/images/icons/rework_icon.svg';
import ReassignIcon from 'assets/images/icons/reassign_icon.svg';
import TakeOnTaskIcon from 'assets/images/icons/take_on_task_icon.svg';
import useStyles from './useStyles';

const STOP_HARDCODE_REMOVAL_DATE = new Date(2021, 7, 12);
const EDS_BUTTON_COMPONENT = 'eds-button';

const HIDDEN_IN_PROCESSES_WITH_PARTIALLY_OR_FULLY_SIGNED_DOCUMENTS = ['Rework'];
const HIDDEN_IN_PROCESSES_WITH_FULLY_SIGNED_DOCUMENTS = ['Reject', 'Canceled', 'Cancel'];
const STATUSES_WITHOUT_PDF_EXPORT = ['Canceled', 'Reject'];

export type Button = {
  action?: string;
  key?: number;
  color?: 'primary' | 'inherit' | 'default' | 'secondary';
  colorClass?: 'info' | 'danger' | 'orange' | 'success' | 'neutral';
  variant?: 'text' | 'contained';
  label?: string;
  tooltip?: string;
  type?: string;
  isIconConnected?: boolean;
  icon?: string;
  iconButton?: boolean;
  componentParams?: any;
  behaviour?: any;
  component?: string;
  attributeId?: number;
  attachmentSigning?: string;
  attachmentStorage?: string;
  binAttribute?: string;
  companyAttribute?: string;
  individualNumberAttribute?: string;
  emailAttribute?: string;
  supplierEmailAttribute?: string;
  existingSupplierAttribute?: string;
  supplierBinAttribute?: string;
  isExternalSign?: boolean;
  isInternalSign?: boolean;
  isInternalSignWithEmployee?: boolean;
  isInternalSignEmployeeInitiator?: boolean;
  showOnlyToInitiator?: boolean;
  showOnlyWithAssignee?: boolean;
  showOnlyWithoutAssignee?: boolean;
  hideOnAutostartProcesses?: boolean;
  showOnAllRequestFormationFormSteps?: boolean;
  showOnlyOnPredefinedSteps?: boolean;
  buttonVisibleSteps?: number[];
  hideOnPredefinedSteps?: boolean;
  buttonHiddenSteps?: number[];
  showOnCompletedTasks?: boolean;
  showForWatchers?: boolean;
  useWithoutRequiredFieldsFilled?: boolean;
  leftSideButton?: boolean;
  createLinkedEntity?: boolean;
  disabled?: boolean;
  buttonDisabled?: boolean;
  alwaysActiveButtons?: string[];
  needActionConfirmation?: boolean;
  confirmationModalKey?: string;
  confirmationModalTitle?: string;
  confirmationModalMessage?: string;
  confirmationModalButtonLabel?: string;
};

const useBpmFormButtonList = ({
  onClick,
  existingButtonList,
  validationAttributeList,
  showButtonsOnBothSides = false,
  disabled,
  buttonDisabled,
  alwaysActiveButtons,
  checkIfTaskDataIsOutdated = false,
  onOutdatedTaskClick,
  validateDocumentsSignatures,
}) => {
  const styles = useStyles();
  const { data: bpmTask, taskOpenTime, documents } = useTaskState();
  const { t } = useTranslation();
  const { id: profileId, nickname: profileNickName } = useUserProfile();
  const { hasAccessToPromoteRequests } = useUsersRole();
  const dispatch = useDispatch();
  const { hasPartiallyOrFullySignedDocuments, hasFullySignedDocuments } = useTaskDocuments({
    formMethods: {},
    goToPreviousStep: () => null,
  });

  const [selectedAction, setSelectedAction] = useState(null);
  const selectedButtonComponentParams = useRef({});
  const selectedButton = useRef(null);
  const [isCompletedTask, setCompletedTask] = useState(false);
  const [isActionConfirmationDialogOpen, setActionConfirmationDialogOpen] = useState(false);
  const [clickedButton, setClickedButton] = useState<Button | null>(null);
  const [clickedButtonIndex, setClickedButtonIndex] = useState<Button | null>(null);

  const buttonIconsMap = useMemo(
    () => ({
      Canceled: <CancelOutlined className={styles.blackIcon} />,
      Cancel: <CancelOutlined className={styles.blackIcon} />,
      Reject: <BlockOutlined className={styles.blackIcon} />,
      Rework: ReworkIcon,
      Reassign: ReassignIcon,
      Restart: <Sync className={styles.whiteIcon} />,
      Delete: <DeleteOutline className={styles.whiteIcon} />,
      Done: <CheckCircleOutlined className={styles.whiteIcon} />,
      Approve: <CheckCircleOutlined className={styles.whiteIcon} />,
      Submit: <CheckCircleOutlined className={styles.whiteIcon} />,
      'Approve & Assign': <CheckCircleOutlined className={styles.whiteIcon} />,
      'Take on a task': TakeOnTaskIcon,
    }),
    []
  );

  const getCompletedTaskStatusFromHistory = async () => {
    try {
      const taskHistory: TaskHistoryType = await getTaskHistoryV2(bpmTask?.processInstanceId);
      setCompletedTask(taskHistory?.pastTasks?.some((step) => step.taskId === bpmTask.taskId && step.completed));
    } catch (error) {
      setCompletedTask(false);
    }
  };

  useEffect(() => {
    getCompletedTaskStatusFromHistory();
  }, [bpmTask]);

  const hasAssignee = useMemo((): boolean => !!bpmTask?.assignee, [bpmTask]);

  const isAssignee: boolean = useMemo(
    (): boolean =>
      bpmTask?.isAssignee(profileId, hasAccessToPromoteRequests) || bpmTask?.isAssignee(profileNickName, hasAccessToPromoteRequests),
    [bpmTask, profileId, profileNickName, hasAccessToPromoteRequests]
  );

  const isAssigneeCandidate: boolean = useMemo(
    (): boolean =>
      bpmTask?.isAssigneeCandidate(profileId, hasAccessToPromoteRequests) ||
      bpmTask?.isAssigneeCandidate(profileNickName, hasAccessToPromoteRequests),
    [bpmTask, profileId, profileNickName, hasAccessToPromoteRequests]
  );

  const isInitiator: boolean = useMemo((): boolean => bpmTask?.initiator === profileId || bpmTask?.initiator === profileNickName, [
    bpmTask,
    profileId,
    profileNickName,
  ]);

  const isPreboarding: boolean = useMemo((): boolean => bpmTask?.businessEntity.sysName === 'preboarding', [bpmTask]);

  const isEDSButton: (string) => boolean = useCallback((component: string): boolean => component === EDS_BUTTON_COMPONENT, []);

  const isDisabled = (action) => disabled && !(buttonDisabled && alwaysActiveButtons.includes(action));

  const currentStateButtons = useMemo(() => existingButtonList || bpmTask?.currentStateButtons || [], [existingButtonList, bpmTask]);

  const buttons = useMemo(() => {
    if (!bpmTask) {
      return {
        leftSide: [],
        rightSide: [],
      };
    }

    let data = currentStateButtons
      .map(({ attributeId, state_order, behaviour, component, componentParams }) => {
        const parsedButton: Button = {};

        const parsedComponentParams = componentParams ? JSON.parse(componentParams) : {};
        if (
          (hasPartiallyOrFullySignedDocuments &&
            HIDDEN_IN_PROCESSES_WITH_PARTIALLY_OR_FULLY_SIGNED_DOCUMENTS.includes(parsedComponentParams?.value)) ||
          (hasFullySignedDocuments && HIDDEN_IN_PROCESSES_WITH_FULLY_SIGNED_DOCUMENTS.includes(parsedComponentParams?.value))
        ) {
          return null;
        }

        parsedButton.component = component;
        parsedButton.behaviour = behaviour;
        parsedButton.key = attributeId + state_order;
        parsedButton.action = parsedComponentParams?.value || '';
        parsedButton.label = t(getTranslationKeyForButtonLabel(parsedComponentParams?.label, bpmTask.processSysName)) || '';
        parsedButton.tooltip = t(getTranslationKeyForButtonLabel(parsedComponentParams?.label, bpmTask.processSysName)) || '';
        parsedButton.icon = parsedComponentParams?.icon || buttonIconsMap?.[parsedComponentParams?.value] || '';
        parsedButton.componentParams = parsedComponentParams;

        parsedButton.needActionConfirmation = !!parsedComponentParams?.needActionConfirmation;
        parsedButton.confirmationModalKey = parsedComponentParams?.confirmationModalKey || '';
        parsedButton.confirmationModalTitle = parsedComponentParams?.confirmationModalTitle || '';
        parsedButton.confirmationModalMessage = parsedComponentParams?.confirmationModalMessage || '';
        parsedButton.confirmationModalButtonLabel = parsedComponentParams?.confirmationModalButtonLabel || '';

        parsedButton.showOnlyToInitiator = !!parsedComponentParams?.showOnlyToInitiator;
        parsedButton.showOnlyWithAssignee = !!parsedComponentParams?.showOnlyWithAssignee;
        parsedButton.hideOnAutostartProcesses = !!parsedComponentParams?.hideOnAutostartProcesses;
        parsedButton.showOnAllRequestFormationFormSteps = !!parsedComponentParams?.showOnAllRequestFormationFormSteps;
        parsedButton.showOnlyWithoutAssignee = !!parsedComponentParams?.showOnlyWithoutAssignee;
        parsedButton.showOnlyOnPredefinedSteps = !!parsedComponentParams?.showOnlyOnPredefinedSteps;
        parsedButton.hideOnPredefinedSteps = !!parsedComponentParams?.hideOnPredefinedSteps;
        parsedButton.buttonVisibleSteps = (bpmTask?.values[parsedComponentParams?.buttonVisibleStepsAttribute] || '')
          .split(';')
          .filter(Boolean)
          .map((v) => +v);
        parsedButton.buttonHiddenSteps = (bpmTask?.values[parsedComponentParams?.buttonHiddenStepsAttribute] || '')
          .split(';')
          .filter(Boolean)
          .map((v) => +v);

        parsedButton.useWithoutRequiredFieldsFilled = !!parsedComponentParams?.useWithoutRequiredFieldsFilled;

        parsedButton.leftSideButton = !!parsedComponentParams?.leftSideButton;
        parsedButton.variant = parsedComponentParams?.textButton ? 'text' : 'contained';

        if (isEDSButton(component)) {
          parsedButton.attachmentSigning = parsedComponentParams.attachmentSigning;
          parsedButton.attachmentStorage = parsedComponentParams.attachmentStorage;

          parsedButton.isExternalSign = parsedComponentParams?.signatureType
            ? parsedComponentParams?.signatureType === 'externalSign'
            : !!parsedComponentParams.isExternalSign;
          parsedButton.isInternalSign = parsedComponentParams?.signatureType
            ? parsedComponentParams?.signatureType === 'internalSign'
            : !!parsedComponentParams.isInternalSign;
          parsedButton.isInternalSignWithEmployee = parsedComponentParams?.signatureType
            ? parsedComponentParams?.signatureType === 'internalSignWithEmployee'
            : !!parsedComponentParams.isInternalSignWithEmployee;
          parsedButton.isInternalSignEmployeeInitiator = parsedComponentParams?.signatureType
            ? parsedComponentParams?.signatureType === 'internalSignEmployeeInitiator'
            : !!parsedComponentParams.isInternalSignEmployeeInitiator;

          parsedButton.binAttribute = parsedComponentParams?.binAttribute || EDS_DEFAULT_ATTRIBUTES.binAttribute;
          parsedButton.companyAttribute = parsedComponentParams?.companyAttribute || EDS_DEFAULT_ATTRIBUTES.companyAttribute;
          parsedButton.individualNumberAttribute =
            parsedComponentParams?.individualNumberAttribute || EDS_DEFAULT_ATTRIBUTES.individualNumberAttribute;
          parsedButton.emailAttribute = parsedComponentParams?.emailAttribute || EDS_DEFAULT_ATTRIBUTES.emailAttribute;
          parsedButton.supplierEmailAttribute =
            parsedComponentParams?.supplierEmailAttribute || EDS_DEFAULT_ATTRIBUTES.supplierEmailAttribute;
          parsedButton.existingSupplierAttribute =
            parsedComponentParams?.existingSupplierAttribute || EDS_DEFAULT_ATTRIBUTES.existingSupplierAttribute;
          parsedButton.supplierBinAttribute = parsedComponentParams?.supplierBinAttribute || EDS_DEFAULT_ATTRIBUTES.supplierBinAttribute;
        }

        switch (parsedComponentParams.class) {
          case 'success':
          case 'orange':
            parsedButton.color = 'primary';
            break;
          case 'danger':
            parsedButton.color = 'secondary';
            break;
          default:
            parsedButton.color = 'secondary';
            break;
        }

        parsedButton.colorClass = parsedComponentParams.class || 'neutral';

        return parsedButton;
      })
      .filter(Boolean);

    const useHardcodedStopButton =
      new Date(bpmTask?.entity?.businessTask?.processStartDate).getTime() < STOP_HARDCODE_REMOVAL_DATE.getTime();

    if (useHardcodedStopButton && !bpmTask.isCompleted && !bpmTask.isFirstStep && isPreboarding) {
      const stopButton = {
        action: 'Stop',
        color: 'secondary',
        key: data.length > 0 ? data[data.length - 1].key + 1 : 1,
        label: 'Stop',
        showOnlyToInitiator: true,
      } as Button;

      data.push(stopButton);
    }

    const hasSubmitAndCreateAnotherButton = data.some((button) => button.action === SUBMIT_AND_CREATE_ANOTHER_ACTION);

    if (!hasSubmitAndCreateAnotherButton && !existingButtonList && bpmTask.isFirstStep && !bpmTask.isRework) {
      const submitAndCreateAnotherButton = {
        action: SUBMIT_AND_CREATE_ANOTHER_ACTION,
        color: 'secondary',
        key: data.length > 0 ? data[data.length - 1].key + 1 : 1,
        label: t('One_step_internal_memo.process_button2'),
      } as Button;

      data = [submitAndCreateAnotherButton, ...data];
    }

    if (
      (!bpmTask.isFirstStep || bpmTask.isCompleted) &&
      bpmTask.candidateUsers?.length === 0 &&
      !STATUSES_WITHOUT_PDF_EXPORT.includes(bpmTask.businessTask.taskStatus)
    ) {
      const generatePdfButton = {
        action: GENERATE_PDF_ACTION,
        color: 'secondary',
        key: data.length > 0 ? data[data.length - 1].key + 1 : 1,
        label: '',
        tooltip: t('buttons.generate_pdf'),
        showOnCompletedTasks: true,
        showToInitiator: true,
        showForWatchers: true,
        leftSideButton: true,
        iconButton: true,
        useWithoutRequiredFieldsFilled: true,
        componentParams: {
          useWithoutRequiredFieldsFilled: true,
        },
        icon: ExportIcon,
      } as Button;

      data = [generatePdfButton, ...data];
    }

    if (showButtonsOnBothSides) {
      const leftSideButtons = data.filter((button) => button.leftSideButton);

      const rightSideButtons = data.filter((button) => !button.leftSideButton);

      return {
        leftSide: leftSideButtons,
        rightSide: rightSideButtons,
      };
    }

    return {
      leftSide: [],
      rightSide: data,
    };
  }, [
    bpmTask,
    existingButtonList,
    currentStateButtons,
    isEDSButton,
    isPreboarding,
    showButtonsOnBothSides,
    isCompletedTask,
    hasFullySignedDocuments,
    hasPartiallyOrFullySignedDocuments,
  ]);

  const buttonActionCallback = async ({ index, action, componentParams }) => {
    if (checkIfTaskDataIsOutdated) {
      try {
        const historyData = await getTaskHistoryV2(bpmTask.processInstanceId);
        const stepCompletionTimes = historyData?.pastTasks.map((step) => new Date(step.taskStartDate).getTime()) || [];
        const isTaskDataOutdated = stepCompletionTimes.some((time) => time > taskOpenTime);
        if (isTaskDataOutdated) {
          onOutdatedTaskClick && onOutdatedTaskClick();
          return;
        }
      } catch (error) {
        NotificationManager.error(t('errors.somethingIsWrong'), `${t('customProcesses.notifications.error')}!`);
        return;
      }
    }

    if (validateDocumentsSignatures) {
      const hasNotSignedDocuments = documents.some(
        (document) => document.signRequiredSteps.includes(bpmTask.currentAction.stepperOrder) && !document.isSigned
      );
      if (hasNotSignedDocuments) {
        dispatch(setDocumentSignatureErrorStatus(true));
        return;
      } else {
        dispatch(setDocumentSignatureErrorStatus(false));
      }
    }

    if (selectedButton.current === index && selectedAction === action) {
      onClick({
        action: selectedAction,
        validationAttributeList,
        componentParams,
      });

      return;
    }

    setSelectedAction(action);
    selectedButton.current = index;
    selectedButtonComponentParams.current = componentParams;
  };

  const handleButtonClick = (index, action, button: Button) => {
    if (button.needActionConfirmation) {
      setActionConfirmationDialogOpen(() => true);
      setClickedButton(() => button);
      setClickedButtonIndex(() => index);
    } else {
      buttonActionCallback({
        index,
        action,
        componentParams: button.componentParams,
      });
    }
  };

  const handleConfirmationDialogClose = () => {
    setActionConfirmationDialogOpen(() => false);
    setClickedButton(() => null);
  };

  const handleActionConfirmation = () => {
    buttonActionCallback({
      index: clickedButtonIndex,
      action: clickedButton?.action,
      componentParams: clickedButton?.componentParams,
    });
  };

  useEffect(() => {
    if (selectedButton.current || selectedButton.current === 0) {
      onClick({
        action: selectedAction,
        componentParams: selectedButtonComponentParams.current,
        validationAttributeList,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAction]);

  return {
    buttons,
    isAssignee,
    isAssigneeCandidate,
    hasAssignee,
    isInitiator,
    isEDSButton,
    selectedButton: selectedButton.current,
    buttonActionCallback,
    isDisabled,
    isCompletedTask,
    isActionConfirmationDialogOpen,
    setActionConfirmationDialogOpen,
    handleButtonClick,
    clickedButton,
    handleConfirmationDialogClose,
    handleActionConfirmation,
  };
};
export default useBpmFormButtonList;
