import React, { memo, ReactElement, useMemo } from 'react';
import cn from 'classnames';
import { Box, Button, CircularProgress, Tooltip } from '@mui/material';

import { EdsButton } from 'pages/Task/TaskForm/Eds/EdsButton';
import { useFieldBehaviour, fieldBehaviour } from 'pages/Task/TaskForm/FormFields/hooks';
import { Button as ButtonType } from './useFormButtonList';

import useStyles from '../useStyles';
import useButtonStyles from './useStyles';

type Props = {
  index: number;
  button: any;
  isIconButton?: boolean;
  icon?: string | React.ReactNode;
  selectedButton?: number;
  disabled?: boolean;
  isEDSButton?: (component: string) => boolean;
  onClick: (index: number, action: string, button: ButtonType) => void;
  taskDetailsVariant?: boolean;
  inFormVariant?: boolean;
  inFormVariantWithHint?: boolean;
  isVisible: boolean;
};

export const FormButton = memo(
  ({
    index,
    button,
    isIconButton = false,
    selectedButton,
    disabled,
    isEDSButton,
    onClick,
    taskDetailsVariant = false,
    inFormVariant = false,
    inFormVariantWithHint = false,
    isVisible,
  }: Props): ReactElement => {
    const { isActive }: fieldBehaviour = useFieldBehaviour(button);

    const { action, component, color, colorClass, variant, label, icon, componentParams } = button;

    const classes = useStyles();
    const buttonClasses = useButtonStyles();

    if (!isVisible) {
      return null;
    }

    if (isEDSButton(component)) {
      const {
        attachmentSigning,
        attachmentStorage,
        isInternalSign,
        isExternalSign,
        isInternalSignWithEmployee,
        isInternalSignEmployeeInitiator,
        binAttribute,
        companyAttribute,
        individualNumberAttribute,
        emailAttribute,
        supplierEmailAttribute,
        existingSupplierAttribute,
        supplierBinAttribute,
        icon,
      } = button;

      return (
        <EdsButton
          key={index}
          type="button"
          variant={variant}
          color={color}
          label={label}
          className={cn({
            [classes.taskDetailsFooterButton]: taskDetailsVariant,
            [classes.footerButton]: !taskDetailsVariant,
            [buttonClasses.buttonWhite]: colorClass === 'neutral',
          })}
          wrapperClassName={taskDetailsVariant ? classes.taskDetailsFooterEdsButtonWrapper : classes.footerEdsButtonWrapper}
          disabled={disabled || !isActive}
          userModelId={componentParams?.userModelId}
          attachmentName={attachmentSigning}
          attachmentStorage={attachmentStorage}
          binAttribute={binAttribute}
          companyAttribute={companyAttribute}
          individualNumberAttribute={individualNumberAttribute}
          emailAttribute={emailAttribute}
          supplierEmailAttribute={supplierEmailAttribute}
          existingSupplierAttribute={existingSupplierAttribute}
          supplierBinAttribute={supplierBinAttribute}
          isInternalSign={isInternalSign}
          isExternalSign={isExternalSign}
          isInternalSignWithEmployee={isInternalSignWithEmployee}
          isInternalSignEmployeeInitiator={isInternalSignEmployeeInitiator}
          onClick={() => onClick(index, action, button)}
          icon={icon}
        />
      );
    }

    return (
      <Tooltip
        title={button?.tooltip}
        disableHoverListener={!button?.tooltip || !isIconButton}
        disableTouchListener={!button?.tooltip || !isIconButton}
        disableFocusListener={!button?.tooltip || !isIconButton}
        classes={{ tooltip: buttonClasses.tooltip }}
        arrow
        placement="bottom"
      >
        <Button
          key={index}
          type="button"
          variant={variant}
          color={color}
          className={cn({
            [classes.taskDetailsFooterButton]: taskDetailsVariant,
            [classes.inFormButton]: inFormVariant,
            [classes.inFormButtonWithHint]: inFormVariantWithHint,
            [classes.footerButton]: !taskDetailsVariant && !inFormVariant && !inFormVariantWithHint,
            [buttonClasses.buttonWhite]: colorClass === 'neutral',
            [buttonClasses.iconButton]: isIconButton,
            [buttonClasses.normalButtonWithIcon]: !isIconButton && icon && typeof icon !== 'string',
          })}
          disabled={disabled || !isActive}
          onClick={() => onClick(index, action, button)}
        >
          {disabled && selectedButton === index ? (
            <Box display="flex" alignItems="center">
              <CircularProgress size={24} />
            </Box>
          ) : (
            <>
              {icon ? (
                typeof icon === 'string' ? (
                  <img
                    src={icon}
                    className={cn(buttonClasses.icon, {
                      [buttonClasses.iconTextVariant]: variant === 'text',
                    })}
                    alt=""
                  />
                ) : (
                  icon
                )
              ) : null}
              {!isIconButton && (
                <span
                  className={cn({
                    [buttonClasses.labelMargin]: icon,
                  })}
                >
                  {label}
                </span>
              )}
            </>
          )}
        </Button>
      </Tooltip>
    );
  }
);
