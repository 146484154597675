import { Modifier, EditorState, SelectionState } from 'draft-js';
import { UserType } from 'types';
import { TFunction } from 'i18next';

import PeopleIcon from 'assets/images/icons/people-all.svg';

export const ALL = 'All';

export const allUserPlug = (t: TFunction): UserType => {
  return {
    id: ALL,
    avatar: PeopleIcon,
    fullName: t('task_data_view.comments_tab.tag_all'),
    fullNameRu: ALL,
    firstName: ALL,
    lastName: ALL,
    logName: ALL,
    jobTitle: '',
    role: '',
    email: ALL,
    phoneNumber: '',
  };
};

export const replaceString = (editorState, template, newValue, replacedTextStyle = '') => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const block = contentState.getBlockForKey(selectionState.getAnchorKey());

  if (block.getText().indexOf(template) === -1 && !newValue?.length) {
    return editorState;
  }

  const selection = new SelectionState({
    anchorKey: block.getKey(),
    anchorOffset: block.getText().length,
    focusKey: block.getKey(),
    focusOffset: block.getText().length,
  });
  const focusedEditorState = EditorState.forceSelection(editorState, selection);

  const templateToEndMargin = block.getText().length - block.getText().indexOf(template);

  const currentSelectionState = focusedEditorState.getSelection();

  let newContentState;
  let editorStateWithReplacedText;
  if (template?.length) {
    // if we mention user with "@", replace template in text by newValue
    newContentState = Modifier.replaceText(
      contentState,
      // The text to replace, which is represented as a range with a start & end offset.
      selectionState.merge({
        // The starting position of the range to be replaced.
        anchorOffset: currentSelectionState.getEndOffset() - templateToEndMargin,
        // The end position of the range to be replaced.
        focusOffset: currentSelectionState.getEndOffset() - templateToEndMargin + template.length,
      }),
      // The new string to replace the old string.
      newValue
    );
    editorStateWithReplacedText = EditorState.push(focusedEditorState, newContentState, 'replace-text');
  } else {
    // if we mention user from toolbar's "@" button, template will be empty, we just append text to the end
    newContentState = Modifier.insertText(
      contentState,
      // The text to replace, which is represented as a range with a start & end offset.
      selectionState.merge({
        anchorOffset: currentSelectionState.getEndOffset(),
        focusOffset: currentSelectionState.getEndOffset(),
      }),
      newValue
    );
    editorStateWithReplacedText = EditorState.push(focusedEditorState, newContentState, 'insert-characters');
  }

  if (!replacedTextStyle) {
    // move cursor after replaced value
    const newStateSelection = editorStateWithReplacedText.getSelection();
    const newStateSelectionWithMovedCursor = newStateSelection.merge({
      anchorOffset: newStateSelection.getEndOffset(),
      focusOffset: newStateSelection.getEndOffset(),
    });

    return EditorState.forceSelection(editorStateWithReplacedText, newStateSelectionWithMovedCursor);
  }

  const newSelection = new SelectionState({
    anchorKey: block.getKey(),
    anchorOffset: block.getText().length,
    focusKey: block.getKey(),
    focusOffset: block.getText().length,
  });
  const newFocusedEditorState = EditorState.forceSelection(editorStateWithReplacedText, newSelection);
  const newSelectionState = newFocusedEditorState.getSelection();

  const cs = newFocusedEditorState.getCurrentContent();
  const newBlock = cs.getBlockForKey(selectionState.getAnchorKey());

  const newValueIndex = newBlock.getText().indexOf(newValue);

  // add passed style to replaced text

  const stateWithStyledReplacedText = Modifier.applyInlineStyle(
    newContentState,
    newSelectionState.merge({
      anchorOffset: newValueIndex,
      focusOffset: newValueIndex + newValue.trimRight().length,
    }),
    replacedTextStyle
  );

  const editorStateWithReplacedTextAndStyles = EditorState.push(editorStateWithReplacedText, stateWithStyledReplacedText, 'replace-text');

  // move cursor after replaced value
  const styledStateSelection = editorStateWithReplacedTextAndStyles.getSelection();
  const styledStateSelectionWithMovedCursor = styledStateSelection.merge({
    anchorOffset: styledStateSelection.getEndOffset() + 1,
    focusOffset: styledStateSelection.getEndOffset() + 1,
  });

  return EditorState.forceSelection(editorStateWithReplacedTextAndStyles, styledStateSelectionWithMovedCursor);
};

export const getSelectedBlock = (editorState) => {
  const selection = editorState?.getSelection();
  const contentState = editorState?.getCurrentContent();
  const blockStartKey = selection?.getStartKey();

  return contentState?.getBlockMap()?.get(blockStartKey);
};
