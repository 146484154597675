import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, Typography, Modal, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import EyeIcon from 'assets/images/icons/blue-eye.svg';

import useStyles from './StepPreviewSavingVariantSelectionDialog.useStyles';
import cn from 'classnames';

type Props = {
  isOpen: boolean,
  onClose: () => void;
  onConfirm: (savingType: SavingType) => void;
};

export type SavingType = 'current' | 'currentAndFuture';

export const StepPreviewSavingVariantSelectionDialog = (
  {
    isOpen,
    onClose,
    onConfirm
  }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [savingVariant, setSavingVariant] = useState<SavingType>('current');

  useEffect(() => {
    if (isOpen) {
      setSavingVariant('current');
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen}>
      <Box className={classes.modalBody}>
        <div className={classes.modalHeader}>
          <Typography className={classes.title}>
            {t('customProcesses.creationPage.stepPreviewSavingVariantSelectionDialog.title', { defaultValue: 'Apply Preview to' })}
          </Typography>

          <IconButton onClick={onClose}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>

        <div className={classes.modalContent}>
          <RadioGroup
            defaultValue={savingVariant}
            onChange={(_, value) => setSavingVariant(value as SavingType)}
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="current"
              control={<Radio
                className={cn(classes.radioButton, {
                  [classes.radioButtonChecked]: savingVariant === 'current'
                })}
              />}
              label={<span
                className={classes.radioOptionLabel}>{t('customProcesses.creationPage.processPreview.currentStep', 'Only this step')}</span>}
            />
            <FormControlLabel
              value="currentAndFuture"
              control={<Radio
                className={cn(classes.radioButton, {
                  [classes.radioButtonChecked]: savingVariant === 'currentAndFuture'
                })}
              />}
              label={
              <span className={classes.radioOptionLabel}>{t('customProcesses.creationPage.processPreview.currentAndFutureSteps', 'This and all following steps')}</span>}
            />
          </RadioGroup>

          {savingVariant === 'currentAndFuture' && <div className={classes.hiddenFieldsInfoBlock}>
            <img src={EyeIcon} alt={''} className={classes.hiddenFieldsInfoBlockIcon}/>
            <div>
              <p className={classes.hiddenFieldsInfoBlockTitle}>
                {t('customProcesses.creationPage.processPreview.hiddenFieldsInfoTitle')}
              </p>
              <p className={classes.hiddenFieldsInfoBlockMessage}>
                {t('customProcesses.creationPage.processPreview.hiddenFieldsInfoMessage')}
              </p>
            </div>
          </div>}
        </div>

        <div className={classes.modalFooter}>
          <Button color="secondary" variant="contained" onClick={onClose} className={classes.modalButton}>
            {t('comment_modal.button_cancel')}
          </Button>

          <Button
            color="primary"
            variant="contained"
            className={classes.modalButton}
            onClick={() => {
              onConfirm(savingVariant);
              onClose();
            }}>
            {t('customProcesses.documentFieldsLinkModal.buttons.apply')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};


