import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    marginBottom: spacing(4),
    '&:empty': {
      marginBottom: 0,
      minHeight: 0,
      height: 0,
    },
    '& label': {
      width: 'fit-content',
      maxWidth: '90%',
      color: '#6D6E85',
      fontWeight: 500,
      minHeight: 24,
    },
  },
  empty: {
    marginBottom: 0,
    minHeight: 0,
  },
  required: {
    '& label': {
      display: 'block',
      position: 'relative',
      overflow: 'visible',
      paddingRight: 0,
    },
    '& label::before': {
      content: '"*"',
      color: palette.error[600],
      fontSize: 14,
      fontWeight: 600,
      display: 'block',
      position: 'absolute',
      top: 0,
      right: -8,
    },
  },
  textFieldNoMargin: {
    marginTop: '0 !important',
  },
  requiredRadioButtonGroup: {
    '& > div > label:first-child': {
      position: 'relative',
      display: 'block',
      '&::before': {
        content: '"*"',
        color: palette.error[600],
        fontSize: 14,
        fontWeight: 600,
        display: 'block',
        position: 'absolute',
        top: 0,
        right: -8,
      },
    },
  },
  accessType: {
    marginBottom: spacing(3),
    minHeight: 0,
  },
  hiddenField: {
    visibility: 'hidden',
    minHeight: 0,
    maxHeight: 0,
    margin: 0,
    padding: 0,
  },
  taskDetailsVariant: {
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      '& p:first-child': {
        minWidth: 150,
        marginRight: 16,
        marginBottom: 4,
        color: '#6D6E85',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 500,
      },
      '& p:first-child:empty': {
        display: 'none',
      },
    },
  },
  taskDetailsVariantAccessType: {
    '& div:nth-child(2)': {
      display: 'flex',
      flexDirection: 'row',
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      marginRight: 16,
      '& p:first-child': {
        color: palette.grey[600],
      },
    },
  },
  taskDetailsVariantReadonlyList: {
    '& ul li': {
      paddingLeft: 0,
    },
  },
  taskDetailsReadOnly: {
    color: `${palette.grey[700]} !important`,
    '& span': {
      whiteSpace: 'pre-wrap',
    },
  },
  taskDetailsVariantRadioButtonGroup: {
    '& div': {
      flexDirection: 'column',
    },
  },
  entityCardVariant: {
    '& div': {
      display: 'flex',
      '& p:first-child': {
        width: 125,
        minWidth: 125,
        maxWidth: 125,
        marginRight: 16,
        color: palette.grey[600],
      },
      '& p:first-child:empty': {
        display: 'none',
      },
    },
  },
  entityCardVariantAccessType: {
    '& div:nth-child(2)': {
      display: 'flex',
      flexDirection: 'row',
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      marginRight: 16,
      '& p:first-child': {
        color: palette.grey[600],
      },
    },
  },
  entityCardVariantReadonlyList: {
    '& ul li': {
      paddingLeft: 0,
    },
  },
  entityCardVariantReadOnly: {
    color: `${palette.grey[700]} !important`,
  },
  entityCardVariantRadioButtonGroup: {
    '& div': {
      flexDirection: 'column',
    },
  },

  partialWidth: {
    float: 'left',
    marginRight: 12,
  },

  'col-1': {
    width: 'calc(8.33% - 12px)',
  },
  'col-2': {
    width: 'calc(16.67% - 12px)',
  },
  'col-3': {
    width: 'calc(25% - 12px)',
  },
  'col-4': {
    width: 'calc(33.32% - 12px)',
  },
  'col-5': {
    width: 'calc(41.65% - 12px)',
  },
  'col-6': {
    width: 'calc(50% - 12px)',
  },
  'col-7': {
    width: 'calc(58.31% - 12px)',
  },
  'col-8': {
    width: 'calc(66.64% - 12px)',
  },
  'col-9': {
    width: 'calc(75% - 12px)',
  },
  'col-10': {
    width: 'calc(83.3% - 12px)',
  },
  'col-11': {
    width: 'calc(91.63% - 12px)',
  },
  'col-12': {
    width: '100%',
  },
  emptyValueMessage: {
    fontSize: 14,
    color: '#8B8C9E',
    fontStyle: 'normal',
    fontWeight: 400,
  }
}));

export default useStyles;
