import { createMuiTheme } from '@material-ui/core/styles';
import { theme } from '@dartech/dms-ui';

// TODO: Update whole primary color in theme
const newPrimary = '#039BE6';
const newPrimaryHover = '#0692D9';
const newPrimaryActive = '#088DD2';

const customTheme = createMuiTheme(theme, {
  overrides: {
    MuiAutocomplete: {
      root: {
        width: 'auto'
      }
    },
    MuiButton: {
      /* Styles applied to the root element. */
      root: {
        boxSizing: 'border-box',
        // minWidth: 64,
        // maxWidth: 384,
        margin: 0,
        height: 32,
        borderRadius: 10,
        outline: 'none',
        padding: theme.spacing(1, 3),
        textTransform: 'none',
        fontFamily: theme.typography.button.fontFamily,
        fontSize: theme.typography.button.fontSize,
        lineHeight: '24px',
        '&.MuiButton-containedSecondary path': {
          fill: 'inherit',
        },
      },
      /* Styles applied to the root element if `size="small"` and `variant="contained"`. */
      sizeSmall: {
        height: 24,
        padding: theme.spacing(0.5, 2),
        fontSize: '12px',
        lineHeight: '18px',
      },
      /* Styles applied to the root element if `size="large"` and `variant="contained"`. */
      sizeLarge: {
        height: 40,
        padding: theme.spacing(2, 4),
        fontSize: theme.typography.button.fontSize,
        lineHeight: '24px',
      },
      /* Styles applied to the root element if `color="inherit"`. */
      colorInherit: {
        color: theme.palette.common.white,
        backgroundColor: newPrimary,
        '& path': {
          fill: theme.palette.common.white,
        },
        '&:hover': {
          backgroundColor: newPrimaryHover,
          borderColor: newPrimaryHover,
          color: theme.palette.common.white,
          '& path': {
            fill: theme.palette.common.white,
          },
        },
        '&:active': {
          backgroundColor: newPrimaryActive,
          borderColor: newPrimaryActive,
          color: theme.palette.common.white,
          '& svg path': {
            fill: theme.palette.common.white,
          },
        },
        '&$disabled': {
          backgroundColor: theme.palette.grey[300],
          borderColor: theme.palette.grey[300],
          color: theme.palette.common.white,
          '& path': {
            fill: theme.palette.common.white,
          },
        },
      },
      /* Styles applied to the root element if `variant="contained"`. */
      contained: {
        color: theme.palette.common.white,
        backgroundColor: newPrimary,
        borderColor: newPrimary,
        '& path': {
          fill: theme.palette.common.white,
        },
        '&:hover': {
          backgroundColor: newPrimaryHover,
          borderColor: newPrimaryHover,
        },
        '&:active': {
          backgroundColor: newPrimaryActive,
          borderColor: newPrimaryActive,
        },
        '&$disabled': {
          backgroundColor: theme.palette.grey[300],
          borderColor: theme.palette.grey[300],
          color: theme.palette.common.white,
        },
      },
      /* Styles applied to the root element if `variant="contained"` and `color="primary"`. */
      containedPrimary: {
        color: theme.palette.common.white,
        backgroundColor: newPrimary,
        border: `1px solid ${newPrimary}`,
        '& path': {
          fill: theme.palette.common.white,
        },
        '&:hover': {
          backgroundColor: newPrimaryHover,
          borderColor: newPrimaryHover,
        },
        '&:active': {
          backgroundColor: newPrimaryActive,
          borderColor: newPrimaryActive,
        },
        '&$disabled': {
          backgroundColor: theme.palette.grey[300],
          borderColor: theme.palette.grey[300],
          color: theme.palette.common.white,
        },
      },
      /* Styles applied to the root element if `variant="contained"` and `color="secondary"`. */
      containedSecondary: {
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.grey[50],
        border: `1px solid ${theme.palette.grey[300]}`,
        borderColor: theme.palette.grey[300],
        '& path': {
          fill: theme.palette.common.black,
        },
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
          borderColor: theme.palette.grey[300],
        },
        '&:active': {
          backgroundColor: theme.palette.grey[200],
          borderColor: theme.palette.grey[300],
        },
        '&$disabled': {
          color: theme.palette.grey[400],
          backgroundColor: theme.palette.grey[50],
          '& path': {
            fill: theme.palette.grey[400],
          },
        },
      },
      /* Styles applied to the root element if `variant="text"`. */
      text: {
        color: theme.palette.primary[200],
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        borderColor: 'transparent',
        '& path': {
          fill: theme.palette.primary[200],
        },
        '&:hover': {
          color: theme.palette.primary[300],
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          '& path': {
            fill: theme.palette.primary[300],
          },
        },
        '&:active': {
          color: theme.palette.primary[200],
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          '& svg path': {
            fill: theme.palette.primary[200],
          },
        },
        '&$disabled': {
          color: theme.palette.grey[400],
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          '& path': {
            fill: theme.palette.grey[400],
          },
        },
      },
      /* Styles applied to the root element if `variant="text"` and `color="primary"`. */
      textPrimary: {
        color: theme.palette.primary[200],
        backgroundColor: 'transparent',
        '& path': {
          fill: theme.palette.primary[200],
        },
        '&:hover': {
          color: theme.palette.primary[300],
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          '& path': {
            fill: theme.palette.primary[300],
          },
        },
        '&:active': {
          color: theme.palette.primary[200],
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          '& svg path': {
            fill: theme.palette.primary[200],
          },
        },
        '&$disabled': {
          color: theme.palette.grey[400],
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          '& path': {
            fill: theme.palette.grey[400],
          },
        },
      },
      /* Styles applied to the root element if `variant="text"` and `color="secondary"`. */
      textSecondary: {
        color: theme.palette.grey[700],
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        borderColor: 'transparent',
        '& path': {
          fill: theme.palette.common.black,
        },
        '&:hover': {
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.grey[100],
          borderColor: theme.palette.grey[100],
          '& path': {
            fill: theme.palette.common.black,
          },
        },
        '&:active': {
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.grey[200],
          borderColor: theme.palette.grey[200],
          '& svg path': {
            fill: theme.palette.common.black,
          },
        },
        '&$disabled': {
          color: theme.palette.grey[400],
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          '& path': {
            fill: theme.palette.grey[400],
          },
        },
      },
      /* Styles applied to the root element if `variant="outlined"`. */
      outlined: {
        color: newPrimary,
        backgroundColor: 'transparent',
        border: `1px solid ${newPrimary}`,
        borderColor: newPrimary,
        '& path': {
          fill: newPrimary,
        },
        '&:hover': {
          color: theme.palette.common.white,
          backgroundColor: newPrimaryHover,
          borderColor: newPrimaryHover,
          '& path': {
            fill: theme.palette.common.white,
          },
        },
        '&:active': {
          color: theme.palette.common.white,
          backgroundColor: newPrimaryActive,
          borderColor: newPrimaryActive,
          '& path': {
            fill: theme.palette.common.white,
          },
        },
        '&$disabled': {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.grey[300],
          borderColor: theme.palette.grey[300],
          '& path': {
            fill: theme.palette.common.white,
          },
        },
      },
      /* Styles applied to the root element if `variant="outlined"` and `color="primary"`. */
      outlinedPrimary: {
        color: newPrimary,
        backgroundColor: 'transparent',
        border: `1px solid ${newPrimary}`,
        borderColor: newPrimary,
        '& path': {
          fill: newPrimary,
        },
        '&:hover': {
          color: theme.palette.common.white,
          backgroundColor: newPrimaryHover,
          borderColor: newPrimaryHover,
          '& path': {
            fill: theme.palette.common.white,
          },
        },
        '&:active': {
          color: theme.palette.common.white,
          backgroundColor: newPrimaryActive,
          borderColor: newPrimaryActive,
          '& path': {
            fill: theme.palette.common.white,
          },
        },
        '&$disabled': {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.grey[300],
          borderColor: theme.palette.grey[300],
          '& path': {
            fill: theme.palette.common.white,
          },
        },
      },
      /* Styles applied to the root element if `variant="outlined"` and `color="secondary"`. */
      outlinedSecondary: {
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.grey[50],
        border: `1px solid ${theme.palette.grey[300]}`,
        borderColor: theme.palette.grey[300],
        '& path': {
          fill: theme.palette.common.black,
        },
        '&:hover': {
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.grey[100],
          borderColor: theme.palette.grey[300],
          '& path': {
            fill: theme.palette.common.black,
          },
        },
        '&:active': {
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.grey[200],
          borderColor: theme.palette.grey[300],
          '& path': {
            fill: theme.palette.common.black,
          },
        },
        '&$disabled': {
          color: theme.palette.grey[400],
          backgroundColor: theme.palette.grey[50],
          borderColor: theme.palette.grey[300],
          '& path': {
            fill: theme.palette.grey[400],
          },
        },
      },
      /* Styles applied to the startIcon element if supplied. */
      startIcon: {
        display: 'inherit',
        marginRight: 8,
        marginLeft: 0,
        '&$iconSizeSmall': {
          marginLeft: 0,
        },
      },
      /* Styles applied to the endIcon element if supplied. */
      endIcon: {
        display: 'inherit',
        marginRight: 0,
        marginLeft: 8,
        '&$iconSizeSmall': {
          marginRight: 0,
        },
      },
    },

    MuiInputLabel: {
      root: {
        background: '#fff',
        padding: '0 4px',
        marginLeft: '-4px'
      }
    },
    MuiIconButton: {
      root: {
        //fix incorrect position of circle in toggle (moved to bottom right corner)
        '&:not(.MuiRadio-root):not(.MuiCheckbox-root):not(.MuiSwitch-switchBase):not(.MuiAutocomplete-clearIndicator)': {
          padding: 0
        }
      }
    },
    MuiSvgIcon: {
      root: {
        '&:not(.MuiRadio-root):not(.MuiCheckbox-root):not(.MuiSwitch-switchBase):not(.MuiAutocomplete-clearIndicator)': {
          '& path[fill="none"]': {
            fill: 'none !important'
          }
        }
      }
    },
    MuiSwitch: {
      root: {
        marginRight: 6
      }
    },
    MuiFormControlLabel: {
      root: {
        '& .MuiSwitch-root': {
          marginRight: 6
        }
      },
      labelPlacementStart: {
        marginLeft: 0,
        '& .MuiSwitch-root': {
          marginLeft: 6,
          marginRight: 0
        }
      }
    },
    MuiPickersDay: {
      dayDisabled: {
        color: '#8A96A1 !important'
      }
    },
    MuiTypography: {
      body1: {
        fontFamily: 'inherit'
      }
    },
    MuiTab: {
      root: {
        fontFamily: 'inherit'
      }
    },
    // TODO: remove MuiCircularProgress styles, when @dartech/dms-ui is updated to 1.31.5 or higher version
    MuiCircularProgress: {
      root: {
        color: '#8B8C9E'
      },
      colorPrimary: {
        color: '#8B8C9E'
      }
    }
  },
  zIndex: {
    modal: 1200,
  }
});

export default customTheme;
