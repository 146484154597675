import React, { memo, useMemo } from 'react';
import { Box, List, ListItem } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';

import { CustomPagination, ErrorMessage, Spinner, Ticket } from 'components';
import { TaskRequestType } from 'types';
import { SearchNoResults } from '../SearchNoResults';

// TODO: Доделать props
type Props = {
  searchText: string,
  requests: any,
  totalPages: any,
  error: any,
  loading: any,
  pageSize: any,
  currentPage: any,
  selectedRequest: any,
  handlePageChange: any,
  handleTaskSelection: any,
  handlePageSizeChange: any
}

export const SearchList = memo(({
  searchText,
  requests,
  totalPages,
  error,
  loading,
  pageSize,
  currentPage,
  selectedRequest,
  handlePageChange,
  handleTaskSelection,
  handlePageSizeChange,
}: Props) => {
  const listContent = useMemo(() => {
    if (!requests || !requests.length) {
      return null;
    }

    return (
      <Box paddingRight={3}>
        <List>
          {requests.map((request: TaskRequestType) => (
            <ListItem
              key={request.businessTask?.taskId}
              style={{ width: 'calc(100% - 12px)' }}
              disableGutters
            >
              <Ticket
                type="myRequest"
                request={request}
                active={selectedRequest === request.businessTask?.taskId}
                onClick={() => handleTaskSelection(request.businessTask?.taskId)}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }, [requests, selectedRequest]);

  if (loading) {
    return (
      <Spinner absolute />
    )
  }

  if (error) {
    return (
      <ErrorMessage text={error} />
    );
  }

  if (searchText.length === 0) {
    return null;
  }

  if (Array.isArray(requests) && requests.length) {
    return (
      <>
        <Scrollbars
          style={{
            width: '100%',
            height: 'calc(100vh - 255px)',
            paddingRight: 12,
            marginTop: 4,
          }}
        >
          {listContent}
        </Scrollbars>

        <CustomPagination
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
        />
      </>
    );
  }

  if (Array.isArray(requests)) {
    return (
      <SearchNoResults />
    )
  }

  return null;
});
