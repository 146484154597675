import React, { Dispatch, SetStateAction, useState, useMemo } from 'react';

import cn from 'classnames';
import { theme } from '@dartech/dms-ui';
import { ThemeProvider } from 'styled-components';

import AttachmentsUpload from './attachments-upload';
import AttachmentsList from './attachments-list';

import { AttachmentItemType } from './index';

import { Styled } from './attachments.styles';
import { useTranslation } from 'react-i18next';
import { VariantSelect } from './attachments-list/attachments-list.component';

const FILE_TOO_BIG_ERROR = 'form_components.attachment.errors.file_too_big';
const FILE_TYPE_NOT_ALLOWED = 'form_components.attachment.errors.unsupported_file_format';

export interface AttachmentsProps {
  variant?: VariantSelect;
  attachments?: AttachmentItemType[];
  error?: string;
  readOnly?: boolean;
  preventFileSelectOpen?: boolean;
  dynamicCompactVariantHeight?: boolean;
  attachmentsOnRightSide?: boolean;
  showAllowedFileTypes?: boolean;
  allowedFileTypes?: string[];
  allowedFileMimeTypes?: string[];
  maximumFileSize?: number;
  fileNumberLimit?: number;
  setError?: Dispatch<SetStateAction<string>> | undefined;
  onFileSelect?: (file: File) => void | undefined;
  onClick?: (attachment: AttachmentItemType) => void | undefined;
  onDelete?: (attachment: any) => void | undefined;
  onUndo?: (attachment: any) => void | undefined;
}

const Attachments: React.FC<AttachmentsProps> = ({
  variant = VariantSelect.Big,
  error = '',
  attachments = [],
  readOnly = false,
  preventFileSelectOpen = false,
  dynamicCompactVariantHeight = false,
  attachmentsOnRightSide = false,
  allowedFileTypes = [],
  allowedFileMimeTypes = [],
  showAllowedFileTypes = true,
  maximumFileSize = 50,
  fileNumberLimit = 0,
  setError = undefined,
  onFileSelect = undefined,
  onClick = undefined,
  onDelete = undefined,
  onUndo = undefined,
}) => {
  const { t } = useTranslation();
  const [isFilesLimitReached, setFilesLimitReached] = useState(false);

  const activeAttachmentsCount = useMemo(() =>
    attachments.filter((a) => !a.isDeleted).length,
    [attachments]
  );

  const dragOver = (e: any) => {
    e.preventDefault();
  };

  const dragEnter = (e: any) => {
    e.preventDefault();
  };

  const dragLeave = (e: any) => {
    e.preventDefault();
  };

  const fileDrop = (e: any) => {
    if (preventFileSelectOpen) {
      return;
    }
    e.preventDefault();
    const files = e.dataTransfer.files;

    files.length && handleDragAndDropFiles(files);
  };

  const validateFile = (file: File) => {
    const k = 1000;

    if (maximumFileSize && file.size > maximumFileSize * k * k) {
      setError(t(FILE_TOO_BIG_ERROR));
      return false;
    }

    if (!allowedFileMimeTypes.includes(file.type)) {
      setError(t(FILE_TYPE_NOT_ALLOWED));
      return false;
    }

    if (fileNumberLimit && activeAttachmentsCount + 1 >= fileNumberLimit) {
      setFilesLimitReached(true);
      return activeAttachmentsCount + 1 === fileNumberLimit;
    }

    setFilesLimitReached(false);
    return true;
  };

  const handleDragAndDropFiles = (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        handleSelectFile(files[i]);
      }
    }
  };

  const handleSelectFile = (selectedFile: File) => {
    if (!validateFile(selectedFile)) {
      return;
    }

    onFileSelect && onFileSelect(selectedFile);
  };

  const handleFileDelete = (attachment: any) => {
    setFilesLimitReached(false);

    onDelete && onDelete(attachment);
  };

  return (
    <ThemeProvider theme={theme}>
      <Styled.Attachments
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
      >
        {readOnly ? (
          <AttachmentsList
            attachments={attachments}
            showWithoutScroll
            readOnly
            rightSideAttachments={attachmentsOnRightSide}
            variant={variant}
            onClick={onClick}
          />
        ) : (
          <>
            <div
              className={cn('AttachmentsWrapper', {
                AttachmentsWrapperWide:
                  variant === 'big' && activeAttachmentsCount > 2,
                AttachmentsWrapperEmpty:
                  variant === 'big' && !activeAttachmentsCount,
                AttachmentsWithError: error,
                AttachmentsBig: variant === 'big',
                AttachmentsCompact: variant === 'compact',
                AttachmentsCompactDynamicHeight: variant === 'compact' && dynamicCompactVariantHeight,
                AttachmentsCompactDashed: variant === 'compact-dashed',
              })}
            >

              <AttachmentsUpload
                variant={variant}
                attachmentsCount={attachments.length}
                activeAttachmentsCount={activeAttachmentsCount}
                error={error}
                allowedFileTypes={allowedFileTypes}
                showAllowedFileTypes={showAllowedFileTypes}
                dynamicCompactVariantHeight={dynamicCompactVariantHeight}
                maximumFileSize={maximumFileSize}
                isFilesLimitReached={isFilesLimitReached}
                onFileSelect={handleSelectFile}
                preventFileSelectOpen={preventFileSelectOpen}
              />

              {error && (variant === 'compact') && (
                <div className="AttachmentsError">{error}</div>
              )}

              {!(variant === VariantSelect.Big && !activeAttachmentsCount) ? (
                <AttachmentsList
                  attachments={attachments}
                  variant={variant}
                  onClick={onClick}
                  onDelete={handleFileDelete}
                  onUndo={onUndo}
                />
              ) : null}

            </div>
            {error &&
              (variant === 'big') &&
              !!activeAttachmentsCount && (
                <div className="AttachmentsError">{error}</div>
              )}

            {error && variant === 'compact-dashed' && (
              <div className="CompactDashedAttachmentsError">{error}</div>
            )}
          </>
        )}
      </Styled.Attachments>
    </ThemeProvider>
  );
};

export default Attachments;
