import { makeStyles } from '@material-ui/core';

type StylesProps = {
  isPassedStep: boolean;
}

export default makeStyles(({ spacing, palette }) => ({
  historyItemWrapper: {
    position: 'relative',
    marginTop: 10,
    marginLeft: spacing(6),
    paddingRight: spacing(6),
    // '&:first-child': {
    //   marginTop: spacing(4),
    // },
  },
  timelinePart: {
    position: 'absolute',
    left: -26,
    top: 5,
    height: '100%',
  },
  timelinePoint: {
    width: 15,
    height: 19,
    display: 'flex',
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    zIndex: 2,
    '& img': {
      width: 15,
      marginTop: 2,
      marginBottom: -2,
    },
  },
  timelineLine: {
    height: '100%',
    width: 1,
    background: ({ isPassedStep }: StylesProps) =>
      isPassedStep
      ? '#007994'
      : 'repeating-linear-gradient(to bottom, transparent, transparent 5px, #A9A9B8 5px, #A9A9B8 10px)',
    position: 'absolute',
    top: 10,
    left: 7,
    marginTop: 4,
  },
  groupWrapper: {
    marginLeft: -8,
    marginTop: -10,
    marginBottom: 16,
    width: 'calc(100% + 8px)',
    border: '1px solid rgba(38, 40, 66, 0.12)',
    padding: 0,
    borderRadius: 8,
    background: '#F0F0F7',
  },
  groupWrapperFutureStep: {
    '& p, & h6, & span': {
      color: '#A9A9B8 !important',
    },
  },
  groupedStepsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 8,
    paddingRight: 0,
    gap: 16,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 6,
      width: 8,
      height: 25,
      background: '#F0F0F7',
      zIndex: 15,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 6,
      width: 8,
      height: 8,
      background: '#F0F0F7',
      zIndex: 15
    }
  },
  stepsGroupInfo: {
    width: '100%',
    padding: 8,
    borderBottom: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  stepsGroupTitle: {
    margin: 0,
    padding: 0,
    marginBottom: 4,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#262842',
  },
  stepsGroupBranchesCount: {
    margin: 0,
    padding: 0,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#262842',
  },
  branchWrapper: {
    marginLeft: 24,
    width: 'calc(100% - 32px)',
    border: '1px solid rgba(38, 40, 66, 0.12)',
    background: '#FFFFFF',
    padding: 8,
    borderRadius: 8,
  },
  branchesLine: {
    height: '100%',
    width: 1,
    background: '#A9A9B8',
    position: 'absolute',
    top: 0,
    left: 8,
  }
}));
