import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { UseFormReturn } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useTaskState } from 'store/requests';

import { CustomSnackbar, Spinner } from 'components';

import { HistoryTab, CommentsTab, FormTab } from '../Tabs';

import useStyles from './useStyles';

import { ATTACHMENTS_TAB_NAME, HISTORY_TAB_NAME, INFORMATION_TAB_NAME } from '../Tabs/FormTab/constants';

import { forceTranslate } from './forceTranslate';
import { DocumentsTab } from '../Tabs/DocumentsTab';
import { UserType } from 'types';
import { useHoldingTranslationsLoadedState } from '../../../../store/main';

type Props = {
  activeTab: string;
  isCurrentUserWatcher: boolean;
  isCurrentUserAssignee: boolean;
  handleWatchersSelect: (selectedWatchersIdList: string[], selectedWatcherObjectList: UserType[]) => void;
  setActiveTab: (tabName: string) => void;
  setTabCounter: (tabName: string, value: number | null) => void;
  tabsList: any[];
  scrollbarsRef?: any;
  setSteps?: (steps: any[]) => void;
  formMethods: UseFormReturn<any, any>;
  setButtonDisabled: (value: boolean) => void;
  setAlwaysActiveButtons: (value: string[]) => void;
  handleAction: any;
  formError: string;
  taskFormLoading: boolean;
  isValidationErrorSnackbarOpen: boolean;
  setValidationErrorSnackbarOpen: (value: boolean) => void;
  formErrorsRefresh: boolean;
  checkValidationErrors?: () => boolean;
  disableRenderInformationTab?: boolean;
  isFormTabOnly?: boolean;
  setErrorMessageLines?: (value: ('fields' | 'documents')[]) => void;
};

export const DataViewContent = (
  {
    activeTab,
    isCurrentUserWatcher = false,
    isCurrentUserAssignee = false,
    handleWatchersSelect,
    setActiveTab,
    setTabCounter,
    tabsList,
    scrollbarsRef = null,
    setSteps,
    setButtonDisabled,
    setAlwaysActiveButtons,
    formMethods,
    formError,
    taskFormLoading,
    handleAction,
    isValidationErrorSnackbarOpen,
    setValidationErrorSnackbarOpen,
    formErrorsRefresh,
    checkValidationErrors,
    setErrorMessageLines,
    disableRenderInformationTab,
    isFormTabOnly = false
  }: Props) => {
  const { t } = useTranslation();

  const [stepDescription, setStepDescription] = useState(null);

  const { data: bpmTask, componentsDataLoadedTable, loading } = useTaskState();
  const holdingTranslationsLoaded = useHoldingTranslationsLoadedState();
  const classes = useStyles();

  const isComponentsDataLoading: boolean = useMemo((): boolean =>
      Object.values(componentsDataLoadedTable).some(isLoading => isLoading),
    [componentsDataLoadedTable]);

  const getStepDescription = async () => {
    const currentStepSysName = bpmTask.currentAction.sysName;
    if (!currentStepSysName) return;
    const translationKey = `constructor-${bpmTask.processSysName}.actions.${currentStepSysName}.description`;
    const description = t(translationKey);

    // value exists
    if (description !== translationKey) {
      setStepDescription(description);

      return;
    }

    const forcedTranslatedDescr = forceTranslate(translationKey);

    if (forcedTranslatedDescr) {
      setStepDescription(forcedTranslatedDescr);
    }
  };


  const companyId = useMemo(() =>
      bpmTask?.businessTask?.companyId || '',
    [bpmTask]);


  const tabContent = useMemo(() => {
    if (loading || !bpmTask) {
      return <Spinner />;
    }

    // рендерить все вкладки, оставляя видимой только активную,
    // чтобы при переходе между вкладками данные не сбрасывались
    // (например, введенный текст комментария)
    return (
      <>
        {!isFormTabOnly &&
          <>
            <HistoryTab
              className={cn({
                  [classes.hiddenTabContent]: activeTab !== 'history'
                }
              )}
              companyId={companyId}
              activeTab={activeTab}
              setSteps={setSteps}
            />

            <CommentsTab
              className={cn({
                [classes.hiddenTabContent]: activeTab !== 'comments'
              })}
              companyId={companyId}
              activeTab={activeTab}
              setTabCounter={setTabCounter}
              tabsList={tabsList}
              mainScrollbarsRef={scrollbarsRef}
            />

            <DocumentsTab
              className={cn({
                [classes.hiddenTabContent]: activeTab !== 'documents'
              })}
              activeTab={activeTab}
              formMethods={formMethods}
              checkValidationErrors={checkValidationErrors}
              setErrorMessageLines={setErrorMessageLines}
            />
          </>
        }

        {(!disableRenderInformationTab || (disableRenderInformationTab && activeTab === ATTACHMENTS_TAB_NAME)) && (
          <FormTab
            className={cn({
              [classes.hiddenTabContent]: ['history', 'comments', 'documents'].includes(
                activeTab
              )
            })}
            isCurrentUserWatcher={isCurrentUserWatcher}
            isCurrentUserAssignee={isCurrentUserAssignee}
            handleWatchersSelect={handleWatchersSelect}
            tabName={activeTab}
            setButtonDisabled={setButtonDisabled}
            setAlwaysActiveButtons={setAlwaysActiveButtons}
            setActiveTab={setActiveTab}
            setTabCounter={setTabCounter}
            handleAction={handleAction}
            stepDescription={stepDescription}
            disableRenderInformationTab={disableRenderInformationTab}
          />
        )
        }

      </>
    );
  }, [
    activeTab,
    loading,
    bpmTask,
    formMethods,
    tabsList,
    setTabCounter,
    scrollbarsRef,
    stepDescription,
    disableRenderInformationTab,
    checkValidationErrors,
    setErrorMessageLines
  ]);

  useEffect(() => {
    setValidationErrorSnackbarOpen(!isEmpty(formMethods.formState.errors) || formErrorsRefresh);
  }, [formMethods.formState.errors, formErrorsRefresh]);

  useEffect(() => {
    getStepDescription();
  }, [holdingTranslationsLoaded]);

  return (
    <>
      {taskFormLoading && <Spinner />}

      <Box className={cn(classes.tabWrapper, {
        [classes.tabContentWrapperHistoryTab]: activeTab === HISTORY_TAB_NAME,
        [classes.tabContentWrapperInformationTab]: activeTab === INFORMATION_TAB_NAME
      })}>
        <div
          style={{
            height: '100%',
            ...(activeTab !== INFORMATION_TAB_NAME && {
              background: '#F7F8FC',
              paddingBottom: 20
            }),
            ...(activeTab === HISTORY_TAB_NAME && {
              background: 'white',
              borderRadius: 12,
              border: '1px solid rgba(38, 40, 66, 0.12)',
              overflowY: 'auto'
            }),
            ...(activeTab === INFORMATION_TAB_NAME && {
              overflowY: 'auto'
            })
          }}
        >
          <Box
            className={cn(classes.tabContentWrapper, {
              [classes.tabContentWrapperWithValidationError]: isValidationErrorSnackbarOpen,
              [classes.tabContentWrapperAttachmentsTab]: activeTab === ATTACHMENTS_TAB_NAME
            })}
          >
            {tabContent}
          </Box>
        </div>
      </Box>

      <Box className={cn(classes.dataViewSnackbar, { [classes.isOpen]: isComponentsDataLoading })}>
        <CustomSnackbar />
      </Box>

      {formError && (
        <Typography color="error" className={classes.errorMessage}>
          {t(formError)}
        </Typography>
      )}
    </>
  );
};
