import { makeStyles } from '@material-ui/core';

type Props = {
  isModalVariant: boolean;
}

export default makeStyles(({ spacing, palette }) => ({
  documentsList: {
    borderTop: '1px solid rgba(38, 40, 66, 0.12)',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    flexGrow: 1,
    width: ({ isModalVariant }: Props) => isModalVariant ? '100%' : 'calc(100% + 48px)',
    marginLeft: ({ isModalVariant }: Props) => isModalVariant ? 0 : -24,
    padding: '16px 24px',
  },
  documentsListDraggableItem: {
    width: '100%'
  },
  departmentDocumentsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  departmentName: {
    margin: 0,
    padding: 0,
    color: '#262842',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
  },
  typeName: {
    margin: 0,
    padding: 0,
    color: '#6D6E85',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
  },
  typeDocumentsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  emptyMessageWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 148,
  },
  emptyMessageIconWrapper: {
    width: 100,
    height: 100,
    borderRadius: 50,
    background: '#F0F0F7',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
  },
  emptyMessageIcon: {
    width: 64,
    height: 64,
  },
  emptyMessageTitle: {
    maxWidth: '80%',
    marginBottom: 4,
    color: '#6D6E85',
    textAlign: 'center',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
  },
  emptyMessageText: {
    maxWidth: '80%',
    color: '#6D6E85',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    '& span': {
      color: '#262842'
    }
  },
}));
