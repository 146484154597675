import { makeStyles } from '@material-ui/core/styles';

type Props = {
  visiblePanel: boolean;
  hasMenuButtons: boolean;
}

export default makeStyles((theme) => ({
  requestsListLayout: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    paddingTop: 12,
  },
  requestsListWrapper: {
    paddingLeft: 24,
    paddingRight: 24,
    flexGrow: 1,
    maxHeight: ({visiblePanel, hasMenuButtons}: Props) =>
      hasMenuButtons
      ? visiblePanel
        ? 'calc(100vh - 420px)'
        : 'calc(100vh - 350px)'
      : 'calc(100vh - 298px)',
    overflowY: 'scroll',
  },
  footerWrapper: {
    paddingLeft: 24,
    paddingRight: 24,
  }
}));
