import React, { memo, ReactElement } from 'react';
import {
  Button,
  DialogContent,
  Typography,
  DialogActions,
  Dialog,
  Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type CreationDialogProps = {
  open: boolean;
  onCancel: () => void;
  onStart: () => Promise<void>;
  processName: string;
  templateName: string;
};

export const CreationDialog = memo(
  ({
    open = false,
    onCancel,
    onStart,
    processName,
    templateName,
  }: CreationDialogProps): ReactElement => {
    const { t } = useTranslation();

    return (
    <Dialog open={open} onClose={onCancel} maxWidth="xs" fullWidth>
      <Box
        component="header"
        bgcolor="primary.700"
        color="common.white"
        py={4}
        px={6}
        display="flex"
        justifyContent="space-between"
      >
        <Typography variant="h4">{t('template_start_modal.title')}</Typography>
      </Box>

      <DialogContent>
        <Box py={6}>
          <Typography>
            {t('template_start_modal.template_creation_text1')}<strong>{processName}</strong>{t('template_start_modal.template_creation_text2')}<strong>{templateName}</strong>{t('template_start_modal.template_creation_text3')}
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onStart}>
          {t('template_start_modal.button_start')}
        </Button>
        <Button color={'secondary'} onClick={onCancel}>
          {t('template_start_modal.button_cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
});
