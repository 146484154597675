import React, { useMemo, ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab, Badge } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { counter$ } from '@dar-dms/topbar';

import { useUsersRole } from 'hooks';
import { routesList } from 'router';

import useStyles from './useStyles';
import cn from 'classnames';

export const MainTabs = (): ReactElement => {
  const classes = useStyles();

  const location = useLocation();
  const { t } = useTranslation();
  const [counters, setCounters] = useState({approvals: 0, requests: 0, watchers: 0});

  const { isACLUserHasAccessToSettings, isTemplateProcessPageAvailable, isRegisterExportAvailable, isUserAdmin, isTemplatePageAvailable } = useUsersRole();

  const activeValue = '/' + location.pathname.split('/')[1];

  useEffect(() => {
    const subscription = counter$.subscribe((v) => {
      setCounters({
        approvals: v?.myapprovals || 0,
        requests: v?.myrequests || 0,
        watchers: v?.watchers || 0,
      });
    });
    return () => {
      subscription?.unsubscribe?.();
    };
  }, []);

  const isWideTabs = useMemo(() => {
    switch (location.pathname) {
      case "/requests/active":
      case "/requests/completed":
      case "/requests/drafts":
      case "/template-processes":
        return false
      default:
        return true
    }
  }, [location.pathname]);

  const isRequestsPage = useMemo(() => {
    switch (location.pathname) {
      case "/requests/active":
      case "/requests/completed":
      case "/requests/drafts":
        return true
      default:
        return false
    }
  }, [location.pathname]);

  const menuList = useMemo(() => [
    {
      ...routesList(t).approvals,
      visible: true,
      badgeCounter: counters?.approvals,
    },
    {
      ...routesList(t).requests,
      visible: true,
      badgeCounter: counters?.requests,
    },
    {
      ...routesList(t).monitoring,
      visible: true,
      badgeCounter: counters?.watchers
    },
    {
      ...routesList(t).templateProcesses,
      name: t('customProcesses.title'),
      visible: isTemplateProcessPageAvailable,
      badgeCounter: 0,
    },
    {
      ...routesList(t).analytics,
      visible: isRegisterExportAvailable,
      badgeCounter: 0,
    },
    {
      path: routesList(t).templates.path,
      name: routesList(t).templates.name,
      visible: isTemplatePageAvailable,
      badgeCounter: 0,
    },
    {
      path: routesList(t).adminPanel.path,
      name: routesList(t).adminPanel.name,
      visible: isUserAdmin,
      badgeCounter: 0,
    },
  ], [
    isRegisterExportAvailable,
    isTemplateProcessPageAvailable,
    isACLUserHasAccessToSettings,
    counters,
    isUserAdmin,
  ]);

  return (
    <Tabs
      variant="scrollable"
      scrollButtons={false}
      value={activeValue}
      className={cn(classes.tabs, {[classes.tabsWide]: isWideTabs, [classes.tabsRequestsPage]: isRequestsPage})}
      classes={{
        indicator: classes.tabIndicator,
      }}
    >
      {menuList.filter(tab => tab?.visible)
        .map((el) =>
          <Tab
            className={classes.tab}
            value={el.path}
            key={el.name}
            label={el?.badgeCounter === 0
                   ? <span>{el.name}</span>
                   : <Badge
                     classes={{ badge: classes.tabBadgeBadge, root: classes.tabBadgeRoot }}
                     badgeContent={el?.badgeCounter || 0}
                     color="primary"
                     max={99}>
                     <span>{el.name}</span>
                   </Badge>
            }
            component={Link}
            to={`${el.path}`}
          />
        )}
    </Tabs>
  );
};
