import React from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { RenderFormProps } from './types';
import { UserList } from './FormFields/Fields';
import FormGroupTemplate from './FormGroupTemplate';
import { WATCHERS_LIMIT } from '../constants';
import useStyles from './useStyles';
import { useSelectedTask } from 'store/approvals';

const orderFormGroups = (a, b) => (a.fields[0].state_order || 0) - (b.fields[0].state_order || 0);

const RenderForm = (props: RenderFormProps) => {
  const {
    groupsList,
    handleAction,
    handleWatchersSelect,
    processSysName,
    selectedWatchers,
    setAlwaysActiveButtons,
    setButtonDisabled,
    stepSysName,
  } = props;
  const selectedTaskGlobal = useSelectedTask();
  const classes = useStyles();
  const { t } = useTranslation();
  const orderedFormGroups = [];
  const groupsListKeys = Object.keys(groupsList);
  const unhiddenGroupNames = groupsListKeys.filter(groupListKey => !groupListKey.includes('#hidden'));
  
  unhiddenGroupNames.forEach((groupName) => {
    orderedFormGroups.push({ name: groupName, fields: groupsList[groupName] });
  });
  orderedFormGroups.sort(orderFormGroups);

  return (
    <>
      { orderedFormGroups.map((formGroup) =>
        <FormGroupTemplate
          formGroup={formGroup}
          processSysName={processSysName}
          stepSysName={stepSysName}
          setButtonDisabled={setButtonDisabled}
          setAlwaysActiveButtons={setAlwaysActiveButtons}
          handleAction={handleAction} />)
      }
      <Box className={classes.formGroup}>
        <Typography variant="h5" className={classes.formGroupTitle}>
          {t('Watchers.processWatchers')}
        </Typography>
        <Box className={classes.formGroup}>
          <div className={classes.formSeparateHardField}>
            <Typography variant="body1" className={classes.watchersCount}>
              {t('Watchers.processWatchers')} ({selectedWatchers.length}/{WATCHERS_LIMIT})
            </Typography>
            <UserList
              handleUsersSelect={handleWatchersSelect}
              maxUsersCount={WATCHERS_LIMIT}
              name={'Name'}
              placeholder={t('Watchers.selectUsers')}
              rules={t('form_components.required_errors.default')}
              value={""}
              showWithoutDepartmentView
              selectedTask={selectedTaskGlobal}
              clearSelectedOnComponentUnmount={false}
              withoutInitiator />
          </div>
        </Box>
      </Box>
    </>
  )
};

export default RenderForm;
