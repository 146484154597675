import React, { ReactElement, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, FormControl, Tooltip, Typography } from '@mui/material';
import { UseFormRegister } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import cn from 'classnames';
import moment from 'moment';
import DOMPurify from 'dompurify';
import { purifyOptions } from '@dar-dms/utils';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useRouteMatch } from 'react-router-dom';
import ContentEditable from 'react-contenteditable';

import { AppTextField } from 'components';
import { useUsersState } from 'store/users';
import { LOCALIZED_TIME_UNITS } from 'utils/time';
import { environment } from 'environments/environment';
import { CommentEditor } from 'components/TaskDetails/components/Tabs/CommentsTab/TaskComments/CommentEditor';

import {
  AutostartCheckbox,
  AutostartForm,
  ProcessStepTag,
  StepFieldCard,
  UserDropdownWithDepartments,
} from '../index';
import useProcessStepFormStyles from './ProcessStepForm.useStyles';
import { DirectManagerCheckbox } from '../DirectManagerCheckbox';
import { PerformerSelectCheckbox } from '../PerformerSelectCheckbox';
import { FIELDS } from '../ProcessSetupSidePanel/ProcessSetupSidePanel.constants';
import { ParallelAssigneeToggle } from '../ParallelAssigneeToggle';
import { BRANCH_STEPS_NUMBER_LIMIT, MAX_NAME_LENGTH, STEPS_NUMBER_LIMIT } from '../../TemplateProcesses.constants';
import { getDisplayedStepOrder } from '../../TemplateProcesses.utils';
import { SLAForm } from '../SLAForm';
import { DocumentsForm } from '../DocumentsForm/DocumentsForm';

import {
  AutostartSettings, ConditionStep,
  DepartmentInfo, DocumentTemplate, FormField,
  Language, ParallelBranchesGroup,
  ProcessStep,
  StepValidationResult,
  UserSelectDepartmentData,
} from '../../TemplateProcesses.types';

import InfoIcon from 'assets/images/icons/template-processes-preview-info.svg';
import DocumentsIcon from 'assets/images/icons/template-processes-documents-info.svg';
import FieldsIcon from 'assets/images/icons/template-processes-fields-info.svg';
import VisibleFieldsIcon from 'assets/images/icons/template-processes-visible-fields-icon.svg';
import HiddenFieldsIcon from 'assets/images/icons/template-processes-hidden-fields-icon.svg';
import WarningIcon from 'assets/images/icons/warning-sign.svg';

const LOCALES = {
  'en': 'en-GB',
  'kz': 'kk',
  'ru': 'ru',
  'id': 'id',
};

interface ProcessStepFormProps {
  isReadonlyView?: boolean;
  isEditDisabled?: boolean;
  isDragActive?: boolean;
  dragType: 'documents' | 'fields';
  register: UseFormRegister<ProcessStep[]>;
  trigger: any;
  clearErrors: any;
  errors: any;
  formErrors?: StepValidationResult[];
  nameFieldRef: any; // todo: fix type
  currentLanguage: Language;
  currentStep: ProcessStep;
  processSteps: ProcessStep[];
  conditions: ConditionStep[],
  parallelBranchesGroups: ParallelBranchesGroup[],
  documents: DocumentTemplate[];
  departmentsList: DepartmentInfo[];
  directManagerSteps?: number[];
  performerSelectionSteps?: number[];
  focusedStepFieldId?: string;
  focusedDocumentId?: string;
  isAutostartActive?: boolean;
  autostartStatus?: string;
  autostartSettings: AutostartSettings;
  summaryFields: string[];
  allProcessStepsFields: any[];
  hasWorkCalendar: boolean;
  toggleStepFieldVisibility: (field: FormField) => void;
  handleAutostartSettingsChange: (value: AutostartSettings) => void;
  handleInitiatorsChange: (userId: string[]) => void;
  handleAssigneeTypeChange: (value: boolean) => void;
  handleAutostartChange: (value: boolean) => void;
  handleStepNameChange: (value: string) => void;
  handleStepDescriptionChange: (value: string) => void;
  handleDirectManagerToggle: (value: boolean) => void;
  handlePerformerSelectionToggle: (value: boolean) => void;
  handleSLACheckboxToggle: (value: boolean) => void;
  handleStepExecutionTimeChange: (value: string) => void;
  handleStepFieldFocus: (fieldId: string) => void;
  handleStepFieldDelete: (fieldId: string) => void;
  handleDisplayedVersionToggle: () => void;
  openStepsLimitReachedDialog: () => void;
  openBranchStepsLimitReachedDialog: () => void;
  handleProcessSelect: (processStep: ProcessStep) => void;
  handleDocumentAdd: (documentId: number, order: number) => void;
  gridPathRef: RefObject<HTMLElement>
  handleDocumentFocus: (documentId: string) => void;
  handleDocumentDelete: (documentId: string) => void;
  handleDocumentVisibilityChange: (documentId: string, isVisible: boolean) => void;
  handleDocumentPreviewOpen: (documentId: number, documentData?: DocumentTemplate) => void;
  handleCardPreviewDialogOpen: () => void;
}

export const ProcessStepForm = ({
  isReadonlyView = false,
  isEditDisabled = false,
  isDragActive = false,
  dragType,
  register,
  trigger,
  clearErrors,
  errors,
  formErrors,
  nameFieldRef,
  currentLanguage,
  currentStep,
  processSteps = [],
  conditions = [],
  documents = [],
  parallelBranchesGroups = [],
  allProcessStepsFields = [],
  focusedStepFieldId = '',
  focusedDocumentId,
  hasWorkCalendar,
  departmentsList,
  directManagerSteps = [],
  performerSelectionSteps = [],
  isAutostartActive = false,
  autostartStatus = 'ACTIVE',
  summaryFields,
  toggleStepFieldVisibility,
  autostartSettings,
  handleAutostartSettingsChange,
  handleAutostartChange,
  handleStepNameChange,
  handleStepDescriptionChange,
  handleInitiatorsChange,
  handleAssigneeTypeChange,
  handleDirectManagerToggle,
  handlePerformerSelectionToggle,
  handleSLACheckboxToggle,
  handleStepExecutionTimeChange,
  handleStepFieldFocus,
  handleStepFieldDelete,
  handleDisplayedVersionToggle,
  openStepsLimitReachedDialog,
  openBranchStepsLimitReachedDialog,
  handleProcessSelect,
  gridPathRef,
  handleDocumentAdd,
  handleDocumentFocus,
  handleDocumentDelete,
  handleDocumentVisibilityChange,
  handleDocumentPreviewOpen,
  handleCardPreviewDialogOpen
}: ProcessStepFormProps): ReactElement => {
  const { t, i18n } = useTranslation();
  moment.locale(LOCALES[i18n.language] || i18n.language);
  const { users } = useUsersState();

  const routeMatch = useRouteMatch();

  const isTemplatesPage = useMemo(() => {
    return routeMatch.path === '/templates/:id';
  }, [routeMatch]);

  const [currentStepDMs, setCurrentStepDMs] = useState<string[]>([]);
  const [isUsersListOpen, setUsersListOpen] = useState<boolean>(false);
  const [isDescriptionOpen, setDescriptionOpen] = useState<boolean>(false);
  
  const classes = useProcessStepFormStyles();

  const descriptionRef = useRef<HTMLElement>();

  const isDirectManagerStep = useMemo(() =>
      directManagerSteps.includes(currentStep?.stepOrder)
    , [directManagerSteps, currentStep]);

  const isPerformerStep = useMemo(() =>
      performerSelectionSteps.includes(currentStep?.stepOrder - 1)
    , [performerSelectionSteps, currentStep]);

  useEffect(() => {
    if (!currentStep || currentStep?.stepOrder === 1) {
      return;
    }

    let chainLength = 1;
    for (let i = currentStep?.stepOrder; i > 1; i--) {
      if (directManagerSteps.includes(i - 1)) {
        chainLength += 1;
      } else {
        break;
      }
    }

    let previousStepsDMs = [];
    let displayAssignees = [];
    let displayWithoutDMs = [];
    for (let i = 0; i < chainLength; i++) {
      const stepOrder = currentStep?.stepOrder - chainLength + i;
      const stepInfo = processSteps.find(step => step.stepOrder === stepOrder);

      const stepUsers = previousStepsDMs.length
                        ? (previousStepsDMs || [])
                        : (stepInfo.assigneeId?.map(userId => users[userId]).filter(Boolean) || []);
      const stepsDMs = stepUsers?.filter(user => user.reportsToId).map(user => users[user.reportsToId]).filter(Boolean) || [];
      const stepUsersWithoutDms = stepUsers?.filter(user => !user.reportsToId).map(user => user.fullName) || [];

      previousStepsDMs = stepsDMs;
      displayAssignees = stepUsers;
      displayWithoutDMs = stepUsersWithoutDms;
    }
    const uniqueAssigneesDMsIds = displayAssignees?.map(user => user.reportsToId).filter((v, i, a) => Boolean(v) && a.indexOf(v) === i) || [];
    setCurrentStepDMs(uniqueAssigneesDMsIds);
  }, [currentStep, processSteps, isDirectManagerStep, directManagerSteps, setCurrentStepDMs]);

  const assigneeInfo = useMemo(() => {
    if (!currentStep) {
      return;
    }

    if (currentStep?.stepOrder === 1 && isAutostartActive) {
      let status = t('customProcesses.creationPage.processForm.autostart.status.started');
      if (autostartStatus === 'STOPPED' || autostartStatus === 'PAUSED') {
        status = t('customProcesses.creationPage.processForm.autostart.status.stopped');
      }
      if (autostartStatus === 'FINISHED') {
        status = t('customProcesses.creationPage.processForm.autostart.status.finished');
      }
      const startDateString = moment(autostartSettings.startDate).format('MMM DD YYYY, HH:mm');
      let endingString = t('customProcesses.creationPage.processForm.autostartEndingNever');
      if (autostartSettings.endingType === 'date') {
        endingString = moment(autostartSettings.endingDate).format('MMM DD YYYY');
      }
      if (autostartSettings.endingType === 'after') {
        endingString = `${t('customProcesses.creationPage.processForm.autostartEndingAfter')} ${autostartSettings.endingRepetitionsCount} ${t('customProcesses.creationPage.processForm.autostartEndingAfterRepetitions')}`;
      }

      return <Trans
        i18nKey="customProcesses.creationPage.processForm.autostart.readonlyTemplate"
        values={{
          status: status,
          repeatCount: autostartSettings.repeatCount,
          repeatUnit: t(`customProcesses.creationPage.processForm.autostart.${autostartSettings.repeatUnit}`),
          startDate: startDateString,
          ending: endingString,
        }}
      />;
    }

    if (!currentStep?.assigneeId?.length && !isDirectManagerStep) {
      return (<>
        {isPerformerStep
         ? t('customProcesses.readonlyView.assigneeSelectedOnPreviousStep')
         : t('customProcesses.creationPage.processForm.assigneeNotSelected')
        }
      </>);
    }

    if (isDirectManagerStep && currentStepDMs.length === 1) {
      return <>{users[currentStepDMs[0]]?.fullName}</>;
    }

    if (currentStep?.assigneeId?.length === 1) {
      return <>{users[currentStep?.assigneeId[0]]?.fullName}</>;
    }

    const tooltipAssignees = (isDirectManagerStep ? currentStepDMs : currentStep?.assigneeId)
      .map(userId => users[userId]);

    const tooltipAssigneesDividedByDepartments =
      tooltipAssignees.reduce((acc, assignee): { [key: string]: UserSelectDepartmentData } => {
        const userDepartmentKey = assignee?.department || 'no_department';
        if (!Object.keys(acc).includes(userDepartmentKey)) {
          let departmentName = userDepartmentKey === 'no_department'
                               ? t('form_components.select_users.no_department')
                               : userDepartmentKey;

          const departmentInfo = departmentsList.find(({ id }) => id === userDepartmentKey);
          if (departmentInfo) {
            departmentName = Object.keys(departmentInfo.localization).includes(i18n.language)
                             ? departmentInfo.localization[i18n.language]
                             : departmentInfo.value;
          }

          acc[userDepartmentKey] = {
            key: userDepartmentKey,
            name: departmentName,
            users: [],
          };
        }
        acc[userDepartmentKey].users.push(assignee);
        return acc;
      }, {});

    const tooltipAssigneesSorted = (Object.values(tooltipAssigneesDividedByDepartments) as UserSelectDepartmentData[])
      // sort placing 'no department assign' group to the end
      .sort((a, b) => a.key === 'no_department' ? 1 : a.name.localeCompare(b.name))
      .map(department => ({
        ...department,
        users: department.users.sort((a, b) => a.fullName.localeCompare(b.fullName)),
      }));

    const tooltipContent = <Scrollbars style={{ width: 160, height: 160 }} className={classes.tooltipContent}>{
      tooltipAssigneesSorted.map(department => {
        return <>
          <span>{department.name}</span>
          <ul>
            {department.users.map(user => <li>{user?.fullName}</li>)}
          </ul>
        </>;
      })
    }
    </Scrollbars>;

    return (
      <>
        {isDirectManagerStep
         ? users[currentStepDMs[0] || '']?.fullName
         : users[currentStep?.assigneeId[0] || '']?.fullName
        }

        <Tooltip
          arrow
          placement="bottom"
          title={tooltipContent}
          leaveDelay={500}
          classes={{
            popper: classes.processStepAssigneesTooltipPopper,
            tooltip: classes.processStepAssigneesTooltip,
          }}>
          <span className={classes.processStepAssigneeCount}>(+{tooltipAssignees.length - 1})</span>
        </Tooltip>
      </>);
  }, [currentStep, currentStepDMs, departmentsList, users, isDirectManagerStep, isPerformerStep]);

  const processComponents = useMemo(() =>
      currentStep?.fields
        .filter(field => !field.isHidden)
        .map(field => {
          const fieldComponent = FIELDS.find(({ key }) => key === field.component);
          return {
            hint: field.hint[currentLanguage],
            component: t(fieldComponent?.title),
            isRequired: field.isRequired || false,
            isEditable: field.isEditable || false,
          };
        }),
    [currentStep?.fields, currentLanguage]);

  const readonlyAssigneeLabel = useMemo(() => {
    if (currentStep?.parallel) {
      return t('task_history.parallelAssigneesLabel');
    }

    if (currentStep?.assigneeId?.length > 1 && !currentStep?.parallel) {
      return t('task_history.possible_assignees');
    }

    return t('customProcesses.creationPage.processForm.assignee');
  }, [currentStep]);

  const readonlyUsersListHint = useMemo(() => {
    if (currentStep?.stepOrder === 1) {
      return '';
    }
    let res = '';

    if (currentStep?.assigneeId?.length) {
      const userDepartmentKey = users[currentStep?.assigneeId[0]]?.department || 'no_department';
      let departmentName = userDepartmentKey === 'no_department'
                           ? t('form_components.select_users.no_department')
                           : userDepartmentKey;

      const departmentInfo = departmentsList.find(({ id }) => id === userDepartmentKey);
      if (departmentInfo) {
        departmentName = Object.keys(departmentInfo.localization).includes(i18n.language)
                         ? departmentInfo.localization[i18n.language]
                         : departmentInfo.value;
      }
      res += departmentName;
    }

    if (directManagerSteps.includes(currentStep?.stepOrder)) {
      res += (res.length > 0 ? ' · ' : '') + t('customProcesses.readonlyView.directManagers');
    }

    if (performerSelectionSteps.includes(currentStep?.stepOrder)) {
      res += (res.length > 0 ? ' · ' : '') + t('customProcesses.readonlyView.canAssignPerformer');
    }
    return res;
  }, [currentStep, performerSelectionSteps, directManagerSteps, departmentsList]);

  useEffect(() => {
    setUsersListOpen(false);
  }, [currentStep]);

  const isUserSelectDisabled = useMemo(() =>
      isDirectManagerStep || isPerformerStep || (isAutostartActive && currentStep?.stepOrder === 1)
    , [isDirectManagerStep, isPerformerStep, isAutostartActive, currentStep]);

  const assigneeInputPlaceholder = useMemo(() => {
    if (currentStep?.stepOrder === 1) {
      return t('customProcesses.creationPage.processForm.initiator');
    } else {
      return t('customProcesses.creationPage.processForm.assignee');
    }
  }, [currentStep?.stepOrder]);

  const isDMCheckboxAvailable = useMemo(() => {
    if (currentStep?.stepOrder === 1) {
      return false;
    }

    const previousStep = processSteps[currentStep?.stepOrder - 2];

    if (currentStep?.isConditionBranchStep) {
      const currentStepCondition = conditions?.find(c =>
        [
          ...(c?.hasPositiveBranch ? c?.positiveBranch : []),
          ...(c?.hasNegativeBranch ? c.negativeBranch : []),
        ].includes(currentStep?.stepOrder));
      const stepBeforeCondition = processSteps[currentStepCondition?.previousStepOrder - 1];
      return !(stepBeforeCondition?.isParallelBranchesGroupStep || stepBeforeCondition?.isConditionBranchStep);
    }

    if (currentStep?.isParallelBranchesGroupStep) {
      const currentStepParallelGroup = parallelBranchesGroups?.find(g => g?.steps?.flat().includes(currentStep?.stepOrder));
      const isFirstStepInBranch = currentStepParallelGroup?.steps?.map(b => b[0]).includes(currentStep?.stepOrder);
      const stepBeforeParallelGroup = processSteps[currentStepParallelGroup?.previousStepOrder - 1];
      return !(isFirstStepInBranch && (stepBeforeParallelGroup?.isParallelBranchesGroupStep || stepBeforeParallelGroup?.isConditionBranchStep));
    }

    return !(previousStep?.isParallelBranchesGroupStep || previousStep?.isConditionBranchStep);
  }, [currentStep, processSteps, conditions, parallelBranchesGroups]);

  const onPerformerSelectionToggle = (value) => {
    if (!value) {
      handlePerformerSelectionToggle(value);
    } else {
      if (processSteps.length >= STEPS_NUMBER_LIMIT) {
        openStepsLimitReachedDialog();
        return;
      }

      const stepParallelBranchesGroup = parallelBranchesGroups.find(group => group?.steps?.flat().includes(currentStep?.stepOrder));
      if (stepParallelBranchesGroup) {
        const stepParallelBranch = stepParallelBranchesGroup.steps.find(b => b.includes(currentStep?.stepOrder)) || [];
        if (stepParallelBranch.length >= BRANCH_STEPS_NUMBER_LIMIT) {
          openBranchStepsLimitReachedDialog();
          return;
        }
      }

      const currentStepCondition = conditions?.find(c => [
        ...(c?.hasPositiveBranch ? c?.positiveBranch : []),
        ...(c?.hasNegativeBranch ? c.negativeBranch : []),
      ].includes(currentStep?.stepOrder));
      if (currentStepCondition) {
        const isPositiveBranchStep = (currentStepCondition?.hasPositiveBranch && currentStepCondition?.positiveBranch.includes(currentStep?.stepOrder));
        const branchSteps = (isPositiveBranchStep
                             ? currentStepCondition?.positiveBranch
                             : currentStepCondition?.negativeBranch) || [];
        if (branchSteps.length >= BRANCH_STEPS_NUMBER_LIMIT) {
          openBranchStepsLimitReachedDialog();
          return;
        }
      }

      handlePerformerSelectionToggle(value);
    }
  };

  if (isReadonlyView) {
    return <Box className={classes.processStepForm}>
      <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
          <ProcessStepTag size="small"
                          stepOrder={getDisplayedStepOrder(currentStep, conditions, parallelBranchesGroups)}/>
        </Box>
      </Box>

      <Box className={classes.readonlyProcessInfoWrapper}>
        <Box className={classes.readonlyProcessInfoTitleWrapper}>
          <img src={InfoIcon} className={classes.readonlyProcessInfoTitleIcon}/>
          <h2 className={classes.readonlyProcessInfoTitle}>
            {t('customProcesses.readonlyView.stepInfo')}
          </h2>
        </Box>

        <Box className={classes.readonlyProcessInfoRow}>
          <Box className={classes.readonlyProcessInfoTitleColumn}>
            {t('customProcesses.creationPage.fields.name')}
          </Box>
          <Box className={classes.readonlyProcessInfoContentColumn}>
            {currentStep?.stepName[currentLanguage]}
          </Box>
        </Box>

        <Box className={classes.readonlyProcessInfoRow}>
          <Box className={classes.readonlyProcessInfoTitleColumn}>
            {currentStep?.stepOrder === 1
             ? t('customProcesses.creationPage.processForm.initiator')
             : readonlyAssigneeLabel}
          </Box>
          <Box className={classes.readonlyProcessInfoContentColumn}>
            {(currentStep?.assigneeId?.length > 1 || (isDirectManagerStep && currentStepDMs.length > 0)) &&
              (<Button className={classes.usersListToggleButton} variant="text"
                       onClick={() => setUsersListOpen(v => !v)}>
                {isUsersListOpen ? t('customProcesses.readonlyView.hide') : t('customProcesses.readonlyView.details')}
              </Button>)
            }

            {isUsersListOpen
             ? <Scrollbars
               className={classes.readonlyUsersListScrollbars}
               style={{
                 height: Math.min(24 * (
                   (isDirectManagerStep
                    ? currentStepDMs
                    : currentStep?.assigneeId).length + (readonlyUsersListHint.length
                                                         ? 1.25
                                                         : 0)), 296),
                 transitionProperty: 'height',
                 transitionDuration: '500ms',
               }}
             >
               <ul className={cn(classes.readonlyUsersList, { [classes.readonlyUsersListCompact]: !isUsersListOpen })}>
                 {(isDirectManagerStep ? currentStepDMs : currentStep?.assigneeId)
                   .map((userId, index, array) =>
                     <li>
                       {users[userId]?.fullName}
                     </li>,
                   )}
               </ul>
               <span className={classes.readonlyUsersInfoMessage}>
                {readonlyUsersListHint}
              </span>
             </Scrollbars>

             : <>
               {assigneeInfo}
               {!!readonlyUsersListHint &&
                 (<span className={classes.readonlyUsersInfoMessage}>{readonlyUsersListHint}</span>)
               }
             </>
            }
          </Box>
        </Box>

        {!!currentStep?.timer &&
          (<Box className={classes.readonlyProcessInfoRow}>
              <Box className={classes.readonlyProcessInfoTitleColumn}>
                {t('customProcesses.processStep.time')}
              </Box>
              <Box className={classes.readonlyProcessInfoContentColumn}>
                {currentStep?.timer}{' '}{LOCALIZED_TIME_UNITS[i18n.language].hour}
              </Box>
            </Box>
          )
        }

        {currentStep?.stepDescription?.[currentLanguage] &&
          <Box className={classes.readonlyProcessInfoRow}>
            <Box className={classes.readonlyProcessInfoTitleColumn}>
              {t('customProcesses.creationPage.processForm.fields.stepDescription')}
            </Box>

            <Box className={classes.readonlyProcessInfoContentColumn}>
              {isDescriptionOpen
               ?
               <Scrollbars
                 className={classes.readonlyUsersListScrollbars}
                 style={{
                   height: Math.min(descriptionRef.current?.clientHeight, 296),
                   maxHeight: Math.min(descriptionRef.current?.clientHeight, 296),
                   transitionProperty: 'height',
                   transitionDuration: '500ms',
                 }}
               >
                 <Box>
                   <Typography className={classes.readonlyDescription}>
                     <ContentEditable
                       innerRef={descriptionRef}
                       disabled
                       html={currentStep?.stepDescription?.[currentLanguage]}
                       onChange={() => null}
                     />
                   </Typography>
                 </Box>
               </Scrollbars>
               :
               <Box className={classes.readonlyDescriptionCompactWrapper}>
                 <Typography className={classes.readonlyDescription}>
                   <ContentEditable
                     innerRef={descriptionRef}
                     disabled
                     html={currentStep?.stepDescription?.[currentLanguage]}
                     onChange={() => null}
                   />
                 </Typography>
               </Box>
              }

              <Button className={classes.descriptionToggleButton} variant="text"
                      onClick={() => setDescriptionOpen(v => !v)}>
                {isDescriptionOpen
                 ? t('customProcesses.readonlyView.hide')
                 : t('customProcesses.readonlyView.showAll')
                }
              </Button>
            </Box>
          </Box>}
      </Box>

      {(!(isAutostartActive && currentStep?.stepOrder === 1)
          && !!documents?.filter(doc => doc.firstAppearanceStep <= currentStep?.stepOrder && !doc.hiddenSteps.includes(currentStep?.stepOrder))?.length)
        && (<Box className={classes.readonlyProcessInfoWrapper}>
          <Box className={classes.readonlyProcessInfoTitleWrapper}>
            <img src={DocumentsIcon} className={classes.readonlyProcessInfoTitleIcon}/>
            <h2 className={classes.readonlyProcessInfoTitle}>
              {t('customProcesses.readonlyView.documents')}
            </h2>
          </Box>

          {documents
            .filter(doc => doc.firstAppearanceStep <= currentStep?.stepOrder && !doc.hiddenSteps.includes(currentStep?.stepOrder))
            .map(document => (
              <Box className={classes.readonlyProcessInfoRow}>
                <Box
                  className={classes.readonlyProcessInfoTitleColumn}>
                  {t('customProcesses.readonlyView.documentName', { defaultValue: 'Document name' })}
                </Box>
                <Box className={classes.readonlyProcessInfoContentColumn}>
                  <span>{document.title}</span>
                  {document.signatureSteps.includes(currentStep?.stepOrder) &&
                    <span className={classes.signatureChip}>
                    {t('customProcesses.creationPage.processForm.documentCard.signatureRequired')}
                  </span>
                  }
                </Box>
              </Box>
            ))}
        </Box>)
      }

      {!(isAutostartActive && currentStep?.stepOrder === 1) &&
        (<Box className={classes.readonlyProcessInfoWrapper}>
          <Box className={classes.readonlyProcessInfoTitleWrapper}>
            <img src={FieldsIcon} className={classes.readonlyProcessInfoTitleIcon}/>
            <h2 className={classes.readonlyProcessInfoTitle}>
              {t('customProcesses.readonlyView.fieldsInfo')}
            </h2>
          </Box>

          {processComponents.map(stepField => (
            <Box className={classes.readonlyProcessInfoRow}>
              <Box
                className={cn(classes.readonlyProcessInfoTitleColumn, { [classes.requiredFieldTitle]: stepField.isRequired })}>
                {stepField.hint}
              </Box>
              <Box className={classes.readonlyProcessInfoContentColumn}>
                {stepField.component}
                <span className={classes.readonlyProcessInfoReadonlyFieldComponentSuffix}>
                  {stepField.isEditable ? '' : ` (${t('customProcesses.readonlyView.fieldReadonlySuffix')})`}
                </span>
              </Box>
            </Box>
          ))}
        </Box>)
      }
    </Box>;
  }


  return (
    <Box className={classes.processStepForm}>
      {isEditDisabled && (<Box className={classes.processStepFormEditingDisabledIndicator}>
          <p>
            <Trans i18nKey="customProcesses.creationPage.processForm.editingDisabledMessage">
              Редактирование недоступно, потому что есть <span onClick={handleDisplayedVersionToggle}>версия</span> с
              неопубликованными изменениями
            </Trans>
          </p>
        </Box>
      )}

      <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" pb={4}>
        <Box width="100%" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <ProcessStepTag size="small"
                          stepOrder={getDisplayedStepOrder(currentStep, conditions, parallelBranchesGroups)}/>

          <Button color='secondary' onClick={handleCardPreviewDialogOpen}>
            {t('customProcesses.creationPage.processForm.openPreviewButton', {defaultValue: 'Set up request preview'})}
          </Button>
        </Box>
      </Box>

      <Box className={classes.processStepFormSection}>
        <Typography className={classes.processStepFormSectionTitle}>
          {t('customProcesses.creationPage.processForm.sections.general')}
        </Typography>

        <Box display="flex" flexDirection="column">
          <FormControl fullWidth>
            <AppTextField
              {...register(`processSteps[${currentStep?.stepOrder - 1 as number}].stepName.${currentLanguage}`, {
                required: t('form_components.required_errors.default') as string,
              })}
              disabled={(isAutostartActive && currentStep?.stepOrder === 1) || isEditDisabled}
              inputRef={nameFieldRef}
              size="medium"
              className={cn(classes.stepNameField, { [classes.textFieldDisabled]: isAutostartActive && currentStep?.stepOrder === 1 })}
              label={<span className={classes.requiredFieldTitle}>
              {t('customProcesses.creationPage.processForm.fields.name')}
            </span>}
              error={formErrors[currentStep?.stepOrder - 1]?.stepName.includes(currentLanguage)}
              helperText={
                <>
                  {formErrors[currentStep?.stepOrder - 1]?.stepName.includes(currentLanguage)
                   ? <div className={classes.processStepFormErrorMessage}>
                     <img src={WarningIcon} alt="warning"/>
                     <span>{t('customProcesses.creationPage.processFormErrors.requiredField')}</span>
                   </div>
                   : null
                  }
                  {currentStep?.stepName[currentLanguage]?.length > MAX_NAME_LENGTH
                   ? <div
                     className={cn(classes.processStepFormErrorMessage, classes.processStepFormErrorMessageTwoSides)}>
                     <div>
                       <img src={WarningIcon} alt="warning"/>
                       <span>{t('customProcesses.creationPage.processFormErrors.valueTooLong')}</span>
                     </div>

                     <div>
                       {currentStep?.stepName[currentLanguage]?.length || 0}/{MAX_NAME_LENGTH}
                     </div>
                   </div>
                   : null
                  }
                </>
              }
              placeholder={t('customProcesses.creationPage.processForm.fields.namePlaceholder')}
              value={currentStep?.stepName[currentLanguage]}
              defaultValue={currentStep?.stepName[currentLanguage]}
              onChange={event => handleStepNameChange(event.target.value)}
            />
          </FormControl>

          {currentStep?.stepOrder > 1 &&
            <>
              <Box mt={4} mb={4}>
                <FormControl fullWidth style={{ display: 'none' }}>
                  <AppTextField
                    {...register(`processSteps[${currentStep?.stepOrder - 1 as number}].stepDescription.${currentLanguage}`)}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <Box className={classes.processStepFormFileTitle}
                       mb={1}>{t('customProcesses.creationPage.processForm.fields.stepDescription')}</Box>
                  <CommentEditor
                    onChange={value => {
                      handleStepDescriptionChange(DOMPurify.sanitize(value, purifyOptions));
                    }}
                    value={currentStep?.stepDescription?.[currentLanguage]}
                    inputId={`processSteps[${currentStep?.stepOrder - 1 as number}].stepDescription.${currentLanguage}`}
                  />
                  {formErrors[currentStep?.stepOrder - 1]?.stepDescription.includes(currentLanguage)
                   ? <div className={classes.processStepFormErrorMessage} style={{ marginTop: 7 }}>
                     <img src={WarningIcon} alt="warning"/>
                     <span>{t('customProcesses.creationPage.processFormErrors.requiredField')}</span>
                   </div>
                   : null}
                </FormControl>
              </Box>
            </>}
        </Box>
      </Box>


      <Box className={classes.processStepFormSection}>
        <Typography className={classes.processStepFormSectionTitle}>
          {currentStep?.stepOrder === 1
           ? t('customProcesses.creationPage.processForm.sections.initiator')
           : t('customProcesses.creationPage.processForm.sections.assignee')}
        </Typography>

        <Box>
          <input
            type="hidden"
            {...register(`processSteps[${currentStep?.stepOrder - 1}].assigneeId`, {
              required: isUserSelectDisabled || isTemplatesPage
                        ? false
                        : t('form_components.required_errors.default') as string,
            })}
          />

          {(currentStep?.stepOrder > 1 && !isPerformerStep && !currentStep?.isConditionBranchStep && !currentStep?.isParallelBranchesGroupStep) &&
            (<ParallelAssigneeToggle
              disabled={isTemplatesPage}
              currentStep={currentStep}
              handleChange={handleAssigneeTypeChange}
              documents={documents}
            />)
          }

          <UserDropdownWithDepartments
            isParallelAssigneeSelect={currentStep?.parallel}
            placeholder={assigneeInputPlaceholder}
            activeUsersId={currentStep?.assigneeId}
            currentStepOrder={currentStep?.stepOrder}
            handleUsersSelect={usersId => handleInitiatorsChange(usersId)}
            // todo - think if there is a better way than passing departments list through several components
            // but without repeated requests since same list is used for step card
            departmentsList={departmentsList}
            disabled={isUserSelectDisabled || isEditDisabled || isTemplatesPage}
            error={!isTemplatesPage && formErrors[currentStep?.stepOrder - 1]?.assigneeId}
            parallelAssigneeErrors={formErrors[currentStep?.stepOrder - 1]?.parallelAssignees}
            isAutostartActive={isAutostartActive}
            helperText={
              !isTemplatesPage && formErrors[currentStep?.stepOrder - 1]?.assigneeId
              ? <div className={classes.processStepFormErrorMessage}>
                <img src={WarningIcon} alt="warning"/>
                <span>
                  {currentStep?.stepOrder === 1
                   ? t('customProcesses.creationPage.processFormErrors.initiatorRequired')
                   : t('customProcesses.creationPage.processFormErrors.assigneeRequired')
                  }</span>
              </div>
              : null
            }
            isPerformerStep={isPerformerStep}
            showAllUsersGroup={currentStep?.stepOrder === 1}
            showAllDepartmentsGroup={currentStep?.stepOrder > 1}
            allowOnlyOneDepartment={currentStep?.stepOrder > 1}
          />
        </Box>

        {!isPerformerStep && (<>
          {isDMCheckboxAvailable &&
            <Box>
              <DirectManagerCheckbox
                isDirectManagerStep={directManagerSteps.includes(currentStep?.stepOrder)}
                isAutostartActive={isAutostartActive}
                directManagerSteps={directManagerSteps}
                currentStep={currentStep}
                processSteps={processSteps}
                conditions={conditions}
                parallelBranchesGroups={parallelBranchesGroups}
                disabled={isEditDisabled || isTemplatesPage}
                setCurrentStepDMs={setCurrentStepDMs}
                handleDirectManagerToggle={handleDirectManagerToggle}
              />
            </Box>
          }

          <Box>
            <PerformerSelectCheckbox
              isDirectManagerStep={directManagerSteps.includes(currentStep?.stepOrder)}
              isPerformerSelectionStep={performerSelectionSteps.includes(currentStep?.stepOrder)}
              processSteps={processSteps}
              currentStep={currentStep}
              disabled={isEditDisabled}
              currentStepDMs={currentStepDMs}
              handlePerformerSelectionToggle={onPerformerSelectionToggle}
            />
          </Box>

          {currentStep?.stepOrder > 1 &&
            <Box>
              <SLAForm
                hasWorkCalendar={hasWorkCalendar}
                register={register}
                trigger={trigger}
                errors={formErrors}
                clearErrors={clearErrors}
                currentStep={currentStep}
                isEditDisabled={isEditDisabled}
                handleSLACheckboxToggle={handleSLACheckboxToggle}
                handleTimerValueChange={handleStepExecutionTimeChange}
              />
            </Box>
          }

        </>)}

        <AutostartCheckbox
          value={isAutostartActive}
          currentStep={currentStep}
          disabled={isEditDisabled}
          handleAutostartToggle={handleAutostartChange}
        />
      </Box>

      {(isAutostartActive && currentStep?.stepOrder === 1) && (
        <Box className={classes.processStepFormSection}>
          <Typography className={classes.processStepFormSectionTitle}>
            {t('customProcesses.creationPage.processForm.sections.autostartSettings')}
          </Typography>

          <AutostartForm
            values={autostartSettings}
            formErrors={formErrors}
            disabled={isEditDisabled}
            updateValues={handleAutostartSettingsChange}
          />
        </Box>
      )}

      {
        (!isTemplatesPage &&
          environment?.isDocumentAdditionInTemplateProcessesAvailable &&
          (!isAutostartActive || currentStep?.stepOrder > 1)) &&
          !currentStep?.isConditionBranchStep &&
          !currentStep?.isParallelBranchesGroupStep &&
        <Box className={classes.processStepFormSection}>
          <Typography
            className={cn(classes.processStepFormSectionTitle, classes.processStepFormSectionTitleWithDescription)}>
            {t('customProcesses.creationPage.processForm.sections.documents')}
          </Typography>
          <Typography className={classes.processStepFormSectionDescription}>
            {t('customProcesses.creationPage.processForm.sections.documentsDescription')}
          </Typography>

          <Box display="flex" flexDirection="column" gap={6}>
            <DocumentsForm
              documents={documents}
              processSteps={processSteps}
              conditions={conditions}
              parallelBranchesGroups={parallelBranchesGroups}
              currentStep={currentStep}
              formErrors={formErrors}
              focusedDocumentId={focusedDocumentId}
              focusedStepFieldId={focusedStepFieldId}
              isDragActive={isDragActive}
              dragType={dragType}
              isEditDisabled={isEditDisabled}
              gridPathRef={gridPathRef}
              currentLanguage={currentLanguage}
              onDocumentAdd={handleDocumentAdd}
              onDocumentFocus={handleDocumentFocus}
              onDocumentVisibilityChange={handleDocumentVisibilityChange}
              onDocumentDelete={handleDocumentDelete}
              handleProcessSelect={handleProcessSelect}
              handleDocumentPreviewOpen={handleDocumentPreviewOpen}
            />
          </Box>
        </Box>
      }

      {(!isAutostartActive || currentStep?.stepOrder > 1) && (
        <Box className={classes.processStepFormSection}>
          <Typography className={classes.processStepFormSectionTitle}>
            {t('customProcesses.creationPage.processForm.sections.customFields')}
          </Typography>

          <Box display="flex" flexDirection="column" gap={6}>

            <Box className={classes.fieldGroup}>
              <div className={classes.fieldGroupHeader}>
                <img src={VisibleFieldsIcon} className={classes.processStepFormFieldsGroupIcon}/>
                <Typography className={classes.processStepFormFieldsGroupTitle}>
                  {t('customProcesses.processForm.customFieldsTitleActiveFields')}
                </Typography>
              </div>

              <Droppable key="FIELDS" droppableId="FIELDS" isDropDisabled={dragType !== 'fields'}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className={cn(classes.fieldsListWrapper, { [classes.fieldsListDragZoneVisible]: isDragActive && dragType === 'fields' })}
                  >
                    {currentStep?.fields?.filter(stepField => !stepField?.isHidden)?.length === 0 && <Box
                      className={formErrors[currentStep?.stepOrder - 1]?.fields
                                 ? classes.processStepFormErrorMessage
                                 : classes.processStepFormFieldsInfoMessage}
                    >
                      {formErrors[currentStep?.stepOrder - 1]?.fields && <img src={WarningIcon} alt="warning"/>}
                      <span>{t('customProcesses.fields.noActiveFields')}</span>
                    </Box>
                    }
                    {currentStep?.fields
                      ?.filter(stepField => !stepField?.isHidden)
                      .map((stepField, index) => (
                        <Draggable
                          key={stepField.id}
                          draggableId={stepField.id}
                          disabled={isEditDisabled}
                          index={index}>
                          {(
                            provided,
                            snapshot,
                          ) => (
                            <div
                              ref={
                                provided.innerRef
                              }
                              {...provided.draggableProps}
                              style={
                                provided
                                  .draggableProps
                                  .style
                              }>
                              <StepFieldCard
                                conditions={conditions}
                                parallelBranchesGroups={parallelBranchesGroups}
                                gridPathRef={gridPathRef}
                                handleProcessSelect={handleProcessSelect}
                                processSteps={processSteps}
                                field={stepField}
                                documents={documents}
                                dragHandleProps={provided.dragHandleProps}
                                isDragged={snapshot.isDragging}
                                isFocused={stepField.id === focusedStepFieldId}
                                summaryFields={summaryFields}
                                currentLanguage={currentLanguage}
                                allProcessStepsFields={allProcessStepsFields}
                                currentStepOrder={currentStep?.stepOrder}
                                isEditDisabled={isEditDisabled}
                                areActionsAvailable={!(focusedStepFieldId || focusedDocumentId)}
                                onEditButtonClick={() => {
                                  handleStepFieldFocus(stepField.id);
                                }}
                                onDeleteButtonClick={() => handleStepFieldDelete(stepField.id)}
                                onVisibilityButtonClick={() => toggleStepFieldVisibility(stepField)}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Box>

            {(currentStep?.stepOrder > 1 || (isAutostartActive && currentStep?.stepOrder > 2)) && <Box className={classes.fieldGroup}>
              <div className={classes.fieldGroupHeader}>
                <img src={HiddenFieldsIcon} className={classes.processStepFormFieldsGroupIcon}/>
                <Typography className={classes.processStepFormFieldsGroupTitle}>
                  {t('customProcesses.processForm.customFieldsTitleHiddenFields')}
                </Typography>
              </div>

              <Box className={classes.fieldsListWrapper}>
                {currentStep?.fields?.filter(stepField => stepField?.isHidden)
                  .map((stepField, index) => (
                    <StepFieldCard
                      conditions={conditions}
                      parallelBranchesGroups={parallelBranchesGroups}
                      gridPathRef={gridPathRef}
                      handleProcessSelect={handleProcessSelect}
                      processSteps={processSteps}
                      field={stepField}
                      documents={documents}
                      isFocused={stepField.id === focusedStepFieldId}
                      areActionsAvailable={!(focusedStepFieldId || focusedDocumentId)}
                      summaryFields={summaryFields}
                      currentLanguage={currentLanguage}
                      allProcessStepsFields={allProcessStepsFields}
                      currentStepOrder={currentStep?.stepOrder}
                      isEditDisabled={isEditDisabled}
                      onEditButtonClick={() => handleStepFieldFocus(stepField.id)}
                      onDeleteButtonClick={() => handleStepFieldDelete(stepField.id)}
                      onVisibilityButtonClick={() => toggleStepFieldVisibility(stepField)}
                    />))
                }

                {currentStep?.fields?.filter(stepField => stepField?.isHidden)?.length === 0 && <Box
                  className={classes.processStepFormFieldsInfoMessage}
                >
                  <span>{t('customProcesses.fields.noHiddenFields')}</span>
                </Box>
                }
              </Box>
            </Box>
            }
          </Box>
        </Box>
      )}
    </Box>
  );
};
