import React from 'react';
import { Avatar, Tooltip } from '@material-ui/core';
import { AvatarGroup as MuiAvatarGroup } from '@material-ui/lab';

import { UserAvatar } from 'components/User';
import { UserType } from 'types';
import useStyles from './styles';

interface AvatarGroupProps {
  users: Array<UserType>;
  maxUsers?: number;
}

export const AvatarGroup: React.FC<AvatarGroupProps> = ({ users, maxUsers = 3 }) => {
  const styles = useStyles();
  return (
    <MuiAvatarGroup>
      {users?.slice(0, maxUsers).map((user, i) => (
        <Tooltip title={user?.fullName || `${user?.firstName} ${user?.lastName}`}>
          <span className={styles.avatarWrapper} style={{ zIndex: i }}>
            <UserAvatar user={user} key={`${i}-${user?.fullName || user?.logName}`} avatarSize={24} />
          </span>
        </Tooltip>
      ))}
      {maxUsers < users?.length && (
        <Tooltip
          title={
            <div className={styles.tooltip}>
              {users
                .slice(maxUsers)
                .map((user) => user?.fullName || `${user?.firstName} ${user?.lastName}`)
                .join('\n')}
            </div>
          }
        >
          <span className={styles.avatarWrapper} style={{ zIndex: maxUsers }}>
            <Avatar className={styles.avatar}>+{users?.length - maxUsers}</Avatar>
          </span>
        </Tooltip>
      )}
    </MuiAvatarGroup>
  );
};
