import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing }) => ({
  draftStatus: {
    display: 'flex',
    marginRight: 12,
    color: '#262842',
    fontSize: 14,
    '& img': {
      marginRight: 4,
      verticalAlign: 'middle'
    }
  }
}));
