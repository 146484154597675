import { getProfileCompanyDataFromLocalStorage } from 'utils/user';

export const commonExceptionHandler = error => {
  // TODO: implement
  console.error(error);
};

export const addTrackingHeaders = (config) => {
  const { id: companyId } = getProfileCompanyDataFromLocalStorage();
  const { id: userId } = JSON.parse(localStorage.getItem('profile'));

  config.headers = {
    ...config.headers,
    'dar-dms-org-id': companyId,
    'dar-dms-user-id': userId,
  };
  return config;
};
