import styled from 'styled-components';

const AttachmentsList = styled.div`
  .AttachmentsListRoot {
    box-sizing: content-box;
  }
  .AttachmentsListWrapper {
    width: 100%;
    height: auto;
    min-height: 160px;
    max-height: 296px;
    overflow: hidden;
  }
  .AttachmentsList {
    height: auto;
    max-width: 608px;
    width: 100%;
    padding-top: 28px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .AttachmentsListNoScrolling {
    max-width: 608px;
    width: 100%;
    height: auto;
  }
  .AttachmentsListCompact {
    display: flex;
    flex-direction: column;
    height: auto;
    max-width: 608px;
    width: 100%;
    padding-top: 16px;
  }
  .AttachmentListCompactDashed {
    box-sizing: content-box;
  }
  .AttachmentListCompactDashedScrolled {
    padding-top: 4px;
    padding-right: 8px;
  }
  .AttachmentListReadOnly {
    .AttachmentsList {
      margin-left: -16px;
      padding-top: 0;
    }
  }
  .AttachmentsOnRightSide {
    .AttachmentsList {
      margin-left: 0;
      justify-content: flex-start;
    }
  }
`;

export const Styled = {
  AttachmentsList,
};
