import React, { ReactElement, useMemo } from 'react';
import { Box, Tooltip } from '@material-ui/core';

import { UserType } from 'types';

import useUserDropdownStyles from './UserDropdownWithDepartments.useStyles';
import { UserSelectDepartmentData } from '../../TemplateProcesses.types';

interface UserDropdownCardProps {
  user: UserType;
  showDepartmentName?: boolean;
  departments?: UserSelectDepartmentData[];
}

export const UserDropdownCard = ({
  user,
  showDepartmentName = false,
  departments = [],
}: UserDropdownCardProps): ReactElement => {
  const classes = useUserDropdownStyles({});

  const userDepartmentName = useMemo(() => {
    const usersDepartment = departments.find(({ key }) => key === user?.department || !user?.department && key === 'no_department');
    return usersDepartment?.name;
  }, [user, departments]);

  return <Tooltip
    title={
      showDepartmentName
      ? (<>{user?.fullName}<br/>{userDepartmentName}</>)
      : user?.fullName
    }
    enterDelay={300}
    placement="top"
    arrow>
    <Box display="flex" width={'100%'} flexDirection="column" alignItems="flex-start" justifyContent={'center'}>
      <span className={classes.userCardFullName}>{user?.fullName}</span>
      {showDepartmentName &&
        <span className={classes.userCardDepartment}>{userDepartmentName}</span>
      }
    </Box>
  </Tooltip>;
};
