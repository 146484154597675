import React, { memo, useMemo } from 'react';
import { Box, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ProgressBarStepType } from 'types';
import { useUsersState } from 'store/users';

import clockIcon from "assets/images/icons/task-status-tooltip-clock.svg";
import redCrossIcon from "assets/images/icons/red-cross-icon.svg";
import whiteCrossIcon from "assets/images/icons/white-cross-icon.svg";
import whiteCheckmarkIcon from "assets/images/icons/white-checkmark-icon.svg";
import useStyles from './useStyles';

type Props = {
  step: number;
  variant?: "ticket" | "header",
  processSysName?: string;
  parallelBranchesName?: string;
  parallelBranchesStepperOrder?: number;
  totalSteps: number;
  isCompleted: boolean;
  isCancelled: boolean;
  isRejected: boolean;
  stepsList: ProgressBarStepType[];
  hasCondition?: boolean;
};

export const TaskStatusIndicator = memo(({
  step,
  variant = "ticket",
  processSysName = '',
  parallelBranchesName = '',
  parallelBranchesStepperOrder = 0,
  totalSteps,
  isCompleted,
  isCancelled,
  isRejected,
  stepsList = [],
  hasCondition = false
}: Props) => {
  const classes = useStyles({variant});
  const { users } = useUsersState();

  const { t } = useTranslation();

  const value = useMemo((): number => {
    if (isCompleted) {
      return 100;
    }

    if (step === 0 || isRejected || isCancelled) {
      return 2;
    }

    if (step === totalSteps) {
      return 93;
    }

    return (step / totalSteps) * 100;
  }, [isCompleted, isRejected, isCancelled, step, totalSteps]);

  const label = useMemo((): JSX.Element => {
    if (isCompleted) {
      return <img src={whiteCheckmarkIcon} alt="100%"/>
    }

    if (isRejected || isCancelled) {
      return <img src={redCrossIcon} alt="" />
    }

    const stepValue = step || step === 0 ? step : '';
    const totalStepsValue = totalSteps ? totalSteps : '';

    return <span>{`${stepValue}/${totalStepsValue}`}</span>;
  }, [isCompleted, isRejected, isCancelled, step, totalSteps]);

  const tooltipContent = useMemo(() => {
    if (isRejected || isCancelled) {
      return <div className={classes.statusWrapper}>
        <img src={whiteCrossIcon} className={classes.statusIcon}/>
        <span>{isRejected ? t('task_history_past.reject') : t('task_history_past.canceled')}</span>
      </div>;
    }

    if (isCompleted) {
      return <div className={classes.statusWrapper}>
        <img src={whiteCheckmarkIcon} className={classes.statusIcon}/>
        <span>{t('task_statuses.completed')}</span>
      </div>;
    }

    return <>
      <div className={classes.statusWrapper}>
        <img src={clockIcon} className={classes.statusIcon}/>
        <span>{t('task_status_indicator.completedTemplate', {count: step, total: totalSteps})}</span>
      </div>

      <div className={classes.stepInfoWrapper}>
        {parallelBranchesName && <div className={classes.stepInfo}>
          <span className={classes.stepInfoLabel}>{t('task_status_indicator.parallelBranchesLabel')}:</span> {t(`constructor-${processSysName}.parallelBranches.${parallelBranchesStepperOrder}.name`, {defaultValue: parallelBranchesName})}
        </div>
        }
        {stepsList?.map(stepInfo => {
          const stepName = t(`constructor-${processSysName}.actions.${stepInfo?.actionSysName}.name`, { defaultValue: stepInfo?.stepName });
          const assigneesList = stepInfo?.assigneeId?.map(assignee => {
              // eslint-disable-next-line no-template-curly-in-string
              if (assignee === 'HeadCheck' || assignee === '${HeadCheck}') {
                return t('customProcesses.creationPage.processForm.assigneeDirectManager');
              }

              // eslint-disable-next-line no-template-curly-in-string
              if (assignee === '${performer}' || assignee === 'performer') {
                return t('customProcesses.creationPage.processForm.assigneePerformer');
              }

              return users[assignee] ? users[assignee]?.fullName : t('task_data_view.employee_not_found');
            },
          );
          let assigneesLabel = t('task_status_indicator.assigneesLabel');
          if (assigneesList?.length > 1) {
            assigneesLabel = t('task_status_indicator.assigneesMultipleLabel');
          }
          if (stepInfo?.candidateUsers) {
            assigneesLabel = t('task_history.possible_assignees');
          }
          if (stepInfo?.parallelAssignee) {
            assigneesLabel = t('task_history.parallelAssigneesLabel');
          }

          return <div className={classes.stepInfo}>
            <p><span className={classes.stepInfoLabel}>{t('task_status_indicator.stepNameLabel')}:</span> {stepName}</p>
            <p><span className={classes.stepInfoLabel}>{assigneesLabel}:</span>
              <ul>
                {assigneesList?.map(assignee => <li>{assignee}</li>)}
              </ul>
            </p>
          </div>
        })}
      </div>

      {hasCondition &&
        <div className={classes.notFinalCountInfoMessageWrapper}>
          {t('task_status_indicator.stepsCountNotFinalMessage', { defaultValue: 'The number of required steps can be updated according to the process rules' })}
        </div>
      }
    </>
  }, [isCompleted, isCancelled, isRejected, stepsList, processSysName, users])

  return (
    <>
      <Tooltip
        title={
          tooltipContent
        }
        classes={{tooltip: classes.tooltip}}
        placement="bottom"
        arrow
        interactive
      >
        <Box position="relative" display="inline-flex" height={variant === "header" ? 40 : 48}>
          <Box position="relative">
            <CircularProgress
              variant="determinate"
              className={classes.circleWrapper}
              disableShrink
              thickness={3}
              value={100}
              size={variant === "header" ? 40 : 48}
            />

            <CircularProgress
              variant="determinate"
              value={value}
              thickness={isCompleted ? (variant === "header" ? 22 : 24) : 3}
              size={variant === "header" ? 40 : 48}
              classes={{circle: isCompleted ? classes.circleCompleted : classes.circleInProgress}}
            />
          </Box>

          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
              className={classes.stepLabel}
            >
              {label}
            </Typography>
          </Box>
        </Box>
      </Tooltip>
    </>
  );
});
