import React, { memo, ReactElement, useMemo } from 'react';
import {
  Button,
  DialogContent,
  Typography,
  DialogActions,
  Dialog,
  Box,
  ButtonBase,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { UserType } from 'types';
import { UserInfo } from 'components';
import { environment } from 'environments/environment';
import { getTranslationKeyForProcessStartError } from 'utils/translations';

import useStyles from './useStyles';

import CloseIcon from 'assets/images/icons/close-white.svg';

const { baseUrl, appUrl, env } = environment;

type Props = {
  open: boolean;
  onClose: () => void;
  message: string;
  showDirectManagerButton?: boolean;
  users: { user: UserType; title: string }[];
};

const ASSIGN_DIRECT_MANAGER_LINK_SUFFIX = '/people/employee-external?page=0&limit=25';

export const ProcessStartErrorDialog = memo(
  ({
    open = false,
    onClose,
    message,
    showDirectManagerButton = false,
    users = [],
  }: Props): ReactElement => {
    const classes = useStyles();
    const { t } = useTranslation();

    const directManagerLink = useMemo(
      (): string => (env === 'PROD' ? appUrl : baseUrl) + ASSIGN_DIRECT_MANAGER_LINK_SUFFIX,
      [baseUrl, appUrl, env]
    );

    return (
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        hideBackdrop={true}
        onClose={onClose}
      >
        <Box
          component="header"
          bgcolor={'primary.700'}
          color="common.white"
          py={4}
          px={6}
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant="h4">
            {t('Multi_step_internal_memo.process_no_direct1')}.
          </Typography>
          <ButtonBase onClick={onClose}>
            <img src={CloseIcon} alt="Close" />
          </ButtonBase>
        </Box>

        <DialogContent className={classes.content}>
          <Typography className={classes.message}>
            {t(`errors.${message}`, {defaultValue: t(getTranslationKeyForProcessStartError(message))})}
          </Typography>

          {!!users.length && (
            <Box className={classes.usersWrapper}>
              {users.map((user) => (
                <div className={classes.userItem}>
                  <UserInfo
                    user={user.user}
                    avatarSize={32}
                    title={user.title}
                    showTitleUnderName
                  />
                </div>
              ))}
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            className={classes.dialogButton}
            color="secondary"
            onClick={onClose}
          >
            {t('Multi_step_internal_memo.process_no_direct2_button1')}
          </Button>
          {showDirectManagerButton && (
            <Button
              className={classes.dialogButton}
              color="primary"
              href={directManagerLink}
            >
              {t('Multi_step_internal_memo.process_no_direct2_button2')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
);
