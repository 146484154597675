import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing }) => ({
  createSettingsCongratulationDialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: spacing(6, 7.5, 10.5, 7.5)
  },
  createSettingsCongratulationDialogImage: {
    height: 175,
    width: 175
  },
  createSettingsCongratulationDialogTitle: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.3,
    color: '#101F2B'
  },
  createSettingsCongratulationDialogSubtitle: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#465564',
    marginTop: spacing(3)
  }
}));
