import { makeStyles } from '@material-ui/core/styles';

type StyleProps = {
  hasConditions?: boolean,
  hasParallelStepsGroups?: boolean
}

// @ts-ignore
export default makeStyles(({ spacing }) => ({
  dialogRoot: {},
  dialogHeader: {
    backgroundColor: '#FFFFFF',
    padding: spacing(3, 6),
    height: 64,
    borderBottom: '1px solid rgba(38, 40, 66, 0.12)',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  dialogHeaderIconButton: {
    width: 40,
    height: 40,
    marginRight: 0,
    borderRadius: '10px !important',
    '&:hover': {
      backgroundColor: 'rgba(38, 40, 66, 0.04) !important',
      borderColor: 'transparent !important',
    },
    '&:active': {
      backgroundColor: 'rgba(38, 40, 66, 0.08) !important',
      borderColor: 'transparent !important',
    },
  },
  dialogHeaderIconButtonIcon: {
    '& > path': {
      fill: '#262842 !important',
    },
  },
  dialogHeaderTitle: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#262842',

    paddingLeft: 12,

    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  dialogPublishStatusWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  dialogPublishStatus: {
    backgroundColor: '#99A5B1',
    borderRadius: '50%',
    height: 12,
    width: 12,
    marginRight: 8,
  },
  dialogPublishStatusActive: {
    backgroundColor: '#2DB77B',
  },
  dialogPublishStatusUnpublishedChanges: {
    backgroundColor: '#FFA530',
  },
  toggleOpenedVersion: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    zIndex: 1350,
    height: 48,
    minWidth: 320,
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    marginBottom: spacing(2),
    background: '#FFFFFF',
    border: '1px solid rgba(38, 40, 66, 0.08)',
    boxShadow: '0px 8px 16px rgba(38, 40, 66, 0.04)',
    borderRadius: 10,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#007994',
    cursor: 'pointer',
  },
  dialogHeaderText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#262842',
  },
  dialogPublishStatusDropdownIndicator: {
    marginLeft: 4,
  },
  draftStatus: {
    fontSize: 14,
    color: '#FFFFFF',
    '& img': {
      marginRight: spacing(1),
      verticalAlign: 'middle',
    },
  },
  autostartStatus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: spacing(3),
    cursor: 'pointer',
  },
  autostartStatusTitle: {
    fontSize: 14,
    fontWeight: 400,
    color: '#FFFFFF',
    marginRight: spacing(2),
  },
  autostartStatusIcon: {
    height: 24,
    width: 24,
    marginRight: spacing(1.5),
  },
  headerButton: {
    height: 40,
    borderRadius: 10,
    padding: spacing(2.5, 4),
  },
  dialogContent: {
    padding: 0,
  },
  dialogContentNavigation: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: 56,
    borderBottom: '1px solid #E0E3EA',
  },
  dialogContentTab: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.3,
    color: '#6D6E85',
    padding: spacing(2.5, 4),
    border: 'none',
    borderRadius: 0,
    borderBottom: '2px solid white',

    '&:hover': {
      color: '#101F2B',
    },
  },
  dialogContentTabActive: {
    fontWeight: 500,
    borderBottom: '2px solid #007994',
    color: '#007994',
  },
  dialogContentTabWithError: {
    '& span:after': {
      content: '""',
      display: 'block',
      backgroundColor: '#ED4C36',
      height: 6,
      width: 6,
      borderRadius: '50%',
      position: 'absolute',
      right: 3,
      top: 4,
    },
  },
  dialogContentNavigationSettings: {
    position: 'absolute',
    bottom: spacing(3),
    right: spacing(8),
    display: 'flex',
    alignItems: 'center',
  },
  headerValidationErrorMessage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: spacing(3),
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#D6331F',
    '& img': {
      marginRight: spacing(1.25),
      height: 13,
      width: 13,
    },
  },
  dialogContentLanguage: {
    borderRadius: spacing(2),
  },
  dialogContentLanguageActive: {
    backgroundColor: '#E6ECF1',
  },
  dialogContentLanguageWithErrors: {
    '& span:after': {
      content: '""',
      display: 'block',
      backgroundColor: '#ED4C36',
      height: 6,
      width: 6,
      borderRadius: '50%',
      position: 'absolute',
      right: 3,
      top: 4,
    },
  },
  dialogContentIcon: {
    '& > path': {
      fill: '#262842 !important',
    },
  },
  dialogContentMenuItem: {
    padding: spacing(2.5, 4),
    minWidth: 160,
    borderTop: '1px solid rgba(38, 40, 66, 0.12)',
    '&:first-child': {
      borderTop: 'none',
    },

    '& img': {
      width: 20,
      height: 20,
      marginRight: spacing(2),
    },
  },
  dialogContentGrid: {
    height: 'calc(100vh - 128px)',
    flexWrap: 'nowrap',
  },
  dialogContentWrapper: {
    maxHeight: '100%',
    minWidth: 'calc(460px + 24px + 24px)',
    width: ({ hasConditions, hasParallelStepsGroups }: StyleProps) => hasConditions || hasParallelStepsGroups
                                                                      ? 'unset'
                                                                      : 'calc(100% - 495px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  dialogContentSidePanel: {
    background: '#F1F3F8',
    borderRight: '1px solid #E0E3EA',
    boxShadow: '4px 0px 12px rgba(28, 55, 77, 0.08)',
    overflow: 'auto',
    height: '100%',
  },
  dialogContentGridPathTitle: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.3,
    color: '#101F2B',
  },
  dialogContentIconButton: {
    height: 40,
    width: 40,
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #D6D6DB !important',
    borderRadius: `${spacing(2.5)}px !important`,

    '&:hover': {
      borderRadius: `${spacing(2)}px !important`,
    },
  },
  hiddenTab: {
    '&, & *': {
      visibility: 'hidden',
      opacity: 0,
      pointerEvents: 'none',
      height: '0px !important',
      width: '0px !important',
      float: 'left',
      padding: 0,
      margin: 0,
    },
  },
  settingsFormWrapper: {
    width: 490,
    margin: 'auto',
    marginTop: 26,
  },
  settingsFormWrapperReadonly: {
    width: 800,
    margin: 'auto',
    marginTop: 80,
  },
}));
