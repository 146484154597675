import React, {ReactElement} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, IconButton, Typography} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import useAutostartDisablingConfirmationDialogStyles from './AutostartDisablingConfirmationDialog.useStyles';

interface AutostartDisablingConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const AutostartDisablingConfirmationDialog = ({ isOpen, onClose, onConfirm }: AutostartDisablingConfirmationDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useAutostartDisablingConfirmationDialogStyles();

  const handleConfirm = () => {
    onConfirm();
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <Box px={6} pt={6} display="flex" alignItems="center" justifyContent="space-between">
        <Typography className={classes.dialogTitle}>
          {t('customProcesses.creationPage.processForm.autostartDisableDialogTitle')}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon className={classes.dialogIcon} />
        </IconButton>
      </Box>

      <DialogContent>
        <Typography className={classes.dialogSubtitle}>
          {t('customProcesses.creationPage.processForm.autostartDisableDialogMessage')}
        </Typography>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t('template_delete_modal.button_cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          {t('customProcesses.creationPage.processForm.autostartDisableDialogConfirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
