import axios from 'axios';
import JsFileDownloader from 'js-file-downloader';

import { commonExceptionHandler } from '../handlers';

import { environment } from 'environments/environment';
import { googleApiClient } from '../clients';

export const downloadAttachmentById = (
  fileId,
  filename,
  googleDriveApiAccessToken
): Promise<any> => {
  return new JsFileDownloader({
    url: `${environment.NX_GOOGLE_BASE_API}/drive/v2/files/${fileId}?alt=media&source=downloadUrl`,
    headers: [
      {
        name: 'Authorization',
        value: `Bearer ${googleDriveApiAccessToken}`,
      },
    ],
    filename,
  }) as any;
};

export const uploadAttachmentToGoogleDrive = async (
  formData,
  googleDriveApiAccessToken
) => {
  try {
    const response = await googleApiClient.request({
      method: 'POST',
      url: '/upload/drive/v3/files?uploadType=multipart&supportsAllDrives=true',
      headers: {
        Authorization: `Bearer ${googleDriveApiAccessToken}`,
      },
      data: formData,
      responseType: 'json',
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getAttachmentById = async (fileId, googleDriveApiAccessToken) => {
  try {
    const response = await googleApiClient.request({
      method: 'GET',
      url: `https://www.googleapis.com/drive/v2/files/${fileId}?alt=media&source=downloadUrl`,
      headers: {
        Authorization: `Bearer ${googleDriveApiAccessToken}`,
      },
      responseType: 'blob',
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const initGoogleDriveLongUpload = async ({
  accessToken,
  filename,
  type,
  parents = [],
}) => {
  try {
    const data = {
      mimeType: type,
      name: filename,
    };

    if (parents?.length) {
      data['parents'] = parents;
    }

    const response = await googleApiClient.request({
      method: 'POST',
      url: '/upload/drive/v3/files?uploadType=resumable&supportsAllDrives=true',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data,
    });

    return response;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const uploadGoogleDriveLongUploadPart = async ({
  url,
  accessToken,
  data,
  range,
}) => {
  try {
    const response = await axios.request({
      method: 'POST',
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Range': range,
      },
      data,
    });

    return response;
  } catch (error) {
    if (error.response.status === 308) {
      return error.response;
    }

    commonExceptionHandler(error);
    throw error;
  }
};

export const getHolidaysFromGoogleDrive = async () => {
  try {
    const response = await googleApiClient.request({
      method: 'GET',
      url: `/calendar/v3/calendars/ru.kz%23holiday%40group.v.calendar.google.com/events?key=${environment.NX_GOOGLE_CALENDAR_API_KEY}`
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};
