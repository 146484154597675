import { makeStyles } from '@material-ui/core';
import { DROPDOWN_WIDTH, DROPDOWN_HEIGHT } from './constants';

type stylesProps = {
  isCustomDatePickerOpen: boolean;
  x: number;
  y: number;
};

export default makeStyles(({ palette, spacing }) => ({
  textField: {
    width: '100%',
  },
  dropdownWrapper: {
    minWidth: DROPDOWN_WIDTH,
    height: DROPDOWN_HEIGHT,
    position: 'absolute',
    top: ({ y }: stylesProps) => y,
    left: ({ x }: stylesProps) => x,
    zIndex: 1300,
    backgroundColor: palette.common.white,
    borderRadius: spacing(1),
    marginTop: spacing(1),
    border: `1px solid ${palette.grey[300]}`,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'row',
  },
  optionsWrapper: {
    transitionDuration: '0.5s',
    transitionProperty: 'width',
    minWidth: 240,
    width: ({ isCustomDatePickerOpen }: stylesProps) =>
      isCustomDatePickerOpen ? 240 : '100%',
  },
  optionsList: {
    margin: 0,
    padding: 0,
    marginTop: 8,
  },
  option: {
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 0,
    paddingLeft: spacing(4),
    cursor: 'pointer',
  },
  selectedOption: {
    backgroundColor: 'rgba(38, 40, 66, 0.08)'
  },
  optionWithInput: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    height: 56,
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: palette.grey[300],
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
  daysInput: {
    width: 40,
    height: 40,
    marginLeft: spacing(2),
    marginRight: spacing(2),
  },
  customPickerWrapper: {
    transitionDuration: '0.5s',
    transitionProperty: 'width',
    width: 292,
    height: '100%',
    borderLeft: `1px solid ${palette.grey[300]}`,
  },
  selectedDateDisplay: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 56,
    width: '100%',
  },
  selectedDateTextField: {
    width: 112,
    height: 40,
  },
  selectedDateDash: {
    display: 'block',
    marginLeft: 12,
    marginRight: 12,
    marginTop: -3,
    color: palette.grey[400],
    fontSize: 16,
  },
  monthAndYearSelect: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    width: '100%',
  },
  arrowButton: {
    padding: 12,
    height: 10,
  },
  select: {
    border: 'none !important',
    color: palette.primary[200],
    paddingLeft: '16px !important',
    paddingRight: '16px !important',
  },
  monthsSelect: {
    width: 145,
  },
  weekdaysRow: {
    width: '100%',
    marginTop: spacing(2),
    paddingBottom: spacing(2),
    borderBottom: `1px solid ${palette.grey[300]}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    color: palette.grey[500],
  },
  calendarTile: {
    width: 32,
    height: 32,
    marginLeft: spacing(0.75),
    marginRight: spacing(0.75),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  calendarWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 351,
  },
  calendarMonthWrapper: {
    marginTop: spacing(6.5),
  },
  calendarMonthTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: palette.grey[500],
    marginBottom: spacing(4),
    marginLeft: spacing(4),
  },
  calendarRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  currentMonth: {
    color: palette.grey[600],
    cursor: 'pointer',
  },
  otherMonth: {
    color: palette.grey[400],
    cursor: 'pointer',
  },
  selectedDate: {
    position: 'relative',

    '& span': {
      zIndex: 3,
      color: palette.grey[50],
    },

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      zIndex: 2,
      height: '100%',
      width: '100%',
      background: '#007994',
      borderRadius: 8,
    },

    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      zIndex: 1,
      height: '100%',
      width: 'calc(50% + 6px)',
      backgroundColor: '#EBF5FC',
    },
  },
  dateFrom: {
    '&::after': {
      left: '50%',
    },
  },
  dateTo: {
    '&::after': {
      right: '50%',
    },
  },
  dateInPeriod: {
    position: 'relative',

    '& span': {
      zIndex: 3,
    },

    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      zIndex: 1,
      height: '100%',
      width: 'calc(100% + 12px)',
      left: -6,
      backgroundColor: '#EBF5FC',
    },
  },
  noBackground: {
    '&::after': {
      background: 'none !important',
    },
  },
  analyticsFiltersVariant: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    paddingLeft: spacing(2),

    '& label': {
      position: 'relative',
      fontSize: 14,
      fontWeight: 400,
      height: 40,
      display: 'flex',
      alignItems: 'center',
      paddingTop: 6,
      background: 'none',
    },

    '& > div': {
      border: '1px solid transparent',
      width: 'fit-content',
      padding: '9px 7px',
      margin: 0,
      marginLeft: 4,
      fontSize: 14,
      color: '#1B74B4',
      background: 'none',
    },
  },
  mainFiltersVariant: {
    marginTop: 20,

    '& label': {
      position: 'relative',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      color: '#6D6E85',
      backgroundColor: 'transparent',
      display: 'flex',
      alignItems: 'center',
      paddingTop: 6,
      background: 'none',
    },
    '& label + div': {
      marginTop: '4px !important',
      border: '1px solid rgba(38, 40, 66, 0.12)',
      borderRadius: 8,
    }
  },
  valueChanged: {
    backgroundColor: '#E4F6FE',
  },
}));
