import React, { Dispatch, useMemo } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import { Box, List, ListItem, Tooltip } from '@material-ui/core';

import useUserDropdownStyles from '../UserList.useStyles';
import { ALL_USERS_KEY, NO_DEPARTMENT_KEY } from '../constants';

import ListArrow from 'assets/images/icons/list-arrow-thin.svg';
import { UserSelectDepartmentData } from 'pages/TemplateProcesses/TemplateProcesses.types';
import { UserType } from 'types';
import { useDispatch } from 'react-redux';
import { setSelectedTask } from 'store/approvals';

interface DepartmentList {
  departments: UserSelectDepartmentData[];
  departmentsListTopRef: React.MutableRefObject<any>;
  noDepartmentInfo: UserSelectDepartmentData | {
    total: number;
    selected: number;
  };
  selectedDepartment: string;
  selectedUsersId: string[];
  setSelectedDepartment: Dispatch<string>;
  showAllDepartmentsGroup: boolean;
  showAllUsersGroup: boolean;
  showWithoutDepartmentView?: boolean;
  userOptions: UserType[];
}

const DepartmentList = (props: DepartmentList) => {
  const {
    departments,
    departmentsListTopRef,
    noDepartmentInfo,
    selectedDepartment,
    selectedUsersId,
    setSelectedDepartment,
    showAllDepartmentsGroup,
    showAllUsersGroup,
    showWithoutDepartmentView,
    userOptions,
  } = props;

  const classes = useUserDropdownStyles({showWithoutDepartmentView});
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSelectDepartment = (department): void => {  
    setSelectedDepartment(department);
    dispatch(setSelectedTask({
      selectedDepartment
    }));
  };

  const userDropdownDepartmentsListItemClassNames = useMemo(() => classNames(classes.userDropdownDepartmentsListItem, {
    [classes.userDropdownDepartmentsListItemActive]: selectedDepartment === ALL_USERS_KEY,
  }), [selectedDepartment]);

  return (
    <Box className={classes.userDropdownDepartmentsListWrapper}>
      <Scrollbars className={classes.listScrollbars}>
        <List className={classes.userDropdownDepartmentsList} disablePadding>
          <div ref={departmentsListTopRef}/>
          {(showAllUsersGroup || showAllDepartmentsGroup) &&
            (<ListItem className={userDropdownDepartmentsListItemClassNames} onClick={() => handleSelectDepartment(ALL_USERS_KEY)}>
                <>
                  <Tooltip
                    title={showAllUsersGroup
                          ? t('form_components.select_users.all_users')
                          : t('form_components.select_users.all_departments')}
                    enterDelay={300}
                    placement="top"
                    arrow>
                    <>
                      {showAllUsersGroup &&
                        <span>{t('form_components.select_users.all_users')}</span>
                      }
                      {showAllDepartmentsGroup &&
                        <span>{t('form_components.select_users.all_departments')}</span>
                      }
                    </>
                  </Tooltip>
                  <div className={classes.userDropdownDepartmentsListItemRightPart}>
                    {showAllUsersGroup && <span>({selectedUsersId?.length}/{userOptions?.length})</span>}
                    <img src={ListArrow} alt="List Arrow" />
                  </div>
                </>
              </ListItem>
            )}

          {
            departments
              ?.filter(({ key }) => key !== NO_DEPARTMENT_KEY)
              .map(({ key, name, selected, total }) =>
                <ListItem
                  className={classNames(classes.userDropdownDepartmentsListItem, {
                    [classes.userDropdownDepartmentsListItemActive]: selectedDepartment === key,
                  })}
                  onClick={() => handleSelectDepartment(key)}
                >
                  <Tooltip
                    title={name}
                    enterDelay={300}
                    placement="top"
                    arrow>
                    <span className={classes.userDropdownDepartmentsListDepartmentName}>{name}</span>
                  </Tooltip>
                  <div className={classes.userDropdownDepartmentsListItemRightPart}>
                    <span>({selected}/{total})</span>
                    <img src={ListArrow}/>
                  </div>
                </ListItem>)
          }

          {(noDepartmentInfo?.total > 0) &&
            <ListItem
              className={classNames(classes.userDropdownDepartmentsListItem, {
                [classes.userDropdownDepartmentsListItemActive]: selectedDepartment === NO_DEPARTMENT_KEY,
              })}
              onClick={() => handleSelectDepartment(NO_DEPARTMENT_KEY)}
            >
              <Tooltip
                title={t('form_components.select_users.no_department')}
                enterDelay={300}
                placement="top"
                arrow>
                <span className={classes.userDropdownDepartmentsListDepartmentName}>{t('form_components.select_users.no_department')}</span>
              </Tooltip>
              <div className={classes.userDropdownDepartmentsListItemRightPart}>
                <span>({noDepartmentInfo.selected}/{noDepartmentInfo.total})</span>
                <img src={ListArrow}/>
              </div>
            </ListItem>
          }
        </List>
      </Scrollbars>
    </Box>
  )
}

export default DepartmentList;
