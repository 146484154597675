import { makeStyles } from '@material-ui/core';

export default makeStyles(({ palette, spacing }) => ({
  darkOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: -1,
    background: 'rgba(0, 0, 0, 0.5)',
    transitionProperty: 'opacity, backdropFilter',
    transitionDuration: '0.4s',
  },
  darkOverlayShown: {
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(4px)',
    opacity: 1,
  },
  darkOverlayHidden: {
    width: '100%',
    height: '100%',
    backdropFilter: 'none',
    opacity: 0,
  },
  darkOverlayHigh: {
    zIndex: 150,
  }
}));
