import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ContentLayout } from 'layouts';
import { SearchInput } from './SearchInput';
import { SearchHelper } from './SearchHelper';

import useSearch from './useSearch';
import { SearchList } from './SearchList';

import useStyles from "./useStyles";

const SearchPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    requests,
    error,
    loading,
    totalPages,
    totalElements,
    pageSize,
    currentPage,
    searchText,
    setSearchText,
    selectedRequest,
    handleSearchStart,
    handlePageChange,
    handleTaskSelection,
    handlePageSizeChange,
    onSearchHelperCloseHandler
  } = useSearch();

  return (
    <ContentLayout
      title={t("form_components.select.search_placeholder")}
      hasMainMenuTabs={false}
      headerChild={
        <SearchInput
          loading={loading}
          value={searchText}
          currentPage={currentPage}
          setValue={setSearchText}
          handleSearchStart={handleSearchStart}
        />
      }
    >
      <Box flex="1 0 auto" position="relative">
        <SearchHelper
          pageSize={pageSize}
          currentPage={currentPage}
          searchText={searchText}
          onClose={onSearchHelperCloseHandler}
        />

        {searchText && !!requests?.length && (
          <Box pt={3}>
            <Typography className={classes.text} variant="body1">
              Total {totalElements} requests
            </Typography>
          </Box>
        )}

        <SearchList
          searchText={searchText}
          requests={requests}
          error={error}
          loading={loading}
          totalPages={totalPages}
          pageSize={pageSize}
          currentPage={currentPage}
          selectedRequest={selectedRequest}
          handlePageChange={handlePageChange}
          handleTaskSelection={handleTaskSelection}
          handlePageSizeChange={handlePageSizeChange}
        />
      </Box>
    </ContentLayout>
  );
};

export default SearchPage;
