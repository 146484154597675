import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing }) => ({
  templateProcesses: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 50px)'
  },
  headerWrapper: {
    margin: "12px 0",
    minHeight: 40,
    boxSizing: 'content-box',
    paddingRight: spacing(6),
    paddingLeft: 20,
  },
  templateProcessesTitle: {
    fontSize: 24,
    fontWeight: 500,
    color: '#101F2B'
  },
  templateProcessesProcessName: {
    display: 'flex',
    alignItems: 'center'
  },
  templateProcessesProcessNameHeader: {
    // backgroundColor: '#E4F2FF',
    borderRadius: spacing(2),

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    height: spacing(7),
    width: spacing(7),
  },
  templateProcessesProcessNameHeaderWidthWarning: {
    width: 'calc(28px + 16px + 8px) !important',
  },
  templateProcessesProcessNameHeaderWarningIcon: {
    height: 16,
    width: 16,
    marginRight: 8
  },
  templateProcessesProcessNameHeaderImage: {
    height: 28,
    width: 28,
    minWidth: 28
  },
  templateProcessesProcessNameTitle: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#007994',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    "&:hover":{
      color: '#007994',
    }
  },
  templateProcessesProcessNameSubtitle: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#8A96A1'
  },

  templateProcessesProcessStatus: {
    height: 8,
    width: 8,
    borderRadius: '50%',
    backgroundColor: '#99A5B1',
    flexShrink: 0,
  },
  templateProcessesProcessStatusActive: {
    backgroundColor: '#2DB77B',
    flexShrink: 0,
  },
  templateProcessesProcessStatusTitle: {
    marginLeft: spacing(2),
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.3,
    color: '#101F2B'
  },
  templateProcessesProcessStatusSubtitle: {
    marginLeft: spacing(2),
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#8B8C9E',
  },
  templateProcessesIconButton: {
    '&:hover': {
      borderColor: 'transparent !important',
      backgroundColor: 'transparent !important'
    }
  },
  templateProcessesIconButtonIcon: {
    '& > path': {
      fill: '#1B74B4 !important'
    }
  },
  filterButton: {
    display: 'flex',
    gap: 10,
    padding: '10px 16px',
    borderRadius: 10,
    height: 40,
  },
  filterButtonActive: {
    background: '#F0F0F7',
    color: '#262842'
  },
  popup: {
    position: 'fixed',
    top: 48,
    bottom: 0,
    right: 0,
    zIndex: 100,

    width: 370,
    border: '1px solid rgba(38, 40, 66, 0.08)',

    background: '#fff',

  },
  popupClosed: {
    visibility: 'hidden',
    pointerEvents: 'none'
  },
  searchInput: {
    width: 260,
    height: '40px !important',

    '& input': {
      padding: 0,
    },
    '& > div': {
      borderRadius: 10,
    }
  },
  searchIconWrapper: {
    marginRight: 3,

    '& svg': {
      fill: '#8B8C9E',
      width: 17
    }
  },
  avatar: {
    "h6": {
      marginTop: 10
    }
  },
  tableCell: {
    padding: 8,
  },
  tableFooterHint: {
    color: '#6D6E85'
  },
  templateProcessNameInfo: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 8,
    flexGrow: 1,
    overflow: "hidden"
  },
  tabWrapper: {
    paddingLeft: 20,

    "& > div": {
      marginBottom: 0
    }
  },
  filterControls: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    padding: "0 20px 0 20px",
    marginTop: 12
  }
}));
