import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing }) => ({
  mainBox: {
    // height: "calc(100vh - 265px - 49px)",
    // overflowY: "scroll"
  },
  root: {
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    minWidth: 1060
  },
  container: {
    width: '100%',
    borderColor: '#E0E3EA',
    borderRadius: '15px',
  },
  table: {
    borderCollapse: 'collapse',
    tableLayout: "fixed",

    "& th:first-child, & td:first-child": {
      paddingLeft: 8
    },
    "& th:last-child, & td:last-child": {
      paddingRight: 8
    },
  },
  tableHeaderRow: {
    height: spacing(10),
    background: '#F5F8FA',

    "&:hover $checkbox": {
      visibility: "visible"
    },
    "& $checkbox:has(input:checked)": {
      visibility: "visible"
    },
  },
  tableHeaderRowCell: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.3,
    color: '#465564',
    padding: 8,
    overflow: "hidden",
    width: "max-content",
  },
  tableBodyActionMenuListItem: {
    padding: spacing(2.5, 4),
    borderTop: '1px solid rgba(38, 40, 66, 0.12)',
    '&:first-child': {
      borderTop: 'none',
    },
  },
  tableBodyActionMenuListItemText: {
    color: '#101F2B'
  },
  tableBodyActionMenuListItemIcon: {
    width: 20,
    height: 20,
    marginRight: spacing(2)
  },
  sortButton: {
    display: 'block',
    width: 12,
    height: 12,
    marginLeft: 5,
  },
  checkbox: {
    marginLeft: "5px !important",
    visibility: "hidden",

    "&:has(input:checked": {
      visibility: "visible"
    }
  },
}));
