import React, { memo, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControl } from '@material-ui/core';

import { CustomMaskedInput as inputComponent } from './CustomMaskedInput';
import { AppTextField } from "components";
import { useTranslation } from 'react-i18next';

type Props = {
  name?: string;
  hint?: string;
  mask?: (string | RegExp)[];
  params?: {[key: string]: any};
  isActive: boolean;
}

export const MaskedField = memo(({
  name,
  hint,
  mask: propsMask,
  params,
  isActive
}: Props) => {
  const { t } = useTranslation();
  const {register, formState: {errors}} = useFormContext();

  const mask = useMemo(() => {
    if (propsMask) {
      return propsMask;
    }

    if (params?.mask) {
      return params.mask.split('').map(elem => elem === '9' ? /\d/ : elem);
    }

    return [];
  }, [propsMask, params?.mask]);

  const isMaskWithVisibleSymbols = useMemo(() => {
    if (params?.mask) {
      const filteredMask = params?.mask.replaceAll('9', '');
      return !!filteredMask.trim().length;
    }

    return true;
  }, [params?.mask]);

  const validate = useCallback(value => {
    let valid = true;

    mask.forEach((maskItem, maskIndex) => {
      if (typeof maskItem === 'object') {
        valid = RegExp(maskItem).test(value[maskIndex]);
      } else {
        valid = maskItem === value[maskIndex];
      }
    });

    return valid || t('form_components.required_errors.default') as string;
  }, [mask]);

  const options = useMemo(() => ({
    required: t('form_components.required_errors.default') as string,
    validate
  }), [validate]);

  return (
    <FormControl fullWidth>
      <AppTextField
        {...register(name, options)}
        label={hint}
        disabled={!isActive}
        inputProps={{ mask, 'data-selenium': name, showMask: isMaskWithVisibleSymbols}}
        InputProps={{ inputComponent }}
        InputLabelProps={{ shrink: true }}
        error={!!errors[name]}
        helperText={!!errors[name] && errors[name].message}
      />
    </FormControl>
  );
});
