import { makeStyles } from '@material-ui/core';

type stylesProps = {
  notFullScreen: boolean;
};

export default makeStyles(({ palette, spacing }) => ({
  ErrorPage: ({notFullScreen}: stylesProps) => ({
    position: notFullScreen ? null : "fixed",
    left: 0,
    top: 0,
    width: notFullScreen ? "100%" : '100vw',
    height: notFullScreen ? "100%" :'100vh',
    zIndex: 2000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF'
  }),
  ErrorPageContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorInfoWrapper: {
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 200,
    height: 200,
    marginBottom: 24,
  },
  title: {
    fontSize: 32,
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#262842',
    margin: 0,
    marginBottom: 12,
  },
  message: {
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#6D6E85',
    margin: 0
  },
  backButton: {
    height: 48,
    background: '#E0613A',
    borderRadius: 12,
    paddingLeft: 24,
    paddingRight: 24,
  },
}));
