import { makeStyles } from '@material-ui/core';

export default makeStyles(({ palette, spacing }) => ({
  textField: {
    '& > div': {
      border: '1px solid rgba(38, 40, 66, 0.12)',
      borderRadius: 10,
    }
  },
  arrowsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4
  },
  arrowIcon: {
    width: 14,
    height: 8,
    cursor: 'pointer'
  }
}));
