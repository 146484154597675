import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { NotificationManager } from 'react-notifications';

import useDocumentParamsFormStyles from './DocumentParamsForm.useStyles';
import { Button, Box, Checkbox, Switch, FormControlLabel, Tooltip } from '@material-ui/core';
import { DocumentFieldCard } from './DocumentFieldCard';
import { DocumentFieldsLinkModal } from '../DocumentFieldsLinkModal';
import {
  AllProcessFieldsListItem,
  DocumentTemplate,
  DocumentTemplateField,
  Language, ParallelBranchesGroup, ProcessStep,
} from '../../../../../../TemplateProcesses.types';

import { ReactComponent as CheckboxUncheckedIcon } from 'assets/images/icons/checkbox-unchecked.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/images/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxIndeterminateIcon } from 'assets/images/icons/checkbox-indeterminate.svg';
import PreviewIcon from 'assets/images/icons/preview-document-icon.svg';
import WarningIcon from 'assets/images/icons/warning-sign.svg';

interface DocumentParamsFormProps {
  document: DocumentTemplate;
  focusedDocumentId: string;
  formErrors: { [key: string]: any }[];
  allProcessStepsFields: AllProcessFieldsListItem[];
  parallelBranchesGroups: ParallelBranchesGroup[];
  currentLanguage: Language;
  currentProcessStepOrder: number;
  currentStep: ProcessStep;
  setFocusedDocumentId: (documentId: string) => void;
  handleDocumentPreviewOpen: (documentId: number) => void;
  handleDocumentFieldsAdd: (fields: DocumentTemplateField[]) => void;
  handleDocumentParamsChange: (document: DocumentTemplate) => void;
  handleDocumentChangeUndo: (addedFields: string[], linkedFields: string[], hasSignatureStateChanged: boolean) => void;
  handleDocumentSignatureStepRemovalDialogOpen: () => void;
}

export const DocumentParamsForm = ({
  document,
  focusedDocumentId,
  formErrors,
  allProcessStepsFields,
  parallelBranchesGroups,
  currentLanguage,
  currentStep,
  currentProcessStepOrder,
  setFocusedDocumentId,
  handleDocumentPreviewOpen,
  handleDocumentFieldsAdd,
  handleDocumentParamsChange,
  handleDocumentChangeUndo,
  handleDocumentSignatureStepRemovalDialogOpen,
}: DocumentParamsFormProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useDocumentParamsFormStyles();
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [innerSelectedFields, setInnerSelectedFields] = useState<string[]>([]);
  const [isLinkFieldsModalOpen, setLinkFieldsModalOpen] = useState(false);
  const [addedFields, setAddedFields] = useState<string[]>([]);
  const [linkedFields, setLinkedFields] = useState<string[]>([]);
  const [hasSignatureStateChanged, setSignatureStateChanged] = useState<boolean>(false);

  const handleFieldSelect = (fieldId) => {
    if (selectedFields.includes(fieldId)) {
      setSelectedFields(fields => fields.filter(id => id !== fieldId));
    } else {
      setSelectedFields(fields => [...fields, fieldId]);
    }
  };

  const handleSelectAllFields = () => {
    if (selectedFields?.length === document?.fields?.length) {
      setSelectedFields([]);
    } else {
      setSelectedFields(document?.fields?.map(field => field.id));
    }
  };

  const onFieldsAdd = () => {
    const addedFields = selectedFields.length === 0
                        ? document.fields.filter(field => !field.processFieldId)
                        : document.fields.filter(field => selectedFields.includes(field.id) && !field.processFieldId);
    handleDocumentFieldsAdd(addedFields);
    setAddedFields(fields => [...fields, ...addedFields.map(field => field.id)]);
    setSelectedFields([]);
  };

  const onFieldsLink = (fieldsLinks: { [key: string]: string }) => {
    Object.keys(fieldsLinks).forEach(fieldId => {
        const fieldIndex = document.fields.findIndex(f => f.id === fieldId);
        document.fields[fieldIndex].processFieldId = fieldsLinks[fieldId];
      },
    );
    setLinkedFields(fields => [...fields, ...Object.keys(fieldsLinks)]);

    // check if all signature happen after all fields are filled after linking
    // and remove incorrect signature steps
    const initialSignatureSteps = [...document.signatureSteps];
    const documentFields = document.fields.map(field => field.processFieldId);

    const fieldsSteps = documentFields.map(fieldId => allProcessStepsFields?.find(item => item.field.id === fieldId)?.firstAppearanceStep);
    document.signatureSteps = document.signatureSteps.filter(step => step >= Math.max(...fieldsSteps));
    if (document.signatureSteps.sort().join(',') !== initialSignatureSteps.sort().join(',')) {
      setSignatureStateChanged(true);
      handleDocumentSignatureStepRemovalDialogOpen();
    }

    handleDocumentParamsChange(document);
  };

  const onSignatureToggleChange = () => {
    setSignatureStateChanged(true);
    document.signatureSteps = document.signatureSteps.includes(currentProcessStepOrder)
                              ? document.signatureSteps.filter(v => v !== currentProcessStepOrder)
                              : [...document.signatureSteps, currentProcessStepOrder].sort((a, b) => a - b);
    handleDocumentParamsChange(document);
  };

  const areAllFieldsLinked = useMemo(() =>
      document?.fields?.every(field => field.processFieldId)
    , [document, allProcessStepsFields, currentProcessStepOrder]);

  const isSignatureAvailable = useMemo(() => {
    if (!document || document?.fields?.some(field => !field.processFieldId)) {
      return false;
    }
    // signature is not available for steps with parallel assignees
    if (currentStep?.parallel) {
      return false;
    }

    if (document.hiddenSteps.includes(currentProcessStepOrder)) {
      return false;
    }

    const documentFields = document.fields.map(field => field.processFieldId);
    const fieldsSteps = documentFields.map(fieldId => allProcessStepsFields?.find(item => item.field.id === fieldId)?.firstAppearanceStep);
    return fieldsSteps.every(step => step <= currentProcessStepOrder);
  }, [document, allProcessStepsFields, currentProcessStepOrder]);

  const availableFields = useMemo(() => {
    const fieldsForMapping = {};
    document?.fields.forEach(documentField => {
      fieldsForMapping[documentField.id] = allProcessStepsFields.filter(fieldData => {
        if (!documentField) {
          return false;
        }
        // allow only fields with correct component
        if (fieldData.field.component !== documentField.type) {
          return false;
        }
        // allow fields from current step no matter of step type
        if (fieldData.firstAppearanceStep === currentProcessStepOrder) {
          return true;
        }
        // do not allow fields from future steps
        if (fieldData.firstAppearanceStep > currentProcessStepOrder) {
          return false;
        }
        // do not allow fields from condition step
        if (fieldData.isFieldFromConditionStep) {
          return false;
        }
        // allow fields from previous parallel branches
        // except for fields from current step parallel branches group
        if (fieldData.isFieldFromParallelBranchesGroupStep) {
          const currentStepParallelStepsGroup = parallelBranchesGroups?.find(g => g.steps.flat().includes(currentProcessStepOrder));
          if (currentStepParallelStepsGroup) {
            if (currentStepParallelStepsGroup.id !== fieldData.fieldParallelBranchesGroupStep) {
              return true;
            } else {
              const currentStepBranchIndex = currentStepParallelStepsGroup.steps.findIndex(branch => branch.includes(currentProcessStepOrder));
              const fieldStepBranchIndex = currentStepParallelStepsGroup.steps.findIndex(branch => branch.includes(fieldData.firstAppearanceStep));
              return currentStepBranchIndex === fieldStepBranchIndex;
            }
            return currentStepParallelStepsGroup.id !== fieldData.fieldParallelBranchesGroupStep;
          } else {
            return true;
          }
        }
        return true;
      });
    });
    return fieldsForMapping;
  }, [document, allProcessStepsFields]);

  const hasFieldsToLink = useMemo(() =>
      Object.values(availableFields).some(v => (v as any[])?.length > 0)
    , [availableFields]);

  const signatureUnavailableError = useMemo(() => {
    if (!document || document?.fields?.some(field => !field.processFieldId)) {
      return t('customProcesses.documentParamsForm.signatureValidation.notAllFieldsLinked');
    }

    if (currentStep?.parallel) {
      return t('customProcesses.documentParamsForm.signatureValidation.parallelAssignees');
    }

    const documentFields = document.fields.map(field => field.processFieldId);
    const fieldsSteps = documentFields.map(fieldId => allProcessStepsFields?.find(item => item.field.id === fieldId)?.firstAppearanceStep);
    if (!fieldsSteps.every(step => step <= currentProcessStepOrder)) {
      return t('customProcesses.documentParamsForm.signatureValidation.stepBeforeFieldsFilled');
    }

    if (document.hiddenSteps.includes(currentProcessStepOrder)) {
      return t('customProcesses.documentParamsForm.signatureValidation.hiddenDocument');
    }

    return '';
  }, [document, allProcessStepsFields, currentProcessStepOrder]);

  const handleCancel = () => {
    handleDocumentChangeUndo(addedFields, linkedFields, hasSignatureStateChanged);
    setAddedFields([]);
    setLinkedFields([]);
    setSignatureStateChanged(false);
  };

  const handleSave = () => {
    setFocusedDocumentId('');
    setAddedFields([]);
    setLinkedFields([]);
    setSignatureStateChanged(false);
  };

  const linkFieldsModalFieldsList = useMemo(() => {
    if (innerSelectedFields.length > 0) {
      return innerSelectedFields;
    }

    if (selectedFields.length > 0) {
      return selectedFields;
    }

    return document?.fields?.map(field => field.id);
  }, [innerSelectedFields, selectedFields, document]);

  if (!document) {
    return null;
  }

  // TODO - localization
  return (
    <div className={cn(classes.formRoot)}>
      <Box className={classes.section}>
        <h3 className={classes.formTitle}>
          {t('customProcesses.documentParamsForm.title')}
        </h3>
        <Button
          className={classes.previewButton}
          variant="text"
          endIcon={<img src={PreviewIcon} alt={''}/>}
          onClick={() => handleDocumentPreviewOpen(document?.id)}
        >
          {t('customProcesses.documentParamsForm.previewDocumentButton')}
        </Button>
        <Box className={classes.documentInfoWrapper}>
          <span className={classes.fieldLabel}> {t('customProcesses.documentParamsForm.fields.name')}</span>
          <span className={classes.fieldText}>{document.title}</span>
        </Box>
      </Box>

      <Box className={classes.section}>
        <h3 className={classes.sectionTitle}>{t('customProcesses.documentParamsForm.fieldsSection.title')}</h3>
        <p
          className={classes.sectionInfoMessage}>{t('customProcesses.documentParamsForm.fieldsSection.description')}</p>
        <Box className={classes.fieldsListControls}>
          <Box className={classes.fieldsListControlsLeftSide}>
            <Checkbox
              icon={<CheckboxUncheckedIcon/>}
              checkedIcon={<CheckboxCheckedIcon/>}
              indeterminateIcon={<CheckboxIndeterminateIcon/>}
              color="default"
              className={cn(classes.checkbox)}
              classes={{
                disabled: classes.checkboxDisabled,
                indeterminate: classes.checkboxIndeterminate,
                checked: classes.checkboxChecked,
              }}
              checked={selectedFields?.length === document?.fields?.length}
              indeterminate={selectedFields?.length > 0 && selectedFields?.length < document?.fields?.length}
              onChange={() => handleSelectAllFields()}
            />
            <span onClick={() => handleSelectAllFields()}>
              {t('customProcesses.documentParamsForm.fieldsSection.selectAllCheckboxLabel', { selectedCount: selectedFields?.length })}
            </span>
          </Box>

          <Box className={classes.fieldsListControlsRightSide}>
            <Button
              className={classes.fieldsActionButton}
              variant="text"
              onClick={() => onFieldsAdd()}
              disabled={areAllFieldsLinked}
            >
              {t('customProcesses.documentParamsForm.fieldsSection.buttons.addFields')}
            </Button>

            <Tooltip
              title={t('customProcesses.documentParamsForm.fieldsSection.linkButton.noFieldsError')}
              placement="bottom"
              arrow
              enterDelay={500}
              disableHoverListener={hasFieldsToLink}
              disableTouchListener={hasFieldsToLink}
              disableFocusListener={hasFieldsToLink}
              classes={{ popper: classes.cardTooltipPopper, tooltip: classes.cardTooltip }}
            >
              <span>
                <Button
                  className={classes.fieldsActionButton}
                  variant="text"
                  disabled={!hasFieldsToLink}
                  onClick={() => {
                    setLinkFieldsModalOpen(true);
                  }}
                >
                  {t('customProcesses.documentParamsForm.fieldsSection.buttons.linkFields')}
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>

        <Droppable droppableId="DOCUMENT_FIELDS" isDropDisabled={true}>
          {(provided, snapshot) =>
            <div className={classes.fieldsList} ref={provided.innerRef}>
              {document.fields.map((templateField, index) =>
                <Draggable
                  key={templateField.id}
                  draggableId={templateField.id}
                  isDragDisabled={!!templateField.processFieldId}
                  index={index}>
                  {(provided, snapshot) => (
                    <React.Fragment>
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        isDragging={snapshot.isDragging}
                        className={classes.fieldsListDraggableItem}
                        style={
                          provided.draggableProps
                            .style
                        }>
                        <DocumentFieldCard
                          key={templateField.id}
                          name={templateField.name}
                          type={templateField.type}
                          hasFieldsToLink={hasFieldsToLink}
                          linkedProcessFieldId={templateField.processFieldId}
                          allProcessStepsFields={allProcessStepsFields}
                          onSelect={() => handleFieldSelect(templateField.id)}
                          isSelected={selectedFields?.includes(templateField.id)}
                          dragHandleProps={provided.dragHandleProps}
                          onLinkButtonClick={() => {
                            setInnerSelectedFields(() => [templateField.id]);
                            setLinkFieldsModalOpen(() => true);
                          }}
                        />
                      </Box>

                      {snapshot.isDragging && (
                        <DocumentFieldCard
                          key={templateField.id}
                          name={templateField.name}
                          type={templateField.type}
                          hasFieldsToLink={hasFieldsToLink}
                          linkedProcessFieldId={templateField.processFieldId}
                          dragHandleProps={{}}
                          allProcessStepsFields={allProcessStepsFields}
                          onSelect={() => handleFieldSelect(templateField.id)}
                          isSelected={selectedFields?.includes(templateField.id)}
                          onLinkButtonClick={() => {
                            setInnerSelectedFields(() => [templateField.id]);
                            setLinkFieldsModalOpen(() => true);
                          }}
                        />
                      )}
                    </React.Fragment>
                  )}
                </Draggable>)
              }
            </div>
          }
        </Droppable>

        {formErrors?.[currentProcessStepOrder - 1]?.documents?.includes(document.uniqueId) &&
          <div className={classes.processStepFormErrorMessage}>
            <img src={WarningIcon} alt="warning"/>
            <span>{t('customProcesses.documentParamsForm.errors.hasNotLinkedFields')}</span>
          </div>
        }

      </Box>

      <Box className={classes.section}>
        <h3 className={classes.sectionTitle}>{t('customProcesses.documentParamsForm.signatureSection.title')}</h3>

        {
          isSignatureAvailable
          ?
          <div className={classes.signatureToggleWrapper}>
            <FormControlLabel
              className={classes.toggle}
              control={<Switch
                color="default"
                classes={{
                  track: document.signatureSteps.includes(currentProcessStepOrder)
                         ? classes.switchTrackChecked
                         : classes.switchTrackUnchecked,
                }}
                checked={document.signatureSteps.includes(currentProcessStepOrder)}
                onChange={() => onSignatureToggleChange()}
              />}
              label={t('customProcesses.documentParamsForm.signatureSection.signatureCheckbox')}
            />
          </div>
          :
          <span className={classes.signatureUnavailableBlock}>
          {signatureUnavailableError}
        </span>
        }

      </Box>

      <Box className={classes.buttonsWrapper}>
        <Button className={classes.button} color="secondary"
                onClick={handleCancel}>{t('comment_modal.button_cancel')}</Button>
        <Button className={classes.button} color="primary"
                onClick={handleSave}>{t('buttons.button_save')}</Button>
      </Box>

      <DocumentFieldsLinkModal
        isOpen={isLinkFieldsModalOpen}
        onClose={() => {
          setLinkFieldsModalOpen(false);
          setSelectedFields(() => []);
          setInnerSelectedFields(() => []);
        }}
        onSave={onFieldsLink}
        selectedDocumentFields={linkFieldsModalFieldsList}
        currentDocument={document}
        availableFields={availableFields}
        currentProcessStepOrder={currentProcessStepOrder}
        allProcessStepsFields={allProcessStepsFields}
        parallelBranchesGroups={parallelBranchesGroups}
        currentLanguage={currentLanguage}
      />

    </div>
  );
};
