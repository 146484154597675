import React, { useMemo, memo, useCallback, ReactElement, useEffect } from 'react';

import { theme } from '@dartech/dms-ui';
import { ThemeProvider } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

import { TaskParametersType } from 'types';

import {
  AmountAndCurrency,
  AmountInKzt,
  Attachment,
  Checkbox,
  Countries,
  CurrencyRate,
  Date,
  Email,
  EmployeeSelect,
  Glossary,
  MaskedField,
  Number,
  PhoneNumber,
  ReadOnly,
  ReadOnlyAccessType,
  ReadOnlyNumber,
  ReadOnlyUser,
  Select,
  SelectGlossary,
  Switch,
  TextArea,
  UserSelect,
  RadioButtonGroup,
  LinkField,
  ReadOnlyAmountAndCurrency,
  LinkInput,
  CheckResult,
  EntitiesListDisplay,
  FormButtonField,
  SuppliersVotingResult,
  SuppliersVotingSwitch,
  HiddenField,
  RejectionReason,
  TemplateViewer,
  CompanySelect,
  UserList,
  ReadOnlyMultiSelectGlossary,
} from './Fields';
import { CustomModal } from './Fields/CustomModal';
import { EdsFinalDocuments } from 'pages/Task/TaskForm/Eds/EdsFinalDocuments';

import { useField, useFieldBehaviour, useFieldValueChange, fieldBehaviour } from './hooks';

import { setTaskComponentHidden, setTaskComponentVisible } from '../../../../store/requests';
import { FormFieldProps } from '../types';

import useStyles from './useStyles';

// fix active select color
theme.palette.primary.main = '#1B74B4';

theme.overrides = {
  ...theme.overrides,
  MuiAutocomplete: {
    inputRoot: {
      // fix not centered text in selects
      paddingTop: '2px !important',
    },
    option: {
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
      '&:focus': {
        backgroundColor: theme.palette.grey[100],
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    endAdornment: {
      top: 'auto',
      marginRight: 12,
      color: theme.palette.grey[500],
      '& button': {
        //fix clear icon color
        color: theme.palette.grey[500],
      },
    },
    popupIndicator: {
      color: theme.palette.grey[500],
    },
  },
};

const ACCESS_ATTRIBUTE_NAME__OTHER = 'other';
// TODO - check if used, localize
const ACCESS_ATTRIBUTE_NAME__OTHER__VALIDATION =
  'You should select at least one option';

export const FormField = memo(
  ({
    attribute,
    isTaskDetailsVariant = false,
    isEntityCardVariant = false,
    handleAction,
    tabName = '',
    setActiveTab,
    updateAttachmentsCounter,
    instance = null,
    instancesList = [],
    entitySysName = '',
    stepSysName = '',
    setButtonDisabled,
    setAlwaysActiveButtons,
    setInstancesList = null,
    setMainFormValue = null,
  }: FormFieldProps): ReactElement => {
    const classes = useStyles();
    const { register, watch, setValue, getValues } = useFormContext();
    const formValues = getValues();
    const dispatch = useDispatch();

    useFieldValueChange(attribute);
    const { isActive, isVisible, isDisabled }: fieldBehaviour = useFieldBehaviour(attribute);
    const { hint, params, rules, options, localizedOptions, localizedPossibleResults, masterField, secondMasterField, innerFields } =
      useField(attribute, entitySysName, stepSysName);

    useEffect(() => {
      if (isVisible) {
        dispatch(setTaskComponentVisible(attribute?.name));
      } else {
        dispatch(setTaskComponentHidden(attribute?.name));
      }
    }, [isVisible, attribute?.name]);

    const isAccessType = useMemo((): boolean =>
        attribute.component === 'readonly-accessType',
      [attribute.component]);

    const isEmpty = useMemo((): boolean =>
        isAccessType && !attribute.value,
      [attribute, isAccessType]);

    const validateAttributeNum = useCallback((attributeValue): boolean | string => {
      const otherValue = !!watch(ACCESS_ATTRIBUTE_NAME__OTHER);

      if (!attributeValue && !otherValue) {
        return ACCESS_ATTRIBUTE_NAME__OTHER__VALIDATION;
      }

      return true;
    }, [watch]);

    const getUserListValue = () => {
      try {
        return formValues[attribute.name]?.join(',');
      } catch (e) {
        return attribute.value; 
      }
    }

    const fieldsMap = useMemo(() => ({
        'hidden-field': (
          <HiddenField
            name={attribute.name}
            type={attribute.type}
            rules={rules}
            params={params}
            validateAttributeNum={validateAttributeNum}
          />
        ),
        textarea: (
          <TextArea
            name={attribute.name}
            label={hint}
            rules={rules}
            params={params}
            isActive={isActive}
            isDisabled={isDisabled}
          />
        ),
        'link-input': (
          <LinkInput
            name={attribute.name}
            label={hint}
            rules={rules}
            isActive={isActive}
            isTaskDetailsVariant={isTaskDetailsVariant}
          />
        ),
        select: (
          <Select
            name={attribute.name}
            hint={hint}
            options={options}
            localizedOptions={localizedOptions}
            isActive={isActive}
          />
        ),
        'radio-button-group': (
          <RadioButtonGroup
            name={attribute.name}
            hint={hint}
            rules={rules}
            params={params}
            localizedOptions={localizedOptions}
            isActive={isActive}
          />
        ),
        number: (
          <Number
            name={attribute.name}
            hint={hint}
            rules={rules}
            params={params}
            isActive={isActive}
            isDisabled={isDisabled}
          />
        ),
        currencyRate: (
          <CurrencyRate
            name={attribute.name}
            hint={hint}
            currencyFieldName={params.currencyFieldName}
          />
        ),
        amountInKzt: (
          <AmountInKzt
            name={attribute.name}
            hint={hint}
            amountFieldName={params.amountFieldName}
            currencyRateFieldName={params.currencyRateFieldName}
          />
        ),
        'amount-and-currency': (
          <AmountAndCurrency
            name={attribute.name}
            hint={hint}
            currencies={params.currencies}
            currencyFieldName={params.currencyFieldName}
            isTaskDetailsVariant={isTaskDetailsVariant}
            allowZeroAmount={params.allowZeroAmount}
          />
        ),
        glossary: (
          <Glossary
            name={attribute.name}
            hint={hint}
            params={params}
            masterField={masterField}
            secondMasterField={secondMasterField}
            innerFields={innerFields}
            isActive={isActive}
            isDisabled={isDisabled}
            isTaskDetailsVariant={isTaskDetailsVariant}
            showSingleOptionReadOnly={params.singleValueAsReadOnly}
            rules={rules}
          />
        ),
        'readonly-field': (
          <ReadOnly
            name={attribute.name}
            hint={hint}
            type={attribute.type}
            value={attribute.value}
            component={attribute.component}
            params={params}
            titleVariant={!!params.titleVariant}
            systemVariableName={params.systemVariable}
            profileCompanyFieldName={params.profileCompanyFieldName}
            isTaskDetailsVariant={isTaskDetailsVariant}
            isGlossaryValue={!!params.isGlossaryValue}
            isSelectGlossaryValueSavedById={!!params.isGlossaryValueSavedAsId}
            isValueFromValuesList={!!params.isValueFromValuesList}
            showCustomValue={!!params.showCustomValue}
            displayValue={params.displayValue}
          />
        ),
        'readonly-date': (
          <ReadOnly
            name={attribute.name}
            hint={hint}
            type={attribute.type}
            value={attribute.value}
            component={attribute.component}
            params={params}
            isTaskDetailsVariant={isTaskDetailsVariant}
            systemVariableName={params.systemVariable}
          />
        ),
        'readonly-list': (
          <ReadOnly
            name={attribute.name}
            hint={hint}
            type={attribute.type}
            value={attribute.value}
            component={attribute.component}
            params={params}
            isTaskDetailsVariant={isTaskDetailsVariant}
          />
        ),
        'readonly-accessType': (
          <ReadOnlyAccessType
            name={attribute.name}
            hint={hint}
            value={attribute.value}
          />
        ),
        'readonly-amount-and-currency': (
          <ReadOnlyAmountAndCurrency
            name={attribute.name}
            hint={hint}
            currencyFieldName={params.currencyFieldName}
            isTaskDetailsVariant={isTaskDetailsVariant}
          />
        ),
        'check-status': (
          <CheckResult
            name={attribute.name}
            hint={hint}
            options={params.possibleResults}
            localizedOptions={localizedPossibleResults}
          />
        ),
        'entities-list': (
          <EntitiesListDisplay
            name={attribute.name}
            titleAttribute={params.titleAttribute}
            displayState={params.displayState}
            designVariant={params.designVariant || 'accordion'}
            suppliersListAttribute={params.suppliersListAttribute}
            tabName={tabName}
            updateAttachmentsCounter={updateAttachmentsCounter}
            buttonList={params?.buttonList}
            handleAction={handleAction}
            hasMinimumNumberOfElements={params?.hasMinimumNumberOfElements || false}
            minimum={+params?.minimum}
            errorText={params?.errorText || ''}
            validateByInstancesFieldsEmptiness={params?.validateByInstancesFieldsEmptiness}
            instanceFieldName={params?.instanceFieldName}
            isValidationResultSaved={params?.isValidationResultSaved || false}
            validationResultAttribute={params?.validationResultAttribute}
            setMainFormValue={setValue}
          />
        ),
        file: (
          <Attachment
            name={attribute.name}
            label={hint}
            isReadOnly={!!params.readOnly || isDisabled}
            fileNumberLimit={+params.amount}
            allowedTypes={params.allowedTypes}
            hideAllowedTypes={!!params.hideAllowedTypes}
            fieldRules={rules}
            folderId={params.folderId}
            params={params}
            storage={params.storage}
            variant={params.attachmentDesignVariant || (isTaskDetailsVariant ? 'compact-dashed' : 'big')}
          />
        ),
        datetime: (
          <Date
            name={attribute.name}
            hint={hint}
            rules={rules}
            params={params}
            isDisabled={isDisabled}
          />
        ),
        switch: (
          <Switch
            name={attribute.name}
            hint={hint}
            variantWithLabels={!!params.variantWithLabels}
          />
        ),
        country: <Countries
          name={attribute.name}
          hint={hint}
        />,
        'masked-field': (
          <MaskedField
            name={attribute.name}
            hint={hint}
            params={params}
            isActive={isActive}
          />
        ),
        phone: (
          <PhoneNumber
            name={attribute.name}
            hint={hint}
            params={params}
            isActive={isActive}
          />
        ),
        checkbox: (
          <Checkbox
            name={attribute.name}
            hint={hint}
            rules={rules}
            params={params}
          />
        ),
        email: (
          <Email
            name={attribute.name}
            hint={hint}
            isActive={isActive}
          />
        ),
        // TODO: Исправить все readonly компоненты
        'readonly-number':
          attribute.name === 'currency' ? (
            <ReadOnly
              name={attribute.name}
              hint={hint}
              type={attribute.type}
              component={attribute.component}
              value={attribute.value}
              params={params}
              isTaskDetailsVariant={isTaskDetailsVariant}
            />
          ) : (
            <ReadOnlyNumber
              name={attribute.name}
              hint={hint}
              type={attribute.type}
              isTaskDetailsVariant={isTaskDetailsVariant}
              systemVariableName={params.systemVariable}
            />
          ),
        'user-select': (
          <UserSelect
            name={attribute.name}
            hint={hint}
            params={params}
            rules={rules}
            isDisabled={isDisabled}
          />
        ),
        'readonly-user': (
          <ReadOnlyUser
            name={attribute.name}
            hint={hint}
            params={params}
            isTaskDetailsVariant={isTaskDetailsVariant}
          />
        ),
        'employee-select': (
          <EmployeeSelect
            name={attribute.name}
            hint={hint}
          />
        ),
        selectGlossary: (
          <SelectGlossary
            name={attribute.name}
            hint={hint}
            params={params}
            rules={rules}
          />
        ),
        'link-field': (
          <LinkField
            name={attribute.name}
            hint={hint}
            params={params}
            setActiveTab={setActiveTab}
            isTaskDetailsVariant={isTaskDetailsVariant}
          />
        ),
        edsFinalDocuments: <EdsFinalDocuments params={params}/>,
        modal: (
          <CustomModal
            sysName={attribute.sysName}
            params={params}
            handleAction={handleAction}
          />
        ),
        'action-button': (
          <FormButtonField
            hint={hint}
            behaviour={attribute.behaviour}
            attributeId={attribute.attributeId}
            attributeSysName={attribute.sysName}
            stateOrder={attribute.state_order}
            params={params}
            handleAction={handleAction}
          />
        ),
        'supplier-voting-results': (
          <SuppliersVotingResult
            name={attribute.name}
            suppliersListAttribute={params.suppliersListAttribute}
            dependentModalAttributeSysName={params.dependentModalAttributeSysName}
            handleAction={handleAction}
          />
        ),
        'supplier-voting-toggle': (
          <SuppliersVotingSwitch
            instance={instance}
            instancesList={instancesList}
            setInstancesList={setInstancesList}
            votingStatusAttribute={params.votingStatusAttribute}
            setMainFormValue={setMainFormValue}
          />
        ),
        'rejection-reason': <RejectionReason/>,
        'template-viewer': (
          <TemplateViewer
            name={attribute.name}
            label={hint}
            displayStep={attribute.stepNumber}
            params={params}
            isActive={isActive}
            setButtonDisabled={setButtonDisabled}
            setAlwaysActiveButtons={setAlwaysActiveButtons}
          />
        ),
        'company-select': (
          <CompanySelect
            name={attribute.name}
            hint={hint}
            innerFields={innerFields}
            rules={rules}
          />
        ),
        'user-list': <UserList
          label={hint}
          placeholder={params?.placeholder}
          allowOnlyOneUser={params?.allowOnlyOneUser || false}
          name={attribute.name}
          rules={isDisabled ? {} : rules}
          value={isDisabled ? attribute.value as string : getUserListValue()}
          disabled={isDisabled}
          isPreview={isDisabled}
          clearSelectedOnComponentUnmount={false}
        />,
        'readonly-user-list': <UserList
          label={hint}
          placeholder={params?.placeholder}
          value={attribute.value as string}
          isPreview
          rules={{}}
          disabled={true}
          clearSelectedOnComponentUnmount={false}
        />,
        'readonly-multi-select-glossary': <ReadOnlyMultiSelectGlossary
          name={attribute.name}
          hint={hint}
          params={params}
        />,
        default: (
          <TextArea
            name={attribute.name}
            label={hint}
            isActive={isActive}
            rules={rules}
            params={params}
            isDisabled={isDisabled}
          />
        ),
      }),
      [
        innerFields,
        isActive,
        isDisabled,
        masterField,
        options,
        params,
        rules,
        secondMasterField,
        tabName,
        attribute,
        register,
        validateAttributeNum,
        isTaskDetailsVariant,
        instance,
        instancesList,
        setInstancesList,
        setValue,
        setMainFormValue,
        setAlwaysActiveButtons,
      ],
    );

    const renderField = useMemo(() =>
        fieldsMap[attribute.component] || fieldsMap.default,
      [attribute.component, fieldsMap]);

    const isRequired = useMemo((): boolean =>
        attribute.bsnRules?.some((rule) => rule.type === 'REQUIRED'),
      [attribute.bsnRules]);

    // TODO: Поресерчить как можно исправить кусок кода ниже
    const fieldWrapperClasses = cn(classes.root, {
      [classes.partialWidth]: !!attribute.cssClass,
      [classes[attribute.cssClass]]: !!attribute.cssClass,
      [classes.required]:
      isRequired &&
      attribute.component !== 'radio-button-group' &&
      !(
        attribute.component === 'selectGlossary' &&
        !!params.useActivityToggle
      ),
      [classes.requiredRadioButtonGroup]:
      isRequired && attribute.component === 'radio-button-group',
      [classes.empty]: isEmpty,
      [classes.accessType]: isAccessType,
      [classes.taskDetailsVariant]:
      isTaskDetailsVariant &&
      attribute.component !== 'file' &&
      attribute.component !== 'user-select' &&
      attribute.component !== 'entities-list',
      [classes.taskDetailsVariantAccessType]:
      isTaskDetailsVariant && attribute.component === 'readonly-accessType',
      [classes.taskDetailsVariantReadonlyList]:
      isTaskDetailsVariant && attribute.component === 'readonly-list',
      [classes.taskDetailsVariantRadioButtonGroup]:
      isTaskDetailsVariant && attribute.component === 'radio-button-group',
      [classes.hiddenField]: attribute.component === 'hidden-field',
      [classes.entityCardVariant]:
      isEntityCardVariant &&
      attribute.component !== 'file' &&
      attribute.component !== 'user-select',
      [classes.entityCardVariantAccessType]:
      isEntityCardVariant && attribute.component === 'readonly-accessType',
      [classes.entityCardVariantReadonlyList]:
      isEntityCardVariant && attribute.component === 'readonly-list',
      [classes.entityCardVariantRadioButtonGroup]:
      isEntityCardVariant && attribute.component === 'radio-button-group',
    });

    if (isVisible) {
      return (
        <ThemeProvider theme={theme}>
          <div className={fieldWrapperClasses}>{renderField}</div>
        </ThemeProvider>
      );
    }

    return null;
  },
);
