import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  root: {
    position: 'relative',
    height: 42,
  },
  switchRoot: {
    width: 90,
    height: 40,
    borderRadius: 3,
    position: 'absolute',
    top: 1.2,
    left: 1,
    transitionProperty: 'border, background-color',
    transitionDuration: '0.5s',
    border: `1px solid ${palette.primary[700]}`,
    backgroundColor: palette.primary[700],
  },
  switchRootOverlay: {
    height: 40,
    borderRadius: 3,
    position: 'absolute',
    top: 1.2,
    left: 1,
    transitionProperty: 'left, width',
    transitionDuration: '0.5s',
    width: 90,
    border: '1px solid #B6C0CA',
    backgroundColor: '#DDE3E9',
  },
  switchRootOverlayYes: {
    width: 40,
    left: 50,
  },
  coverBlock: {
    width: 42,
    height: 42,
    borderRadius: 3,
    border: '1px solid #B6C0CA',
    backgroundColor: palette.grey[50],
    position: 'absolute',
    top: 0,
    left: 0,
    transitionProperty: 'left, border-color',
    transitionDuration: '0.5s',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  coverBlockYes: {
    left: 50,
    border: `1px solid ${palette.primary[700]}`,
  },
  checkIcon: {
    width: 18,
    opacity: 0,
    transitionProperty: 'opacity',
    transitionDuration: '0.25s',
  },
  checkIconShown: {
    opacity: 1,
  },
  yesLabel: {
    fontSize: 16,
    color: palette.grey[50],
    position: 'absolute',
    left: 11,
    top: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  noLabel: {
    fontSize: 16,
    color: '#33475B',
    position: 'absolute',
    right: 12,
    top: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
