import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useTaskState } from 'store/requests';

import {
  StepType,
  StructuredHistoryParallelBranches, StructuredHistoryStep,
} from './history.types';

import useParallelBranchesStyles from './useParallelBranchesStyles';
import CircleTertiary from 'assets/images/icons/history-circle-tertiary.svg';
import CircleGrey from 'assets/images/icons/history-circle-grey.svg';
import ArrowGrey from 'assets/images/icons/history-arrow-grey.svg';
import ArrowTertiary from 'assets/images/icons/history-arrow-tertiary.svg';
import { HistoryTabStep } from './HistoryTabStep';

type Props = {
  parallelBranches: StructuredHistoryParallelBranches;
  nodeIndex: number;
  nodeCount: number;
  companyId: string;
};

export const HistoryTabParallelBranches = ({
  parallelBranches,
  nodeIndex,
  nodeCount,
  companyId,
}: Props) => {
  const classes = useParallelBranchesStyles({ isPassedStep: !parallelBranches?.branches.flat().every(step => (step?.data as StructuredHistoryStep)?.isFutureStep) });

  const { data: bpmTask } = useTaskState();
  const { t } = useTranslation();

  const timelineIcon = useMemo(() => {
    const isLastNode = !(parallelBranches?.nextStep || parallelBranches?.parallelBranchHistory || parallelBranches?.parallelHistory || parallelBranches?.conditionHistory);
    if (isLastNode) {
      return !(parallelBranches?.branches[0]?.[0]?.data as StructuredHistoryStep)?.isFutureStep ? CircleGrey : CircleTertiary;
    }

    return !(parallelBranches?.branches[0]?.[0]?.data as StructuredHistoryStep)?.isFutureStep ? ArrowGrey : ArrowTertiary;
  }, [parallelBranches]);

  return (
    <Box className={classes.historyItemWrapper}>
      <div className={classes.timelinePart}>
        <div className={classes.timelinePoint}>
          <img src={timelineIcon} alt=""/>
        </div>
        <div className={classes.timelineLine}></div>
      </div>

      <div
        className={cn(classes.groupWrapper, { [classes.groupWrapperFutureStep]: parallelBranches?.branches.flat().every(step => (step?.data as StructuredHistoryStep)?.isFutureStep) })}>
        <div className={classes.stepsGroupInfo}>
          <span className={classes.stepsGroupTitle}>
            {t(`constructor-${bpmTask.processSysName}.parallelBranches.${parallelBranches?.stepperOrder}.name`, { defaultValue: t(`constructor-${bpmTask.processSysName}.parallelBranches.${parallelBranches.stepperOrder + 1}.name`, { defaultValue: parallelBranches.parallelBranchName }) })}
          </span>
          <span className={classes.stepsGroupBranchesCount}>
            {t('task_data_view.history_tab.parallelBranchesCountTemplate', { count: parallelBranches.branches.length })}
          </span>
        </div>

        <div className={cn(classes.groupedStepsWrapper)}>
          <div className={classes.branchesLine}/>
          {parallelBranches.branches.map((branch, branchIndex) =>
            <div className={classes.branchWrapper}>
              {branch.map((branchNode, branchNodeIndex) =>
                  <HistoryTabStep
                    step={branchNode.data as StructuredHistoryStep}
                    isFirstStep={false}
                    isLastStep={false}
                    companyId={companyId}
                    branchStepItem
                    topBranchStepItem={branchIndex === 0}
                  />
              )}
            </div>,
          )}
        </div>
      </div>
    </Box>
  );
};
