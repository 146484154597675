import {makeStyles} from "@material-ui/core/styles";

export default makeStyles(({ spacing, palette }) => ({
  DirectManagerCheckbox: {

  },
  checkboxWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  checkbox: {
    marginLeft: spacing(-1),
    marginRight: spacing(1),
    '& svg path': {
      fill: '#8B8C9E !important'
    }
  },
  checkboxChecked: {
    '& svg path': {
      fill: '#007994 !important'
    }
  },
  checkboxDisabled: {
    '& svg path': {
      fill: '#2628421F !important'
    }
  },
  checkboxLabel: {
    marginLeft: 8,
  },
  defaultCursor: {
    cursor: 'default'
  },
  infoIcon: {
    height: 20,
    width: 20,
    marginLeft: 4
  },
  tooltipPopper: {
    pointerEvents: 'all',
  },
  helpTooltip: {
    width: 320,
    height: 'max-content',
    minHeight: 'max-content',
  },
  helpTooltipContent: {
    width: 300,
    height: 'max-content',
    minHeight: 'max-content',
    paddingRight: 8,
    '& p': {
      margin: 0,
      marginBottom: spacing(5),
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  checkboxMessageWrapper: {},
  checkboxMessage: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: palette.grey[500]
  },
  usersListTooltip: {
    width: 240,
    height: 120,
    padding: 10,
    fontSize: 12,
    fontWeight: 400,
    color: palette.grey[50],
  },
  usersTooltipContent: {
    overflowY: 'auto',
    '& ul': {
      margin: 0,
      padding: 0,
      paddingLeft: 20,
    },
  }
}));
