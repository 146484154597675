import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({palette, spacing}) => ({
  popover: {
    position: 'absolute',
    top: '50vh',
    left: '50vw'
  },
  templateNameInput: {
    marginTop: spacing(2)
  },
  commentInput: {
    marginTop: spacing(2),

    '& label': {
      fontWeight: 500,
      color: "#6D6E85",
      fontSize: 14,
      position: 'relative',
      display: 'block',
      width: 'fit-content',
    },

    '& div': {
      marginTop: 0,
      borderRadius: 10,
    }
  },
  requiredInputWrapper: {
    '& div': {
      borderRadius: 10,

      '& label': {
        color: "#6D6E85",

        '&::after': {
          display: 'inline-block',
          content: '"*"',
          color: palette.error[600],
          fontSize: 14,
          position: 'relative',
          right: -2,
          top: -2
        }
      }
    },
    '& > div > div > div': {
      paddingTop: "3px !important",
    }
  },
  header:{
    border: "1px solid rgba(38, 40, 66, 0.08)"
  },
  footer: {
    background: "#F7F8FC"
  }
}));

export default useStyles;
