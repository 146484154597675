import React, { useEffect, useState } from 'react';
import { AppTextField } from 'components';
import { Box } from '@material-ui/core';

import topArrowIcon from 'assets/images/icons/sort-asc.svg';
import bottomArrowIcon from 'assets/images/icons/sort-desc.svg';
import useStyles from './useStyles';

type Props = {
  name?: string;
  value?: number;
  minValue?: number;
  maxValue?: number;
  onChange?: (arg: number) => void;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  className?: any;
  error?:boolean;
};

export const NumberInputWithArrows = ({
  name,
  value,
  minValue = null,
  maxValue = null,
  onChange,
  disabled = false,
  loading,
  placeholder = null,
  className,
  error = false,
  ...props
}: Props) => {

  const classes = useStyles();
  const [counter, setCounter] = useState(0);

  const isValidNumber = (value) => {
    if (minValue !== null && value < minValue) {
      return false;
    }
    if (maxValue !== null && value > maxValue) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (value !== counter) {
      setCounter(value);
    }
  }, [value, counter])

  const handleChange = (newValue) => {
    if (disabled) {
      return;
    }

    const hasNonNumberChars = newValue.split('').some(char => !'0123456789'.includes(char));
    if (!hasNonNumberChars && isValidNumber(+newValue)) {
      setCounter(+newValue);
      onChange(+newValue);
    }
    if (+newValue === 0 && minValue > 0) {
      setCounter(minValue);
      onChange(minValue);
    }
  }

  return (
    <AppTextField
      classes={{ root: classes.textField }}
      className={className}
      type="tel"
      value={value}
      error={error}
      disabled={disabled}
      onChange={(event) => handleChange(event.target.value)}
      InputProps={{
        endAdornment:
          <Box flexDirection="column" className={classes.arrowsWrapper}>
            <img className={classes.arrowIcon} src={topArrowIcon} onClick={() => handleChange((counter + 1).toString())}/>
            <img className={classes.arrowIcon} src={bottomArrowIcon} onClick={() => handleChange((counter - 1).toString())}/>
          </Box>,
      }}
    />
  );
};
