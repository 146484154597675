import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Switch,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import useProcessCardPreviewDialogStyles from './ProcessCardPreviewDialog.useStyles';

import CloseIcon from 'assets/images/icons/close-icon-thin-light-grey.svg';
import { ReactComponent as CommentsIcon } from 'assets/images/icons/comments-ticket-icon.svg';
import { ReactComponent as AttachmentsIcon } from 'assets/images/icons/attachments-icon-new.svg';
import {
  ATTACHMENT_FIELD_KEY,
  FIELDS,
  GLOSSARY_MULTI_SELECT_FIELD_KEY,
  MAX_FIELDS_IN_SUMMARY,
  MULTILINE_TEXT_FIELD_KEY
} from '../ProcessSetupSidePanel/ProcessSetupSidePanel.constants';
import {
  ConditionStep,
  ParallelBranchesGroup,
  ProcessStep
} from '../../TemplateProcesses.types';
import { getDisplayedStepOrder } from '../../TemplateProcesses.utils';
import { isFieldFromCurrentConditionBranch } from './utils';

interface ProcessCardPreviewDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSaveProcessStepPreview: (selectedFields: string[]) => void;
  allProcessStepsFields: any[];
  processName: string;
  processDescription: string;
  currentStep?: ProcessStep;
  currentLanguage: string;
  conditions: ConditionStep[];
  parallelBranchesGroups: ParallelBranchesGroup[];
}

export const ProcessCardPreviewDialog = (
  {
    isOpen,
    onClose,
    onSaveProcessStepPreview,
    processName,
    processDescription,
    allProcessStepsFields,
    currentStep,
    currentLanguage,
    conditions,
    parallelBranchesGroups
  }: ProcessCardPreviewDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useProcessCardPreviewDialogStyles();

  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const displayedFields = useMemo(() => {
    return selectedFields?.map(
      (fieldId) =>
        allProcessStepsFields?.find(
          (processField) => processField?.field?.id === fieldId
        )?.field
    );
  }, [selectedFields, allProcessStepsFields]);

  useEffect(() => {
    if (isOpen) {
      setHasChanges(false);
      setSelectedFields(currentStep?.summaryFields);
    }
  }, [currentStep?.summaryFields, isOpen]);

  const possibleCardFields = useMemo(() => {
    if (!currentStep) return [];
    if (currentStep?.stepOrder === 1) {
      return currentStep?.fields
        .filter(
          (field) => {
            const isFieldRequired = allProcessStepsFields?.find(
              (processField) => processField?.field?.id === field?.id
            )?.field.isRequired;

            return field.component !== ATTACHMENT_FIELD_KEY &&
              field.component !== MULTILINE_TEXT_FIELD_KEY &&
              isFieldRequired;
          }
        )
        .map((field) => ({
          id: field.id,
          hint: field.hint,
          component: field.component,
          localizedType: t(FIELDS.find((f) => f.key === field.component).title),
          icon: FIELDS.find((f) => f.key === field.component).iconPath,
          isConditionStepField: false
        }));
    }

    return currentStep?.fields
      .filter((field) => {
        const isFieldRequired = allProcessStepsFields?.find(
          (processField) => processField?.field?.id === field?.id
        )?.field.isRequired;
        return (
          field.component !== ATTACHMENT_FIELD_KEY &&
          field.component !== MULTILINE_TEXT_FIELD_KEY &&
          !field.isEditable &&
          !field.isHidden &&
          isFieldRequired
        );
      })
      .map((field) => {
        const currentField = allProcessStepsFields?.find(
          (processField) => processField?.field?.id === field?.id
        );
        return {
          id: field.id,
          hint: field.hint,
          component: field.component,
          localizedType: t(FIELDS.find((f) => f.key === field.component).title),
          icon: FIELDS.find((f) => f.key === field.component).iconPath,
          // we use isConditionStepField variable to tell user that condition fields might not be displayed if condition is not met;
          // tooltip is shown on non-condition steps or other condition branches
          isConditionStepField:
            currentField?.isFieldFromConditionStep &&
            (!currentStep?.isConditionBranchStep ||
              !isFieldFromCurrentConditionBranch(
                currentField?.firstAppearanceStep,
                currentStep?.stepOrder,
                conditions
              ))
        };
      });
  }, [currentStep, allProcessStepsFields]);

  const toggleField = (fieldId) => {
    setHasChanges(true);
    setSelectedFields((fields) =>
      fields.includes(fieldId)
        ? selectedFields.filter((v) => v !== fieldId)
        : [...fields, fieldId]
    );
  };

  return (
    <Modal className={cn({ [classes.modalWrapperOpen]: isOpen })} open={isOpen}>
      <Box className={classes.modalBody}>
        <div className={classes.modalHeader}>
          <Typography className={classes.title}>
            {t('customProcesses.ProcessCardPreviewDialog.title', {
              stepOrder: getDisplayedStepOrder(
                currentStep,
                conditions,
                parallelBranchesGroups
              )
            })}
          </Typography>

          <IconButton onClick={onClose}>
            <img
              src={CloseIcon}
              alt={'Close modal'}
              className={classes.closeIcon}
            />
          </IconButton>
        </div>

        <div className={classes.modalContent}>
          <div className={classes.cardPreviewInfoWrapper}>
            <p className={classes.cardPreviewInfoTitle}>
              {t('customProcesses.ProcessCardPreviewDialog.cardTitle')}
            </p>
            <p className={classes.cardPreviewInfoMessage}>
              {t('customProcesses.ProcessCardPreviewDialog.cardInfoMessage')}
            </p>
          </div>

          <div className={classes.card}>
            <div className={classes.cardLeftSide}>
              <div className={classes.processBlock}>
                <div
                  className={cn(
                    classes.placeholderBlock,
                    classes.placeholderIcon
                  )}
                />
                <span
                  className={cn(
                    classes.placeholderText,
                    classes.processNamePlaceholderText
                  )}
                >
                  {processName}
                </span>
              </div>

              <div className={classes.summaryBlock}>
                {displayedFields.length === 0 ? (
                  <div className={classes.processDescription}>
                    <Tooltip
                      title={processDescription}
                      placement="bottom"
                      arrow
                      enterDelay={500}
                      classes={{
                        popper: classes.cardTooltipPopper,
                        tooltip: classes.cardTooltipLongText
                      }}
                    >
                      <>{processDescription}</>
                    </Tooltip>
                  </div>
                ) : (
                  displayedFields.map((field) => (
                    <div className={classes.summaryField}>
                      <Tooltip
                        title={
                          field?.hint[currentLanguage] ||
                          t('customProcesses.sidePanel.form.fieldName')
                        }
                        placement="bottom"
                        arrow
                        enterDelay={500}
                        classes={{
                          popper: classes.cardTooltipPopper,
                          tooltip: classes.cardTooltip
                        }}
                      >
                        <div className={classes.summaryFieldHintWrapper}>
                          <span
                            className={cn(
                              'summary-field-hint',
                              classes.summaryFieldHint
                            )}
                          >
                            {field?.hint[currentLanguage] ||
                              t('customProcesses.sidePanel.form.fieldName')}
                            :
                          </span>
                        </div>
                      </Tooltip>
                      <span className={classes.summaryFieldValue}>
                        {field?.component === GLOSSARY_MULTI_SELECT_FIELD_KEY
                          ? t(
                            'customProcesses.cardPreview.fieldValuePlaceholderMulti'
                          )
                          : t(
                            'customProcesses.cardPreview.fieldValuePlaceholderSingle'
                          )}
                      </span>
                    </div>
                  ))
                )}
              </div>

              <div className={classes.statusBlock}>
                <div className={classes.processStatusPlaceholderWrapper}>
                  <div
                    className={cn(
                      classes.placeholderBlock,
                      classes.processStatusPlaceholder
                    )}
                  />
                </div>
                <span className={classes.placeholderText}>
                  {t('customProcesses.cardPreview.status', {
                    defaultValue: 'Status'
                  })}
                </span>
              </div>

              <div className={classes.initiatorBlock}>
                <span className={classes.placeholderText}>
                  {t('customProcesses.cardPreview.initiatorFullName', {
                    defaultValue: 'Initiator full name'
                  })}
                </span>
                <CommentsIcon className={classes.placeholderIcon} />
                <span
                  className={cn(
                    classes.placeholderText,
                    classes.placeholderTextLarger
                  )}
                >
                  0
                </span>
                <AttachmentsIcon className={classes.placeholderIcon} />
                <span
                  className={cn(
                    classes.placeholderText,
                    classes.placeholderTextLarger
                  )}
                >
                  0
                </span>
              </div>
            </div>

            <div className={classes.cardRightSide}>
              <div className={classes.statusIndicatorPlaceholder}>
                <Box position="relative">
                  <CircularProgress
                    variant="determinate"
                    className={classes.wrapperCircle}
                    disableShrink
                    thickness={3}
                    value={100}
                    size={48}
                  />

                  <CircularProgress
                    variant="determinate"
                    value={20}
                    thickness={3}
                    size={48}
                    classes={{ circle: classes.progressCircle }}
                  />
                </Box>
              </div>
            </div>
          </div>

          <p className={classes.possibleFieldsTitle}>
            {t('customProcesses.cardPreview.possibleFieldsTitle', {
              selected: selectedFields?.length,
              max: MAX_FIELDS_IN_SUMMARY,
              defaultValue: `Choose fields to preview (${selectedFields?.length}/${MAX_FIELDS_IN_SUMMARY})`
            })}
          </p>

          <div className={classes.possibleFieldsWrapper}>
            {possibleCardFields?.map((possibleField) => {
              return (
                <div className={classes.possibleFieldCard}>
                  <div className={classes.possibleFieldCardInfo}>
                    <div className={classes.possibleFieldCardIcon}>
                      <img src={possibleField.icon} alt={''} />
                    </div>
                    <div className={classes.possibleFieldCardNameAndType}>
                      <Tooltip
                        title={possibleField.hint[currentLanguage]}
                        enterDelay={500}
                        placement="top"
                        arrow
                      >
                        <span className={classes.possibleFieldName}>
                          {possibleField.hint[currentLanguage]}
                        </span>
                      </Tooltip>
                      <span className={classes.possibleFieldType}>
                        {possibleField.localizedType}
                      </span>
                    </div>
                  </div>
                  <div className={classes.possibleFieldCardToggle}>
                    {possibleField.isConditionStepField && (
                      <span className={classes.conditionFieldPreviewHint}>
                        {t(
                          'customProcesses.ProcessCardPreviewDialog.condition_field_preview_hint'
                        )}
                      </span>
                    )}
                    <Switch
                      checked={selectedFields.includes(possibleField?.id)}
                      classes={{
                        track: selectedFields.includes(possibleField?.id)
                          ? classes.trackChecked
                          : selectedFields.length === MAX_FIELDS_IN_SUMMARY
                            ? classes.trackDisabled
                            : classes.track,
                        switchBase: classes.switchBase,
                        thumb:
                          selectedFields.length === MAX_FIELDS_IN_SUMMARY &&
                          !selectedFields.includes(possibleField?.id)
                            ? classes.circleDisabled
                            : null
                      }}
                      onChange={(e) => toggleField(possibleField.id)}
                      disabled={
                        selectedFields.length === MAX_FIELDS_IN_SUMMARY &&
                        !selectedFields.includes(possibleField?.id)
                      }
                    />
                  </div>
                </div>
              );
            })}

            {possibleCardFields?.length === 0 && (
              <span className={classes.possibleFieldsPlaceholder}>
                {t('customProcesses.cardPreview.noPossibleCardFields', {
                  defaultValue: 'No visible fields to preview'
                })}
              </span>
            )}
          </div>
        </div>

        <div className={classes.modalFooter}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.modalButton}
            onClick={onClose}
          >
            {t('buttons.close')}
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.modalButton}
            disabled={!hasChanges}
            onClick={() => {
              onSaveProcessStepPreview(selectedFields);
              onClose();
            }}
          >
            {t('customProcesses.creationPage.buttons.save')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
