import React, { ReactElement } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import useFieldsListCardStyles from './FieldsListCard.useStyles';

import DragIndicator from 'assets/images/icons/drag-indicator.svg';
import { FieldComponent } from '../../TemplateProcesses.types';

interface ProcessStepCardProps {
  field: FieldComponent,
  isDragged?: boolean;
  rowType?: boolean;
  name?: string;
  type?: string;
}

export const FieldsListCard = ({
  field,
  isDragged = false,
  rowType,
  name,
  type = ''
}: ProcessStepCardProps): ReactElement => {
  const classes = useFieldsListCardStyles();
  const { t } = useTranslation();

  if (rowType) {
    return (
      <Box className={classes.rowCard}>

        <Box className={classes.rowIconWrapper}>
          {field.icon}
        </Box>

        <Box className={classes.rowFieldInfo}>
            <span className={classes.rowFieldName}>{name}</span>
            <span className={classes.secondaryName}>{t(field.title)}{type === 'integrated' ? ` · ${t('customProcesses.creationPage.processForm.fieldChips.integrated')}` : ''}</span>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={cn(classes.card, {[classes.cardDragged]: isDragged})}>
      <img src={DragIndicator} className={classes.dragIndicator} alt=''/>

      <Box className={classes.iconWrapper}>
        {field.icon}
      </Box>

      <Tooltip title={t(field.title)} placement="bottom" arrow enterDelay={500}
                 classes={{ popper: classes.cardTooltipPopper, tooltip: classes.cardTooltip }}>
        <span className={classes.name}>{t(field.title)}</span>
      </Tooltip>
    </Box>
  );
};
