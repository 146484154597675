import React, { ReactElement, useMemo } from 'react';
import cn from 'classnames';
import { Box } from '@material-ui/core';

import { useFieldBehaviour } from 'pages/Task/TaskForm/FormFields/hooks';
import { useTaskState } from 'store/requests';
import { TaskParametersType } from 'types';

import { ActionConfirmationDialog } from '../../ActionConfirmationDialog';

import { FormButton } from './FormButton';
import useFormButtonList from './useFormButtonList';
import useStyles from './useStyles';
import { isButtonVisible } from './utils';

type Props = {
  disabled: boolean;
  buttonDisabled?: boolean;
  alwaysActiveButtons?: string[];
  onClick: any;
  bpmTask?: any;
  taskDetailsVariant?: boolean;
  showButtonsOnBothSides?: boolean;
  showOnlyAllStepsButtons?: boolean;
  validationAttributeList?: TaskParametersType[];
  existingButtonList?: TaskParametersType[];
  checkIfTaskDataIsOutdated?: boolean;
  validateDocumentsSignatures?: boolean;
  onOutdatedTaskClick?: () => void;
  extraButtons?: ReactElement;
  extraButtonsIndex?: number;
  isWatcher?: boolean;
};

export const FormButtonList = ({
  disabled,
  buttonDisabled = false,
  alwaysActiveButtons = [],
  onClick,
  taskDetailsVariant = false,
  showButtonsOnBothSides = false,
  showOnlyAllStepsButtons = false,
  validationAttributeList,
  existingButtonList,
  checkIfTaskDataIsOutdated = false,
  onOutdatedTaskClick = null,
  validateDocumentsSignatures = false,
  extraButtons,
  extraButtonsIndex,
  isWatcher = false,
}: Props) => {
  const classes = useStyles();
  const { data: bpmTask } = useTaskState();
  const { isRuleGroupWorks, getFieldsByListOfRules } = useFieldBehaviour();
  const {
    buttons,
    selectedButton,
    isAssignee,
    isAssigneeCandidate,
    hasAssignee,
    isInitiator,
    isEDSButton,
    isDisabled,
    isCompletedTask,
    isActionConfirmationDialogOpen,
    handleButtonClick,
    clickedButton,
    handleConfirmationDialogClose,
    handleActionConfirmation,
  } = useFormButtonList({
    onClick,
    showButtonsOnBothSides,
    validationAttributeList,
    existingButtonList,
    disabled,
    buttonDisabled,
    alwaysActiveButtons,
    checkIfTaskDataIsOutdated,
    onOutdatedTaskClick,
    validateDocumentsSignatures,
  });

  const leftSideButtons = useMemo(
    () =>
      buttons?.leftSide?.map((button) => ({
        ...button,
        isVisible: isButtonVisible({
          button,
          bpmTask,
          isRuleGroupWorks,
          getFieldsByListOfRules,
          isCompletedTask,
          hasAssignee,
          isAssignee,
          isWatcher,
          isAssigneeCandidate,
          isInitiator,
          showOnlyAllStepsButtons,
        }),
      })) || [],
    // eslint-disable-next-line
    [buttons, bpmTask, isAssignee, isAssigneeCandidate]
  );

  const rightSideButtons = useMemo(
    () =>
      buttons?.rightSide?.map((button) => ({
        ...button,
        isVisible: isButtonVisible({
          button,
          bpmTask,
          isRuleGroupWorks,
          getFieldsByListOfRules,
          isCompletedTask,
          hasAssignee,
          isAssignee,
          isWatcher,
          isAssigneeCandidate,
          isInitiator,
          showOnlyAllStepsButtons,
        }),
      })) || [],
    // eslint-disable-next-line
    [buttons, bpmTask, isAssignee, isAssigneeCandidate]
  );

  const visibleLeftSideButtonsCount = useMemo(() => leftSideButtons?.filter((button) => button.isVisible).length, [leftSideButtons]);

  const visibleRightSideButtonsCount = useMemo(() => rightSideButtons?.filter((button) => button.isVisible).length, [rightSideButtons]);

  const visibleButtonsCount = useMemo(() => visibleLeftSideButtonsCount + visibleRightSideButtonsCount, [
    visibleLeftSideButtonsCount,
    visibleRightSideButtonsCount,
  ]);

  if (
    !(isAssignee || isWatcher) &&
    (buttons.leftSide || buttons.rightSide) &&
    buttons.leftSide.every(
      (button) => !button.showOnlyToInitiator && !button.showOnlyWithoutAssignee && !button.showOnlyOnCompletedTasks
    ) &&
    buttons.rightSide.every((button) => !button.showOnlyToInitiator && !button.showOnlyWithoutAssignee && !button.showOnlyOnCompletedTasks)
  ) {
    return null;
  }

  return (
    <Box
      className={cn(classes.root, {
        [classes.bothSidesButtonsVariant]: showButtonsOnBothSides,
        [classes.taskDetailsVariant]: taskDetailsVariant,
      })}
    >
      {leftSideButtons.length > 0 && (
        <div className={classes.leftSide}>
          {leftSideButtons.map((button, index) => (
            <>
              {index === extraButtonsIndex && extraButtons}

              <FormButton
                index={index}
                isVisible={button?.isVisible}
                button={button}
                isIconButton={button?.iconButton || visibleButtonsCount >= 5}
                disabled={isDisabled(button.action)}
                selectedButton={selectedButton}
                taskDetailsVariant={taskDetailsVariant}
                isEDSButton={isEDSButton}
                onClick={handleButtonClick}
              />
            </>
          ))}
        </div>
      )}

      {rightSideButtons.length > 0 && (
        <div className={classes.rightSide}>
          {rightSideButtons.map((button, index) => (
            <>
              {index + buttons.leftSide?.length === extraButtonsIndex && extraButtons}

              <FormButton
                index={index}
                isVisible={button?.isVisible}
                button={button}
                isIconButton={button?.iconButton || (visibleButtonsCount >= 5 && visibleRightSideButtonsCount >= 4 && index === 0)}
                disabled={isDisabled(button.label)}
                selectedButton={selectedButton}
                taskDetailsVariant={taskDetailsVariant}
                isEDSButton={isEDSButton}
                onClick={handleButtonClick}
              />
            </>
          ))}
        </div>
      )}

      <ActionConfirmationDialog
        isOpen={isActionConfirmationDialogOpen}
        dialogType={clickedButton?.confirmationModalKey}
        defaultTitle={clickedButton?.confirmationModalTitle}
        defaultMessage={clickedButton?.confirmationModalMessage}
        defaultConfirmButtonLabel={clickedButton?.confirmationModalButtonLabel}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleActionConfirmation}
      />
    </Box>
  );
};
