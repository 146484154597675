import React, { memo, useEffect, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { useFormContext, Controller } from 'react-hook-form';
import { theme } from '@dartech/dms-ui';

import { removeSpacesFromNumber } from 'utils/general';

type Props = {
  name: string;
  hint?: string;
  currencyRateFieldName: string;
  amountFieldName: string;
};

const AMOUNT_DEFAULT = 'amount';
const CURRENCY_RATE_DEFAULT = 'currencyRate';

export const AmountInKzt = memo(({
  name,
  hint = 'Amount in KZT',
  currencyRateFieldName,
  amountFieldName,
}: Props) => {
  const {
    watch,
    setValue,
    control
  } = useFormContext();

  const amountInKzt = watch(name);
  const currencyRate = watch(currencyRateFieldName || CURRENCY_RATE_DEFAULT);
  const amount = removeSpacesFromNumber(watch(amountFieldName || AMOUNT_DEFAULT));

  useEffect(() => {
    const newAmountInKzt = (amount * currencyRate).toFixed(2);

    setValue(name, newAmountInKzt);
  }, [name, currencyRate, amount, setValue]);

  const displayValue = useMemo(() => {
    if (!amountInKzt) {
      return `${Number(!!amountInKzt)}.00`;
    }

    const digitSeparatorIndex = `${amountInKzt}`.indexOf('.');
    if (digitSeparatorIndex === -1) {
      return `${amountInKzt}.00`;
    }

    const digitAfterPoint = `${amountInKzt}`.substr(digitSeparatorIndex + 1).length;
    return `${amountInKzt}${digitAfterPoint === 1 ? '0' : ''}`;
  }, [amountInKzt]);

  return (
    <div>
      <Typography>{hint}</Typography>
      <Typography style={{ color: theme.palette.grey[700] }}>
        <NumberFormat
          value={displayValue}
          displayType="text"
          thousandSeparator=" "
          decimalSeparator="."
          inputProps={{'data-selenium': name}}
        />
      </Typography>

      <Controller
        name={name}
        control={control}
        render={() => <input type="hidden" />}
      />
    </div>
  );
});
