import React, { memo, ReactElement, useMemo, useState } from 'react';
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import cn from 'classnames';

import { iconsMap } from 'components/Attachments/constants';
import DownloadIcon from 'assets/images/icons/arrow_download_regular.svg';
import SignedDocumentIcon from 'assets/images/icons/checkmark_circle_regular.svg';
import ErrorIcon from 'assets/images/icons/warning_regular.svg';

import useStyles from './useStyles';
import { useTaskState } from '../../store/requests';

type DocumentCardProps = {
  documentId: number;
  documentName: string;
  fileType: string;
  size: number;
  creationDate: string;
  stepName: string
  needSignature?: boolean
  hasSignatures?: boolean
  isSigned?: boolean;
  isFullySigned?: boolean;
  isPublished?: boolean;
  isLoading?: boolean;
  onSign?: (documentId: number) => Promise<void>;
  onPreview?: (documentId: number) => Promise<void>;
  onDownload?: (documentId: number) => Promise<void>;
}

const DocumentCard = memo(({
  documentId,
  documentName,
  fileType,
  size,
  creationDate,
  stepName,
  needSignature = false,
  hasSignatures = false,
  isSigned = false,
  isFullySigned = false,
  isPublished = false,
  isLoading = false,
  onSign,
  onPreview,
  onDownload,
}: DocumentCardProps): ReactElement => {
  const { hasDocumentsSignatureValidationError } = useTaskState();

  const classes = useStyles({ isSigned });
  const { t } = useTranslation();

  const formattedFileSize = useMemo(() => {
    if (!size) return null;
    const k = 1000;
    const sizes = [t('units.b'), t('units.kb'), t('units.mb'), t('units.gb'), 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return (
      parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    );
  }, [size]);

  const formattedCreationDate = useMemo(() => {
    return moment(creationDate).format('DD.MM.YYYY, HH:mm');
  }, [creationDate]);

  const signatureStatus = useMemo(() => {
    if (isSigned || isFullySigned) {
      return <>
        <img src={SignedDocumentIcon} alt={''} className={classes.documentStatusIcon}/>
        <span>{t('task_data_view.documentCardStatus.signedDigital')}</span>
      </>;
    }

    if (isPublished) {
      if (!needSignature) {
        return <span>{t('task_data_view.documentCardStatus.inProgress')}</span>;
      }

      return <span>{t('task_data_view.documentCardStatus.waiting')}</span>;
    }

    if (hasDocumentsSignatureValidationError) {
      return <>
        <img src={ErrorIcon} alt={''} className={classes.errorIcon}/>
        <span className={classes.error}>{t('task_data_view.documentCardStatus.signRequired')}</span>
      </>;
    }

    return <span>{t('task_data_view.documentCardStatus.draft')}</span>;
  }, [isSigned, isFullySigned, needSignature, hasDocumentsSignatureValidationError]);

  const handleSignature = async () => {
    await onSign(documentId);
  };

  return (
    <div className={classes.card}>
      <div className={classes.cardInfo}>
        <div className={classes.cardIconWrapper}>
          <img src={iconsMap[fileType] || iconsMap['default']} className={classes.cardIcon} alt={''}/>
        </div>
        <div className={classes.cardText}>
          <span className={classes.documentName}>{documentName}</span>
          <span className={classes.documentInfo}>{formattedFileSize} · {formattedCreationDate}</span>
          <span className={classes.stepInfo}><span
            className={classes.cardInfoTitle}>{t('task_data_view.documentCard.step')}:</span> {stepName}</span>
          <span className={classes.signatureStatus}>
              <span className={classes.cardInfoTitle}>{t('task_data_view.documentCard.status')}:</span>
            {' '}
            {signatureStatus}
            </span>
        </div>
      </div>

      <div className={classes.cardActions}>
        {isLoading
         ? <CircularProgress size={16}/>
         : <>
           {isFullySigned &&
             <IconButton
               color={'secondary'}
               className={cn(classes.button, classes.iconButton)}
               onClick={() => onDownload(documentId)}
             >
               <img src={DownloadIcon} alt={''}/>
             </IconButton>
           }
           {(hasSignatures && (!needSignature || isSigned)) &&
             <Button
               className={classes.button} color={'secondary'}
               onClick={() => onPreview(documentId)}>
               {t('task_data_view.documentCard.buttons.view')}
             </Button>
           }
           {(needSignature && !isSigned) &&
             <Button
               className={classes.button}
               color={'primary'}
               onClick={handleSignature}
             >
               {t('task_data_view.documentCard.buttons.sign')}
             </Button>
           }
         </>
        }
      </div>
    </div>
  );
});

export default DocumentCard;
