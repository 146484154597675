import React, { ReactElement, useState, useEffect, useCallback, memo } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

import { getProfileCompanyDataFromLocalStorage } from "utils/user";
import { getCompanyAccessAnswer, getChildrenCompanies, getAllAvailableCompanies } from 'api/requests';
import { AppTextField } from "components";

import { LegalEntity } from "types";

type Props = {
  name?: string;
  label: string;
  value: string;
  error?: string;
  fixSelectedValueWithoutId?: boolean;
  showAllAvailableCompanies?: boolean;
  onSelect: (value: LegalEntity, isDefaultValueSetting?: boolean, isClearing?: boolean) => void;
  onDefaultValueSetting?: () => void;
};

// TODO: Отрефакторить код ниже
// Исправить props
export const SelectLegalEntity = memo(({
  name = 'company-select',
  label,
  value,
  onSelect,
  onDefaultValueSetting = () => {},
  fixSelectedValueWithoutId = false,
  showAllAvailableCompanies = false,
  error = '',
}: Props): ReactElement => {
  const { t } = useTranslation();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [legalEntityList, setLegalEntity] = useState<LegalEntity[]>([]);

  const {
    bin,
    id,
    organization: {
      name: companyName,
      shortName
    }
  } = getProfileCompanyDataFromLocalStorage();

  const setDefaultCompany = useCallback((): void => {
    const defaultCompany: LegalEntity = { bin, id, name: companyName, shortName };

    setLegalEntity([defaultCompany]);
    setIsDisabled(true);

    onSelect(defaultCompany, true);
    onDefaultValueSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName]);

  const getLegalEntityList = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true);
      setErrorMessage('');

      if (showAllAvailableCompanies) {
        const companies = await getAllAvailableCompanies();
        const mappedCompanies = companies.map(company => {return {
          id: company.id,
          name: company.fullName,
          bin: company.fields.bin,
          shortName: company.name
        }});
        setLegalEntity(mappedCompanies);
        return;
      }

      const isAccessExists: boolean = await getCompanyAccessAnswer();
      if (isAccessExists) {
        const parentCompany = { bin, id, name: companyName, shortName };
        const childrenCompanies: LegalEntity[] = await getChildrenCompanies();

        setLegalEntity([parentCompany, ...childrenCompanies]);
        // исправляет не загружающихся пользователей при получении компании
        // из адреса страницы (причина - у компании в таком случае нет id)
        if (fixSelectedValueWithoutId) {
          const selectedCompanyValue = typeof value === 'string' ? {shortName: value} : value;
          const selectedCompany = [parentCompany, ...childrenCompanies]
            .find(c => c?.shortName === selectedCompanyValue['shortName']);
          onSelect(selectedCompany);
        }
        return;
      }

      setDefaultCompany();
    } catch (error) {
      setErrorMessage(error?.response?.data || t('filters.filter_legal_entity_error'));
      setLegalEntity([]);
    } finally {
      setIsLoading(false);
    }
  }, [setDefaultCompany, fixSelectedValueWithoutId, showAllAvailableCompanies]);

  useEffect(() => {
    getLegalEntityList();
  }, [getLegalEntityList]);

  const getOptionLabel = (option: LegalEntity) => {
    return (option && ((typeof option === 'string') ? option : option.shortName)) || '';
  }
  const getOptionSelected = (option: LegalEntity, value: LegalEntity) => {
    return value && option.shortName === value.shortName;
  }

  const renderInput = params => (
    <AppTextField
      {...params}
      label={label}
      placeholder={t('form_components.select.placeholder')}
      error={Boolean(errorMessage || error)}
      helperText={errorMessage || error}
      inputProps={{...params.inputProps, 'data-selenium': name }}
    />
  );

  return (
    <Autocomplete
      fullWidth
      forcePopupIcon
      value={value}
      options={legalEntityList}
      disabled={isLoading || isDisabled}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      renderInput={renderInput}
      onChange={(event: React.ChangeEvent, value: LegalEntity, reason) => {
        onSelect(value, false, reason as string === 'clear');
      }}
    />
  );
});
