import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Slide, Typography } from "@material-ui/core";
import useStyles from "./useStyles";
import { CloseButton, Spinner } from "components";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import AddIcon from "assets/images/icons/add_circle_filled.svg"
import { TemplatePreview } from "../TemplatePreview";
import { TemplatesTableRow } from "pages/Templates/components/TemplatesTable/TemplatesTable.types";
import { ProcessContextType } from "types";
import ProcessContext from "contexts/ProcessContext";
import { getAllTemplates } from "api/requests";
import { TemplateInfoCard } from "./TemplateInfoCard/TemplateInfoCard";

type Props = {
  close: () => void;
  handleNewTemplateProcessDialogOpen: () => void;
  isCreateTemplateDialogOpen: boolean
  isSetupTemplateDialogOpen: boolean
}

type TemplateData = TemplatesTableRow & {stepsCount: number}

// TODO: add tooltips
export const TemplateListDialog = ({ close, handleNewTemplateProcessDialogOpen, isCreateTemplateDialogOpen, isSetupTemplateDialogOpen }: Props) => {
  const classes = useStyles({isCreateTemplateDialogOpen, isSetupTemplateDialogOpen})
  const [isOpened, setIsOpened] = useState(true);

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState<TemplateData[]>([]);
  const { groups }: Partial<ProcessContextType> = useContext(ProcessContext);

  const { t, i18n } = useTranslation()

  const handleClose = () => {
    setIsOpened(false);
  }


  const getTemplates = async () => {
    setIsLoading(true)

    try {
      const data = await getAllTemplates();

      setTemplates(data);
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getTemplates();
  }, [])

  const templateCategoryNames = useMemo(() => {
    if (templates.length < 1) return [];

    return [...new Set(templates.map(template => template.category))]
  }, [templates])

  const translatedCategoryName = useMemo(() => {
    return t(`groups.${selectedCategoryId}.name`, { defaultValue: groups.find(g => g?.sysName === selectedCategoryId)?.name })
  }, [selectedCategoryId, groups, t])


  return <Slide
  in={isOpened}
  onExited={close} // set render condition to false
  direction='down'
  timeout={300}
  >
    <div className={classes.root}>

      <div className={classes.header}>
        <Typography className={classes.modalTitle}>{t("customProcesses.createProcess.header")}</Typography>

        <CloseButton onClick={handleClose} />
      </div>

      <div className={classes.body}>
          <div className={classes.sideMenu}>

            <div className={classes.createProcessBtn} onClick={handleNewTemplateProcessDialogOpen}>
               <div className={classes.addIconWrapper}>
                <img src={AddIcon} alt="add icon" />
              </div>

                <div className={classes.createProcessBtnTextWrapper}>
                    <Typography component="h5">{t("customProcesses.createProcessFromScratch")}</Typography>
                    <Typography className={classes.secondaryText} component="p">{t("customProcesses.createProcessWithParameters")}</Typography>
                </div>
            </div>

            <Typography className={classes.secondaryText} style={{marginTop: "24px"}}>{t("templates.templatesCategories")}</Typography>

            <Box
            onClick={() => setSelectedCategoryId(null)}
            className={classNames(classes.tab, {
              [classes.activeTab]: !selectedCategoryId
            })} mt="12px">
                <span className={classes.tabTitle}>{t("Processes.new_request_templates_all")}</span>
                <span>{templates.length}</span>
            </Box>

            {templateCategoryNames.map(category => {
                const translatedCategoryName = t(`groups.${category}.name`, { defaultValue: groups.find(g => g?.sysName === category)?.name })

               return <Box
               onClick={() => setSelectedCategoryId(category)}
               className={classNames(classes.tab, {
                [classes.activeTab]: selectedCategoryId === category
              })}>
                  <span className={classes.tabTitle}>{translatedCategoryName}</span>
                  <span>{templates.filter(template => template.category === category).length}</span>
              </Box>
            })}

          </div>

          <div className={classes.content}>
              {!selectedCategoryId
              ?
              <Typography component="h3" className={classes.allTemplatesTitle}>{t("templates.allTemplates")}</Typography>
              :
              <Typography component="h3" className={classes.allTemplatesTitle}>{translatedCategoryName}</Typography>
              }

              {isLoading && <Box className={classes.loaderWrapper}>
                <Spinner absolute={false} />
              </Box>}

              {!selectedCategoryId && templateCategoryNames.map(category => {
              const translatedCategoryName = t(`groups.${category}.name`, { defaultValue: groups.find(g => g?.sysName === category)?.name })

              return  <Box>
              <Typography className={classes.categoryTitle}>
                  {translatedCategoryName}
              </Typography>

              <div className={classes.templateCards}>
                {templates.filter(template => template.category === category).map(template => {
                  let info = template.languages[0]
                  const foundLanguage = template.languages.find(attr => attr.language === i18n.language)

                  if (foundLanguage){
                    info = foundLanguage;
                  }

                  return <TemplateInfoCard
                    title={info.processName}
                    description={info.description}
                    stepsCount={template.stepsCount}
                    categoryName={translatedCategoryName}
                    integrations={template?.integration?.split(';') || []}
                    onClick={() => {
                      setSelectedTemplateId(template.processSysName)
                    }}
                    iconPath={template.iconPath}
                  />
                })}
              </div>
            </Box>
            })}


            {selectedCategoryId && <Box>
              <div className={classes.templateCards}>
                {templates.filter(template => template.category === selectedCategoryId).map(template => {
                  let info = template.languages[0]
                  const foundLanguage = template.languages.find(attr => attr.language === i18n.language)

                  if (foundLanguage){
                    info = foundLanguage;
                  }

                  return <TemplateInfoCard
                    title={info.processName}
                    description={info.description}
                    stepsCount={template.stepsCount}
                    categoryName={translatedCategoryName}
                    integrations={template?.integration?.split(';') || []}
                    onClick={() => {
                      setSelectedTemplateId(template.processSysName)
                    }}
                    iconPath={template.iconPath}
                  />
                })}
              </div>
            </Box>}

          </div>
      </div>

      {
        selectedTemplateId && <TemplatePreview
        close={() => {
          setSelectedTemplateId(null)
        }}
        closeTemplateListDialog={handleClose}
        id={selectedTemplateId}
        />
      }

    </div>
  </Slide>
}
