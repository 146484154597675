import { useEffect, useMemo, useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  useTaskState,
  setTaskComponentsDisabled,
  setTaskDocuments,
} from 'store/requests';
import {
  fillDocumentWithFormData,
  getDocument,
  getDocumentAttachments,
  saveTaskState,
} from 'api/requests';
import { downloadAttachmentFromUrl } from 'utils/files';

type Props = {
  isActiveTab?: boolean
  formMethods?: any;
  checkValidationErrors?: () => boolean;
  setErrorMessageLines?: (value: ('fields' | 'documents')[]) => void;
  goToPreviousStep?: () => void;
}

const useTaskDocuments = ({
  isActiveTab = false,
  formMethods,
  checkValidationErrors,
  setErrorMessageLines,
  goToPreviousStep,
}: Props) => {
  const { data: bpmTask, documents } = useTaskState();

  const { getValues } = useFormContext();
  const dispatch = useDispatch();

  const [currentDocumentId, setCurrentDocumentId] = useState(-1);
  const [documentsForSignature, setDocumentsForSignature] = useState([]);
  const [signedDocuments, setSignedDocuments] = useState([]);
  const [publishedDocuments, setPublishedDocuments] = useState([]);
  const [currentLoadingDocumentId, setCurrentLoadingDocumentId] = useState(-1);
  const [documentViewMode, setDocumentViewMode] = useState<'view' | 'sign'>('view');
  const [goBackToFormAfterClose, setGoBackToFormAfterClose] = useState(false);
  const documentIdToOpen = useRef<number | null>(null);
  const viewModeToOpen = useRef<'view' | 'sign'>('view');

  const openDocumentForSignWithValidation = async (documentId) => {
    documentIdToOpen.current = documentId;
    viewModeToOpen.current = 'sign';
    await formMethods?.trigger();
  };

  const openDocumentForPreviewWithValidation = async (documentId) => {
    documentIdToOpen.current = documentId;
    viewModeToOpen.current = 'view';
    await formMethods?.trigger();
  };

  const isSignatureAvailable = useMemo(() =>
      !['Reject', 'Cancel', 'Canceled', 'Completed'].includes(bpmTask.businessTask?.taskStatus)
    , [bpmTask]);

  const hasPartiallyOrFullySignedDocuments = useMemo(() =>
      documents.some(doc =>
        doc.signings.some(signing =>
          // document signature was already created
          signing.status ||
          // or was created on the current step
          (signing.stepOrder === bpmTask?.currentAction?.stepperOrder && doc.isSigned),
        ),
      )
    , [documents, bpmTask?.currentAction?.stepperOrder]);

  const hasPublishedDocuments = useMemo(() =>
      documents.some(doc =>
        doc.isPublished,
      )
    , [documents, bpmTask?.currentAction?.stepperOrder]);

  const hasFullySignedDocuments = useMemo(() =>
      documents.some(doc =>
        doc.signings.every(signing =>
          // document signature was already created
          signing.status ||
          // or was created on the current step
          (signing.stepOrder === bpmTask?.currentAction?.stepperOrder && doc.isSigned),
        ),
      )
    , [documents, bpmTask?.currentAction?.stepperOrder]);

  useEffect(() => {
    if (!formMethods?.formState?.isValidating && documentIdToOpen?.current) {
      const valid = checkValidationErrors();

      if (valid) {
        if (viewModeToOpen.current === 'sign') {
          openDocumentForSign(documentIdToOpen.current);
        } else {
          previewDocument(documentIdToOpen.current);
        }
      } else {
        setErrorMessageLines(['fields']);
      }

      documentIdToOpen.current = null;
    }
  }, [formMethods?.formState?.isValidating]);

  const beforeDocumentSign = async () => {
    const document = documents.find(doc => doc.docflowDocumentId === currentDocumentId);
    if (!(publishedDocuments.includes(currentDocumentId) || document.isPublished)) {
      // save current form values
      const formValues = getValues();
      const data = bpmTask?.setInstanceAttributes({
        values: formValues,
        observerUsers: [],
      });
      await saveTaskState(bpmTask.taskId, data);

      // save published status
      const modifiedDocuments = documents.map(doc => {
        if (doc.docflowDocumentId === currentDocumentId) {
          doc.isPublished = true;
        }

        return doc;
      });
      dispatch(setTaskDocuments(modifiedDocuments));
      setPublishedDocuments(docs => [...docs, currentDocumentId]);
    }
  };

  const openDocumentForSign = async (documentId) => {
    setCurrentLoadingDocumentId(() => documentId);
    setDocumentViewMode(() => 'sign');
    const document = documents.find(doc => doc.docflowDocumentId === documentId);
    if (!document?.isPublished) {
      // fill document with form fields data
      const formValues = getValues();
      const data = bpmTask?.setInstanceAttributes({
        values: formValues,
        observerUsers: [],
      });
      await fillDocumentWithFormData(documentId, bpmTask.processInstanceId, data);
    }

    // hide loader and open document modal
    setCurrentLoadingDocumentId(() => -1);
    setCurrentDocumentId(() => documentId);
  };

  const previewDocument = async (documentId) => {
    setDocumentViewMode(() => 'view');
    const document = documents.find(doc => doc.docflowDocumentId === documentId);
    if (!document?.isPublished) {
      setCurrentLoadingDocumentId(() => documentId);
      const formValues = getValues();
      const data = bpmTask?.setInstanceAttributes({
        values: formValues,
        observerUsers: [],
      });
      await fillDocumentWithFormData(documentId, bpmTask.processInstanceId, data);
      setCurrentLoadingDocumentId(() => -1);
    }

    setCurrentDocumentId(() => documentId);
  };

  const downloadDocument = async (documentId) => {
    setCurrentLoadingDocumentId(() => documentId);
    const documentData = await getDocument(documentId);
    const attachment = await getDocumentAttachments(documentData?.attachmentId);
    await downloadAttachmentFromUrl(attachment.signedLink, documentData.title + '.pdf');
    await downloadAttachmentFromUrl(attachment.link, documentData.title + ' (QR).pdf');
    setCurrentLoadingDocumentId(() => -1);
  };

  useEffect(() => {
    setSignedDocuments(docs => [...docs, ...documents.filter(doc => doc.isSigned).map(doc => doc.docflowDocumentId)]);
  }, [documents])

  const visibleDocuments = useMemo(() =>
      documents.filter(document => document.firstAppearanceStep <= bpmTask.currentAction.stepperOrder)
    , [documents, bpmTask.currentAction.stepperOrder]);

  const groupedDocuments = useMemo(() => {
    const documentsForSign = visibleDocuments
      .filter(document => document.signRequiredSteps.includes(bpmTask.currentAction.stepperOrder));
    const documentsForView = visibleDocuments
      .filter(document => !document.signRequiredSteps.includes(bpmTask.currentAction.stepperOrder));

    setDocumentsForSignature(() =>
      [...documentsForSign.map(doc => doc.docflowDocumentId)],
    );
    return {
      sign: documentsForSign,
      view: documentsForView,
    };
  }, [documents, bpmTask.currentAction.stepperOrder, visibleDocuments]);

  useEffect(() => {
    const publishedDocumentsList = documents.filter(doc => publishedDocuments.includes(doc.docflowDocumentId) || doc?.isPublished);
    const allPublishedDocumentsFields = publishedDocumentsList.flatMap(doc => doc.flowTabFields.map(field => field.templateFieldId));
    dispatch(setTaskComponentsDisabled(allPublishedDocumentsFields));
  }, [documents, publishedDocuments]);

  const onSignComplete = async () => {
    // change status of the document
    setSignedDocuments(docs => [...docs, currentDocumentId]);
    const modifiedDocuments = documents.map(doc => {
      if (doc.docflowDocumentId === currentDocumentId) {
        doc.isSigned = true;
      }

      return doc;
    });
    dispatch(setTaskDocuments(modifiedDocuments));
    // close document modal
    setCurrentLoadingDocumentId(() => -1);
  };

  const handleDocumentClose = () => {
    if (goBackToFormAfterClose && !signedDocuments.includes(currentDocumentId)) {
      goToPreviousStep();
      setGoBackToFormAfterClose(() => false);
    }
    setCurrentDocumentId(-1);
    setCurrentLoadingDocumentId(() => -1);
  };

  const changeCurrentDocument = (documentId) => {
    if (documentsForSignature.includes(currentDocumentId)) {
      openDocumentForSign(documentId);
    } else {
      previewDocument(documentId)
    }
  };

  return {
    visibleDocuments,
    groupedDocuments,
    currentLoadingDocumentId,
    currentDocumentId,
    documentViewMode,
    isSignatureAvailable,
    hasPartiallyOrFullySignedDocuments,
    hasFullySignedDocuments,
    hasPublishedDocuments,
    documentsForSignature,
    signedDocuments,
    beforeDocumentSign,
    openDocumentForSign,
    openDocumentForSignWithValidation,
    openDocumentForPreviewWithValidation,
    previewDocument,
    downloadDocument,
    changeCurrentDocument,
    onSignComplete,
    setSignedDocuments,
    setCurrentDocumentId,
    setCurrentLoadingDocumentId,
    setGoBackToFormAfterClose,
    handleDocumentClose,
  };
};

export default useTaskDocuments;
