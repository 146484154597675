import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  actionButton: {
    borderRadius: "50%",
    '&:hover': {
      borderColor: 'transparent !important',
      backgroundColor: 'transparent !important'
    }
  },
  actionButtonIcon: {
    '& > path': {
      fill: '#1B74B4 !important'
    }
  },

  tableCell: {
    padding: 8,
  },
  titleBox: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  title: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#262842',
    whiteSpace: "nowrap",

    "&:hover":{
      color: '#007994',
    }
  },
  titleIcon: {
    width: 28,
    height: 28,
  },
  groupTitle: {
    color: "#6D6E85",
  },
}));
