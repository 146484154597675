import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { MoreHoriz } from '@material-ui/icons';

// import { useUsersState } from 'store/users';

import { ConfirmDeleteConditionModalContext, FirstErrorStepIndexContext } from '../SetupTemplateProcessesDialog';
import { PROCESS_STEP_CARD_ERROR_WRAPPER_CLASSNAME, ProcessStepCard, ProcessStepTag } from '../index';
import {
  AllProcessFieldsListItem,
  ConditionStep, DocumentTemplate,
  FormField,
  Language,
  ParallelBranchesGroup,
  ProcessStep,
  StepValidationResult,
} from '../../TemplateProcesses.types';
import { CONDITION_TYPES } from '../StepConditionModal/StepConditionModal.constants';
import useConditionStepCardStyles from './ConditionStepCard.useStyles';

import ConditionIcon from 'assets/images/icons/condition-step-icon.svg';
import PlusIcon from 'assets/images/icons/black-plus-sign.svg';
import WarningIcon from 'assets/images/icons/warning-sign.svg';
import { useFormatConditionValue } from 'pages/TemplateProcesses/useFormatConditionValue';
import { ProcessStepAddMenu } from '../ProcessStepAddMenu';
import { getDisplayedStepOrder } from '../../TemplateProcesses.utils';
import { limitStringLength } from 'utils/user';

interface ConditionStepCardProps {
  isAddConditionButtonAvailable?: boolean;
  isAddParallelBranchesButtonAvailable?: boolean;
  tagSize?: 'small' | 'medium';
  stepOrderDelta?: number;
  currentLanguage: Language;
  conditionStep: ConditionStep;
  conditions: ConditionStep[];
  parallelBranchesGroups: ParallelBranchesGroup[];
  documents: DocumentTemplate[];
  // processSteps: ProcessStep[];
  positiveBranch: ProcessStep[];
  negativeBranch: ProcessStep[];
  formErrors: StepValidationResult[];
  allProcessStepsFields: AllProcessFieldsListItem[];
  departmentsList: any[];
  directManagerSteps: number[];
  performerSelectionSteps: number[];
  isReadonlyView: boolean;
  isEditDisabled?: boolean;
  isAddCardButtonDisabled?: boolean;
  isActive?: boolean;
  index?: number;
  currentStepIndex?: number;
  selectedItemType?: 'processStep' | 'condition' | 'parallelBranchesGroups';
  handleAddCard: () => void;
  handleAddConditionBranchStep: (branchType: 'positive' | 'negative', stepPosition: number) => void;
  handleAddCondition: () => void;
  handleAddParallelStepsGroup: () => void;
  handleProcessCardClick: (processStep: ProcessStep) => void;
  handleConditionCardClick: () => void;
  isTemplatePreview?: boolean;
  isFlowPreview?: boolean;
}

export const ConditionStepCard = ({
  isAddConditionButtonAvailable = true,
  isAddParallelBranchesButtonAvailable = true,
  tagSize = 'small',
  // stepOrderDelta = 0,
  conditions,
  parallelBranchesGroups,
  documents,
  currentLanguage,
  conditionStep,
  positiveBranch,
  negativeBranch,
  formErrors,
  departmentsList,
  directManagerSteps,
  performerSelectionSteps,
  allProcessStepsFields,
  isReadonlyView = false,
  isEditDisabled = false,
  isAddCardButtonDisabled = false,
  isActive = false,
  index,
  currentStepIndex,
  selectedItemType,
  handleAddCard,
  handleAddConditionBranchStep,
  handleAddCondition,
  handleAddParallelStepsGroup,
  handleProcessCardClick,
  handleConditionCardClick,
  isTemplatePreview,
  isFlowPreview,
}: ConditionStepCardProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useConditionStepCardStyles();
  // const { users } = useUsersState();

  const { index: firstErrorStepIndex, setEl, ref: firstErrorStepEl } = useContext(FirstErrorStepIndexContext) || {};
  const { formatConditionValue, conditionValueFormatted } = useFormatConditionValue();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [addMenuAnchorEl, setAddMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [addBranchStepMenuAnchorEl, setAddBranchStepMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [branchType, setBranchType] = useState<'positive' | 'negative'>('positive');

  const rootRef = useRef(null);

  const {
    setIsOpenConfirmConditionDeleteModal,
    setDataForConditionDelete,
  } = useContext(ConfirmDeleteConditionModalContext);

  const conditionSentenceTemplateKey = useMemo(() => {
    const field = allProcessStepsFields.find(item => item?.field.id === conditionStep?.condition?.field)?.field;
    if (field) {
      return CONDITION_TYPES[field.component]?.find(item => item.key === conditionStep?.condition?.type)?.template;
    }
    return '';
  }, [conditionStep, allProcessStepsFields]);

  const conditionFieldName = useMemo(() => {
    const field = allProcessStepsFields.find(item => item?.field.id === conditionStep?.condition?.field)?.field;
    return field?.hint[currentLanguage] || '';
  }, [conditionStep, allProcessStepsFields, currentLanguage]);

  const getFormattedConditionValue = useCallback(async () => {
    const field = allProcessStepsFields.find(item => item?.field.id === conditionStep?.condition?.field)?.field;

    if (!field || !conditionStep) return;

    formatConditionValue({
      fieldType: field?.component,
      value: conditionStep?.condition?.value,
      fieldParams: field?.params || {}
    });
  }, [conditionStep?.condition?.field, conditionStep?.condition?.value]);

  const hasErrors = useMemo(() =>
      formErrors[conditionStep?.previousStepOrder - 1]?.scriptName.length > 0
      || formErrors[conditionStep?.previousStepOrder - 1]?.scriptCondition
      || formErrors[conditionStep?.previousStepOrder - 1]?.lengthLimit.scriptName.length > 0
    , [formErrors, conditionStep]);

  useEffect(() => {
    if (firstErrorStepIndex === index) {
      setEl?.(rootRef.current);
    }
  }, [firstErrorStepIndex]);

  useEffect(() => {
    getFormattedConditionValue();
  }, [getFormattedConditionValue]);

  return (
    <div ref={rootRef} className={cn(classes.wrapper, {[classes.previewWrapper]: isTemplatePreview})}>

      {!isTemplatePreview && !isReadonlyView &&
        (<IconButton
          className={classes.processStepAddStep}
          onClick={({ currentTarget }) => {
            setAddMenuAnchorEl(currentTarget);
          }}
          disabled={isAddCardButtonDisabled}>
          <img src={PlusIcon} className={classes.processStepAddStepIcon}/>
        </IconButton>)
      }

      <Box className={cn({
        [classes.conditionCardWrapperWithError]: hasErrors,
        [PROCESS_STEP_CARD_ERROR_WRAPPER_CLASSNAME]: hasErrors,
      })}>
        <Box
          className={cn(classes.processStepCard, {
            [classes.processStepCardActive]: isActive,
            [classes.processStepCardError]: hasErrors && !isActive,
          })}
          onClick={handleConditionCardClick}
        >
          <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <ProcessStepTag
              size={tagSize}
              stepOrder={conditionStep?.previousStepOrder}
              isConditionStep
            />

            {(!isTemplatePreview && !isReadonlyView && !isEditDisabled) && (
              <IconButton
                size="small"
                onClick={({ currentTarget }) => {
                  setAnchorEl(currentTarget);
                }}
              >
                <MoreHoriz fontSize="small"/>
              </IconButton>
            )}
          </div>

          <Box pt={3}>
            {conditionStep?.stepName[currentLanguage]
             ? <Typography
               className={classes.processStepCardName}>{conditionStep?.stepName[currentLanguage]}</Typography>
             : <Typography
               className={classes.processStepCardNamePlaceholder}>{t('customProcesses.processStep.conditionNamePlaceholder')}</Typography>
            }
          </Box>

          <Box className={classes.stepCondition}>
            <img src={ConditionIcon} className={classes.processStepCardIcon}/>
            {conditionStep?.condition?.field
             ?
             <Typography className={classes.processStepCardText}>
               <Trans
                 i18nKey={conditionSentenceTemplateKey}
                 values={{ field: conditionFieldName, value: limitStringLength(conditionValueFormatted, 25) }}
               >
                 If <span>conditionFieldName</span> is less than <span>conditionValueFormatted</span>
               </Trans>
             </Typography>
             :
             <Typography className={classes.processStepCardNamePlaceholder}>
               {t('customProcesses.processStep.conditionEmpty')}
             </Typography>
            }
          </Box>
        </Box>

        {hasErrors && !isFlowPreview &&
          <div className={classes.processStepCardErrorMessage}>
            <img src={WarningIcon} alt="warning"/>
            <span>{t('customProcesses.creationPage.processFormErrors.requiredFieldsNotFilled')}</span>
          </div>
        }
      </Box>

      <Box className={classes.branchesWrapper}>
        <div className={classes.topLine}>
          <Typography className={cn(classes.branchLabel, classes.leftBranchLabel)}>
            {t('customProcesses.processStep.conditionBranches.yes')}
          </Typography>
          <Typography className={cn(classes.branchLabel, classes.rightBranchLabel)}>
            {t('customProcesses.processStep.conditionBranches.no')}
          </Typography>
        </div>

        <Box className={classes.branchCardsWrapper}>
          <div className={classes.branchWrapper}>
            {(conditionStep?.hasPositiveBranch && positiveBranch.length > 0) ?
             <>
               {positiveBranch?.map((step, index) => {
                   const documentSignaturesCount = documents?.filter(doc => doc?.signatureSteps?.includes(step?.stepOrder))?.length;

                   return <ProcessStepCard
                     isFlowPreview={isFlowPreview}
                     isTemplatePreview={isTemplatePreview}
                     tagStepOrder={getDisplayedStepOrder(step, conditions, parallelBranchesGroups)}
                     language={currentLanguage}
                     processStep={step as ProcessStep}
                     index={step?.stepOrder - 1}
                     documentSignaturesCount={documentSignaturesCount}
                     stepErrors={formErrors?.length ? formErrors[step?.stepOrder - 1] : {} as StepValidationResult}
                     departmentsList={departmentsList}
                     isActive={selectedItemType === 'processStep' && currentStepIndex === step?.stepOrder - 1}
                     hideLines
                     isMenuButtonShown={positiveBranch?.length + negativeBranch?.length > 1}
                     isAddConditionButtonAvailable={false}
                     isAddParallelBranchesButtonAvailable={false}
                     isDirectManagerStep={directManagerSteps?.includes(step?.stepOrder)}
                     isPerformerStep={performerSelectionSteps?.includes(step?.stepOrder - 1)}
                     isAutostartActive={false}
                     handleClick={() => handleProcessCardClick(step)}
                     handleAddCard={() => {
                       handleAddConditionBranchStep('positive', conditionStep?.positiveBranch.indexOf(step?.stepOrder));
                     }}
                     isReadonlyView={isReadonlyView}
                   />;
                 },
               )}

               <div
                 className={cn(classes.addBranchStepButtonWrapper, { [classes.addBranchStepButtonWrapperPreview]: isTemplatePreview })}>
                 {(!isFlowPreview && !isTemplatePreview && !isReadonlyView && !isEditDisabled) && <IconButton
                   className={classes.addBranchStepButton}
                   onClick={({ currentTarget }) => {
                     setBranchType(() => 'positive');
                     setAddBranchStepMenuAnchorEl(currentTarget);
                   }}
                 >
                   <img src={PlusIcon} className={classes.processStepAddStepIcon}/>
                 </IconButton>}
               </div>
             </> :
             <div
               className={cn(classes.stepCardPlaceholder, { [classes.stepCardPlaceholderPreview]: isTemplatePreview })}>
               {(!isFlowPreview && !isTemplatePreview && !isReadonlyView && !isEditDisabled) &&
                 <IconButton
                   className={classes.processStepAddConditionStep}
                   onClick={({ currentTarget }) => {
                     setBranchType(() => 'positive');
                     setAddBranchStepMenuAnchorEl(currentTarget);
                   }}
                   disabled={isAddCardButtonDisabled}>
                   <img src={PlusIcon} className={classes.processStepAddStepIcon}/>
                 </IconButton>
               }
             </div>}
          </div>

          <div className={classes.branchWrapper}>
            {(conditionStep?.hasNegativeBranch && negativeBranch.length > 0) ?
             <>
               {negativeBranch?.map((step, index) => {
                   const documentSignaturesCount = documents?.filter(doc => doc.signatureSteps.includes(step?.stepOrder))?.length;

                   return <ProcessStepCard
                     isFlowPreview={isFlowPreview}
                     isTemplatePreview={isTemplatePreview}
                     tagStepOrder={getDisplayedStepOrder(step, conditions, parallelBranchesGroups)}
                     language={currentLanguage}
                     processStep={step as ProcessStep}
                     index={step?.stepOrder - 1}
                     documentSignaturesCount={documentSignaturesCount}
                     stepErrors={formErrors?.length ? formErrors[step?.stepOrder - 1] : {} as StepValidationResult}
                     departmentsList={departmentsList}
                     isActive={selectedItemType === 'processStep' && currentStepIndex === step?.stepOrder - 1}
                     hideLines
                     isMenuButtonShown={positiveBranch?.length + negativeBranch?.length > 1}
                     isAddConditionButtonAvailable={false}
                     isAddParallelBranchesButtonAvailable={false}
                     isDirectManagerStep={directManagerSteps?.includes(step?.stepOrder)}
                     isPerformerStep={performerSelectionSteps?.includes(step?.stepOrder - 1)}
                     isAutostartActive={false}
                     handleClick={() => handleProcessCardClick(step)}
                     handleAddCard={() => {
                       handleAddConditionBranchStep('negative', conditionStep?.negativeBranch.indexOf(step?.stepOrder));
                     }}
                     isReadonlyView={isReadonlyView}
                   />;
                 },
               )}

               <div
                 className={cn(classes.addBranchStepButtonWrapper, { [classes.addBranchStepButtonWrapperPreview]: isTemplatePreview })}>
                 {(!isFlowPreview && !isTemplatePreview && !isReadonlyView && !isEditDisabled) && <IconButton
                   className={classes.addBranchStepButton}
                   onClick={({ currentTarget }) => {
                     setBranchType(() => 'negative');
                     setAddBranchStepMenuAnchorEl(currentTarget);
                   }}
                 >
                   <img src={PlusIcon} className={classes.processStepAddStepIcon}/>
                 </IconButton>}
               </div>
             </> :
             <div
               className={cn(classes.stepCardPlaceholder, { [classes.stepCardPlaceholderPreview]: isTemplatePreview })}>
               {(!isFlowPreview && !isTemplatePreview && !isReadonlyView && !isEditDisabled) &&
                 <IconButton
                   className={classes.processStepAddConditionStep}
                   onClick={({ currentTarget }) => {
                     setBranchType(() => 'negative');
                     setAddBranchStepMenuAnchorEl(currentTarget);
                     // handleAddConditionBranchStep('negative', 0);
                   }}
                   disabled={isAddCardButtonDisabled}>
                   <img src={PlusIcon} className={classes.processStepAddStepIcon}/>
                 </IconButton>
               }
             </div>}
          </div>
        </Box>

        <div className={classes.bottomLine}/>
      </Box>

      <ProcessStepAddMenu
        anchorElement={addMenuAnchorEl}
        handleAddCondition={handleAddCondition}
        handleAddParallelStepsGroup={handleAddParallelStepsGroup}
        handleAddStep={handleAddCard}
        hide
        isAddConditionButtonAvailable={isAddConditionButtonAvailable}
        isAddParallelBranchesButtonAvailable={isAddParallelBranchesButtonAvailable}
        open={Boolean(addMenuAnchorEl)}
        setAnchorElement={setAddMenuAnchorEl}
      />

      <ProcessStepAddMenu
        anchorElement={addBranchStepMenuAnchorEl}
        handleAddCondition={handleAddCondition}
        handleAddParallelStepsGroup={handleAddParallelStepsGroup}
        handleAddStep={() => {
          const stepPosition = branchType === 'positive'
                               ? conditionStep?.positiveBranch.length
                               : conditionStep?.negativeBranch.length;
          handleAddConditionBranchStep(branchType, stepPosition);
        }}
        hide
        isAddConditionButtonAvailable={false}
        isAddParallelBranchesButtonAvailable={false}
        open={Boolean(addBranchStepMenuAnchorEl)}
        setAnchorElement={setAddBranchStepMenuAnchorEl}
      />

      <Menu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        MenuListProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
          },
        }}
        PaperProps={{
          style: {
            border: '1px solid rgba(38, 40, 66, 0.08)',
            boxShadow: '0px 8px 16px rgba(38, 40, 66, 0.04)',
            borderRadius: 10,
            marginTop: 4,
          },
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => {
          setIsOpenConfirmConditionDeleteModal(true);
          setDataForConditionDelete(conditionStep);
          setAnchorEl(null);
        }}>
          <Box>
            {t('customProcesses.creationPage.buttons.deleteCondition')}
          </Box>
        </MenuItem>
      </Menu>

    </div>
  );
};
