import React from 'react';
import { Box, Button, IconButton, Typography, Modal } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

import { ConditionStep, ParallelBranchesGroup, ProcessStep } from '../../TemplateProcesses.types';

import useStyles from './DeleteProcessStepConfirmationModal.useStyles';
import CloseIcon from '@material-ui/icons/Close';
import { getDisplayedStepOrder } from '../../TemplateProcesses.utils';

type Props = {
  isOpen: boolean,
  onClose: () => void
  handleStepDelete: (step, isPerformerStep: boolean) => void
  data: {
    processStep: ProcessStep;
    isPerformerStep: boolean
  },
  conditions: ConditionStep[],
  parallelBranchesGroups: ParallelBranchesGroup[]
};

export const DeleteProcessStepConfirmationModal = ({ isOpen, onClose, handleStepDelete, data, conditions, parallelBranchesGroups }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Modal open={isOpen}>
      <Box className={classes.modalBody}>
        <div className={classes.modalHeader}>
          <Typography className={classes.title}>
            <Trans i18nKey="customProcesses.creationPage.deleteModal.title"
                   values={{ value: getDisplayedStepOrder(data?.processStep, conditions, parallelBranchesGroups) }}/>
          </Typography>

          <IconButton onClick={onClose}>
            <CloseIcon className={classes.closeIcon}/>
          </IconButton>
        </div>

        <div className={classes.modalContent}>
          <Typography className={classes.description}>
            {t('customProcesses.creationPage.deleteModal.description')}
          </Typography>
        </div>

        <div className={classes.modalFooter}>
          <Button color="secondary" variant="contained" onClick={onClose} className={classes.modalButton}>
            {t('comment_modal.button_cancel')}
          </Button>

          <Button color="primary" variant="contained" className={classes.modalButton}
                  onClick={() => {
                    handleStepDelete(data?.processStep, data?.isPerformerStep);
                    onClose();
                  }}>
            {t('customProcesses.creationPage.buttons.delete')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};


