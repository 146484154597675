import React, {ReactElement, useMemo} from "react";

import { Box, Tooltip } from '@material-ui/core';

import { UserDropdownCardProps } from 'pages/Task/TaskForm/types';
import useUserDropdownStyles from "./UserList.useStyles";
import { UserAvatar } from '../../../../../../components/User/UserAvatar';

export const UserDropdownCard = ({ 
  user, showDepartmentName = false, departments = [] }: UserDropdownCardProps): ReactElement => {
  
  const classes = useUserDropdownStyles({});

  const userDepartmentName = useMemo(() => {
    const usersDepartment = departments.find(({key}) => key === user?.department || !user?.department && key === 'no_department');
    return usersDepartment?.name
  }, [user, departments])

  return <Tooltip
    title={showDepartmentName ? (<>{user?.fullName}<br/>{userDepartmentName}</>) : user?.fullName }
    enterDelay={300}
    placement="top"
    arrow>
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent={"center"}>
      <UserAvatar user={user} avatarSize={24} />
      <span className={classes.userCardFullName}>{user?.fullName}</span>
      {showDepartmentName && <span className={classes.userCardDepartment}>{userDepartmentName}</span>}
    </Box>
  </Tooltip>
}
