import { makeStyles } from '@material-ui/core';

type StyleProps = {
  hasConditions?: boolean,
  hasParallelStepsGroups?: boolean
}

export default makeStyles(({ spacing, palette }) => ({
  dialogContentSidePanel: {
    background: '#F7F8FC',
    borderRight: '1px solid rgba(38, 40, 66, 0.12)',
    overflow: 'hidden',
    height: '100%',
    transitionDuration: '0.5s',
    transitionProperty: 'width',
    width: ({hasConditions, hasParallelStepsGroups}: StyleProps) => hasConditions || hasParallelStepsGroups ? "unset" : 495,
    flexGrow: ({hasConditions, hasParallelStepsGroups}: StyleProps) => hasConditions || hasParallelStepsGroups ? 1 : "unset",
  },
  dialogContentGridPathTitle: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.3,
    color: '#101F2B',
    marginBottom: spacing(4),
  },
  tabsList: {
    height: 40,
    paddingLeft: 42,
    backgroundColor:'#F7F8FC',
    borderBottom: '1px solid rgba(38, 40, 66, 0.12)',
  },
  tab: {
    backgroundColor: 'transparent',
    color: '#6D6E85',
    borderBottom: '2px solid transparent',
    borderRadius: '0px !important',
    height: '100%',
    fontWeight: 500,
    fontSize: 14,
    textTransform: 'none',
    padding: spacing(2.5, 3),
    minWidth: 'fit-content',
    transitionDuration: '0.3s',
    '&:hover': {
      backgroundColor: 'rgba(38, 40, 66, 0.04)'
    },
    '& span': {
      flexDirection: 'row'
    },
    '&.Mui-selected': {
      border: 'none !important',
      borderBottom: '3px solid #007994 !important',
      color: '#007994',
    },
    '&.Mui-disabled': {
      color: '#A9A9B8',
      backgroundColor: 'transparent !important'
    }
  },
  tabIcon: {
    height: 16,
    maxHeight: 16,
    width: 'auto',
    marginBottom: '0 !important',
    marginRight: spacing(1.5),
  },
  tabContent: {
    overflow: 'auto',
    height: 'calc(100% - 40px)',
    padding: spacing(6, 6),
  },
  tabContentLimitedHeight: {
    maxHeight: 'calc(100% - 40px)',
  },
  tabContentReadonlyView: {
    height: '100% !important',
  },
  fieldsTypesTitle: {
    color: '#101F2B',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: spacing(4),
  },
  fieldsParamsSubTitle: {
    color: '#101F2B',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: spacing(4),
  },
  processParamsBlock: {
    marginBottom: 32,
    display: 'flex',
    flexDirection: 'column'
  },
  processCardPreviewButton: {
    marginTop: 16,
    padding: 0,
    height: 'fit-content',
    width: 'fit-content'
  },
  editableToggle: {
    marginBottom: 16,
  },
  valuesChangeInfoMessage: {
    marginBottom: '16px !important',
  },
  fieldsTypesDescription: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: "#8B8C9E",
    marginBottom: spacing(4),
  },
  fieldsListWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: spacing(5),
  },
  sidePanelInfoText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: '#6D6E85',
    marginBottom: spacing(1)
  },
  optionLabel: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: '#6D6E85',
    marginBottom: spacing(1),
  },
  optionLabelRequired: {
    '&::after': {
      display: 'inline-block',
      content: '"*"',
      color: palette.error[600],
      fontSize: 14,
      position: 'relative',
      right: -2,
      top: -2,
    },
  },
  textField: {
    '& > div': {
      border: '1px solid rgba(38, 40, 66, 0.12)',
      borderRadius: 10,
    }
  },
  fieldType: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#262842',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fieldTypeInfo: {
    height: 13,
    width: 13,
    marginLeft: spacing(1.25)
  },
  toggle: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#262842',
    width: 'fit-content',
    '& input + span': {
      marginTop: -1,
      marginLeft: -1
    }
  },
  switchTrackChecked: {
    backgroundColor: '#2DB77B !important',
    borderColor: '#2DB77B !important',
    '&:hover': {
      backgroundColor: '#2DB77B !important',
      borderColor: '#2DB77B !important',
    }
  },
  switchTrackUnchecked: {
    backgroundColor: '#F0F0F7 !important',
    borderColor: '#F0F0F7 !important',
    '&:hover': {
      backgroundColor: '#F0F0F7 !important',
      borderColor: '#F0F0F7 !important',
    }
  },
  fieldWrapper: {
    marginBottom: spacing(4),
  },
  limitedWidthFieldWrapper: {
    width: 410,
    maxWidth: 410,
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: spacing(3),
    width: 410,
    maxWidth: 410,
  },
  button: {
    height: 40,
    padding: spacing(2.5, 4),
    borderRadius: spacing(2.5)
  },
  clone: {
    '& + div': {
      display: 'none !important',
    },
  },
  tooltipContent: {
    whiteSpace: 'pre-wrap'
  },
  errorMessage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: spacing(1),
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#D6331F',
    '& img': {
      marginRight: spacing(1.25),
      height: 13,
      width: 13,
    }
  },
  fieldEditingInfo: {
    width: '100%',
    padding: 12,
    marginBottom: 16,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: '#262842',
    backgroundColor: '#EBF5FC',
    border: '1px solid #2628421F',
    borderRadius: 12,
    '& span': {
      fontWeight: 500,
    }
  },
  fieldIntegrations: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 4,
    marginTop: 4,
    marginBottom: 12,
  },
  integrationChip: {
    padding: '7px 8px',
    borderRadius: 8,
    background: '#F0F0F7',
    color: '#262842',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    whiteSpace: 'nowrap',
  },
  tooltip: {
    background: "#262842",
  },
  tooltipArrow: {
    color: "#262842"
  },
  fieldTooltip: {
    borderRadius: 8,
  },
  // Block Start
  blockWrapper: {
    height: '50px',
    padding: '8px 0',
    borderRadius: '8px',
    border: '1px solid #26284214',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '24px',
    backgroundColor: '#FFFFFF'
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: "33.3%",
    padding: '0 14px'
  },
  verticalBorderLine: {
    width: '1px',
    height: '32px',
    backgroundColor: '#26284214'
  },
  iconStatus: {
    position: 'relative',
    width: '24px',
    height: '24px',
    minWidth: '24px',
    minHeight: '24px',
    transform: 'rotateY(0deg) rotate(45deg)',
    backgroundColor: '#8754F6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    marginTop: '4px'
  },
  revertPlusIcon: {
    transform: 'rotateY(0deg) rotate(45deg)',
  },
  purpleBackground: {
    backgroundColor: '#8754F6'
  },
  greenBackground: {
    backgroundColor: '#3B854B'
  },
  grayBackground: {
    backgroundColor: '#A9A9B8'
  },
  howItWorks: {
    cursor: 'pointer',
    color: '#007994',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  plusIcon: {
    width: '15px',
    height: '15px'
  },
  naming: {
    color: '#8B8C9E',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  statusData: {
    color: '#262842'
  },
  statusDataVermilion: {
    color: '#D6331F',
  },
  limitData: {
    marginLeft: '14px',
    width: '80%'
  },
  mr0: {
    marginRight: 0
  },
  // Block End
  // How It Works Sidepanel Start
  howItWorksSidePanel: {
    width: '370px',
    maxWidth: '370px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  howItWorksSidePanelHeader: {
    borderBottom: '1px solid #26284214',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '16px',
    fontWeight: 'bold',
  },
  howItWorksSidePanelHeaderText: {
    fontWeight: 'bold',
  },
  closeSidePanel: {
    cursor: 'pointer'
  },
  howItWorksSidePanelBody: {
    padding: '16px',
    width: '100%'
  },
  hotItWorksSidePanelWarningMessage: {
    background: '#FCF8EB',
    borderRadius: '12px',
    width: '100%',
    padding: '8px 12px',
    marginBottom: '24px',
    border: '1px solid #2628421F'
  },
  instructionHeader: {
    fontWeight: 'bold',
  },
  instructionDescription: {
    marginTop: '8px',
    marginBottom: '24px',
  },
  processLimits: {
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  limitList: {},
  limitListRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent:'space-between',
    alignItems: 'center',
    marginBottom: '8px',
    padding: '6px 0'
  },
  limitListRowLeftSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  limitName: {
    marginLeft: '8px',
  },
  limitCount: {
    color: '#6D6E85'
  },
  inappropriateStepTypesCountError: {
    maxWidth: '745px',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '12px',
    padding: '12px 16px',
    backgroundColor: '#FFA530',
    margin: '12px 0',
  },
  inappropriateStepTypesCountErrorText: {
    color: '#262842',
    marginLeft: '16px'
  }
  // How It Works Sidepanel End
}));
