import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { DebouncedFunc } from 'lodash';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

import { getDocumentTemplateByID, uploadAttachmentToPS } from 'api/requests';
import { useRequestActiveStep, useTaskState } from 'store/requests';
import { createFileInstance } from 'components/Attachments/utils';
import { getProfileCompanyDataFromLocalStorage } from 'utils/user';
import { replaceWithModifiers } from './utils';

import { AttributeType } from 'types';
import {
  StatusType,
  TemplateViewerHookProps,
  TemplateDocumentStatus,
  TemplateViewerHookResponse,
  RequestParameters,
  FormValues
} from './types';

import {
  ERROR_MESSAGES,
  HINT_MESSAGES,
  TEMPLATE_DEFAULT_FORMAT,
  DEBOUNCE_DELAY,
  FORM_VALUES_KEYS_EXCEPTION,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_FILE_NAME
} from './constants';

import { getCorrectTimeWithISOFormat } from 'utils/time';

export const useTemplateViewer = (
  {
    params: {
      fileId,
      attachmentAttributeSysName,
      fileNameTemplate = DEFAULT_FILE_NAME,
      alwaysActiveButtons = ''
    },
    setButtonDisabled,
    setAlwaysActiveButtons,
    displayStep
  }: TemplateViewerHookProps): TemplateViewerHookResponse => {
  const { getValues, setValue } = useFormContext();
  const { data: bpmTask } = useTaskState();
  const { activeStep } = useRequestActiveStep();

  // Необходимо для сравнения вводных данных пользователя
  const formValues = getValues();
  const formValuesOld = useRef(null);

  // Данные документа
  const [templateDocumentBlob, setTemplateDocumentBlob] = useState<Blob | null>(null);
  const [templateDocumentURL, setTemplateDocumentURL] = useState<string | null>(null);
  const [templateDocumentStatus, setStatus] = useState<TemplateDocumentStatus>(StatusType.PENDING);
  const [templateDocumentName, setTemplateDocumentName] = useState<string>(DEFAULT_FILE_NAME);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // TODO: Уменьшить количество используемых переменных
  const [isFileApproved, setFileApproved] = useState<boolean | null>(null);
  const [fileLoadingToggle, setFileLoadingToggle] = useState<boolean>(false);

  // Данные об статусе при загрузке в Google Drive
  const [isUploadLoading, setUploadLoadingStatus] = useState<boolean>(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string | null>(null);
  const [uploadHintMessage, setUploadHintMessage] = useState<string | null>(HINT_MESSAGES.APPROVE_PDF_FILE);

  // Основные параметры для запроса по получению шаблона
  const getRequestParameters = useCallback((values: FormValues): RequestParameters => ({
    format: TEMPLATE_DEFAULT_FORMAT,
    fileId: fileId?.toString(),
    parameters: {
      ...values
    }
  }), [fileId]);

  // Функция для обработки данных которые отправляются для формирования файла
  const getFormattedValues = useCallback((values: FormValues): FormValues =>
    Object.keys(values).reduce((accumulator, currentKey) => {
      const currentValue = values[currentKey];
      const currentAttribute = bpmTask.getAttributeByName(currentKey);

      switch (currentAttribute?.type) {
        case AttributeType.DATETIME: {
          accumulator[currentKey] = moment(currentValue).format('DD.MM.YYYY');
          return accumulator;
        }
        default: {
          accumulator[currentKey] = currentValue;
          return accumulator;
        }
      }
    }, {}), [bpmTask]);

  // Получение компонента attachment-а для сохранения данных сгенерированного шаблона
  const attachmentAttributeName = useMemo((): string | null => {
    if (attachmentAttributeSysName) {
      const attribute = bpmTask.getAttributeBySysName(attachmentAttributeSysName);

      return attribute?.name || null;
    }

    return null;
  }, [bpmTask, attachmentAttributeSysName]);

  const generateFileName = (values: FormValues): string => {
    const nameTemplateWithFileType = fileNameTemplate + '.pdf';
    return replaceWithModifiers(nameTemplateWithFileType, values);
  };

  const getTemplateDocument = useCallback(async (values: FormValues): Promise<void> => {
    try {
      setStatus(StatusType.PENDING);

      const formattedValues: FormValues = getFormattedValues(values);
      const requestParameters: RequestParameters = getRequestParameters(formattedValues);
      const document: BlobPart = await getDocumentTemplateByID(requestParameters);

      const filename = generateFileName(formattedValues);
      setTemplateDocumentName(filename);

      // Создание документа и временной ссылки для iframe
      const file: Blob = new Blob([document], { type: 'application/pdf' });
      const fileURL: string = window.URL.createObjectURL(file);

      setTemplateDocumentBlob(file);
      setTemplateDocumentURL(fileURL);
      setStatus(StatusType.RESOLVED);

      // Отключаем кнопки когда документ обновился
      if (attachmentAttributeName) {
        setUploadHintMessage(HINT_MESSAGES.APPROVE_PDF_FILE);
        setButtonDisabled(true);
        setFileApproved(false);
        // toggle исправляет неработающий фикс для предотвращения блокировки кнопок
        // при создании time off из шаблона
        setFileLoadingToggle(v => !v);
      }
    } catch (error) {
      setErrorMessage(error.message || DEFAULT_ERROR_MESSAGE);
      setStatus(StatusType.REJECTED);
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    attachmentAttributeName,
    setButtonDisabled,
    getFormattedValues,
    getRequestParameters,
    setFileApproved,
    setFileLoadingToggle
  ]);

  const uploadFileToPS = async (): Promise<void> => {
    if (!templateDocumentBlob) {
      return;
    }

    try {
      setUploadErrorMessage('');
      setUploadLoadingStatus(true);

      const documentMetaData = {
        name: templateDocumentName,
        mimeType: templateDocumentBlob.type,
      };
      const documentMetaDataBlob = new Blob([JSON.stringify(documentMetaData)], {
        type: 'application/json'
      });

      const formData = new FormData();
      formData.append('metadata', documentMetaDataBlob);
      formData.append('file', templateDocumentBlob);

      const fileInstanceId = await createFileInstance({
        payload: {
          file: {
            name: documentMetaData.name,
            mimeType: documentMetaData.mimeType,
            size: templateDocumentBlob.size,
            id: ''
          },
          taskId: bpmTask.taskId,
          applicationNumber: bpmTask.applicationNumber,
          step: bpmTask.currentAction.sysName,
          assignee: bpmTask.assignee,
          creationDate: getCorrectTimeWithISOFormat()
        }
      });

      const { id: profileCompanyId } = getProfileCompanyDataFromLocalStorage();
      const companyId = bpmTask.values['companyId'] || profileCompanyId;

      try {
        await uploadAttachmentToPS({
          data: formData,
          params: {
            companyId,
            fileId: fileInstanceId
          }
        });

        setValue(attachmentAttributeName, fileInstanceId);
        setButtonDisabled(false);

        setFileApproved(true);
        setUploadHintMessage(HINT_MESSAGES.SUCCESS_APPROVE_PDF_FILE);
      } catch (e) {
        setUploadErrorMessage(ERROR_MESSAGES.UPLOAD_FILE_PS);
      } finally {
        setUploadLoadingStatus(false);
      }
    } catch (error) {
      setUploadErrorMessage(error.message);
      setUploadLoadingStatus(false);
    }
  };

  // Получение данных о том, изменились ли данные формы или нет
  const isFormValuesChanged = (value: FormValues): boolean => {
    const condition = (!value) || (value && !isEqual(value, formValuesOld.current));
    formValuesOld.current = value; // обновление предыдущего значения формы

    return condition;
  };

  // Очищаем данные формы от ключей FORM_VALUES_KEYS_EXCEPTION и данных об attachment-е,
  // в который мы сохраняем данные PDF-а
  const cleanFormValues = useMemo((): FormValues =>
      omit(
        formValues,
        attachmentAttributeName ?
          [...FORM_VALUES_KEYS_EXCEPTION, attachmentAttributeName] :
          FORM_VALUES_KEYS_EXCEPTION
      ),
    [formValues, attachmentAttributeName]);

  // Создание debounce функции для того, чтобы компонент ждал пока пользователь
  // в течении DEBOUNCE_DELAY в мс времени не перестанет писать в инпут
  const debouncedFormValuesHandler = useMemo((): DebouncedFunc<(values: FormValues) => void> =>
      debounce((values: FormValues): void => {
        // Проверка на изменения значении формы, для того чтобы не отправлять
        // лишний запрос в случай если пользователь записал и стер новые данные
        if (isFormValuesChanged(values)) {
          getTemplateDocument(values);
        }
      }, DEBOUNCE_DELAY),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  useEffect(() => {
    // в случае fileId и cleanFormValues существуют, то отправляем данные формы
    if (fileId && cleanFormValues) {
      debouncedFormValuesHandler(cleanFormValues);
    }

    // Остановка вызова debounce функции после завершения работы компонента
    return () => {
      debouncedFormValuesHandler.cancel();
    };
  }, [fileId, cleanFormValues, debouncedFormValuesHandler, activeStep]);

  useEffect(() => {
    // Предотвращаем блокировку кнопок на шагах отличных от шага на котором
    // отображается компонент при создании заявки из шаблона или черновика
    if (bpmTask.stepName === 'Rework' && !isFileApproved) {
      setButtonDisabled(true);
    } else if (activeStep !== displayStep - 1) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(!!attachmentAttributeName && !isFileApproved);
    }
  }, [
    activeStep,
    displayStep,
    attachmentAttributeName,
    isFileApproved,
    fileLoadingToggle
  ]);

  useEffect(() => {
    const alwaysActiveButtonsList = alwaysActiveButtons?.split(',').map(value => value.trim()) || [];
    setAlwaysActiveButtons && setAlwaysActiveButtons(alwaysActiveButtonsList);
  }, [alwaysActiveButtons, setAlwaysActiveButtons]);

  return {
    isUploadLoading,
    uploadErrorMessage,
    uploadHintMessage,
    isAttachmentExists: !!attachmentAttributeName,
    errorMessage,
    templateDocumentURL,
    templateDocumentStatus,
    uploadFileToPS
  };
};
