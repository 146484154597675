import React, { ReactElement, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useDocumentsTemplateModalStyles from './DocumentTemplatesModal.useStyles';
import CloseIcon from 'assets/images/icons/close-icon-grey.svg';
import { DocumentsList } from '../ProcessSetupSidePanel/tabs/documents/components/DocumentsList/DocumentsList';
import { useDocumentsTab } from '../ProcessSetupSidePanel/tabs/documents/useDocumentsTab';
import {
  DocumentsListFilters,
} from '../ProcessSetupSidePanel/tabs/documents/components/DocumentsListFilters/DocumentsListFilters';
import { Spinner } from '../../../../components';
import { DocumentTemplate } from '../../TemplateProcesses.types';

interface StepConditionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDocumentSelect: (documentId: number) => void;
  onDocumentPreview: (documentId: number, documentData?: DocumentTemplate) => void;
}

export const DocumentTemplatesModal = ({
  isOpen,
  onClose,
  onDocumentSelect,
  onDocumentPreview,
}: StepConditionModalProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useDocumentsTemplateModalStyles();
  const [selectedDocumentTemplate, setSelectedDocumentTemplate] = useState<number>(null);

  const {
    documentsList,
    groupedDocumentsList,
    loading,
    directories,
    searchValue,
    handleSearchChange,
    departmentValue,
    handleDepartmentChange,
    typeValue,
    handleTypeChange,
  } = useDocumentsTab({ focusedDocumentId: '', processDocuments: [] });

  const handleSubmit = () => {
    onDocumentSelect(selectedDocumentTemplate);
    setSelectedDocumentTemplate(null);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{
        root: classes.dialogRoot,
        paper: classes.dialog,
      }}
      BackdropProps={{style: {backgroundColor: 'rgba(38, 40, 66, 0.32)'}}}
      disableEnforceFocus
    >
      <Box className={classes.dialogHeader}>
        <Typography className={classes.dialogTitle}>
          {t('customProcesses.creationPage.documentAddModal.title')}
        </Typography>
        <IconButton onClick={onClose}>
          <img src={CloseIcon} className={classes.dialogIcon}/>
        </IconButton>
      </Box>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.filtersWrapper}>
          <DocumentsListFilters
            modalVariant
            directories={directories}
            searchValue={searchValue}
            department={departmentValue}
            type={typeValue}
            onSearchChange={handleSearchChange}
            onDepartmentSelect={handleDepartmentChange}
            onTypeSelect={handleTypeChange}
          />
        </div>

        <div className={classes.documentsListWrapper}>
          {
            loading
            ? <Spinner absolute={false}/>
            : <DocumentsList
              groupedDocumentsList={groupedDocumentsList}
              onDocumentClick={(documentId) => setSelectedDocumentTemplate(documentId)}
              selectedDocument={selectedDocumentTemplate}
              modalVariant
              isFiltered={!!(searchValue || departmentValue || typeValue)}
              searchText={searchValue}
            />
          }
        </div>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          color="secondary"
          onClick={onClose}
          className={classes.dialogActionsButton}
        >
          {t('buttons.cancel')}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            onDocumentPreview(selectedDocumentTemplate, documentsList.find(doc => doc.id === selectedDocumentTemplate));
          }}
          disabled={!selectedDocumentTemplate}
          className={classes.dialogActionsButton}
        >
          {t('customProcesses.creationPage.documentAddModal.showPreview', { defaultValue: 'Show preview' })}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!selectedDocumentTemplate}
          className={classes.dialogActionsButton}
        >
          {t('customProcesses.creationPage.documentAddModal.add', { defaultValue: 'Add' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
