import React, { useMemo } from 'react';
import { Box, Button, IconButton, Typography, Modal } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';

import { DocumentTemplate } from '../../TemplateProcesses.types';
import useStyles from './DeleteStepFieldConfirmationModal.useStyles';

type Props = {
  isOpen: boolean,
  onClose: () => void;
  onConfirm: () => void;
  fieldId: string;
  documents: DocumentTemplate[];
};

export const DeleteStepFieldConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  fieldId,
  documents,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isDocumentField = useMemo(() =>
    documents.some(doc => doc.fields
      .some(field => field.processFieldId === fieldId),
    ), [fieldId, documents]);

  return (
    <Modal open={isOpen}>
      <Box className={classes.modalBody}>
        <div className={classes.modalHeader}>
          <Typography className={classes.title}>
            {t(
              isDocumentField
               ? 'customProcesses.creationPage.deleteDocumentFieldConfirmationModal.title'
               : 'customProcesses.creationPage.deleteStepFieldConfirmationModal.title'
            )}
          </Typography>

          <IconButton onClick={onClose}>
            <CloseIcon className={classes.closeIcon}/>
          </IconButton>
        </div>

        <div className={classes.modalContent}>
          <Typography className={classes.description}>
            {t(
              isDocumentField
              ? 'customProcesses.creationPage.deleteDocumentFieldConfirmationModal.description'
              : 'customProcesses.creationPage.deleteStepFieldConfirmationModal.description'
            )}
          </Typography>
        </div>

        <div className={classes.modalFooter}>
          <Button color="secondary" variant="contained" onClick={onClose} className={classes.modalButton}>
            {t('comment_modal.button_cancel')}
          </Button>

          <Button color="primary" variant="contained" className={classes.modalButton}
                  onClick={() => {
                    onConfirm();
                    onClose();
                  }}>
            {t('customProcesses.creationPage.buttons.delete')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};


