import React, { ReactElement, useMemo, useState } from 'react';
import { Box, IconButton, List, ListItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ProcessStepCard } from '../ProcessStepCard';
import {
  ConditionStep,
  DepartmentInfo, DocumentTemplate,
  Language, ParallelBranchesGroup,
  ProcessStep,
  StepValidationResult,
} from '../../TemplateProcesses.types';
import { BRANCH_STEPS_NUMBER_LIMIT, STEPS_NUMBER_LIMIT } from '../../TemplateProcesses.constants';

import PlusIcon from 'assets/images/icons/black-plus-sign.svg';
import FinishIcon from 'assets/images/icons/process-path-finish-icon.svg';

import useProcessStepListStyles from './ProcessStepList.useStyles';
import { ConditionStepCard } from '../ConditionStepCard';
import { ParallelStepsGroupsCard } from '../ParallelStepsGroupCard';
import { ProcessStepAddMenu } from '../ProcessStepAddMenu';
import { getDisplayedStepOrder } from '../../TemplateProcesses.utils';

export const PROCESS_STEP_CARD_ERROR_WRAPPER_CLASSNAME = "PROCESS_STEP_CARD_ERROR_WRAPPER_CLASSNAME";

interface ProcessStepListProps {
  isAddConditionButtonAvailable?: boolean;
  isAddParallelBranchesButtonAvailable?: boolean;
  isReadonlyView?: boolean;
  processIntegrations?: string[];
  processSteps: ProcessStep[];
  formErrors?: StepValidationResult[];
  currentLanguage: Language;
  currentStepIndex: number;
  currentConditionId: string;
  currentParallelBranchesGroupId: string;
  selectedItemType: 'processStep' | 'condition' | 'parallelBranchesGroups';
  departmentsList: DepartmentInfo[];
  directManagerSteps: number[];
  performerSelectionSteps: number[];
  allProcessStepsFields: any[];
  isAutostartActive?: boolean;
  isEditDisabled?: boolean;
  conditions: ConditionStep[];
  parallelBranchesGroups: ParallelBranchesGroup[];
  documents: DocumentTemplate[];
  handleAddProcessConditionStep: (processStep: ProcessStep) => void;
  handleAddProcessStep: () => void;
  handleAddPreviousStep: (processStep: ProcessStep, isConditionBranchStep?: boolean, branch?: 'positive' | 'negative', condition?: ConditionStep) => void;
  handleAddParallelStepsGroup: (processStep: ProcessStep) => void;
  handleParallelBranchStepAdd: (parallelBranchesGroup: ParallelBranchesGroup, branchIndex: number, stepPosition: number) => void;
  handleConditionBranchStepAdd: (condition: ConditionStep, branchType: 'positive' | 'negative', stepPosition: number) => void;
  handleProcessSelect: (processStep: ProcessStep) => void;
  handleConditionSelect: (condition: ConditionStep) => void;
  handleParallelStepsGroupSelect: (parallelStepsGroup: ParallelBranchesGroup) => void;
  openStepsLimitReachedDialog: () => void;
  openBranchStepsLimitReachedDialog: () => void;
  isTemplatePreview?: boolean;
  isFlowPreview?: boolean;
}

export const ProcessStepList = ({
  allProcessStepsFields,
  conditions,
  isAddConditionButtonAvailable = true,
  isAddParallelBranchesButtonAvailable = true,
  currentConditionId,
  processIntegrations = [],
  processSteps,
  formErrors,
  currentLanguage,
  currentParallelBranchesGroupId,
  currentStepIndex,
  departmentsList,
  directManagerSteps = [],
  handleAddParallelStepsGroup,
  handleAddPreviousStep,
  handleAddProcessConditionStep,
  handleAddProcessStep,
  handleConditionBranchStepAdd,
  handleConditionSelect,
  handleParallelBranchStepAdd,
  handleParallelStepsGroupSelect,
  handleProcessSelect,
  isAutostartActive = false,
  isEditDisabled = false,
  documents,
  isFlowPreview,
  isReadonlyView = false,
  isTemplatePreview,
  openStepsLimitReachedDialog,
  openBranchStepsLimitReachedDialog,
  parallelBranchesGroups,
  performerSelectionSteps = [],
  selectedItemType,
}: ProcessStepListProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useProcessStepListStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const disableFirstStepDeletion = useMemo(() =>
    processIntegrations?.length > 0
  , [processIntegrations])

  const isLimitReached = processSteps.length >= STEPS_NUMBER_LIMIT;

  const handleMenuAddStep = (): void => {
    if (!isLimitReached) {
      handleAddProcessStep();
    }
  };

  const handleMenuAddCondition = (processStep: ProcessStep): void => {
    if (STEPS_NUMBER_LIMIT - processSteps.length >= 2) {
      handleAddProcessConditionStep(processStep);
    } else {
      openStepsLimitReachedDialog();
    }
  };

  const handleMenuAddParallelStepsGroup = (processStep: ProcessStep): void => {
    if (STEPS_NUMBER_LIMIT - processSteps.length >= 2) {
      handleAddParallelStepsGroup(processStep);
    } else {
      openStepsLimitReachedDialog();
    }
  };

  const handleMenuAddParallelBranchStep = (parallelBranchesGroup: ParallelBranchesGroup, branchIndex: number, stepIndex: number): void => {
    if (processSteps.length >= STEPS_NUMBER_LIMIT) {
      openStepsLimitReachedDialog();
      return;
    }
    const branchStepsCount = parallelBranchesGroup?.steps[branchIndex]?.length;
    if (branchStepsCount >= BRANCH_STEPS_NUMBER_LIMIT) {
      openBranchStepsLimitReachedDialog();
      return;
    }
    handleParallelBranchStepAdd(parallelBranchesGroup, branchIndex, stepIndex)
  };

  const handleMenuAddConditionBranchStep = (condition: ConditionStep, branchType: 'positive' | 'negative', stepPosition: number): void => {
    if (processSteps.length >= STEPS_NUMBER_LIMIT) {
      openStepsLimitReachedDialog();
      return;
    }
    const branchStepsCount = branchType === 'positive' ? condition?.positiveBranch?.length : condition?.negativeBranch?.length;
    if (branchStepsCount >= BRANCH_STEPS_NUMBER_LIMIT) {
      openBranchStepsLimitReachedDialog();
      return;
    }
    handleConditionBranchStepAdd(condition, branchType, stepPosition);
  };

  const handleAddPreviousStepButtonClick = (processStep: ProcessStep, isConditionBranchStep?: boolean, branch?: 'positive' | 'negative', condition?: ConditionStep): void => {
    if (!isLimitReached) {
      handleAddPreviousStep(processStep, isConditionBranchStep, branch, condition);
    } else {
      openStepsLimitReachedDialog();
    }
  };

  return (
    <Box className={classes.contentWrapper} id={'template-processes-builder-process-path'}>
      <Typography className={classes.processStepsListTitle}>
        {t('customProcesses.creationPage.processForm.processStepsListTitle')}
      </Typography>
      <List disablePadding className={classes.listWrapper}>
        <ListItem className={classes.processStepListItem} disableGutters>
          {processSteps.map((processStep: ProcessStep, index: number) => {
            const stepCondition = conditions.find(condition => condition.previousStepOrder === index + 1);
            const stepParallelBranchesGroup = parallelBranchesGroups.find(parallelBranchesGroup => parallelBranchesGroup.previousStepOrder === index + 1);
            const documentSignaturesCount = documents?.filter(doc => doc.signatureSteps.includes(processStep.stepOrder))?.length;

            return (<>
              {(!processStep?.isConditionBranchStep && !processStep?.isParallelBranchesGroupStep) &&
                <ProcessStepCard
                  isAddConditionButtonAvailable={isAddConditionButtonAvailable}
                  isAddParallelBranchesButtonAvailable={isAddParallelBranchesButtonAvailable}
                  isFlowPreview={isFlowPreview}
                  isTemplatePreview={isTemplatePreview}
                  language={currentLanguage}
                  tagStepOrder={getDisplayedStepOrder(processStep, conditions, parallelBranchesGroups)}
                  processStep={processStep as ProcessStep}
                  documentSignaturesCount={documentSignaturesCount}
                  processIntegrations={processIntegrations}
                  index={index}
                  isAddCardButtonShown={processStep?.stepOrder > 1}
                  stepErrors={formErrors?.length ? formErrors[index] : {} as StepValidationResult}
                  departmentsList={departmentsList}
                  isActive={selectedItemType === 'processStep' && currentStepIndex === processStep?.stepOrder - 1}
                  isAddCardButtonDisabled={isLimitReached || isEditDisabled}
                  isDirectManagerStep={directManagerSteps.includes(processStep?.stepOrder)}
                  isPerformerStep={performerSelectionSteps.includes(processStep?.stepOrder - 1)}
                  isAutostartActive={isAutostartActive}
                  handleAddCard={() => handleAddPreviousStepButtonClick(processStep)}
                  handleAddCondition={() => handleMenuAddCondition(processStep)}
                  handleAddParallelStepsGroup={() => handleMenuAddParallelStepsGroup(processStep)}
                  handleClick={() => handleProcessSelect(processStep as ProcessStep)}
                  isReadonlyView={isReadonlyView}
                  isEditDisabled={isEditDisabled}
                  isDeletionDisabled={processStep?.stepOrder === 1 && disableFirstStepDeletion}
                />
              }

              {stepCondition &&
                <ConditionStepCard
                  isAddConditionButtonAvailable={isAddConditionButtonAvailable}
                  isAddParallelBranchesButtonAvailable={isAddParallelBranchesButtonAvailable}
                  isFlowPreview={isFlowPreview}
                  isTemplatePreview={isTemplatePreview}
                  conditions={conditions}
                  parallelBranchesGroups={parallelBranchesGroups}
                  documents={documents}
                  formErrors={formErrors}
                  currentLanguage={currentLanguage}
                  currentStepIndex={currentStepIndex}
                  selectedItemType={selectedItemType}
                  isActive={selectedItemType === 'condition' && currentConditionId === stepCondition?.id}
                  conditionStep={stepCondition}
                  positiveBranch={stepCondition?.hasPositiveBranch ? stepCondition?.positiveBranch.map(order => processSteps.find(s => s.stepOrder === order)) as ProcessStep[] : []}
                  negativeBranch={stepCondition?.hasNegativeBranch ? stepCondition?.negativeBranch.map(order => processSteps.find(s => s.stepOrder === order)) as ProcessStep[] : []}
                  departmentsList={departmentsList}
                  directManagerSteps={directManagerSteps}
                  performerSelectionSteps={performerSelectionSteps}
                  allProcessStepsFields={allProcessStepsFields}
                  isAddCardButtonDisabled={isLimitReached || isEditDisabled}
                  isReadonlyView={isReadonlyView}
                  isEditDisabled={isEditDisabled}
                  handleAddCard={() => handleAddPreviousStepButtonClick(processSteps[Math.min(...stepCondition?.positiveBranch, ...stepCondition?.negativeBranch) - 1] as ProcessStep)}
                  handleAddConditionBranchStep={(branchType, stepPosition) => handleMenuAddConditionBranchStep(stepCondition, branchType, stepPosition)}
                  handleAddCondition={() => handleMenuAddCondition(processSteps[Math.min(...stepCondition?.positiveBranch, ...stepCondition?.negativeBranch) - 1] as ProcessStep)}
                  handleAddParallelStepsGroup={() => handleMenuAddParallelStepsGroup(processSteps[Math.min(...stepCondition?.positiveBranch, ...stepCondition?.negativeBranch) - 1] as ProcessStep)}
                  handleProcessCardClick={(step) => handleProcessSelect(step as ProcessStep)}
                  handleConditionCardClick={() => handleConditionSelect(stepCondition)}
                />
              }

              {stepParallelBranchesGroup &&
                <ParallelStepsGroupsCard
                  isAddConditionButtonAvailable={isAddConditionButtonAvailable}
                  isAddParallelBranchesButtonAvailable={isAddParallelBranchesButtonAvailable}
                  isFlowPreview={isFlowPreview}
                  isTemplatePreview={isTemplatePreview}
                  formErrors={formErrors}
                  currentLanguage={currentLanguage}
                  currentStepIndex={currentStepIndex}
                  selectedItemType={selectedItemType}
                  conditions={conditions}
                  parallelBranchesGroups={parallelBranchesGroups}
                  parallelBranchesGroup={stepParallelBranchesGroup}
                  documents={documents}
                  parallelStepsList={stepParallelBranchesGroup.steps.map(b => b.map(order => processSteps.find(s => s.stepOrder === order)))}
                  isActive={selectedItemType === 'parallelBranchesGroups' && currentParallelBranchesGroupId === stepParallelBranchesGroup?.id}
                  departmentsList={departmentsList}
                  directManagerSteps={directManagerSteps}
                  performerSelectionSteps={performerSelectionSteps}
                  isAddCardButtonDisabled={isLimitReached || isEditDisabled}
                  isReadonlyView={isReadonlyView}
                  isEditDisabled={isEditDisabled}
                  handleAddCard={() => handleAddPreviousStepButtonClick(processSteps[Math.min(...stepParallelBranchesGroup?.steps.flat() || []) - 1] as ProcessStep)}
                  handleAddCondition={() => handleMenuAddCondition(processSteps[Math.min(...stepParallelBranchesGroup?.steps.flat() || []) - 1] as ProcessStep)}
                  handleAddParallelStepsGroup={() => handleMenuAddParallelStepsGroup(processSteps[Math.min(...stepParallelBranchesGroup?.steps.flat() || []) - 1] as ProcessStep)}
                  handleParallelBranchStepAdd={handleMenuAddParallelBranchStep}
                  handleProcessCardClick={(step) => handleProcessSelect(step as ProcessStep)}
                  handleParallelStepsGroupCardClick={() => handleParallelStepsGroupSelect(stepParallelBranchesGroup)}
                />
              }
            </>)
          })}

          {(!isReadonlyView && !isLimitReached) && (
            <Box className={classes.addButtonWrapper}>
              <IconButton
                className={classes.addButton}
                onClick={({ currentTarget }) => {
                  setAnchorEl(currentTarget);
                }}
                disabled={isEditDisabled}
              >
                <img src={PlusIcon} className={classes.addIcon}/>
              </IconButton>
            </Box>
          )}

          {isLimitReached &&
            <Box className={classes.maximumStepsReachedMessageWrapper}>
              <Typography className={classes.maximumStepsReachedMessageText}>
                {t('customProcesses.creationPage.processStepsList.stepsLimitReached')}
              </Typography>
            </Box>
          }

          <Box className={cn(classes.pathEnd, {[classes.pathEndPreview]: (isTemplatePreview || isFlowPreview), [classes.pathEndLimitReached]: isLimitReached && !isTemplatePreview})}>
            <img src={FinishIcon} className={classes.pathEndIcon} alt={'Finish'}/>
            <Typography className={classes.pathEndText}>
              {t('customProcesses.creationPage.processStepsList.finish')}
            </Typography>
          </Box>
        </ListItem>
      </List>

      <ProcessStepAddMenu
        anchorElement={anchorEl}
        handleAddCondition={() => handleMenuAddCondition(null)}
        handleAddParallelStepsGroup={() => handleMenuAddParallelStepsGroup(null)}
        handleAddStep={handleMenuAddStep}
        hide
        isAddConditionButtonAvailable={isAddConditionButtonAvailable}
        isAddParallelBranchesButtonAvailable={isAddParallelBranchesButtonAvailable}
        open={Boolean(anchorEl)}
        setAnchorElement={setAnchorEl}
      />
    </Box>
  );
};
