import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { AppTextField, Spinner } from 'components';
import { TemplateProcess } from '../../TemplateProcesses.types';
import useDuplicateProcessDialogStyles from './DuplicateProcessDialog.useStyles';

import CloseIcon from 'assets/images/icons/close-icon-thin-light-grey.svg';
import { MAX_NAME_LENGTH } from '../../TemplateProcesses.constants';
import WarningIcon from '../../../../assets/images/icons/warning-sign.svg';

const COPY_SUFFIX = ' - copy';

interface ActionConfirmationDialogProps {
  processData: Partial<TemplateProcess>,
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (processCopyName: string, openNewProcess: boolean) => Promise<boolean>;
}

export const DuplicateProcessDialog = ({
  processData,
  isOpen,
  onClose,
  onConfirm,
}: ActionConfirmationDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useDuplicateProcessDialogStyles();

  const [processCopyName, setProcessCopyName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentAction, setCurrentAction] = useState<'duplicate' | 'duplicate & open'>('duplicate');

  const handleDuplicate = async () => {
    setLoading(() => true);
    setCurrentAction(() => 'duplicate');
    const isSuccess = await onConfirm(processCopyName, false);
    setLoading(() => false);
    if (isSuccess) {
      onClose();
    }
  };

  const handleDuplicateAndOpen = async () => {
    setLoading(() => true);
    setCurrentAction(() => 'duplicate & open');
    const isSuccess = await onConfirm(processCopyName, true);
    setLoading(() => false);
    if (isSuccess) {
      onClose();
    }
  };

  const handleNameChange = (value: string) => {
    setProcessCopyName(value);
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(() => false);
      setProcessCopyName(processData?.processName + COPY_SUFFIX);
    }
  }, [processData, isOpen]);

  return (
    <Modal open={isOpen}>
      <Box className={classes.modalBody}>
        <div className={classes.modalHeader}>
          <Typography className={classes.title}>
            {t('customProcesses.duplicateProcessDialog.title')}
          </Typography>

          <IconButton onClick={onClose} disabled={loading}>
            <img src={CloseIcon} alt={'Close duplicate process modal'} className={classes.closeIcon}/>
          </IconButton>
        </div>

        <div className={classes.modalContent}>
          <AppTextField
            label={t('customProcesses.duplicateProcessDialog.fields.name')}
            value={processCopyName}
            placeholder={''}
            className={cn(classes.nameInput, classes.nameInputRequired)}
            fullWidth
            disabled={loading}
            onChange={(e) => handleNameChange(e.target.value)}
            error={processCopyName?.trim().length === 0 || processCopyName?.length > MAX_NAME_LENGTH}
            helperText={
              <>
                {processCopyName?.trim().length === 0
                 ? <div className={classes.errorMessage}>
                   <img src={WarningIcon} alt="warning"/>
                   <span>{t('customProcesses.creationPage.processFormErrors.requiredField')}</span>
                 </div>
                 : null
                }
                {processCopyName?.length > MAX_NAME_LENGTH
                 ? <div
                   className={cn(classes.errorMessage, classes.errorMessageTwoSides)}>
                   <div>
                     <img src={WarningIcon} alt="warning"/>
                     <span>{t('customProcesses.creationPage.processFormErrors.valueTooLong')}</span>
                   </div>

                   <div>
                     {processCopyName?.length || 0}/{MAX_NAME_LENGTH}
                   </div>
                 </div>
                 : null
                }
              </>
            }
          />

          <div className={classes.infoCard}>
            <p>{t('customProcesses.duplicateProcessDialog.infoCard.topMessage')}</p>
            <ul>
              <li>
                {t('customProcesses.duplicateProcessDialog.infoCard.fieldsLine')}
              </li>
              <li>
                {t('customProcesses.duplicateProcessDialog.infoCard.assigneesLine')}
              </li>
              <li>
                {t('customProcesses.duplicateProcessDialog.infoCard.conditionsLine')}
              </li>
            </ul>
            <p>{t('customProcesses.duplicateProcessDialog.infoCard.bottomMessage')}</p>
          </div>
        </div>

        <div className={classes.modalFooter}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.modalButton}
            disabled={loading}
            onClick={onClose}>
            {t('template_delete_modal.button_cancel', { defaultValue: 'Cancel' })}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            className={classes.modalButton}
            disabled={loading || processCopyName.trim().length === 0 || processCopyName?.length > MAX_NAME_LENGTH}
            endIcon={(loading && currentAction === 'duplicate & open') ? <Spinner size={14} absolute={false} buttonVersion/> : null}
            onClick={handleDuplicateAndOpen}>
            {t('customProcesses.duplicateProcessDialog.duplicateAndOpenButton', { defaultValue: 'Duplicate & Open' })}
          </Button>

          <Button
            variant="contained"
            color="primary"
            disabled={loading || processCopyName.trim().length === 0 || processCopyName?.length > MAX_NAME_LENGTH}
            className={classes.modalButton}
            endIcon={(loading && currentAction === 'duplicate') ? <Spinner size={14} absolute={false} white buttonVersion/> : null}
            onClick={handleDuplicate}>
            {t('customProcesses.duplicateProcessDialog.duplicateButton', { defaultValue: 'Duplicate' })}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
