import React from 'react';
import { Box, ClickAwayListener } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

import useStyles from './TaskComments/useStyles';
import useTaskComments from './TaskComments/useTaskComments';
import { CommentsList } from './TaskComments/CommentsList';
import { UserMentionsList } from './TaskComments/UserMentionsList';
import { CommentEditor } from './TaskComments/CommentEditor';
import { ErrorMessage } from 'components/ErrorMessage';

type Props = {
  className?: string;
  activeTab?: string;
  setTabCounter: (tabName: string, value: number) => void;
  tabsList: any[];
  mainScrollbarsRef: any;
  companyId: string;
};

export const CommentsTab = ({ className, activeTab, setTabCounter, tabsList, mainScrollbarsRef, companyId }: Props) => {
  const classes = useStyles();

  const {
    loading,
    error,
    dateGroupedComments,
    filteredUsers,
    isUsersListShown,
    isCommentEmpty,
    commentsScrollbarsRef,
    foundMention,
    fullMention,
    setFoundMention,
    setFullMention,
    setCommentEmpty,
    handleUserSelect,
    handleCommentInput,
    handleFormSubmit,
    showFilteredUsersList,
    closeUsersList,
  } = useTaskComments({
    setTabCounter,
    tabsList,
    mainScrollbarsRef,
    activeTab,
  });

  return (
    <Box className={classes.CommentsTab + ' ' + className}>
      <Box className={classes.commentsListWrapper}>
        <Scrollbars
          style={{ height: 'calc(100% - 4px)', width: 'calc(100% - 4px)' }}
          ref={commentsScrollbarsRef}
          // to force hide horizontal scrollbar
          renderView={(props) => <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />}
          renderTrackHorizontal={(props) => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
        >
          <CommentsList comments={dateGroupedComments} loading={loading && activeTab === 'comments'} companyId={companyId} />
        </Scrollbars>
      </Box>

      <Box className={classes.commentInputWrapper}>
        {isUsersListShown && filteredUsers.length > 0 && (
          <ClickAwayListener onClickAway={closeUsersList}>
            <div>
              <UserMentionsList
                users={filteredUsers}
                onUserSelected={(user) => {
                  handleUserSelect(user);
                }}
              />
            </div>
          </ClickAwayListener>
        )}

        <CommentEditor
          onChange={handleCommentInput}
          onSubmit={handleFormSubmit}
          foundMentionValue={foundMention}
          fullMentionValue={fullMention}
          setFoundMentionValue={setFoundMention}
          setFullMentionValue={setFullMention}
          setCommentEmpty={setCommentEmpty}
          isUserMentionPopupOpen={isUsersListShown && filteredUsers.length > 0}
          showFilteredUsersList={showFilteredUsersList}
        />

        {!!error && <ErrorMessage text={error} />}
      </Box>
    </Box>
  );
};
