import { makeStyles } from '@material-ui/core';


export default makeStyles(({ palette, spacing }) => ({
  bgPanel: {
    color: palette.grey[500],
    marginTop: 0,
    width: '50%',
    height: 'calc(100vh - 48px)',
    bottom: 0,
    right: 0,
    background: palette.grey[100],
    border: '1px solid #E0E3EA',
    transitionProperty: 'width, margin-left',
    transitionDuration: '700ms',
    marginLeft: 'calc(50% + 2px)'
  },
  bgPanelHidden: {
    width: 0,
    border: 'none',
    marginLeft: '100%'
  },
  bgPanelInitiallyFullscreenView: {
    height: '0px !important',
  },
  DataViewFullScreen: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: '-24px !important',
    height: 'calc(100vh - 49px)',
    width: 'calc(100% + 26px) !important',
    margin: 0,
    zIndex: 150,
  },
  DataViewHigh: {
    zIndex: 60,
    transitionProperty: 'z-index',
    transitionDuration: '0s',
  },
  DataViewHidden: {
    visibility: 'hidden'
  },
  errorMessageWrapper: {

  },
  tabWrapper: {
    width: '100%',
    height: 'calc(100% - 150px)',
    margin: 0,
    padding: 0,
  },
  tabContentWrapperInformationTab: {
    height: "calc(100% - 120px)"
  },
  tabContentWrapper: {
    paddingLeft: spacing(6),
    height: '100%',
  },
  tabContentWrapperAttachmentsTab: {
    paddingRight: spacing(3),
    overflow: 'auto',
  },
  tabContentWrapperWithValidationError: {
    // '& > div': {
    //   paddingBottom: 150,
    // },
  },
  tabContentWrapperHistoryTab: {
    background: "#F7F8FC",
    padding: 20,
    paddingBottom: 30
  },
  hiddenTabContent: {
    visibility: 'hidden',
    padding: '0 !important',
    margin: '0 !important',
    height: 0,
    opacity: 0,
    float: 'left',
    '& *': {
      padding: '0 !important',
      margin: '0 !important',
      height: '0 !important',
      minHeight: '0 !important',
      maxHeight: '0 !important',
      width: '0 !important',
      opacity: 0,
      visibility: 'hidden',
    }
  },
  dataViewSnackbar: {
    position: 'absolute',
    left: 0,
    bottom: 16,

    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    transition: '0.3s all ease',
    opacity: 0,
    pointerEvents: "none"
  },
  isOpen: {
    bottom: 68,
    opacity: 1,
    pointerEvents: "auto"
  },
  dataViewFooter: {
    minHeight: 64,
    padding: 8,
    borderRadius: spacing(0, 0, 2, 2),
    backgroundColor: palette.grey[100],
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonsListWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  errorMessage: {
    marginBottom: spacing(2),
  },
  button: {
    height: 40,
    padding: spacing(1, 4),
    order: 1
  }
}));
