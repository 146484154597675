import React, { useEffect } from 'react';
import { Typography, Box, ButtonBase, Drawer } from '@material-ui/core';
import cn from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

import { FormField } from 'pages/Task/TaskForm/FormFields';
import { FormButtonList } from 'pages/Task/TaskForm/ActionsBar/FormButtonList';

import { TaskParametersType } from 'types';

import useStyles from './useStyles';
import { Spinner } from 'components';

type Props = {
  open: boolean;
  color?: 'info' | 'danger' | 'neutral';
  title: string;
  subtitle: string;
  loading?: boolean;
  refreshComponents?: boolean;
  closeIcon?: string;
  buttonList?: TaskParametersType[];
  childrenAttributes?: TaskParametersType[];
  onClose: () => void;
  handleAction: (params: {
    action: string;
    tplName?: string;
    componentParams?: { [key: string]: any };
    instanceFormFieldName?: string;
    instance?: any;
    validationAttributeList?: TaskParametersType[];
  }) => void;
};

const DRAWER_ANCHOR = 'right';

export const SideModal = ({
  color = 'info',
  title,
  subtitle,
  open = false,
  closeIcon,
  loading = false,
  buttonList = [],
  childrenAttributes = [],
  onClose,
  handleAction,
  refreshComponents,
}: Props) => {
  const classes = useStyles();

  const headerBackgroundClass = cn({
    [classes.header]: true,
    [classes.headerColorInfo]: color === 'info',
    [classes.headerColorDanger]: color === 'danger',
    [classes.headerColorNeutral]: color === 'neutral',
  });

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      setTimeout(() => {
        document.body.style.overflow = null;
      }, 300);
    }
  }, [open]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={DRAWER_ANCHOR}
      hideBackdrop={true}
    >
      <Box className={classes.drawer}>
        <Box component="header" className={headerBackgroundClass}>
          <Box display="flex" flexDirection="column">
            <Typography variant="h3">{title}</Typography>

            {subtitle && (
              <Box mt={1}>
                <Typography>{subtitle}</Typography>
              </Box>
            )}
          </Box>

          <Box display="flex" alignItems="center">
            <ButtonBase onClick={onClose}>
              <img src={closeIcon} alt="Close" />
            </ButtonBase>
          </Box>
        </Box>

        {loading && (
          <Box zIndex={120}>
            <Spinner/>
          </Box>
        )}

        <Scrollbars style={{ width: '100%', height: 'calc(100vh - 128px)' }}>
          <Box p={4} className={cn({ [classes.hiddenForm]: loading })}>
            {!refreshComponents &&
              childrenAttributes.map((childrenAttribute) => (
                <FormField
                  key={childrenAttribute.id}
                  attribute={childrenAttribute}
                  isTaskDetailsVariant={
                    !['file', 'currencyRate', 'amountInKzt'].includes(
                      childrenAttribute.component
                    )
                  }
                />
              ))}
          </Box>
        </Scrollbars>

        <Box className={classes.footer}>
          <FormButtonList
            disabled={false}
            showButtonsOnBothSides
            existingButtonList={buttonList}
            onClick={handleAction}
            validationAttributeList={childrenAttributes}
          />
        </Box>
      </Box>
    </Drawer>
  );
};
