import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette, spacing }) => ({
  customSnackbar: {
    backgroundColor: '#101F2B',
    boxShadow: `0 ${spacing(1)}px ${spacing(4)}px rgba(0, 0, 0, 0.16)`,
    borderRadius: spacing(1),

    minWidth: spacing(24),
    padding: `${spacing(1.5)}px ${spacing(4)}px`
  },
  customSnackbarTitle: {
    fontSize: 12,
    fontWeight: 400,
    color: palette.common.white
  }
}));
