import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ spacing, palette }) => ({
  FormTab: {
    padding: spacing(4, 4, 10, 0),
    minHeight: '100%',
    position: 'relative',
  },
  group: {},
  table: {},
  formGroup: {},
  formGroupTitle: {
    marginTop: spacing(0),
    marginBottom: spacing(4),
    color: '#262842',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
  },
  legalEntititesWrapper: {
    width: '100%',
    marginBottom: spacing(3),
    padding: spacing(2),
    overflowX: 'auto',
    '& div .MuiTableContainer-root': {
      width: 'auto',
    },
  },
  hiddenInTabField: {
    visibility: 'hidden',
    padding: '0 !important',
    margin: '0 !important',
    height: '0 !important',
    opacity: 0,
    float: 'left',
    fontSize: '0px !important',
    '& *, & *:before, &:after': {
      padding: '0 !important',
      margin: '0 !important',
      height: '0 !important',
      minHeight: '0 !important',
      maxHeight: '0 !important',
      width: '0 !important',
      opacity: 0,
      visibility: 'hidden',
      fontSize: '0px !important',
    },
  },
  emptyAttachmentsMessageWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#6D6E85',

    '& h3': {
      marginTop: 16,
      marginBottom: 4,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '20px',
    },

    '& p': {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '18px',
    },
  },
  emptyAttachmentsMessageText: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    marginTop: 8,
  },
  labelStepDescription: {
    color: '#6D6E85',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  comment: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    color: '#1D2B36 !important',
    maxWidth: 800,
    marginRight: spacing(4),
    marginBottom: spacing(2.5),
    '& p, & li': {
      margin: 0,
    },
    '& ul, ol': {
      marginTop: spacing(-2),
      marginBottom: spacing(-2),
      paddingLeft: spacing(6),
    },
  },
  noFieldsBlock: {
    position: 'absolute',
    width: 'calc(100% - 24px)',
    height: '100%',
    marginTop: -70,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#6D6E85',

    '& h3': {
      marginTop: 16,
      marginBottom: 4,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '20px',
    },

    '& p': {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '18px',
    },
  },
  disabledFieldsInfoCard: {
    width: '100%',
    height: 'fit-content',
    marginBottom: 20,
    padding: 12,
    background: '#FCF8EB',
    border: '1px solid #2628421F',
    borderRadius: 12,
    color: '#262842',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
  },
}));
