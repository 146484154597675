import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, FormControl, Typography } from '@material-ui/core';

import { UrgentToggle, AppTextField } from 'components';
import useStyles from '../useStyles';

import {
  URGENT_SWITCH_NAME,
  URGENT_TEXTAREA_NAME,
} from '../constants';
import { useTranslation } from 'react-i18next';

type Props = {
  isUrgentReadOnly: boolean;
  isUrgentRequest: boolean;
  urgentReason: string;
  handleUrgentReason: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setUrgentRequestValue: (value: boolean) => void;
};

const BpmUrgentRequestFormation: React.FunctionComponent<Props> = ({
  isUrgentReadOnly,
  isUrgentRequest,
  urgentReason,
  handleUrgentReason,
  setUrgentRequestValue,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { register, formState } = useFormContext();

  if (!register) {
    return null;
  }

  return (
    <Box py={3} display="flex" width="100%">
      <Typography variant="h5" className={classes.formGroupTitle}>
        {t('form_components.urgent.title')}
      </Typography>
      <Box display="flex" flexDirection="column" width="100%">
        <UrgentToggle
          disabled={isUrgentReadOnly}
          name={URGENT_SWITCH_NAME}
          checked={isUrgentRequest}
          setValue={setUrgentRequestValue}
        />

        {isUrgentRequest &&
          (isUrgentReadOnly ? (
            <Box mt={4} width="100%">
              <Typography>{urgentReason}</Typography>
            </Box>
          ) : (
             <Box mt={4} width="100%">
               <FormControl fullWidth>
                 <AppTextField
                   autoFocus
                   multiline
                   rows={2}
                   rowsMax={5}
                   label={t('form_components.urgent.reasonOnTitle')}
                   disabled={isUrgentReadOnly}
                   {...register(URGENT_TEXTAREA_NAME, {
                     required: t('form_components.required_errors.default') as string,
                   })}
                   defaultValue={urgentReason}
                   onChange={handleUrgentReason}
                   error={Boolean(formState?.errors[URGENT_TEXTAREA_NAME])}
                   helperText={
                     Boolean(formState?.errors[URGENT_TEXTAREA_NAME]) &&
                     formState?.errors[URGENT_TEXTAREA_NAME].message
                   }
                 />
               </FormControl>
             </Box>
           ))}
      </Box>
    </Box>
  );
};

export default BpmUrgentRequestFormation;
