import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { Language, ParallelBranchesGroup, ProcessStep, StepName } from '../../../TemplateProcesses.types';

export const useParallelBranchesGroups = ({
  fields,
  allProcessStepsFields,
  conditions,
  setConditions,
  parallelBranchesGroups,
  setParallelBranchesGroups,
  languages,
  nameFieldRef,
  gridPathRef,
  setIsSubmitAvailable,
  setCurrentProcessIndex,
  setCurrentParallelBranchesGroupId,
  setSelectedItemType,
  setFocusedStepFieldId,
  setFocusedDocumentId,
  setDirectManagerSteps,
  setPerformerSelectionSteps,
  setSummaryFields,
  setSelectedStepField,
  append,
  replace,
  currentTypeSummaryFields
}) => {
  const stepNameTemplate = useMemo((): StepName =>
      languages.reduce((result: StepName, language: Language) => ({
        ...result,
        [language]: '',
      }), {} as StepName),
    [languages]);

  const handleAddParallelBranchesGroup = (processStep: ProcessStep): void => {
    setIsSubmitAvailable(true);
    const previousStepsFields = fields
      .slice(0, processStep ? processStep.stepOrder - 1 : fields.length)
      .reduce((acc, step) => {
        step.fields.forEach(field => {
          if (!acc.some(accField => accField.id === field.id)) {
            acc.push(field);
          }
        });
        return acc;
      }, [])
      .map((item, index) => ({
        ...item,
        order: index + 1,
        isRequired: false,
        isEditable: false,
      }));
    if (processStep === null) {
      // add parallel steps group at the end
      const newParallelStepsGroup: ParallelBranchesGroup = {
        id: uuid(),
        previousStepOrder: fields.length,
        stepName: stepNameTemplate,
        steps: [[fields.length + 1], [fields.length + 2]],
      };
      setParallelBranchesGroups(list => [...list, newParallelStepsGroup]);

      let stepToCopySummaryFields: ProcessStep | undefined;
      for(let i = fields.length - 1;i >= 0;i--) {
        if(!currentTypeSummaryFields.includes(fields[i].id)) {
          stepToCopySummaryFields = fields[i];
          break;
        }
      }
      // add parallel step 1
      append({
        id: uuid(),
        stepOrder: fields.length + 1,
        stepName: stepNameTemplate,
        stepDescription: stepNameTemplate,
        assigneeId: [],
        fields: previousStepsFields,
        timer: '',
        hasTimer: false,
        parallel: false,
        isConditionBranchStep: false,
        isParallelBranchesGroupStep: true,
        summaryFields: stepToCopySummaryFields ? stepToCopySummaryFields.summaryFields : []
      });
      // add parallel step 2
      append({
        id: uuid(),
        stepOrder: fields.length + 2,
        stepName: stepNameTemplate,
        stepDescription: stepNameTemplate,
        assigneeId: [],
        fields: previousStepsFields,
        timer: '',
        hasTimer: false,
        parallel: false,
        isConditionBranchStep: false,
        isParallelBranchesGroupStep: true,
        summaryFields: stepToCopySummaryFields ? stepToCopySummaryFields.summaryFields : []
      });

      setTimeout(() => {
        setCurrentParallelBranchesGroupId(newParallelStepsGroup.id);
        setSelectedItemType('parallelBranchesGroups');
        setFocusedStepFieldId('');
        gridPathRef?.current?.scroll({
          top: gridPathRef?.current?.scrollHeight,
          behavior: 'smooth',
        });
        nameFieldRef?.current?.focus();
      });
    } else {
      // add parallel steps group between step
      const newParallelStepsGroup: ParallelBranchesGroup = {
        id: uuid(),
        previousStepOrder: processStep.stepOrder - 1,
        stepName: stepNameTemplate,
        steps: [[processStep.stepOrder], [processStep.stepOrder + 1]],
      };
      const modifiedParallelStepsGroupsList = parallelBranchesGroups.map(group => {
        if (group.previousStepOrder >= processStep.stepOrder - 1) {
          group.previousStepOrder += 2;
          group.steps = group.steps.map(b => b.map(v => v + 2));
          return group;
        } else {
          return group;
        }
      });
      setParallelBranchesGroups(() => [...modifiedParallelStepsGroupsList, newParallelStepsGroup]);

      const modifiedConditionsList = conditions.map(condition => {
        if (condition.previousStepOrder >= processStep.stepOrder - 1) {
          condition.previousStepOrder += 2;
          condition.positiveBranch = condition.positiveBranch.map(step => step+2);
          condition.negativeBranch = condition.negativeBranch.map(step => step+2);
          return condition;
        } else {
          return condition;
        }
      });
      setConditions(() => modifiedConditionsList);

      const stepsBefore = fields.slice(0, processStep.stepOrder - 1);
      const stepsAfter = fields.slice(processStep.stepOrder - 1).map((step, index) => ({
        ...step,
        stepOrder: processStep.stepOrder + 2 + index,
      }));
      let stepToCopySummaryFields: ProcessStep | undefined;
      for(let i = stepsBefore.length - 1;i >= 0;i--) {
        if(!currentTypeSummaryFields.includes(stepsBefore[i].id)) {
          stepToCopySummaryFields = fields[i];
          break;
        }
      }
      if(!stepToCopySummaryFields) {
        for(let i = processStep.stepOrder - 1;i < fields.length;i++) {
          if(!currentTypeSummaryFields.includes(fields[i].id)) {
            stepToCopySummaryFields = fields[i];
            break;
          }
        }
      }
      // change step order of steps after added parallel steps group and
      // add two new steps with correct step order
      // TODO - check which fields are added there
      replace(
        [
          ...stepsBefore,
          // parallel step 1
          {
            id: uuid(),
            stepOrder: processStep.stepOrder,
            stepName: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
            stepDescription: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
            assigneeId: [],
            fields: previousStepsFields,
            timer: '',
            hasTimer: false,
            parallel: false,
            isConditionBranchStep: false,
            isParallelBranchesGroupStep: true,
            summaryFields: stepToCopySummaryFields ? stepToCopySummaryFields.summaryFields : [],
          },
          // parallel step 2
          {
            id: uuid(),
            stepOrder: processStep.stepOrder + 1,
            stepName: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
            stepDescription: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
            assigneeId: [],
            fields: previousStepsFields,
            timer: '',
            hasTimer: false,
            parallel: false,
            isConditionBranchStep: false,
            isParallelBranchesGroupStep: true,
            summaryFields: stepToCopySummaryFields ? stepToCopySummaryFields.summaryFields : [],
          },
          ...stepsAfter,
        ],
      );
      // modify direct manager steps and performer selection steps
      let filteredDMSteps = [processStep.stepOrder + 2];
      if (processStep?.isConditionBranchStep) {
        const nextStepCondition = modifiedConditionsList?.find(c => [
            ...(c?.hasPositiveBranch ? c?.positiveBranch : []),
          ...(c?.hasNegativeBranch ? c.negativeBranch : []),
        ].includes(processStep?.stepOrder + 2));
        filteredDMSteps = [...(nextStepCondition?.hasPositiveBranch ? nextStepCondition?.positiveBranch : []),
                           ...(nextStepCondition?.hasNegativeBranch ? nextStepCondition.negativeBranch : [])];
      }
      if (processStep?.isParallelBranchesGroupStep) {
        const nextStepParallelStepsGroup = modifiedParallelStepsGroupsList?.find(g => g.steps.flat().includes(processStep?.stepOrder + 2));
        filteredDMSteps = nextStepParallelStepsGroup?.steps.map(v => v[0]) || [];
      }
      setDirectManagerSteps(steps => steps.map(step => step < processStep.stepOrder
                                                       ? step
                                                       : step + 2).filter(step => !filteredDMSteps.includes(step)));
      setPerformerSelectionSteps(steps => steps.map(step => step < processStep.stepOrder ? step : step + 2));

      setTimeout(() => {
        setCurrentParallelBranchesGroupId(newParallelStepsGroup.id);
        setSelectedItemType('parallelBranchesGroups');
      });
    }
  };

  const handleParallelStepsGroupSelect = (parallelStepsGroup: ParallelBranchesGroup): void => {
    setCurrentParallelBranchesGroupId(parallelStepsGroup.id);
    setSelectedItemType('parallelBranchesGroups');
    setFocusedStepFieldId('');
    setFocusedDocumentId('');
  };

  const handleParallelStepsGroupValuesChange = (parallelStepsGroup: ParallelBranchesGroup) => {
    setIsSubmitAvailable(true);
    const groupIndex = parallelBranchesGroups.findIndex(item => item.id === parallelStepsGroup.id);
    const modifiedGroupsList = [...parallelBranchesGroups];
    modifiedGroupsList[groupIndex] = parallelStepsGroup;
    setParallelBranchesGroups(() => modifiedGroupsList);
  };

  const handleParallelStepsGroupBranchAdd = (parallelBranchesGroup: ParallelBranchesGroup) => {
    const groupIndex = parallelBranchesGroups.findIndex(item => item.id === parallelBranchesGroups.id);

    const addedStepOrder = parallelBranchesGroup.steps.flat()[parallelBranchesGroup.steps.flat().length - 1] + 1;
    parallelBranchesGroup.steps = [...parallelBranchesGroup.steps, [addedStepOrder]];

    const modifiedParallelStepsGroupsList = parallelBranchesGroups.map(group => {
      if (group.previousStepOrder >= addedStepOrder - 1) {
        group.previousStepOrder += 1;
        group.steps = group.steps.map(b => b.map(v => v + 1));
        return group;
      } else {
        return group;
      }
    });
    modifiedParallelStepsGroupsList[groupIndex] = parallelBranchesGroups;
    setParallelBranchesGroups(() => modifiedParallelStepsGroupsList);

    const modifiedConditionsList = conditions.map(condition => {
      if (condition.previousStepOrder >= addedStepOrder - 1) {
        condition.previousStepOrder += 1;
        condition.positiveBranch = condition.positiveBranch.map(v => v + 1);
        condition.negativeBranch = condition.negativeBranch.map(v => v + 1);
        return condition;
      } else {
        return condition;
      }
    });
    setConditions(() => modifiedConditionsList);

    const fieldsToAdd = allProcessStepsFields.filter(field => {
      if (field.firstAppearanceStep >= addedStepOrder) {
        return false;
      }

      if (parallelBranchesGroup?.steps?.flat().includes(field?.firstAppearanceStep)) {
        const fieldStepBranchIndex = parallelBranchesGroup.steps.findIndex(b => b.includes(field?.firstAppearanceStep));
        if (fieldStepBranchIndex !== parallelBranchesGroup.steps.length - 1) {
          return false;
        }
      }
      return true;
    });

    const stepsBefore = fields.slice(0, addedStepOrder - 1);
    const stepsAfter = fields.slice(addedStepOrder - 1).map((step, index) => ({
      ...step,
      stepOrder: addedStepOrder + 1 + index,
    }))
    let stepToCopySummaryFields: ProcessStep | undefined;
    for(let i = stepsBefore.length - 1;i >= 0;i--) {
      if(!currentTypeSummaryFields.includes(stepsBefore[i].id)) {
        stepToCopySummaryFields = { ...fields[i] };
        break;
      }
    }
    if(!stepToCopySummaryFields) {
      for(let i = addedStepOrder - 1;i < fields.length;i++) {
        if(!currentTypeSummaryFields.includes(fields[i].id)) {
          stepToCopySummaryFields = { ...fields[i] };
          break;
        }
      }
    }

    replace(
      [
        ...stepsBefore,
        {
          id: uuid(),
          stepOrder: addedStepOrder,
          stepName: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
          stepDescription: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
          assigneeId: [],
          fields: fieldsToAdd.map((item, index) =>
            ({ ...item?.field, order: index + 1, isRequired: false, isEditable: false })),
          timer: '',
          hasTimer: false,
          parallel: false,
          isConditionBranchStep: false,
          isParallelBranchesGroupStep: true,
          summaryFields: stepToCopySummaryFields ? stepToCopySummaryFields.summaryFields : []
        },
        ...stepsAfter,
      ],
    );
    // modify direct manager steps and performer selection steps
    setDirectManagerSteps(steps => steps.map(step => step < addedStepOrder ? step : step + 1));
    setPerformerSelectionSteps(steps => steps.map(step => step < addedStepOrder ? step : step + 1));
  };

  const handleParallelStepsGroupBranchDelete = (parallelBranchesGroup: ParallelBranchesGroup, deletedBranchIndex: number) => {
    const deletedStepsCount = parallelBranchesGroup.steps[deletedBranchIndex].length;
    const firstDeletedStepOrder = parallelBranchesGroup.steps[deletedBranchIndex][0];
    const groupIndex = parallelBranchesGroups.findIndex(item => item.id === parallelBranchesGroup.id);

    const removedSteps = parallelBranchesGroup.steps[deletedBranchIndex];

    if (parallelBranchesGroup.steps.length > 2) {
      parallelBranchesGroup.steps = parallelBranchesGroup.steps
        .filter((v, index) => index !== deletedBranchIndex)
        .map(b => b.map((step) => step < firstDeletedStepOrder ? step : step - deletedStepsCount));
      const modifiedParallelStepsGroupsList = parallelBranchesGroups.map(group => {
        if (group.previousStepOrder >= firstDeletedStepOrder - 1) {
          group.previousStepOrder -= deletedStepsCount;
          group.steps = group.steps.map(b => b.map(v => v - deletedStepsCount));
          return group;
        } else {
          return group;
        }
      });
      modifiedParallelStepsGroupsList[groupIndex] = parallelBranchesGroup;
      setParallelBranchesGroups(() => modifiedParallelStepsGroupsList);

      const fieldsAddedOnRemovedSteps = allProcessStepsFields
        .filter(item => removedSteps.includes(item?.firstAppearanceStep))
        .map(item => item?.field?.id);
      const minStep = Math.min(...(removedSteps || []));

      replace(
        fields
          .map((processField, index) => {
            if (index < minStep) {
              return processField;
            }

            return {
              ...processField,
              fields: processField.fields
                .filter(field => !fieldsAddedOnRemovedSteps.includes(field.id))
                .map((field, index) => ({ ...field, order: index + 1 })),
              summaryFields: processField.summaryFields.filter(id => !fieldsAddedOnRemovedSteps.includes(id))
            };
          })
          .filter(({ stepOrder }) => !removedSteps.includes(stepOrder))
          .map((step, index) => ({
            ...step,
            stepOrder: index + 1,
          })),
      );
      setTimeout(() => {
        setCurrentProcessIndex(parallelBranchesGroup.steps[Math.max(deletedBranchIndex - 1, 0)]);
        setSelectedItemType('processStep');
        setFocusedStepFieldId('');
      });
    } else {
      // remove parallel steps group if only 1 step left
      const modifiedParallelStepsGroupsList = parallelBranchesGroups
        .filter(group => group.id !== parallelBranchesGroup.id)
        .map(group => {
          if (group.previousStepOrder >= firstDeletedStepOrder - 1) {
            group.previousStepOrder -= deletedStepsCount;
            group.steps = group.steps.map(b => b.map(v => v - deletedStepsCount));
            return group;
          } else {
            return group;
          }
        });
      setParallelBranchesGroups(() => modifiedParallelStepsGroupsList);
      const remainedBranchStepsOrders = parallelBranchesGroup.steps.filter((v, index) => index !== deletedBranchIndex)[0];

      const fieldsAddedOnRemovedSteps = allProcessStepsFields
        .filter(item => removedSteps.includes(item?.firstAppearanceStep))
        .map(item => item?.field?.id);
      const minStep = Math.min(...(removedSteps || []));

      replace(
        fields
          .map((processField, index) => {
            if (index < minStep) {
              return processField;
            }

            return {
              ...processField,
              fields: processField.fields
                .filter(field => !fieldsAddedOnRemovedSteps.includes(field.id))
                .map((field, index) => ({ ...field, order: index + 1 })),
            };
          })
          .filter(({ stepOrder }) => !parallelBranchesGroup.steps[deletedBranchIndex].includes(stepOrder))
          .map((step, index) => ({
            ...step,
            stepOrder: index + 1,
            isParallelBranchesGroupStep: remainedBranchStepsOrders.includes(step.stepOrder)
                                         ? false
                                         : step.isParallelStepsGroup,
          })),
      );

      setTimeout(() => {
        setCurrentProcessIndex(remainedBranchStepsOrders[0]);
        setSelectedItemType('processStep');
        setFocusedStepFieldId('');
      });

    }

    const modifiedConditionsList = conditions.map(condition => {
      if (condition.previousStepOrder >= firstDeletedStepOrder - 1) {
        condition.previousStepOrder -= deletedStepsCount;
        condition.positiveBranch = condition.positiveBranch.map(v => v - deletedStepsCount);
        condition.negativeBranch = condition.negativeBranch.map(v => v - deletedStepsCount);
        return condition;
      } else {
        return condition;
      }
    });
    setConditions(() => modifiedConditionsList);

    // modify direct manager steps and performer selection steps
    setDirectManagerSteps(steps => steps.map(step => step < firstDeletedStepOrder ? step : step - deletedStepsCount));
    setPerformerSelectionSteps(steps => steps.map(step => step < firstDeletedStepOrder
                                                          ? step
                                                          : step - deletedStepsCount));
  };

  const handleParallelStepsGroupDelete = (deletedParallelStepsGroup: ParallelBranchesGroup) => {
    const processSteps = fields as unknown as ProcessStep[];
    setIsSubmitAvailable(true);
    const deletedStepsCount = deletedParallelStepsGroup?.steps?.flat().length;
    const modifiedConditionsList = conditions.map(c =>
      c.previousStepOrder >= Math.max(...(deletedParallelStepsGroup?.steps?.flat() || []))
      ? {
          ...c,
          previousStepOrder: c.previousStepOrder - deletedStepsCount,
          positiveBranch: c.positiveBranch.map(v => v - deletedStepsCount),
          negativeBranch: c.negativeBranch.map(v => v - deletedStepsCount),
        }
      : c);
    // focus on deleted condition previous step
    setCurrentProcessIndex(() => deletedParallelStepsGroup.previousStepOrder - 1);
    setSelectedItemType('processStep');

    const modifiedParallelStepsGroupsList = parallelBranchesGroups
      .filter(g => g.id !== deletedParallelStepsGroup.id)
      .map(g =>
        g.previousStepOrder >= Math.max(...(deletedParallelStepsGroup?.steps?.flat() || []))
        ? {
            ...g,
            previousStepOrder: g.previousStepOrder - deletedStepsCount,
            steps: g.steps.map(b => b.map(v => v - deletedStepsCount)),
          }
        : g);

    setTimeout(() => {
      setConditions(() => modifiedConditionsList);
      setParallelBranchesGroups(() => modifiedParallelStepsGroupsList);
    }, 0);

    const removedSteps = deletedParallelStepsGroup?.steps.flat() || [];
    const fieldsAddedOnRemovedSteps = allProcessStepsFields
      .filter(item => removedSteps.includes(item?.firstAppearanceStep))
      .map(item => item?.field?.id);
    const minStep = Math.min(...(deletedParallelStepsGroup?.steps?.flat() || []));

    if (fieldsAddedOnRemovedSteps.length > 0) {
      const modifiedProcessFields = processSteps
        .map((processField, index) => {
          if (index < minStep) {
            return processField;
          }

          return {
            ...processField,
            fields: processField.fields
              .filter(field => !fieldsAddedOnRemovedSteps.includes(field.id))
              .map((field, index) => ({ ...field, order: index + 1 })),
            summaryFields: processField.summaryFields.filter(id => !fieldsAddedOnRemovedSteps.includes(id)),
          };
        })
        .filter(({ stepOrder }) => !removedSteps.includes(stepOrder))
        .map((step, index) => ({
          ...step,
          stepOrder: index + 1,
        }));
      replace(modifiedProcessFields);

      const modifiedConditions = conditions.map(c => {
        if (fieldsAddedOnRemovedSteps?.includes(c?.condition?.field)) {
          c.condition.field = '';
          c.condition.type = '';
          c.condition.value = '';
        }
        return c;
      });
      setConditions(() => modifiedConditions);

      setSelectedStepField('');
    } else {
      replace(
        processSteps
          .filter(({ stepOrder }) => !removedSteps.includes(stepOrder))
          .map((step, index) => ({
            ...step,
            stepOrder: index + 1,
          })),
      );
    }
  };

  const handleParallelBranchStepAdd = (parallelBranchesGroup: ParallelBranchesGroup, branchIndex: number, stepPosition: number) => {
    const addedStepOrder =
      stepPosition < parallelBranchesGroup.steps[branchIndex].length
      ? parallelBranchesGroup.steps[branchIndex][stepPosition]
      : parallelBranchesGroup.steps[branchIndex][stepPosition - 1] + 1;

    parallelBranchesGroup.steps = parallelBranchesGroup.steps.map(b => b.map(v => v >= addedStepOrder ? v + 1 : v));
    parallelBranchesGroup.steps[branchIndex] = [
      ...parallelBranchesGroup.steps[branchIndex].slice(0, stepPosition),
      addedStepOrder,
      ...parallelBranchesGroup.steps[branchIndex].slice(stepPosition),
    ];
    const modifiedParallelBranchesGroups = [...parallelBranchesGroups]
      .map(g =>
        g.previousStepOrder >= addedStepOrder - 1
        ? {
            ...g,
            previousStepOrder: g.previousStepOrder + 1,
            steps: g.steps.map(b => b.map(v => v + 1)),
          }
        : g);
    const modifiedBranchIndex = modifiedParallelBranchesGroups.findIndex(v => v.id === parallelBranchesGroup.id);
    modifiedParallelBranchesGroups[modifiedBranchIndex] = parallelBranchesGroup;
    setParallelBranchesGroups(() => modifiedParallelBranchesGroups);

    const fieldsToAdd = allProcessStepsFields.filter(field => {
      if (field.firstAppearanceStep >= addedStepOrder) {
        return false;
      }

      if (parallelBranchesGroup?.steps?.flat().includes(field?.firstAppearanceStep)) {
        const fieldStepBranchIndex = parallelBranchesGroup.steps.findIndex(b => b.includes(field?.firstAppearanceStep));
        if (fieldStepBranchIndex !== branchIndex) {
          return false;
        }
      }
      return true;
    });

    const stepsBefore = fields.slice(0, addedStepOrder - 1);
    const stepsAfter = fields.slice(addedStepOrder - 1).map((step, index) => ({
      ...step,
      stepOrder: addedStepOrder + 1 + index,
    }))
    let stepToCopySummaryFields: ProcessStep | undefined;
    for(let i = stepsBefore.length - 1;i >= 0;i--) {
      if(!currentTypeSummaryFields.includes(stepsBefore[i].id)) {
        stepToCopySummaryFields = { ...fields[i] };
        break;
      }
    }
    if(!stepToCopySummaryFields) {
      for(let i = addedStepOrder - 1;i < fields.length;i++) {
        if(!currentTypeSummaryFields.includes(fields[i].id)) {
          stepToCopySummaryFields = { ...fields[i] };
          break;
        }
      }
    }
    replace(
      [
        ...stepsBefore,
        {
          id: uuid(),
          stepOrder: addedStepOrder,
          stepName: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
          stepDescription: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
          assigneeId: [],
          fields: fieldsToAdd.map((item, index) =>
            ({ ...item?.field, order: index + 1, isRequired: false, isEditable: false })),
          timer: '',
          hasTimer: false,
          parallel: false,
          isConditionBranchStep: false,
          isParallelBranchesGroupStep: true,
          summaryFields: stepToCopySummaryFields ? stepToCopySummaryFields.summaryFields : []
        },
        ...stepsAfter,
      ],
    );

    const modifiedConditionsList = conditions.map(c =>
      c.previousStepOrder >= addedStepOrder - 1
      ? {
          ...c,
          previousStepOrder: c.previousStepOrder + 1,
          positiveBranch: c.positiveBranch.map(v => v + 1),
          negativeBranch: c.negativeBranch.map(v => v + 1),
        }
      : c);
    setConditions(() => modifiedConditionsList);

    setDirectManagerSteps(steps => steps.map(step => step < addedStepOrder ? step : step + 1));
    setPerformerSelectionSteps(steps => steps.map(step => step < addedStepOrder ? step : step + 1));

    setTimeout(() => {
      setCurrentProcessIndex(addedStepOrder - 1);
      setSelectedItemType('processStep');
      setFocusedStepFieldId('');
      nameFieldRef?.current?.focus();
    });
  };

  return {
    handleAddParallelStepsGroup: handleAddParallelBranchesGroup,
    handleParallelStepsGroupSelect,
    handleParallelStepsGroupValuesChange,
    handleParallelStepsGroupBranchAdd,
    handleParallelBranchStepAdd,
    handleParallelStepsGroupBranchDelete,
    handleParallelStepsGroupDelete,
  };
};
