import React, { ReactElement, RefObject, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { AppTextField } from 'components';
import { Box, Button, IconButton, SvgIcon, Tooltip, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';


import {
  AllProcessFieldsListItem,
  ConditionStep,
  DocumentTemplate,
  FormField,
  ParallelBranchesGroup,
  ProcessStep,
} from '../../TemplateProcesses.types';
import { getDisplayedStepOrder } from 'pages/TemplateProcesses/TemplateProcesses.utils';
import { ReactComponent as CalendarIcon } from 'assets/images/emojis/calendar.svg';
import { UserList } from 'pages/Task/TaskForm/FormFields/Fields';
import Attachments from 'components/Attachments/attachments-component';
import ChevronDown from 'assets/images/icons/chevron_down_regular.svg';
import DragIndicatorVertical from 'assets/images/icons/drag-indicator-vertical.svg';
import FieldDeleteIcon from 'assets/images/icons/template-process-field-delete.svg';
import FieldEditIcon from 'assets/images/icons/template-process-field-edit.svg';
import FieldHiddenIcon from 'assets/images/icons/eye_hide_regular.svg';
import FieldVisibleIcon from 'assets/images/icons/template-process-field-shown.svg';
import InfoIcon from 'assets/images/icons/info_outline.svg';

import useFieldsListCardStyles from './StepFieldCard.useStyles';
import { FIELD_TYPES } from '../ProcessSetupSidePanel/ProcessSetupSidePanel.constants';
import { VariantSelect } from 'components/Attachments/attachments-component/attachments-list/attachments-list.component';

const NOT_DELETABLE_FIELDS_TYPES = [FIELD_TYPES.INTEGRATED, FIELD_TYPES.SYSTEM];

interface ProcessStepCardProps {
  conditions: ConditionStep[];
  field: FormField,
  allProcessStepsFields: AllProcessFieldsListItem[];
  documents: DocumentTemplate[];
  currentStepOrder: number;
  dragHandleProps?: any,
  isDragged?: boolean
  isFocused?: boolean
  areActionsAvailable?: boolean;
  currentLanguage: string;
  gridPathRef: RefObject<HTMLElement>;
  handleProcessSelect: (processStep: ProcessStep) => void;
  isEditDisabled?: boolean;
  onDeleteButtonClick: () => void;
  onEditButtonClick: () => void;
  onVisibilityButtonClick: () => void;
  parallelBranchesGroups: ParallelBranchesGroup[];
  processSteps: ProcessStep[];
  summaryFields: string[];
}

const LOCALES = {
  'en': 'en-GB',
  'kz': 'kk',
  'ru': 'ru',
  'id': 'id',
};

export const StepFieldCard = ({
  processSteps,
  gridPathRef,
  conditions,
  parallelBranchesGroups,
  summaryFields,
  allProcessStepsFields,
  documents,
  currentLanguage,
  currentStepOrder,
  dragHandleProps,
  field,
  isDragged = false,
  isEditDisabled = false,
  isFocused = false,
  areActionsAvailable = true,
  onDeleteButtonClick,
  onEditButtonClick,
  onVisibilityButtonClick,
  handleProcessSelect,
}: ProcessStepCardProps): ReactElement => {
  const classes = useFieldsListCardStyles();
  const { t, i18n } = useTranslation();
  moment.locale(LOCALES[i18n.language] || i18n.language);

  const fieldComponents = (placeholder) => ({
    singleLineText: <AppTextField value="" placeholder={placeholder} fullWidth/>,
    systemGlossary: <AppTextField value="" placeholder={placeholder} fullWidth
                                  InputProps={{
                                    endAdornment: <img src={ChevronDown} alt={''}/>,
                                  }}/>,
    glossary: <AppTextField value="" placeholder={placeholder} fullWidth
                            InputProps={{
                              endAdornment: <img src={ChevronDown} alt={''}/>,
                            }}/>,
    glossaryMultiSelect: <AppTextField value="" placeholder={placeholder} fullWidth
                                       InputProps={{
                                         endAdornment: <img src={ChevronDown} alt={''}/>,
                                       }}/>,
    multiLineText: <AppTextField value="" placeholder={placeholder} rows={2} multiline fullWidth/>,
    currency: <AppTextField value="" placeholder={placeholder} fullWidth/>,
    dateLineArea:
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}
                               locale={LOCALES[i18n.language] || i18n.language}>
        <KeyboardDatePicker
          format="DD/MM/YYYY"
          variant='inline'
          autoOk
          value={''}
          onChange={null}
          disableToolbar
          error={false}
          helperText={''}
          readOnly
          fullWidth
          TextFieldComponent={(props) => <AppTextField {...props} value="" placeholder={placeholder} fullWidth/>}
          keyboardIcon={
            <SvgIcon
              component={CalendarIcon}
              style={{ marginTop: 4, marginLeft: 4 }}
            />
          }
        />
      </MuiPickersUtilsProvider>,
    attachmentTemplate: <div className={classes.attachmentWrapper}>
      <Attachments
        variant={VariantSelect.Compact}
        allowedFileTypes={['doc', 'docx', 'jpg', 'jpeg', 'pdf', 'png', 'ppt', 'pptx', 'xls', 'xlsx', 'csv', 'mp4']}
        onFileSelect={() => {
        }}
        preventFileSelectOpen
        dynamicCompactVariantHeight
      />
    </div>,
    userList: <UserList isPreview noEmptyValueText placeholder={placeholder}/>,
    readonlyField: <Typography
      className={classes.readonlyField}>{t('customProcesses.creationPage.processForm.readonlyFieldComponentText', { defaultValue: 'This is a read-only field, the data will be derived from the field entered in the previous step.' })}</Typography>,
  });

  const isFirstAppearanceStep = useMemo(() =>
      allProcessStepsFields
        .find(item => item?.field?.id === field?.id)
        ?.firstAppearanceStep === currentStepOrder,
    [allProcessStepsFields, field]);

  const handleCardClick = (event) => {
    if (
      !event.target.className?.includes?.('CARD_ACTION') &&
      !event.target.className?.includes?.('TOOLTIP') &&
      !event.target.className?.includes?.('MuiTooltip') &&
      !isEditDisabled &&
      areActionsAvailable
    ) {
      onEditButtonClick();
    }
  };

  const fieldAddedInStep = useMemo(() => {
    const currentField = allProcessStepsFields.find(f => f?.field.id === field.id);

    return currentField?.firstAppearanceStep;
  }, [allProcessStepsFields, field]);

  const tooltipTitle = useMemo(() => {
    if (typeof fieldAddedInStep !== 'number') return;

    const step = processSteps.find(s => s?.stepOrder === fieldAddedInStep);
    const fieldDocument = documents.find(doc => doc?.uniqueId === field?.documentId);

    const stepNumber = getDisplayedStepOrder(step, conditions, parallelBranchesGroups);

    if (step?.stepOrder === currentStepOrder) {
      return <>
        {t('customProcesses.creationPage.processForm.cardTooltips.fieldAddedOnCurrentStep')} {stepNumber} - {step?.stepName[currentLanguage] || t('customProcesses.processStep.stepNamePlaceholder')}

        {field.documentId &&
          <>
            <br/>
            {t('customProcesses.creationPage.processForm.cardTooltips.fieldDocumentInfo')}: {fieldDocument?.title}
          </>
        }
      </>;
    }

    return <>
      {t('customProcesses.creationPage.processForm.cardTooltips.fieldInheritedFromStep')} {stepNumber} - {step?.stepName[currentLanguage] || t('customProcesses.processStep.stepNamePlaceholder')}

      <br/>

      {field.documentId &&
        <>
          {t('customProcesses.creationPage.processForm.cardTooltips.fieldDocumentInfo')}: {fieldDocument?.title}
          <br/>
        </>
      }

      <Button
        variant="text"
        onClick={() => {
          handleProcessSelect(step);

          setTimeout(() => {
            const activeStep = gridPathRef.current.querySelector('div[class*=processStepCardActive]');
            activeStep.scrollIntoView({ behavior: 'smooth' });
          }, 500);
        }}
        className={classNames('TOOLTIP', classes.goToStepButton)}
      >
        <span className="TOOLTIP">
           {t('customProcesses.creationPage.processForm.cardTooltips.goToStep')} {stepNumber}
        </span>
      </Button>
    </>;

  }, [currentLanguage, currentStepOrder, fieldAddedInStep, processSteps, documents]);

  const stepFieldHeaderClassNames = useMemo(() =>
    classNames(classes.name, { [classes.nameRequired]: field.isRequired }), [field.isRequired]);
  const imageClassNames = useMemo(() => classNames(classes.dragIndicator, 'CARD_ACTION'), []);

  const draggableFilesClassNames = useMemo(() => classNames(classes.card,
    {
      [classes.cardDragged]: isDragged,
      [classes.cardFocused]: isFocused,
      [classes.cardDisabled]: isEditDisabled,
      [classes.cardIntegratedField]: [FIELD_TYPES.INTEGRATED, FIELD_TYPES.SYSTEM].includes(field.type as FIELD_TYPES),
    },
  ), [isDragged, isFocused, isEditDisabled, field]);

  return (
    <Box className={draggableFilesClassNames} onClick={handleCardClick}>
      <Box className={imageClassNames} {...dragHandleProps}>
        <img src={DragIndicatorVertical}/>
      </Box>

      <Box className={classes.cardContentWrapper}>
        <div className={classes.nameRow}>
          <Tooltip
            placement="bottom"
            title={field.hint[currentLanguage]}
            disableHoverListener={!field.hint[currentLanguage]}
            disableFocusListener={!field.hint[currentLanguage]}
            disableTouchListener={!field.hint[currentLanguage]}
            interactive
            enterDelay={500}
            classes={{
              tooltipArrow: classes.tooltip,
              arrow: classes.tooltipArrow,
              tooltip: classes.fieldTooltip,
            }}>
            <span
              className={stepFieldHeaderClassNames}>{field.hint[currentLanguage] || (t('customProcesses.sidePanel.form.fieldName'))}</span>
          </Tooltip>

          {field.documentId && <span className={classes.templateFieldChip}>
            {t('customProcesses.creationPage.processForm.fieldFromTemplate')}
          </span>}

          <Tooltip
            placement="bottom"
            title={<span className="TOOLTIP">{tooltipTitle}</span>}
            interactive
            enterDelay={300}
            classes={{
              tooltipArrow: classes.tooltip,
              arrow: classes.tooltipArrow,
              tooltip: classes.fieldTooltip,
            }}>
            <img src={InfoIcon} alt="" className={classNames(classes.infoIcon, 'TOOLTIP')}/>
          </Tooltip>

          {
            ([FIELD_TYPES.INTEGRATED, FIELD_TYPES.SYSTEM].includes(field.type as FIELD_TYPES)) &&
            <Tooltip
              placement="bottom"
              title={t('customProcesses.fields.infoMessages.systemWithoutTags')}
              enterDelay={300}
              classes={{
                tooltipArrow: classes.tooltip,
                arrow: classes.tooltipArrow,
                tooltip: classes.fieldTooltip,
              }}>
              <span className={classes.templateFieldChip}>
                {t('customProcesses.creationPage.processForm.fieldChips.system', { defaultValue: 'From template' })}
              </span>
            </Tooltip>
          }

          {
            field.type === FIELD_TYPES.INTEGRATED &&
            <Tooltip
              placement="bottom"
              title={t('customProcesses.fields.infoMessages.integratedWithoutTags')}
              enterDelay={300}
              classes={{
                tooltipArrow: classes.tooltip,
                arrow: classes.tooltipArrow,
                tooltip: classes.fieldTooltip,
              }}>
              <span className={classes.templateFieldChip}>
                {t('customProcesses.creationPage.processForm.fieldChips.integrated', { defaultValue: 'Integrated' })}
              </span>
            </Tooltip>
          }
        </div>

        <Box className={classes.fieldWrapper}>
          {fieldComponents(field.placeholder[currentLanguage])[field.isEditable ? field.component : 'readonlyField']}

          {(!isEditDisabled && areActionsAvailable) && <Box className={classes.cardActions}>
            {!isFirstAppearanceStep && (
              field?.isHidden
              ?
              <Tooltip title={t('customProcesses.creationPage.processForm.cardTooltips.showField')} placement="bottom"
                       arrow>
                <IconButton
                  className={classNames(classes.fieldActionsButton, 'CARD_ACTION')}
                  disabled={isEditDisabled}
                  onClick={onVisibilityButtonClick}
                >
                  <img src={FieldHiddenIcon} alt={t('customProcesses.creationPage.processForm.cardTooltips.showField')}
                       className="CARD_ACTION"/>
                </IconButton>
              </Tooltip>
              :
              <Tooltip title={t('customProcesses.creationPage.processForm.cardTooltips.hideField')} placement="bottom"
                       arrow>
                <IconButton
                  className={classNames(classes.fieldActionsButton, 'CARD_ACTION')}
                  onClick={onVisibilityButtonClick}
                  disabled={isEditDisabled}>
                  <img src={FieldVisibleIcon} alt={t('customProcesses.creationPage.processForm.cardTooltips.hideField')}
                       className="CARD_ACTION"/>
                </IconButton>
              </Tooltip>
            )}

            {(isFirstAppearanceStep && !NOT_DELETABLE_FIELDS_TYPES.includes(field.type as FIELD_TYPES)) &&
              <Tooltip title={t('customProcesses.creationPage.processForm.cardTooltips.deleteField')} placement="bottom"
                       arrow>
                <IconButton
                  className={classNames(classes.fieldActionsButton, 'CARD_ACTION')}
                  onClick={onDeleteButtonClick} disabled={isEditDisabled}>
                  <img src={FieldDeleteIcon} alt={'delete field'} className="CARD_ACTION"/>
                </IconButton>
              </Tooltip>
            }

            <Tooltip title={t('customProcesses.creationPage.processForm.cardTooltips.editField')} placement="bottom"
                     arrow>
              <IconButton
                className={classNames(classes.fieldActionsButton, 'CARD_ACTION')}
                onClick={onEditButtonClick} disabled={isEditDisabled}>
                <img src={FieldEditIcon} alt={'edit field'} className="CARD_ACTION"/>
              </IconButton>
            </Tooltip>
          </Box>
          }
        </Box>
      </Box>
    </Box>
  );
};
