import get from 'lodash/get';
import { CancelToken } from "axios";

import {
  getBusinessEntity,
  createInstance,
  updateInstance,
  getAttachmentFromPS,
} from 'api/requests';
import { MIME_TYPES } from './constants';

const DEFAULT_MIME_TYPES = Object.keys(MIME_TYPES)
  .map((type) => MIME_TYPES[type])
  .join(',');

export const getMimeTypes = (allowedTypes) =>
  allowedTypes
  ? allowedTypes
    .split(',')
    .map((type) => get(MIME_TYPES, type, ''))
    .filter((type) => !!type)
    .join(',')
  : DEFAULT_MIME_TYPES;

export const createFileInstance = async ({
  payload: {
    file,
    taskId,
    applicationNumber,
    creationDate,
    assignee,
    step,
  },
  cancelToken
}: {
  payload: {
    file: {
      id: number | string;
      name: string;
      mimeType: string;
      size?: number;
    },
    taskId: string;
    applicationNumber: string;
    creationDate?: string;
    assignee?: string;
    step?: string;
  },
  cancelToken?: CancelToken
}) => {
  try {
    const fileAttachmentBusinessEntityResponse = await getBusinessEntity({
      params: {
        sysName: 'file_attachment',
      },
      cancelToken
    });

    const fileAttachmentInstanceCreateResponse = await createInstance({
      params: {
        bsnTypeId: fileAttachmentBusinessEntityResponse.id,
        taskId,
        applicationNumber,
      },
      cancelToken
    });

    const fileAttachmentStringAttributes = fileAttachmentInstanceCreateResponse.stringAttributes.map(
      (attr) => {
        if (attr.name === 'Название') return { ...attr, value: file.name };
        if (attr.name === 'Владелец') return { ...attr, value: '(none)' };
        if (attr.name === 'Тип') return { ...attr, value: file.mimeType };
        if (attr.name === 'Файл') return { ...attr, value: file.id };
        if (attr.name === 'assignee') return { ...attr, value: assignee || '' };
        if (attr.name === 'creationDate') return { ...attr, value: creationDate };
        if (attr.name === 'step') return { ...attr, value: step };
        return attr;
      },
    );

    const fileAttachmentIntegerAttributes = fileAttachmentInstanceCreateResponse.integerAttributes.map(
      (attr) => {
        if (attr.name === 'size') return { ...attr, value: file.size };
        return attr;
      },
    );

    const fileAttachmentEntityInstance = {
      ...fileAttachmentInstanceCreateResponse,
      stringAttributes: fileAttachmentStringAttributes,
      integerAttributes: fileAttachmentIntegerAttributes,
    };

    await updateInstance({
      params: {
        taskId
      },
      data: fileAttachmentEntityInstance,
      cancelToken
    });

    return fileAttachmentInstanceCreateResponse.id;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAttachmentFile = async ({
  attachmentId,
  handleSuccess,
  handleError,
}) => {
    try {
      const attachmentBlob = await getAttachmentFromPS({fileId: attachmentId});

      handleSuccess(attachmentBlob);
    } catch (error) {
      handleError(error);
    }
};

export const getErrorMessage = (errors) => get(errors, 'message', '');
