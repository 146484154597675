import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 40,
    margin: '0 auto',

    '&.fullscreen': {
      background: 'rgba(255, 255, 255, 0.5)',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      zIndex: 9999,
    },

    '&.absolute': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(255, 255, 255, 0.5)',
      zIndex: 10,
    },

    '&.buttonVersion': {
      width: 'fit-content !important',
      height: 'fit-content !important',
    },
    '&.jss2': {
      background: 'rgba(255, 255, 255, 0.5) !important',
    },
  },
  circularProgress: {
    color: '#8B8C9E',
    maxHeight: 40,
    maxWidth: 40,
    backgroundColor: 'transparent !important',
  },
  circularProgressWhite: {
    color: '#FFFFFF !important',
  },
  innerWrap: {
    display: 'block',
    maxWidth: 40,
    maxHeight: 40,
  },
}));
