import React, { useState, useCallback, useMemo, memo, ReactElement, useEffect } from 'react';

import { Box, ButtonBase, Grow, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment/moment';
import { useDispatch } from 'react-redux';

import { CloseButton, TaskStatusIndicator, UserAvatar } from 'components';
import { Creator } from '../Creator';

import { useTaskState } from 'store/requests';
import { getProfileCompanyDataFromLocalStorage, limitStringLength } from 'utils/user';

import useStyles from './useStyles';

import LinkIcon from 'assets/images/icons/links-tab-icon.svg';
import ClockIcon from 'assets/images/icons/clock-icon-dark.svg';
import ClockIconOverdue from 'assets/images/icons/clock-icon-overdue.svg';
import PriorityHigh from 'assets/images/icons/priority-high.svg';
import PriorityMedium from 'assets/images/icons/priority-medium.svg';

import { TaskStatusesEnum, UserType } from 'types';
import { REQUEST_LINK_TEMPLATE, TOOLTIP_TIMEOUT, WORKSPACE_PARAM } from './constants';
import { useHoldingTranslationsLoadedState } from '../../../../store/main';
import { getProcessWatchers } from 'api/requests';
import { setSelectedTask, setSelectedTaskWatchers } from 'store/approvals';
import WatcherListIdentificator from './WatcherListIdentificator';
import { AvatarGroup } from 'components/AvatarGroup/AvatarGroup';

type TaskStatuses = {
  [key in TaskStatusesEnum]: {
    color: string;
    translationKey: string;
  };
};

export const taskStatuses: TaskStatuses = {
  InProgress: {
    color: '#497CF6',
    translationKey: 'form_components.votingResults.inProgress',
  },
  Completed: {
    color: '#2DB77B',
    translationKey: 'task_statuses.completed',
  },
  Rework: {
    color: '#FFA530',
    translationKey: 'task_statuses.rework',
  },
  Reject: {
    color: '#D6331F',
    translationKey: 'task_statuses.reject',
  },
  Canceled: {
    color: '#D6331F',
    translationKey: 'task_statuses.canceled',
  },
  Draft: {
    color: '#8B8C9E',
    translationKey: 'task_statuses.draft',
  },
};

type DataViewHeaderProps = {
  handleClose: () => void;
  ButtonsComponent: JSX.Element;
  watchers: UserType[];
};

export const DataViewHeader = memo(
  ({ handleClose, ButtonsComponent }: DataViewHeaderProps): ReactElement => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const holdingTranslationsLoaded = useHoldingTranslationsLoadedState();

    const { data: bpmTask } = useTaskState();

    const tooltipCopyLinkText = t('task_data_view.copy_link');
    const tooltipCopiedLinkText = t('task_data_view.copied_link');

    const [isTooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [tooltipText, setTooltipText] = useState<string>(tooltipCopyLinkText);
    const [watchers, setWatchers] = useState<UserType[]>([]);

    const { applicationNumber, companyId, taskId, title, initiator, dueDateTemplate, taskEndDate } = useMemo(() => {
      return {
        applicationNumber: bpmTask.applicationNumber,
        companyId: bpmTask.businessTask?.companyId || '',
        taskId: bpmTask.taskId,
        title: t(`constructor-${bpmTask.processSysName}.name`, { defaultValue: bpmTask.processName }),
        initiator: bpmTask.initiator,
        dueDateTemplate: bpmTask.businessTask.dueDateTemplate,
        taskEndDate: bpmTask.businessTask.taskEndDate,
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bpmTask, holdingTranslationsLoaded]);

    const timerStatus = useMemo(() => {
      if (!dueDateTemplate || (taskEndDate && moment(dueDateTemplate).isAfter(moment(taskEndDate)))) {
        return {
          formattedDeadline: null,
          isTimerOver: false,
        };
      }

      const deadline = moment(dueDateTemplate);
      const isTimerOver = taskEndDate ? deadline.isBefore(moment(taskEndDate)) : deadline.isBefore(moment(Date.now()));
      return {
        formattedDeadline: deadline.format('DD.MM.Y, HH:mm'),
        isTimerOver,
      };
    }, [dueDateTemplate, taskEndDate]);

    const isTimerOverdue = useMemo(() => {
      return typeof timerStatus?.isTimerOver === 'boolean' && timerStatus?.isTimerOver;
    }, [timerStatus]);

    const showTooltip = useCallback(() => {
      setTooltipOpen(true);
    }, [setTooltipOpen]);

    const closeTooltip = useCallback(() => {
      if (tooltipText !== tooltipCopiedLinkText) {
        setTooltipOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTooltipOpen, tooltipText]);

    const copyTaskLink = useCallback(() => {
      const { id: companyId } = getProfileCompanyDataFromLocalStorage();
      const requestLink = REQUEST_LINK_TEMPLATE + bpmTask?.processInstanceId + WORKSPACE_PARAM + companyId;
      navigator.clipboard.writeText(requestLink);
      setTooltipText(tooltipCopiedLinkText);

      // 2 timeouts to prevent change of tooltip text before it is fully close
      setTimeout(() => setTooltipOpen(false), TOOLTIP_TIMEOUT);
      setTimeout(() => setTooltipText(tooltipCopyLinkText), TOOLTIP_TIMEOUT + 200);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskId, setTooltipOpen, setTooltipText]);

    const getDueDate = useMemo(() => limitStringLength(timerStatus?.formattedDeadline || '-', 25), [timerStatus?.formattedDeadline]);

    const clockTitleClassNames = useMemo(
      () =>
        classNames(classes.clockTitle, {
          [classes.clockTimeOverdue]: isTimerOverdue,
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isTimerOverdue]
    );

    const clockTimeClassNames = useMemo(
      () =>
        classNames(classes.clockTime, {
          [classes.clockTimeOverdue]: isTimerOverdue,
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isTimerOverdue]
    );

    useEffect(() => {
      getProcessWatchers(bpmTask.processInstanceId)
        .then((data) => {
          setWatchers(data);
          dispatch(setSelectedTaskWatchers(data));
          dispatch(
            setSelectedTask({
              selectedUsersId: data.map((user) => user.id),
              selectedUsersObject: data,
            })
          );
        })
        .catch((error) => {
          console.error(error);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bpmTask]);

    // To clear saved state
    useEffect(() => {
      return () => {
        dispatch(
          setSelectedTask({
            selectedUsersId: [],
            selectedUsersObject: [],
          })
        );
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <Box className={classes.mainHeader} borderLeft={`8px solid ${taskStatuses[bpmTask.businessTask.taskStatus]?.color}`}>
          <Box className={classes.mainHeaderLeftContent}>
            <Box display="flex" alignItems="center">
              <img className={classes.taskIcon} src={bpmTask.businessEntity.iconPath} alt="" />
              <span className={classes.applicationNumber}>{applicationNumber}</span>

              <Tooltip TransitionComponent={Grow} title={tooltipText} open={isTooltipOpen} placement={'top'}>
                <ButtonBase onClick={copyTaskLink} onMouseEnter={showTooltip} onMouseLeave={closeTooltip} style={{ marginTop: 4 }}>
                  <img src={LinkIcon} alt="copy link" className={classes.copyLinkButton} />
                </ButtonBase>
              </Tooltip>
            </Box>

            <Tooltip TransitionComponent={Grow} title={title} placement="bottom-start">
              <h2 className={classes.cardTitle}>{title}</h2>
            </Tooltip>
          </Box>

          <Box display="flex" alignItems="center" gap="12px" marginLeft="12px">
            {/* <Box display="flex" alignItems="center">
              <Typography className={classes.progressTitle} color='secondary'>
                {t("general.Progress")}
              </Typography>
              <TaskStatusIndicator
                step={step}
                variant="header"
                totalSteps={totalSteps}
                isCompleted={isCompleted}
                isCancelled={isCancelled}
                processSysName={processSysName}
                parallelBranchesName={parallelBranchesName}
                parallelBranchesStepperOrder={parallelBranchesStepperOrder}
                isRejected={isRejected}
                stepsList={stepsList}
                hasCondition={hasCondition}
              />
            </Box> */}

            {/* <WatcherListIdentificator watchers={watchers} /> */}

            {ButtonsComponent}

            <CloseButton onClick={handleClose} />
          </Box>
        </Box>

        <Box className={classes.subheader}>
          <Box display="flex" alignItems="center">
            <Typography color="secondary">{t('customProcesses.table.status')}</Typography>
            <Box className={classes.statusIcon}>
              <Box className={classes.circle} bgcolor={taskStatuses[bpmTask.businessTask.taskStatus]?.color} />
            </Box>
            <Typography className={classes.textEllipsis}>{t(taskStatuses[bpmTask.businessTask.taskStatus]?.translationKey)}</Typography>
          </Box>

          {typeof bpmTask.businessTask.urgent === 'boolean' && (
            <Box display="flex" alignItems="center">
              <Typography color="secondary">{t('general.Priority', { defaultValue: 'Priority' })}</Typography>
              <img className={classes.priorityIcon} src={bpmTask.businessTask.urgent ? PriorityHigh : PriorityMedium} alt="" />
              <Typography>{bpmTask.businessTask.urgent ? t('form_components.urgent.high') : t('form_components.urgent.medium')}</Typography>
            </Box>
          )}

          <Box display="flex" alignItems="center">
            <Typography color="secondary">{t('customProcesses.creationPage.processForm.initiator')}</Typography>
            <Box ml={2} className={classes.textEllipsis}>
              <Creator maxWidth={180} initiator={initiator} companyId={companyId} />
            </Box>
          </Box>

          {watchers?.length ? (
            <Box display="flex" alignItems="center">
              <Typography color="secondary">{t('Watchers.watchers')}</Typography>
              <Box ml={2} className={classes.textEllipsis}>
                {<AvatarGroup users={watchers} />}
              </Box>
            </Box>
          ) : null}

          <Box className={classes.remainingTimeWrapper}>
            <Typography color="secondary" className={clockTitleClassNames}>
              {isTimerOverdue ? t('task_data_view.timerOverdue') : t('task_data_view.timerDueDate')}:
            </Typography>

            <img src={isTimerOverdue ? ClockIconOverdue : ClockIcon} className={classes.clockIcon} alt="" />
            <span className={clockTimeClassNames} title={timerStatus?.formattedDeadline}>
              {getDueDate}
            </span>
          </Box>
        </Box>
      </>
    );
  }
);

export default DataViewHeader;
