import React, { ReactElement, useState } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import useDocumentFormStyles from './DocumentsForm.useStyles';
import { DocumentTemplatesModal } from '../DocumentTemplatesModal';
import VisibleFieldsIcon from 'assets/images/icons/template-processes-visible-fields-icon.svg';
import PlusIcon from 'assets/images/icons/tertiary-plus-sign.svg';
import {ReactComponent as SignatureIcon} from 'assets/images/icons/signature_regular.svg';
import cn from 'classnames';
import { DocumentsFormCard } from './DocumentsFormCard';
import {
  ConditionStep,
  DocumentTemplate,
  Language,
  ParallelBranchesGroup,
  ProcessStep, StepValidationResult,
} from '../../TemplateProcesses.types';

interface DocumentsFormProps {
  currentStep: any;
  conditions: ConditionStep[];
  parallelBranchesGroups: ParallelBranchesGroup[];
  documents: DocumentTemplate[];
  formErrors: StepValidationResult[];
  processSteps: ProcessStep[];
  focusedDocumentId: string;
  focusedStepFieldId: string;
  isDragActive: boolean;
  dragType: 'documents' | 'fields';
  isEditDisabled: boolean;
  gridPathRef: any;
  currentLanguage: Language;
  onDocumentAdd: (documentId: number, order: number) => void;
  onDocumentFocus: (documentUniqueId: string) => void;
  onDocumentDelete: (documentUniqueId: string) => void;
  onDocumentVisibilityChange: (documentUniqueId: string, isVisible: boolean) => void;
  handleProcessSelect: (step: ProcessStep) => void;
  handleDocumentPreviewOpen: (documentId: number, documentData?: DocumentTemplate) => void;
}

export const DocumentsForm = ({
  documents,
  processSteps,
  formErrors = [],
  conditions,
  parallelBranchesGroups,
  focusedDocumentId,
  focusedStepFieldId,
  isDragActive,
  dragType,
  isEditDisabled,
  gridPathRef,
  currentLanguage,
  onDocumentAdd,
  onDocumentFocus,
  onDocumentDelete,
  onDocumentVisibilityChange,
  currentStep,
  handleProcessSelect,
  handleDocumentPreviewOpen,
}: DocumentsFormProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useDocumentFormStyles();
  const [isAddDocumentModalOpen, setAddDocumentModalOpen] = useState(false);

  const handleDocumentAdd = (documentId) => {
    onDocumentAdd(documentId, documents.length + 1);
  };

  return <Box>
    <div className={classes.infoCard}>
      <div className={classes.infoCardLeftPart}>
        <SignatureIcon className={classes.infoCardIcon}/>
      </div>
      <div className={classes.infoCardRightPart}>
        <span className={classes.infoCardTitle}>{t('customProcesses.processForm.documentsInfoCard.title')}</span>
        <span className={classes.infoCardMessage}>{t('customProcesses.processForm.documentsInfoCard.message')}</span>
      </div>
    </div>

    <Box className={classes.fieldGroup}>
      <div className={classes.fieldGroupHeader}>
        <img src={VisibleFieldsIcon} className={classes.processStepFormFieldsGroupIcon}/>
        <Typography className={classes.processStepFormFieldsGroupTitle}>
          {t('customProcesses.processForm.documentsTitleVisible')}
        </Typography>
      </div>

      <Droppable key="PROCESS_DOCUMENTS_VISIBLE" droppableId="PROCESS_DOCUMENTS_VISIBLE"
                isDropDisabled={dragType !== 'documents'}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={cn(classes.fieldsListWrapper, { [classes.documentsListDragZoneVisible]: isDragActive && dragType === 'documents' })}
          >
            {documents
              .filter(doc => doc.firstAppearanceStep <= currentStep?.stepOrder)
              .map((doc, index) =>
                <Draggable
                  key={doc.uniqueId}
                  draggableId={doc.uniqueId}
                  isDragDisabled={doc.firstAppearanceStep === currentStep?.stepOrder}
                  index={index}>
                  {(provided, snapshot) => (
                    <React.Fragment>
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        isDragging={snapshot.isDragging}
                        className={classes.documentsListDraggableItem}
                        style={
                          provided.draggableProps
                            .style
                        }>
                        <DocumentsFormCard
                          key={doc.uniqueId}
                          document={doc}
                          processSteps={processSteps}
                          conditions={conditions}
                          parallelBranchesGroups={parallelBranchesGroups}
                          gridPathRef={gridPathRef}
                          currentLanguage={currentLanguage}
                          isEditButtonHidden={currentStep?.isConditionBranchStep}
                          onEdit={() => {
                            onDocumentFocus(doc.uniqueId);
                          }}
                          hasNoSignatureError={formErrors?.[currentStep?.stepOrder - 1]?.documentsWithoutSignature?.includes(doc.uniqueId)}
                          onPreview={() => handleDocumentPreviewOpen(doc.id)}
                          onDelete={() => onDocumentDelete(doc.uniqueId)}
                          onVisibilityChange={(isVisible) => onDocumentVisibilityChange(doc.uniqueId, isVisible)}
                          focusedDocumentId={focusedDocumentId}
                          currentProcessStepOrder={currentStep?.stepOrder}
                          dragHandleProps={provided.dragHandleProps}
                          isEditDisabled={isEditDisabled}
                          areActionsAvailable={!(focusedStepFieldId || focusedDocumentId)}
                          handleProcessSelect={handleProcessSelect}
                        />
                      </Box>
                    </React.Fragment>
                  )}
                </Draggable>,
              )}

            {provided.placeholder}

            <Box className={classes.addDocumentContent}>
              <Button
                variant="text"
                startIcon={<img src={PlusIcon}/>}
                onClick={() => setAddDocumentModalOpen(true)}
              >
                {t('customProcesses.processForm.documentsSection.selectTemplateButton')}
              </Button>
              <span className={classes.addDocumentContentInfoMessage}>
              {t('customProcesses.processForm.documentsSection.orDragAndDrop')}</span>
            </Box>
          </div>
        )}
      </Droppable>
    </Box>

    <DocumentTemplatesModal
      isOpen={isAddDocumentModalOpen}
      onClose={() => setAddDocumentModalOpen(false)}
      onDocumentSelect={handleDocumentAdd}
      onDocumentPreview={handleDocumentPreviewOpen}
    />
  </Box>;
};
