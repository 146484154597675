import { useCallback, useMemo } from "react";

import { STORAGE } from "components/Attachments/constants";
import { environment } from 'environments/environment';
const { env } = environment;

type AttachmentsMigrationProps = {
  storage?: string;
  createdDate?: string;
}

type AttachmentsMigrationResponse = {
  actualStorage: string;
}

const ATTACHMENT_PS_MIGRATION_DATE = {
  DEV: new Date('06/14/2022 00:00:00 GMT+0600'),
  QA: new Date('06/15/2022 00:00:00 GMT+0600'),
  PROD: new Date('06/16/2022 18:00:00 GMT+0600')
}

export const useStorageMigration = ({
  storage,
  createdDate
}: AttachmentsMigrationProps): AttachmentsMigrationResponse => {
  const getAttachmentsMigrationDate = (): Date =>
    ATTACHMENT_PS_MIGRATION_DATE[env] || ATTACHMENT_PS_MIGRATION_DATE.DEV

  const isAttachmentsActualVersion = useCallback((processCreatedDate: string): boolean => {
    const processDate = new Date(processCreatedDate);
    const releaseDate = getAttachmentsMigrationDate();

    return processDate > releaseDate;
  }, []);

  const actualStorage = useMemo((): string => {
    if (storage) {
      return storage;
    }

    if (isAttachmentsActualVersion(createdDate)) {
      return STORAGE.PS;
    }

    return STORAGE.GOOGLE_DRIVE;
  }, [createdDate, isAttachmentsActualVersion, storage]);

  return {
    actualStorage,
  }
}
