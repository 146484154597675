import React, { ReactElement, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  MenuItem, Radio,
  RadioGroup,
  Select,
  SvgIcon,
  Typography
} from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { LocalizationProvider, DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { theme } from '@dartech/dms-ui';

import { NumberInputWithArrows } from 'components';
import { nearestFutureMinutes } from 'utils/time';

import useAutostartFormStyles from './AutostartForm.useStyles';
import { AutostartSettings, Weekday, StepValidationResult } from '../../TemplateProcesses.types';
import { MAX_REPEAT_COUNTS } from '../../TemplateProcesses.constants';

import dropdownIndicatorIcon from 'assets/images/icons/dropdown-indicator.svg';
import { ReactComponent as CalendarIcon } from 'assets/images/icons/new-calendar-icon.svg';
import { ReactComponent as ClockIcon } from 'assets/images/icons/new-clock-icon.svg';
import WarningIcon from 'assets/images/icons/warning-sign.svg';

const LOCALES = {
  'en': 'en-GB',
  'kz': 'kk',
  'ru': 'ru',
  'id': 'id'
};

const WEEKDAYS: { value: Weekday, label: string }[] = [
  { value: 'MONDAY', label: 'customProcesses.creationPage.processForm.autostartWeekdays1' },
  { value: 'TUESDAY', label: 'customProcesses.creationPage.processForm.autostartWeekdays2' },
  { value: 'WEDNESDAY', label: 'customProcesses.creationPage.processForm.autostartWeekdays3' },
  { value: 'THURSDAY', label: 'customProcesses.creationPage.processForm.autostartWeekdays4' },
  { value: 'FRIDAY', label: 'customProcesses.creationPage.processForm.autostartWeekdays5' },
  { value: 'SATURDAY', label: 'customProcesses.creationPage.processForm.autostartWeekdays6' },
  { value: 'SUNDAY', label: 'customProcesses.creationPage.processForm.autostartWeekdays7' }
];

interface AutostartFormProps {
  values: AutostartSettings;
  formErrors: StepValidationResult[];
  disabled?: boolean;
  updateValues: (newValues: AutostartSettings) => void;
}

export const AutostartForm = ({
                                values,
                                formErrors,
                                disabled = false,
                                updateValues
                              }: AutostartFormProps): ReactElement => {
  const { t, i18n } = useTranslation();
  moment.locale(LOCALES[i18n.language] || i18n.language);
  const classes = useAutostartFormStyles();

  const startDateCalendarIconRef = useRef<HTMLElement>(null);
  const endDateCalendarIconRef = useRef<HTMLElement>(null);

  const handleChange = (key: string, value: unknown) => {
    updateValues({ ...values, [key]: value });
  };

  const handleDaySelect = (value) => {
    const weekdays = values.repeatDays.includes(value)
      ? values.repeatDays.filter(v => v !== value)
      : [...values.repeatDays, value];
    handleChange('repeatDays', weekdays);
  };

  const timeOptions = useMemo(() => {
    const options = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 4; m++) {
        options.push(`${h.toString().padStart(2, '0')}:${(m * 15).toString().padStart(2, '0')}`);
      }
    }
    return options;
  }, [values?.startDate]);

  const handleTimeSelect = (value: string) => {
    const [h, m] = value.split(':');
    const newStartDate = moment(values.startDate);
    newStartDate.set({ 'hours': +h, 'minutes': +m });
    handleChange('startDate', newStartDate.toDate());
  };

  const timeValue = useMemo(() => {
    if (values?.startDate) {
      const nearestDate = nearestFutureMinutes(15, moment(values?.startDate));
      const h = nearestDate.hours();
      const m = nearestDate.minutes();
      return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
    }
    return null;
  }, [values?.startDate, timeOptions]);

  return (
    <Box className={classes.autostartForm}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box className={classes.repeatWrapper}>
          <Typography className={classes.repeatLabel}>
            {t('customProcesses.creationPage.processForm.autostartRepeatEveryLabel')}
          </Typography>
          <Box className={classes.repetitionsInputsWrapper}>
            <Box>
              <NumberInputWithArrows
                value={values.repeatCount}
                maxValue={MAX_REPEAT_COUNTS[values.repeatUnit]}
                minValue={0}
                disabled={disabled}
                onChange={value => handleChange('repeatCount', value)}
                error={formErrors[0]?.autostartRepeatCount || values.repeatCount === 0 || values.repeatCount > MAX_REPEAT_COUNTS[values.repeatUnit]}
                className={classes.repeatCountInput} />
              <Select
                className={classes.repeatUnitSelect}
                IconComponent={(props) => <img {...props} src={dropdownIndicatorIcon} />}
                value={values.repeatUnit}
                disabled={disabled}
                onChange={event => handleChange('repeatUnit', event.target.value as string)}
              >
                <MenuItem value={'day'}>{t('customProcesses.creationPage.processForm.autostart.day')}</MenuItem>
                <MenuItem value={'week'}>{t('customProcesses.creationPage.processForm.autostart.week')}</MenuItem>
                <MenuItem value={'month'}>{t('customProcesses.creationPage.processForm.autostart.month')}</MenuItem>
                <MenuItem value={'year'}>{t('customProcesses.creationPage.processForm.autostart.year')}</MenuItem>
              </Select>
            </Box>

            <Box className={classes.processStepFormErrorMessage}>
              {(formErrors[0]?.autostartRepeatCount || values.repeatCount === 0 || values.repeatCount > MAX_REPEAT_COUNTS[values.repeatUnit]) && (
                <>
                  <img src={WarningIcon} alt="warning" />
                  <span>{t('customProcesses.creationPage.processForm.autostartInvalidNumberError')}</span>
                </>
              )}
            </Box>
          </Box>
        </Box>

        <Box className={classes.startWrapper}>
          <Typography className={classes.blockLabel}>
            {t('customProcesses.creationPage.processForm.autostartStartLabel')}
          </Typography>

          <Box className={classes.startInputsWrapper}>
            <MuiDatePicker
              className={classes.startDateSelect}
              format="MMM DD, YYYY"
              disabled={disabled}
              value={moment(values.startDate)}
              onChange={(value) => {
                if (value && value?.toDate().toString() !== 'Invalid Date') {
                  handleChange('startDate', value?.toDate());
                }
              }}
              disablePast
            />

            <Select
              className={classes.startTimeSelect}
              value={timeValue}
              disabled={disabled}
              onChange={(event) => handleTimeSelect(event?.target?.value as string)}
              IconComponent={(props) => <SvgIcon {...props} component={ClockIcon} />}
            >
              {timeOptions.map(option =>
                <MenuItem value={option}>{option}</MenuItem>
              )}
            </Select>
          </Box>
        </Box>

        {values.repeatUnit === 'week' &&
          (<Box className={classes.repeatWeekdaysWrapper}>
            <Typography className={classes.blockLabel}>
              {t('customProcesses.creationPage.processForm.autostartRepeatDaysLabel')}
            </Typography>
            <Box className={classes.repeatWeekdaysButtonsWrapper}>
              {
                WEEKDAYS.map(({ value, label }) =>
                  <Button
                    className={cn(classes.weekdayButton, {
                      [classes.selectedWeekdayButton]: values.repeatDays.includes(value)
                    })}
                    disableRipple
                    onClick={() => handleDaySelect(value)}
                  >
                    {t(label)}
                  </Button>
                )
              }
            </Box>
          </Box>)
        }

        {values.repeatUnit === 'month' &&
          (<Box className={classes.repeatInfoMessage}>
            <span>{t('customProcesses.creationPage.processForm.autostartWillSend')}:</span>
            {t('customProcesses.creationPage.processForm.autostartWillSendMonthly1')}
            {' '}{moment(values.startDate).date()}
            {' '}{t('customProcesses.creationPage.processForm.autostartWillSendMonthly2')}
          </Box>)
        }

        {values.repeatUnit === 'year' &&
          (<Box className={classes.repeatInfoMessage}>
            <span>{t('customProcesses.creationPage.processForm.autostartWillSend')}:</span>
            {' '}{t('customProcesses.creationPage.processForm.autostartWillSendYearly')}
            {' '}{moment(values.startDate).format('MMMM D')}
          </Box>)
        }

        <Box className={classes.endingWrapper}>
          <Typography className={classes.blockLabel}>
            {t('customProcesses.creationPage.processForm.autostartEndingLabel')}
          </Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={values.endingType}
            onChange={(_, value) => handleChange('endingType', value)}
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="never"
              control={<Radio
                disabled={disabled}
                className={cn(classes.radioButton, {
                  [classes.radioButtonChecked]: values.endingType === 'never'
                })}
              />}
              label={<span
                className={classes.radioOptionLabel}>{t('customProcesses.creationPage.processForm.autostartEndingNever')}</span>}
            />

            <FormControlLabel
              value="date"
              control={<Radio
                disabled={disabled}
                className={cn(classes.radioButton, { [classes.radioButtonChecked]: values.endingType === 'date' })} />}
              label={<Box className={classes.radioOptionLabelWrapper}>
                <span
                  className={classes.radioOptionLabel}>{t('customProcesses.creationPage.processForm.autostartEndingDate')}</span>
                {values.endingType === 'date' &&
                  (<>
                      <MuiDatePicker
                        className={classes.startDateSelect}
                        format="MMM DD, YYYY"
                        disabled={disabled}
                        value={moment(values.endingDate)}
                        onChange={(value) => {
                          if (value && value?.toDate().toString() !== 'Invalid Date') {
                            handleChange('endingDate', value?.toDate());
                          }
                        }}
                        disablePast
                      />
                    </>
                  )}
              </Box>}
            />

            <FormControlLabel
              value="after"
              control={<Radio
                disabled={disabled}
                className={cn(classes.radioButton, { [classes.radioButtonChecked]: values.endingType === 'after' })} />}
              label={<Box className={classes.radioOptionLabelWrapper}>
                <span
                  className={classes.radioOptionLabel}>{t('customProcesses.creationPage.processForm.autostartEndingAfter')}</span>
                {values.endingType === 'after' &&
                  (<Box className={classes.endingAfterInputsWrapper}>
                      <Box className={classes.endingAfterInputsInnerWrapper}>
                        <NumberInputWithArrows
                          value={values.endingRepetitionsCount}
                          minValue={0}
                          disabled={disabled}
                          error={formErrors[0]?.autostartEndRepeatCount || values.endingRepetitionsCount === 0 || values.endingRepetitionsCount > MAX_REPEAT_COUNTS[values.repeatUnit]}
                          maxValue={MAX_REPEAT_COUNTS[values.repeatUnit]}
                          onChange={value => handleChange('endingRepetitionsCount', value)}
                          className={classes.endRepetitionsCountInput} />
                        <span
                          className={classes.repetitionsLabel}>{t('customProcesses.creationPage.processForm.autostartEndingAfterRepetitions')}</span>
                      </Box>
                      <Box className={classes.processStepFormErrorMessage}>
                        {(formErrors[0]?.autostartEndRepeatCount || values.endingRepetitionsCount === 0 || values.endingRepetitionsCount > MAX_REPEAT_COUNTS[values.repeatUnit]) && (
                          <>
                            <img src={WarningIcon} alt="warning" />
                            <span>{t('customProcesses.creationPage.processForm.autostartInvalidNumberError')}</span>
                          </>
                        )}
                      </Box>
                    </Box>
                  )}
              </Box>
              }
            />
          </RadioGroup>
        </Box>
      </LocalizationProvider>
    </Box>
  );
};
