import { makeStyles } from '@material-ui/core';

export default makeStyles(({ palette, spacing }) => ({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginLeft: spacing(-6),
    marginRight: spacing(-6),
    '&.Mui-expanded': {
      marginLeft: spacing(-6),
      marginRight: spacing(-6),
    },
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  },
  header: {
    height: 48,
    border: 'none',
    width: '100%',

    '& div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  headerInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerControls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: spacing(2),

    '& div': {
      marginBottom: 0,
    },
  },
  headerControlsListVariant: {
    paddingRight: 0,
  },
  headerControlsListWithGroupedAttachmentsVariant: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 100
  },

  icon: {
    marginLeft: spacing(6),
    marginRight: spacing(6),
    maxHeight: 11,
    maxWidth: 11,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    display: 'inline-block',
    lineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    boxOrient: 'vertical',
    maxHeight: 20,
  },
  body: {
    paddingLeft: 60,
    paddingRight: 16,
  },

  listVariantWrapper: {},
  listVariantHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing(6),
  },
  listVariantTitle: {
    fontWeight: 600,
    fontSize: 16,
    color: palette.grey[700],
  },
  listVariantTitleOnlyAttachments: {
    marginTop: spacing(2),
    marginBottom: spacing(4),
  },
  listVariantTitleMalformed: {
    fontWeight: 600,
    fontSize: 16,
    marginBottom: spacing(6),
    color: palette.error[500],
  },
  listVariantBody: {
    paddingLeft: spacing(2),
  },

  listWithGroupedAttachmentsWrapper: {
    width: '100%',
    border: `1px solid ${palette.grey[300]}`,
    marginBottom: spacing(2.5),
  },
  listWithGroupedAttachmentsTitle: {
    height: 52,
    width: '100%',
    marginBottom: spacing(4),
    paddingLeft: spacing(4),
    background: palette.grey[100],
    borderBottom: `1px solid ${palette.grey[300]}`,
    fontSize: 18,
    color: '#333333',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  listWithGroupedAttachmentsVariantBody: {},
  listWithGroupedAttachmentsVariantForm: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: spacing(4),
  },
  listWithGroupedAttachmentsVariantFormContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 'calc(100% - 105px)',

    '& div': {
      width: 400,
      marginRight: spacing(7),
    },
  },

  hiddenField: {
    width: '0 !important',
    height: 0,
    margin: '0 !important',
    padding: 0,
  },
  attachmentsRoot: {
    marginBottom: spacing(3),
  },
  attachmentsHeader: {
    border: 'none',
    height: 24,
    paddingLeft: spacing(4),
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  attachmentsIcon: {
    maxWidth: 10,
    maxHeight: 10,
    marginRight: spacing(3),
  },
  attachmentsTitle: {
    fontSize: 14,
    color: palette.grey[700],
  },
  attachmentsBody: {
    paddingLeft: spacing(10),
  },
}));
