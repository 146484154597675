import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing, palette }) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: spacing(5, 6, 6, 6),
  },
  wrapperColorInfo: {
    backgroundColor: palette.primary[700],
    color: palette.common.white
  },
  wrapperColorDanger: {
    background: palette.error[400],
    color: palette.common.white,
  },
  wrapperColorNeutral: {
    background: palette.common.white,
    color: '#101F2B'
  },

  headerTitle: {
    fontSize: 18,
  },

  content: {
    padding: spacing(5, 4),
    border: '1px solid #E0E3EA'
  },
  actions: {
    padding: spacing(4, 6)
  }
}));
