export enum DATA_TYPES {
  TEXT,
  NUMBER,
  ATTACHMENTS,
  DATE,
  BOOLEAN,
  AMOUNT_AND_CURRENCY,
  USER,
  USER_LIST,
  GLOSSARY_MULTIPLE_VALUES
}

export const COMPONENTS_DATA_TYPES = {
  default: DATA_TYPES.TEXT,
  textarea: DATA_TYPES.TEXT,
  select: DATA_TYPES.TEXT,
  'radio-button-group': DATA_TYPES.TEXT,
  'readonly-multi-select-glossary': DATA_TYPES.GLOSSARY_MULTIPLE_VALUES,
  number: DATA_TYPES.NUMBER,
  currencyRate: DATA_TYPES.TEXT,
  amountInKzt: DATA_TYPES.NUMBER,
  'amount-and-currency': DATA_TYPES.AMOUNT_AND_CURRENCY,
  glossary: DATA_TYPES.TEXT,
  'readonly-field': DATA_TYPES.TEXT,
  'readonly-date': DATA_TYPES.DATE,
  'readonly-list': DATA_TYPES.TEXT,
  'readonly-accessType': DATA_TYPES.TEXT,
  'readonly-amount-and-currency': DATA_TYPES.AMOUNT_AND_CURRENCY,
  file: DATA_TYPES.ATTACHMENTS,
  datetime: DATA_TYPES.DATE,
  switch: DATA_TYPES.BOOLEAN,
  country: DATA_TYPES.TEXT,
  'masked-field': DATA_TYPES.TEXT,
  phone: DATA_TYPES.TEXT,
  checkbox: DATA_TYPES.BOOLEAN,
  email: DATA_TYPES.TEXT,
  'readonly-number': DATA_TYPES.NUMBER,
  'user-select': DATA_TYPES.USER,
  'readonly-user': DATA_TYPES.USER,
  'employee-select': DATA_TYPES.TEXT,
  selectGlossary: DATA_TYPES.TEXT,
  'link-field': DATA_TYPES.TEXT,
  'user-list': DATA_TYPES.USER_LIST,
  'readonly-user-list': DATA_TYPES.USER_LIST,
};
