import {makeStyles} from "@material-ui/core/styles";

export default makeStyles(({ spacing }) => ({
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 12px',
    alignItems: 'center',
  },
  menuItemText: {
    marginLeft: '10px'
  },
  menuItemTextDisable: {
    color: '#A9A9B8'
  }
}));
