import React, { useEffect, useMemo, useCallback, useRef, useState, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Fade } from '@mui/material';
import cn from 'classnames';

import { CloseButton, ErrorMessage, Spinner } from 'components';
import { JobOffer } from './JobOffer';
import { UrgentRequest } from './UrgentRequest';
import { SummaryStep } from './SummaryStep';
import { Steps } from './Steps';

import useTaskForm from './useTaskForm';
import { setDraftStatusAction, setDraftsListRefreshStatus, useTaskState } from 'store/requests';
import { NotificationManager } from 'react-notifications';

import useStyles from './useStyles';
import useDialogStyles from '../../CreateProcessDialog/useStyles';
import { TemplateCreationDialog } from './TemplateCreationDialog';
import { ActionCommentDialog } from './ActionCommentDialog';
import { useDispatch } from 'react-redux';
import { ConfirmationModal } from 'components/Modal/ConfirmationModal';
import { FormProvider } from 'react-hook-form';
import DoneIcon from '../../../assets/images/icons/done.svg';
import { ActionsBar } from './ActionsBar';
import RenderForm from './RenderForm';
import { UserType } from 'types';
import { setSelectedTask } from 'store/approvals';

type Props = {
  isSaveModalVisible: boolean;
  setIsSaveModalVisible: (v: boolean) => void;
  setPropsForActionsBar: (v: {
    saveDraft: () => Promise<unknown>;
    disabled: boolean;
    buttonDisabled: boolean;
    alwaysActiveButtons: string[];
    onClick: ({ action }: { action: string; }) => Promise<void>;
    formMethods: any;
  }) => void;
  handleClose: () => void;
  handleDeleteRef: MutableRefObject<() => any>
  isNewRequest: boolean;
  currentTaskGroup: any;
  localizedProcessName: string;
  draftSaved: boolean;
  taskLoading: boolean;
  taskError: boolean;
  onDialogClose: () => void;
}

export const TaskForm = ({
  setPropsForActionsBar,
  isSaveModalVisible,
  setIsSaveModalVisible,
  handleClose,
  handleDeleteRef,
  isNewRequest,
  currentTaskGroup,
  localizedProcessName,
  draftSaved,
  taskLoading,
  taskError,
  onDialogClose,
}: Props) => {

  const classes = useStyles();
  const dialogClasses = useDialogStyles();

  const dispatch = useDispatch();
  const [selectedWatchers, setSelectedWatchers] = useState<UserType[]>([]);
  const getValuesRef = React.useRef(null);
  const initialValues = React.useRef(null);

  const {
    activeStep,
    alwaysActiveButtons,
    buttonDisabled,
    commentPopoverInfo,
    error,
    formMethods,
    formSteps,
    handleAction,
    handleDelete,
    isDraftsAutoSaveEnabled,
    isSummaryStep,
    loading = true,
    saveUserInput,
    setActionComment,
    setAlwaysActiveButtons,
    setButtonDisabled,
    templatePopoverInfo,
  } = useTaskForm({selectedWatchers});

  const autoSaveTimeout = useRef(null);

  const { t } = useTranslation();
  const { data: bpmTask, documents, disabledComponentsList } = useTaskState();

  const isJobOffer = useMemo(() => bpmTask.businessEntity.sysName === 'JO-3_ver2', [bpmTask]);

  const saveDraft = useCallback(async () => {
    if (!bpmTask?.isFirstStep || !getValuesRef.current) {
      return;
    }

    await saveUserInput(getValuesRef.current);
  }, [bpmTask?.isFirstStep, saveUserInput]);

  const handleDontSave = async () => {
    if (isNewRequest) {
      setIsSaveModalVisible(false);

      handleDelete();
      handleClose();
      return;
    }

    const values = initialValues.current;
    formMethods.reset(values);
    getValuesRef.current = values;
    handleClose();
    setIsSaveModalVisible(false);
  };

  const savedValues = useRef(null);

  useEffect(() => {
    savedValues.current = formMethods.getValues();
    initialValues.current = formMethods.getValues();
  }, []);

  useEffect(() => {
    const currentValues = formMethods.getValues();
    if (JSON.stringify(savedValues.current).trim() !== JSON.stringify(currentValues).trim()) {
      clearTimeout(autoSaveTimeout.current);

      const id = setTimeout(() => {
        saveUserInput(currentValues);
        savedValues.current = currentValues;
      }, 2000);

      autoSaveTimeout.current = id;
    }
  }, [formMethods.getValues()]);

  useEffect(() => {
    setPropsForActionsBar({
      formMethods,
      saveDraft,
      disabled: buttonDisabled || loading,
      alwaysActiveButtons,
      onClick: handleAction,
      buttonDisabled,
    });
  }, [formMethods, buttonDisabled, loading, setPropsForActionsBar]);

  useEffect(() => {
    if (handleDelete) {
      handleDeleteRef.current = handleDelete;
    }
  }, [handleDelete]);

  useEffect(() => {
    getValuesRef.current = formMethods.getValues();
  });

  useEffect(() => {
    dispatch(setDraftStatusAction(false));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      isDraftsAutoSaveEnabled && saveDraft();
      dispatch(setSelectedTask({
        selectedUsersId: [], selectedUsersObject: []
      }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDraftsAutoSaveEnabled]);

  useEffect(() => {
    const handleBeforeUnload = async (event) => {
      if (!bpmTask.isFirstStep) {
        return;
      }
      event.preventDefault();
      event.returnValue = true;

      await saveDraft();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [bpmTask.isFirstStep, saveDraft]);

  const hasSteps = useMemo(() => formSteps?.length > 1, [formSteps]);

  const formGroupClassNames = useMemo(() => cn(classes.formGroup, {
    [classes.urgentHidden]: !(isSummaryStep || activeStep === 0)
  }), [isSummaryStep, activeStep]);


  const handleWatchersSelect = (_selectedWatchers, selectedUsersObject) => {
    setSelectedWatchers(selectedUsersObject);
  }

  const renderProcessForm = useMemo(() => {
    if (isSummaryStep) return <SummaryStep selectedWatchers={selectedWatchers} values={getValuesRef.current} formMethods={formMethods}/>;
    if (bpmTask.isFirstStep && formSteps) {
      const steps = formSteps.map((step, i: number) => (
        <Box display={isSummaryStep ? 'none' : 'block'}
            component="section"
            className={hasSteps ? cn(classes.pane, {
              [classes.paneActive]: i === activeStep,
              [classes.urgentHidden]: i !== activeStep,
            }) : null}>
          <RenderForm
            groupsList={step.attrGroups}
            handleAction={handleAction}
            handleWatchersSelect={handleWatchersSelect}
            processSysName={bpmTask.processSysName}
            selectedWatchers={selectedWatchers}
            setAlwaysActiveButtons={setAlwaysActiveButtons}
            setButtonDisabled={setButtonDisabled}
            stepSysName={bpmTask.stepSysName} />
        </Box>
      ))
      return steps;
    }
    return <RenderForm
            groupsList={bpmTask.currentStateGroups}
            handleAction={handleAction}
            handleWatchersSelect={handleWatchersSelect}
            processSysName={bpmTask.processSysName}
            selectedWatchers={selectedWatchers}
            setAlwaysActiveButtons={setAlwaysActiveButtons}
            setButtonDisabled={setButtonDisabled}
            stepSysName={bpmTask.stepSysName} />
  }, [
    isSummaryStep,
    getValuesRef,
    hasSteps,
    activeStep,
    formSteps,
    bpmTask,
    selectedWatchers
  ]);

  return (
    <FormProvider {...formMethods}>
      <Box
        component="header"
        className={dialogClasses.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Box display="flex" justifyContent="center" flexDirection="column" gap={1.5}>
          {currentTaskGroup && <Box className={dialogClasses.groupName}>
            <img src={currentTaskGroup.iconPath} alt=""/>
            <span>{t(`groups.${currentTaskGroup.sysName}.name`, { defaultValue: currentTaskGroup?.name })}</span>
          </Box>}
          <Typography variant="h3" className={dialogClasses.title}>
            {isSummaryStep
              ? t('new_request.title_request_start_summary')
              : `${t('new_request.title_request_start_prefix')} ${localizedProcessName || ''} ${t('new_request.title_request_start_suffix')}`}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={3}>
          {bpmTask && (
            <Fade in={draftSaved} timeout={300}>
              <Typography className={dialogClasses.draftStatus} variant="caption">
                <img src={DoneIcon} alt="Done"/>
                <span>{t('customProcesses.creationPage.auto-saved')}</span>
              </Typography>
            </Fade>
          )}

          <ActionsBar
            saveDraft={saveDraft}
            formMethods={formMethods}
            disabled={buttonDisabled || loading || taskLoading}
            buttonDisabled={buttonDisabled}
            alwaysActiveButtons={alwaysActiveButtons}
              onClick={handleAction}
            />

          <CloseButton onClick={onDialogClose}/>
        </Box>
      </Box>
      <Box position="relative" flex={1} overflow="hidden">
        {taskLoading && <Spinner/>}
        {!taskLoading && taskError && <ErrorMessage text={t('errors.somethingIsWrong')}/>}

        <Box
          display="grid"
          gridTemplateColumns={isSummaryStep ? '1fr' : '300px auto'}
            gridTemplateAreas={
              isSummaryStep
              ? '\'content\' \'footer\''
              : '\'sidebar content\' \'footer footer\''
            }
          gridTemplateRows="auto 10px"
            height="100%"
          >
          {loading && <Spinner absolute/>}

          {/* Sidebar */}
          {!isSummaryStep && hasSteps && (
            <Box component="aside" bgcolor="grey.100" pt={8} gridArea="sidebar">
              <Steps/>
            </Box>
          )}
          {/* End Sidebar */}

          {/* Content */}
          <Box
            display="grid"
            pr={8}
            component="article"
              style={{
                overflowY: "scroll"
              }}
            gridArea="content"
              gridTemplateColumns="auto 325px"
            >
              <Box
                pt={8}
                pl={14.5}
                pr={hasSteps ? 10 : 6}
                maxWidth={isSummaryStep ? 'none' : 800}
              >
              <Box>
                {(!isSummaryStep && disabledComponentsList?.length > 0) && (
                  <Box className={classes.disabledFieldsInfoCard}>
                    {t('task_data_view.disabled_documents_fields_message')}
                  </Box>
                )}

                <Box component="form" className={classes.form}>
                  <Box component="section" className={formGroupClassNames}>
                    <UrgentRequest readOnly={isSummaryStep} />
                  </Box>
                  <Box component="article" position="relative">
                    {renderProcessForm}
                  </Box>
                </Box>
              </Box>

                {isJobOffer && bpmTask.currentAction.sysName === 'jo-jo_ver2' && (
                  <JobOffer loading={loading}/>
                )}

              {error && (
                <Typography gutterBottom color="error">
                  {t(error)}
                </Typography>
              )}
            </Box>
          </Box>
          {/* End Content */}

        </Box>


        <TemplateCreationDialog
          open={templatePopoverInfo.open}
          isSubmitButtonDisabled={buttonDisabled}
          onClick={handleAction}
        />

        <ActionCommentDialog
          open={commentPopoverInfo.open}
          isCommentRequired={commentPopoverInfo.isCommentRequired}
          onClick={handleAction}
          actionOptions={commentPopoverInfo}
          onCommentChange={(comment) => setActionComment(comment)}
          isTemplate={bpmTask?.isTemplateProcessTask}
        />

        {isSaveModalVisible && <Box className={classes.modal}>
          <ConfirmationModal
            title={t('actionConfirmationModals.draft.saveAsDraftModalTitle')}
            description={t('actionConfirmationModals.draft.saveAsDraftModalDescription')}
            close={() => setIsSaveModalVisible(false)}
              action={async () => {
                return handleClose();
              }}
            onSuccessAction={() => {
              dispatch(setDraftsListRefreshStatus(true));
              NotificationManager.success(t('notifications.yourDraftSavedSuccessfully'));
            }}
            cancelButtonText={t('buttons.backToEdition')}
            okButtonText={t('customProcesses.creationPage.buttons.save')}
            additionalButtons={[
              {
                text: t('buttons.dontSave'),
                action: handleDontSave,
              },
            ]}
          />
        </Box>}

        {/* End Grid */}
      </Box>
    </FormProvider>
  );
};
