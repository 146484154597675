import React, { Dispatch, useCallback, useMemo } from 'react';

import { Box, Button } from '@material-ui/core';
import classNames from 'classnames';

import { UserInfo } from 'components';
import { UserType } from 'types';

import PersonIcon from 'assets/images/icons/new-user-icon.svg';
import ChevronDown from 'assets/images/icons/chevron_down_regular.svg';

import useUserDropdownStyles from './UserList.useStyles';

interface AssigneeFieldProps {
  assigneeTooltip: JSX.Element;
  currentAssigneeSelectIndex: number;
  disabled: boolean;
  errors: {[index: string]: any};
  handleMenuOpen: () => void;
  index: number;
  isDropdownOpen: boolean;
  label: string;
  name: string;
  placeholder: string;
  setCurrentAssigneeSelectIndex: Dispatch<number>;
  userListDropdownAnchor: { current: HTMLElement | null };
  users: {[index: string]: UserType};
  selectedUsersId: string[];
}

const AssigneeField = (props: AssigneeFieldProps) => {
  const { 
    assigneeTooltip,
    currentAssigneeSelectIndex,
    disabled, 
    errors, 
    handleMenuOpen, 
    index,
    isDropdownOpen,
    label, 
    name, 
    placeholder,
    setCurrentAssigneeSelectIndex, 
    userListDropdownAnchor,
    users,
    selectedUsersId
  } = props;
  const dropdownStyles = useUserDropdownStyles({});
  const appleOSSystem = useMemo(() => {
    const is_OSX = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);
    const is_iOS = /(iPhone|iPod|iPad)/i.test(navigator.userAgent);
    return is_iOS || is_OSX;
  }, []);


  const assigneeFieldClassNames = useMemo(() => classNames('userField', dropdownStyles.userDropdown, {
    [dropdownStyles.userDropdownDisabled]: disabled,
    [dropdownStyles.userDropdownError]: errors[name],
  }), [disabled, errors[name]]);

  const placeholderClassNames = useMemo(() => classNames({
    [dropdownStyles.placeholder]: true,
    [dropdownStyles.placeholderErrorColor]: errors[name] && !appleOSSystem
  }), [appleOSSystem, errors, name]);

  const handleClick = useCallback((event) => {
    handleMenuOpen();
    setCurrentAssigneeSelectIndex(index);
    const buttons = document.getElementsByClassName('userField');
    for (let i = 0; i < buttons.length; i++) {
      if (buttons.item(i).contains(event.target as Node)) {
        userListDropdownAnchor.current = buttons.item(i) as HTMLElement;
      }
    }
  }, [index, userListDropdownAnchor])

  const ChevronDownIcon = () => {
    const isCorrectAssignee = isDropdownOpen && currentAssigneeSelectIndex === index;
    const chevronDownIconClassNames = useMemo(() => classNames(dropdownStyles.userDropdownIcon, 
      {
        [dropdownStyles.userDropdownActive]: isCorrectAssignee
      }), [isDropdownOpen, currentAssigneeSelectIndex]);
    if (!disabled) {
      return <img src={ChevronDown} className={chevronDownIconClassNames} alt=""/>;
    }
    return null;
  }

  const ErrorMessage = () => {
    if (errors[name]) {
      return <p className={classNames(dropdownStyles.errorText, 'Mui-error')}>{errors[name]?.message}</p>;
    }
    return null;
  }

  const FieldState = () => {
    const selectedUsersCount = selectedUsersId?.length;
    const ShowAssigneeTooltip = () => {
      if (selectedUsersCount > 0) {
        return (
          <Box ml={2}>
            {assigneeTooltip}
          </Box>
        )
      }
      return null;
    }
    if (selectedUsersCount && !!selectedUsersId[0]) {
      return (
        <Box ml={2}>
          <Box display="flex" alignItems="center">
            <UserInfo user={users[selectedUsersId[0]]} avatarSize={20}/>
            <ShowAssigneeTooltip />
          </Box>
        </Box>
      )
    }

    return (
      <Box ml={2} maxWidth={'100%'}>
        <span className={placeholderClassNames}>{placeholder}</span>
      </Box>
    )
  }

  return (
    <Box className={dropdownStyles.userDropdownWrapper}>
      <Box className={dropdownStyles.userDropdownFieldWrapper}>
        {label && <Box className={dropdownStyles.labelWrapper}><label className={dropdownStyles.label}>{label}</label></Box>}
        <Button variant="contained" color="secondary" className={assigneeFieldClassNames} onClick={handleClick}>
          <Box display="flex" alignItems="center" maxWidth={'92%'}>
            <img src={PersonIcon} className={dropdownStyles.userDropdownIcon}/>
            <FieldState />
          </Box>
          <ChevronDownIcon />
        </Button>
        <ErrorMessage />
      </Box>
    </Box>
  )
}

export default AssigneeField;