import { UserSelectDepartmentData } from 'pages/TemplateProcesses/TemplateProcesses.types';
import { Dispatch } from 'react';
import { TaskParametersType, UserType } from 'types';

export enum TreeType {
  DIRECTORY,
  DIRECTORY_ITEM,
}

export enum FetchingState {
  SUCCESS,
  ERROR,
  LOADING,
  IDLE,
}

export interface OnFormFieldHandleActionProps {
  action: any
  tplName: string;
  componentParams: any;
  instanceFormFieldName?: string;
  instanceFieldsPrefix?: string;
  focusedLinkedInstanceId?: string;
  instance?: any | null;
  validationAttributeList?: any[];
}

export interface DocumentFieldProps {
  id: string;
  name: string;
  type: string;
  hint: string;
  language: {
    id: string;
    title: string;
    code: string;
  }
  state_order?: number;
}

export type FormGroupType = {
  name: string,
  fields: DocumentFieldProps[]
}

export interface FormGroupTemplateProps {
  formGroup: FormGroupType;
  processSysName: string;
  stepSysName: string;
  setButtonDisabled: Dispatch<boolean>;
  setAlwaysActiveButtons: Dispatch<any[]>;
  handleAction: (props: OnFormFieldHandleActionProps) => void;
}

export interface RenderFieldsProps {
  sortedFields: any[];
  setButtonDisabled: Dispatch<boolean>;
  setAlwaysActiveButtons: Dispatch<any[]>;
  handleAction: (props: OnFormFieldHandleActionProps) => void;
}

export interface ActionConfirmationDialogProps {
  isOpen: boolean;
  dialogType: string;
  defaultTitle: string;
  defaultMessage: string;
  defaultConfirmButtonLabel: string;
  onClose: () => void;
  onConfirm: () => void;
}

export interface FormFieldProps {
  attribute: TaskParametersType;
  isTaskDetailsVariant?: boolean;
  isEntityCardVariant?: boolean;
  tabName?: string;
  setActiveTab?: (tabName: string) => void;
  handleAction?: any;
  updateAttachmentsCounter?: any;
  instance?: any;
  instancesList?: any;
  entitySysName?: string;
  stepSysName?: string;
  setInstancesList?: (value: any[]) => void;
  setButtonDisabled?: (value: boolean) => void;
  setAlwaysActiveButtons?: (value: string[]) => void;
  setMainFormValue?: (field: string, value: any) => void;
}

export interface ProjectChildSelectProps {
  directoryName?: string;
  value?: string;
  type: TreeType;
  valueParentId?: string;
  valuesCount?: number;
  disabledItemsIds?: string[];
  id?: string;
  item?: any;
  localization?: { [key: string]: string };
  label?: string;
  onSelect: (value: any) => void;
  isBuilderVariant?: boolean;
  forceEnglish?: boolean;
}

export interface ProjectChildSelectProps {
  directoryName?: string;
  value?: string;
  type: TreeType;
  valueParentId?: string;
  valuesCount?: number;
  disabledItemsIds?: string[];
  id?: string;
  item?: any;
  localization?: { [key: string]: string };
  label?: string;
  isBuilderVariant?: boolean;
  forceEnglish?: boolean;
  children?: any[];
  selectedIds: string[];
  handleItemSelect: (id, state) => void;
  treeStructureNodes: { [key: string]: any };
  onSelect: (value: any) => void;
}

export interface ModalTreeNodeStyles {
  directoryName?: string;
  value?: string;
  id?: string;
  item?: any;
  localization?: { [key: string]: string };
  label?: string;
  children?: any[];
  selectedIds: string[];
  treeStructureNodes: {[key: string]: any};
}

export interface ReadOnlyMultiSelectGlossaryDialogProps {
  title: string;
  glossaryNodes: {[key: string]: any},
  treeStructure: {[key: string]: any}[],
  values: string[];
  isOpen: boolean;
  onClose: () => void;
}

export interface UserDropdownCardProps {
  user: UserType;
  showDepartmentName?: boolean;
  departments?: UserSelectDepartmentData[];
}

export interface UserDropdownProps {
  allowOnlyOneDepartment?: boolean;
  clearSelectedOnComponentUnmount?: boolean;
  disabled?: boolean;
  handleUsersSelect?: (usersId: string[], usersObject: UserType[]) => void;
  isPreview?: boolean;
  label?: string;
  rules?: {
    [key: string]: string;
  },
  maxUsersCount?: number;
  name?: string;
  noEmptyValueText?: boolean;
  placeholder?: string;
  showAllDepartmentsGroup?: boolean;
  showAllUsersGroup?: boolean;
  value?: string;
  allowOnlyOneUser?: boolean;
  showWithoutDepartmentView?: boolean;
  selectedTask?: {
    selectedUsersId: string[];
    selectedUsersObject: UserType[];
  },
  withoutInitiator?: boolean;
}

export interface RenderFormProps {
  groupsList: any;
  processSysName: string;
  stepSysName: string;
  setButtonDisabled: Dispatch<boolean>;
  setAlwaysActiveButtons: Dispatch<any[]>;
  handleAction: (props: OnFormFieldHandleActionProps) => void;
  handleWatchersSelect: (selectedWatchersIdList: string[], selectedWatcherObjectList: UserType[]) => void;
  selectedWatchers: UserType[];
}
