import React, { memo, ReactElement, useMemo } from 'react';
import { FormControl } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AppTextField } from "components";

type EmailProps = {
  name?: string;
  hint?: string;
  isActive: boolean;
}

type Validate = {
  required: string;
  maxLength: { value: number; message: string; };
  validate: (val: string) => string | boolean;
}

const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const Email = memo(({ name, hint, isActive }: EmailProps): ReactElement => {
  const { t } = useTranslation();
  const { register, formState: { errors } } = useFormContext();

  const validate = (value: string): boolean | string => emailRegEx.test(value) || t('form_components.validation_errors.email') as string;

  return (
    <FormControl fullWidth>
      <AppTextField
        disabled={!isActive}
        label={hint}
        {...register(name, {
          required: t('form_components.required_errors.default') as string,
          maxLength: { value: 200, message: t('form_components.validation_errors.email') as string },
          validate
        } as Validate)}
        inputProps={{'data-selenium': name}}
        error={!!errors[name]}
        helperText={!!errors[name] && errors[name].message}
      />
    </FormControl>
  );
});
