import jsPDF from 'jspdf';
import moment from 'moment';

import { getUserDataByIdOrLogName } from 'utils/user';
import { getHCMSUserContactsById, getPositionInfo } from 'api/requests';

import './fonts/Rubik-Regular-normal';
import './fonts/Rubik-Medium-bold';

import Logo from 'assets/images/new-logo.jpg';
import Stamp from 'assets/images/stamp.jpg';

const generatePDF = (fields) => {
  const doc = new jsPDF('p', 'pt', [595.28, 841.89]);

  doc.setFont('Rubik-Medium', 'bold');
  doc.setTextColor(138, 150, 161);

  let nextElemHeight = 16;

  doc.addImage(
    Logo,
    'JPEG',
    doc.internal.pageSize.getWidth() / 2 - 42,
    nextElemHeight,
    100,
    48
  );

  nextElemHeight += 72;

  doc.setFontSize(16);
  doc.text(
    fields.createdDate,
    doc.internal.pageSize.getWidth() / 2,
    nextElemHeight,
    {
      align: 'center',
      lineHeightFactor: 1.47
    }
  );

  nextElemHeight += 16;

  doc.setLineWidth(1.5);
  doc.line(
    20,
    nextElemHeight,
    doc.internal.pageSize.getWidth() - 20,
    nextElemHeight
  );

  nextElemHeight += 24;

  doc.setFontSize(12);
  doc.setFont('Rubik-Regular', 'normal');
  doc.text(`Dear ${fields.name},`, 20, nextElemHeight);

  nextElemHeight += 24;

  doc.text(
    doc.splitTextToSize(
      'We are pleased to offer you employment at DAR company. ' +
        'We feel confident that you will contribute your skills and experience towards the growth of our organization.',
      570
    ),
    20,
    nextElemHeight
  );

  nextElemHeight += 36;

  doc.text(
    doc.splitTextToSize(
      `Per our discussion, the full-time position is ${fields.position} in ${fields.company}. ` +
        `The ${fields.paymentName} offered for this position is ${fields.salary} ${fields.currency} NET monthly. ` +
        `Your supervisor is ${fields.reportsTo}, ${fields.reportsToRole}, ${fields.company}.` +
        `Your starting date on this position is ${fields.startDate}. ` +
        `The running time for probation time is ${fields.probationDurationSentence}, until ${fields.probation}. ` +
        `You are entitled to have an annual paid leave for 28 days and day offs on behalf of supervisor's approval.`,
      570
    ),
    20,
    nextElemHeight
  );

  nextElemHeight += 80;

  if (fields.benefits?.length > 0) {
    doc.text('Some of our benefits include:', 20, nextElemHeight);

    fields.benefits.forEach((benefit) => {
      nextElemHeight += 18;
      doc.text(`- ${benefit}`, 45, nextElemHeight);
    });

    nextElemHeight += 32;
  }

  doc.text(
    doc.splitTextToSize(
      'Other important information like job description, probation ' +
        'period deliverables, company procedures and policies will be explained' +
        ' during the first week as you onboard to a company.',
      570
    ),
    20,
    nextElemHeight
  );

  nextElemHeight += 32;

  doc.text(
    doc.splitTextToSize(
      `In the meantime, please feel free to contact ${fields.contactName}, ` +
        `${fields.contactPosition} via email or phone on ${fields.contactEmail}, ` +
        `${fields.contactPhone}.`,
      570
    ),
    20,
    nextElemHeight
  );

  nextElemHeight += 36;

  doc.line(
    20,
    nextElemHeight,
    doc.internal.pageSize.getWidth() - 20,
    nextElemHeight
  );

  nextElemHeight += 24;

  doc.text(
    doc.splitTextToSize(
      'If you choose to accept this offer, please sign second copy of this ' +
        'letter in the space provided and return it to us as soon as possible. ' +
        `This offer will be valid until ${fields.expireDate}.`,
      570
    ),
    20,
    nextElemHeight
  );

  nextElemHeight += 48;

  if (nextElemHeight > 800) {
    doc.addPage();
    nextElemHeight = 80;
  }

  doc.text(
    doc.splitTextToSize(
      'We look forward to welcoming you as a new employee at DAR',
      570
    ),
    20,
    nextElemHeight
  );

  nextElemHeight += 24;

  doc.text(doc.splitTextToSize('Sincerely,', 550), 20, nextElemHeight);

  nextElemHeight += 14;

  doc.text(doc.splitTextToSize('DAR Company', 550), 20, nextElemHeight);

  nextElemHeight += 36;

  doc.setDrawColor(204, 204, 204);
  doc.setLineWidth(0.5);
  doc.line(20, nextElemHeight, 180, nextElemHeight);

  doc.setLineWidth(0.5);
  doc.line(
    doc.internal.pageSize.getWidth() - 100,
    nextElemHeight,
    doc.internal.pageSize.getWidth() - 20,
    nextElemHeight
  );

  nextElemHeight += 18;

  doc.text(fields.name, 20, nextElemHeight);
  doc.text('Date', doc.internal.pageSize.getWidth() - 100, nextElemHeight);

  nextElemHeight += 18;

  doc.addImage(
    Stamp,
    'JPEG',
    doc.internal.pageSize.getWidth() - 110,
    nextElemHeight,
    80,
    80
  );

  return doc;
};

export const savePDFAsBase64 = (fields) => {
  return new Promise((resolve) => {
    const pdf = generatePDF(fields);

    setTimeout(() => {
      resolve(getBase64FromPDF(pdf.output('datauri')));
    }, 0);
  });
};

export const savePDFAsFile = (fields) => {
  return new Promise((resolve) => {
    const pdf = generatePDF(fields);
    pdf.save(`${fields.name.split(' ').join('-')}-offer.pdf`);

    setTimeout(() => {
      resolve('resolved');
    }, 0);
  });
};

const getBase64FromPDF = (pdf) => pdf.split(',')[1];

export const generateOfferFields = async (
  attributes,
  createdDate,
  users,
  isServiceContract = false,
  companyId
) => {
  const getBenefits = () => {
    const jobOfferV2Benefits = attributes.find(
      ({ name }) => name === 'benefits'
    )?.value;
    const jobOfferV1Benefits = attributes
      .filter(({ name, value }) => name.includes('print') && value)
      .map(({ hint }) => hint);

    return jobOfferV2Benefits ? jobOfferV2Benefits : jobOfferV1Benefits;
  };

  const fields: { [key: string]: string | boolean } = {
    benefits: getBenefits(),
    createdDate: moment(createdDate).format('DD.MM.YYYY'),
    expireDate: moment(createdDate).add(5, 'days').format('DD.MM.YYYY'),
    paymentName: isServiceContract ? 'compensation' : 'salary'
  };

  attributes.forEach((attr) => {
    switch (attr.name) {
      case 'fullName':
        fields.name = attr.value;
        return;
      case 'position':
        fields.position = attr.value;
        return;
      case 'wagesProbationaryPeriod':
        fields.salary = attr.value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return;
      case 'post-trialWages':
        fields.postSalary = attr.value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return;
      case 'startDate':
        fields.startDate = moment(attr.value).format('DD.MM.YYYY');
        return;
      case 'name':
        fields.company = attr.value;
        return;
      case 'reportsTo':
        fields.reportsTo = attr.value;
        return;
      case 'contactPerson':
        fields.contactPerson = attr.value;
        return;
      case 'currency':
        fields.currency = attr.value;
        return;
      case 'probation':
        fields.probation = moment(attr.value).format('DD.MM.YYYY');
        return;
      default:
        break;
    }
  });

  const reportUserId =
    Object.keys(users).find((id) => users[id].logName === fields.reportsTo) ||
    (fields.reportsTo as string);
  const contactUserId =
    Object.keys(users).find(
      (id) => users[id].logName === fields.contactPerson
    ) || (fields.contactPerson as string);

  if (reportUserId) {
    let reportUser = users[reportUserId];
    if (!reportUser) {
      try {
        reportUser = await getUserDataByIdOrLogName(reportUserId, companyId);
      } catch {
        reportUser = null;
      }
    }

    if (reportUser) {
      fields.reportsTo = reportUser.fullName;
      try {
        const reportsToPosition = await getPositionInfo(reportUser.jobTitle);
        fields.reportsToRole = reportsToPosition?.name;
      } catch (error) {
        fields.reportsToRole = reportUser.jobTitle;
      }
    }
  }

  if (contactUserId) {
    let contactUser = users[contactUserId];
    if (!contactUser) {
      try {
        contactUser = await getUserDataByIdOrLogName(contactUserId, companyId);
      } catch (e) {
        contactUser = null;
      }
    }

    if (contactUser) {
      try {
        const { phone } = await getHCMSUserContactsById(contactUserId);
        fields.contactPhone = phone;
      } catch (e) {
        console.log(e);
      }

      fields.contactName = contactUser.fullName;
      fields.contactEmail = contactUser.email;
      try {
        const contractPosition = await getPositionInfo(contactUser.jobTitle);
        fields.contactPosition = contractPosition?.name;
      } catch (error) {
        fields.contactPosition = contactUser.jobTitle;
      }
    }
  }

  if (fields.probation && fields.startDate) {
    const startDate = moment(fields.startDate as string, 'DD.MM.YYYY').startOf(
      'day'
    );
    const endDate = moment(fields.probation as string, 'DD.MM.YYYY').startOf(
      'day'
    );

    const differenceInMonths = endDate.diff(startDate, 'months') as number;
    startDate.add(differenceInMonths, 'months');

    const differenceInDays = endDate.diff(startDate, 'days') as number;

    fields.probationDurationSentence = [
      [differenceInMonths, 'month'],
      [differenceInDays, 'day']
    ]
      .reduce((accumulator: string, currentValue: [number, string]) => {
        const [difference, unit] = currentValue;

        if (difference) {
          const fullUnit = `${unit}${difference > 1 ? 's' : ''}`;
          const sentence = `${difference} ${fullUnit}`;

          return `${accumulator} ${sentence}`;
        }

        return accumulator.toString();
      }, '')
      .trim();
  }

  return fields;
};
