import React from 'react';
import { Box, Typography } from '@material-ui/core';

import useStyles from './useStyles';

import Logo from 'assets/images/new-logo.jpg';
import Stamp from 'assets/images/stamp.jpg';

export const OfferDocument = ({ fields }) => {
  const classes = useStyles();

  if (!fields) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.offerDocument}>
        <img src={Logo} className={classes.logo} alt="Dar logo" />

        <Typography className={classes.createDate} variant="h4">
          {fields.createdDate}
        </Typography>

        <Box className={classes.content}>
          <Typography className={classes.paragraph} variant="body1">
            Dear {fields.name},
          </Typography>

          <Typography className={classes.paragraph} variant="body1">
            We are pleased to offer you employment at DAR company.
            We feel confident that you will contribute your skills and experience towards the growth of our organization.
          </Typography>

          <Typography className={classes.paragraph} variant="body1">
            Per our discussion, the full-time position is {fields.position} in {fields.company}.
            The {fields.paymentName} offered for this position is {fields.salary} {fields.currency} NET monthly.
            Your supervisor is {fields.reportsTo}, {fields.reportsToRole}, {fields.company}.
            Your starting date on this position is {fields.startDate}.
            The running time for probation time is {fields.probationDurationSentence}, until {fields.probation}.
            You are entitled to have an annual paid leave for 28 days and day offs on behalf of supervisor's approval.
          </Typography>

          {fields.benefits?.length > 0 && (
            <Typography className={classes.paragraph} variant="body1">
              Some of our benefits include:
              <ul className={classes.benefitList}>
                {fields.benefits.map((benefit) => (
                  <li key={benefit}>
                    - {benefit}
                  </li>
                ))}
              </ul>
            </Typography>
          )}

          <Typography className={classes.paragraph} variant="body1">
            Other important information like job description, probation period deliverables, company procedures and policies will be explained during the first week as you onboard to a company.
          </Typography>

          <Typography className={classes.paragraph} variant="body1">
            In the meantime, please feel free to contact {fields.contactName}, {fields.contactPosition} via email or phone on {fields.contactEmail}, {fields.contactPhone}.
          </Typography>

          <Typography className={classes.afterSectionParagraph} variant="body1">
            If you choose to accept this offer, please sign second copy of this letter in the space provided and return it to us as soon as possible.
            This offer will be valid until {fields.expireDate}.
          </Typography>

          <Typography className={classes.paragraph} variant="body1">
            We look forward to welcoming you as a new employee at DAR
          </Typography>

          <Typography className={classes.paragraph} variant="body1">
            Sincerely, <br />DAR Company
          </Typography>

          <Box display="flex" alignItems="center" justifyContent="space-between" mt={4}>
            <Typography className={classes.sign} variant="body1">{fields.name}</Typography>

            <Typography className={classes.date} variant="body1">Date</Typography>
          </Box>

          <img src={Stamp} className={classes.stamp} alt="Dar stamp" />
        </Box>
      </Box>
    </Box>
  );
}
