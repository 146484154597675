import React, { ReactElement } from 'react';

import { Box, Button, IconButton, Modal, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from 'assets/images/icons/close-icon-thin-light-grey.svg'
import { ActionConfirmationDialogProps } from '../types';

import useActionConfirmationDialogStyles from './ActionConfirmationDialog.useStyles';

export const ActionConfirmationDialog = ({
  isOpen,
  dialogType,
  defaultTitle,
  defaultMessage,
  defaultConfirmButtonLabel,
  onClose,
  onConfirm,
}: ActionConfirmationDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useActionConfirmationDialogStyles();

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal open={isOpen}>
      <Box className={classes.modalBody}>
        <div className={classes.modalHeader}>
          <Typography className={classes.title}>
            {t(`actionConfirmationModals.${dialogType}.title`, {defaultValue: defaultTitle})}
          </Typography>

          <IconButton onClick={onClose}>
            <img src={CloseIcon} alt={"Close action confirmation modal"} className={classes.closeIcon}/>
          </IconButton>
        </div>

        <div className={classes.modalContent}>
          <Typography className={classes.description}>
            {t(`actionConfirmationModals.${dialogType}.message`, {defaultValue: defaultMessage})}
          </Typography>
        </div>

        <div className={classes.modalFooter}>
          <Button variant="contained" color="secondary" className={classes.modalButton} onClick={onClose}>
            {t('template_delete_modal.button_cancel')}
          </Button>
          <Button variant="contained" color="primary" className={classes.modalButton} onClick={handleConfirm}>
            {t(`actionConfirmationModals.${dialogType}.confirmButtonLabel`, {defaultValue: defaultConfirmButtonLabel})}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
