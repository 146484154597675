import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  iframe: {
    height: '400px',
    width: '100%'
  },
  hintMessage: {
    width: '100% !important'
  }
}));
