import { makeStyles } from '@material-ui/core';

const maxWidth = '450px';

export default makeStyles(() => ({
  generalButtons: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: 0,
    textAlign: 'center',
    // border: '1px solid #2628421F',
    // color: '#262842',
    // borderRadius: '10px',
    padding: '11px 16px',
    minHeight: '40px',
    minWidth: '80px',
  },
  cancelActionButton: {
    color: '#262842',
    border: '1px solid #2628421F'
  },
  confirmActionButton: {},
  body: {
    marginTop: '20px',
    marginBottom: '32px',
    maxWidth: maxWidth
  },
  header: {},
  paper: {
    overflowY: 'hidden',
    backgroundColor: 'red',
    borderRadius: 12,
  },
  headerWrapper: {
    width: maxWidth,
    display: 'flex',
    flexDirection: 'row',
    justifyContent:'space-between',
    alignItems: 'center',
  },
  closeIcon: {
    width: '15px',
    height: '15px',
    cursor: 'pointer',
  },
  actionsSection: {
    backgroundColor: '#F7F8FC',
    borderTop: '1px solid #26284214'
  }
}));
