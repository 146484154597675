import React, { ReactElement, useMemo, useState } from 'react';

import { Box, Button, IconButton, Modal, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeView from '@material-ui/lab/TreeView';

import { ModalTreeNode } from './ModalTreeNode';
import { ReadOnlyMultiSelectGlossaryDialogProps } from 'pages/Task/TaskForm/types';
import { useStyles } from './ReadOnlyMultiSelectGlossary.styles';
import CloseIcon from 'assets/images/icons/close-icon-thin-light-grey.svg';

export const ReadOnlyMultiSelectGlossaryDialog = ({
  glossaryNodes,
  isOpen,
  onClose,
  title,
  treeStructure = [],
  values = [],
}: ReadOnlyMultiSelectGlossaryDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);

  const handleChange = (event, nodes) => {
    setExpanded(nodes);
  };

  const treeStructureFiltered = useMemo(() =>
    treeStructure.filter(node => node.data.status === 'PUBLISHED'
        && (values.includes(node.data.id) || glossaryNodes[node.id]?.selectedChildCount > 0))
  , [values, treeStructure, glossaryNodes]);

  return (
    <Modal open={isOpen}>
      <Box className={classes.modalBody}>
        <div className={classes.modalHeader}>
          <Typography className={classes.title}>
            {title}
          </Typography>

          <IconButton onClick={onClose}>
            <img src={CloseIcon} alt={"Close readonly glossary modal"} className={classes.closeIcon}/>
          </IconButton>
        </div>

        <div className={classes.modalContent}>
          <TreeView
            className={classes.treeRoot}
            defaultCollapseIcon={<ExpandMoreIcon/>}
            defaultExpandIcon={<ChevronRightIcon/>}
            // expanded={expanded}
            onNodeToggle={handleChange}
          >
            {
              treeStructureFiltered
                .map(
                  node => <ModalTreeNode
                    key={node.id}
                    item={node.data}
                    children={node.children}
                    {...node.data}
                    selectedIds={values}
                    treeStructureNodes={glossaryNodes}
                  />,
                )
            }
          </TreeView>
        </div>

        <div className={classes.modalFooter}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.modalButton}
            onClick={onClose}
          >
            {t('buttons.close')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
