import axios from 'axios';
import isArray from 'lodash/isArray';
import moment from 'moment/moment';
import { CURRENCY_SYMBOLS } from '../FormFields/Fields/ReadOnlyAmountAndCurrency/constants';
import { COMPONENTS_DATA_TYPES, DATA_TYPES } from '../SummaryStep/constants';
import { UserType } from 'types';
import { CombinedStepType, StepType } from './../../../../components/TaskDetails/components/Tabs/HistoryTab/history.types';
import { getTranslationKeyForTaskHistoryInPast } from '../../../../utils/translations';
import { getUserDataByIdOrLogName } from '../../../../utils/user';
import { Typography } from '@material-ui/core';
import React from 'react';

export const getInitials = (value: string) =>
  value
    .split(' ')
    .filter(v => !!v)
    .map(v => v[0].toUpperCase())
    .slice(0, 2)
    .join('');

export const getUserById = async (users, userKey: string, companyId: string) => {
  const user = users[userKey];
  if (user) {
    return user;
  }

  const userByLogName = (Object.values(users) as UserType[]).find(u => u?.logName === userKey);
  if (userByLogName) {
    return userByLogName;
  }

  if (userKey) {
    const loadedUser = await getUserDataByIdOrLogName(userKey, companyId);
    if (loadedUser) {
      return loadedUser;
    }
  }

  return null;
};

export const getImageFromUrlAsBase64 = async (url) => {
  // param added to prevent caching and CORS errors related to getting image from cache
  const image = await axios.get(url + '?' + (new Date()).getTime(), { responseType: 'arraybuffer' });
  const raw = Buffer.from(image.data).toString('base64');
  return 'data:' + image.headers['content-type'] + ';base64,' + raw;
};

export const getImageDimensionsFromBase64 = (imageData): Promise<{ w: number, h: number }> => {
  return new Promise(function(resolved, rejected) {
    const i = new Image();
    i.onload = function() {
      resolved({ w: i.width, h: i.height });
    };
    i.src = imageData;
  });
};

export const formatFieldValue = (value, component, params, values, bpmTask, t, i18n, glossaryMap) => {
  const dataType =
    COMPONENTS_DATA_TYPES[component] || COMPONENTS_DATA_TYPES['default'];

  switch (dataType) {
    case DATA_TYPES.TEXT:
      if (['radio-button-group', 'select'].includes(component)) {
        const valuesLocalizationKey = `constructor-${bpmTask.processSysName}.states.${bpmTask.stepSysName}.attributes.${component.sysName.replaceAll('::', '-')}.params.values`;
        const localizedValues = t(valuesLocalizationKey, { defaultValue: params.values });

        if (!localizedValues) {
          return value;
        }

        const valuesArray = params.values.split(',');
        const localizedValuesArray = localizedValues.split(',');
        const localizedValue = valuesArray.indexOf(value) >= 0
                               ? valuesArray.indexOf(value) < localizedValuesArray.length
                                 ? localizedValuesArray[valuesArray.indexOf(value)]
                                 : value
                               : value;
        return localizedValue;
      }

      if (['selectGlossary', 'readonly-field'].includes(component) && (params.saveAsId || params.isGlossaryValueSavedAsId) && params.glossaryIndexName) {
        const glossaryValues = glossaryMap[params.glossaryIndexName];
        const selectedItem = glossaryValues?.find(item => item.id === value);
        if (selectedItem) {
          return selectedItem.additionalFields?.[i18n.language] || selectedItem.value;
        }
      }

      if (['glossary', 'selectGlossary'].includes(component)) {
        const translationsEn = i18n.getResourceBundle('en', '');
        const translationsRu = i18n.getResourceBundle('ru', '');
        if (!(translationsRu && translationsEn)
          || !translationsEn.glossary || !translationsEn.glossary_1c
          || !translationsRu.glossary || !translationsRu.glossary_1c) {
          return value;
        }

        const glossariesKeys = Object.keys(translationsEn.glossary)
          .reduce((acc, key) => {
            return {
              ...acc,
              [translationsEn.glossary[key]]: key,
              [translationsRu.glossary[key]]: key,
            };
          }, {});

        const glossaries1cKeys = Object.keys(translationsEn.glossary_1c)
          .reduce((acc, key) => {
            return {
              ...acc,
              [translationsEn.glossary_1c[key]]: key,
              [translationsRu.glossary_1c[key]]: key,
            };
          }, {});

        if (params['useMultiSelect']) {
          // eslint-disable-next-line no-extra-boolean-cast
          return !!value
                 ? (isArray(value) ? value : (value as string).split('\n'))
                   .reduce((item, acc) => {
                     if (Object.keys(glossaries1cKeys).includes(item as string)) {
                       const glossaryValueKey = glossaries1cKeys[item as string];
                       return acc + t(`glossary_1c.${glossaryValueKey}`, { defaultValue: item }) + '\n';
                     } else {
                       const glossaryValueKey = glossariesKeys[item as string];
                       return acc + t(`glossary.${glossaryValueKey}`, { defaultValue: item }) + '\n';
                     }
                   }, '')
                 : ('-');
        }

        let translatedValue = '';
        if (Object.keys(glossaries1cKeys).includes(value as string)) {
          const glossaryValueKey = glossaries1cKeys[value as string];
          translatedValue = t(`glossary_1c.${glossaryValueKey}`, { defaultValue: value });
        } else {
          const glossaryValueKey = glossariesKeys[value as string];
          translatedValue = t(`glossary.${glossaryValueKey}`, { defaultValue: value });
        }

        return translatedValue;
      }

      if (typeof value === 'boolean') {
        return value
               ? t('form_components.readonly.boolean.true', { defaultValue: 'Yes' })
               : t('form_components.readonly.boolean.false', { defaultValue: 'No' });
      }
      return value;
    case DATA_TYPES.NUMBER: {
      if (value === '') {
        return value;
      }

      let formattedNumber = value?.toLocaleString('ru-RU', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).replace(',', '.');

      if (Number(value) === value && value % 1 !== 0) {
        formattedNumber = parseFloat(String(formattedNumber));
      } else {
        formattedNumber = formattedNumber.split('.')[0];
      }

      return formattedNumber || value;
    }
    case DATA_TYPES.DATE:
      return moment(value as string).format('DD.MM.YYYY');
    case DATA_TYPES.USER: {
      return '';
    }
    case DATA_TYPES.BOOLEAN:
      return value ? t('new_request.summary.boolean.true', { defaultValue: 'Yes' }) : '-';
    case DATA_TYPES.AMOUNT_AND_CURRENCY: {
      const currency = values[params['currencyFieldName']];
      const currencyValue = Object.keys(CURRENCY_SYMBOLS).includes(currency)
                            ? CURRENCY_SYMBOLS[currency]
                            : currency;
      const formattedAmount = (+value)?.toLocaleString('ru-RU', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
        .replace(',', '.');
      return `${currencyValue}${formattedAmount}`;
    }
    default:
      return value;
  }
};

export const getStepsWithParallelSteps = (steps: StepType[]) => {
  let parallelStepsTemp = [];
  return steps.reduce((acc, step, index) => {
    if (step.parallel && (parallelStepsTemp.length === 0 || parallelStepsTemp.length > 0 && parallelStepsTemp[0].stepperOrder === step.stepperOrder)) {
      parallelStepsTemp.push(step);
    } else {
      if (parallelStepsTemp.length > 0) {
        const combinedStep: CombinedStepType = {
          ...parallelStepsTemp[0],
          isCombinedStep: true,
          completedStatuses: parallelStepsTemp.map(v => v.completed),
          assigneeList: parallelStepsTemp.map(v => v.assignee),
          approveStageList: parallelStepsTemp.map(v => v.approveStage),
        };
        acc.push(combinedStep);
        parallelStepsTemp = [];
      }
      if (step.parallel) {
        parallelStepsTemp.push(step);
      } else {
        acc.push({ ...step, isCombinedStep: false });
      }
    }

    return acc;
  }, []);
};

export const getStepApproveStageInfo = ({
  isCurrentStep = false,
  isFutureStep = false,
  cancelledStep = false,
  taskStartDate,
  approveStage = '',
}, t) => {
  if (isCurrentStep || isFutureStep) {
    return {};
  }

  const translatedCurrentApprovedStage = t(getTranslationKeyForTaskHistoryInPast(approveStage?.substring(1, approveStage.length - 1)));

  const currentStepEndDate = taskStartDate
                             ? moment(taskStartDate).format('D MMMM Y, HH:mm')
                             : t('task_data_view.history_tab.no_date');

  return {
    displayApproveStage: translatedCurrentApprovedStage,
    completionDateString: currentStepEndDate,
  };
};

export const parseAssigneeList = (assignee: string) => {
  const separator = assignee?.includes(',') ? ',' : ';';
  return assignee?.split(separator)?.map(v => v.trim()) || [assignee];
};
