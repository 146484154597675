import { Language, TemplateProcessTableColumn } from './TemplateProcesses.types';

export const PROCESS_NAME_DEFAULT = {
  [Language.RUSSIAN]: 'Безымянный процесс',
  [Language.KAZAKH]: 'Процестің атауы жоқ',
  [Language.ENGLISH]: 'Untitled process',
  [Language.BAHASA]: 'Proses tanpa judul',
  [Language.GERMAN]: 'Prozess ohne Namen',
};

export const LANGUAGE_TRANSLATION = {
  ru: 'Русский',
  kz: 'Қазақша',
  en: 'English',
  id: 'Bahasa',
  de: 'Deutsch'
}

export const TEMPLATE_PROCESSES_TABLE_COLUMNS: TemplateProcessTableColumn[] = [
  {
    id: "processName",
    label: "customProcesses.table.processName",
    width: "auto",
  },
  {
    id: "published",
    label: "customProcesses.table.status",
    width: 140
  },
  {
    id: "autoStart",
    label: "customProcesses.table.autostart",
    width: 150
  },
  {
    id: "allLanguages",
    label: "customProcesses.table.languages",
    width: 100,
  },
  {
    id: "docflowTemplatesCount",
    label: "customProcesses.table.docflowTemplatesCount",
    width: 100,
  },
  {
    id: "creator",
    label: "filters.author",
    width: 160
  },
  {
    id: "stepsCount",
    label: "customProcesses.table.steps",
    width: 95
  },
  {
    id: "createdDate",
    label: "customProcesses.table.creationDate",
    width: 130,
  },
  {
    id: "lastUpdated",
    label: "customProcesses.table.updateDate",
    width: 130
  },
  {
    id: "processMenu",
    label: "",
    width: 48
  }
];

export const DM_CHECK_ASSIGNEE = '${HeadCheck}';
export const PERFORMER_STEP_ASSIGNEE = '${performer}';

export const SUMMARY_PER_FIELD_TEMPLATE = '<p>{{ FIELD_NAME }}</p>';

export const DEFAULT_FIELDS_FOR_OLD_PROCESSES = [
  {
    id: 'field1',
    component: 'singleLineText',
    isRequired: true,
    isHidden: false,
    isEditable: true,
    order: 1,
    hint: {
      'en': 'Subject',
      'ru': 'Тема',
      'kz': 'Тақырып',
      'id': 'Subjek',
    },
    placeholder: {
      'ru': '',
      'en': '',
      'kz': '',
      'id': '',
    },
  },
  {
    id: 'field2',
    component: 'multiLineText',
    isRequired: true,
    isHidden: false,
    isEditable: true,
    order: 2,
    hint: {
      'en': 'Description',
      'ru': 'Описание',
      'kz': 'Сипаттамасы',
      'id': 'Deskripsi',
    },
    placeholder: {
      'ru': '',
      'en': '',
      'kz': '',
      'id': '',
    },
  },
  {
    id: 'field3',
    component: 'attachmentTemplate',
    isRequired: true,
    isHidden: false,
    isEditable: true,
    order: 3,
    hint: {
      'en': 'Attachment',
      'ru': 'Вложение',
      'kz': 'Қосымша',
      'id': 'Lampiran',
    },
    placeholder: {
      'ru': '',
      'en': '',
      'kz': '',
      'id': '',
    },
  },
];

export const CUSTOM_FIELDS_RELEASE_DATE = {
  'DEV': '2022-11-25',
  'QA': '2022-11-25',
  'PROD': '2022-12-3'
}

export const MAX_REPEAT_COUNTS = {
  'day': 100000,
  'week': 10000,
  'month': 1000,
  'year': 100,
}

export const MAX_PARALLEL_BRANCHES_COUNT = 5;

export const STEPS_NUMBER_LIMIT = 41;
export const BRANCH_STEPS_NUMBER_LIMIT = 10;
export const MAX_FIELDS_PER_PROCESS = 100;

export const MAX_NAME_LENGTH = 255;
export const MAX_DESCRIPTION_LENGTH = 500;
