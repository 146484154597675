import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  Wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  InnerWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    position: 'relative',
    width: 'fit-content',
  },
  amountInput: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    marginRight: -1,
    width: '100%',
    '&:hover': {
      zIndex: 20,
    },
    '&:focused': {
      zIndex: 20,
    },
  },
  currencyAutocomplete: {
    width: 109,
  },
  currencyInputRoot: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: 109,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500],
  },
  currencyInput: {
    padding: '0 !important',
  },
  errorMessage: {
    marginLeft: 14,
  },
}));
