import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
  ReactComponentElement,
  ReactNode,
} from 'react';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';
import NumberFormat from 'react-number-format';
import Scrollbars from 'react-custom-scrollbars';
import cn from 'classnames';
import {
  Box,
  ButtonBase,
  TextField,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';

import Portal from 'utils/portal';

import Calendar from './calendarGenerator';
import useStyles from './useStyles';

import {
  OPTIONS,
  OPTIONS_DATES,
  DEFAULT_OPTION,
  DEFAULT_DATE_FROM,
  DEFAULT_DATE_TO,
  CUSTOM,
  N_DAYS_UP_TO_TODAY,
  N_DAYS_UP_TO_YESTERDAY,
  DEFAULT_DAYS_INPUT_VALUE,
  DATE_FORMAT_TEMPLATE,
  YEAR_MIN,
  YEAR_MAX,
  YEAR_DEFAULT,
  MONTH_DEFAULT,
  MONTHS_COUNT,
  MONTHS_EN,
  DROPDOWN_HEIGHT, OPTIONS_LABELS, DROPDOWN_WIDTH, STAY_OPEN_OPTIONS,
} from './constants';

import dropdownArrowIcon from 'assets/images/icons/dropdown-arrow-icon.svg';
import dropdownIndicatorIcon from 'assets/images/icons/dropdown-indicator.svg';
import leftArrowIcon from 'assets/images/icons/arrow-left-blue.svg';
import rightArrowIcon from 'assets/images/icons/arrow-right-blue.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  [key: string]: any;
  label: string | ReactNode;
  dateFromValue?: Date;
  onDateFromChange?: (arg: Date) => void;
  dateToValue?: Date;
  onDateToChange?: (arg: Date) => void;
  selectedDatePeriod?: string;
  onSelectedDatePeriodChange?: (arg: string) => void;
  analyticsFiltersDesignVariant?: boolean;
  preventInitialValuesSet?: boolean;
  defaultDateFrom?: Date;
  defaultDateTo?: Date;
  defaultDatePeriod?: Date;
  hideCustomDaysTo?: boolean;
  mainFiltersVariant?: boolean;
};

export const DatePeriodPicker = ({
  label,
  onDateFromChange,
  onDateToChange,
  analyticsFiltersDesignVariant = false,
  selectedDatePeriod = DEFAULT_OPTION,
  onSelectedDatePeriodChange,
  preventInitialValuesSet = false,
  dateFromValue,
  dateToValue,
  hideCustomDaysTo = false,
  mainFiltersVariant = false,
  className,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const textFieldRef = useRef(null);
  const [dropdownId] = useState(uniqueId('date-period-picker-dropdown-'));

  const [open, setOpen] = useState(false);
  const [selectInCustomOpen, setSelectInCustomOpen] = useState(false);
  const [isCustomDatePickerOpen, setCustomDatePickerOpen] = useState(false);
  const [dateFrom, setDateFrom] = useState(
    dateFromValue ? moment(dateFromValue) : DEFAULT_DATE_FROM,
  );
  const [dateTo, setDateTo] = useState(
    dateToValue ? moment(dateToValue) : DEFAULT_DATE_TO,
  );
  const [daysUpToTodayValue, setDaysUpToTodayValue] = useState(
    DEFAULT_DAYS_INPUT_VALUE,
  );
  const [daysUpToYesterdayValue, setDaysUpToYesterdayValue] = useState(
    DEFAULT_DAYS_INPUT_VALUE,
  );

  // true - select first date from, false - select second date
  const [isDateOneSelection, setDateOneSelection] = useState(true);

  const monthsList = useMemo(() => moment.months(), []);

  const yearsList = useMemo(() => {
    const list = [];
    for (let year = YEAR_MIN; year <= YEAR_MAX; year++) {
      list.push(year);
    }
    return list;
  }, []);

  const [selectedMonth, setSelectedMonth] = useState(monthsList[MONTH_DEFAULT]);
  const [selectedYear, setSelectedYear] = useState(YEAR_DEFAULT);

  const hasValueChanged = useMemo(
    () =>
      !(
        moment(dateFrom).isSame(DEFAULT_DATE_FROM) &&
        moment(dateTo).isSame(DEFAULT_DATE_TO)
      ),
    [dateFrom, dateTo],
  );

  const handleMonthChange = useCallback(
    (value) => {
      setSelectedMonth(value);
    },
    [setSelectedMonth],
  );

  const handleYearChange = useCallback(
    (value) => {
      setSelectedYear(value);
    },
    [setSelectedYear],
  );

  const handlePreviousMonth = useCallback(() => {
    const monthIndex = monthsList.indexOf(selectedMonth);
    if (monthIndex > 0) {
      setSelectedMonth(monthsList[monthIndex - 1]);
    } else {
      if (+selectedYear > +YEAR_MIN) {
        setSelectedMonth(monthsList[monthsList.length - 1]);
        setSelectedYear(selectedYear - 1);
      }
    }
  }, [
    selectedMonth,
    selectedYear,
    setSelectedMonth,
    setSelectedYear,
    monthsList,
  ]);

  const handleNextMonth = useCallback(() => {
    const monthIndex = monthsList.indexOf(selectedMonth);
    if (monthIndex < 11) {
      setSelectedMonth(monthsList[monthIndex + 1]);
    } else {
      if (+selectedYear < +YEAR_MAX) {
        setSelectedMonth(monthsList[0]);
        setSelectedYear(selectedYear + 1);
      }
    }
  }, [
    selectedMonth,
    selectedYear,
    setSelectedMonth,
    setSelectedYear,
    monthsList,
  ]);

  const textFieldValue = useMemo(() => {
    if (selectedDatePeriod === CUSTOM) {
      return `${moment(dateFrom).format(DATE_FORMAT_TEMPLATE)} — ${moment(
        dateTo,
      ).format(DATE_FORMAT_TEMPLATE)}`;
    }

    if (selectedDatePeriod === N_DAYS_UP_TO_TODAY) {
      return `${t('form_components.date_period_picker.days_up_to_today_1')}${daysUpToTodayValue} ${t('form_components.date_period_picker.days_up_to_today_2')}`;
    }

    if (selectedDatePeriod === N_DAYS_UP_TO_YESTERDAY) {
      return `${t('form_components.date_period_picker.days_up_to_yesterday_1')}${daysUpToYesterdayValue} ${t('form_components.date_period_picker.days_up_to_yesterday_2')}`;
    }

    return t(OPTIONS_LABELS[selectedDatePeriod]);
  }, [selectedDatePeriod, dateFrom, dateTo]);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        ref.current &&
        !ref.current?.contains(event.target) &&
        open &&
        // prevent closing when material ui select is clicked
        ((selectInCustomOpen &&
            event?.target?.tagName !== 'BODY' &&
            !event?.target?.classList?.includes('MuiMenuItem-root')) ||
          !selectInCustomOpen)
      ) {
        setOpen(() => false);
      }
    },
    [ref, open, setOpen, selectInCustomOpen, setSelectInCustomOpen],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    !preventInitialValuesSet &&
    onDateFromChange &&
    onDateFromChange(dateFrom?.startOf('day').toDate());
    !preventInitialValuesSet &&
    onDateToChange &&
    onDateToChange(dateTo?.endOf('day').toDate());
    !preventInitialValuesSet &&
    onSelectedDatePeriodChange &&
    onSelectedDatePeriodChange(DEFAULT_OPTION);
  }, [preventInitialValuesSet]);

  useEffect(() => {
    setCustomDatePickerOpen(selectedDatePeriod === CUSTOM);

    const deltaInDays = moment(dateTo).endOf('day').diff(moment(dateFrom).startOf('day'), 'days');
    if (selectedDatePeriod === N_DAYS_UP_TO_TODAY && daysUpToTodayValue !== deltaInDays.toString()) {
      setDaysUpToTodayValue(deltaInDays.toString());
    }
    if (selectedDatePeriod === N_DAYS_UP_TO_YESTERDAY && daysUpToYesterdayValue !== deltaInDays.toString()) {
      setDaysUpToYesterdayValue((deltaInDays + 1).toString());
    }
  }, [selectedDatePeriod, setDaysUpToTodayValue, setDaysUpToYesterdayValue]);

  const handleClick = useCallback(
    (value) => {
      onSelectedDatePeriodChange && onSelectedDatePeriodChange(value);
      setCustomDatePickerOpen(() => false);

      if (Object.keys(OPTIONS).includes(value)) {
        const selectedOptionDates = OPTIONS_DATES[OPTIONS[value]];
        setDateFrom(selectedOptionDates.from?.startOf('day'));
        setDateTo(selectedOptionDates.to?.endOf('day'));
        onDateFromChange &&
        onDateFromChange(selectedOptionDates.from?.startOf('day').toDate());
        onDateToChange &&
        onDateToChange(selectedOptionDates.to?.endOf('day').toDate());
      }

      if (value === N_DAYS_UP_TO_TODAY) {
        const from = moment()
          .subtract(+daysUpToTodayValue, 'days')
          .startOf('day');
        const to = OPTIONS_DATES[OPTIONS.TODAY]?.to.endOf('day');
        setDateFrom(from);
        setDateTo(to);
        onDateFromChange && onDateFromChange(from.toDate());
        onDateToChange && onDateToChange(to.toDate());
      }

      if (value === N_DAYS_UP_TO_YESTERDAY) {
        const from = moment()
          .subtract(+daysUpToYesterdayValue + 1, 'days')
          .startOf('day');
        const to = OPTIONS_DATES[OPTIONS.YESTERDAY]?.to.endOf('day');
        setDateFrom(from);
        setDateTo(to);
        onDateFromChange && onDateFromChange(from.toDate());
        onDateToChange && onDateToChange(to.toDate());
      }

      if (value === CUSTOM) {
        setCustomDatePickerOpen(() => true);
      }

      if (!STAY_OPEN_OPTIONS.includes(value)) {
        setOpen(() => false);
      }
    },
    [
      setDateFrom,
      setDateTo,
      setOpen,
      onDateFromChange,
      onDateToChange,
      daysUpToTodayValue,
      daysUpToYesterdayValue,
    ],
  );

  const handleCalendarTileClick = useCallback(
    (date) => {
      if (isDateOneSelection) {
        setDateFrom(date);
        setDateTo(date);
        onDateToChange && onDateToChange(date?.endOf('day').toDate());
        onDateFromChange && onDateFromChange(date?.startOf('day').toDate());
        setDateOneSelection(() => false);
      } else {
        if (date.isSameOrAfter(dateFrom)) {
          setDateTo(date);
          onDateToChange && onDateToChange(date?.endOf('day').toDate());
        } else {
          setDateFrom(date);
          onDateFromChange && onDateFromChange(date?.startOf('day').toDate());
        }
        setDateOneSelection(() => true);
      }
    },
    [
      isDateOneSelection,
      setDateOneSelection,
      dateFrom,
      dateTo,
      setDateFrom,
      setDateTo,
    ],
  );

  useEffect(() => {
    if (selectedDatePeriod === N_DAYS_UP_TO_TODAY) {
      const from = moment()
        .subtract(+daysUpToTodayValue, 'days')
        .startOf('day');
      setDateFrom(from);
      onDateFromChange && onDateFromChange(from.toDate());
    }
  }, [selectedDatePeriod, daysUpToTodayValue, onDateFromChange]);

  useEffect(() => {
    if (selectedDatePeriod === N_DAYS_UP_TO_YESTERDAY) {
      const from = moment()
        .subtract(+daysUpToYesterdayValue, 'days')
        .startOf('day');
      setDateFrom(from);
      onDateFromChange && onDateFromChange(from.toDate());
    }
  }, [selectedDatePeriod, daysUpToYesterdayValue, onDateFromChange]);

  const weekdays = useMemo(() => {
    const weekdaysShort = moment.weekdaysMin().map(v => v[0].toUpperCase());
    const shiftedWeekdays = [];
    for (let i = 0; i < weekdaysShort.length; i++) {
      const index = i + 1 > weekdaysShort.length - 1 ? i + 1 - weekdaysShort.length : i + 1;
      shiftedWeekdays.push(weekdaysShort[Math.abs(index)]);
    }
    return shiftedWeekdays;
  }, []);

  const calendarMatrix = useMemo(() => {
    // calendar matrix generation
    let startDate = null;
    let endDate = null;
    const from = dateFrom?.toDate();
    const to = dateTo?.toDate();

    const selectedMonthIndex = moment.months().indexOf(selectedMonth);
    const selectedMonthEn = MONTHS_EN[selectedMonthIndex];

    const selectedCustomDate = new Date(
      '1 ' + selectedMonthEn + ' ' + selectedYear,
    );

    if (!dateFrom || !dateTo || !selectedMonth || !selectedYear) {
      startDate = moment();
      endDate = moment();
      if (selectedDatePeriod === CUSTOM && !dateFrom) {
        setDateFrom(moment());
      }
      if (selectedDatePeriod === CUSTOM && !dateTo) {
        setDateTo(moment());
      }
    } else {
      if (
        (from < selectedCustomDate && selectedCustomDate < to) ||
        moment(selectedCustomDate)
          .startOf('day')
          .isSame(dateFrom.startOf('day')) ||
        moment(selectedCustomDate).startOf('day').isSame(dateTo.startOf('day'))
      ) {
        startDate = moment(dateFrom);
        endDate = moment(dateTo);
      }
      if (selectedCustomDate < from && from <= to) {
        startDate = moment(selectedCustomDate);
        endDate = moment(dateTo);
      }
      if (from <= to && to < selectedCustomDate) {
        startDate = moment(dateFrom);
        endDate = moment(selectedCustomDate);
      }
    }

    const startMonth = startDate?.month();
    const startYear = startDate?.year();
    const endMonth = endDate?.month();
    const endYear = endDate?.year();
    const yearsDiff = endYear - startYear;

    let calendarMonthsList = [];
    if (yearsDiff === 0) {
      // dates in one year
      for (let m = startMonth; m <= endMonth; m++) {
        calendarMonthsList.push({ month: MONTHS_EN[m], year: startYear });
      }
    } else {
      // dates in different years
      for (let m = startMonth; m < MONTHS_COUNT; m++) {
        calendarMonthsList.push({ month: MONTHS_EN[m], year: startYear });
      }
      if (yearsDiff > 1) {
        for (let year = startYear + 1; year < endYear; year++) {
          const monthsInYear = monthsList.map((month) => {
            return { month, year };
          });
          calendarMonthsList = calendarMonthsList.concat(monthsInYear);
        }
      }
      for (let m = 0; m <= endMonth; m++) {
        calendarMonthsList.push({ month: MONTHS_EN[m], year: endYear });
      }
    }

    const calendarMatrixes = [];
    calendarMonthsList.forEach((calendarMonth) => {
      const monthMatrix = new Calendar(
        new Date('1 ' + calendarMonth['month'] + ' ' + calendarMonth['year']),
      ).getMatrix();
      calendarMatrixes.push({
        month: calendarMonth['month'],
        year: calendarMonth['year'],
        matrix: monthMatrix,
      });
    });

    return calendarMatrixes;
  }, [dateFrom, dateTo, selectedMonth, selectedYear]);

  const dropdownOptions = useMemo(() => {
    if (!textFieldRef.current) {
      return { x: 0, y: 0, maxDropdownHeight: 0 };
    }

    const boundRect = textFieldRef.current.getBoundingClientRect();
    const options = {
      x: Math.min(boundRect.left, window.innerWidth - DROPDOWN_WIDTH - 20),
      y: Math.min(
        window.innerHeight - DROPDOWN_HEIGHT - 20,
        boundRect.top + 27,
      ),
    };

    return options;
  }, [textFieldRef.current, open]);

  const classes = useStyles({ isCustomDatePickerOpen, ...dropdownOptions });

  const calendar = useMemo(() => {
    return calendarMatrix.map((calendarMonth) => {
      const weeksCount = Object.keys(calendarMonth['matrix']).length;
      const weeks = [];
      for (let i = 0; i < weeksCount; i++) {
        weeks.push('week_' + i);
      }

      return (
        <div className={classes.calendarMonthWrapper}>
          <Typography className={classes.calendarMonthTitle}>
            {monthsList[MONTHS_EN.indexOf(calendarMonth['month'])]?.substring(0, 3).toUpperCase()}{' '}
            {calendarMonth['year']}
          </Typography>
          {weeks.map((weekKey) => {
            const week = calendarMonth['matrix'][weekKey].map((day) => {
              return { ...day, date: moment(day.date) };
            });

            if (!week.every((day) => day?.isNextMonth || day?.isPrevMonth)) {
              return (
                <div className={classes.calendarRow}>
                  {week.map((day) => {
                    const calendarTileClasses = cn(classes.calendarTile, {
                      [classes.currentMonth]:
                      !day.isNextMonth && !day.isPrevMonth,
                      [classes.otherMonth]: day.isNextMonth || day.isPrevMonth,
                      [classes.selectedDate]:
                      day.date
                        .startOf('day')
                        .isSame(moment(dateFrom).startOf('day')) ||
                      day.date
                        .startOf('day')
                        .isSame(moment(dateTo).startOf('day')),
                      [classes.dateFrom]:
                      !!dateFrom &&
                      day.date
                        .startOf('day')
                        .isSame(moment(dateFrom).startOf('day')),
                      [classes.dateTo]:
                      !!dateTo &&
                      day.date
                        .startOf('day')
                        .isSame(moment(dateTo).startOf('day')),
                      [classes.noBackground]:
                      !!dateFrom &&
                      !!dateTo &&
                      dateFrom.startOf('day').isSame(dateTo?.startOf('day')),
                      [classes.dateInPeriod]:
                      !!dateFrom &&
                      !!dateTo &&
                      day.date.isBetween(
                        moment(dateFrom).startOf('day'),
                        moment(dateTo).subtract(1, 'day').endOf('day'),
                      ),
                    });

                    return (
                      <div
                        className={calendarTileClasses}
                        onClick={() => handleCalendarTileClick(day.date)}
                      >
                        <span>{day.date.date()}</span>
                      </div>
                    );
                  })}
                </div>
              );
            }
          })}
        </div>
      );
    });
  }, [calendarMatrix]);

  return (
    <Box className={className}>
      <TextField
        className={cn(classes.textField, {
          [classes.analyticsFiltersVariant]: analyticsFiltersDesignVariant,
          [classes.mainFiltersVariant]: mainFiltersVariant,
          [classes.valueChanged]:
          analyticsFiltersDesignVariant && hasValueChanged,
        })}
        label={label}
        value={textFieldValue}
        onClick={() => setOpen((v) => !v)}
        inputRef={textFieldRef}
        InputProps={{
          endAdornment: (
            <img src={mainFiltersVariant ? dropdownIndicatorIcon : dropdownArrowIcon} style={{ marginRight: 4 }}/>
          ),
        }}
      />

      {open && (
        <Portal id={dropdownId}>
          <div className={classes.dropdownWrapper} ref={ref}>
            <Box className={classes.optionsWrapper}>
              <ul className={classes.optionsList}>
                <li
                  className={cn([classes.option], {
                    [classes.selectedOption]: selectedDatePeriod === CUSTOM,
                  })}
                  onClick={() => handleClick(CUSTOM)}
                >
                  {t('form_components.date_period_picker.custom')}
                </li>

                <div className={classes.divider}/>

                {Object.keys(OPTIONS).map((optionKey) => {
                  return (
                    <li
                      className={cn(classes.option, {
                        [classes.selectedOption]:
                        optionKey === selectedDatePeriod,
                      })}
                      onClick={() => handleClick(optionKey)}
                    >
                      {t(OPTIONS_LABELS[optionKey])}
                    </li>
                  );
                })}

                <div className={classes.divider}/>
                {!hideCustomDaysTo && (
                  <>
                    <li
                      className={cn([classes.option, classes.optionWithInput], {
                        [classes.selectedOption]:
                        selectedDatePeriod === N_DAYS_UP_TO_TODAY,
                      })}
                      onClick={() => handleClick(N_DAYS_UP_TO_TODAY)}
                    >
                      {t('form_components.date_period_picker.days_up_to_today_1')}
                      <NumberFormat
                        customInput={TextField}
                        className={classes.daysInput}
                        value={daysUpToTodayValue}
                        onChange={(e) => setDaysUpToTodayValue(e.target.value)}
                      />
                      {t('form_components.date_period_picker.days_up_to_today_2')}
                    </li>

                    <li
                      className={cn([classes.option, classes.optionWithInput], {
                        [classes.selectedOption]:
                        selectedDatePeriod === N_DAYS_UP_TO_YESTERDAY,
                      })}
                      onClick={() => handleClick(N_DAYS_UP_TO_YESTERDAY)}
                    >
                      {t('form_components.date_period_picker.days_up_to_yesterday_1')}
                      <NumberFormat
                        customInput={TextField}
                        className={classes.daysInput}
                        value={daysUpToYesterdayValue}
                        onChange={(e) => setDaysUpToYesterdayValue(e.target.value)}
                      />
                      {t('form_components.date_period_picker.days_up_to_yesterday_2')}
                    </li>
                  </>
                )}
              </ul>
            </Box>

            {/* CUSTOM DATE PERIOD PICKER */}
            {isCustomDatePickerOpen && (
              <Box className={classes.customPickerWrapper}>
                <Box className={classes.selectedDateDisplay}>
                  <TextField
                    className={classes.selectedDateTextField}
                    value={moment(dateFrom).format(DATE_FORMAT_TEMPLATE)}
                  />
                  <span className={classes.selectedDateDash}>—</span>
                  <TextField
                    className={classes.selectedDateTextField}
                    value={moment(dateTo).format(DATE_FORMAT_TEMPLATE)}
                  />
                </Box>

                <Box className={classes.monthAndYearSelect}>
                  <ButtonBase
                    className={classes.arrowButton}
                    onClick={handlePreviousMonth}
                  >
                    <img src={leftArrowIcon} alt={'go to previous month'}/>
                  </ButtonBase>

                  <Select
                    onChange={(event) => handleMonthChange(event.target.value)}
                    onOpen={() => setSelectInCustomOpen(() => true)}
                    onClose={() => setSelectInCustomOpen(() => false)}
                    className={cn([classes.select, classes.monthsSelect])}
                    defaultValue={monthsList[MONTH_DEFAULT]}
                    value={selectedMonth}
                  >
                    {monthsList.map((month) => (
                      <MenuItem value={month}>{month}</MenuItem>
                    ))}
                  </Select>

                  <Select
                    onChange={(event) => handleYearChange(event.target.value)}
                    onOpen={() => setSelectInCustomOpen(() => true)}
                    onClose={() => setSelectInCustomOpen(() => false)}
                    className={classes.select}
                    defaultValue={YEAR_DEFAULT}
                    value={selectedYear}
                    // classes={{}}
                  >
                    {yearsList.map((year) => (
                      <MenuItem value={year}>{year}</MenuItem>
                    ))}
                  </Select>

                  <ButtonBase
                    className={classes.arrowButton}
                    onClick={handleNextMonth}
                  >
                    <img src={rightArrowIcon} alt={'go to next month'}/>
                  </ButtonBase>
                </Box>

                <Box className={classes.weekdaysRow}>
                  {weekdays.map(name => <div className={classes.calendarTile}>{name}</div>)}
                </Box>

                <Box className={classes.calendarWrapper}>
                  <Scrollbars style={{ width: '100%', height: '100%' }}>
                    {calendar}
                  </Scrollbars>
                </Box>
              </Box>
            )}
          </div>
        </Portal>
      )}
    </Box>
  );
};
