import { makeStyles } from "@material-ui/core";

export default makeStyles({
  checkbox: {
    '& svg path': {
      fill: '#8B8C9E !important',
    },
  },
  checkboxChecked: {
    '& svg path': {
      fill: '#007994 !important',
    },
  },
})
