import React, { useEffect, useCallback, useState } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { CircularProgress } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core';
import { theme } from '@dartech/dms-ui';
import MomentUtils from '@date-io/moment';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { ReactComponent as CalendarIcon } from 'assets/images/emojis/calendar.svg';

const LOCALES = {
  'en': 'en-GB',
  'kz': 'kk',
  'ru': 'ru',
  'id': 'id'
}

type Props = {
  name?: string;
  [key: string]: any;
  value?: Date;
  onChange?: (arg: Date) => void;
  checkDateDisabled?: (arg: Date) => boolean;
  disabled?: boolean;
  disableKeyboardInput?: boolean;
  loading?: boolean;
  placeholder?: string;
};

export const DatePicker = ({
  name,
  value,
  onChange,
  disabled,
  disableKeyboardInput = false,
  loading,
  checkDateDisabled = (arg: Date) => false,
  placeholder = null,
  ...props
}: Props) => {
  const {i18n} = useTranslation();

  moment.locale(LOCALES[i18n.language] || i18n.language)

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={LOCALES[i18n.language] || i18n.language}>
      <KeyboardDatePicker
        {...props}
        format="DD/MM/YYYY"
        placeholder={placeholder}
        variant="inline"
        autoOk
        value={value}
        onChange={(value) =>
          value ? onChange(value.toDate()) : onChange(null)
        }
        onKeyDown={(e) => {
          if (disableKeyboardInput) {
            e.preventDefault();
          }
        }}
        disableToolbar
        shouldDisableDate={(value) => checkDateDisabled(value.toDate())}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        leftArrowButtonProps={{
          style: {
            color: theme.palette.grey[700],
          },
        }}
        rightArrowButtonProps={{
          style: {
            color: theme.palette.grey[700],
          },
        }}
        inputProps={{ 'data-selenium': name }}
        keyboardIcon={
          loading ? (
            <CircularProgress size={18} />
          ) : (
            <SvgIcon
              component={CalendarIcon}
              style={{ marginTop: 4, marginLeft: 4 }}
            />
          )
        }
        disabled={loading || disabled}
      />
    </MuiPickersUtilsProvider>
  );
};
