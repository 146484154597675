import { useCallback, useEffect, useMemo, useState } from 'react';

import isNil from 'lodash/isNil';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';

import { useUsersState } from 'store/users';
import { setCreateRequestAction as setCreateRequest, setInitialTab, useProcessesListState, useTaskState } from 'store/requests';
import { useHoldingTranslationsLoadedState, useReadStatus } from 'store/main';
import { getUserDataByIdOrLogName } from 'utils/user';
import { useRequestParams, useUserProfile, useUsersRole } from 'hooks';

import { MassActionValidationType, TaskRequestType, TaskStatusesEnum } from 'types';
import { MASS_ACTIONS_VALIDATION_RESULTS, TicketDataType } from './ticket.types';

import { ATTACHMENTS_COUNTER_CLASS, COMMENTS_COUNTER_CLASS, MALFORMED_TASK_ERROR } from './constants';
import { ATTACHMENTS_TAB_NAME, HISTORY_TAB_NAME } from 'components/TaskDetails/components/Tabs/FormTab/constants';
import { COMMENTS_TAB_NAME } from 'components/TaskDetails/components/Tabs/CommentsTab/TaskComments/constants';
import isObject from 'lodash/isObject';
import { takeOnATask } from '../../api/requests';
import { getApprovalsAction, useApprovalsListState } from '../../store/approvals';
import { useFiltersState } from '../../store/search';
import isNull from 'lodash/isNull';

type Props = {
  request: TaskRequestType,
  type?: 'myRequest' | 'completedRequest' | 'reviewedApproval' | 'approval' | 'draft' | 'watchers',
  setMassApprove?: (ticketData: TicketDataType) => void;
  onClick?: () => void;
}

export const useTicket = ({ request, type, setMassApprove, onClick }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { companyName, id: profileId } = useUserProfile();
  const { hasAccessToMassActions } = useUsersRole();

  const readStatuses = useReadStatus();
  const holdingTranslationsLoaded = useHoldingTranslationsLoadedState();

  const { users } = useUsersState();
  const { data: processesList } = useProcessesListState();

  const filtersParams = useFiltersState();
  const { pageSize: approvalsPageSize } = useApprovalsListState();
  const {
    getRequestParameters: getRequestParametersForApprovals,
    urlParams: urlParamsApprovals,
  } = useRequestParams({
    completed: false,
    currentPage: 1,
    pageSize: approvalsPageSize,
    type: 'approvals',
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [ticketData, setTicketData] = useState<TicketDataType | null>(null);

  const [initiatorFullName, setInitiatorFullName] = useState('');
  const [assigneeFullName, setAssigneeFullName] = useState('');
  const [assigneeUsersList, setAssigneeUsersList] = useState([]);

  const [takeOnTaskLoading, setTakeOnTaskLoading] = useState<boolean>(false);

  const handleCommentsCounterClick = useCallback(() =>
      dispatch(setInitialTab(COMMENTS_TAB_NAME)),
    [dispatch]);

  const handleAttachmentsCounterClick = useCallback(() =>
      dispatch(setInitialTab(ATTACHMENTS_TAB_NAME)),
    [dispatch]);

  const handleTakeOnTask = useCallback(async () => {
    try {
      setTakeOnTaskLoading(true);
      const { taskId } = request?.businessTask || {};
      const { assigneeVariableName } = request?.currentAction || {};
      await takeOnATask({
        taskId,
        variable: assigneeVariableName
      });
      NotificationManager.success(t("success_messages.take_on_task"))

      setTimeout(() => {
        const approvalsParametersTemp = getRequestParametersForApprovals(
          urlParamsApprovals,
        );

        const approvalsParameters = {
          ...approvalsParametersTemp, ...{
            ...filtersParams,
            pagination: {
              ...approvalsParametersTemp.pagination,
              ...(filtersParams?.pagination || {}),
              size: approvalsPageSize,
              ...(filtersParams?.pagination
                  ? { page: filtersParams?.pagination?.page }
                  : {}),

            },
          },
        };

        dispatch(getApprovalsAction({ params: approvalsParameters }));
      })
    } catch (error) {
      console.log(error);
      NotificationManager.error(t("taskTicket.notifications.TakeOnTaskError"))
    } finally {
      setTakeOnTaskLoading(false);
    }
  }, [request, filtersParams, approvalsPageSize]);

  const getUserData = async (userKey, companyId) => {
    if (isNil(userKey)) return t("task_data_view.employee_not_found");
    const hcmsUser = users[userKey];
    if (hcmsUser) {
      return hcmsUser.fullName;
    }

    const loadedUser = await getUserDataByIdOrLogName(userKey, companyId);
    if (loadedUser) {
      return loadedUser.fullName;
    }
  };

  const getInitiatorData = async () => {
    const {
      businessTask: {
        initiator,
        companyId
      },
    } = request;

    if (initiator === 'company' || initiator === '${company}') {
      setInitiatorFullName(companyName);
      return;
    }

    const fullName = await getUserData(initiator, companyId);
    setInitiatorFullName(fullName);
  };

  const getAssigneeData = async () => {
    const {
      businessTask: {
        assignee,
        companyId
      },
    } = request;

    const fullName = await getUserData(assignee, companyId);
    setAssigneeFullName(fullName);
  };

  const getAssigneeListData = async () => {
    const {
      businessTask: {
        candidateUsers = [],
        assigneesList = [],
        assignee,
        companyId
      },
    } = request;

    const list = [...(candidateUsers || []),
                  ...(assigneesList?.length ? assigneesList : [assignee])]
      .filter(v => !!v);
    setAssigneeUsersList(() => list);

    const assigneeFullNamesList = [];
    for (const user of list) {
      const fullName = await getUserData(user, companyId);
      assigneeFullNamesList.push(fullName);
    }
    setAssigneeUsersList(assigneeFullNamesList);
  };

  const localizedSummary = useMemo(() => {
    if (!request) {
      return '';
    }

    let summaryText = request.summary || '';

    const translationsEn = i18n.getResourceBundle('en', '');
    const translationsRu = i18n.getResourceBundle('ru', '');

    if (!(translationsRu && translationsEn) || !translationsEn.glossary || !translationsRu.glossary) {
      return summaryText;
    }

    const glossariesKeys = Object.keys(translationsEn.glossary)
      .reduce((acc, key) => {
        return {
          ...acc,
          [translationsEn.glossary[key]]: key,
          [translationsRu.glossary[key]]: key,
        };
      }, {});

    while (summaryText.includes('${')) {
      const startPoint = summaryText.indexOf('${');
      const endPoint = summaryText.indexOf('}');
      if (startPoint > 0 && endPoint > 0) {
        const replaceString = summaryText.substring(startPoint, endPoint + 1);
        const initialValue = replaceString.substring(2, replaceString.length - 1);
        const glossaryValueKey = glossariesKeys[initialValue];
        summaryText = summaryText.replace(replaceString, t(`glossary.${glossaryValueKey}`, { defaultValue: initialValue }));
      } else {
        summaryText = summaryText.replace('${', '');
      }
    }

    return summaryText;
  }, [request, holdingTranslationsLoaded]);

  const handleClick = useCallback((event): void => {
    if (type === 'draft') {
      const process = processesList?.find(({name}) => name === ticketData?.processName);

      const createRequestParameters = {
        name: ticketData.processName,
        id: ticketData.id,
        sysName: process?.processSysName || '',
      };

      dispatch(setCreateRequest(createRequestParameters));
      return;
    }

    event.preventDefault();
    if (onClick) {
      // prevent default initial tab setting when comments or attachments counter is clicked
      !(event.target.classList.contains(COMMENTS_COUNTER_CLASS) ||
        event.target.classList.contains(ATTACHMENTS_COUNTER_CLASS)) &&
      dispatch(setInitialTab(HISTORY_TAB_NAME));

      onClick();
    }
  }, [type, history, dispatch, processesList, ticketData]);

  const getExecutionDeadlineStatus = (deadlineDate, taskEndDate) => {
    if (!deadlineDate) {
      return {
        formattedDeadline: null,
        isTimerOver: false,
      }
    }

    const deadline = moment(deadlineDate);
    const isTimerOver = taskEndDate ? deadline.isBefore(moment(taskEndDate)) : deadline.isBefore(moment(Date.now()));
    return {
      formattedDeadline: deadline.format('DD.MM.Y, HH:mm'),
      isTimerOver,
    }
  }

  const isUnread = useMemo(() => {
    if (type === 'watchers') {
      const watcherTicketUnread = !!request.businessTask.badges?.observer
        ?.map(o => o?.taskRead)
        ?.find(v => v?.startsWith(profileId) && v?.endsWith('true'));

      return watcherTicketUnread && !readStatuses.tasks.includes(request?.businessTask?.taskId);
    }

    const isBadgeAlwaysHidden = ['reviewedApproval', 'completedRequest'].includes(type);
    const requestsCase = (type === 'myRequest' && request?.businessTask?.badges?.initiator?.taskRead);
    const approvalCase = type === 'approval' &&
      (request?.businessTask?.badges?.assignee?.taskRead ||
        !!request.businessTask.badges?.candidateUsers
          ?.map(userBadges => userBadges?.taskRead)
          ?.find(v => v?.startsWith(profileId) && v?.endsWith('true')));
    return !isBadgeAlwaysHidden && (requestsCase || approvalCase) && !readStatuses.tasks.includes(request?.businessTask?.taskId);
  }, [type, request, readStatuses]);

  const hasUnreadComments = useMemo(() => {
    if (type === 'watchers') {
      const watcherTicketCommentUnread = !!request.businessTask.badges?.observer
        ?.map(o => o?.commentRead)
        ?.find(v => v?.startsWith(profileId) && v?.endsWith('true'));

      return watcherTicketCommentUnread && !readStatuses.comments.includes(request?.businessTask?.taskId);
    }
    return (
      !(type === "reviewedApproval") &&
      (
        (type === 'myRequest' && request?.businessTask?.badges?.initiator?.commentRead) ||
        (type === 'approval' && (
          request?.businessTask?.badges?.assignee?.commentRead ||
          !!request.businessTask.badges?.candidateUsers
            ?.map(userBadges => userBadges?.commentRead)
            ?.find(v => v?.startsWith(profileId) && v?.endsWith('true'))
          )
      )) && !readStatuses.comments.includes(request?.businessTask?.taskId)
    )
  }, [type, request, readStatuses])

  const hasUnreadAttachments = useMemo(() =>
    !(type === "reviewedApproval") &&
      ((type === 'myRequest' && request?.businessTask?.badges?.initiator?.attachmentRead) ||
      (type === 'approval' && (
        request?.businessTask?.badges?.assignee?.attachmentRead ||
        !!request.businessTask.badges?.candidateUsers
          ?.map(userBadges => userBadges?.attachmentRead)
          ?.find(v => v?.startsWith(profileId) && v?.endsWith('true'))
      )
      ))
      && !readStatuses.attachments.includes(request?.businessTask?.taskId)
    , [type, request, readStatuses])

  const getFieldDisplayValue = (fieldData) => {
    if (fieldData?.type === 'glossary') {
      if (!fieldData?.localization || !isObject(fieldData.localization)) {
        return fieldData?.value as string;
      }
      return fieldData?.localization[i18n.language] || fieldData?.localization['en'] || fieldData?.localization['default'];
    }

    if (fieldData?.type === 'systemGlossary') {
      if (!fieldData?.localization || !isObject(fieldData.localization)) {
        return fieldData?.value as string;
      }
      return fieldData?.localization[i18n.language] || fieldData?.localization['en'] || fieldData?.value;
    }

    if (fieldData.type === 'glossaryMultiSelect') {
      let firstItemValue = fieldData?.value as string
      if (fieldData?.localization && isObject(fieldData.localization)) {
        firstItemValue = fieldData?.localization[i18n.language] || fieldData?.localization['en'] || fieldData?.localization['default'];
      }
      return firstItemValue;
    }

    return fieldData?.value === null ? '' : fieldData?.value as string;
  }

  const getProcessDescriptionDisplayValue = (descriptionLocalization, processSysName, defaultDescription) => {
      if (!descriptionLocalization || !isObject(descriptionLocalization)) {
        return t(`constructor-${processSysName}.description`, {defaultValue: defaultDescription});
      }
      return descriptionLocalization[i18n.language] || descriptionLocalization['en'] || descriptionLocalization['default'];
  }

  const getDisplayedMassActionValidationErrorType = (massActionValidation: MassActionValidationType, taskStatus: TaskStatusesEnum): null | MASS_ACTIONS_VALIDATION_RESULTS => {
    if (!massActionValidation || Object.values(massActionValidation).every(v => !v)) {
      return null;
    }

    if (massActionValidation.oldRequest) {
      return MASS_ACTIONS_VALIDATION_RESULTS.IS_OLD_REQUEST;
    }

    if (massActionValidation.noAssignee) {
      return MASS_ACTIONS_VALIDATION_RESULTS.NEED_TAKE_ON_TASK;
    }

    if (massActionValidation.needAssignPerformer) {
      return MASS_ACTIONS_VALIDATION_RESULTS.NEED_PERFORMER_SELECTION;
    }

    if (massActionValidation.needSignature || massActionValidation.hasDocumentSign) {
      return MASS_ACTIONS_VALIDATION_RESULTS.NEED_SIGNATURE;
    }

    if (massActionValidation.stepWithoutApproveAction) {
      switch (taskStatus) {
        case TaskStatusesEnum.Canceled: return MASS_ACTIONS_VALIDATION_RESULTS.IS_CANCELED_STEP;
        case TaskStatusesEnum.Rework: return MASS_ACTIONS_VALIDATION_RESULTS.IS_REWORK_STEP;
        case TaskStatusesEnum.Reject: return MASS_ACTIONS_VALIDATION_RESULTS.IS_REJECT_STEP;
        default: return MASS_ACTIONS_VALIDATION_RESULTS.HAS_REQUIRED_FIELDS
      }
    }

    return MASS_ACTIONS_VALIDATION_RESULTS.HAS_REQUIRED_FIELDS;
  }

  useEffect(() => {
    getInitiatorData();
    getAssigneeData();
    getAssigneeListData();
  }, [request]);

  useEffect(() => {
    try {
      const {
        businessTask: {
          taskId: id,
          companyId,
          applicationNumber,
          initiator = '',
          assignee = '',
          processInstanceId = '',
          processDefinitionName: processName = '',
          processSysName = '',
          taskCompleted: completed = false,
          taskEndDate,
          errorStatus = false,
          dueDate,
          dueDateTemplate,
          progressBar,
          taskStatus
        },
        commentsSize = 0,
        attachmentCount = 0,
        currentAction: {
          name: stepName = '',
          sysName: stepSysName = '',
          stepperOrder: stepNumber,
          massApprove = false,
        },
        processIconPath: iconPath,
        // totalCountOfSteps: stepsTotal,
        urgent: {
          urgent = false,
          reason: reasonOfUrgent = '',
        },
        summaryFields = [],
        descriptionLocalization = {},
        massActivationValidation,
        hasDocumentSign = false,
        defaultDescription,
      } = request;

      const {
        currentParallelSteps = null,
        currentParallelStepsStepperOrder = 0,
        stepsDetails = [],
        hasCondition = false,
        completedStepOrder = 0,
        stepsCount = 0,
      } = progressBar ?? {};

      const timerStatus = getExecutionDeadlineStatus(dueDateTemplate, taskEndDate);

      const descriptionSystemTranslation = getProcessDescriptionDisplayValue(descriptionLocalization, processSysName, defaultDescription);
      const isEmptySummaryField = !summaryFields?.length;
      const isEmptySummary = (request?.summary || '')?.replace('<p>', '').replace('</p>', '').trim() === '';
      const summaryType = () => {
        if (!isEmptySummaryField) {
          return 'fields';
        }
        if (!isEmptySummary) {
          return 'html';
        }
        return 'description';
      };

      const massApproveAvailable = hasAccessToMassActions || type === 'draft';
      const massApproveCheckboxDisabled = !(!!(massActivationValidation && Object.values(massActivationValidation).every(v => !v)) || type === 'draft');
      const massApproveValidationResult = getDisplayedMassActionValidationErrorType(massActivationValidation, taskStatus)

      const ticketInfo: TicketDataType = {
        id,
        companyId,
        processInstanceId,
        applicationNumber,
        initiator: initiatorFullName,
        assignee: assigneeFullName || assignee,
        assigneeList: assigneeUsersList,
        processName,
        processSysName,
        processDisplayName: t(`constructor-${processSysName}.name`, {defaultValue: processName}),
        completed: completed || taskStatus === 'Completed',
        cancelled: (completed && errorStatus) || taskStatus === 'Canceled',
        massApproveAvailable: massApproveAvailable,
        massApproveCheckboxDisabled: massApproveCheckboxDisabled,
        massActivationValidationErrorType: massApproveValidationResult,
        stepName,
        stepDisplayName: t(`constructor-${processSysName}.actions.${stepSysName}.name`, {defaultValue: stepName}),
        stepNumber: completedStepOrder,
        stepsTotal: stepsCount,
        stepsList: stepsDetails,
        iconPath,
        commentsSize,
        attachmentCount,
        summaryType: summaryType(),
        summary: localizedSummary || '',
        processDescription: descriptionSystemTranslation,
        summaryFields: summaryFields
          ?.sort((a,b) => a.fieldOrder - b.fieldOrder)
          ?.map(fieldData => ({
            fieldType: fieldData.type,
            hint: t(`constructor-${processSysName}.attributes.${fieldData?.fieldSysName?.replaceAll('::', '-')}.hint`, {defaultValue: fieldData?.name}),
            value: getFieldDisplayValue(fieldData) as string,
            valuesCount: +fieldData?.valuesCount || 0,
          })),
        urgent,
        reasonOfUrgent,
        isOverdue: taskEndDate ? moment(dueDate).isBefore(moment(taskEndDate)) : moment(dueDate).isBefore(moment()),
        rework: stepSysName?.toLowerCase().includes('rework'),
        reject: taskStatus === 'Reject',
        unread: isUnread,
        unreadComments: hasUnreadComments,
        unreadAttachments: hasUnreadAttachments,
        hasCondition,
        parallelBranchesName: currentParallelSteps,
        parallelBranchesStepperOrder: currentParallelStepsStepperOrder,
        isSignatureStep: hasDocumentSign,
        ...timerStatus,
      };

      setTicketData(ticketInfo);
      setError('');
    } catch (error) {
      console.log('Error preparing ticket data');
      console.log(error);
      setError(MALFORMED_TASK_ERROR);
    } finally {
      setLoading(false);
    }
  }, [
    users,
    request,
    initiatorFullName,
    assigneeFullName,
    assigneeUsersList,
    localizedSummary,
    isUnread,
    hasUnreadComments,
    hasUnreadAttachments,
    holdingTranslationsLoaded
  ]);

  useEffect(() => {
    if (ticketData?.massApproveAvailable && !ticketData.massApproveCheckboxDisabled && setMassApprove) {
      setMassApprove(ticketData);
    }
  }, [ticketData, setMassApprove]);

  return {
    loading,
    error,
    ticketData,
    handleClick,
    handleCommentsCounterClick,
    handleAttachmentsCounterClick,
    handleTakeOnTask,
    takeOnTaskLoading
  };
};
