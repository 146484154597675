import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing }) => ({
  paper: {
    minWidth: '500px',
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: '20px 24px 14px 24px',
    background: '#FFFFFF',
    color: '#101F2B',
  },
  dialogHeaderTitle: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.3,
    color: '#101F2B',
  },
  dialogHeaderButtonIcon: {
    '& > path': {
      fill: '#6D7C8B !important',
    },
  },
  dialogContent: {
    padding: spacing(6, 6, 11.25, 6),
    maxWidth: 500,
    backgroundColor: '#FFFFFF',
  },
  dialogContentGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  currentIconButton: {
    display: 'block',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    padding: 0,
    width: '62px !important',
    height: '62px !important',
    minWidth: 'auto',
    borderRadius: 12,
    border: '1px solid white',
    '& span': {
      borderRadius: 12,
      // border: '2px solid white !important',
      margin: 0,
      padding: 0,
      height: 60,
      width: 60,
      marginLeft: -0.25,
      '& img': {
        width: "100%",
        borderRadius: '12px !important',
      },
    },

    '&:hover': {
      backgroundColor: 'transparent',
      border: '1px solid #007F9B !important',
    },
  },
  iconButtonWithError: {
    borderRadius: 12,
    border: '1px solid #D6331F !important',
  },
  dialogContentButton: {
    display: 'block',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderRadius: spacing(3),

    height: 48,
    width: 48,
    minWidth: 'auto',
    padding: spacing(0),
    border: '2px solid white',

    '& img': {
      borderRadius: 10,
    },

    '& > .MuiButton-label': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& > img': {
        height: '100%',
        width: '100%',
        transition: '0.3s height ease, 0.3s width ease, 0.3s border ease',
      },
    },

    '&:not(:last-of-type)': {
      marginRight: spacing(4),
      marginBottom: spacing(4),
    },

    '&:hover': {
      backgroundColor: 'transparent',
      border: '2px solid #007F9B',
      // borderRadius: 10,

      '& img': {
        width: 42,
        height: 42,
        marginTop: 1,
        marginLeft: 0.5,
        borderRadius: 10,
      },
    },
  },
  dialogContentButtonPrimary: {
    height: 58,
    width: 58,

    '&:not(:last-of-type)': {
      margin: spacing(0),
    },
  },
  dialogContentButtonActive: {
    backgroundColor: 'transparent',
    border: '2px solid #007F9B',
    borderRadius: 10,

    '& img': {
      width: 42,
      height: 42,
      marginTop: 1,
      marginLeft: 0.5,
      borderRadius: 10,
    },
  },
}));
