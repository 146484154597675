import React, { useCallback } from 'react';

import { Box, List, ListItem, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';

import { CustomPagination, Ticket } from 'components';
import { RequestsListLayout } from 'layouts/RequestsListLayout';
import { MonitoringListProps } from '../ApprovalsList';
import { TaskRequestType } from 'types';

import useStyles from './index.style';

const MonitoringList = (props: MonitoringListProps) => {
  const {
    currentPage,
    handlePageChange,
    handlePageSizeChange,
    pageSize,
    totalElements,
    totalPages,
    list,
    handleTaskSelection
  } = props;
  const classes = useStyles();
  
  const ticketOnClickHandler = useCallback((ticketListItem: TaskRequestType) => {
    handleTaskSelection(ticketListItem.businessTask.taskId);
  }, []);
  
  return (
    <RequestsListLayout
      footerChild={
        <>
          <Box display="flex" justifyContent="end" alignItems="center" gridGap={24}>
            <Typography className={classes.tableFooterHint}>
              <Trans
                i18nKey="customProcesses.table.pagination"
                values={{
                  from: (currentPage - 1) * pageSize + 1,
                  to: Math.min(pageSize * (currentPage), totalElements),
                  total: totalElements,
                }}/>
            </Typography>

            {totalElements > 10 &&
              <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                pageSize={pageSize}
                handlePageSizeChange={handlePageSizeChange}
              />
            }
          </Box>
        </>
      }>
        <List style={{ padding: 0 }}>
          {list.map((taskItem: TaskRequestType) => (
            <ListItem key={taskItem?.approvedTaskId || taskItem?.businessTask.taskId} disableGutters style={{ padding: 0 }}>
              <Ticket 
                type="watchers"
                request={taskItem}
                onClick={() => ticketOnClickHandler(taskItem)} />
            </ListItem>
          ))}  
        </List>
      </RequestsListLayout>    
  )
};

export default MonitoringList;
