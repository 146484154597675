import React from "react";
import CloseIcon from 'assets/images/icons/dismiss_regular.svg';
import { IconButton } from "@material-ui/core";
import useStyles from './useStyles'

type Props = {
  onClick: () => void;
  disabled?: boolean;
  className?: string
}

export const CloseButton = ({onClick}: Props) => {
  const classes = useStyles();

  return <IconButton
  disableFocusRipple
  tabIndex={1}
  className={classes.closeButton}
  onClick={onClick}
  >
  <img src={CloseIcon} alt="close task details" draggable={false} />
  </IconButton>
}
