import React, { useState } from "react"
import { Checkbox } from "@material-ui/core"
import classNames from "classnames"
import useStyles from './CustomCheckbox.styles'

type Props = {
  onChange?: (value: boolean) => void;
  value: boolean;
  className?: string;
}

export const CustomCheckbox = ({ onChange, className, value }: Props) => {
  const classes = useStyles()

 return  <Checkbox
  color="default"
  className={classNames(classes.checkbox, {[classes.checkboxChecked]:  value }, className )}
  checked={value}
  value={value}
  onChange={(e) => {
    onChange?.(e.target.checked)
  }}
/>
}
