import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  form: {
    position: 'relative',
  },
  groupsWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  formGroup: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  formSeparateHardField: {
    width: '100%',
  },
  formGroupTitle: {
    width: 160,
    minWidth: 160,
    paddingRight: theme.spacing(4),
    fontWeight: 500,
  },
  group: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '25%',
    },
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  groupTitle: {
    marginBottom: theme.spacing(2),
  },
  bordered: {
    width: '100%',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    border: '1px solid #ccc',
  },
  pane: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: '.3s',
    paddingBottom: theme.spacing(10),
  },
  paneActive: {
    position: 'relative',
    opacity: 1,
    zIndex: 1,
  },
  urgentHidden: {
    display: 'none',
  },
  modal: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9,
    background: "rgba(38, 40, 66, 0.40);"
  },
  watchersCount: {
    color: '#6D6E85'
  },
  disabledFieldsInfoCard: {
    width: '100%',
    height: 'fit-content',
    marginBottom: 20,
    padding: 12,
    background: '#FCF8EB',
    border: '1px solid #2628421F',
    borderRadius: 12,
    color: '#262842',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
  }
}));

export default useStyles;
