import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import cn from 'classnames'

import useDocumentCardStyles from './DocumentCard.useStyles';
import DragIndicatorVertical from 'assets/images/icons/drag-indicator-vertical.svg';
import { useUsersState } from '../../../../../../../../store/users';
import { getUserDataByIdOrLogName, limitStringLength } from '../../../../../../../../utils/user';
import { Tooltip } from "@mui/material";

interface DocumentCardProps {
  name: string;
  description: string;
  author: string;
  authorWorkspace: string;
  publicationDate: string;
  isDraggable?: boolean;
  isSelected?: boolean;
  onClick: () => void;
  dragHandleProps: any;
}

export const DocumentsCard = ({
  name,
  description,
  author,
  authorWorkspace,
  publicationDate,
  isDraggable = false,
  isSelected = false,
  dragHandleProps,
  onClick,
}: DocumentCardProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useDocumentCardStyles();
  const { users } = useUsersState();

  const [authorFullName, setAuthorFullName] = useState('');

  const formattedPublicationDate = useMemo(() =>
    moment(publicationDate).format('DD.MM.YYYY')
  , [publicationDate]);

  const getUserData = async (userId: string) => {
    if (users[userId]) {
      setAuthorFullName(users[userId].fullName);
      return;
    }

    try {
      const userInfo = await getUserDataByIdOrLogName(userId, authorWorkspace);
      if (userInfo) {
        setAuthorFullName(userInfo.fullName);
      } else {
        setAuthorFullName(t('task_data_view.employee_not_found'));
      }
    } catch (error) {
      setAuthorFullName(t('task_data_view.employee_not_found'));
    }
  };

  useEffect(() => {
    getUserData(author);
  }, [author]);

  return (
    <div className={cn(classes.card, {[classes.selectedCard]: isSelected})} onClick={onClick}>

      <div className={classes.dragAndDropIndicatorWrapper}>
        {isDraggable &&
          <img src={DragIndicatorVertical} alt="" {...dragHandleProps}/>
        }
      </div>

      <div className={classes.content}>
        <h3 className={classes.name}>
          {name}
        </h3>

        <div className={classes.infoWrapper}>
          <span>{t('customProcesses.documentCard.publicationDate', {formattedPublicationDate})}</span>
          <span>·</span>
          <Tooltip title={authorFullName}>
            <span>{limitStringLength(authorFullName, 25)}</span>
          </Tooltip>
        </div>

        <p className={classes.description}>
          {description}
        </p>
      </div>
    </div>
  );
};
