import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  root: {
    width: 253,
    padding: 16,

    overflow: "hidden",

    background: "white",
    border: "1px solid rgba(38, 40, 66, 0.12)",
    borderRadius: 10,

    cursor: "pointer",

    transition: "border 0.2s ease",

    "&:hover": {
      border: "1px solid #007994"
    }
  },
  addIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    padding: 0,
    margin: "0 auto",
    width: 56,
    height: 56,

    borderRadius: 10,
    background: "#F7F8FC",

    userSelect: "none"
  },
  title: {
    marginTop: 4,

    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: "#262842",
    textAlign: "center",

    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  description: {
    marginTop: 16,

    fontSize: 14,
    fontWeight: 400,
    lineHeight: "18px",
    color: "#8B8C9E",
    textAlign: "center",

    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}))
