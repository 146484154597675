import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, palette }) => ({
  root: {
    position: 'relative'
  },
  contentFilter: {
    display: 'none',

    position: 'absolute',
    top: 42,
    left: 0,

    backgroundColor: palette.background.default,
    borderRadius: `0 ${spacing(2)} ${spacing(2)} ${spacing(2)}`,
    border: '1px solid #E0E3EA',

    width: 400,
    minHeight: 200,

    padding: spacing(6, 6, 8),
    boxShadow: 'rgba(122, 122, 122, 0.12) 0 8px 48px',
    zIndex: 10,
  },
  contentFilterActive: {
    display: 'block'
  },
  inputAdornment: {
    height: '100%',
    maxHeight: '100%',

    border: 'none',
    margin: 0,
  },
  inputAdornmentEnd: {
    marginRight: 8,
  },
  startAdornmentButton: {
    backgroundColor: '#E6ECF1',
    borderColor: '#E6ECF1',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    height: '100%',
    minWidth: 'auto',

    padding: 8,
    '&:not($startAdornmentButtonActive):hover': {
      backgroundColor: '#E6ECF1',
      borderColor: '#E6ECF1',
    }
  },
  startAdornmentButtonActive: {
    backgroundColor: palette.background.default,
    '&:hover': {
      backgroundColor: palette.background.default,
      borderColor: '#E6ECF1',
    }
  },
  startAdornmentButtonIcon: {
    height: 20,
    width: 20
  },
  endAdornmentButtonIcon: {
    '& > path': {
      fill: '#6D7C8B !important'
    }
  },
  inputWrapper: {
    fontSize: 16,
    color: '#8A96A1',

    border: '2px solid #CED7DF',
    borderWidth: '2px 0 2px 2px !important',
    borderRadius: '6px 0 0 6px',

    minWidth: '450px',
    padding: '0 !important',
    '&:hover': {
      borderColor: '#1B74B4',
    }
  },
  inputWrapperInput: {
    padding: '0 !important',
    border: 'none !important',
    '& > .MuiInput-input': {
      padding: 8
    }
  },
  searchButton: {
    backgroundColor: '#007F9B',
    border: '2px solid #007F9B',
    borderRadius: '0 4px 4px 0',

    height: '100%',
    minWidth: 'auto',

    padding: 8,
  }
}));
