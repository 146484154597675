import React, { ReactElement, useMemo } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useProcessStepAddMenuStyles from './ProcessStepAddMenu.useStyles';
import StatusIcon from '../StatusIcon';

interface ProcessStepAddMenuProps {
  hide?: boolean;
  open: boolean,
  anchorElement: HTMLElement | null,
  setAnchorElement: (value: HTMLElement | null) => void,
  handleAddCondition: () => void;
  handleAddParallelStepsGroup: () => void;
  handleAddStep: () => void;
  isAddConditionButtonAvailable?: boolean;
  isAddParallelBranchesButtonAvailable?: boolean;
}

export const ProcessStepAddMenu = ({
  hide = false,
  open,
  anchorElement,
  setAnchorElement,
  handleAddCondition,
  handleAddParallelStepsGroup,
  handleAddStep,
  isAddConditionButtonAvailable = true,
  isAddParallelBranchesButtonAvailable = true,
}: ProcessStepAddMenuProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useProcessStepAddMenuStyles();

  const conditionButtonsClassNames = useMemo(() => classNames({
    [classes.menuItemText]: true,
    [classes.menuItemTextDisable]: false
  }), [isAddConditionButtonAvailable]);

  const parallelButtonsClassNames = useMemo(() => classNames({
    [classes.menuItemText]: true,
    [classes.menuItemTextDisable]: false
  }), [isAddParallelBranchesButtonAvailable]);

  const MenuItemList = () => {
    if (hide && !isAddConditionButtonAvailable && !isAddParallelBranchesButtonAvailable) {
      return (
        <MenuItem onClick={() => {
          handleAddStep();
          setAnchorElement(null);
        }}>
          <Box className={classes.menuItem}>
            <StatusIcon />
            <span className={classes.menuItemText}>{t('customProcesses.creationPage.buttons.addStep')}</span>
          </Box>
        </MenuItem>
      )
    }
    return <>
      <MenuItem onClick={() => {
        if (!isAddConditionButtonAvailable) return;
        handleAddCondition();
        setAnchorElement(null);
      }}>
        <Box className={classes.menuItem}>
          <StatusIcon condition size={18} disabled={!isAddConditionButtonAvailable} />
          <span className={conditionButtonsClassNames}>{t('customProcesses.creationPage.buttons.addCondition')}</span>
        </Box>
      </MenuItem>
      <MenuItem onClick={() => {
          if (!isAddParallelBranchesButtonAvailable) return
          handleAddParallelStepsGroup();
          setAnchorElement(null);
        }}>
        <Box className={classes.menuItem}>
          <StatusIcon parallel size={18} disabled={!isAddParallelBranchesButtonAvailable} />
          <span className={parallelButtonsClassNames}>{t('customProcesses.creationPage.buttons.addParallelSteps')}</span>
        </Box>
      </MenuItem>
      <MenuItem onClick={() => {
        handleAddStep();
        setAnchorElement(null);
      }}>
        <Box className={classes.menuItem}>
          <StatusIcon />
          <span className={classes.menuItemText}>{t('customProcesses.creationPage.buttons.addStep')}</span>
        </Box>
      </MenuItem>
    </>
  }

  return (
    <Menu
      anchorEl={anchorElement}
      onClose={() => setAnchorElement(null)}
      open={Boolean(anchorElement) && open}
      MenuListProps={{
        style: {
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      PaperProps={{
        style: {
          border: '1px solid rgba(38, 40, 66, 0.08)',
          boxShadow: '0px 8px 16px rgba(38, 40, 66, 0.04)',
          borderRadius: 10,
          marginTop: 4,
        },
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >

      <MenuItemList />

    </Menu>
  );
};
