import React, { useState, useMemo, ReactElement, useEffect, useRef, useCallback } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import {
  Box,
  Button,
  ClickAwayListener,
  FormControl,
  Popper,
  Tooltip,
  Typography
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import isNil from 'lodash/isNil';

import { DepartmentInfo, UserSelectDepartmentData } from 'pages/TemplateProcesses/TemplateProcesses.types';
import { UserDropdownProps } from 'pages/Task/TaskForm/types';
import { UserInfo } from 'components';
import { UserType } from 'types';
import { useUsersState } from 'store/users';
import useUserDropdownStyles from './UserList.useStyles';

import { getDepartmentsList } from 'api/requests';
import ChevronDown from 'assets/images/icons/chevron_down_regular.svg';
import PersonIcon from 'assets/images/icons/new-user-icon.svg';
import AssigneeField from './AssigneeField';
import { ALL_USERS_KEY, NO_DEPARTMENT_KEY } from './constants';
import FinalActionButtons from './FinalActionButtons';
import DepartmentList from './DepartmentList';
import DepartmentsUserList from './DepartmentsUserList';
import { setSelectedTask } from 'store/approvals';
import { useDispatch } from 'react-redux';
import { useTaskState } from 'store/requests';
import { useUserProfile } from 'hooks';

export const UserList = ({
  allowOnlyOneDepartment = false,
  clearSelectedOnComponentUnmount = true,
  disabled = false,
  allowOnlyOneUser = false,
  handleUsersSelect,
  isPreview,
  label,
  maxUsersCount = null,
  name,
  noEmptyValueText = false,
  placeholder,
  rules,
  showAllDepartmentsGroup = false,
  showAllUsersGroup = true,
  value,
  showWithoutDepartmentView = false,
  selectedTask = null,
  withoutInitiator = false,
}: UserDropdownProps): ReactElement => {
  const { data: bpmTask } = useTaskState();
  const { id: profileId } = useUserProfile();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const classes = useUserDropdownStyles({showWithoutDepartmentView});
  const userListDropdownAnchor = useRef<HTMLElement>();
  const { control, setValue, trigger, formState: {errors = {}} } = useFormContext() || {formState: {}};
  const { users: usersInfo } = useUsersState();
  const [departmentsList, setDepartmentsList] = useState<DepartmentInfo[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedUsersId, setSelectedUsersId] = useState<string[]>([]);
  const [selectedUsersObject, setSelectedUsersObject] = useState<UserType[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>(ALL_USERS_KEY);
  const [currentAssigneeSelectIndex, setCurrentAssigneeSelectIndex] = useState<number>(0);
  const usersListTopRef = useRef(null);
  const departmentsListTopRef = useRef(null);

  const users: { [index: string]: UserType; } = useMemo(() => {
    if (withoutInitiator) {
      const initiatorId = bpmTask.initiator ?? profileId;
      let newUsers = cloneDeep(usersInfo);
      delete newUsers[initiatorId];
      return newUsers;
    }
    return usersInfo;
  }, [usersInfo, withoutInitiator, bpmTask, profileId]);

  const userOptions = useMemo((): UserType[] => Object.values(users), [users]);

  const groupedUserOptions = useMemo(() =>
    userOptions.reduce((acc, user) => {
      const userDepartment = user?.department || NO_DEPARTMENT_KEY;
      if (!Object.keys(acc).includes(userDepartment)) {
        acc[userDepartment] = [];
      }
      acc[userDepartment].push(user);
      return acc;
    }, {}), [userOptions, departmentsList]);

  const departments = useMemo((): UserSelectDepartmentData[] => {
    const departmentsCounts = Object.keys(groupedUserOptions).reduce((acc, key) => {
      const departmentInfo = departmentsList.find(({ id }) => id === key);
      let departmentName = key;
      if (departmentInfo) {
        departmentName = Object.keys(departmentInfo.localization).includes(i18n.language)
                         ? departmentInfo.localization[i18n.language]
                         : departmentInfo.value;
      }
      if (key === NO_DEPARTMENT_KEY) {
        departmentName = t('form_components.select_users.no_department_name', { defaultValue: NO_DEPARTMENT_KEY });
      }
      return {
        ...acc,
        [key]: { key, name: departmentName, selected: 0, total: groupedUserOptions[key]?.length },
      };
    }, {});

    if (selectedUsersId) {
      for (const userId of selectedUsersId) {
        const user = users[userId];
        if (user) {
          const userDepartment = user?.department || NO_DEPARTMENT_KEY;
          departmentsCounts[userDepartment].selected += 1;
        }
      }
    }

    return (Object.values(departmentsCounts) as UserSelectDepartmentData[])
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [groupedUserOptions, selectedUsersId]);

  const noDepartmentInfo = useMemo(() => {
    const departmentInfo = departments?.find(({ key }) => key === NO_DEPARTMENT_KEY);
    if (departmentInfo) {
      return departmentInfo;
    }

    return { total: 0, selected: 0 };
  }, [departments]);

  const handleMenuClose = (resetState = false): void => {
    if (isDropdownOpen) {
      usersListTopRef.current.scrollIntoView();
      !showWithoutDepartmentView && departmentsListTopRef.current.scrollIntoView();
      setIsDropdownOpen(false);
      setHasChanges(false);
      setSelectedDepartment(ALL_USERS_KEY);
      if (resetState) setSearchText('');
    }
  };

  const handleMenuOpen = (): void => {
    if (!disabled) {
      setIsDropdownOpen(true);
      setHasChanges(false);
      setSelectedDepartment(ALL_USERS_KEY);
      setSearchText('');
    }
  };

  const handleDropdownClear = (): void => {
    setSearchText('');
    setIsAllSelected(false);
    setSelectedUsersId([]);
    setValue(name, []);
    setSelectedUsersObject([]);
    handleUsersSelect?.([], []);
  };

  const handleDropdownSubmit = (): void => {
    handleUsersSelect?.(selectedUsersId, selectedUsersObject);
    handleMenuClose();
  };

  const assigneeTooltip = useMemo(() => {
    if (selectedUsersId?.length <= 1) {
      return null;
    }

    const tooltipAssignees = selectedUsersId?.map(userId => users[userId]).filter(Boolean);
    const tooltipAssigneesDividedByDepartments =
      tooltipAssignees?.reduce((acc, assignee): { [key: string]: UserSelectDepartmentData } => {
        const userDepartmentKey = assignee?.department || 'no_department';
        if (!Object.keys(acc).includes(userDepartmentKey)) {
          let departmentName = userDepartmentKey === 'no_department'
                               ? t('form_components.select_users.no_department')
                               : userDepartmentKey;

          const departmentInfo = departmentsList.find(({ id }) => id === userDepartmentKey);
          if (departmentInfo) {
            departmentName = Object.keys(departmentInfo.localization).includes(i18n.language)
                             ? departmentInfo.localization[i18n.language]
                             : departmentInfo.value;
          }

          acc[userDepartmentKey] = {
            key: userDepartmentKey,
            name: departmentName,
            users: [],
          };
        }
        acc[userDepartmentKey].users.push(assignee);
        return acc;
      }, {});

    const tooltipAssigneesSorted = (Object.values(tooltipAssigneesDividedByDepartments || []) as UserSelectDepartmentData[])
      // sort placing 'no department assign' group to the end
      .sort((a, b) => a.key === 'no_department' ? 1 : a.name.localeCompare(b.name))
      .map(department => ({
        ...department,
        users: department.users.sort((a, b) => a.fullName.localeCompare(b.fullName)),
      }));

    const tooltipContent = <Scrollbars style={{ width: 160, height: 160 }} className={classes.tooltipContent}>{
      tooltipAssigneesSorted.map(department => {
        return <>
          <span>{department.name}</span>
          <ul>
            {department.users?.map(user => <li>{user?.fullName}</li>)}
          </ul>
        </>;
      })
    }
    </Scrollbars>;

    return (
      <Tooltip
        arrow
        placement="top"
        title={tooltipContent}
        leaveDelay={500}
        classes={{
          popper: classes.assigneesTooltipPopper,
          tooltip: classes.assigneesTooltip,
        }}>
        <span className={classes.userDropdownValueCount}>(+{tooltipAssignees?.length - 1})</span>
      </Tooltip>);
  }, [selectedUsersId, departmentsList]);

  const usersListTitle = useMemo(() => {
    if (selectedDepartment === ALL_USERS_KEY) {
      return allowOnlyOneDepartment
             ? t('form_components.select_users.all_departments')
             : t('form_components.select_users.all_users');
    }
    if (selectedDepartment === NO_DEPARTMENT_KEY) {
      return t('form_components.select_users.no_department');
    }
    const selectedDepartmentInfo = departments.find(({ key }) => key === selectedDepartment);
    return selectedDepartmentInfo?.name;
  }, [selectedDepartment, departments]);

  useEffect(() => {
    if(!value) return;
    // here probably on old requests can be more than
    // expected count of selected users
    // so we must not limit the count here
    // so user could modify it.
    setSelectedUsersId(value.split(","));
  }, [value])

  useEffect(() => {
    if (isPreview || selectedUsersId?.length === 0) return;
    setValue(name, selectedUsersId);
    if (allowOnlyOneUser) {
      handleMenuClose(true);
    }
  }, [selectedUsersId, isPreview, allowOnlyOneUser])

  useEffect(() => {
    if (selectedTask !== null) {
      setSelectedUsersId(selectedTask.selectedUsersId);
      setSelectedUsersObject(selectedTask.selectedUsersObject);
    }
  }, [selectedTask])

  useEffect(() => {
    getDepartmentsInfo()
      .then()
      .catch((e) => { console.error(`Get departments info has failed: ${e}`) });
  }, [])

  useEffect(() => {
    if(hasChanges) trigger(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanges])

  useEffect(() => {
    return () => {
      if (clearSelectedOnComponentUnmount) {
        dispatch(setSelectedTask({
          selectedUsersId: [], selectedUsersObject: []
        }));
      }
    }
  }, []);

  const getDepartmentsInfo = useCallback(async () => {
    try {
      const departments = await getDepartmentsList();
      setDepartmentsList(departments);
    } catch (e) {
      console.log('Error loading departments list');
    }
  }, [setDepartmentsList]);

  const userFieldClassNames = useMemo(() => classNames('userField', classes.userDropdown, {
    [classes.userDropdownDisabled]: disabled,
    [classes.userDropdownError]: errors[name],
  }), [disabled, errors[name]]);

  const userDropdownIconClassNames = useMemo(() => classNames(classes.userDropdownIcon, {
    [classes.userDropdownActive]: isDropdownOpen
  }), [isDropdownOpen]);

  const errorTextClassNames = useMemo(() => classNames(classes.errorText, 'Mui-error'), []);

  const userDropdownMenuClassNames = useMemo(() => classNames(classes.userDropdownMenu, {
    [classes.userDropdownMenuActive]: isDropdownOpen
  }), [isDropdownOpen]);

  if (isPreview) {
    if (isNil(value) && !noEmptyValueText) {
      return <div>
        <Typography gutterBottom>{label}</Typography>
        <Typography className={classes.emptyValueMessage}>
          {t('form_components.readOnly.emptyValue')}
        </Typography>
      </div>;
    }

    return (
      <Box className={classes.userDropdownFieldWrapper}>
        {label && <Box className={classes.labelWrapper}><label className={classes.label}>{label}</label></Box>}
        <Button variant="contained" color="secondary" className={userFieldClassNames}>
          <Box display="flex" alignItems="center">
            <img src={PersonIcon} className={classes.userDropdownIcon}/>
          </Box>
          {
            value ?
            <Box ml={2} width="100%">
              {(selectedUsersId?.length && !!selectedUsersId[0]) && (
                <Box display="flex" alignItems="center">
                  <UserInfo user={users[selectedUsersId[0]]} avatarSize={20}/>
                  {selectedUsersId?.length > 1 && <Box ml={2}>{assigneeTooltip}</Box>}
                </Box>
              )}
            </Box> : <span className={classes.previewPlaceholder}>{placeholder}</span>
          }

          {!disabled && <img src={ChevronDown} className={userDropdownIconClassNames} alt=""/>}
        </Button>
        {errors[name] && <p className={errorTextClassNames}>{errors[name]?.message}</p>}
      </Box>
    )
  }

  return (
    <ClickAwayListener onClickAway={() => handleMenuClose(true)}>
      <FormControl style={{ width: '100%' }}>
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={(_index) => (
            <AssigneeField
              assigneeTooltip={assigneeTooltip}
              currentAssigneeSelectIndex={currentAssigneeSelectIndex}
              disabled={disabled}
              errors={errors}
              handleMenuOpen={handleMenuOpen}
              index={0}
              isDropdownOpen={isDropdownOpen}
              label={label}
              name={name}
              placeholder={placeholder}
              setCurrentAssigneeSelectIndex={setCurrentAssigneeSelectIndex}
              userListDropdownAnchor={userListDropdownAnchor}
              users={users}
              selectedUsersId={selectedUsersId} />
          )}
        />

        <Popper
          id="user-select-dropdown"
          open
          anchorEl={userListDropdownAnchor.current}
          className={classes.userDropdownMenuPopper}
          placement="bottom-start"
          disablePortal
          keepMounted>
          <Box className={userDropdownMenuClassNames}>
            <Box className={classes.userDropdownMenuHeader}>
              {
                !showWithoutDepartmentView &&
                  <Box className={classes.userDropdownDepartmentsTitleWrapper}>
                    <Typography className={classes.userDropdownHeaderTitle}>
                      {t('form_components.select_users.departments')}
                    </Typography>
                  </Box>
              }
              <Box className={classes.userDropdownUsersListTitleWrapper}>
                <Typography className={classes.userDropdownHeaderTitle}>
                  {usersListTitle}
                </Typography>
              </Box>
            </Box>

            <Box className={classes.userDropdownMenuContent}>
              {
                !showWithoutDepartmentView &&
                  <DepartmentList
                    departments={departments}
                    departmentsListTopRef={departmentsListTopRef}
                    noDepartmentInfo={noDepartmentInfo}
                    selectedDepartment={selectedDepartment}
                    selectedUsersId={selectedUsersId}
                    setSelectedDepartment={setSelectedDepartment}
                    showAllDepartmentsGroup={showAllDepartmentsGroup}
                    showAllUsersGroup={showAllUsersGroup}
                    showWithoutDepartmentView={showWithoutDepartmentView}
                    userOptions={userOptions} />
              }

              <DepartmentsUserList
                allowOnlyOneDepartment={allowOnlyOneDepartment}
                allowOnlyOneUser={allowOnlyOneUser}
                departments={departments}
                groupedUserOptions={groupedUserOptions}
                isAllSelected={isAllSelected}
                handleUsersSelect={handleUsersSelect}
                maxUsersCount={maxUsersCount}
                searchText={searchText}
                selectedDepartment={selectedDepartment}
                selectedUsersId={selectedUsersId}
                setHasChanges={setHasChanges}
                setIsAllSelected={setIsAllSelected}
                selectedUsersObject={selectedUsersObject}
                setSearchText={setSearchText}
                setSelectedUsersId={setSelectedUsersId}
                setSelectedUsersObject={setSelectedUsersObject}
                showAllDepartmentsGroup={showAllDepartmentsGroup}
                showWithoutDepartmentView={showWithoutDepartmentView}
                userOptions={userOptions}
                users={users}
                usersInfo={usersInfo}
                usersListTopRef={usersListTopRef} />
            </Box>

            {!allowOnlyOneUser && <FinalActionButtons
              handleDropdownClear={handleDropdownClear}
              handleDropdownSubmit={handleDropdownSubmit}
              selectedUsersId={selectedUsersId}
              hasChanges={hasChanges} />
            }
          </Box>
        </Popper>
      </FormControl>
    </ClickAwayListener>
  );
};
