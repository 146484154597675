import { CancelToken } from 'axios';
import { v4 as uuid } from 'uuid';

import { baseV1ApiCamundaTimeoutClient, baseV1ApiClient } from '../clients';
import { commonExceptionHandler } from '../handlers';
import { getProfileCompanyDataFromLocalStorage } from 'utils/user';
import { HcmsUserModel, LegalEntity, ProcessDocumentData } from 'types';
import { AdminPanelRequestsFilterValues, ResponseGetAdminPanelRequests } from 'pages/AdminPanel/AdminPanel.types';
import { LOCALSTORAGE_PROFILE_KEY } from '../../hooks/useUserProfile';

export const getProcessDefinitions = async (config?) => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/process-info/secure/V2/process-definition',
      headers: {
        companyId
      },
      cancelToken: config?.cancelToken
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getAllCompanyProcesses = async (config?) => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/process-info/process-definition',
      headers: {
        companyId
      },
      cancelToken: config?.cancelToken
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const searchRequests = async (params) => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/process-info/requests',
      params,
      headers: {
        companyId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getRegisterCsv = async (params) => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/process-info/export/register',
      params,
      headers: {
        companyId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getEntityInstanceById = async (id: number) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/tab',
      params: {
        id
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const createEntityInstance = async (params: {
  processInstanceId: string;
  sysName: string;
}) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: '/bpm-main-api/tab/add',
      params
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const updateEntityInstance = async (tabDTO) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'PUT',
      url: '/bpm-main-api/tab',
      data: tabDTO
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const deleteEntityInstance = async (id: number) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'DELETE',
      url: '/bpm-main-api/tab',
      params: {
        id
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const createTemplate = async ({
  title,
  businessInstanceDTO,
  processSysName,
  processDefinitionId,
  processDefinitionKey,
  processDefinitionName,
  urgentParameters
}) => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiCamundaTimeoutClient.request({
      method: 'POST',
      url: '/bpm-main-api/process-info/template',
      data: {
        id: `${Date.now() + Math.floor(Math.random() * 100)}`,
        title,
        businessInstanceDTO,
        isStartableInTasklist: true,
        processSysName,
        processDefinitionId,
        processDefinitionKey,
        processDefinitionName,
        ...urgentParameters
      },
      headers: {
        companyId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const deleteTemplateById = (id) => {
  return baseV1ApiCamundaTimeoutClient.request({
    method: 'DELETE',
    url: '/bpm-main-api/process-info/template',
    params: {
      id
    }
  });
};

export const getMyTemplates = async () => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiCamundaTimeoutClient.request({
      method: 'GET',
      url: '/bpm-main-api/process-info/template',
      headers: {
        companyId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getMyRequests = async (data: any) => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiCamundaTimeoutClient.request({
      method: 'POST',
      url: '/bpm-main-api/process-info/my-requests',
      data,
      headers: {
        companyId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getMyRequestsCount = async (params: {
  [key: string]: string | number | boolean;
}) => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiCamundaTimeoutClient.request({
      method: 'GET',
      url: '/bpm-main-api/process-info/my-requests/count',
      params,
      headers: {
        companyId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getReviewedApprovalsCount = async (params: {
  [key: string]: string | number | boolean;
}) => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiCamundaTimeoutClient.request({
      method: 'GET',
      url: '/bpm-main-api/process-info/my-completed-tasks/count',
      params,
      headers: {
        companyId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const draftsMassDeleteRequest = async ({
  data
}: {
  data: {
    processInstanceId: string[];
  };
}): Promise<string> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'DELETE',
      url: '/bpm-main-api/taskController/massDelete',
      data
    });

    return response.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const updateDraftInstance = async (
  processInstanceId: string,
  data: { [key: string]: unknown }
) => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiCamundaTimeoutClient.request({
      method: 'PUT',
      url: '/bpm-main-api/process-info/draft',
      data,
      params: {
        processInstanceId
      },
      headers: {
        companyId
      }
    });

    return response;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getGoogleDriveAccessToken = async () => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/process-info/camunda/access'
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getApprovalsRequests = async ({ params }) => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiCamundaTimeoutClient.request({
      method: 'POST',
      url: '/bpm-main-api/process-info/approvals/list',
      data: {
        ...params,
        archive: false,
        completed: false,
        pending: true,
        taskCompleted: false,
      },
      headers: {
        companyId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getTaskHistoryV2 = async (processInstanceId) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/process-info/tasks/history',
      params: {
        processInstanceId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getTaskHistoryStructured = async (processInstanceId) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: 'bpm-main-api/process-info/tasks/historyV2',
      params: {
        processInstanceId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getReviewedApprovalsRequests = async ({ params }) => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiCamundaTimeoutClient.request({
      method: 'POST',
      url: '/bpm-main-api/process-info/my-completed-tasks',
      data: {
        ...params,
        archive: false
      },
      headers: {
        companyId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getMonitoringListRequest = async ({ params }) => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();
    const profile = JSON.parse(localStorage.getItem(LOCALSTORAGE_PROFILE_KEY));
    const response = await baseV1ApiCamundaTimeoutClient.request({
      method: 'POST',
      url: '/bpm-main-api/process-info/watcher-requests',
      data: {
        ...params,
        userId: profile.id,
        archive: false
      },
      headers: { companyId }
    });
    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

const OBSERVERS_ENDPOINT = '/bpm-main-api/process-info/process/observers';

export const getProcessWatchers = async (processInstanceId: string) => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();
    const response = await baseV1ApiCamundaTimeoutClient.request({
      method: 'GET',
      url: OBSERVERS_ENDPOINT,
      params: { processInstanceId },
      headers: { companyId }
    });
    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const makeProcessUrgent = async ({ processInstanceId, urgent, reason }) => {
  try {
    const response = await baseV1ApiCamundaTimeoutClient.request({
      method: 'POST',
      url: `/bpm-main-api/process-info/tasks/urgent`,
      params: {
        processInstanceId,
        urgent,
        reason
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const takeOnATask = async (params: {
  taskId: string;
  variable: string;
}) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'PUT',
      url: '/bpm-main-api/process-definition/task/assignee',
      params
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const deleteTask = (params: { processInstanceId: string }) => {
  return baseV1ApiClient.request({
    method: 'DELETE',
    url: '/bpm-main-api/process-definition/task/delete',
    params
  });
};

export const completeTask = (params, data) => {
  const { id: companyId } = getProfileCompanyDataFromLocalStorage();

  return baseV1ApiCamundaTimeoutClient.request({
    method: 'PUT',
    url: '/bpm-main-api/process-definition/tasks/complete',
    data,
    params,
    headers: {
      companyId
    }
  });
};

export const saveTaskState = (taskId, values) => {
  return baseV1ApiCamundaTimeoutClient.request({
    method: 'POST',
    url: '/bpm-main-api/process-definition/instance/update',
    data: values,
    params: {
      taskId
    }
  });
};

export const sendComment = async (data: {
  processInstanceId: string;
  author: string;
  text: string;
  date: Date;
  initiatorOfTask: string;
  taggedUsers?: string[];
  assignee?: string;
  approveStage: string;
  action?: string;
  processStep: string;
}) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: '/bpm-main-api/comment',
      data
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getRequestComments = async (processInstanceId: string) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/comment/list',
      params: {
        processInstanceId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const completeAllTasks = async (data) => {
  try {
    const response = await baseV1ApiCamundaTimeoutClient.request({
      method: 'PUT',
      url: '/bpm-main-api/process-definition/tasks/mass/complete',
      data
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const cancelProcess = async (data: {
  taskId: string;
  processInstanceId: string;
  cancelReason: string;
}) => {
  try {
    const response = await baseV1ApiCamundaTimeoutClient.request({
      method: 'PUT',
      url: '/bpm-main-api/process-definition/cancel',
      data
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

interface StartProcessProps {
  params: {
    creator: string | null;
    id: string;
    processTemplate?: boolean;
  },
  data: {
    processId: string;
    processDefinitionName: string;
    variables?: {[key: string]: any}
  },
  abortSignal?: AbortSignal
}

export const startProcessById = async (props: StartProcessProps): Promise<string> => {
  const { params, data, abortSignal } = props;
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: '/bpm-main-api/process-definition/V2/start',
      params,
      data: {
        ...data,
        businessKey: uuid(),
        variables: data?.variables || {}
      },
      headers: {
        companyId
      },
      signal: abortSignal
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getHistoryTask = async (taskId: string) => {
  try {
    const response = await baseV1ApiCamundaTimeoutClient.request({
      method: 'GET',
      url: '/bpm-main-api/process-info/history',
      params: {
        taskId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getHistoryTaskByProcessInstanceId = async (processInstanceId: string) => {
  try {
    const response = await baseV1ApiCamundaTimeoutClient.request({
      method: 'GET',
      url: '/bpm-main-api/process-info/history/assigneeStepTask',
      params: {
        processInstanceId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getInstance = async (id) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/instances',
      params: {
        id
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const createInstance = async ({
  params,
  cancelToken
}: {
  params: {
    bsnTypeId: string;
    taskId: string;
    applicationNumber: string;
  };
  cancelToken?: CancelToken;
}) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: '/bpm-main-api/instances',
      params,
      cancelToken
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const updateInstance = async ({
  params,
  data,
  cancelToken
}: {
  params: {
    taskId: string;
  };
  data: any;
  cancelToken?: CancelToken;
}) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'PUT',
      url: '/bpm-main-api/instances',
      data,
      params,
      cancelToken
    });

    return response;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getCompanyAccessAnswer = async (): Promise<boolean> => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/user/company/access',
      params: {
        company: companyId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getStartAllowedAnswer = async (
  processSysName: string,
  abortSignal?: AbortSignal
): Promise<{
  admin: boolean;
  message: string;
  startAllowed: boolean;
  admins: HcmsUserModel[];
}> => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/process-definition/checkStartAccess',
      params: {
        processSysName,
        companyId
      },
      signal: abortSignal
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getAllAvailableCompanies = async (): Promise<{
  [key: string]: any;
}> => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/hcms/companies/secure',
      headers: {
        companyId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getChildrenCompanies = async (): Promise<LegalEntity[]> => {
  try {
    const { id: parentId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/hcms/companies/byParentId',
      params: {
        parentId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const updateTaskReadStatus = async (taskId: string) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/rest/process/badge/taskRead',
      params: {
        taskId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const updateTaskCommentsReadStatus = async (taskId: string) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/rest/process/badge/commentRead',
      params: {
        taskId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const updateTaskAttachmentsReadStatus = async (taskId: string) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/rest/process/badge/attachmentRead',
      params: {
        taskId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const setTaskAttachmentsUnreadStatus = async (taskId: string) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/rest/process/badge/attachmentAddBadge',
      params: {
        taskId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getRequestAssigneesList = async (processInstanceId: string) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/bpm-main-api/process-info/past/task/history/users',
      params: {
        processInstanceId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getAdminPanelRequests = async (data: AdminPanelRequestsFilterValues, signal: AbortSignal): Promise<ResponseGetAdminPanelRequests> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: '/bpm-main-api/taskController/tasks/allActive',
      data,
      signal
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const deleteAdminPanelRequest = async (processInstanceId: string) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'DELETE',
      url: '/bpm-main-api/taskController/delete',
      params: {
        processInstanceId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const massDeleteAdminPanelRequest = async (processInstanceId: string[]) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'DELETE',
      url: '/bpm-main-api/taskController/massDelete',
      data: {
          processInstanceId
        }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const updateAssignee = async ({
  taskId,
  assigneeId
}: {
    taskId: string;
    assigneeId: string;
}) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'PUT',
      url: '/bpm-main-api/taskController/changeAssignee',
      params: {
        taskId,
        assigneeId
      },
    });

    return response;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const massUpdateAssignee = async (list: {
    taskId: string;
    userId: string;
}[]) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'PUT',
      url: '/bpm-main-api/taskController/changeListAssignee',
      data: list,
    });

    return response;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};


export const getHistoryStepsForRework = async (processInstanceId: string, currentStepOrder : number) => {
  try {
    const response = await baseV1ApiCamundaTimeoutClient.request({
      method: 'GET',
      url: '/bpm-main-api/process-info/tasks/history/rework',
      params: {
        processInstanceId,
        currentStepOrder
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const checkWorkingCalendarExistence = async (): Promise<{boolean}> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: `bpm-main-api/hcms/companies/calendar/flexible`,
      params: {
        year: (new Date()).getFullYear()
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const getProcessDocuments = async (processInstanceId: string): Promise<ProcessDocumentData[]> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: `bpm-main-api/docflow/tabs/all`,
      params: {
        processInstanceId,
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const fillDocumentWithFormData = async (documentId: number, processInstanceId: string, values: any) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'POST',
      url: `bpm-main-api/docflow/tabs/replace/fields`,
      params: {
        documentId,
        processInstanceId
      },
      data: values
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const getDocument = async (documentId: number) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: `docflow/documents/${documentId}`,
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}

export const getDocumentAttachments = async (attachmentId: number) => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: `docflow/documents/attachments/${attachmentId}`
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
}
