import styled from 'styled-components';
import { theme } from '@dartech/dms-ui';

const AttachmentsUpload = styled.div`
  .AttachmentsUploadBigWithoutFiles {
    position: relative;
    width: 100%;
    height: 240px;
    border-radius: 4px;

    & .ContentWrapper {
      text-align: center;
      padding-top: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & .TextWrapper {
        margin-bottom: 24px;
      }
    }
  }
  .AttachmentsUploadLowHeight {
    width: 100%;
    min-height: 80px;
    height: 80px;
    background: ${theme.palette.grey[100]};
    border-radius: 4px;

    & .ContentWrapper {
      height: 100%;
      min-height: 80px;
      margin-left: 20px;
      margin-right: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & .TextWrapper {
        text-align: left;

        & .MainText {
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          margin-bottom: 4px;
        }

        & .SecondaryText {
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 0;
        }
      }
    }
  }
  .TextWrapper {
    text-align: center;
  }
  .MainText {
    margin-top: 0;
    margin-bottom: 8px;
    font-family: Golos, Helvetics, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${theme.palette.grey[700]};
  }
  .SecondaryText {
    margin-top: 0;
    margin-bottom: 0;
    font-family: Golos, Helvetics, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    max-width: 320px;
    color: ${theme.palette.grey[500]};
  }
  .AttachmentsUploadBig.AttachmentsUploadWithoutFiles {
    text-align: center;
    paddingtop: 70px;
  }
  .AttachmentsUploadBig.AttachmentsUploadWithFiles {
    text-align: left;
  }
  .Error {
    width: 100%;
    bottom: 42px;
    font-size: 12px;
    text-align: left;
    padding: 5px 0;
    color: ${theme.palette.error[600]};
  }
  .AttachmentUploadCompact {
    width: 608px;
    background: ${theme.palette.grey[100]};
    border: 1px dashed #b7c3cc;
    border-radius: 4px;

    &:hover {
      border: 2px ${theme.palette.primary[200]} solid;
      margin: -1px;
    }
  }
  .AttachmentsUploadDynamicLowHeight {
    min-height: 80px !important;
    height: fit-content !important;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .AttachmentsUploadCompactWithError {
    border: 2px ${theme.palette.error[600]} solid;
    margin: -1px;

    &:hover {
      border: 2px ${theme.palette.primary[600]} solid;
    }
  }

  .AttachmentUploadCompactDashed {
    min-height: 46px;
    width: 100%;
    border: none;
    border-radius: 0;
    background-color: #F5F8FA;
    border: 1px dashed rgba(38, 40, 66, 0.12);
    border-radius: 12px;

    &:hover {
      border: 1px dashed rgba(38, 40, 66, 0.12);
      margin: 0;
    }

    .ContentWrapperCompactDashed {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      min-height: 46px;
      padding: 20px 12px;

      .TextWrapperCompactDashed {
        display: flex;
        align-items: center;
        gap: 12px;

        .MainText {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          color: ${theme.palette.grey[700]};
          margin-bottom: 8px;
        }

        .SecondaryText: {
          margin-top: 8px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #8A96A1;
        }
      }

      .ButtonWrapperCompactDashed {
        flex-shrink: 0;

        .ButtonCompactDashed {
          background: #FFFFFF;
          border: 1px solid #CED7DF;
          color: #101F2B;
          font-weight: 500;
          line-height: 1.1;
          border-radius: 8px;

          .ButtonCompactDashedIcon {
            height: 18px;
            margin-right: ${theme.spacing(3)}px;
          }
        }
      }
    }
  }

  .AttachmentUploadCompactDashedWithFiles {
    height: fit-content;

    .ContentWrapperCompactDashed {
      flex-direction: row !important;

      .TextWrapperCompactDashed {
        display: flex;
        gap: 12px;
        margin-bottom: 0 !important;
        text-align: left;

        .MainText {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: ${theme.palette.grey[700]};
          margin-bottom: 4px;
        }
      }
    }
  }
`;

export const Styled = {
  AttachmentsUpload,
};
