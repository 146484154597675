import { makeStyles } from '@material-ui/core';

export default makeStyles(({ palette, spacing }) => ({
  drawer: {
    width: 500,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(5),
    minHeight: 64,
  },
  headerColorInfo: {
    background: palette.primary[700],
    color: palette.common.white,
  },
  headerColorDanger: {
    background: palette.error[400],
    color: palette.common.white,
  },
  headerColorNeutral: {
    background: palette.common.white,
    color: '#465564',
  },
  title: {
    fontSize: 24,
    fontWeight: 400,
  },
  footer: {
    minHeight: 64,
    padding: spacing(5, 4),
    backgroundColor: palette.grey[100],
    borderTop: `1px solid ${palette.grey[300]}`,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  hiddenForm: {
    visibility: 'hidden'
  }
}));
