import {makeStyles} from "@material-ui/core/styles";

export default makeStyles(({ spacing }) => ({
  processStepTag: {
    backgroundColor: '#EBF5FC',
    borderRadius: spacing(2),
    padding: spacing(1, 3),

    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.3,
    color: '#497CF6'
  },
  processStepActive: {
    backgroundColor: '#FFEDFB',
    color: '#BE385E'
  },
  processStepCondition: {
    backgroundColor: '#F0EDFF',
    color: '#8754F6'
  },
  processStepParallelStepsGroup: {
    backgroundColor: '#EBFCEF',
    color: '#3B854B'
  },
  processStepIntegrated: {
    backgroundColor: '#F0F0F7',
    color: '#262842'
  },
  processStepSizeSmall: {
    fontSize: 12
  }
}));
