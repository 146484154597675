import React, { useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import useTaskDocuments from './useTaskDocuments';
import DocumentCard from '../../../../DocumentsView/DocumentCard';

import useStyles from './useStyles';
import { useTaskState } from '../../../../../store/requests';
import { DocumentDetails } from '@dar-dms/utils';
import { useUserProfile } from '../../../../../hooks';

type Props = {
  className?: string;
  activeTab?: string;
  formMethods?: any;
  checkValidationErrors?: () => boolean;
  setErrorMessageLines?: (value: ('fields' | 'documents')[]) => void;
};

export const DocumentsTab = ({ className, activeTab, formMethods, checkValidationErrors, setErrorMessageLines}: Props) => {
  const { t } = useTranslation();
  const { data: bpmTask } = useTaskState();
  const { id } = useUserProfile();
  const {
    groupedDocuments,
    currentLoadingDocumentId,
    currentDocumentId,
    documentViewMode,
    setCurrentDocumentId,
    setCurrentLoadingDocumentId,
    documentsForSignature,
    signedDocuments,
    isSignatureAvailable,
    openDocumentForSignWithValidation,
    openDocumentForPreviewWithValidation,
    downloadDocument,
    changeCurrentDocument,
    onSignComplete,
    beforeDocumentSign,
    handleDocumentClose,
  } = useTaskDocuments({
    isActiveTab: activeTab === 'documents',
    formMethods,
    checkValidationErrors,
    setErrorMessageLines,
  });

  const classes = useStyles();

  return (
    <div className={cn(className, classes.tabContentWrapper)}>
      {
        groupedDocuments?.sign.length > 0 && <div className={classes.documentsListSection}>
          <h3 className={classes.documentsListSectionTitle}>
            {t('new_request.summary.documents.signingRequired')}
          </h3>
          <div className={classes.documentsListWrapper}>
            {
              groupedDocuments?.sign?.map(document =>
                <DocumentCard
                  documentId={document.docflowDocumentId}
                  documentName={document.name}
                  fileType={'pdf'}
                  size={document.size}
                  creationDate={document.dataCreate}
                  stepName={t(`constructor-${bpmTask.processSysName}.actions.${bpmTask.currentAction.sysName}.name`, { defaultValue: bpmTask.currentAction.name })}
                  needSignature={bpmTask.isAssignee(id, false) && isSignatureAvailable}
                  isSigned={signedDocuments.includes(document?.docflowDocumentId) || document?.isSigned}
                  isFullySigned={document.signings.every(signing => signing.status || (signing.stepOrder === bpmTask.currentAction.stepperOrder && document.isSigned))}
                  hasSignatures={document.isSigned || document.signings.some(v => v.status)}
                  isPublished={document?.isPublished}
                  isLoading={document.docflowDocumentId === currentLoadingDocumentId}
                  onSign={openDocumentForSignWithValidation}
                  onPreview={openDocumentForPreviewWithValidation}
                  onDownload={downloadDocument}
                />,
              )
            }
          </div>
        </div>
      }

      {currentDocumentId >= 0 &&
        <DocumentDetails
          id={currentDocumentId}
          isPreview={documentViewMode === 'view'}
          documentsList={documentsForSignature.includes(currentDocumentId || currentLoadingDocumentId) ? documentsForSignature : []}
          signedDocumentsList={signedDocuments}
          handleCurrentDocumentChange={changeCurrentDocument}
          onSignSuccess={onSignComplete}
          beforeSign={beforeDocumentSign}
          onClose={handleDocumentClose}
        />
      }

      {
        groupedDocuments?.view.length > 0 && <div className={classes.documentsListSection}>
          <h3 className={classes.documentsListSectionTitle}>
            {t('new_request.summary.documents.noSignature')}
          </h3>
          <div className={classes.documentsListWrapper}>
            {
              groupedDocuments?.view?.map(document =>
                <DocumentCard
                  documentId={document.docflowDocumentId}
                  documentName={document.name}
                  fileType={'pdf'}
                  size={document.size}
                  creationDate={document.dataCreate}
                  stepName={t(`constructor-${bpmTask.processSysName}.actions.${bpmTask.currentAction.sysName}.name`, { defaultValue: bpmTask.currentAction.name })}
                  isLoading={document.docflowDocumentId === currentLoadingDocumentId}
                  needSignature={false}
                  hasSignatures={document.isSigned || document.signings.some(v => v.status)}
                  isSigned={false}
                  isFullySigned={document.signings.every(signing => signing.status || (signing.stepOrder === bpmTask.currentAction.stepperOrder && document.isSigned))}
                  isPublished={document?.isPublished}
                  onPreview={openDocumentForPreviewWithValidation}
                  onDownload={downloadDocument}
                />,
              )
            }
          </div>
        </div>
      }
    </div>
  );
};
