import React from 'react';

import useStyles from './index.styles';
import { Typography } from '@material-ui/core';

import ErrorTriangleWhiteIcon from '../../assets/images/icons/error_triangle_white_icon.svg';
import SuccessRoundGreenIcon from '../../assets/images/icons/success_round_green_icon.svg';
import CloseCrossWhiteIcon from '../../assets/images/icons/close_cross_white_icon.svg';

interface MassAppoveLoaderProps {
  statusText: string;
  percentage: number;
  showErrorIcon: boolean;
  showSuccessIcon: boolean;
  onClose: () => void;
}

const MassApproveLoader = ({statusText, percentage, showErrorIcon, showSuccessIcon, onClose}: MassAppoveLoaderProps) => {
  const classes = useStyles({percentage, showErrorIcon, showSuccessIcon})();

  const Content = () => {
    if (showErrorIcon || showSuccessIcon) {
      return (
        <div className={classes.blockWrapper}>
          <div className={classes.messageWrapper}>
            {
              showErrorIcon &&
              <img className={classes.errorIcon} src={ErrorTriangleWhiteIcon} alt="error icon"/>
            }
            {
              showSuccessIcon &&
              <img className={classes.successIcon} src={SuccessRoundGreenIcon} alt="success icon"/>
            }
            <Typography className={classes.statusText} variant="body1">{statusText}</Typography>
          </div>
          <img onClick={onClose} className={classes.closeIcon} src={CloseCrossWhiteIcon} alt="close icon"/>
        </div>
      )
    }
    return (
      <>
        <Typography className={classes.statusText} variant="body1">{statusText}</Typography>
        <div className={classes.loadTracker}>
          <div className={classes.loadTrackerBar} />
        </div>
      </>
    )
  }

  return (
    <div className={classes.massApproveLoader}>
      <Content />
    </div>
  )
}

export default MassApproveLoader;
