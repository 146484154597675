import React from 'react';

import { CircularProgress } from '@material-ui/core';

const FileViewLoader = ({children, actionLoading, isLoading}) => {
  if (isLoading && actionLoading) {
    return (
      <div className="fileViewLoader">
        <CircularProgress size={25} className="Spinner" />
      </div>
    )
  }
  return children;
};

export default FileViewLoader;