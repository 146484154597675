import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, IconButton, Tooltip } from '@material-ui/core';
import cn from 'classnames';

import useDocumentFieldCardStyles from './DocumentParamsForm.useStyles';
import { ReactComponent as CheckboxUncheckedIcon } from 'assets/images/icons/checkbox-unchecked.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/images/icons/checkbox-checked.svg';
import DragIndicatorVertical from 'assets/images/icons/drag-indicator-vertical.svg';
import LinkIcon from 'assets/images/icons/link_regular.svg';
import LinkIconEdit from 'assets/images/icons/link_edit_regular.svg';
import LinkDisabledIcon from 'assets/images/icons/link_dismiss_regular.svg';
import { FIELDS } from '../../../../ProcessSetupSidePanel.constants';
import { AllProcessFieldsListItem } from '../../../../../../TemplateProcesses.types';

interface DocumentFieldCardProps {
  name: string;
  type: string;
  linkedProcessFieldId: string;
  allProcessStepsFields: AllProcessFieldsListItem[];
  isSelected?: boolean;
  hasFieldsToLink: boolean;
  onLinkButtonClick: () => void;
  onSelect?: () => void;
  dragHandleProps: any,
}

export const DocumentFieldCard = ({
  name,
  type,
  onSelect,
  linkedProcessFieldId,
  allProcessStepsFields,
  hasFieldsToLink,
  dragHandleProps,
  onLinkButtonClick,
  isSelected = false,
}: DocumentFieldCardProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useDocumentFieldCardStyles();

  const linkedFieldData = useMemo(() => {
    return allProcessStepsFields.find(item => item.field.id === linkedProcessFieldId);
  }, [allProcessStepsFields, linkedProcessFieldId]);

  return (
    <div className={classes.cardWrapper}>
      <div className={cn(classes.card, { [classes.linkedFieldCard]: linkedFieldData })}>
        <div className={classes.dragAndDropIndicatorWrapper}>
          <img src={DragIndicatorVertical} alt="" {...dragHandleProps}/>
        </div>

        <div className={classes.checkboxWrapper}>
          <Checkbox
            icon={<CheckboxUncheckedIcon/>}
            checkedIcon={<CheckboxCheckedIcon/>}
            color="default"
            className={cn(classes.checkbox)}
            classes={{ disabled: classes.checkboxDisabled, checked: classes.checkboxChecked }}
            checked={isSelected}
            onChange={() => onSelect()}
          />
        </div>

        <div className={classes.content}>
        <span className={classes.name}>
          {name}
        </span>

          <span className={classes.type}>
          {t(FIELDS?.find(v => v.key === type)?.title, { defaultValue: type })}
        </span>
        </div>

        {linkedFieldData &&
          <div className={classes.linkedFieldIndicator}>
          <span className={classes.linkedFieldChip}>
            {
              linkedFieldData.field.documentId
              ? t('customProcesses.documentsForm.documentFieldStatus.addedOnStep', { stepOrder: linkedFieldData.firstAppearanceStep })
              : t('customProcesses.documentsForm.documentFieldStatus.linked')
            }
          </span>
          </div>
        }
      </div>

      <IconButton onClick={onLinkButtonClick}>
        <Tooltip
          title={t(hasFieldsToLink
                   ? 'customProcesses.documentsForm.linkButton.notLinked'
                   : 'customProcesses.documentsForm.linkButton.noFieldsError'
          )}
          placement="bottom"
          arrow
          enterDelay={500}
          disableHoverListener={!!(hasFieldsToLink && linkedFieldData)}
          disableTouchListener={!!(hasFieldsToLink && linkedFieldData)}
          disableFocusListener={!!(hasFieldsToLink && linkedFieldData)}
          classes={{ popper: classes.cardTooltipPopper, tooltip: classes.cardTooltip }}
        >
          <img
            src={hasFieldsToLink
                 ? linkedFieldData
                   ? LinkIconEdit
                   : LinkIcon
                 : LinkDisabledIcon}
            alt="link"/>
        </Tooltip>
      </IconButton>
    </div>
  );
};
