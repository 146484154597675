import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing, palette }) => ({
  formRoot: {
    width: 410,
    maxWidth: 410,
    paddingBottom: 48,
  },
  formTitle: {
    margin: 0,
    padding: 0,
    color: '#101F2B',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '22px',
  },
  previewButton: {
    width: 'fit-content',
    padding: 0,
    color: '#007994',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
  },
  documentInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  fieldLabel: {
    color: '#6D6E85',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
    marginBottom: 4,
  },
  fieldText: {
    color: '#262842',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
  },
  section: {
    marginBottom: 32,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  sectionTitle: {
    margin: 0,
    padding: 0,
    color: '#262842',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
  },
  sectionInfoMessage: {
    margin: 0,
    padding: 0,
    color: '#262842',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
  },
  fieldsList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
  },
  fieldsListControls: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fieldsListControlsLeftSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  fieldsListControlsRightSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  fieldsActionButton: {

  },
  fieldsListDraggableItem: {
    width: '100%',
  },
  signatureUnavailableBlock: {
    padding: 12,
    width: '100%',
    borderRadius: 12,
    border: '1px solid var(--Light-Outline-2, rgba(38, 40, 66, 0.12))',
    background: '#FCF8EB',
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    width: '100%'
  },
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '4px 8px 4px 0px',
    borderRadius: 8,
    border: '1px solid var(--Light-Outline-2, rgba(38, 40, 66, 0.12))',
    background: '#FFF',
    flexGrow: 1
  },
  linkedFieldCard: {
    background: '#F7F8FC',
  },
  dragAndDropIndicatorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 4,
    '& img': {
      width: 20,
      height: 20,
    },
  },
  checkboxWrapper: {},
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexGrow: 1,
  },
  name: {
    color: '#262842',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
  },
  type: {
    color: '#8B8C9E',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
  },
  checkbox: {
    marginRight: 8,
    '& path': {
      fill: '#8B8C9E !important'
    }
  },
  checkboxIndeterminate: {
    '& path': {
      fill: '#007994 !important'
    }
  },
  checkboxChecked: {
    '& path': {
      fill: '#007994 !important'
    }
  },
  checkboxDisabled: {
    '& path': {
      fill: '#2628421F !important'
    }
  },
  linkedFieldIndicator: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 4,
  },
  linkedFieldChip: {
    padding: '4px 6px',
    borderRadius: 6,
    background: '#F0F0F7',
  },
  signatureToggleWrapper: {

  },
  toggle: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#262842',
    width: 'fit-content',
    '& input + span': {
      marginTop: -1,
      marginLeft: -1
    }
  },
  switchTrackChecked: {
    backgroundColor: '#2DB77B !important',
    borderColor: '#2DB77B !important',
    '&:hover': {
      backgroundColor: '#2DB77B !important',
      borderColor: '#2DB77B !important',
    }
  },
  switchTrackUnchecked: {
    backgroundColor: '#F0F0F7 !important',
    borderColor: '#F0F0F7 !important',
    '&:hover': {
      backgroundColor: '#F0F0F7 !important',
      borderColor: '#F0F0F7 !important',
    }
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: spacing(3),
    width: 410,
    maxWidth: 410,
  },
  button: {
    height: 40,
    padding: spacing(2.5, 4),
    borderRadius: spacing(2.5)
  },
  processStepFormErrorMessage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: spacing(1),
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#D6331F',
    '& img': {
      marginRight: spacing(1.25),
      height: 13,
      width: 13,
    },
  },
  cardTooltipPopper: {
    pointerEvents: 'all',
  },
  cardTooltip: {
    color: '#F7F8FC',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    maxWidth: 240,
    width: 'fit-content',
    padding: '8px 12px',
    borderRadius: 10,
  },
}));
