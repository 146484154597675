import {makeStyles} from "@material-ui/core";

const circle = {
  width: 10,
  height: 10,

  borderRadius: '50%',
}

export default makeStyles(({ spacing }) => ({
  root: {
    padding: "0px",
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  select: {
    marginTop: 20
  },
  datePicker: {
    "& label": {
      fontWeight: "600 !important"
    }
  },
  published: {
    ...circle,

    background: "#2DB77B"
  },
  not_published: {
    ...circle,

    background: "#99A5B1"
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 48,
    padding: '14px 16px',
    borderBottom: '1px solid rgba(38, 40, 66, 0.08);',

    '& h3': {
      color: '#262842',
      fontSize: 16,
      margin: 0,
    }
  },
  content: {
    height: "100%",
    overflowY: "scroll",
    paddingBottom: 20,
  },
  footer: {
    height: 56,

    background: '#F7F8FC',
    borderTop: '1px solid rgba(38, 40, 66, 0.08)',

    padding: '12px 16px'
  },
}));
