import React, { memo } from 'react';

import { Typography, List, ListItem } from '@material-ui/core';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';

import useReadOnly from './useReadOnly';

import useStyles from '../../useStyles';
import useReadOnlyStyles from './useStyles';

type Props = {
  name?: string;
  hint?: string;
  type?: string;
  component?: string;
  isTaskDetailsVariant?: boolean;
  params: { [key: string]: string };
  value?: unknown;
  titleVariant?: boolean;
  systemVariableName?: string;
  profileCompanyFieldName?: string;
  isGlossaryValue?: boolean;
  isSelectGlossaryValueSavedById?: boolean;
  isValueFromValuesList?: boolean;
  showCustomValue?: boolean;
  displayValue?: string;
};

export const ReadOnly = memo(({
  name,
  hint,
  type,
  component,
  isTaskDetailsVariant = false,
  titleVariant = false,
  params,
  value,
  systemVariableName = '',
  profileCompanyFieldName = '',
  isGlossaryValue = false,
  isSelectGlossaryValueSavedById = false,
  isValueFromValuesList = false,
  showCustomValue = false,
  displayValue = ''
}: Props) => {
  const classes = useStyles();
  const readOnlyClasses = useReadOnlyStyles();
  const {
    register
  } = useFormContext();
  const {t} = useTranslation();

  const {
    text
  } = useReadOnly({
    name,
    type,
    component,
    params,
    value,
    systemVariableName,
    profileCompanyFieldName,
    isGlossaryValue,
    isSelectGlossaryValueSavedById,
    isValueFromValuesList,
    showCustomValue,
    displayValue
  });
  
  if (isNil(value)) {
    return <div>
      <Typography>{hint}</Typography>
      <Typography className={classes.emptyValueMessage}>
        {t('form_components.readOnly.emptyValue')}
      </Typography>
    </div>;
  }

  return (
    <div>
      {!!hint && <Typography>{hint}</Typography>}
      {component === 'readonly-list' ? (
        <List disablePadding>
          {Array.isArray(text) ? (
            text.map((str) => (
              <ListItem key={str} dense>
                <Typography
                  color="primary"
                  className={cn({
                    [classes.taskDetailsReadOnly]: isTaskDetailsVariant,
                  })}
                >
                  {str}
                </Typography>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <Typography
                color="primary"
                className={cn({
                  [classes.taskDetailsReadOnly]: isTaskDetailsVariant,
                })}
              >
                {text.length === 0 ? value : text}
              </Typography>
            </ListItem>
          )}
        </List>
      ) : (
        <Typography
          color="primary"
          className={cn({
            [classes.taskDetailsReadOnly]: isTaskDetailsVariant,
            [readOnlyClasses.taskDetailsReadOnlyWithoutHint]:
              isTaskDetailsVariant && !hint,
            [readOnlyClasses.titleVariant]: titleVariant,
          })}
        >
          <span>{text.length === 0 ? value : text}</span>
        </Typography>
      )}
      <input type="hidden" data-selenium={name} {...register(name)} />
    </div>
  );
});
