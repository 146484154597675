import React, { ReactElement, useMemo, useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useDocumentsListStyles from './DocumentsListFilters.useStyles';

import SearchIcon from 'assets/images/icons/search-icon-thin.svg';
import {
  TreeStructureNode
} from '../../../../../../../Task/TaskForm/FormFields/Fields/Glossary/TreeGlossaryMultiSelect';
import { FiltersGlossaryInput } from './FiltersGlossaryInput';

interface DocumentsListProps {
  searchValue: string;
  department: string;
  directories: TreeStructureNode[];
  type: string;
  modalVariant?: boolean;
  onSearchChange: (value: string) => void;
  onDepartmentSelect: (value: string) => void,
  onTypeSelect: (value: string) => void,
}

export const DocumentsListFilters = ({
  searchValue,
  department,
  type,
  directories,
  onSearchChange,
  onDepartmentSelect,
  onTypeSelect,
  modalVariant = false,
}: DocumentsListProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useDocumentsListStyles({ isModalVariant: modalVariant });
  const [searchInputValue, setSearchInputValue] = useState('');

  const departmentsNode = useMemo(() =>
      directories?.find(node => node.data.directoryName === 'Department')
    , [directories]);

  const typesNode = useMemo(() => {
    const docflowNode = directories.find(node => node.data?.directoryName === 'Docflow');
    return docflowNode?.children?.find(node => node.data?.localization?.en === 'Type of document' || node.data?.value === 'Type of document');
  }, [directories]);

  return (
    <div className={classes.documentsListFilters}>
      <TextField
        fullWidth
        size="small"
        value={searchInputValue}
        placeholder={t('form_components.select.search_placeholder')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={SearchIcon}/>
            </InputAdornment>
          ),
        }}
        className={classes.searchField}
        onChange={(event) => {
          setSearchInputValue(event.target.value);
          onSearchChange(event.target.value);
        }}
      />

      <div className={classes.selectsWrapper}>
        <div className={classes.typeSelectWrapper}>
          <span className={classes.selectLabel}>{t('customProcesses.documentPreview.toolbar.department', {defaultValue: "Department"})}</span>
          <FiltersGlossaryInput
            rootNode={departmentsNode}
            value={department}
            onSelect={(value) => {
              onDepartmentSelect(value?.data?.id);
            }}
            leftSideInput
          />
        </div>
        <div className={classes.subtypeSelectWrapper}>
          <span className={classes.selectLabel}>{t('customProcesses.documentPreview.toolbar.type', {defaultValue: "Type"})}</span>
          <FiltersGlossaryInput
            rootNode={typesNode}
            value={type}
            onSelect={(value) => {
              onTypeSelect(value?.data?.id)
            }}
            disabled={!department}
          />
        </div>
      </div>
    </div>
  );
};
