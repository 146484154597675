import { useCallback, useEffect, useState } from 'react';
import sortBy from 'lodash/sortBy';
import moment from 'moment/moment';
import { v4 as uuid } from 'uuid';
import i18n from 'i18next';

import { environment } from 'environments/environment';
import { useUsersState } from 'store/users';

import {
  AutostartSettings,
  ConditionStep,
  FormProcess,
  Language,
  ProcessStep,
  StepName,
  Weekday,
} from '../../../TemplateProcesses.types';

import {
  CUSTOM_FIELDS_RELEASE_DATE,
  DEFAULT_FIELDS_FOR_OLD_PROCESSES,
  DM_CHECK_ASSIGNEE,
  PERFORMER_STEP_ASSIGNEE,
} from '../../../TemplateProcesses.constants';
import {
  ATTACHMENT_FIELD_KEY,
  HIDDEN_FIELD_SUFFIX,
  NOT_REQUIRED_EDITABLE_FIELD_SUFFIX,
  READONLY_FIELD_SUFFIX,
} from '../../ProcessSetupSidePanel/ProcessSetupSidePanel.constants';
import {
  DEFAULT_PROCESS,
  INITIAL_AUTOSTART_SETTINGS,
  STEP_TITLE_LANGUAGE_DEFAULT,
  STEP_TITLE_LANGUAGE_DIRECT_MANAGER,
  STEP_TITLE_LANGUAGE_PERFORMER,
  WEEKDAYS,
} from '../SetupTemplateProcessDialog.constants';
import { checkWorkingCalendarExistence } from '../../../../../api/requests';

const { env } = environment;

export const useProcessStepForm = ({
  fields,
  initialValues,
  templateSettings,
  currentProcessIndex,
  allProcessStepsFields,
  directManagerSteps,
  performerSelectionSteps,
  formErrors,
  languages,
  currentLanguage,
  isOpen,
  isAutostartActive,
  needResetProcessForm,
  conditions,
  parallelBranchesGroups,
  documents,
  setParallelBranchesGroups,
  setConditions,
  setDocuments,
  setFormErrors,
  setProcessIcon,
  setProcessGroup,
  setSummaryFields,
  setDirectManagerSteps,
  setCurrentLanguage,
  setPerformerSelectionSteps,
  setAutostartSettings,
  setLanguageTabs,
  setAutostartActive,
  setAnchorEl,
  setNeedResetProcessForm,
  clearData,
  replaceSettingsForm,
  update,
  replace,
  handleAutostartDisablingConfirmationDialogOpen,
  handleAutostartActivationConfirmationDialogOpen,
  handleAutostartActivationNotPossibleDialogOpen,
}) => {
  const { users } = useUsersState();

  const [hasWorkCalendar, setHasWorkCalendar] = useState(false);

  const checkWorkCalendar = async () => {
    try {
      const res = await checkWorkingCalendarExistence();
      setHasWorkCalendar(!!res);
    } catch (error) {
      setHasWorkCalendar(false);
      console.log('Error checking working calendar existence', error);
    }
  }

  useEffect(() => {
    checkWorkCalendar()
  }, []);

  const isActiveUser = useCallback(userId => {
    if (!users || Object.keys(users).length === 0) {
      return true;
    }

    if ([DM_CHECK_ASSIGNEE, PERFORMER_STEP_ASSIGNEE].includes(userId)) {
      return true;
    }

    return Object.keys(users).includes(userId);
  }, [users]);

  const initiateProcessSteps = (processValues): any => {
    let  summaryFieldsTemp = []
    if (processValues?.summary_new) {
      summaryFieldsTemp = (processValues?.summary_new || '')
        .replaceAll('<p>{{', '').replaceAll('}}</p>', '')
        .split(' ').filter(Boolean);
    } else {
      summaryFieldsTemp = (processValues?.summaryFields || [])?.sort((a, b) => {
        return a?.fieldOrder - b?.fieldOrder;
      })?.map(field => field.sysName) || [];
    }
    setSummaryFields(() => summaryFieldsTemp);

    const isProcessCreatedBeforeCustomFieldsRelease = new Date(processValues.lastUpdated) < new Date(CUSTOM_FIELDS_RELEASE_DATE[env]);
    if (isProcessCreatedBeforeCustomFieldsRelease) {
      setSummaryFields(() => ['field1']);
    }

    const conditionsTemp = [];
    const parallelStepsGroupsTemp = [];

    const fieldTypes = {};
    const fieldIntegrations = {};
    processValues.generateAttributes.forEach(attribute => {
      fieldTypes[attribute.name] = attribute.type || null;
      fieldIntegrations[attribute.name] = attribute.integration || null;
    });

    const fieldDocuments = {};
    processValues.generateAttributes.forEach(attribute => {
      fieldDocuments[attribute.name] = attribute.documentTemplateField;
    });

    const sortedSteps = sortBy(processValues.steps, 'stepOrder');
    let processSteps = processValues.stepsLanguage.reduce((result: ProcessStep[], currentValue) => {
      if (result[currentValue.stepOrder - 1]) {
        result[currentValue.stepOrder - 1] = {
          ...result[currentValue.stepOrder - 1],
          stepName: {
            ...result[currentValue.stepOrder - 1].stepName,
            [currentValue.language]: currentValue.name,
          },
          stepDescription: {
            ...result[currentValue.stepOrder - 1].stepDescription,
            [currentValue.language]: currentValue.stepDescription,
          },
        };
        const conditionIndex = conditionsTemp?.findIndex(item => item?.previousStepOrder === currentValue?.stepOrder);
        if (conditionIndex < conditionsTemp.length && conditionIndex >= 0) {
          conditionsTemp[conditionIndex] = {
            ...conditionsTemp[conditionIndex],
            stepName: {
              ...conditionsTemp[conditionIndex]?.stepName,
              [currentValue.language]: currentValue?.scriptName,
            },
          };
        }
        const parallelStepsGroupIndex = parallelStepsGroupsTemp?.findIndex(item => item?.previousStepOrder === currentValue?.stepOrder);
        if (parallelStepsGroupIndex < parallelStepsGroupsTemp.length && parallelStepsGroupIndex >= 0) {
          parallelStepsGroupsTemp[parallelStepsGroupIndex] = {
            ...parallelStepsGroupsTemp[parallelStepsGroupIndex],
            stepName: {
              ...parallelStepsGroupsTemp[parallelStepsGroupIndex]?.stepName,
              [currentValue.language]: currentValue?.parallelBranchName,
            },
          };
        }
      } else {
        const stepFields =
          isProcessCreatedBeforeCustomFieldsRelease
            //default fields for old template processes
          ? DEFAULT_FIELDS_FOR_OLD_PROCESSES.map(defaultField => ({
              ...defaultField,
              isRequired: currentValue.stepOrder === 1 || defaultField.component === ATTACHMENT_FIELD_KEY,
              isEditable: currentValue.stepOrder === 1 || defaultField.component === ATTACHMENT_FIELD_KEY
            }))
            // parsed fields for newer template processes
          : sortedSteps[currentValue.stepOrder - 1]?.attributes?.map(attribute => ({
            id: attribute.name,
            component: attribute.sysName.replace(READONLY_FIELD_SUFFIX, '').replace(HIDDEN_FIELD_SUFFIX, '').replace(NOT_REQUIRED_EDITABLE_FIELD_SUFFIX, ''),
            isRequired: !(attribute.sysName.includes(READONLY_FIELD_SUFFIX) || attribute.sysName.includes(NOT_REQUIRED_EDITABLE_FIELD_SUFFIX) || attribute.sysName.includes(HIDDEN_FIELD_SUFFIX)),
            isHidden: attribute.sysName.includes(HIDDEN_FIELD_SUFFIX),
            isEditable: (!attribute.sysName.includes(READONLY_FIELD_SUFFIX) || attribute.sysName.includes(NOT_REQUIRED_EDITABLE_FIELD_SUFFIX)) && !attribute.sysName.includes(HIDDEN_FIELD_SUFFIX),
            order: attribute.state_order,
            hint: attribute.attributeLanguageDTO.reduce((acc, value) => ({ ...acc, [value.language]: value.hint }), {}),
            placeholder: attribute.attributeLanguageDTO.reduce((acc, value) => ({
              ...acc,
              [value.language]: value.placeholder,
            }), {}),
            params: attribute.params ?? {},
            documentId: fieldDocuments[attribute.name],
            type: fieldTypes[attribute.name] || null,
            integration: fieldIntegrations[attribute.name] || null,
          }))?.sort((a, b) => {
            return a?.order - b?.order;
          }) || [];

        result[currentValue.stepOrder - 1] = {
          stepOrder: currentValue.stepOrder,
          stepName: {
            [currentValue.language]: currentValue.name,
          } as StepName,
          stepDescription: {
            [currentValue.language]: currentValue.stepDescription,
          } as StepName,
          assigneeId: sortedSteps[currentValue.stepOrder - 1]?.assigneeId.filter(isActiveUser),
          hasTimer: !!sortedSteps[currentValue.stepOrder - 1]?.timer,
          timer: sortedSteps[currentValue.stepOrder - 1]?.timer
                 ? ('' + sortedSteps[currentValue.stepOrder - 1]?.timer)
                 : '',
          parallel: sortedSteps[currentValue.stepOrder - 1]?.parallel ?? false,
          fields: stepFields,
          isConditionBranchStep: false,
          isParallelBranchesGroupStep: false,
          summaryFields: (summaryFieldsTemp.length && !sortedSteps[currentValue.stepOrder - 1].summaryFields)
            ? summaryFieldsTemp
            : sortedSteps[currentValue.stepOrder - 1]?.summaryFields?.map(v => v?.sysName) || []
        };

        if (sortedSteps[currentValue.stepOrder - 1]?.script) {
          const isNewVersionCondition = !!sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.isNewVersion;
          const hasOnlyPositiveStep = sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.hasPositiveStep && !sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.hasNegativeStep;
          const hasOnlyNegativeStep = sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.hasNegativeStep && !sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.hasPositiveStep;
          const hasBothPositiveAndNegativeSteps = sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.hasPositiveStep && sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.hasNegativeStep;
          const existingConditionIndex = conditionsTemp.findIndex(v => ((!v.hasPositiveBranch && !sortedSteps[currentValue.stepOrder - 1]?.scriptModel.hasPositiveStep) || v.positiveBranch[0] === sortedSteps[currentValue.stepOrder - 1]?.scriptModel.positiveStep[0]) && ((!v.hasNegativeBranch && !sortedSteps[currentValue.stepOrder - 1]?.scriptModel.hasNegativeStep) || v.negativeBranch[0] === sortedSteps[currentValue.stepOrder - 1]?.scriptModel.negativeStep[0]));
          if (existingConditionIndex >= 0) {
            conditionsTemp[existingConditionIndex].previousStepOrder = Math.max(currentValue.stepOrder, conditionsTemp[existingConditionIndex].previousStepOrder);
          } else {
            if (isNewVersionCondition) {
              const positiveSteps = sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.hasPositiveStep
                                    ? [sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.positiveStep[0]]
                                    : [];
              const negativeSteps = sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.hasNegativeStep
                                    ? [sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.negativeStep[0]]
                                    : [];

              if (sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.hasPositiveStep) {
                let nextPositiveBranchStepOrder = sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.positiveStep[0];
                while (nextPositiveBranchStepOrder <= sortedSteps.length) {
                  const branchStep = sortedSteps[nextPositiveBranchStepOrder - 1];
                  if (branchStep.conditionJoin) {
                    break;
                  } else {
                    nextPositiveBranchStepOrder += 1;
                    positiveSteps.push(nextPositiveBranchStepOrder);
                  }
                }
              }

              if (sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.hasNegativeStep) {
                let nextNegativeBranchStepOrder = sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.negativeStep[0];
                while (nextNegativeBranchStepOrder <= sortedSteps.length) {
                  const branchStep = sortedSteps[nextNegativeBranchStepOrder - 1];
                  if (branchStep.conditionJoin) {
                    break;
                  } else {
                    nextNegativeBranchStepOrder += 1;
                    negativeSteps.push(nextNegativeBranchStepOrder);
                  }
                }
              }

              const newConditionStep = {
                id: uuid(),
                previousStepOrder: currentValue.stepOrder,
                stepName: {
                  [currentValue.language]: currentValue.scriptName,
                },
                condition: {
                  field: sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.field,
                  type: sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.condition,
                  value: sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.value,
                },
                positiveBranch: positiveSteps,
                hasPositiveBranch: hasOnlyPositiveStep || hasBothPositiveAndNegativeSteps,
                negativeBranch: negativeSteps,
                hasNegativeBranch: hasOnlyNegativeStep || hasBothPositiveAndNegativeSteps,
              } as ConditionStep;
              conditionsTemp.push(newConditionStep);
            } else {
              const newConditionStep = {
                id: uuid(),
                previousStepOrder: currentValue.stepOrder,
                stepName: {
                  [currentValue.language]: currentValue.scriptName,
                },
                condition: {
                  field: sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.field,
                  type: sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.condition,
                  value: sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.value,
                },
                positiveBranch: sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.positiveStep || [],
                hasPositiveBranch: hasOnlyPositiveStep || hasBothPositiveAndNegativeSteps,
                negativeBranch: sortedSteps[currentValue.stepOrder - 1]?.scriptModel?.negativeStep || [],
                hasNegativeBranch: hasOnlyNegativeStep || hasBothPositiveAndNegativeSteps,
              } as ConditionStep;
              conditionsTemp.push(newConditionStep);
            }
          }
        }

        if (sortedSteps[currentValue.stepOrder - 1]?.possibleSteps.length > 1 && !sortedSteps[currentValue.stepOrder - 1]?.script) {
          const existingParallelStepsGroupIndex = parallelStepsGroupsTemp.findIndex(v => v.steps.map(b => b[0]).sort().join(',') === sortedSteps[currentValue.stepOrder - 1].possibleSteps.sort().join(','));

          if (existingParallelStepsGroupIndex >= 0) {
            parallelStepsGroupsTemp[existingParallelStepsGroupIndex].previousStepOrder = Math.max(currentValue.stepOrder, parallelStepsGroupsTemp[existingParallelStepsGroupIndex].previousStepOrder);
            if (!parallelStepsGroupsTemp[existingParallelStepsGroupIndex].stepName[currentValue.language]) {
              parallelStepsGroupsTemp[existingParallelStepsGroupIndex].stepName[currentValue.language] = currentValue.parallelBranchName;
            }
          } else {
            // get all steps
            const parallelBranchesSteps = (sortedSteps[currentValue.stepOrder - 1]?.possibleSteps || []).map(v => [v]);
            for (let branchIndex = 0; branchIndex < parallelBranchesSteps.length; branchIndex++) {
              let nextBranchStepOrder = parallelBranchesSteps[branchIndex][parallelBranchesSteps[branchIndex].length - 1];
              while (nextBranchStepOrder <= sortedSteps.length) {
                const branchStep = sortedSteps[nextBranchStepOrder - 1];
                if (branchStep.parallelJoin) {
                  break;
                } else {
                  nextBranchStepOrder += 1;
                  parallelBranchesSteps[branchIndex].push(nextBranchStepOrder);
                }
              }
            }
            const newParallelStepsGroup = {
              id: uuid(),
              previousStepOrder: currentValue.stepOrder,
              stepName: {
                [currentValue.language]: currentValue.parallelBranchName,
              },
              steps: parallelBranchesSteps,
            };
            parallelStepsGroupsTemp.push(newParallelStepsGroup);
          }
        }
      }

      return result;
    }, [] as ProcessStep[]);

    processSteps = processSteps.map((step) => {
      if (!step.assigneeId?.length || ![DM_CHECK_ASSIGNEE, PERFORMER_STEP_ASSIGNEE].includes(step?.assigneeId[0])) {
        return {
          ...step,
          assigneeIdFromBackend: initialValues.steps.find((s) => s.stepOrder === step.stepOrder).assigneeId
        }
      }

      if (step.assigneeId[0] === DM_CHECK_ASSIGNEE) {
        setDirectManagerSteps(steps => [...steps, step.stepOrder]);
      }
      if (step.assigneeId[0] === PERFORMER_STEP_ASSIGNEE) {
        setPerformerSelectionSteps(steps => [...steps, step.stepOrder - 1]);
      }

      return {
        ...step,
        assigneeId: [],
        assigneeIdFromBackend: initialValues.steps.find((s) => s.stepOrder === step.stepOrder).assigneeId
      };
    });

    conditionsTemp.forEach((condition) => {
      if (condition.hasPositiveBranch) {
        condition.positiveBranch.forEach(step => {
          if (processSteps[step - 1]) {
            processSteps[step - 1].isConditionBranchStep = true;
          }
        })
      }
      if (condition.hasNegativeBranch) {
        condition.negativeBranch.forEach(step => {
          if (processSteps[step - 1]) {
            processSteps[step - 1].isConditionBranchStep = true;
          }
        })
      }
    });

    parallelStepsGroupsTemp.forEach((parallelStepsGroup) => {
      parallelStepsGroup?.steps.flat().forEach((step) => {
        if (step <= processSteps.length) {
          processSteps[step - 1].isParallelBranchesGroupStep = true;
        }
      });
    });

    let documentsTemp = [];
    if (processValues?.docflowTemplates) {
      documentsTemp = processValues?.docflowTemplates?.map(doc => ({
        id: doc.attachmentId,
        uniqueId: doc.docFlowTemplateId,
        backUniqueId: doc.id || '',
        title: doc.title,
        destination: '',
        status: '',
        author: {
          userId: '',
          workspaceId: ''
        },
        department: doc?.department || '',
        type: doc?.type || '',
        createdAt: '',
        updatedAt: '',
        description: doc.description || '',
        attachmentId: '',
        fields: doc.fields.map(field => ({
          id: field.docFlowTemplateFieldId,
          name: field.templateFieldName,
          type: field.type,
          hint: field.hint,
          processFieldId: field.processFieldId,
          backUniqueId: field.id || '',
          langCode: field.language ? field.language.code : null
        })),
        firstAppearanceStep: doc.firstAppearanceStep,
        signatureSteps: doc.signRequiredSteps,
        hiddenSteps: [],
      }));
      setDocuments(documentsTemp);
    }

    setConditions(conditionsTemp);
    setParallelBranchesGroups(parallelStepsGroupsTemp);
    replace(processSteps);
    return {
      documents: documentsTemp,
      conditions: conditionsTemp,
      parallelStepsGroups: parallelStepsGroupsTemp,
      processSteps,
    }
  };

  const initiateAutostartSettings = (processValues) => {
    if (processValues?.autoStart && processValues?.autoStart !== 'INACTIVE') {
      const { startDate, endDate, endingNever, endingCount, recurrenceRule } = processValues.recurrenceAutoStart;
      const autostartInfo = {
        repeatUnit: 'day',
        repeatCount: 1,
        startDate: new Date(startDate),
        repeatDays: [WEEKDAYS[moment(startDate).isoWeekday() - 1]],
        endingType: 'never',
        endingDate: new Date(),
        endingRepetitionsCount: 1,
      } as AutostartSettings;

      if (recurrenceRule.includes('DAILY')) {
        autostartInfo.repeatUnit = 'day';
        autostartInfo.repeatCount = +recurrenceRule.split('COUNT=')[1];
      } else if (recurrenceRule.includes('WEEKLY')) {
        autostartInfo.repeatUnit = 'week';
        const ruleParts = recurrenceRule.split(';');
        autostartInfo.repeatCount = +ruleParts[1].replace('COUNT=', '');
        autostartInfo.repeatDays = ruleParts[2].replace('BYDAY=', '').split(',') as Weekday[];
      } else if (recurrenceRule.includes('MONTHLY')) {
        autostartInfo.repeatUnit = 'month';
        autostartInfo.repeatCount = +recurrenceRule.split('COUNT=')[1];
      } else {
        autostartInfo.repeatUnit = 'year';
        autostartInfo.repeatCount = +recurrenceRule.split('COUNT=')[1];
      }

      if (endingNever) {
        autostartInfo.endingType = 'never';
      } else if (endDate) {
        autostartInfo.endingType = 'date';
        autostartInfo.endingDate = new Date(endDate);
      } else {
        autostartInfo.endingType = 'after';
        autostartInfo.endingRepetitionsCount = endingCount;
      }

      setAutostartSettings(autostartInfo);
      setAutostartActive(true);
    }
  };

  const initiateProcessSettings = (templateSettingsValues): void => {
    setProcessIcon(templateSettingsValues.processIcon);
    setProcessGroup(templateSettingsValues.processGroup);
    replaceSettingsForm(templateSettingsValues.formProcess);
    const processLanguages = templateSettingsValues?.formProcess?.map(({ language }: FormProcess): Language => language);
    setCurrentLanguage(() => processLanguages?.length > 0 ? processLanguages[0] : i18n.language);
    setLanguageTabs(tabs => processLanguages ?? tabs);
  };

  const handleAnchorClose = (): void => {
    setAnchorEl(null);
  };

  const handleStepNameChange = (stepName: string): void => {
    const processFields = fields as unknown as ProcessStep[];
    const newFormErrors = formErrors;
    if (newFormErrors[currentProcessIndex]) {
      newFormErrors[currentProcessIndex].stepName = newFormErrors[currentProcessIndex].stepName
        .filter(v => v !== currentLanguage);
      setFormErrors(newFormErrors);
    }

    update(currentProcessIndex, {
      ...processFields[currentProcessIndex],
      stepName: {
        ...processFields[currentProcessIndex].stepName,
        [currentLanguage]: stepName,
      },
    });
  };

  const handleStepDescriptionChange = (stepDescription: string): void => {
    const processFields = fields as unknown as ProcessStep[];

    const newFormErrors = formErrors;
    if (newFormErrors[currentProcessIndex]) {
      newFormErrors[currentProcessIndex].stepDescription = newFormErrors[currentProcessIndex].stepDescription
        .filter(v => v !== currentLanguage);
      setFormErrors(newFormErrors);
    }

    update(currentProcessIndex, {
      ...processFields[currentProcessIndex],
      stepDescription: {
        ...processFields[currentProcessIndex].stepDescription,
        [currentLanguage]: stepDescription,
      },
    });
  };

  const handleInitiatorsChange = (assigneeId: string[]): void => {
    const newFormErrors = formErrors;
    if (newFormErrors[currentProcessIndex]) {
      newFormErrors[currentProcessIndex].assigneeId = false;
      setFormErrors(newFormErrors);
    }

    update(currentProcessIndex, {
      ...fields[currentProcessIndex],
      assigneeId,
    });
  };

  const handleAssigneeTypeChange = (isParallel: boolean) => {
    const isPerformerSelectionStep = performerSelectionSteps.includes(currentProcessIndex + 1);
    setDirectManagerSteps(steps => steps.filter(v => v !== currentProcessIndex + 1));
    setPerformerSelectionSteps(steps => steps.filter(v => v !== currentProcessIndex + 1));

    const newFormErrors = formErrors;
    if (newFormErrors[currentProcessIndex]) {
      newFormErrors[currentProcessIndex].assigneeId = false;
      setFormErrors(newFormErrors);
    }

    const processSteps = fields as unknown as ProcessStep[];
    replace(
      processSteps
        .filter(({ stepOrder }) => isPerformerSelectionStep ? currentProcessIndex + 2 !== stepOrder : true)
        .map((step, index) => {
          if (step.stepOrder === currentProcessIndex + 1) {
            return ({
              ...step,
              parallel: isParallel,
              assigneeId: [''],
              stepOrder: index + 1,
            });
          }
          return ({
            ...step,
            stepOrder: index + 1,
          });
        }),
    );

    setDocuments(docs => docs.map(doc => ({
      ...doc,
      signatureSteps: doc.signatureSteps.filter(v => v !== currentProcessIndex + 1),
    })));
  };

  const handleDirectManagerToggle = (value) => {
    setDirectManagerSteps(steps => value
                                   ? [...steps, currentProcessIndex + 1]
                                   : steps.filter(v => v !== currentProcessIndex + 1));
    const newFormErrors = formErrors;
    if (newFormErrors[currentProcessIndex]) {
      newFormErrors[currentProcessIndex].assigneeId = false;
      setFormErrors(newFormErrors);
    }

    if (value) {
      update(currentProcessIndex, {
        ...fields[currentProcessIndex],
        stepName: languages.reduce((acc, lang) => ({ ...acc, [lang]: STEP_TITLE_LANGUAGE_DIRECT_MANAGER[lang] }), {}),
        assigneeId: [],
      });
    }
  };

  const handlePerformerSelectionToggle = (value) => {
    setPerformerSelectionSteps(steps => value
                                        ? [...steps, currentProcessIndex + 1].filter((value, index, array) => array.indexOf(value) === index)
                                        : steps.filter(v => v !== currentProcessIndex + 1));
    if (value) {
      const currentStep = fields[currentProcessIndex];
      replace([
        ...fields.slice(0, currentProcessIndex + 1),
        {
          stepOrder: currentProcessIndex + 1 + 1,
          stepName: languages.reduce((acc, lang) => ({ ...acc, [lang]: STEP_TITLE_LANGUAGE_PERFORMER[lang] }), {}),
          stepDescription: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
          assigneeId: [],
          fields: allProcessStepsFields.map(item =>
            ({ ...item?.field, isRequired: false, isEditable: false })),
          timer: '',
          hasTimer: false,
          parallel: false,
          isConditionBranchStep: !!currentStep?.isConditionBranchStep,
          isParallelBranchesGroupStep: !!currentStep?.isParallelBranchesGroupStep,
          summaryFields: fields[currentProcessIndex].summaryFields
        },
        ...fields.slice(currentProcessIndex + 1).map((step, index) => ({
          ...step,
          stepOrder: currentProcessIndex + 1 + 2 + index,
        })),
      ] as ProcessStep[]);

      const modifiedConditionsList = conditions.map(condition => {
        const conditionSteps = [
          ...(condition?.hasPositiveBranch ? condition?.positiveBranch : []),
          ...(condition?.hasNegativeBranch ? condition?.negativeBranch : []),
        ];
        if (conditionSteps?.includes(currentProcessIndex + 1)) {
          const isPositiveBranchStep = (condition?.hasPositiveBranch && condition?.positiveBranch.includes(currentProcessIndex + 1));
          const branchStepIndex = isPositiveBranchStep
                                  ? condition?.positiveBranch?.findIndex(v => v === currentProcessIndex + 1)
                                  : condition?.negativeBranch?.findIndex(v => v === currentProcessIndex + 1);
          if (isPositiveBranchStep) {
            condition.positiveBranch = [
              ...condition?.positiveBranch.slice(0, branchStepIndex + 1),
              currentProcessIndex + 1 + 1,
              ...condition?.positiveBranch.slice(branchStepIndex + 1).map(v => v + 1),
            ];
            condition.negativeBranch = condition?.negativeBranch?.map(v => v + 1);
          } else {
            condition.negativeBranch = [
              ...condition?.negativeBranch.slice(0, branchStepIndex + 1),
              currentProcessIndex + 1 + 1,
              ...condition?.negativeBranch.slice(branchStepIndex + 1).map(v => v + 1),
            ];
          }
        } else {
          condition.positiveBranch = condition.positiveBranch.map(v => v >= currentProcessIndex + 1 ? v + 1 : v);
          condition.negativeBranch = condition.negativeBranch.map(v => v >= currentProcessIndex + 1 ? v + 1 : v);
        }
        if (condition.previousStepOrder >= currentProcessIndex + 1) {
          condition.previousStepOrder += 1;
        }
        return condition;
      });
      setConditions(() => modifiedConditionsList);

      const modifiedParallelStepsGroupsList = parallelBranchesGroups.map(group => {
        if (group?.steps?.flat().includes(currentProcessIndex + 1)) {
          const currentBranchIndex = group?.steps?.findIndex(b => b.includes(currentProcessIndex + 1));
          const currentBranchStepIndex = group?.steps[currentBranchIndex]?.findIndex(v => v === currentProcessIndex + 1);
          group.steps = group.steps.map((b, bIndex) => {
            if (bIndex < currentBranchIndex) {
              return b;
            }
            if (bIndex === currentBranchIndex) {
              return [
                ...b.slice(0, currentBranchStepIndex + 1),
                currentProcessIndex + 1 + 1,
                ...b.slice(currentBranchStepIndex + 1).map(v => v + 1),
              ];
            }
            return b.map(v => v + 1);
          });
        } else {
          group.steps = group.steps.map(b => b.map(v => v >= currentProcessIndex + 1 ? v + 1 : v));
        }
        if (group.previousStepOrder >= currentProcessIndex + 1) {
          group.previousStepOrder += 1;
        }
        return group;
      });
      setParallelBranchesGroups(() => modifiedParallelStepsGroupsList);

      setDirectManagerSteps(steps => steps.map(step => step < currentProcessIndex + 2 ? step : step + 1));
      setPerformerSelectionSteps(steps => steps.map(step => step < currentProcessIndex + 2 ? step : step + 1));
    } else {
      const processSteps = fields as unknown as ProcessStep[];
      replace(
        processSteps
          .filter(({ stepOrder }) => currentProcessIndex + 2 !== stepOrder)
          .map((step, index) => ({
            ...step,
            stepOrder: index + 1,
          })),
      );

      const modifiedConditionsList = conditions.map(condition => {
        condition.positiveBranch = condition.positiveBranch
          .filter(v => v !== currentProcessIndex + 1)
          .map(v => v >= currentProcessIndex + 1
                    ? v - 1
                    : v);
        condition.negativeBranch = condition.negativeBranch
          .filter(v => v !== currentProcessIndex + 1)
          .map(v => v >= currentProcessIndex + 1
                    ? v - 1
                    : v);
        if (condition.previousStepOrder >= currentProcessIndex + 1) {
          condition.previousStepOrder -= 1;
        }
        return condition;
      });
      setConditions(() => modifiedConditionsList);

      const modifiedParallelStepsGroupsList = parallelBranchesGroups.map(group => {
        group.steps = group.steps
          .map(b => b.filter(v => v !== currentProcessIndex + 1)
            .map(v => v >= currentProcessIndex + 1
                      ? v - 1
                      : v));
        if (group.previousStepOrder >= currentProcessIndex + 1) {
          group.previousStepOrder -= 1;
        }
        return group;
      });
      setParallelBranchesGroups(() => modifiedParallelStepsGroupsList);

      setDirectManagerSteps(steps => steps.map(step => step < currentProcessIndex + 2 ? step : step - 1));
      setPerformerSelectionSteps(steps => steps.map(step => step < currentProcessIndex + 2 ? step : step - 1));
    }
  };

  const handleStepExecutionTimeChange = (executionTime: string): void => {
    const processFields = fields as unknown as ProcessStep[];
    const hasNonNumberChars = executionTime.split('').some(char => !'0123456789'.includes(char));
    const executionTimeWithoutStartingZeros = executionTime.replace(/^0+/, '');
    if (!hasNonNumberChars) {
      update(currentProcessIndex, {
        ...processFields[currentProcessIndex],
        timer: executionTimeWithoutStartingZeros,
      });
    }
  };

  const handleSLACheckboxToggle = (value: boolean): void => {
    const processFields = fields as unknown as ProcessStep[];
      update(currentProcessIndex, {
        ...processFields[currentProcessIndex],
        hasTimer: value,
        timer: '',
      });
  };

  const handleActiveLanguageSelect = (language: Language): void => {
    setCurrentLanguage(language);
  };

  const getLocalizedDefaultName = useCallback((processStepOrder, lang) => {
    if (processStepOrder === 1) {
      return STEP_TITLE_LANGUAGE_DEFAULT[lang];
    }
    if (directManagerSteps.includes(processStepOrder)) {
      return STEP_TITLE_LANGUAGE_DIRECT_MANAGER[lang] || '';
    }
    if (performerSelectionSteps.includes(processStepOrder - 1)) {
      return STEP_TITLE_LANGUAGE_PERFORMER[lang] || '';
    }
    return '';
  }, [directManagerSteps, performerSelectionSteps]);

  const validateAutostartToggle = (value: boolean, skipCheck = false) => {
    const processFields = fields as unknown as ProcessStep[];
    // show warning modal if there is condition just after 1st step
    if (processFields.length > 1) {
      if (processFields[1].isConditionBranchStep) {
        handleAutostartActivationNotPossibleDialogOpen();
        return false;
      }
    }
    // confirm if there are documents and/or fields on the 1st step
    const shouldConfirmChange = !skipCheck && value && (
      allProcessStepsFields.some(field => field.firstAppearanceStep === 1) ||
      documents.some(doc => doc.firstAppearanceStep === 1)
    );
    if (shouldConfirmChange) {
      handleAutostartActivationConfirmationDialogOpen();
      return false;
    }

    return true;
  }

  const handleAutostartChange = (value: boolean, skipCheck = false) => {
    if (initialValues?.published && isAutostartActive && !value) {
      handleAutostartDisablingConfirmationDialogOpen();
    } else {
      const canToggleOnAutostart = validateAutostartToggle(value, skipCheck);
      if (!canToggleOnAutostart) {
        return;
      }
      setAutostartActive(() => value);
      if (value) {
        const processFields = fields as unknown as ProcessStep[];
        const fieldsAddedOnRequestFormationStep = allProcessStepsFields
          .filter(item => item?.firstAppearanceStep === 1)
          .map(item => item?.field?.id);

        // remove fields added on the 1st step from conditions

        if (fieldsAddedOnRequestFormationStep.length > 0) {
          const modifiedConditions = conditions.map(c => {
            if (fieldsAddedOnRequestFormationStep?.includes(c?.condition?.field)) {
              c.condition.field = '';
              c.condition.type = '';
              c.condition.value = '';
            }
            return c;
          });
          setConditions(() => modifiedConditions);
        }

        // delete all documents added on the 1st step
        // and all links to fields from the 1st step from documents

        const documentsFromFirstStep = documents
          .filter(doc => doc.firstAppearanceStep === 1)
          .map(doc => doc.uniqueId);

        const modifiedDocuments = documents
          .filter(doc => doc.firstAppearanceStep !== 1)
          .map(doc => ({
            ...doc,
            fields: doc.fields.map(field => {
              if (!field.processFieldId) {
                return field;
              }
              const processField = allProcessStepsFields.find(item => item.field.id === doc.processFieldId)?.field;
              const shouldClearProcessFieldLink = fieldsAddedOnRequestFormationStep.includes(doc.processFieldId)
                || documentsFromFirstStep.includes(processField?.documentId);
              return {
                ...field,
                processFieldId: shouldClearProcessFieldLink ? '' : doc.processFieldId
              }
            })
          }));

        setDocuments(modifiedDocuments);

        // remove all fields added on the 1st step
        // and all fields from documents added on the 1st step
        // and change 1st step name, description and other params

        const modifiedProcessFields = processFields.map(processStep => {
          if (processStep.stepOrder === 1) {
            return {
              ...processStep,
              stepName: DEFAULT_PROCESS[0].stepName,
              stepDescription: DEFAULT_PROCESS[0].stepDescription,
              timer: DEFAULT_PROCESS[0].timer,
              hasTimer: false,
              parallel: false,
              assigneeId: [],
              fields: [],
            }
          }

          return {
            ...processStep,
            fields: processStep.fields
              .filter(field => !fieldsAddedOnRequestFormationStep.includes(field.id) && !documentsFromFirstStep.includes(field.documentId))
              .map((field, index) => ({
                ...field,
                order: index+1
              }))
          }
        });

        replace(modifiedProcessFields);
      }
    }
  };

  const handleAutostartDisablingConfirmationDialogConfirm = () => {
    setAutostartActive(() => false);
    setAutostartSettings(() => INITIAL_AUTOSTART_SETTINGS as AutostartSettings);
  };

  const handleAutostartActivationConfirmationDialogConfirm = () => {
    // setAutostartActive(() => false);
    // setAutostartSettings(() => INITIAL_AUTOSTART_SETTINGS as AutostartSettings);
    handleAutostartChange(true, true);
  };

  useEffect(() => {
    if (initialValues) {
      handleAnchorClose();
      initiateProcessSteps(initialValues);
      initiateAutostartSettings(initialValues);
    }
  }, [initialValues, isOpen]);

  useEffect(() => {
    if (templateSettings) {
      initiateProcessSettings(templateSettings);
    }
  }, [templateSettings]);

  useEffect(() => {
    if (needResetProcessForm) {
      clearData();
      handleAnchorClose();
      setNeedResetProcessForm(() => false);
    }
  }, [needResetProcessForm]);

  useEffect((): void => {
    if (languages.length && !currentLanguage) {
      setCurrentLanguage(languages[0]);
    }

    const processSteps = fields as unknown as ProcessStep[];
    const updatedProcessSteps = processSteps.map(processStep => {

      const newStepNames = languages.reduce((acc, lang) => {
        const localizedDefaultName = getLocalizedDefaultName(processStep.stepOrder, lang);
        return {
          ...acc,
          [lang]: processStep.stepName[lang] || localizedDefaultName,
        };
      }, {});

      const newStepDescriptions = languages.reduce((acc, lang) => {
        return {
          ...acc,
          [lang]: processStep.stepDescription?.[lang] || '',
        };
      }, {});

      const newFields = processStep.fields?.map(field => ({
        ...field,
        hint: languages.reduce((acc, lang) => {
          return {
            ...acc,
            [lang]: field.hint[lang] || '',
          };
        }, {}),
        placeholder: languages.reduce((acc, lang) => {
          return {
            ...acc,
            [lang]: field.placeholder[lang] || '',
          };
        }, {}),
      }));
      return {
        ...processStep,
        stepName: newStepNames,
        stepDescription: newStepDescriptions,
        fields: newFields,
      };
    });
    replace(updatedProcessSteps);
  }, [languages, currentLanguage]);

  return {
    hasWorkCalendar,
    initiateProcessSteps,
    initiateAutostartSettings,
    initiateProcessSettings,
    handleAnchorClose,
    handleStepNameChange,
    handleStepDescriptionChange,
    handleInitiatorsChange,
    handleAssigneeTypeChange,
    handleDirectManagerToggle,
    handlePerformerSelectionToggle,
    handleStepExecutionTimeChange,
    handleSLACheckboxToggle,
    handleActiveLanguageSelect,
    handleAutostartChange,
    handleAutostartDisablingConfirmationDialogConfirm,
    handleAutostartActivationConfirmationDialogConfirm,
  };
};
