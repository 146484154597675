import React, { useMemo, useState } from 'react';
import { Typography, IconButton, Fade, Tooltip, Grow } from '@material-ui/core';
import {makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';

import { UserAvatar, NoUserPopover } from 'components';

import { isObjectEmpty } from 'utils/general';
import { UserType } from 'types';
import isArray from 'lodash/isArray';
import { limitStringLength } from 'utils/user';

type Props = {
  user: UserType;
  onDelete?: () => void;
  inputRef?: any;
  showRole?: boolean;
  title?: string;
  avatarSize?: number;
  smallName?: boolean;
  nameOnTop?: boolean;
  nameMarginLeft?: number;
  showTitleUnderName?: boolean;
  hideName?: boolean;
  truncateName?: boolean;
  maxWidth?: number;
  showPosition?: boolean;
};

type stylesProps = {
  nameMarginLeft: number;
  hideName?: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  rootTruncatedName: {
    overflow: 'hidden',
  },
  avatar: {
    margin: ({ hideName }: stylesProps) => (hideName ? 0 : '0 8px 0 0'),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: ({ nameMarginLeft }: stylesProps) => nameMarginLeft,
  },
  contentInner: {
    overflow: 'hidden',
  },
  contentTruncatedName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  helpButton: {
    marginLeft: '8px',
  },
  deleteButton: {
    marginLeft: '16px',
  },
  fullName: {
    color: theme.palette.grey[700],
    textAlign: 'left',
    fontWeight: 400,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  smallFullName: {
    fontSize: 12,
    fontWeight: 400,
    whiteSpace: 'nowrap',
  },
}));

const NO_USER_FULL_NAME = 'No full name';
const NO_USER_ROLE = 'No user role';

export const UserInfo = ({
  user,
  onDelete,
  title = null,
  showRole = false,
  showPosition = false,
  avatarSize = 40,
  smallName = false,
  nameOnTop = false,
  nameMarginLeft = 8,
  showTitleUnderName = false,
  hideName,
  truncateName = false,
  maxWidth,
}: Props) => {
  const classes = useStyles({ nameMarginLeft, hideName });
  const [hovered, setHovered] = useState(false);

  const hasTitleAboveName = useMemo(() => !!title && !showTitleUnderName, [title, showTitleUnderName]);
  const hasTitleUnderName = useMemo(() => !!title && showTitleUnderName, [title, showTitleUnderName]);

  let name = NO_USER_FULL_NAME;
  if (!user) {
    return null;
  }

  const { fullName, logName } = user;
  if (fullName) name = fullName;
  else if (logName) name = logName;
  const shortedName = limitStringLength(name, 25);

  return (
    <div
      className={cn(classes.root, { [classes.rootTruncatedName]: truncateName })}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <UserAvatar user={user} className={classes.avatar} avatarSize={avatarSize} />

      {!hideName && (
        <div className={cn(classes.content, { [classes.contentTruncatedName]: truncateName })}>
          <div className={classes.contentInner}>
            {hasTitleAboveName && (
              <Typography variant="subtitle1" color="textSecondary">
                {title}
              </Typography>
            )}

            {maxWidth ? (
              <Tooltip TransitionComponent={Grow} title={name}>
                <Typography
                  variant="h6"
                  className={cn(classes.fullName, {
                    [classes.smallFullName]: smallName,
                  })}
                  style={{
                    height: nameOnTop ? avatarSize : 'auto',
                    maxWidth,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {shortedName}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                title={name}
                variant="h6"
                className={cn(classes.fullName, {
                  [classes.smallFullName]: smallName,
                })}
                style={{
                  height: nameOnTop ? avatarSize : 'auto',
                }}
              >
                {shortedName}
              </Typography>
            )}

            {showRole && (
              <Typography variant="subtitle1" color="textSecondary">
                {(isArray(user.role) ? user.role.join(', ') : user.role) || NO_USER_ROLE}
              </Typography>
            )}

            {showPosition && (
              <Typography variant="subtitle1" color="textSecondary">
                {user.jobTitle}
              </Typography>
            )}

            {hasTitleUnderName && (
              <Typography variant="subtitle1" color="textSecondary">
                {title}
              </Typography>
            )}
          </div>
          {!isObjectEmpty(user) && <NoUserPopover />}
        </div>
      )}

      {onDelete && (
        <Fade in={hovered}>
          <IconButton className={classes.deleteButton} size="small" onClick={onDelete}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Fade>
      )}
    </div>
  );
};
