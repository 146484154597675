import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  ButtonBase,
  Checkbox,
  Divider,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';

import Search from '@material-ui/icons/Search';

import useStyles from './GroupSelect.useStyles';
import dropdownIndicatorIcon from 'assets/images/icons/dropdown-indicator.svg';
import Close from '@material-ui/icons/Close';

type TypeOption = {
  label: string;
  value: any;
  icon?: React.ReactElement;
  selected?: boolean;
};

type TypeProps = {
  options: TypeOption[];
  value?: TypeOption[] | string[];
  isShownSearch?: boolean;
  multiple?: boolean;
  onChange?: (values: TypeOption[]) => void;
  title?: string;
  className?: string;
};

export const GroupSelect = (props: TypeProps) => {
  const {
    options,
    onChange,
    value,
    isShownSearch,
    multiple,
    title,
    className,
  } = props;
  const classes = useStyles();
  const uid = useMemo(() => uuidv4(), []);
  const { t } = useTranslation();

  const [selectedValues, setSelectedValues] = useState<TypeOption[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [paperWidth, setPaperWidth] = useState(340)

  const inputWrapperRef = useRef<HTMLDivElement>();
  const searchInputRef = useRef<HTMLInputElement>();
  const clearBtnRef = useRef<HTMLButtonElement>();
  const rootRef = useRef<HTMLDivElement>();

  const filteredOptions = useMemo(() => {
    const sortedBySelect = options.map((option) => {
      if (
        selectedValues.some(
          (selectedValue) => selectedValue.value === option.value
        )
      ) {
        return {
          ...option,
          selected: true,
        };
      } else {
        return {
          ...option,
          selected: false,
        };
      }
    });

    return sortedBySelect.filter((o) =>
      o.label.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, options, selectedValues]);

  const handleClickItem = (option) => {
    const { value } = option;

    if (!multiple) {
      setSelectedValues([option]);
      onChange([option]);
      setSearchText('');
      setIsMenuOpen(false);
      return;
    }

    if (selectedValues.some((v) => v.value === value)) {
      setSelectedValues(selectedValues.filter((v) => v.value !== value));
    } else {
      setSelectedValues([...selectedValues, option]);
    }
  };

  const handleClose = ({ target }) => {
    if (
      inputWrapperRef.current &&
      (target === inputWrapperRef.current ||
        inputWrapperRef.current?.contains(target))
    ) {
      return;
    }
    // for clear rendered unapplied values
    if (value?.length) {
      if (typeof value[0] === 'string') {
        const valueObjects = (value as string[])
          .map((v) => options.find((option) => option.value === v))
          .filter(Boolean);
          onChange(valueObjects);
      } else {
        onChange(value as TypeOption[]);
      }
    } else {
      onChange([]);
    }

    setIsMenuOpen(false);
    setSearchText('');
  };

  const renderOption = (option: TypeOption) => {
    const { label, value, icon, selected } = option;
    const isSelected = selectedValues.some((v) => v.value === value);

    return (
      <MenuItem
        className={cn(classes.menuItem, {
          [classes.selectedMenuItem]: isSelected,
        })}
        key={value}
        value={value}
        onClick={() => handleClickItem(option)}
      >
        {selected}
        {multiple && (
          <Checkbox
            checked={isSelected}
            className={classes.checkbox}
            color="default"
          />
        )}

        {icon ? <div className={classes.icon}>{icon}</div> : null}
        <ListItemText primary={label} />
      </MenuItem>
    );
  };

  const handleSelectClickCapture = (e) => {
    if (
      e.target === clearBtnRef.current ||
      clearBtnRef.current?.contains(e.target)
    ) {
      setSelectedValues([]);
      onChange([]);
      return;
    }
    if (!isMenuOpen) {
      setIsMenuOpen(true);
    }
  };

  useEffect(() => {
    if (
      isEqual(value, selectedValues) ||
      isEqual(
        value,
        selectedValues.map((v) => v.value)
      )
    )
      return;

    if (value?.length) {
      if (typeof value[0] === 'string') {
        const valueObjects = (value as string[])
          .map((v) => options.find((option) => option.value === v))
          .filter(Boolean);
        setSelectedValues(valueObjects);
      } else {
        setSelectedValues(value as TypeOption[]);
      }
    } else {
      setSelectedValues([]);
    }
  }, [value]);

  useLayoutEffect(() => {
    if(!rootRef.current) return

    if(isMenuOpen){
      setPaperWidth(rootRef.current.getBoundingClientRect().width)
    }

  }, [isMenuOpen])

  useEffect(() => {
    onChange(selectedValues);
  }, [selectedValues]);

  return (
    <div className={cn(classes.root, className)} ref={rootRef}>
      {title ? <p className={classes.title}>{title}</p> : null}

      <InputLabel id={uid} />
      <Select
        onKeyPress={({ key }) => {
          if (key === 'a' || key === 'A') {
            searchInputRef.current?.focus();
          }
        }}
        labelId={uid}
        fullWidth
        multiple
        onClickCapture={handleSelectClickCapture}
        onClose={handleClose}
        open={isMenuOpen}
        value={selectedValues}
        className={classes.select}
        IconComponent={(props) =>
          !selectedValues.length ? (
            <img
              {...props}
              src={dropdownIndicatorIcon}
              alt=""
              className={classes.dropdownDownIndicatorIcon}
            />
          ) : (
            <ButtonBase
              {...props}
              className={classes.dropdownDownIndicatorIcon}
              ref={clearBtnRef}
            >
              <Close fontSize="small" />
            </ButtonBase>
          )
        }
        renderValue={(selected: TypeOption[]) => {
          return (
            <div className={classes.selectedLabelText}>
              <div className={classes.selectedIcon}>{selected[0].icon}</div>

              <div>
                {selected[0].label}
                {selected.length > 1 ? <b>({selected.length - 1})</b> : null}
              </div>
            </div>
          );
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 340,
              width: paperWidth, // TODO
              marginTop: 10,
            },
          },
          MenuListProps: {
            style: {
              padding: 0,
            },
          },
          getContentAnchorEl: () => {
            return rootRef?.current
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 0,
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 10,
          },
        }}
      >
        <div className={classes.selectContent}>
          {isShownSearch && (
            <div className={classes.searchWrapper} ref={inputWrapperRef}>
              <TextField
                size="small"
                className={classes.searchInput}
                value={searchText}
                inputRef={searchInputRef}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.searchIconWrapper}
                    >
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => setSearchText(event.target.value)}
              />
            </div>
          )}

          <div className={classes.selectOptionWrapper}>
            {filteredOptions
              .filter((option) => option.selected)
              .map((option) => renderOption(option))}
            <Divider style={{ margin: '4px 0' }} />
            {filteredOptions
              .filter((option) => !option.selected)
              .map((option) => renderOption(option))}
          </div>

          <footer className={classes.footer}>
            <Box display="flex" justifyContent="end">
              <ButtonBase
                className={classes.linkButton}
                onClick={() => {
                  setSelectedValues([]);
                  setSearchText('');
                }}
              >
                {t('form_components.select.clear')}
              </ButtonBase>

              <ButtonBase
                className={classes.linkButton}
                onClick={() => {
                  setIsMenuOpen(false);
                  setSearchText('');
                  onChange(selectedValues);
                }}
              >
                {t('filters.filter_button_apply')}
              </ButtonBase>
            </Box>
          </footer>
        </div>
      </Select>
    </div>
  );
};
