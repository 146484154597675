import React, { memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import useValidationErrorSnackbarStyles from './useStyles';
import { IconButton } from '@mui/material';

import CloseIcon from 'assets/images/icons/close-icon-grey.svg';

type ValidationErrorSnackbarProps = {
  onClose: () => void;
  errorMessageLines: ('fields' | 'documents')[];
}

export const ValidationErrorSnackbar = memo(({
  onClose,
  errorMessageLines,
}: ValidationErrorSnackbarProps): ReactElement => {
  const classes = useValidationErrorSnackbarStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <img className={classes.closeIcon} src={CloseIcon} alt={'close'} onClick={onClose}/>

      <span className={classes.title}>{t('task_data_view.validationErrorSnackbar.approvalActions')}:</span>
      <ul className={classes.errorsList}>
        {
          errorMessageLines.includes('fields') &&
          <li className={classes.errorMessage}>{t('task_data_view.validationErrorSnackbar.fieldsError')}</li>
        }
        {
          errorMessageLines.includes('documents') &&
          <li className={classes.errorMessage}>{t('task_data_view.validationErrorSnackbar.documentsError')}</li>
        }
      </ul>
    </div>
  );
});
