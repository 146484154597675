import React, { useContext, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { Box, TextField } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import { ProcessContextType } from 'types';
import ProcessContext from 'contexts/ProcessContext';

import useStyles from './useStyles';

type Props = {
  onSelect: (value) => void;
  label?: string;
  className?: string;
  value?: string;
  defaultValue?: string;
  isRenderOption?: boolean;
  disabled?: boolean;
  margin?: any;
  popperClasses?: Partial<ClassNameMap<any>>;
  textFieldClassName?: string;
  size?: 'small' | 'medium';
  analyticsFiltersDesignVariant?: boolean;
};

// TODO: Исправить код ниже
export const SelectProcess = ({
  onSelect,
  label,
  className,
  value,
  isRenderOption,
  disabled,
  margin,
  textFieldClassName,
  popperClasses,
  defaultValue,
  analyticsFiltersDesignVariant = false,
}: Props) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { processes: data, loading, error }: Partial<ProcessContextType> = useContext(ProcessContext);

  const [inputValue, setInputValue] = useState( '');

  useEffect(() => {
    if (value === '' && inputValue !== '') {
      setInputValue('');
    }
  }, [value, setInputValue]);

  const hasValueChanged = useMemo(() =>
      value !== defaultValue,
    [value, defaultValue]);

  const options = useMemo(() =>
      data ?
      data
        .filter(process => !!process?.groupName)
        .map(process => ({
            ...process,
            label: t(`constructor-${process.processSysName}.name`, { defaultValue: process.name }),
          }))
        .sort((process1, process2) => process1.label.localeCompare(process2.label)) :
        [],
    [data]);

  useEffect(() => {
    if (options.length) {
      const selectedOption = options.find(option => option.name === value);
      if (selectedOption && !inputValue) {
        setInputValue(selectedOption?.label);
      }
    }
  }, [options]);

  const autoCompleteOptions: { renderOption?: (opt: { name: string }) => React.ReactNode } = {};

  if (isRenderOption) {
    autoCompleteOptions.renderOption = option => <Box>{option.name}</Box>;
  }

  const textFieldOptions: { [key: string]: unknown } = {};
  if (margin) {
    textFieldOptions.margin = margin;
  }

  const getOptionLabel = (option: { label?: string }) => {
    return (option && option.label) || '';
  };

  const getOptionSelected = (option: { name?: string }, value: { name?: string }) => value && option.name === value;

  return (
    <Autocomplete
      {...autoCompleteOptions}
      fullWidth
      forcePopupIcon
      className={cn(className, {
        [classes.analyticsFiltersVariant]: analyticsFiltersDesignVariant,
        [classes.valueChanged]:
        analyticsFiltersDesignVariant && hasValueChanged,
      })}
      inputValue={inputValue}
      onChange={(e, value: { label: string }) => {
        onSelect(value);
        setInputValue(value && value.label ? value.label : '');
      }}
      options={(options as Array<{ [key: string]: unknown }>) || []}
      disabled={loading || disabled}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      value={{ name: value }}
      classes={popperClasses}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldOptions}
          label={label}
          error={Boolean(error)}
          placeholder={t('form_components.select.placeholder')}
          helperText={error}
          className={textFieldClassName}
        />
      )}
    />
  );
};
