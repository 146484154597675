import React, { Dispatch, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { Box, Checkbox, InputAdornment, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import InputTopSide from './InputTopSide';
import { UserDropdownCard } from '../UserDropdownCard';
import { ALL_USERS_KEY, NO_DEPARTMENT_KEY } from '../constants';
import useUserDropdownStyles from '../UserList.useStyles';
import { UserType } from 'types';
import { UserSelectDepartmentData } from 'pages/TemplateProcesses/TemplateProcesses.types';
import { containsText } from '../UserList.utils';

import SearchIcon from 'assets/images/icons/search-icon-thin.svg';
import { setSelectedTask } from 'store/approvals';
import { useDispatch } from 'react-redux';

interface DepartmentsUserListProps {
  allowOnlyOneDepartment?: boolean;
  allowOnlyOneUser?: boolean;
  departments: UserSelectDepartmentData[];
  groupedUserOptions: {};
  isAllSelected: boolean;
  handleUsersSelect: (usersId: string[], usersObject: UserType[]) => void;
  maxUsersCount: number | null;
  searchText: string;
  selectedDepartment: string;
  selectedUsersId: string[];
  setHasChanges: Dispatch<boolean>;
  setIsAllSelected: Dispatch<boolean>;
  setSearchText: Dispatch<string>;
  selectedUsersObject: UserType[];
  setSelectedUsersId: Dispatch<string[]>;
  setSelectedUsersObject: Dispatch<UserType[]>;
  showAllDepartmentsGroup: boolean;
  showWithoutDepartmentView?: boolean;
  userOptions: UserType[];
  users: { [index: string]: UserType; };
  usersInfo: any;
  usersListTopRef: MutableRefObject<any>;
}

const HEIGHT_DIFFERENCE = 500;
const ITEMS_TO_LOAD = 50;

const DepartmentsUserList = (props: DepartmentsUserListProps) => {
  const {
    allowOnlyOneDepartment = false,
    allowOnlyOneUser = false,
    departments,
    groupedUserOptions,
    isAllSelected,
    handleUsersSelect,
    maxUsersCount,
    searchText,
    selectedDepartment,
    selectedUsersId,
    selectedUsersObject,
    setHasChanges,
    setIsAllSelected,
    setSearchText,
    setSelectedUsersId,
    setSelectedUsersObject,
    showAllDepartmentsGroup,
    showWithoutDepartmentView,
    userOptions,
    users,
    usersInfo,
    usersListTopRef,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useUserDropdownStyles({showWithoutDepartmentView});
  const searchInputRef = useRef(null);

  const [usersToShow, setUsersToShow] = useState<UserType[]>([]);
  const [selectedUsersToShow, setSelectedUsersToShow] = useState<UserType[]>([]);
  
  const userDropdownUsersListWrapperClassNames = useMemo(() => classNames({
    [classes.userDropdownUsersListWrapper]: true,
  }), []);

  const displayedSelectedOptions = useMemo((): UserType[] => {
    const usersInfo = selectedUsersId?.map(userId => users[userId]);
    if (usersInfo) {
      const onlyFoundedUsers = usersInfo.filter(Boolean);
      const selectedUsers = onlyFoundedUsers.filter((userItem: UserType) => {
        const namesCorresponds = containsText(userItem.fullName, searchText);
        const departmentsCorresponds = userItem.department === selectedDepartment;
        const userSelectedAllUsers = selectedDepartment === ALL_USERS_KEY;
        const userSelectedNoDepartment = selectedDepartment === NO_DEPARTMENT_KEY;
        const userHaveNoDepartment = !userItem.department;
        return namesCorresponds && (departmentsCorresponds || (userHaveNoDepartment && userSelectedNoDepartment) || userSelectedAllUsers);
      });
      return selectedUsers;
    }
    return [];
  },[selectedDepartment, searchText, selectedUsersId, users]);

  const handleSelectAll = (): void => {
    setHasChanges(true);
    if (selectedDepartment === ALL_USERS_KEY) {
      if (isAllSelected) {
        setIsAllSelected(false);
        setSelectedUsersId([]);
        setSelectedUsersObject([]);
        dispatch(setSelectedTask({
          selectedUsersId: [], selectedUsersObject: [],
        }));
        handleUsersSelect?.([], []);
        return;
      }
      const usersId = displayedOptions.map(userOption => userOption.id);
      setIsAllSelected(true);
      setLimitForSelectedUsers(usersId);
      handleUsersSelect?.(usersId, displayedOptions);
    } else {
      const departmentInfo = departments.find(({ key }) => key === selectedDepartment);

      if (departmentInfo) {
        const departmentUsersId = groupedUserOptions[selectedDepartment].map(userOption => userOption.id);
        const filteredSelectedUsersId = selectedUsersId.filter(userId => !departmentUsersId.includes(userId));
        if (departmentInfo.selected === departmentInfo.total) {
          setLimitForSelectedUsers(filteredSelectedUsersId);
        } else {
          setLimitForSelectedUsers([...filteredSelectedUsersId, ...departmentUsersId]);
        }
      }
    }
  };

  const handleSelectOption = (selectedOption): void => {
    setHasChanges(true);
    if (allowOnlyOneUser) {
      setLimitForSelectedUsers([selectedOption.id]);
      return;
    }
    if (selectedUsersId?.includes(selectedOption.id)) {
      setLimitForSelectedUsers(selectedUsersId.filter(userId => userId !== selectedOption.id));
      return;
    }
    setLimitForSelectedUsers([...selectedUsersId, selectedOption.id]);
  };

  const setLimitForSelectedUsers = useCallback((localSelectedUsersId: string[]) => {
    const localSelectedUsersObject = localSelectedUsersId.map(userId => {
      if (users[userId]) return users[userId];
    });

    if (maxUsersCount) {
      // I want to make sure that selectedUsers count will be correctly represented here.
      if (localSelectedUsersId.length <= maxUsersCount) {
        setSelectedUsersId(localSelectedUsersId);
        setSelectedUsersObject(localSelectedUsersObject);
        handleUsersSelect?.([...localSelectedUsersId], [...localSelectedUsersObject]);
        dispatch(setSelectedTask({
          selectedUsersId: localSelectedUsersId, selectedUsersObject: localSelectedUsersObject,
        }));
      }
      return;
    }
    setSelectedUsersId(localSelectedUsersId);
    setSelectedUsersObject(localSelectedUsersObject);
    handleUsersSelect?.([...localSelectedUsersId], [...localSelectedUsersObject]);
  }, [maxUsersCount, setSelectedUsersId, selectedUsersId, usersInfo]);

  const isUserSelectInCurrentDepartmentAllowed = useMemo(() => {
    if (!allowOnlyOneDepartment) {
      return true;
    }

    const departmentsWithSelection = departments?.filter(department => !!department.selected);
    if (!departmentsWithSelection?.length) {
      return true;
    }

    return selectedDepartment === departmentsWithSelection[0].key;
  }, [allowOnlyOneDepartment, departments, selectedDepartment]);

  const isUserSelectUserAllowed = useCallback((user: UserType) => {
    if (!allowOnlyOneDepartment) {
      return true;
    }

    const departmentsWithSelection = departments?.filter(department => !!department.selected);
    if (!departmentsWithSelection?.length) {
      return true;
    }

    if (!selectedUsersId.length) {
      return true;
    }

    return users[selectedUsersId[0]]?.department === user?.department ||
      (!users[selectedUsersId[0]]?.department && !user?.department);
  }, [allowOnlyOneDepartment, departments, selectedDepartment]);

  const displayedOptions = useMemo((): UserType[] => {
    if (allowOnlyOneUser) {
      return userOptions
        .filter((option: UserType) =>
          containsText(option.fullName, searchText) &&
          (option.department === selectedDepartment ||
            (!option.department && selectedDepartment === NO_DEPARTMENT_KEY) ||
            selectedDepartment === ALL_USERS_KEY
          ),
        );
    }
    return userOptions
      .filter((option: UserType) =>
        containsText(option.fullName, searchText) &&
        (option.department === selectedDepartment ||
          (!option.department && selectedDepartment === NO_DEPARTMENT_KEY) ||
          selectedDepartment === ALL_USERS_KEY
        ) &&
        !selectedUsersId?.includes(option.id),
      );
  }, [userOptions, selectedDepartment, searchText, selectedUsersId, allowOnlyOneUser]);

  const selectAllState = useMemo((): { checked: boolean, indeterminate: boolean } => {
    if (selectedDepartment === ALL_USERS_KEY) {
      return {
        checked: selectedUsersId?.length === userOptions?.length,
        indeterminate: selectedUsersId?.length > 0,
      };
    }

    const departmentObject = departments.find(({ key }) => key === selectedDepartment);
    return {
      checked: departmentObject.selected === departmentObject.total,
      indeterminate: departmentObject.selected > 0,
    };
  }, [selectedDepartment, departments]);

  const displaySelectedUsers = useMemo(() => {
    if (selectedDepartment === ALL_USERS_KEY) {
      return `${t('form_components.select_users.select_all_users')} (${selectedUsersId?.length}/${userOptions?.length})`;
    }

    const departmentObject = departments.find(({ key }) => key === selectedDepartment);
    return `${t('form_components.select_users.select_all_members')} (${departmentObject?.selected}/${departmentObject?.total})`;
  }, [selectedDepartment, departments]);

  const onScrollFrame = ({scrollTop, scrollHeight}) => {
    if (scrollTop + HEIGHT_DIFFERENCE >= scrollHeight) {
      const startIndex = usersToShow.length;
      const startIndexSelectedUsers = selectedUsersToShow.length;
      let lastIndex = startIndex + ITEMS_TO_LOAD;
      let lastIndexSelectedUsers = startIndexSelectedUsers + ITEMS_TO_LOAD;
      if (lastIndex >= displayedOptions.length) {
        lastIndex = displayedOptions.length;
      } else if (lastIndexSelectedUsers >= displayedSelectedOptions.length) {
        lastIndexSelectedUsers = displayedSelectedOptions.length;
      }
      const needToLoad = displayedOptions.slice(startIndex, lastIndex);
      const needToLoadSelectedUsers = displayedSelectedOptions.slice(startIndexSelectedUsers, lastIndexSelectedUsers);
      setUsersToShow([...usersToShow, ...needToLoad]);
      setSelectedUsersToShow([...selectedUsersToShow, ...needToLoadSelectedUsers]);
    }
  }

  useEffect(() => {
    setUsersToShow(displayedOptions.slice(0, ITEMS_TO_LOAD));
    setSelectedUsersToShow(displayedSelectedOptions.slice(0, ITEMS_TO_LOAD));
  }, [displayedOptions, displayedSelectedOptions])

  return (
    <Box className={userDropdownUsersListWrapperClassNames}>
      <Scrollbars className={classes.listScrollbars} onScrollFrame={onScrollFrame}>
        <div ref={usersListTopRef}/>
        <Box className={classes.userDropdownMenuSearchWrapper}>
          <TextField
            autoFocus
            fullWidth
            size="small"
            value={searchText}
            inputRef={searchInputRef}
            onClick={(_event) => {
              searchInputRef?.current?.focus();
            }}
            placeholder={t('form_components.select.search_placeholder')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} className={classes.userDropdownIcon}/>
                </InputAdornment>
              ),
            }}
            className={classes.userDropdownMenuSearchTextField}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
          />
        </Box>
        {
          <>
            <Box className={classes.usersListTopPartWrapper}>
              {
                !showWithoutDepartmentView &&
                  <InputTopSide
                    allowOnlyOneDepartment={allowOnlyOneDepartment}
                    displaySelectedUsers={displaySelectedUsers}
                    handleSelectAll={handleSelectAll}
                    isUserSelectInCurrentDepartmentAllowed={isUserSelectInCurrentDepartmentAllowed}
                    selectAllState={selectAllState}
                    selectedDepartment={selectedDepartment}
                    showAllDepartmentsGroup={showAllDepartmentsGroup} />
              }
              {!allowOnlyOneUser && <>
                {selectedUsersToShow?.map((option: UserType) => (
                  <Box
                    key={option.id}
                    className={classes.menuItem}
                    onClick={() => isUserSelectUserAllowed(option) && handleSelectOption(option)}>
                    <Checkbox
                      color="default"
                      checked={selectedUsersId.includes(option.id)}
                      disabled={!isUserSelectUserAllowed(option)}
                      className={classNames(classes.UserDropdownListCheckbox, classes.checkbox,
                        {
                          [classes.checkboxDisabled]: !isUserSelectUserAllowed(option),
                          [classes.checkboxChecked]: selectedUsersId.includes(option.id),
                        })}/>
                    <UserDropdownCard
                      key={option.id}
                      user={option}
                      showDepartmentName={showAllDepartmentsGroup && selectedDepartment === ALL_USERS_KEY}
                      departments={departments}/>
                  </Box>
                ))}
              </>}
            </Box>

            {usersToShow.map((option: UserType) => (
              <Box key={option.id} className={classes.menuItem}>
                <Box
                  key={option.id}
                  className={classNames(classes.menuItem, { [classes.menuItemSingleChoice]: allowOnlyOneUser })}
                  onClick={() => isUserSelectUserAllowed(option) && handleSelectOption(option)}
                >
                  {!allowOnlyOneUser && <Checkbox
                    color="default"
                    checked={selectedUsersId?.includes(option.id)}
                    disabled={!isUserSelectUserAllowed(option)}
                    className={classNames(classes.UserDropdownListCheckbox, classes.checkbox,
                      {
                        [classes.checkboxDisabled]: !isUserSelectUserAllowed(option),
                        [classes.checkboxChecked]: selectedUsersId?.includes(option.id),
                      })}
                  />
                  }
                  <UserDropdownCard
                    key={option.id}
                    user={option}
                    showDepartmentName={showAllDepartmentsGroup && selectedDepartment === ALL_USERS_KEY}
                    departments={departments}/>
                </Box>
              </Box>
            ))}
          </>
        }
      </Scrollbars>
    </Box>
  );
};

export default DepartmentsUserList;
