import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import useAutostartCheckboxStyles from './AutostartCheckbox.useStyles';
import { AllProcessFieldsListItem, DocumentTemplate, ProcessStep } from '../../TemplateProcesses.types';

import InfoIcon from 'assets/images/icons/info_outline.svg';
import { ReactComponent as CheckboxUncheckedIcon } from 'assets/images/icons/checkbox-unchecked.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/images/icons/checkbox-checked.svg';

interface AutostartCheckboxProps {
  currentStep: ProcessStep;
  value: boolean;
  disabled?: boolean;
  handleAutostartToggle: (value: boolean) => void;
}

export const AutostartCheckbox = ({
  currentStep,
  value,
  disabled = false,
  handleAutostartToggle,
}: AutostartCheckboxProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useAutostartCheckboxStyles();

  const helpTooltipContent =
    <div className={classes.helpTooltipContent}>
      <p>
        {t('customProcesses.creationPage.processForm.autostartCheckboxHelp1')}
      </p>
      <p>
        <Trans i18nKey="customProcesses.creationPage.processForm.autostartCheckboxHelp">
        <b>Внимание:</b> Настройка автостарта будет доступна после выбора данной опции
        </Trans>
      </p>
    </div>;

  return (
    <Box
      className={cn(classes.DirectManagerCheckbox)}>
      {currentStep?.stepOrder === 1 && (
        <>
          <Box mt={1} className={classes.checkboxWrapper}>
            <FormControlLabel
              className={cn(classes.checkbox)}
              control={<Checkbox
                checked={value}
                color="default"
                icon={<CheckboxUncheckedIcon/>}
                checkedIcon={<CheckboxCheckedIcon/>}
                disabled={disabled}
                classes={{
                  disabled: classes.checkboxDisabled,
                  checked: classes.checkboxChecked,
                }}
                onChange={e => handleAutostartToggle(e?.target?.checked)}
              />}
              classes={{label: classes.checkboxLabel}}
              label={t('customProcesses.creationPage.processForm.autostartCheckboxLabel')}
            />

            <Tooltip
              classes={{
                popper: classes.tooltipPopper,
                tooltip: classes.helpTooltip,
              }}
              arrow
              placement="bottom"
              title={helpTooltipContent}
              leaveDelay={500}
              interactive
            >
              <img src={InfoIcon} className={classes.infoIcon}/>
            </Tooltip>
          </Box>
        </>
      )}
    </Box>
  );
};
