import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  modalWrapperOpen: {
    '& > div:first-child': {
      backgroundColor: 'rgba(38, 40, 66, 0.40) !important',
    },
  },
  modalBody: {
    width: 1200,
    maxHeight: 600,
    background: '#fff',
    border: '1px solid rgba(38, 40, 66, 0.08)',
    boxShadow: '0px 16px 32px rgba(38, 40, 66, 0.08)',
    borderRadius: 12,
    outline: 'none',

    overflow: 'hidden',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99,
  },
  modalHeader: {
    padding: 20,
    paddingBottom: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 16px 32px 0px #26284214',
  },
  modalContent: {
    padding: 20,
    paddingTop: 5,
    overflowY: 'scroll',
    maxHeight: 'calc(600px - 66px - 65px)',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'end',
    gap: 12,
    padding: '12px 24px',
    background: '#F0F0F7',
    borderTop: '1px solid rgba(38, 40, 66, 0.08);',
  },
  modalButton: {
    borderRadius: 10,
    height: 40,
  },
  closeIcon: {
    '& > path': {
      fill: '#6D7C8B',
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: '#262842',
  },
  cardPreviewInfoWrapper: {
    marginBottom: 16,
  },
  cardPreviewInfoTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#262842',
    margin: 0,
    marginBottom: 2,
  },
  cardPreviewInfoMessage: {
    fontSize: 14,
    fontWeight: 400,
    color: '#6D6E85',
    margin: 0,
  },
  card: {
    width: '100%',
    padding: '12px 16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 16,
    borderRadius: 12,
    border: '1px solid rgba(38, 40, 66, 0.12)',
    boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.08)',
  },
  cardLeftSide: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  processBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  placeholderBlock: {
    borderRadius: 5,
    background: '#F0F0F7',
  },
  placeholderText: {
    color: '#A9A9B8',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
  },
  placeholderTextLarger: {
    fontSize: 14,
  },
  placeholderIcon: {
    width: 20,
    height: 20,
    '& > path': {
      fill: '#A9A9B8',
    },
  },
  processNamePlaceholder: {
    width: 67,
    height: 10,
  },
  processNamePlaceholderText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 616,
  },
  summaryBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  summaryField: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  summaryFieldHintWrapper: {
    maxWidth: 200,
    width: 'fit-content',
  },
  summaryFieldHint: {
    maxWidth: 200,
    width: 'fit-content',
    color: '#6D6E85',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    'line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  summaryFieldValue: {
    color: '#262842',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    '& span': {
      color: '#6D6E85',
    },
  },
  cardTooltipPopper: {
    pointerEvents: 'all',
  },
  cardTooltip: {
    color: '#F7F8FC',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    maxWidth: 240,
    width: 'fit-content',
    padding: '8px 12px',
    borderRadius: 10,
  },
  cardTooltipLongText: {
    color: '#F7F8FC',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    maxWidth: 320,
    width: 'fit-content',
    padding: '8px 12px',
    borderRadius: 10,
  },
  processDescription: {
    color: '#262842',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    maxWidth: 640,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 2,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
  },
  statusBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  initiatorBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  processStatusPlaceholderWrapper: {
    width: 18,
    height: 18,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  processStatusPlaceholder: {
    width: 9,
    height: 9,
  },
  cardRightSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  statusIndicatorPlaceholder: {
    position: 'relative',
    width: 48,
    height: 48,
  },
  wrapperCircle: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: '#EBEEF0',
  },
  progressCircle: {
    strokeLinecap: 'round',
    stroke: '#E0E0E8',
  },
  possibleFieldsTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#262842',
    marginBottom: 16,
    marginTop: 32,
  },
  possibleFieldsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  possibleFieldCard: {
    height: 52,
    width: '100%',
    paddingLeft: 12,
    paddingRight: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #2628421F',
    borderBottom: 'none',
    '&:first-child': {
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },
    '&:last-child': {
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
      borderBottom: '1px solid #2628421F',
    },
  },
  possibleFieldCardInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  possibleFieldCardIcon: {
    '& img': {
      width: 20,
      height: 20,
    },
  },
  possibleFieldCardNameAndType: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  possibleFieldName: {
    width: 'fit-content',
    maxWidth: 1028,
    fontSize: 14,
    fontWeight: 400,
    color: '#262842',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  possibleFieldType: {
    fontSize: 12,
    fontWeight: 400,
    color: '#6D6E85',
  },
  possibleFieldCardToggle: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  track: {
    backgroundColor: '#2628420A !important',
    borderColor: '#2628420A !important',
  },
  trackChecked: {
    backgroundColor: '#039BE6 !important',
    borderColor: '#039BE6 !important',
  },
  trackDisabled: {
    backgroundColor: '#26284214 !important',
    borderColor: '#26284214 !important',
  },
  circleDisabled: {
    backgroundColor: '#2628421F !important',
    borderColor: '#2628421F !important',
  },
  switchBase: {
    top: 0.8,
  },
  possibleFieldsPlaceholder: {
    fontSize: 14,
    fontWeight: 400,
    color: '#8B8C9E',
  },
  conditionFieldPreviewHint: {
    color: '#262842',
    fontWeight: 400,
  },
}));
