import { makeStyles } from '@material-ui/core';
import { UserDropdownProps } from 'pages/Task/TaskForm/types';

const increaseWidthForUserList = ({showWithoutDepartmentView}: UserDropdownProps) => showWithoutDepartmentView ? '100%' : '50%';

export default makeStyles(({ spacing, palette }) => ({
  userDropdownWrapper: {
    position: 'relative',
    maxWidth: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  userDropdownFieldWrapper: {
    maxWidth: '100%',
    width: '100%',
    minWidth: 300,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: "wrap"
  },
  userDropdownFieldDeleteButtonWrapper: {
    width: 40,
    height: 40,
    marginLeft: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  labelWrapper: {
    width: 'max-content',
    maxWidth: '100%',
    position: 'relative',
    marginRight: 4,
    marginBottom: 4,
  },
  label: {
    display: 'inline !important'
  },
  userDropdown: {
    position: 'relative',
    maxWidth: "100%",
    width: "100%",
    height: 40,

    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: 10,
    padding: spacing(2.5, 2.5, 2.5, 2),

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  userDropdownDisabled: {
    cursor: 'auto',
    '&, &:hover': {
      backgroundColor: '#F0F0F7',
      color: '#A9A9B8',
    },
  },
  userDropdownActive: {
    transform: 'rotate(180deg)',
  },
  userDropdownError: {
    border: '1px solid #D6331F',
  },
  processStepFormErrorMessage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: spacing(1),
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#D6331F',
    '& img': {
      marginRight: spacing(1.25),
      height: 13,
      width: 13,
    },
  },
  parallelAssigneeDropdown: {
    marginBottom: 12,
  },
  addAssigneeButton: {
    width: 'fit-content',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: '#007994',
    '&:hover': {
      color: '#007994',
    }
  },
  userDropdownTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: '#101F2B',
  },
  userDropdownTitleDisabled: {
    color: palette.grey[500],
  },
  userDropdownValue: {
    fontSize: 16,
    fontWeight: 400,
    color: '#8A96A1',
  },
  userDropdownValueCount: {
    color: '#101F2B',
    fontSize: 14,
    fontWeight: 600,
  },
  userCardFullName: {
    fontSize: 14,
    fontWeight: 400,
    color: '#262842',
    paddingRight: 4,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 1,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    marginLeft: 8
  },
  userCardDepartment: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#8B8C9E',
    paddingRight: 4,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 1,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
  },
  userDropdownIcon: {
    transition: '0.3s transform ease',

    '& > path': {
      fill: '#6D7C8B !important',
    },
  },
  userDropdownMenuPopper: {
    zIndex: 1400,
    right: 0,
  },
  userDropdownMenu: {
    display: 'none',
    position: 'absolute',
    top: 4,
    left: 0,

    width: '100%',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E0E3EA',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.12)',
    borderRadius: spacing(2.5),
  },
  userDropdownDepartmentsListDepartmentName: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 2,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
  },
  userDropdownDepartmentsListItemRightPart: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& img': {
      marginLeft: 8
    }
  },
  userDropdownMenuActive: {
    display: 'block',
    zIndex: 1300,
  },
  userDropdownMenuHeader: {
    display: 'flex',
    flexDirection: 'row',
    height: 48,
    borderRadius: spacing(2.5, 2.5, 0, 0),
  },
  userDropdownDepartmentsTitleWrapper: {
    borderBottom: '1px solid #E0E3EA',
    borderRight: '1px solid #E0E3EA',
    width: increaseWidthForUserList,
    paddingLeft: spacing(3),
    backgroundColor: '#F5F8FA',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userDropdownUsersListTitleWrapper: {
    borderBottom: '1px solid #E0E3EA',
    borderRight: '1px solid #E0E3EA',
    width: increaseWidthForUserList,
    paddingLeft: spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userDropdownHeaderTitle: {
    fontWeight: 500,
    fontSize: 16,
    color: '#6D6E85',
  },
  userDropdownMenuSearchWrapper: {
    padding: spacing(2, 3),
    lineHeight: 'initial',
    backgroundColor: '#F7F8FC',
  },
  userDropdownMenuSearchTextField: {
    backgroundColor: '#FFFFFF',
    height: 32,
    '& .MuiInput-root': {
      borderRadius: spacing(2),
      paddingTop: 0,
      paddingBottom: 0,
      height: 32,
      '& input': {
        height: '100%',
      },
    },
  },
  userDropdownMenuContent: {
    display: 'flex',
    flexDirection: 'row',
    height: 250,
    overflow: 'auto',
  },
  listScrollbars: {
    height: '100%',
    width: '100%',
    minHeight: '100%',
    minWidth: '100%',
  },
  userDropdownDepartmentsListWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    width: '50%',
    overflow: 'auto',
    backgroundColor: '#F7F8FC',
    borderRight: '1px solid rgba(38, 40, 66, 0.08)',
  },
  userDropdownDepartmentsList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  userDropdownDepartmentsListItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    minHeight: 40,
    '&:hover': {
      background: 'rgba(38, 40, 66, 0.08)',
    },
    paddingLeft: spacing(3),
    paddingRight: spacing(4),
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#262842',
    cursor: 'pointer',
  },
  userDropdownDepartmentsListItemActive: {
    background: 'rgba(38, 40, 66, 0.08)',
  },
  userDropdownUsersListWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    width: increaseWidthForUserList,
    overflow: 'auto',
  },
  fullWidth: {
    width: '100%'
  },
  UserDropdownListCheckbox: {
    marginLeft: spacing(1.5),
    marginRight: spacing(0.5),
    '&.MuiCheckbox-indeterminate': {
      color: '#1B74B4',
    },
  },
  UserDropdownListSelectAll: {
    height: 44,
    minHeight: 44,
    width: '100%',
    marginRight: 0,
    marginBottom: 2,
    display: 'inline-flex !important'
  },
  usersListTopPartWrapper: {
    borderBottom: '1px solid rgba(38, 40, 66, 0.08)',
    width: '100%',
    marginRight: 0,
    marginBottom: 2,
  },
  UserDropdownListWarningMessage: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#262842',
    backgroundColor: '#FFE8C2',
    borderRadius: 4,
    margin: spacing(2, 3),
    padding: spacing(1, 2),
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0),
    cursor: 'pointer',
    minHeight: 40,
    height: 'fit-content',
    '& label': {
      width: '100%',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
    },
  },
  menuItemSingleChoice: {
    padding: spacing(2, 4),
    width: '100%',
    cursor: 'pointer',
  },
  menuItemImage: {
    height: 24,
    width: 24,
    borderRadius: '50%',
  },
  userDropdownMenuFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    padding: spacing(2),
    borderTop: '1px solid #E0E3EA',
    color: '#007994',
  },
  assigneesTooltipPopper: {
    pointerEvents: 'all',
  },
  assigneesTooltip: {
    maxHeight: 180,
    padding: 10,
    fontSize: 12,
    fontWeight: 500,
    color: palette.grey[50],
  },
  tooltipContent: {
    height: 160,
    width: 160,
    maxHeight: 160,
    maxWidth: 160,
    overflowY: 'auto',
    '& ul': {
      margin: 0,
      padding: 0,
      paddingLeft: 20,
    },
  },
  checkbox: {
    '& svg path': {
      fill: '#8B8C9E !important',
    },
  },
  checkboxChecked: {
    '& svg path': {
      fill: '#007994 !important',
    },
  },
  checkboxDisabled: {
    '& svg path': {
      fill: palette.grey[300] + ' !important',
    },
  },
  placeholder: {
    color: "#A7ACB1",
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    fontSize: 16,
    fontWeight: 400
  },
  previewPlaceholder: {
    width: "100%",
    paddingLeft: 5,
    textAlign: 'start',
    color: "#A7ACB1",
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: 16,
  },
  placeholderErrorColor: {
    color: '#E02E14',
  },
  errorText: {
    fontFamily: 'Golos, Helvetics, sans-serif',
    color: '#E02E14',
    fontWeight: 400,
    fontSize: 12,
    margin: 0,
    marginTop: 2,
    lineHeight: "18px",
    letterSpacing: "0.03333em"
  },
  emptyValueMessage: {
    fontSize: 14,
    color: '#8B8C9E',
    fontStyle: 'normal',
    fontWeight: 400,
  }
}));
