import React, { ReactElement } from 'react';

import { Modal, Backdrop, Fade } from '@material-ui/core';
import classNames from 'classnames';

import useStyles from './ModalContainer.styles';

interface ModalProps {
  children: ReactElement;
  fullWidth?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const ModalContainer = (props: ModalProps) => {
  const {children, fullWidth, isOpen, onClose} = props;

  const classes = useStyles();

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    onClose?.();
  };
  
  return (
    <Modal 
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{timeout: 500}}
      className={classNames(classes.modal)}>
      {children && (
        <Fade in={Boolean(open)}>
          <div className={classNames(classes.content, { [classes.flexGrow]: fullWidth })}>
            {children}
          </div>
        </Fade>
      )}
    </Modal>
  )
};

export default ModalContainer;
