import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  modal: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2000,
    overflow: 'hidden',
    background: 'rgba(38, 40, 66, 0.40)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalBody: {
    width: 500,
    background: '#fff',
    border: "1px solid rgba(38, 40, 66, 0.08)",
    boxShadow: "0px 16px 32px rgba(38, 40, 66, 0.08)",
    borderRadius: 12,
  },
  modalHeader: {
    padding: 24,
    paddingBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalContent: {
    padding: 24,
    paddingTop: 0,
    paddingBottom: 0,
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'end',
    gap: 8,
    padding: "12px 16px",
    background: '#F0F0F7',
    borderTop: '1px solid rgba(38, 40, 66, 0.08);',
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
  modalButton: {
    borderRadius: 10,
    height: 40,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: '#262842'
  },
  description: {
    marginBottom: 60,
    fontSize: 16,
    color: '#8B8C9E',
  }
}))
