import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, ButtonBase, IconButton, Typography } from '@material-ui/core';
import useStyles from "./useStyles";
import { CloseButton, Spinner } from 'components';
import { ProcessContextType } from 'types';
import ProcessContext from 'contexts/ProcessContext';
import { useTranslation } from 'react-i18next';
import { useDraggable } from 'react-use-draggable-scroll';
import { useSetupTemplateProcessDialog } from 'pages/TemplateProcesses/components/SetupTemplateProcessesDialog/hooks/useSetupTemplateProcessDialog';
import { Language, ProcessStep } from 'pages/TemplateProcesses/TemplateProcesses.types';
import { PROCESS_STEP_CARD_ERROR_WRAPPER_CLASSNAME, ProcessStepList } from 'pages/TemplateProcesses/components';
import { useUsersState } from 'store/users';

import ChevronRight from "assets/images/icons/chevron_right.svg"
import ChevronLeft from "assets/images/icons/chevron_left.svg"
import { DM_CHECK_ASSIGNEE, PERFORMER_STEP_ASSIGNEE } from 'pages/TemplateProcesses/TemplateProcesses.constants';

type Props = {
  data: any;
  onClose: () => void;
  handleStartProcess: (e: any, process: any) => void;
  loading: boolean;
}

export const FlowPreview = ({data, onClose, handleStartProcess, loading}: Props) => {
  const classes = useStyles();
  const { groups }: Partial<ProcessContextType> = useContext(ProcessContext);
  const { t, i18n } = useTranslation();

  const { users } = useUsersState();

  const [errors, setErrors] = useState<number[]>([]);

  const gridPathRef = useRef(null);
  const { events: draggableScrollEvents } = useDraggable(gridPathRef);

  const [focusedStepOrder, setFocusedStepOrder] = useState(null);

  const {
    allProcessStepsFields,
    initiateProcessSteps,
    currentLanguage,
    setCurrentLanguage,
    languages,
    formErrors,
    directManagerSteps,
    performerSelectionSteps,
    setFormErrors,
    documents
  } = useSetupTemplateProcessDialog({
    isOpen: false,
    initialValues: data,
    publishedVersionValues: data,
    templateSettings: null,
    haveTemplateSettingsChanged: false,
    onClose: () => {
      //
    },
    onFormSubmit: () => {
      //
    },
    onBackButtonClick: () => {
      //
    },
    setTemplateSettings: () => {
      //
    },
    needResetProcessForm: false,
    setNeedResetProcessForm: null,
    handleAutostartActionsClick: null,
    handlePublishedProcessesLimitDialogOpen: null,
  });

  useEffect(() => {
    const processLanguages = data?.languages.map(({ language }): Language => language);
    if (processLanguages.includes(i18n.language)){
      setCurrentLanguage(i18n.language as Language);
    } else {
      setCurrentLanguage(processLanguages[0]);
    }

    const result = []

    data.steps.forEach(step => {
      if (step.stepOrder === 1) return;
      step.assigneeId.forEach(assignee => {
        if (![DM_CHECK_ASSIGNEE, PERFORMER_STEP_ASSIGNEE].includes(assignee) && !users[assignee]) {
          result.push(step.stepOrder)
        }
      })
    })

    if(result.length){
     setErrors([...new Set(result)])
     setFocusedStepOrder(0)
    }
  }, [data, users])

  const {
    conditions: previewConditions,
    parallelStepsGroups: previewParallelStepsGroups,
    processSteps: previewProcessSteps,
  } = useMemo(() =>
      initiateProcessSteps(data)
    , []);

  useEffect(() => {
    if (errors.length === 0) return;
    if (typeof focusedStepOrder === "number") {
      setFormErrors(
        previewProcessSteps.map(step => {
          if (errors[focusedStepOrder] === step.stepOrder){
            return {
              stepName: [],
              stepDescription: [],
              errorFieldsIds: [],
              scriptName: [],
              parallelBranchesName: [],
              lengthLimit: {
                parallelBranchesName: [],
                scriptName: [],
                stepName: []
              },
              fields: false,
              time: false,
              // set error
              assigneeId: true,
            }
          }

          // pass empty errors to pass check
          return {
            stepName: [],
            stepDescription: [],
            errorFieldsIds: [],
            scriptName: [],
            parallelBranchesName: [],
            lengthLimit: {
              parallelBranchesName: [],
              scriptName: [],
              stepName: []
            },
            assigneeId: false,
            fields: false,
            time: false

          }
        })
      )
    }
  }, [focusedStepOrder, errors])

  useLayoutEffect(() => {
    if (typeof focusedStepOrder !== "number") return;
    const el = gridPathRef.current?.querySelector(`.${PROCESS_STEP_CARD_ERROR_WRAPPER_CLASSNAME}`)

    if (el) {
      el.scrollIntoView({block: "center", behavior: "smooth"})
    }

  }, [formErrors])

  const localizedProcessName = useMemo(() => {
    const processNameKey = `constructor-${data?.processSysName}.name`;
    return t(processNameKey, {defaultValue: data?.processName || ''})
  }, [data]);

  const currentTaskGroup = useMemo(() => {
    return groups.find(g => g?.sysName === data.category)
  }, [data, groups])

  const flowPreviewError = useMemo(() => {
    return errors.length > 0 && <Box className={classes.errorBox}>
    <Typography>{t("errors.errorsFound", {
      defaultValue: "errors found"
    })}</Typography>

    <Box display="flex" alignItems="center" gridGap={8}>
      <Typography>
      {t("AdminPanel.table.step", {
        stepperOrder: focusedStepOrder + 1,
        totalCountOfSteps: errors.length,
        defaultValue: `${focusedStepOrder + 1} - ${errors.length}`
      })}
      </Typography>

      <ButtonBase onClick={() => {
        if (focusedStepOrder === 0){
          setFocusedStepOrder(errors.length - 1)
        } else {
          setFocusedStepOrder(focusedStepOrder - 1)
        }
        }}
        className={classes.controlBtn}
      >
        <img src={ChevronLeft} alt='' />
      </ButtonBase>

      <ButtonBase
        onClick={() => {
          if (focusedStepOrder + 1 === errors.length){
            setFocusedStepOrder(0)
          } else {
            setFocusedStepOrder(focusedStepOrder + 1)
          }
        }}
        className={classes.controlBtn}
        >
         <img src={ChevronRight} alt='' />
      </ButtonBase>
    </Box>
</Box>
  }, [classes.controlBtn, classes.errorBox, errors.length, focusedStepOrder, t])

  return <Box className={classes.root}>
    <Box className={classes.content}>

      <Box className={classes.header}>
      <Box display="flex" justifyContent="center" flexDirection="column" gridGap={6} overflow="hidden">
          {currentTaskGroup && <Box className={classes.groupName}>
              <img src={currentTaskGroup.iconPath} alt='' />
              <span>{t(`groups.${currentTaskGroup.sysName}.name`, { defaultValue: currentTaskGroup?.name })}</span>
            </Box>}
          <Typography variant="h3" className={classes.title}>
            {localizedProcessName}
          </Typography>
        </Box>

        <Box flexShrink={0}>
          <Button className={classes.startBtn} disabled={errors.length > 0} onClick={(e) => handleStartProcess(e, data)}>
          {t("customProcesses.creationPage.buttons.start")}
          {loading && <Spinner size={14} white />}
          </Button>
          <CloseButton onClick={onClose} />
        </Box>
      </Box>

      <div
        className={classes.flowWrapper}
      >
        {flowPreviewError}

        <div
          {...draggableScrollEvents}
          ref={gridPathRef}
          className={classes.flow}
          style={{paddingTop: errors.length < 1 ? 56 : undefined}}
        >

        <ProcessStepList
          isFlowPreview
          isReadonlyView
          isEditDisabled
          currentLanguage={currentLanguage}
          processSteps={previewProcessSteps as unknown as ProcessStep[]}
          conditions={previewConditions}
          parallelBranchesGroups={previewParallelStepsGroups}
          documents={documents}
          handleParallelStepsGroupSelect={() => {
            //
          }}
          handleAddProcessConditionStep={() => {
            //
          }}
          handleAddParallelStepsGroup={() => {
            //
          }}

          allProcessStepsFields={allProcessStepsFields}
          handleAddPreviousStep={() => {
            //
          }}
          handleAddProcessStep={() => {
            //
          }}
          handleParallelBranchStepAdd={() => {
            //
          }}
          handleConditionBranchStepAdd={() => {
            //
          }}

          // reset
          currentStepIndex={null}
          currentConditionId={null}
          currentParallelBranchesGroupId={null}
          selectedItemType={null}

          formErrors={formErrors}
          departmentsList={[]}
          directManagerSteps={directManagerSteps}
          performerSelectionSteps={performerSelectionSteps}
          isAutostartActive={false}
          handleProcessSelect={() => {
            //
          }}
          handleConditionSelect={null}
          openBranchStepsLimitReachedDialog={() => {
            //
          }}
          openStepsLimitReachedDialog={() => {
            //
          }}
        />
        </div>
      </div>
    </Box>
  </Box>
}
