import React, { ReactElement } from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import useAutostartActivationNotPossibleDialogStyles from './AutostartActivationNotPossibleDialog.useStyles';

interface AutostartActivationNotPossibleDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AutostartActivationNotPossibleDialog = ({
  isOpen,
  onClose,
}: AutostartActivationNotPossibleDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useAutostartActivationNotPossibleDialogStyles();

  return (
    <Modal open={isOpen}>
      <Box className={classes.modalBody}>
        <div className={classes.modalHeader}>
          <Typography className={classes.title}>
            {t('customProcesses.creationPage.processForm.autostartActivationNotPossibleTitle')}
          </Typography>

          <IconButton onClick={onClose}>
            <CloseIcon className={classes.closeIcon}/>
          </IconButton>
        </div>

        <div className={classes.modalContent}>
          <Typography className={classes.description}>
            {t('customProcesses.creationPage.processForm.autostartActivationNotPossibleMessage')}
          </Typography>
        </div>

        <div className={classes.modalFooter}>
          <Button variant="contained" color="secondary" className={classes.modalButton} onClick={onClose}>
            {t('template_outdated_modal.button_close')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
