import { makeStyles } from "@material-ui/core";

export default makeStyles(({spacing}) => ({
  root: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    animation: "$fade 1.5s",
    zIndex: 1,

    background: "rgba(38, 40, 66, 0.40)"
  },
  card: {
    height: "100%",
    width: 960,
    marginLeft: "auto",
    position: "relative",

    display: "flex",
    flexDirection: "column",
    background: "white"
  },
  cardHeader: {
    padding: "12px 24px",
    borderBottom: "1px solid rgba(38, 40, 66, 0.08)",

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

  },
  tabs: {
    padding: "0 24px",
    borderBottom: "1px solid rgba(38, 40, 66, 0.12)",
  },
  content: {
    background: "#F7F8FC",
    flexGrow: 1,
    padding: "12px 24px",
    overflowY: "auto",

    "& h3": {
      color: "#262842",
      fontSize: 18,
      fontWeight: 500,
      display: "flex",
      alignItems: "center",

      "& img": {
        width: 28,
        height: 28,
        borderRadius: 4,
        marginRight: 12,
      }
    },
    "& h4": {
      marginTop: 18,
      color: "#262842",
      fontSize: 16,
      fontWeight: 500
    },
    "& p": {
      marginTop: 12,
      color: "#262842",
      fontSize: 14,
      fontWeight: 400
    },
  },
  footer: {
    padding: "12px 24px",
    display: "flex",
    justifyContent: "end",
    gap: 12,

    borderTop: "1px solid rgba(38, 40, 66, 0.08)",

    "& button": {
      height: 40
    }
  },
  groupIcon: {
    width: 16,
    height: 16,
    marginRight: 8,
    borderRadius: 4
  },
  headerInfo: {
    overflow: "hidden",
    "& h6": {
      display: "flex",
      alignItems: "center",
      color: "#6D6E85",
      fontSize: 14,
      fontWeight: 400
    },
    "& p": {
      color: "#262842",
      fontSize: 20,
      fontWeight: 400,
      lineHeight: "24px",
      marginTop: 6,
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  tab: {
    fontSize: 14,
    fontWeight: 500,
    color: '#8B8C9E',
    textTransform: 'none',
    padding: spacing(2.5, 3),
    height: 40,
    borderBottom: '2px solid transparent',
    maxWidth: "fit-content",
    minWidth: '100px',
    '&.Mui-selected': {
      border: "none",
      borderBottom: '2px solid #007994',
      color: '#007994',
    },
  },
  tabContent: {
    background: "white",
    borderRadius: 12,
    padding: "8px 16px",
    border: "1px rgba(38, 40, 66, 0.12)"
  },
  fields: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    marginTop: 12
  },
  "@keyframes fade": {
    "0%": {
      background: "none"
    },
    "100%": {
      background: "rgba(38, 40, 66, 0.40)"
    }
  },
  historyTab: {
    background: "#f7f8fc"
  }
}))
