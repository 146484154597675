import { makeStyles } from '@material-ui/core';

export default makeStyles(({palette, spacing}) => ({
  modalBody: {
    width: 500,
    background: '#fff',
    border: '1px solid rgba(38, 40, 66, 0.08)',
    boxShadow: '0px 16px 32px rgba(38, 40, 66, 0.08)',
    borderRadius: 12,

    overflow: 'hidden',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99,
    outline: 'none'
  },
  modalHeader: {
    padding: 24,
    paddingBottom: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalContent: {
    padding: 24,
    paddingTop: 4,
    paddingBottom: 32,
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'end',
    gap: 12,
    padding: '12px 24px',
    background: '#F0F0F7',
    borderTop: '1px solid rgba(38, 40, 66, 0.08);',
  },
  modalButton: {
    borderRadius: 10,
    height: 40,
  },
  closeIcon: {
    '& > path': {
      fill: '#6D7C8B',
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: '#262842',
  },
  description: {
    marginBottom: 60,
    fontSize: 16,
    color: '#6D6E85',
  },
  infoCard: {
    padding: 12,
    borderRadius: 12,
    marginTop: 16,
    border: '1px solid rgba(38, 40, 66, 0.12)',
    background: '#EBF5FC',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,

    '& p': {
      margin: 0,
      marginBottom: 8,
    },

    '& ul': {
      margin: 0,
      padding: 0,
      paddingLeft: 20,
      marginBottom: 8,
    },

    '& p:last-child': {
      marginBottom: 0,
    }
  },
  nameInput: {
    '& label': {
      fontWeight: 500,
      color: "#6D6E85",
      fontSize: 14,
      position: 'relative',
      display: 'block',
      width: 'fit-content',
      fontFamily: 'Euclid, sans-serif !important',
    },

    '& div': {
      marginTop: 0,
      borderRadius: 12,
      borderColor: 'rgba(38, 40, 66, 0.12)'
    },

    '& input': {
      fontSize: '14px !important',
      fontWeight: 400,
      color: '#262842',
    }
  },
  nameInputRequired: {
    '& label': {
      '&::after': {
        display: 'inline-block',
        content: '"*"',
        color: palette.error[600],
        fontSize: 14,
        position: 'relative',
        right: -2,
        top: -2,
      },
    },
  },
  errorMessage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: spacing(1),
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#D6331F',
    '& img': {
      marginRight: spacing(1.25),
      height: 13,
      width: 13,
    },
  },
  errorMessageTwoSides: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
}));
