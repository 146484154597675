import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PersonIcon from 'assets/images/icons/new-user-icon-blue.svg';
import DeleteIcon from 'assets/images/icons/tertiary-delete-icon.svg';
import useStyles from './ActionsWithSelections.styles';

export const ActionsWithSelection = ({
  openChangeAssigneeModal,
  openDeleteModal,
  selectedRowsCount
}: {
  openChangeAssigneeModal: () => void;
  openDeleteModal: () => void;
  selectedRowsCount: number
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>

      <Typography className={classes.selectedText}>
        {t("Processes.requests_selected_hint", {
          value: selectedRowsCount
        })}
      </Typography>

      <Button
        className={classes.btn}
        variant="text"
        onClick={openChangeAssigneeModal}
        startIcon={ <img src={PersonIcon} alt='icon' />}
      >
        { t('AdminPanel.actions.changeAssignee')}
      </Button>

      <Button
        className={classes.btn}
        variant="text"
        onClick={openDeleteModal}
        startIcon={ <img src={DeleteIcon} alt='icon' />}
      >
        {t('customProcesses.table.actions.delete')}
      </Button>
    </div>
  );
};
