import React, { memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link, Button, CircularProgress, Typography } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import useEdsFinalDocuments from './useEdsFinalDocuments';

import { FINAL_DOCUMENTS } from 'pages/Task/TaskForm/Eds/EdsConstants';
import { EdsFinalDocumentProps, EdsFinalDocumentsHookResponse, DocumentItem } from "./types";

import useStyles from './useStyles';

export const EdsFinalDocuments = memo(({ params }: EdsFinalDocumentProps): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    isLoading,
    documentList
  }: EdsFinalDocumentsHookResponse = useEdsFinalDocuments({
    params
  });

  if (isLoading) {
    return <CircularProgress size={25}/>;
  }

  if (!documentList || (documentList?.length === 0)) {
    return <Typography>{t(FINAL_DOCUMENTS.NO_DOCUMENTS)}</Typography>;
  }

  return (
    <Box display="flex" alignItems="center">
      {documentList.map((documentItem: DocumentItem, documentItemIndex: number) => (
        <Link
          key={documentItemIndex}
          className={classes.groupLink}
          href={documentItem.link}
          download
        >
          <Button size="small" startIcon={<InsertDriveFileIcon color="primary"/>}>
            {documentItem.label}
          </Button>
        </Link>
      ))}
    </Box>
  );
});
