import React, { Dispatch, memo, SetStateAction, useCallback, useMemo, useState, useEffect } from 'react';
import { Box, Button, IconButton, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';

import { ContentFilter, AppTextField } from 'components';

import { PageType } from "types";

import useStyles from "./useStyles";
import FilterIcon from 'assets/images/icons/filter-icon.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  loading: boolean;
  value: string,
  currentPage: number,
  setValue: Dispatch<SetStateAction<string>>,
  handleSearchStart: any
}

export const SearchInput = memo(({
  loading,
  value,
  currentPage,
  setValue,
  handleSearchStart
}: Props) => {
  const classes = useStyles();

  const [isFiltersOpen, setFiltersOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const hasInput = useMemo<boolean>(() => value.length !== 0, [value]);

  const onKeyPress = useCallback(event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearchStart(value);
    }
  }, [handleSearchStart, value]);

  const startAdornment = (
    <InputAdornment
      position="start"
      className={classes.inputAdornment}
    >
      <Button
        className={cn(classes.startAdornmentButton, {
          [classes.startAdornmentButtonActive]: isFiltersOpen
        })}
        onClick={() => setFiltersOpen(true)}>
        <img
          src={FilterIcon}
          className={classes.startAdornmentButtonIcon}
          alt="Filter"
        />
      </Button>
    </InputAdornment>
  );

  const endAdornment = hasInput ? (
    <InputAdornment
      position="end"
      className={cn(classes.inputAdornment, classes.inputAdornmentEnd)}
    >
      <IconButton
        type="button"
        size="small"
        onClick={() => setValue('')}
      >
        <CloseIcon
          fontSize="small"
          className={classes.endAdornmentButtonIcon}
        />
      </IconButton>
    </InputAdornment>
  ) : null;

  return (
    <Box className={classes.root}>
      <Box display="flex" alignItems="center">
        <AppTextField
          autoFocus
          size="medium"
          placeholder={t("form_components.select.search_placeholder")}
          value={value}
          // disabled={loading}
          className={classes.inputWrapper}
          InputProps={{
            classes: { root: classes.inputWrapperInput },
            startAdornment,
            endAdornment
          }}
          onKeyDown={event => onKeyPress(event)}
          onChange={event => setValue(event.target.value)}
        />

        <Button
          disabled={loading}
          className={classes.searchButton}
          onClick={() => handleSearchStart(value)}>
          <SearchIcon />
        </Button>
      </Box>

      <Box className={cn(classes.contentFilter, {
        [classes.contentFilterActive]: isFiltersOpen
      })}>
        <ContentFilter
          type={PageType.SEARCH}
          searchValue={value}
          currentPage={currentPage}
          closeFilter={() => setFiltersOpen(false)}
        />
      </Box>
    </Box>
  )
});
