import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing }) => ({
  dialog: {
    width: 800,
    borderRadius: 12,
    border: '1px solid rgba(38, 40, 66, 0.08)',
  },
  dialogHeader: {
    display: 'flex',
    paddingLeft: 16,
    paddingRight: 20,
    height: 48,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(38, 40, 66, 0.08)',
  },
  dialogTitle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.3,
    color: '#262842',
  },
  dialogSubtitle: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#8B8C9E',
    margin: spacing(2, 0, 15),
  },
  dialogIcon: {
    '& > path': {
      fill: '#6D7C8B',
    },
  },
  dialogContent: {
    padding: '12px 24px 24px 24px'
  },
  dialogActions: {
    height: 64,
    backgroundColor: '#F7F8FC',
  },
  dialogActionsButton: {
    height: 40,
  },
  conditionFieldsWrapper: {
    height: 64,
    margin: 16,
    marginBottom: 72,
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: 12,
  },
  conditionField: {
    width: 240,
    height: 40,
    marginLeft: 12,
    marginTop: 12,
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: 10,
  },
  conditionFieldText: {
    '& div': {
      width: 240,
      height: 40,
      marginLeft: 12,
      marginTop: 12,
      border: '1px solid rgba(38, 40, 66, 0.12)',
      borderRadius: 10,
    },
  },
  emptyFieldsListMessageWrapper: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#8B8C9E',
    '& p': {
      margin: 0,
      padding: 0,
      paddingBottom: 2,
    },
    '& ul': {
      paddingLeft: 18,
      listStyle: 'disc',
    },
  },
  fieldsMenuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  fieldsMenuItemFieldName: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 400,
    color: '#262842'
  },
  fieldsMenuItemStepInfo: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12,
    fontWeight: 400,
    color: '#8B8C9E'
  },
  fieldsListWrapper: {

  },
  description: {
    color: '#262842',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    margin: 0,
    padding: 0,
    marginBottom: 16,
  },
  fieldsBlock: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alightItems: 'center',
    gap: 16,
  },
  fieldsBlockRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 8,
  },
  sideColumnCell: {
    width: 358,
  },
  centralColumnCell: {
    width: 'fit-content',
    minWidth: 20,
    height: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linkIcon: {
    width: 20,
    height: 20,
  },
  columnTitle: {
    color: '#6D6E85',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
  },
  fieldCard: {
    width: '100%',
    height: 40,
    paddingLeft: 12,
    paddingRight: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderRadius: 8,
    border: '1px solid rgba(38, 40, 66, 0.12)',
    background: '#FFF',
  },
  fieldInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: '#FFF',
  },
  fieldName: {
    overflow: 'hidden',
    color: '#262842',
    textOverflow: 'ellipsis',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
  },
  fieldType: {
    overflow: 'hidden',
    color: '#8B8C9E',
    textOverflow: 'ellipsis',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
  },
  fieldSelect: {
    width: '100%',
    height: 40,
    borderRadius: 8,
    border: '1px solid rgba(38, 40, 66, 0.12)',
    background: '#FFF',
  },
  fieldSelectItem: {

  },
  errorMessage: {
    color: '#D6331F',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    marginTop: 4,
  },
}));
