import { baseHcmsApiClient, baseV1ApiClient } from '../clients';
import { commonExceptionHandler } from '../handlers';
import { getProfileCompanyDataFromLocalStorage } from "utils/user";
import { HcmsUserModel, HcmsUserContacts } from 'types';
import { DepartmentInfo } from '../../pages/TemplateProcesses/TemplateProcesses.types';

export const getHCMSUsersListByCompany = async (propCompanyId?: string): Promise<HcmsUserModel[]> => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/route-api/hcms/employees/company',
      params: {
        companyId: propCompanyId ?? companyId
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getHCMSUserContactsById = async (id: string): Promise<HcmsUserContacts> => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/route-api/hcms/employees/contacts',
      params: {
        griffonId: id
      },
      headers: {
        companyId
      },
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getHCMSUserById = async (id: string, passedCompanyId = null): Promise<HcmsUserModel> => {
  try {
    const { id: companyId } = getProfileCompanyDataFromLocalStorage();

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/route-api/hcms/employee/griffon',
      params: {
        griffonId: id
      },
      headers: {
        companyId: passedCompanyId || companyId
      },
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getHCMSUserByLogName = async (logName): Promise<HcmsUserModel> => {
  try {
    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/route-api/hcms/employee/emailId',
      params: {
        userId: logName
      }
    });

    return response?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getDepartmentsList = async (): Promise<DepartmentInfo[]> => {
  try {
    const { holdingId } = getProfileCompanyDataFromLocalStorage();
    const workspaceAuthorizationToken = localStorage.getItem('workspace_token') || '';

    const allDirectoriesResponse = await baseV1ApiClient.request({
      method: 'GET',
      url: '/hcms/main-api/directory/v2/allInfo',
      params: {
        holdingId
      },
      headers: {
        'Workspace-Authorization': workspaceAuthorizationToken
      }
    });

    const departmentDirectory = allDirectoriesResponse?.data.find(item => item?.directoryName === 'Department');

    const departmentDirectoryResponse = await baseV1ApiClient.request({
      method: 'GET',
      url: '/hcms/main-api/directory/v2/allHierarchy?',
      params: {
        holdingId,
        firstLevelId: departmentDirectory?.id
      },
      headers: {
        'Workspace-Authorization': workspaceAuthorizationToken
      }
    });

    return departmentDirectoryResponse?.data?.value;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getPositions = async (): Promise<{[key: string]: any}> => {
  try {
    const { holdingId } = getProfileCompanyDataFromLocalStorage();
    const workspaceAuthorizationToken = localStorage.getItem('workspace_token') || '';
    const headers = {
      'Workspace-Authorization': workspaceAuthorizationToken
    }

    const response = await baseV1ApiClient.request({
      method: 'GET',
      url: '/hcms/main-api/directory/v2/allInfo',
      params: {
        holdingId
      },
      headers
    });

    const glossary = response?.data?.find(d => d.directoryName === "Position")
    const allDirectoriesResponse = await baseV1ApiClient.request({
      method: 'GET',
      url: '/hcms/main-api/directory/v2/value/all',
      params: {
        holdingId,
        glossaryId: glossary?.id,
      },
      headers
    });

    return allDirectoriesResponse?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getDirectoriesV2 = async (forceEnglish = false): Promise<{[key: string]: any}> => {
  try {
    const { holdingId } = getProfileCompanyDataFromLocalStorage();
    const workspaceToken = localStorage.getItem('workspace_token');

    const headers = forceEnglish ? {
      'Workspace-Authorization': workspaceToken,
      'Accept-Language': 'en'
    } : {
      'Workspace-Authorization': workspaceToken,
    }

    const allDirectoriesResponse = await baseHcmsApiClient.request({
      method: 'GET',
      url: '/directory/list/new/v2',
      params: {
        holdingId
      },
      headers: headers
    });

    return allDirectoriesResponse?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getDirectoriesV2Value = async (glossaryId: string, forceEnglish = false): Promise<{[key: string]: any}> => {
  try {
    const { holdingId } = getProfileCompanyDataFromLocalStorage();
    const workspaceToken = localStorage.getItem('workspace_token') || '';

    const headers = forceEnglish ? {
      'Workspace-Authorization': workspaceToken,
      'Accept-Language': 'en'
    } : {
      'Workspace-Authorization': workspaceToken,
    }

    const allDirectoriesResponse = await baseV1ApiClient.request({
      method: 'GET',
      url: '/hcms/main-api/directory/v2/value/all',
      params: {
        holdingId,
        glossaryId,
      },
      headers: headers
    });

    return allDirectoriesResponse?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getDirectoriesV2ByValueWithParentId = async (glossaryId: string, valueParentId: string, value: string, forceEnglish = false): Promise<{[key: string]: any}> => {
  try {
    const { holdingId } = getProfileCompanyDataFromLocalStorage();
    const workspaceToken = localStorage.getItem('workspace_token') || '';

    const headers = forceEnglish ? {
      'Workspace-Authorization': workspaceToken,
      'Accept-Language': 'en'
    } : {
      'Workspace-Authorization': workspaceToken,
    }

    const allDirectoriesResponse = await baseV1ApiClient.request({
      method: 'GET',
      url: '/dms-glossary/directory/holding/value',
      params: {
        holdingId,
        value,
        glossaryId,
        valueParentId,
        status: 'PUBLISHED',
        // pageable: {
          page: 0,
          size: 10000,
        //   sort: []
        // }
      },
      headers: headers
    });

    return allDirectoriesResponse?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getDirectoryInfo = async (glossaryId: string, forceEnglish = false): Promise<{[key: string]: any}> => {
  try {
    const { holdingId } = getProfileCompanyDataFromLocalStorage();
    const workspaceToken = localStorage.getItem('workspace_token') || '';

    const headers = forceEnglish ? {
      'Workspace-Authorization': workspaceToken,
      'Accept-Language': 'en'
    } : {
      'Workspace-Authorization': workspaceToken,
    }

    const allDirectoriesResponse = await baseV1ApiClient.request({
      method: 'GET',
      url: '/hcms/main-api/directory/v2/id',
      params: {
        holdingId,
        id: glossaryId,
      },
      headers: headers
    });

    return allDirectoriesResponse?.data;
  } catch (error) {
    commonExceptionHandler(error);
    throw error;
  }
};

export const getDirectoryByIdV2 = async (id: string, valueParentId?: string, status?: string, forceEnglish = false) => {
  const { holdingId } = getProfileCompanyDataFromLocalStorage();
  const workspaceToken = localStorage.getItem('workspace_token');

  const headers = forceEnglish ? {
    'Workspace-Authorization': workspaceToken,
    'Accept-Language': 'en'
  } : {
    'Workspace-Authorization': workspaceToken,
  }

  const res = await baseHcmsApiClient.request({
    method: 'GET',
    url: `/directory/list/new/v2/${id}`,
    params: {
      holdingId,
      valueParentId,
      status,
    },
    headers: headers
  });

  return res?.data;
};

export const getNestedDirectories = async (id: string, status?: string, forceEnglish = false) => {
  const { holdingId } = getProfileCompanyDataFromLocalStorage();
  const workspaceToken = localStorage.getItem('workspace_token');

  const headers = forceEnglish ? {
    'Workspace-Authorization': workspaceToken,
    'Accept-Language': 'en'
  } : {
    'Workspace-Authorization': workspaceToken,
  }

  const res = await baseHcmsApiClient.request({
    method: 'GET',
    url: `/directory/list/new/v2/next/${id}`,
    params: {
      holdingId,
      status,
    },
    headers
  });

  return res?.data;
};

export const getAllHierarchy = async (id: string, value: string, status = 'PUBLISHED', forceEnglish = false) => {
  const { holdingId } = getProfileCompanyDataFromLocalStorage();
  const workspaceToken = localStorage.getItem('workspace_token');

  const headers = forceEnglish ? {
    'Workspace-Authorization': workspaceToken,
    'Accept-Language': 'en'
  } : {
    'Workspace-Authorization': workspaceToken,
  }

  const res = await baseHcmsApiClient.request({
    method: 'GET',
    url: `/directory/list/new/v2/all-hierarchy/${id}`,
    params: {
      holdingId,
      status,
      value
    },
    headers
  });

  return res?.data;
};

export const getAllHierarchyV2 = async (firstLevelId: string, value: string, status = 'PUBLISHED', glossaryId = null, valueParentId = null, forceEnglish = false) => {
  const { holdingId } = getProfileCompanyDataFromLocalStorage();
  const workspaceToken = localStorage.getItem('workspace_token');

  const headers = forceEnglish ? {
    'Workspace-Authorization': workspaceToken,
    'Accept-Language': 'en'
  } : {
    'Workspace-Authorization': workspaceToken,
  }

  const res = await baseV1ApiClient.request({
    method: 'GET',
    url: `/hcms/main-api/directory/v2/allHierarchy`,
    params: {
      firstLevelId,
      holdingId,
      glossaryId,
      valueParentId,
      status,
      value
    },
    headers
  });

  return res?.data;
};

export const getValuesByNameFragment = async (value: string, status = 'PUBLISHED', forceEnglish = false) => {
  const workspaceToken = localStorage.getItem('workspace_token');

  const headers = forceEnglish ? {
    'Workspace-Authorization': workspaceToken,
    'Accept-Language': 'en'
  } : {
    'Workspace-Authorization': workspaceToken,
  }

  const res = await baseV1ApiClient.request({
    method: 'GET',
    url: '/hcms/main-api/directory/v2/name',
    params: {
      value: value
    },
    headers
  });

  return res?.data;
};

export const getGlossaryAsTree = async (glossaryId, forceEnglish = false, onlyPublishedItems = true) => {
  const workspaceToken = localStorage.getItem('workspace_token');
  const { holdingId } = getProfileCompanyDataFromLocalStorage();

  const headers = forceEnglish ? {
    'Workspace-Authorization': workspaceToken,
    'Accept-Language': 'en'
  } : {
    'Workspace-Authorization': workspaceToken,
  }

  const params = onlyPublishedItems ? {
    holdingId,
    status: 'PUBLISHED',
    glossaryId,
  } : {
    holdingId,
    glossaryId,
  }

  const res = await baseV1ApiClient.request({
    method: 'GET',
    url: '/hcms/main-api/directory/v2/all/hierarchy/tree',
    params,
    headers
  });

  return res?.data;
};
