import React from 'react';

import { FormField } from './FormFields';
import { RenderFieldsProps } from './types';

const RenderFields = ((props: RenderFieldsProps) => {
  const {sortedFields, setButtonDisabled, setAlwaysActiveButtons, handleAction} = props;
  return (
    <>
      {
        sortedFields.map((attribute) => 
          <FormField
            key={attribute.id}
            attribute={attribute}
            setButtonDisabled={setButtonDisabled}
            setAlwaysActiveButtons={setAlwaysActiveButtons}
            handleAction={handleAction} />
        )
      }
    </>
  )
})

export default RenderFields;
