import { useState, useMemo, useEffect } from 'react';
import axios  from 'axios';
import { useTranslation } from 'react-i18next';

import { AttachmentItemType } from '../attachments-component';

import {
  getInstance,
  getGoogleDriveAccessToken,
  downloadAttachmentById,
  initGoogleDriveLongUpload,
  uploadGoogleDriveLongUploadPart,
  downloadAttachmentFromPS,
  uploadAttachmentToPS
} from 'api/requests';

import { useTaskState } from 'store/requests';
import { useUsersState } from 'store/users';
import { getProfileCompanyDataFromLocalStorage, getUserDataByIdOrLogName } from 'utils/user';
import { createFileInstance, getErrorMessage, getChunkpot } from '../utils';
import { useStorageMigration, useUserProfile } from "hooks";

import { CHUNK_SIZE, INITIAL_LOADING_PROGRESS, ERROR_MESSAGES, STORAGE, GOOGLE_DRIVE_CONFIGS } from '../constants';
import { environment } from 'environments/environment';
import { getCorrectTimeWithISOFormat } from 'utils/time';
const { env } = environment;

export const useAttachments = ({
  value,
  formErrors,
  updateValue,
  clearFormErrors,
  folderId,
  isReadOnly,
  storage
}) => {
  const { t } = useTranslation();
  const { data: bpmTask } = useTaskState();
  const { actualStorage } = useStorageMigration({
    storage,
    createdDate: bpmTask.createdDate
  });
  const { companyId } = useUserProfile();

  const { users } = useUsersState();

  const [attachments, setAttachments] = useState<AttachmentItemType[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const fieldValidationErrorMessage = useMemo((): string =>
    getErrorMessage(formErrors),
    [formErrors]);

  const uploadFolderId = useMemo((): string => {
    if (folderId) {
      return folderId;
    }

    if (env === 'PROD') {
      return GOOGLE_DRIVE_CONFIGS.UPLOAD_FOLDER_ID_PROD;
    }

    return GOOGLE_DRIVE_CONFIGS.UPLOAD_FOLDER_ID_DEV_QA;
  }, [folderId]);

  useEffect(() => {
    // fix empty attachments value on first step form after going
    // to previous steps from the summary step
    if (value.length === 0 && attachments.length > 0 && bpmTask.isFirstStep) {
      const uploadedAttachmentsIds = attachments
        .filter(
          ({ isLoading, isDeleted, error }) =>
            !isLoading && !isDeleted && !error
        )
        .map(({ id }) => id);
      if (uploadedAttachmentsIds.length > 0) {
        const newValue = uploadedAttachmentsIds.join(',');
        updateValue(newValue);
      }
    }
  }, [value, attachments, bpmTask.isFirstStep, updateValue]);

  useEffect(() => {
    if (attachments.length > 0 && !isReadOnly) {
      const uploadedAttachmentsIds = attachments
      .filter(({ isLoading, isDeleted, error }) => !isLoading && !isDeleted && !error)
      .map(({ id }) => id);

      const uniqueAttachmentsIds = uploadedAttachmentsIds
        .reduce((idsArray, id) => idsArray.includes(id) ? idsArray : [...idsArray, id], []);

      const attachmentNewValue = uniqueAttachmentsIds.join(',');
      const attachmentOldValue = value.join(',');

      if (attachmentNewValue !== attachmentOldValue) {
        updateValue(attachmentNewValue);
      }
      // if value or updateValue is in dependency array
      // component is lagging in modal window because of
      // infinity running of this useEffect
    }
  }, [attachments, isReadOnly]);

  useEffect(() => {
    if (attachments.length === 0 && value.length > 0) {
      const fetchAttachments = () => {
        value.forEach(async (attachmentId) => {
          const instance = await getInstance(attachmentId);
          const { stringAttributes, integerAttributes } = instance;

          const fileInstanceId = stringAttributes.find(({ name }) => name === 'Файл').value;

          const attachmentItem: AttachmentItemType = {
            cancelToken: axios.CancelToken.source(),
            filename: stringAttributes.find(({ name }) => name === 'Название').value,
            googleDriveId: fileInstanceId,
            id: attachmentId,
            isLoading: false,
            randomId: Math.random(),
            type: stringAttributes.find(({ name }) => name === 'Тип').value
          };

          const isNewVersion = !!stringAttributes.find(({ name }) => name === 'step');
          if (isNewVersion) {
            const attachmentsStepSysName = stringAttributes.find(({ name }) => name === 'step').value
            const attachmentStep = bpmTask.actions.find(({ sysName }) => sysName === attachmentsStepSysName);

            const attachmentAssigneeKey = stringAttributes.find(({ name }) => name === 'assignee')?.value;
            const attachmentAssigneeUser = users[attachmentAssigneeKey];
            if (attachmentAssigneeUser) {
              attachmentItem.assignee = attachmentAssigneeUser?.fullName;
            } else {
              try {
                const userInfo = await getUserDataByIdOrLogName(attachmentAssigneeKey, companyId);
                attachmentItem.assignee = userInfo?.fullName || t('task_data_view.employee_not_found');
              } catch (e) {
                attachmentItem.assignee = t('task_data_view.employee_not_found');
              }
            }

            const attachmentsStepLocalizationKey = `constructor-${bpmTask.processSysName}.actions.${attachmentsStepSysName}.name`;
            attachmentItem.step = t(attachmentsStepLocalizationKey, {defaultValue: attachmentStep?.name});
            attachmentItem.creationDate = stringAttributes.find(({ name }) => name === 'creationDate')?.value || '';
            attachmentItem.size = integerAttributes.find(({ name }) => name === 'size')?.value || 0;
          }

          setAttachments((attachmentsList) => {
            const isDuplicateAttachment = attachmentsList.find(({ id }) => id === attachmentItem.id);
            if (isDuplicateAttachment) {
              return attachmentsList;
            }

            return [...attachmentsList, attachmentItem]
              .sort((a, b) => +b.id - +a.id);
          });
        });
      };

      fetchAttachments();
    }
  }, [value, setAttachments]);

  useEffect(() => {
    setErrorMessage(fieldValidationErrorMessage);
  }, [fieldValidationErrorMessage]);

  const updateAttachment = (attachmentData: AttachmentItemType) => {
    const { randomId } = attachmentData;
    setAttachments((atts) => {
      const attachmentIndex = atts.indexOf(
        atts.find((att) => att.randomId === randomId)
      );
      if (attachmentIndex >= 0) {
        return [
          ...atts.slice(0, attachmentIndex),
          attachmentData,
          ...atts.slice(attachmentIndex + 1),
        ];
      }
      return atts;
    });
  };

  const resetAllErrors = () => {
    setErrorMessage('');
    clearFormErrors();
  };

  const onGoogleDriveUploadFinished = async (
    fileData: {
      kind: string;
      id: string;
      name: string;
      mimeType: string;
    },
    attachmentData: AttachmentItemType
  ) => {
    try {
      const attachmentId = await createFileInstance({
        payload: {
          file: fileData,
          taskId: bpmTask.taskId,
          applicationNumber: bpmTask.applicationNumber,
        }
      });

      updateAttachment({
        ...attachmentData,
        id: attachmentId,
        googleDriveId: fileData.id,
        isLoading: false,
        loadingProgress: 1,
      });
    } catch {
      updateAttachment({
        ...attachmentData,
        isLoading: false,
        error: t(ERROR_MESSAGES.ATTACHMENT_UPLOAD),
      });

      setErrorMessage(t(ERROR_MESSAGES.CAMUNDA_DEFAULT));
    }
  };

  const handleAttachmentClick = async (attachment: AttachmentItemType) => {
    const { googleDriveId, id, filename, type } = attachment;

    if ((!googleDriveId && actualStorage !== STORAGE.PS) || !filename) {
      return;
    }

    updateAttachment({
      ...attachment,
      isLoading: true,
      loadingProgress: 0.1
    });

    try {
      if (actualStorage === STORAGE.PS) {
        await downloadAttachmentFromPS(id, filename, type);
      } else {
        const accessToken = await getGoogleDriveAccessToken();
        await downloadAttachmentById(googleDriveId, filename, accessToken);
      }
    } catch (error) {
      setErrorMessage(t(ERROR_MESSAGES.ATTACHMENT_DOWNLOAD));
    } finally {
      updateAttachment({
        ...attachment,
        isLoading: false,
        loadingProgress: 1
      });
    }
  };

  async function doUpload(e: any) {
    const { chunks, location, attachmentData, accessToken } = e;
    let cnt = 0;
    const end = chunks.length;
    const initialChunk = chunks[0];
    let params = {
      url: location,
      data: initialChunk.data,
      range: initialChunk.range,
      accessToken,
    };
    let isUploadComplete = false;
    let response = null;
    try {
      while (!isUploadComplete) {
        response = await uploadGoogleDriveLongUploadPart(params);
        isUploadComplete = response.status === 200;
        const uploadProgress = INITIAL_LOADING_PROGRESS + 0.8 * (cnt / end);
        updateAttachment({
          ...attachmentData,
          isLoading: true,
          loadingProgress: uploadProgress,
        });
        if (isUploadComplete) {
          break;
        }
        const chunk = chunks[cnt];
        params = {
          url: location,
          data: chunk.data,
          range: chunk.range,
          accessToken,
        };
        cnt++;
      }
      await onGoogleDriveUploadFinished(response.data, attachmentData);
    } catch (e) {
      updateAttachment({
        ...attachmentData,
        isLoading: false,
        error: t(ERROR_MESSAGES.ATTACHMENT_UPLOAD),
      });
      setErrorMessage(t(ERROR_MESSAGES.ATTACHMENT_UPLOAD));
    }
  }

  async function initGoogleDriveUploading() {
    const attachmentData = this.attachmentData;
    const buf = this.result;

    const { size, filename, type } = attachmentData;
    const chunkpot = getChunkpot(CHUNK_SIZE, size);
    const uint8Array = new Uint8Array(buf);
    const chunks = chunkpot.chunks.map(function (e) {
      return {
        data: uint8Array.slice(e.startByte, e.endByte + 1),
        length: e.numByte,
        range: 'bytes ' + e.startByte + '-' + e.endByte + '/' + chunkpot.total,
      };
    });

    const handleSuccess = ({ accessToken }) => {
      initGoogleDriveLongUpload({
        accessToken,
        filename,
        type,
        parents: [uploadFolderId],
      })
        .then((response) => {
          doUpload({
            location: response.headers.location,
            chunks: chunks,
            attachmentData,
            accessToken,
          });
        })
        .catch(() => {
          updateAttachment({
            ...attachmentData,
            isLoading: false,
            error: t(ERROR_MESSAGES.ATTACHMENT_UPLOAD),
          });
          setErrorMessage(t(ERROR_MESSAGES.GOOGLE_DRIVE_DEFAULT));
        });
    };

    const handleError = () => {
      updateAttachment({
        ...attachmentData,
        isLoading: false,
        error: t(ERROR_MESSAGES.ATTACHMENT_UPLOAD),
      });
      setErrorMessage(t(ERROR_MESSAGES.GOOGLE_DRIVE_DEFAULT));
    };

    try {
      const accessToken = await getGoogleDriveAccessToken();
      handleSuccess({ accessToken });
    } catch (e) {
      handleError();
    }
  }

  async function initPSUploading(file: File, attachmentItem: AttachmentItemType) {
    const fileId = await createFileInstance({
      payload: {
        file: {
          id: '',
          name: attachmentItem.filename,
          mimeType: attachmentItem.type,
          size: attachmentItem.size
        },
        taskId: bpmTask.taskId,
        applicationNumber: bpmTask.applicationNumber,
        step: bpmTask.currentAction.sysName,
        assignee: bpmTask.assignee,
        creationDate: getCorrectTimeWithISOFormat()
      },
      cancelToken: attachmentItem.cancelToken.token
    });

    updateAttachment({
      ...attachmentItem,
      id: fileId,
      isLoading: true,
      loadingProgress: 0.2,
    });

    const { id: profileCompanyId } = getProfileCompanyDataFromLocalStorage();
    const companyId = bpmTask.values['companyId'] || profileCompanyId;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const handleUploadProgress = (progress: number) => {
        updateAttachment({
          ...attachmentItem,
          id: fileId,
          isLoading: true,
          loadingProgress: 0.2 + 0.8 * progress,
        });
      }

      await uploadAttachmentToPS({
        params: {
          companyId,
          fileId
        },
        data: formData,
        config: {
          handleUploadProgress,
          cancelToken: attachmentItem.cancelToken.token
        }
      });

      updateAttachment({
        ...attachmentItem,
        id: fileId,
        isLoading: false,
        loadingProgress: 1,
      });
    } catch (error) {
      if (error?.message === t(ERROR_MESSAGES.ATTACHMENT_UPLOAD_CANCELED)) {
        return;
      }

      const isFileLarge = error.response?.status === 413;
      updateAttachment({
        ...attachmentItem,
        id: fileId,
        isLoading: false,
        error: isFileLarge ? t(ERROR_MESSAGES.PS_FILE_TOO_LARGE) : t(ERROR_MESSAGES.ATTACHMENT_UPLOAD),
      });

      if (isFileLarge) {
        setErrorMessage(t(ERROR_MESSAGES.PS_FILE_TOO_LARGE));
      } else {
        setErrorMessage(t(ERROR_MESSAGES.PS_DEFAULT));
      }
    }
  }

  const handleAttachmentUpload = async (attachmentFile: File) => {
    resetAllErrors();
    const attachmentsStepLocalizationKey = `constructor-${bpmTask.processSysName}.actions.${bpmTask.stepSysName}.name`;

    const attachmentData = {
      randomId: Math.random(),
      filename: attachmentFile.name,
      size: attachmentFile.size,
      type: attachmentFile.type,
      step: t(attachmentsStepLocalizationKey, {defaultValue: bpmTask.currentAction.name}),
      assignee: users[bpmTask.assignee]?.fullName || t('task_data_view.employee_not_found'),
      creationDate: getCorrectTimeWithISOFormat(),
      isLoading: true,
      loadingProgress: INITIAL_LOADING_PROGRESS,
      cancelToken: axios.CancelToken.source()
    };

    setAttachments((attachments) => [attachmentData, ...attachments]);

    if (actualStorage === STORAGE.PS) {
      initPSUploading(attachmentFile, attachmentData);
    } else {
      const fr = new FileReader();
      (fr as any).attachmentData = attachmentData;
      fr.onload = initGoogleDriveUploading;
      fr.readAsArrayBuffer(attachmentFile);
    }
  };

  const handleAttachmentRemoval = (attachment: AttachmentItemType): void => {
    if (attachment.isLoading) {
      attachment.cancelToken.cancel(t(ERROR_MESSAGES.ATTACHMENT_UPLOAD_CANCELED));
    }

    attachment.isDeleted = true;

    const existingAttachments = attachments
      .filter(({ randomId, isDeleted }: AttachmentItemType) => randomId !== attachment.randomId && !isDeleted);
    if (!existingAttachments.length) {
      setErrorMessage('');
    }

    const attachmentIndex = attachments.findIndex(({randomId}) => randomId === attachment.randomId);
    const newAttachments = [...attachments];
    newAttachments[attachmentIndex] = attachment;

    setAttachments(newAttachments);
  };

  const handleAttachmentRemovalUndoing = (attachment: AttachmentItemType): void => {
    if (!attachment.isLoading) {
      attachment.isDeleted = false;
      const attachmentIndex = attachments.findIndex(({ randomId }) => randomId === attachment.randomId);
      const newAttachments = [...attachments];
      newAttachments[attachmentIndex] = attachment;

      setAttachments(newAttachments);
    }
  };

  return {
    attachments,
    handleAttachmentUpload,
    handleAttachmentRemoval,
    handleAttachmentRemovalUndoing,
    handleAttachmentClick,
    errorMessage,
    setErrorMessage,
  };
};
