import React, {
  createContext,
  ReactElement,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Popper,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { DragDropContext } from 'react-beautiful-dnd';
import { useRouteMatch } from 'react-router-dom';

import {
  ArchiveProcessDialog,
  ProcessAutoSave,
  ProcessStepForm,
  ProcessSetupSidePanel,
  TemplateProcessSettingsForm,
  ChangesRollbackConfirmationDialog,
} from '../index';

import {
  LANGUAGE_TRANSLATION,
} from '../../TemplateProcesses.constants';
import {
  ConditionStep,
  DepartmentInfo,
  FormProcess, ParallelBranchesGroup,
  ProcessStep,
  TemplateProcess,
  TemplateProcessSettings,
} from '../../TemplateProcesses.types';
import { Spinner } from 'components';
import { useUsersRole } from 'hooks';
import {
  DeleteProcessStepConfirmationModal,
} from 'pages/TemplateProcesses/components/DeleteProcessStepConfirmationModal';

import useSetupTemplateProcessesDialogStyles from './SetupTemplateProcessesDialog.useStyles';
import { MaximumFieldsErrorDialog } from '../MaximumFieldsErrorDialog/MaximumFieldsErrorDialog';
import { AutostartActivationNotPossibleDialog } from '../AutostartActivationNotPossibleDialog';
import { AutostartDisablingConfirmationDialog } from '../AutostartDisablingConfirmationDialog';
import { AutostartActivationConfirmationDialog } from '../AutostartActivationConfirmationDialog';
import { useSetupTemplateProcessDialog } from './hooks/useSetupTemplateProcessDialog';
import { StepConditionModal } from '../StepConditionModal';
import { ConditionForm } from '../ConditionForm';
import { DeleteConditionConfirmationDialog } from '../DeleteConditionConfirmationDialog';
import { ParallelStepsGroupForm } from '../ParallelStepsGroupForm';
import { DeleteParallelBranchesConfirmationDialog } from '../DeleteParallelBranchesConfirmationDialog';
import { StepsLimitReachedDialog } from '../StepsLimitReachedDialog';
import { BranchStepsLimitReachedDialog } from '../BranchStepsLimitReachedDialog';
import { ProcessCardPreviewDialog } from '../ProcessCardPreviewDialog';
import { ConditionAdditionNotPossibleDialog } from '../ConditionAdditionNotPossibleDialog';
import { DeleteStepFieldConfirmationModal } from '../DeleteStepFieldConfirmationModal';

import WarningIcon from 'assets/images/icons/warning-sign.svg';
import DropdownIndicatorDark from 'assets/images/icons/dropdown-indicator-dark.svg';
import AutoStartOnIcon from 'assets/images/icons/autostart-on.svg';
import AutoStartPauseIcon from 'assets/images/icons/autostart-pause.svg';
import AutoStartFinishedIcon from 'assets/images/icons/autostart-finished.svg';
import ArrowIcon from 'assets/images/icons/builder-header-arrow.svg';
import ArrowIconLeft from 'assets/images/icons/arrow_left_regular.svg';
import MenuCopyIcon from 'assets/images/icons/template_processes_menu_copy_icon.svg';
import MenuArchiveIcon from 'assets/images/icons/template_processes_menu_archive_icon.svg';
import MenuSaveAsTemplateIcon from 'assets/images/icons/document_regular.svg';
import MenuDeleteIcon from 'assets/images/icons/template_processes_menu_delete_icon.svg';
import { DocumentDetails } from '@dar-dms/utils';
import { DocumentSignatureStepRemovalDialog } from '../DocumentSignatureStepRemovalDialog';
import { StepPreviewSavingVariantSelectionDialog } from '../StepPreviewSavingVariantSelectionDialog';
import { HidePreviewFieldConfirmationModal } from '../HidePreviewFieldConfirmationModal';

export const FirstErrorStepIndexContext = createContext(null);
export const ConfirmDeleteProcessStepModalContext = createContext<{
  setIsOpenConfirmDeleteModal: (boolean) => void,
  setDataForDelete: (
    arg: {
      processStep: ProcessStep,
      isPerformerStep: boolean
    },
  ) => void;
}>({
  setIsOpenConfirmDeleteModal: null,
  setDataForDelete: null,
});
export const ConfirmDeleteConditionModalContext = createContext<{
  setIsOpenConfirmConditionDeleteModal: (boolean) => void,
  setDataForConditionDelete: (condition: ConditionStep) => void;
}>({
  setIsOpenConfirmConditionDeleteModal: null,
  setDataForConditionDelete: null,
});
export const ConfirmDeleteParallelBranchesModalContext = createContext<{
  setIsOpenConfirmParallelBranchesDeleteModal: (boolean) => void,
  setDataForParallelBranchesDelete: (parallelStepsGroup: ParallelBranchesGroup) => void;
}>({
  setIsOpenConfirmParallelBranchesDeleteModal: null,
  setDataForParallelBranchesDelete: null,
});

interface SetupTemplateProcessesDialogProps {
  isOpen: boolean;
  initialValues: TemplateProcess | null;
  publishedVersionValues: TemplateProcess | null;
  templateSettings: TemplateProcessSettings | null;
  departmentsList: DepartmentInfo[];
  availableLocales: string[];
  haveTemplateSettingsChanged: boolean;
  onClose: () => void;
  onFormSubmit: () => Promise<void>;
  onBackButtonClick: () => void;
  onProcessArchive: () => void;
  onDuplicateProcessDialogOpen: (processData) => void;
  onCopyProcessDialogOpen: (processData) => void;
  onSaveAsTemplateOpen?: (processData) => void;
  setTemplateSettings: any;
  needResetProcessForm: boolean;
  setNeedResetProcessForm: any;
  handleAutostartActionsClick: (event: any, values: any) => void;
  handlePublishedProcessesLimitDialogOpen: () => void,
  handleProcessDeleteConfirmationDialogOpen: () => void,
}

export const SetupTemplateProcessesDialog = ({
  isOpen,
  initialValues,
  publishedVersionValues,
  templateSettings,
  departmentsList,
  availableLocales,
  haveTemplateSettingsChanged,
  onClose,
  onFormSubmit,
  onBackButtonClick,
  onProcessArchive,
  onDuplicateProcessDialogOpen,
  onCopyProcessDialogOpen,
  setTemplateSettings,
  needResetProcessForm,
  setNeedResetProcessForm,
  handleAutostartActionsClick,
  handlePublishedProcessesLimitDialogOpen,
  handleProcessDeleteConfirmationDialogOpen,
  onSaveAsTemplateOpen,
}: SetupTemplateProcessesDialogProps): ReactElement => {
  const { t } = useTranslation();

  const [isOpenConfirmDeleteProcessStepModal, setIsOpenConfirmDeleteProcessStepModal] = useState(false);
  const [dataForDelete, setDataForDelete] = useState<{
    processStep: ProcessStep,
    isPerformerStep: boolean
  } | null>(null);

  const routeMatch = useRouteMatch();

  const isTemplatePage = useMemo(() => {
    return routeMatch.path === '/templates/:id';
  }, [routeMatch]);

  const [isOpenConfirmDeleteConditionModal, setIsOpenConfirmDeleteConditionModal] = useState(false);
  const [isOpenStepsLimitDialogOpen, setIsOpenStepsLimitDialog] = useState(false);
  const [isOpenBranchStepsLimitDialogOpen, setIsOpenBranchStepsLimitDialog] = useState(false);
  const [dataForConditionDelete, setDataForConditionDelete] = useState<ConditionStep | null>(null);
  const [isOpenConfirmDeleteParallelBranchesModal, setIsOpenConfirmDeleteParallelBranchesModal] = useState(false);
  const [dataForParallelBranchesDelete, setDataForParallelBranchesDelete] = useState<ParallelBranchesGroup | null>(null);

  const {
    isTemplateProcessArchivingAvailable,
    isTemplateProcessCopyingAvailable,
    isTemplateProcessCreationAvailable,
    isTemplatePageAvailable,
  } = useUsersRole();

  const {
    fields,
    currentProcessIndex,
    currentConditionId,
    currentParallelBranchesGroupId,
    selectedItemType,
    allProcessStepsFields,
    allUsedGlossaries,
    focusedStepFieldId,
    processIntegrations,
    isNewField,
    directManagerSteps,
    performerSelectionSteps,
    summaryFields,
    processIcon,
    processGroup,
    isAutostartActive,
    autostartSettings,
    autoSaveProcessSysName,
    processDisplayName,
    processDisplayDescription,
    fieldsSettingsForm,
    formState,
    formErrors,
    anchorEl,
    gridPathRef,
    nameFieldRef,
    versionToggleAnchorEl,
    firstErrorStepEl,
    currentTab,
    tabsWithErrors,
    hasErrors,
    languages,
    currentLanguage,
    currentLanguageTab,
    languageTabs,
    languagesWithErrors,
    languageTabsWithErrors,
    firstErrorStepIndex,
    loading,
    isVersionToggleOpen,
    isReadonlyView,
    isSubmitAvailable,
    isEditDisabled,
    isProcessPublishing,
    isDragActive,
    dragType,
    conditions,
    parallelBranchesGroups,
    documents,
    selectedStepField,
    previewDocumentToolbarFields,
    previewDocumentSignatories,
    previewedDocumentTemplateId,
    setPreviewedDocumentTemplateId,
    setParallelBranchesGroups,
    setConditions,
    focusedDocumentId,
    setFocusedDocumentId,
    setIsNewDocument,
    setCurrentTab,
    setVersionToggleAnchorEl,
    setVersionToggleOpen,
    setIsSubmitAvailable,
    setCurrentLanguageTab,
    setLanguageTabs,
    setProcessIcon,
    setProcessGroup,
    setFirstErrorStepEl,
    setFocusedStepFieldId,
    setIsNewField,
    hasWorkCalendar,
    onAutostartActionsClick,
    handleBackButtonClick,
    handleDragStart,
    handleDragEnd,
    handleAnchorClick,
    handleAnchorClose,
    handleCheck,
    handleDialogSubmit,
    handleProcessStepsAutoSave,
    handleDisplayedVersionToggle,
    handleChangesRollback,
    handleActiveLanguageSelect,
    handleAddPreviousStep,
    handleAddProcessStep,
    handleAddProcessConditionStep,
    handleProcessSelect,
    handleConditionSelect,
    handleStepFieldValuesChange,
    handleAutostartChange,
    handleAutostartSettingsChange,
    handleStepNameChange,
    handleStepDescriptionChange,
    handleInitiatorsChange,
    handleAssigneeTypeChange,
    handleDirectManagerToggle,
    handlePerformerSelectionToggle,
    handleSLACheckboxToggle,
    handleStepExecutionTimeChange,
    handleStepDelete,
    handleStepFieldDeleteStart,
    handleProcessPreviewSavingStart,
    updateStepPreview,
    toggleStepFieldVisibility,
    toggleStepFieldVisibilityWithOptionalConfirmation,
    handleToggleSelectedFieldVisibilityConfirmation,
    clearErrors,
    register,
    trigger,
    updateSettingsForm,
    appendSettingsForm,
    removeSettingsForm,
    isDialogOpen,
    handleDialogOpen,
    handleDialogClose,
    isDeleteDialogOpen,
    handleDeleteDialogClose,
    handleStepFieldDeleteConfirmation,
    isMaximumFieldsDialogOpen,
    handleMaximumFieldsDialogClose,
    isAutostartDisablingConfirmationDialogOpen,
    handleAutostartDisablingConfirmationDialogClose,
    handleAutostartDisablingConfirmationDialogConfirm,
    isAutostartActivationConfirmationDialogOpen,
    handleAutostartActivationConfirmationDialogClose,
    handleAutostartActivationConfirmationDialogConfirm,
    isAutostartActivationNotPossibleDialogOpen,
    handleAutostartActivationNotPossibleDialogOpen,
    handleAutostartActivationNotPossibleDialogClose,
    isConditionAdditionNotPossibleDialogOpen,
    handleConditionAdditionNotPossibleDialogOpen,
    handleConditionAdditionNotPossibleDialogClose,
    isDocumentSignatureStepRemovalDialogOpen,
    handleDocumentSignatureStepRemovalDialogClose,
    handleDocumentSignatureStepRemovalDialogOpen,
    isChangesRollbackConfirmationDialogOpen,
    handleChangesRollbackConfirmationDialogOpen,
    handleChangesRollbackConfirmationDialogClose,
    isConditionModalOpen,
    handleConditionModalOpen,
    handleConditionModalClose,
    isCardPreviewDialogOpen,
    handleCardPreviewDialogOpen,
    handleCardPreviewDialogClose,
    isCardPreviewSavingTypeDialogOpen,
    handleCardPreviewSavingTypeDialogOpen,
    handleCardPreviewSavingTypeDialogClose,
    isHidePreviewFieldConfirmationModalOpen,
    handleHidePreviewFieldConfirmationModalOpen,
    handleHidePreviewFieldConfirmationModalClose,
    handleConditionValuesChange,
    handleConditionDelete,
    handleParallelStepsGroupSelect,
    handleAddParallelStepsGroup,
    handleParallelStepsGroupValuesChange,
    handleParallelStepsGroupBranchAdd,
    handleParallelStepsGroupBranchDelete,
    handleParallelStepsGroupDelete,
    handleParallelBranchStepAdd,
    handleConditionBranchStepAdd,
    handleDocumentAdd,
    handleDocumentFieldsAdd,
    handleDocumentParamsChange,
    handleDocumentChangeUndo,
    handleDocumentDelete,
    handleDocumentVisibilityChange,
    handleDocumentPreviewOpen,
    handleDocumentPreviewClose
  } = useSetupTemplateProcessDialog({
    isOpen,
    initialValues,
    publishedVersionValues,
    templateSettings,
    haveTemplateSettingsChanged,
    onClose,
    onFormSubmit,
    onBackButtonClick,
    setTemplateSettings,
    needResetProcessForm,
    setNeedResetProcessForm,
    handleAutostartActionsClick,
    handlePublishedProcessesLimitDialogOpen
  });

  const classes = useSetupTemplateProcessesDialogStyles({
    hasConditions: conditions?.length > 0,
    hasParallelStepsGroups: parallelBranchesGroups?.length > 0,
  });

  const publishButtonStartIcon = useMemo((): ReactElement | undefined =>
      isProcessPublishing ? <CircularProgress style={{ 'color': 'white' }} size={16}/> : undefined,
    [isProcessPublishing]);

  const dialogHeaderText = useMemo(() => {
    if (!isTemplatePage) return publishedVersionValues?.published
                                ? (isSubmitAvailable && !isEditDisabled)
                                  ? t('customProcesses.table.unpublishedChanges')
                                  : t('customProcesses.table.statusPublished')
                                : t('customProcesses.table.statusNotPublished');

    return publishedVersionValues?.published
           ? (isSubmitAvailable && !isEditDisabled)
             ? t('templates.unsharedChanges')
             : t('templates.statusShared')
           : t('templates.statusNotShared');
  }, [isEditDisabled, isSubmitAvailable, isTemplatePage, publishedVersionValues?.published, t]);

  return (
    <ConfirmDeleteProcessStepModalContext.Provider value={{
      setDataForDelete,
      setIsOpenConfirmDeleteModal: setIsOpenConfirmDeleteProcessStepModal,
    }}>
      <ConfirmDeleteConditionModalContext.Provider value={{
        setIsOpenConfirmConditionDeleteModal: setIsOpenConfirmDeleteConditionModal,
        setDataForConditionDelete,
      }}>
        <ConfirmDeleteParallelBranchesModalContext.Provider value={{
          setIsOpenConfirmParallelBranchesDeleteModal: setIsOpenConfirmDeleteParallelBranchesModal,
          setDataForParallelBranchesDelete,
        }}>
          <Dialog
            fullScreen
            open={isOpen}
            onClose={onClose}
            disableEnforceFocus
            classes={{
              root: classes.dialogRoot,
            }}
            // st
            // sx={{
            //   zIndex: 1100
            // }}
          >

            {loading && <Spinner absolute/>}

            <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
              <Box className={classes.dialogHeader}>
                <Box display="flex" alignItems="center" overflow="hidden">
                  <IconButton className={classes.dialogHeaderIconButton} onClick={handleBackButtonClick}>
                    <img src={ArrowIconLeft} alt="Back" />
                  </IconButton>

                  <Tooltip title={processDisplayName}>
                    <Typography className={classes.dialogHeaderTitle}>{processDisplayName}</Typography>
                  </Tooltip>
                </Box>

                <Box display="flex" alignItems="center" flexShrink={0}>

                  {!isReadonlyView && (
                    <Box ml={7}>
                      <ProcessAutoSave
                        processSteps={fields as unknown as ProcessStep[]}
                        conditions={conditions}
                        parallelBranchesGroups={parallelBranchesGroups}
                        documents={documents}
                        fieldsSettingsForm={fieldsSettingsForm}
                        allProcessStepsFields={allProcessStepsFields}
                        directManagerSteps={directManagerSteps}
                        performerSelectionSteps={performerSelectionSteps}
                        isAutostartActive={isAutostartActive}
                        autostartSettings={autostartSettings}
                        summaryFields={summaryFields}
                        onAutoSave={handleProcessStepsAutoSave}
                        isActive={!isEditDisabled && isSubmitAvailable}
                      />
                    </Box>
                  )}

                  <Box className={classes.dialogPublishStatusWrapper} onClick={(event) => {
                    if (initialValues && publishedVersionValues?.published) {
                      setVersionToggleAnchorEl(event.currentTarget);
                      setVersionToggleOpen(v => !v);
                    }
                  }
                  }>
                    <Box
                      className={cn(classes.dialogPublishStatus, {
                        [classes.dialogPublishStatusActive]: publishedVersionValues?.published && (!isSubmitAvailable || isEditDisabled),
                        [classes.dialogPublishStatusUnpublishedChanges]: publishedVersionValues?.published && isSubmitAvailable && !isEditDisabled,
                      })}></Box>

                    <Typography className={classes.dialogHeaderText}>
                      {dialogHeaderText}
                    </Typography>

                    {publishedVersionValues?.published && <img className={classes.dialogPublishStatusDropdownIndicator} src={DropdownIndicatorDark}/>}

                    <Popper id="opened-version-toggle" open={isVersionToggleOpen} anchorEl={versionToggleAnchorEl}
                            className={classes.toggleOpenedVersion}>
                      <span onClick={handleDisplayedVersionToggle}>
                        {isEditDisabled
                        ? t('customProcesses.creationPage.buttons.openChangedVersion')
                        : isTemplatePage ? t('templates.openSharedVersion') : t('customProcesses.creationPage.buttons.openPublishedVersion')}
                      </span>

                    </Popper>
                  </Box>

                  {initialValues?.autoStart && initialValues?.autoStart !== 'INACTIVE' &&
                    <Box className={classes.autostartStatus} onClick={onAutostartActionsClick}>
                      <Box display="flex" flexDirection="row" alignItems="center" mr={2}>
                        <img className={classes.autostartStatusIcon}
                             src={initialValues?.autoStart === 'ACTIVE'
                                  ? AutoStartOnIcon
                                  : initialValues?.autoStart === 'STOPPED'
                                    ? AutoStartPauseIcon
                                    : AutoStartFinishedIcon
                             } alt=""/>

                        <Typography className={classes.autostartStatusTitle}>
                          {
                            initialValues?.autoStart === 'ACTIVE'
                            ? t('customProcesses.creationPage.processForm.autostart.status.started')
                            : (initialValues?.autoStart === 'STOPPED' || initialValues?.autoStart === 'PAUSED')
                              ? t('customProcesses.creationPage.processForm.autostart.status.stopped')
                              : t('customProcesses.creationPage.processForm.autostart.status.finished')
                          }
                        </Typography>
                      </Box>

                      <img src={DropdownIndicatorDark} alt=""/>
                    </Box>
                  }

                  {(!isTemplatePage && !isReadonlyView && !!initialValues && (isTemplateProcessCopyingAvailable || isTemplateProcessArchivingAvailable)) && (
                    <Box ml={3}>
                      <IconButton
                        className={classes.dialogContentIconButton}
                        onClick={handleAnchorClick}
                        size="large">
                        <MoreHorizIcon fontSize="small" color="secondary" className={classes.dialogContentIcon}/>
                      </IconButton>
                    </Box>
                  )}

                  <Menu
                    id="fade-menu"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleAnchorClose}
                    PaperProps={{ style: { transform: 'translateY(40px)', borderRadius: 10 } }}
                    MenuListProps={{ style: { padding: 0 } }}
                  >
                    {(isTemplateProcessCopyingAvailable) &&
                      <MenuItem
                        className={classes.dialogContentMenuItem}
                        onClick={() => onCopyProcessDialogOpen(publishedVersionValues)}
                      >
                        <img src={MenuCopyIcon}/>
                        <span>{t('customProcesses.creationPage.buttons.copyProcess')}</span>
                      </MenuItem>
                    }


                    {
                      isTemplatePageAvailable &&
                      publishedVersionValues?.published &&
                      typeof onSaveAsTemplateOpen === 'function' &&
                      <MenuItem
                        className={classes.dialogContentMenuItem}
                        onClick={() => {
                          onSaveAsTemplateOpen(publishedVersionValues);
                        }}
                      >
                        <img src={MenuSaveAsTemplateIcon}/>
                        <span>{t('templates.buttons.saveAsTemplate')}</span>
                      </MenuItem>
                    }

                    {isTemplateProcessArchivingAvailable && (
                      publishedVersionValues?.published
                      ? (
                        <MenuItem
                          className={classes.dialogContentMenuItem}
                          onClick={handleDialogOpen}
                        >
                          <img src={MenuArchiveIcon}/>
                          <span>{t('customProcesses.creationPage.buttons.archive')}</span>
                        </MenuItem>
                      )
                      : (
                        <MenuItem
                          className={classes.dialogContentMenuItem}
                          onClick={handleProcessDeleteConfirmationDialogOpen}
                        >
                          <img src={MenuDeleteIcon}/>
                          <span>{t('customProcesses.creationPage.buttons.deleteProcess')}</span>
                        </MenuItem>
                      )
                    )}
                  </Menu>

                  {(!(isReadonlyView || isEditDisabled) &&
                      initialValues && initialValues?.processPublishedId &&
                      (autoSaveProcessSysName?.current || publishedVersionValues?.draftIsChange)) &&
                    (<Box ml={3}>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.headerButton}
                        onClick={handleChangesRollbackConfirmationDialogOpen}
                      >
                        {t('customProcesses.creationPage.buttons.rollbackChanges')}
                      </Button>
                    </Box>)
                  }

                  {!(isReadonlyView || isEditDisabled) &&
                    (<Box ml={3}>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.headerButton}
                        onClick={handleCheck}
                      >
                        {t('customProcesses.creationPage.buttons.check')}
                      </Button>
                    </Box>)
                  }

                  {!(isReadonlyView || isEditDisabled) &&
                    (<Box ml={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.headerButton}
                        disabled={isProcessPublishing || !isSubmitAvailable}
                        startIcon={publishButtonStartIcon}
                        onClick={handleDialogSubmit}>
                        {isTemplatePage
                         ? t('templates.buttons.share')
                         : t('customProcesses.creationPage.buttons.publish')}
                      </Button>
                    </Box>)
                  }
                </Box>
              </Box>

              <DialogContent className={classes.dialogContent}>
                <Box className={classes.dialogContentNavigation}>
                  <Box display="flex" alignItems="center">
                    <Button
                      variant="text"
                      className={cn(classes.dialogContentTab, {
                        [classes.dialogContentTabActive]: currentTab === 'settings',
                        [classes.dialogContentTabWithError]: tabsWithErrors.includes('settings'),
                      })}
                      onClick={() => setCurrentTab('settings')}
                    >
                      <Typography>
                        {t('customProcesses.creationPage.tabs.settings')}
                      </Typography>
                    </Button>

                    {!isReadonlyView &&
                      (<Box p={1}>
                        <img src={ArrowIcon} className={classes.dialogContentIcon} alt=""/>
                      </Box>)
                    }

                    <Button
                      variant="text"
                      className={cn(classes.dialogContentTab, {
                        [classes.dialogContentTabActive]: currentTab === 'steps',
                        [classes.dialogContentTabWithError]: tabsWithErrors.includes('steps'),
                      })}
                      onClick={() => setCurrentTab('steps')}
                    >
                      <Typography>
                        {isReadonlyView
                         ? t('customProcesses.creationPage.tabs.process')
                         : t('customProcesses.creationPage.tabs.builder')
                        }
                      </Typography>
                    </Button>
                  </Box>

                  <Box className={classes.dialogContentNavigationSettings}>
                    {hasErrors &&
                      <div className={classes.headerValidationErrorMessage}>
                        <img src={WarningIcon} alt="warning"/>
                        <span>{t('customProcesses.creationPage.processFormErrors.stepFieldsNotValidated')}</span>
                      </div>
                    }

                    <ButtonGroup
                      disableElevation
                      color="secondary"
                      variant="contained"
                    >
                      {languages.map(language => (
                        <Button
                          key={language}
                          className={cn(
                            classes.dialogContentLanguage,
                            {
                              [classes.dialogContentLanguageActive]: language === currentLanguage,
                              [classes.dialogContentLanguageWithErrors]: languagesWithErrors.includes(language),
                            })}
                          onClick={() => handleActiveLanguageSelect(language)}
                        >
                          {LANGUAGE_TRANSLATION[language]}
                        </Button>
                      ))}
                    </ButtonGroup>

                  </Box>
                </Box>

                <Grid container
                      className={cn(classes.dialogContentGrid, { [classes.hiddenTab]: currentTab !== 'settings' })}>
                  <Box className={cn({
                    [classes.settingsFormWrapper]: !isReadonlyView,
                    [classes.settingsFormWrapperReadonly]: isReadonlyView,
                  })}>
                    <TemplateProcessSettingsForm
                      isReadonlyView={isReadonlyView}
                      onValuesChange={() => setIsSubmitAvailable(true)}
                      register={register}
                      fields={fieldsSettingsForm as unknown as FormProcess[]}
                      update={updateSettingsForm}
                      append={appendSettingsForm}
                      remove={removeSettingsForm}
                      errors={formState?.errors}
                      processIcon={processIcon}
                      setProcessIcon={setProcessIcon}
                      processGroup={processGroup}
                      setProcessGroup={setProcessGroup}
                      languageTabsWithErrors={languageTabsWithErrors}
                      languageTabs={languageTabs}
                      availableLocales={availableLocales}
                      currentLanguageTab={currentLanguageTab}
                      setCurrentLanguageTab={setCurrentLanguageTab}
                      setLanguageTabs={setLanguageTabs}
                      selectedProcessLanguage={currentLanguage}
                    />
                  </Box>
                </Grid>
                <FirstErrorStepIndexContext.Provider value={{
                  index: firstErrorStepIndex,
                  setEl: setFirstErrorStepEl,
                  el: firstErrorStepEl,
                }}>
                  <Grid container
                        className={cn(classes.dialogContentGrid, { [classes.hiddenTab]: currentTab !== 'steps' })}>
                    {isOpen &&
                      <ProcessSetupSidePanel
                        processIntegrations={processIntegrations}
                        isReadonlyView={isReadonlyView}
                        isAutostartActive={isAutostartActive}
                        currentLanguage={currentLanguage}
                        currentStepIndex={currentProcessIndex}
                        currentConditionId={currentConditionId}
                        currentParallelBranchesGroupId={currentParallelBranchesGroupId}
                        selectedItemType={selectedItemType}
                        parallelBranchesGroups={parallelBranchesGroups}
                        processSteps={fields as unknown as ProcessStep[]}
                        conditions={conditions}
                        documents={documents}
                        formErrors={formErrors}
                        focusedStepFieldId={focusedStepFieldId}
                        allUsedGlossaries={allUsedGlossaries}
                        departmentsList={departmentsList}
                        directManagerSteps={directManagerSteps}
                        performerSelectionSteps={performerSelectionSteps}
                        allProcessStepsFields={allProcessStepsFields}
                        focusedDocumentId={focusedDocumentId}
                        setFocusedDocumentId={setFocusedDocumentId}
                        handleAddPreviousStep={handleAddPreviousStep}
                        handleAddProcessStep={handleAddProcessStep}
                        handleAddProcessConditionStep={handleAddProcessConditionStep}
                        handleAddParallelStepsGroup={handleAddParallelStepsGroup}
                        handleParallelBranchStepAdd={handleParallelBranchStepAdd}
                        handleConditionBranchStepAdd={handleConditionBranchStepAdd}
                        handleProcessSelect={handleProcessSelect}
                        handleConditionSelect={handleConditionSelect}
                        handleParallelStepsGroupSelect={handleParallelStepsGroupSelect}
                        gridPathRef={gridPathRef}
                        clearFocusedField={() => setFocusedStepFieldId('')}
                        handleStepFieldValuesChange={handleStepFieldValuesChange}
                        isEditDisabled={isEditDisabled}
                        openStepsLimitReachedDialog={() => setIsOpenStepsLimitDialog(true)}
                        openBranchStepsLimitReachedDialog={() => setIsOpenBranchStepsLimitDialog(true)}
                        handleDocumentFieldsAdd={handleDocumentFieldsAdd}
                        handleDocumentParamsChange={handleDocumentParamsChange}
                        handleDocumentChangeUndo={handleDocumentChangeUndo}
                        handleDocumentPreviewOpen={handleDocumentPreviewOpen}
                        handleDocumentSignatureStepRemovalDialogOpen={handleDocumentSignatureStepRemovalDialogOpen}
                        handleStepFieldDeleteConfirmation={handleStepFieldDeleteConfirmation}
                        isNewField={isNewField}
                      />
                    }

                    <Grid item className={classes.dialogContentWrapper}>
                      {(isOpen && selectedItemType === 'processStep') &&
                        <ProcessStepForm
                          gridPathRef={gridPathRef}
                          handleProcessSelect={handleProcessSelect}
                          isReadonlyView={isReadonlyView}
                          isEditDisabled={isEditDisabled}
                          isDragActive={isDragActive}
                          dragType={dragType}
                          register={register}
                          trigger={trigger}
                          clearErrors={clearErrors}
                          errors={formState.errors}
                          formErrors={formErrors}
                          nameFieldRef={nameFieldRef}
                          currentLanguage={currentLanguage}
                          currentStep={fields[currentProcessIndex] as unknown as ProcessStep}
                          processSteps={fields as unknown as ProcessStep[]}
                          conditions={conditions}
                          parallelBranchesGroups={parallelBranchesGroups}
                          documents={documents}
                          allProcessStepsFields={allProcessStepsFields}
                          directManagerSteps={directManagerSteps}
                          performerSelectionSteps={performerSelectionSteps}
                          isAutostartActive={isAutostartActive}
                          autostartStatus={initialValues?.autoStart}
                          autostartSettings={autostartSettings}
                          departmentsList={departmentsList}
                          focusedStepFieldId={focusedStepFieldId}
                          focusedDocumentId={focusedDocumentId}
                          hasWorkCalendar={hasWorkCalendar}
                          handleAutostartSettingsChange={handleAutostartSettingsChange}
                          handleAutostartChange={handleAutostartChange}
                          handleStepNameChange={handleStepNameChange}
                          handleStepDescriptionChange={handleStepDescriptionChange}
                          handleInitiatorsChange={handleInitiatorsChange}
                          handleAssigneeTypeChange={handleAssigneeTypeChange}
                          handleDirectManagerToggle={handleDirectManagerToggle}
                          handlePerformerSelectionToggle={handlePerformerSelectionToggle}
                          handleSLACheckboxToggle={handleSLACheckboxToggle}
                          handleStepExecutionTimeChange={handleStepExecutionTimeChange}
                          handleStepFieldFocus={(fieldId) => {
                            setIsNewField(false);
                            setFocusedStepFieldId(fieldId);
                            setFocusedDocumentId('');
                          }}
                          handleDocumentFocus={(documentId) => {
                            // setCurrentTab('documents');
                            setFocusedStepFieldId('');
                            setFocusedDocumentId(documentId);
                            setIsNewDocument(false);
                          }}
                          handleDocumentDelete={handleDocumentDelete}
                          handleDocumentVisibilityChange={handleDocumentVisibilityChange}
                          handleStepFieldDelete={(fieldId) => handleStepFieldDeleteStart(fieldId)}
                          summaryFields={summaryFields}
                          toggleStepFieldVisibility={toggleStepFieldVisibilityWithOptionalConfirmation}
                          handleDisplayedVersionToggle={handleDisplayedVersionToggle}
                          openStepsLimitReachedDialog={() => setIsOpenStepsLimitDialog(true)}
                          openBranchStepsLimitReachedDialog={() => setIsOpenBranchStepsLimitDialog(true)}
                          handleDocumentAdd={handleDocumentAdd}
                          handleDocumentPreviewOpen={handleDocumentPreviewOpen}
                          handleCardPreviewDialogOpen={handleCardPreviewDialogOpen}
                        />
                      }

                      {(isOpen && selectedItemType === 'condition') &&
                        <ConditionForm
                          isTemplatePage={isTemplatePage}
                          isReadonlyView={isReadonlyView}
                          isEditDisabled={isEditDisabled}
                          condition={conditions.find(condition => condition.id === currentConditionId)}
                          currentLanguage={currentLanguage}
                          languages={languageTabs}
                          formErrors={formErrors}
                          allProcessStepsFields={allProcessStepsFields}
                          handleDisplayedVersionToggle={handleDisplayedVersionToggle}
                          handleConditionValuesChange={handleConditionValuesChange}
                          handleConditionModalOpen={handleConditionModalOpen}
                        />
                      }

                      {(isOpen && selectedItemType === 'parallelBranchesGroups') &&
                        <ParallelStepsGroupForm
                          isReadonlyView={isReadonlyView}
                          isEditDisabled={isEditDisabled}
                          processSteps={fields as unknown as ProcessStep[]}
                          parallelBranchesGroup={parallelBranchesGroups.find(group => group.id === currentParallelBranchesGroupId)}
                          currentLanguage={currentLanguage}
                          languages={languageTabs}
                          formErrors={formErrors}
                          allProcessStepsFields={allProcessStepsFields}
                          handleDisplayedVersionToggle={handleDisplayedVersionToggle}
                          handleParallelStepsGroupValuesChange={handleParallelStepsGroupValuesChange}
                          handleAddBranch={handleParallelStepsGroupBranchAdd}
                          handleBranchDelete={handleParallelStepsGroupBranchDelete}
                          openStepsLimitReachedDialog={() => setIsOpenStepsLimitDialog(true)}
                        />
                      }
                    </Grid>
                  </Grid>
                </FirstErrorStepIndexContext.Provider>
              </DialogContent>
            </DragDropContext>
          </Dialog>

          {previewedDocumentTemplateId >= 0 &&
            <DocumentDetails
              variant={'documentTemplate'}
              id={previewedDocumentTemplateId}
              toolbarFields={previewDocumentToolbarFields}
              templateSignatories={previewDocumentSignatories}
              onClose={() => handleDocumentPreviewClose()}
            />
          }

          <ArchiveProcessDialog
            isOpen={isDialogOpen}
            onClose={handleDialogClose}
            onArchive={onProcessArchive}
          />

          <DeleteStepFieldConfirmationModal
            isOpen={isDeleteDialogOpen}
            onClose={handleDeleteDialogClose}
            onConfirm={handleStepFieldDeleteConfirmation}
            fieldId={selectedStepField}
            documents={documents}
          />

          <MaximumFieldsErrorDialog
            isOpen={isMaximumFieldsDialogOpen}
            onClose={handleMaximumFieldsDialogClose}
          />

          <StepsLimitReachedDialog
            isOpen={isOpenStepsLimitDialogOpen}
            onClose={() => setIsOpenStepsLimitDialog(false)}
          />

          <BranchStepsLimitReachedDialog
            isOpen={isOpenBranchStepsLimitDialogOpen}
            onClose={() => setIsOpenBranchStepsLimitDialog(false)}
          />

          <AutostartActivationNotPossibleDialog
            isOpen={isAutostartActivationNotPossibleDialogOpen}
            onClose={handleAutostartActivationNotPossibleDialogClose}
          />

          <ConditionAdditionNotPossibleDialog
            isOpen={isConditionAdditionNotPossibleDialogOpen}
            onClose={handleConditionAdditionNotPossibleDialogClose}
          />

          <AutostartDisablingConfirmationDialog
            isOpen={isAutostartDisablingConfirmationDialogOpen}
            onClose={handleAutostartDisablingConfirmationDialogClose}
            onConfirm={handleAutostartDisablingConfirmationDialogConfirm}
          />

          <AutostartActivationConfirmationDialog
            isOpen={isAutostartActivationConfirmationDialogOpen}
            onClose={handleAutostartActivationConfirmationDialogClose}
            onConfirm={handleAutostartActivationConfirmationDialogConfirm}
          />

          <DocumentSignatureStepRemovalDialog
            isOpen={isDocumentSignatureStepRemovalDialogOpen}
            onClose={handleDocumentSignatureStepRemovalDialogClose}
          />

          <ChangesRollbackConfirmationDialog
            isOpen={isChangesRollbackConfirmationDialogOpen}
            onClose={handleChangesRollbackConfirmationDialogClose}
            onConfirm={handleChangesRollback}
          />

          <DeleteProcessStepConfirmationModal
            isOpen={isOpenConfirmDeleteProcessStepModal}
            onClose={() => {
              setIsOpenConfirmDeleteProcessStepModal(false);
              setDataForDelete(null);
            }}
            handleStepDelete={handleStepDelete}
            data={dataForDelete}
            conditions={conditions}
            parallelBranchesGroups={parallelBranchesGroups}
          />

          <DeleteConditionConfirmationDialog
            isOpen={isOpenConfirmDeleteConditionModal}
            onClose={() =>
              setIsOpenConfirmDeleteConditionModal(false)
            }
            onConfirm={() => handleConditionDelete(dataForConditionDelete)
            }
          />

          <DeleteParallelBranchesConfirmationDialog
            isOpen={isOpenConfirmDeleteParallelBranchesModal}
            onClose={() =>
              setIsOpenConfirmDeleteParallelBranchesModal(false)
            }
            onConfirm={() => handleParallelStepsGroupDelete(dataForParallelBranchesDelete)
            }
          />

          <StepConditionModal
            isOpen={isConditionModalOpen}
            onClose={handleConditionModalClose}
            currentLanguage={currentLanguage}
            condition={conditions.find(condition => condition.id === currentConditionId)}
            handleConditionValuesChange={handleConditionValuesChange}
            currentStep={fields[currentProcessIndex] as unknown as ProcessStep}
            processSteps={fields as unknown as ProcessStep[]}
            allProcessStepsFields={allProcessStepsFields}
            departmentsList={departmentsList}
          />

          <ProcessCardPreviewDialog
            isOpen={isCardPreviewDialogOpen}
            onClose={handleCardPreviewDialogClose}
            onSaveProcessStepPreview={handleProcessPreviewSavingStart}
            allProcessStepsFields={allProcessStepsFields}
            currentStep={fields[currentProcessIndex] as unknown as ProcessStep}
            processName={processDisplayName}
            processDescription={processDisplayDescription}
            currentLanguage={currentLanguage}
            conditions={conditions}
            parallelBranchesGroups={parallelBranchesGroups}
          />

          <StepPreviewSavingVariantSelectionDialog
            isOpen={isCardPreviewSavingTypeDialogOpen}
            onClose={handleCardPreviewSavingTypeDialogClose}
            onConfirm={(savingType) => updateStepPreview(savingType)}
          />

          <HidePreviewFieldConfirmationModal
            isOpen={isHidePreviewFieldConfirmationModalOpen}
            onClose={handleHidePreviewFieldConfirmationModalClose}
            onConfirm={handleToggleSelectedFieldVisibilityConfirmation}
          />


        </ConfirmDeleteParallelBranchesModalContext.Provider>
      </ConfirmDeleteConditionModalContext.Provider>
    </ConfirmDeleteProcessStepModalContext.Provider>
  );
};
