import React, {
  memo,
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect, ReactElement
} from 'react';
import cn from 'classnames';
import { FormControl, TextField, FormLabel } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import addIcon from 'assets/images/icons/blue-plus-sign.svg';
import linkIcon from 'assets/images/icons/link-icon.svg';
import editIcon from 'assets/images/icons/pencil-icon.svg';

import useStyles from './useStyles';

type Props = {
  name: string;
  label: string;
  rules: { [key: string]: string };
  isActive: boolean;
  isTaskDetailsVariant?: boolean;
};

export const LinkInput = memo(({
  name,
  label,
  isActive,
  rules,
  isTaskDetailsVariant = false,
}: Props): ReactElement => {
  const classes = useStyles();
  const { register, formState: { errors }, setValue, watch } = useFormContext();

  const ref = useRef(null);
  const [focused, setFocused] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    setInputValue(watch(name));
  }, [watch, name]);

  const isEmpty = useMemo((): boolean =>
    inputValue.trim().length === 0,
    [inputValue]);

  const handleChange = useCallback((value: string): void => {
      setInputValue(value);
      setValue(name, value);
    }, [setInputValue, setValue, name]);

  useEffect(() => {
    // force focus on input when Add link or edit button is clicked
    if (focused) {
      ref.current?.focus();
    } else {
      // force add https protocol if protocol is not specified to make link open correctly
      if (
        !!inputValue.trim() &&
        !(
          inputValue.trim().startsWith('http://') ||
          inputValue.trim().startsWith('https://')
        )
      ) {
        const inputValueWithHttpsProtocol = 'https://' + inputValue;
        setInputValue(inputValueWithHttpsProtocol);
        setValue(name, inputValueWithHttpsProtocol);
      }
    }
  }, [focused]);

  return (
    <FormControl
      fullWidth
      className={cn({ [classes.taskDetailsWrapper]: isTaskDetailsVariant })}
    >
      <div
        className={cn({
          [classes.taskDetailsLabelWrapper]: isTaskDetailsVariant,
        })}
      >
        <FormLabel className={classes.label}>{label}</FormLabel>
      </div>
      <input type="hidden" data-selenium={name} {...register(name, rules)} />

      <div
        className={cn({
          [classes.taskDetailsInputWrapper]: isTaskDetailsVariant,
        })}
      >
        <TextField
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          className={focused ? classes.visible : classes.hidden}
          focused={focused}
          value={inputValue}
          placeholder="example.com"
          name={name + '_input'}
          size="medium"
          disabled={!isActive}
          inputRef={ref}
          onChange={(e) => handleChange(e.target.value)}
          error={!!errors[name]}
          helperText={!!errors[name] && errors[name].message}
          InputProps={{
            startAdornment: <img src={linkIcon} className={classes.linkIcon} />,
            onFocus: () => setFocused(true),
            onBlur: () => setFocused(false),
          }}
        />

        {!focused && isEmpty && (
          <div className={classes.addLinkWrapper}>
            <div
              className={classes.addLink}
              onClick={() => {
                setFocused(true);
              }}
            >
              <img src={addIcon} /> Add link
            </div>
            {!!errors[name] && (
              <div className={classes.error}>{errors[name].message}</div>
            )}
          </div>
        )}

        {!focused && !isEmpty && (
          <div className={classes.linkValue}>
            <a href={watch(name)} target="_blank">
              {watch(name)}
            </a>
            <img
              src={editIcon}
              className={classes.editButton}
              onClick={() => {
                setFocused(true);
              }}
            />
          </div>
        )}
      </div>
    </FormControl>
  );
});
