import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  scrollbar: {
    width: '100%',
    height: 'calc(100vh - 335px) !important',
    paddingRight: 12,
  },
  activePanelScrollbar: {
    backgroundColor: '#F5F8FA',
    height: 'calc(100vh - 335px - 59px) !important',
  },
  ticket: {
    paddingLeft: 0
  },
  emptyBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    background: "#F7F8FC"
  },
  tableFooterHint: {
    color: '#6D6E85'
  },
  massActionsPanelWrapper: {
    marginLeft: 24,
    marginRight: 24,
    marginTop: 12,
  }
}));

export default useStyles;
