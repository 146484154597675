import { makeStyles } from "@material-ui/core";

type Props = {
  isCreateTemplateDialogOpen: boolean
  isSetupTemplateDialogOpen: boolean
}

export default makeStyles(() => ({
  root: ({isCreateTemplateDialogOpen, isSetupTemplateDialogOpen}: Props) => ({
    position: "fixed",
    zIndex: isCreateTemplateDialogOpen || isSetupTemplateDialogOpen ? -1 : 1300,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
  }),
  header:{
    height: 64,
    padding: "12px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    background: "white",
    borderBottom: "1px solid rgba(38, 40, 66, 0.08)"
  },
  body: {
    height: "calc(100vh - 64px)",
    display: "flex",
  },
  sideMenu: {
    width: 320,
    padding: "24px 12px",
    background: "white",
    borderRight: "1px solid rgba(38, 40, 66, 0.08)",
    flexShrink:0
  },
  content: {
    flexGrow: 1,
    padding: "24px 24px 24px 24px",
    background: "#F7F8FC",
    overflowY: "auto"
  },
  modalTitle: {
    color: "#262842",
    fontSize: 20,
    fontWeight: 500
  },
  createProcessBtn: {
    borderRadius: 8,
    border: "1px solid rgba(38, 40, 66, 0.12)",
    padding: "12px 16px",
    display: "flex",
    gap: 8,

    overflow: "hidden",
    cursor: "pointer",

    "&:hover": {
      border: "1px solid #007994"
    },

    "& h5": {
      color: "#262842",
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "20px",

      overflow: "hidden",
      textOverflow: "ellipsis"
    },

    "& p": {
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  createProcessBtnTextWrapper: {
    overflow: "hidden"
  },
  secondaryText: {
    marginTop: 4,
    color: "#6D6E85",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "18px"
  },
  addIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,

    padding: 0,
    width: 40,
    height: 40,

    borderRadius: 10,
    background: "#F7F8FC",

    userSelect: "none"
  },
  tab: {
    padding: "4px 12px",
    borderRadius: 10,
    height: 40,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 12,

    color: "#262842",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "18px",

    cursor: "pointer",

    "&:hover": {
      background: "rgba(38, 40, 66, 0.08)"
    }
  },
  activeTab: {
    background: "rgba(38, 40, 66, 0.04)",
    fontWeight: 500,
  },
  tabTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  allTemplatesTitle: {
    color: "#262842",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  categoryTitle: {
    color: "#6D6E85",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    marginTop: 24
  },
  templateCategory: {

  },
  templateCards: {
    marginTop: 20,
    display: 'grid',
    gap: 20,
    // gridTemplateColumns: "repeat(auto-fill,minmax(250px, 290px))"
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))"

  },
  loaderWrapper: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  }
}))
