import React, { useCallback, useEffect, useState } from 'react';

import { Typography } from '@material-ui/core';

import { getDirectoryInfo } from 'api/requests';
import useStyles from './useStyles';

const GlossaryValue = ({ id, count = 1 }: { id: string, count?: number }) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);

  const getValue = useCallback(async () => {
    setLoading(true);
    try {
      const info = await getDirectoryInfo(id);
      setValue(info?.value);
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getValue();
  }, [getValue, id]);

  return <Typography className={classes.textValueWrapper}>
    {!loading && (value || id)} {(!loading && count > 1) && `(+${count - 1})`}
  </Typography>;
};

export default GlossaryValue;