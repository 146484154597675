import { useEffect, useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTaskState } from 'store/requests';
import { useStorageMigration } from "hooks";
import { getAttachmentFile } from 'components/Attachments/utils';
import {
  getP12ClientKeyObject,
  signDocumentWithTSP,
  sendSignatureToParagraph,
  getPointInstance,
  getPointSignatureInstance,
  getSigningInstance,
  getBinFromEDSSubject,
  getOrganizationList,
  getAttachmentInformationById,
  convertFileToBase64
} from './utils';

import {
  KEYS,
  BUTTON_STATES,
  ERROR_MESSAGES
} from '../EdsConstants';

import { environment } from 'environments/environment';

const PARAGRAPH_DEFAULT_ACCOUNT = environment.NX_DEFAULT_PARAGRAPH_ACCOUNT;

type Props = {
  userModelId: string;
  attachmentName: string;
  attachmentStorage?: string;
  binAttribute: string;
  companyAttribute: string;
  individualNumberAttribute: string;
  emailAttribute: string;
  supplierEmailAttribute: string;
  existingSupplierAttribute: string;
  supplierBinAttribute: string;
  labelValue: string;
  isInternalSign: boolean;
  isExternalSign: boolean;
  isInternalSignWithEmployee: boolean;
  isInternalSignEmployeeInitiator: boolean;
  onClick: () => void;
};

type P12ClientKeyObject = {
  notAfter?: string,
  keyUsage?: string,
  notBefore?: string,
  subject?: any
};

type SignDocumentWithTSPResult = {
  cms?: any
};

const useEdsForm = ({
  userModelId = PARAGRAPH_DEFAULT_ACCOUNT,
  attachmentName,
  attachmentStorage,
  binAttribute,
  companyAttribute,
  individualNumberAttribute,
  emailAttribute,
  supplierEmailAttribute,
  existingSupplierAttribute,
  supplierBinAttribute,
  labelValue = BUTTON_STATES.SIGN_WITH_EDS,
  isInternalSign,
  isExternalSign,
  isInternalSignWithEmployee,
  isInternalSignEmployeeInitiator,
  onClick,
}: Props) => {
  const { getValues } = useFormContext();

  const { data: bpmTask } = useTaskState();
  const { actualStorage } = useStorageMigration({
    storage: attachmentStorage,
    createdDate: bpmTask.createdDate
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [buttonLabel, setButtonLabel] = useState(labelValue);

  const [clientEdsFileName, setClientEdsFileName] = useState('');
  const [clientEdsFileBase64, setClientEdsFileBase64] = useState(null);
  const [clientEdsPassword, setClientEdsPassword] = useState(null);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!loading) {
        return;
      }

      event.preventDefault();
      event.returnValue = true;

      return true;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [loading]);

  const isButtonDisabled = useMemo(
    () => !bpmTask || loading || !clientEdsFileName || !clientEdsPassword,
    [bpmTask, loading, clientEdsFileName, clientEdsPassword]
  );

  const handleClientEdsPassword = (password) => setClientEdsPassword(password);

  const handleClientSignature = async () => {
    const getParagraphsEntities = async (subject) => {
      const companyBin = getValues(binAttribute);
      const company = getValues(companyAttribute);
      const individualNumber = getValues(individualNumberAttribute);

      const { edsBin } = getBinFromEDSSubject({
        isInternalSignEmployeeInitiator,
        individualNumber,
        subject,
        handleError: () => sendErrorCode(ERROR_MESSAGES.BIN_EMPTY),
      });

      const organizations = await getOrganizationList({
        userModelId,
        handleOrganizationsEmptyError: () =>
          sendErrorCode(ERROR_MESSAGES.ORGANIZATIONS_EMPTY),
        handleOrganizationsFetchError: () =>
          sendErrorCode(ERROR_MESSAGES.FETCH_ORGANIZATION_ERROR),
      });

      const pointSignatureInstance = getPointSignatureInstance();
      const signingInstance = getSigningInstance();
      const pointInstance = getPointInstance({
        isInternalSignEmployeeInitiator,
        organizations,
        companyBin,
        edsBin,
        processCompanyName: company,
        handleBinError: () => sendErrorCode(ERROR_MESSAGES.BIN_DOES_NOT_MATCH),
      });

      return {
        ...pointInstance,
        ...pointSignatureInstance,
        ...signingInstance,
        processSysId: bpmTask.businessTask.actionSysName,
      };
    };

    const getDocuments = async ({
      isInternalSignEmployeeInitiator,
      blobArray,
    }) => {
      const { name, type } = attachment;
      const blob = new Blob([blobArray], { type: type });
      const file = new File([blob], name);

      if (isInternalSignEmployeeInitiator) {
        return {
          sign: '',
          file,
        };
      }

      setButtonLabel(BUTTON_STATES.SIGNING_DOCUMENT);

      const attachmentFileBase64 = await convertFileToBase64(file);
      const signDocumentWithTSPResult: SignDocumentWithTSPResult = await signDocumentWithTSP(
        {
          documentBase64: attachmentFileBase64,
          edsBase64: clientEdsFileBase64,
          edsPassword: clientEdsPassword,
          handleError: (errorMessage) =>
            sendErrorCode(
              errorMessage || ERROR_MESSAGES.DOCUMENT_SIGNING_ERROR
            ),
        }
      );

      const { cms } = signDocumentWithTSPResult;

      return {
        sign: cms,
        file,
      };
    };
    const getSupplierData = () => {
      if (isInternalSign) {
        return {};
      }

      if (isExternalSign) {
        const supplierEmail = bpmTask.values[supplierEmailAttribute];
        const existingSupplier = bpmTask.values[existingSupplierAttribute];
        const supplierBin = bpmTask.values[supplierBinAttribute];
        const bin = bpmTask.values[binAttribute];

        const supplierChosenBin = existingSupplier ? bin : supplierBin;
        const formattedSupplierBin = supplierChosenBin.replace(/ /g, '');

        return {
          bin: formattedSupplierBin,
          counterpartyEmail: supplierEmail,
        };
      }

      if (isInternalSignWithEmployee || isInternalSignEmployeeInitiator) {
        const email = getValues(emailAttribute);
        const individualNumber = getValues(individualNumberAttribute);

        return {
          bin: individualNumber?.replace(/ /g, ''),
          counterpartyEmail: email,
        };
      }

      return {};
    };

    const handleAttachmentFileSuccess = async (blobArray) => {
      const { processInstanceId } = bpmTask;
      const { subject } = p12ClientKeyObject;

      const paragraphEntities = await getParagraphsEntities(subject);
      const documentsData = await getDocuments({
        isInternalSignEmployeeInitiator,
        blobArray,
      });
      const supplierData = getSupplierData();

      const paramsObject = {
        dmsId: processInstanceId,
        ...documentsData,
        ...supplierData,
        ...paragraphEntities,
      };

      const formData = new FormData();
      Object.keys(paramsObject).forEach((key) =>
        formData.append(key, paramsObject[key])
      );

      setButtonLabel(BUTTON_STATES.SENDING_REQUEST);

      await sendSignatureToParagraph({
        isExternal: !!isInternalSignEmployeeInitiator,
        userModelId,
        formData,
        handleSuccess: () => onClick(),
        handleError: (errorMessage) =>
          sendErrorCode(
            errorMessage || ERROR_MESSAGES.SIGNATURE_ENDPOINT_ERROR
          ),
      });

      setLoading(false);
      setButtonLabel(labelValue);
    };

    setError('');
    setLoading(true);
    setButtonLabel(BUTTON_STATES.LOADING);

    const p12ClientKeyObject: P12ClientKeyObject = await getP12ClientKeyObject({
      isInternalSignEmployeeInitiator,
      edsBase64: clientEdsFileBase64,
      edsPassword: clientEdsPassword,
      isOCSPVerified: true,
      handleError: (errorMessage) =>
        sendErrorCode(errorMessage || ERROR_MESSAGES.GET_PASSWORD_ERROR),
    });

    // TODO: Узнать почему пропускаем проверку для работников
    if (!isInternalSignEmployeeInitiator) {
      const { notAfter, notBefore, keyUsage } = p12ClientKeyObject;
      const currentDate = new Date();
      const certNotAfterDate = new Date(notAfter);
      const certNotBeforeDate = new Date(notBefore);

      if (keyUsage === KEYS.AUTH) {
        sendErrorCode(ERROR_MESSAGES.EDS_NOT_AUTH_KEY);
      }

      if (currentDate < certNotBeforeDate) {
        sendErrorCode(ERROR_MESSAGES.EDS_NOT_BEFORE_KEY);
      }

      if (currentDate >= certNotAfterDate) {
        sendErrorCode(ERROR_MESSAGES.EDS_NOT_AFTER_KEY);
      }
    }

    const attachmentId = getValues(attachmentName);
    if (!attachmentId || attachmentId.trim().length === 0) {
      sendErrorCode(ERROR_MESSAGES.ATTACHMENT_KEY_NOT_FOUND);
    }

    setButtonLabel(BUTTON_STATES.DATA_PROCESSING);

    const attachment = await getAttachmentInformationById({
      attachmentId,
      handleError: () =>
        sendErrorCode(ERROR_MESSAGES.GET_ATTACHMENT_INFO_ERROR),
    });

    await getAttachmentFile({
      fileId: attachment.fileInstanceId,
      attachmentId,
      attachmentStorage: !!attachment.fileInstanceId ? actualStorage : 'PS',  // для исправления заявок где файл в PS но у кнопки подписания настроен Google Drive
      handleSuccess: (blobArray) => handleAttachmentFileSuccess(blobArray),
      handleError: ({ message }) =>
        sendErrorCode(message || ERROR_MESSAGES.GET_ATTACHMENT_FILE_ERROR),
    });
  };

  const handleClientEdsFile = async (event) => {
    const file = event.target.files[0];
    const { name } = file;

    const fileBase64 = await convertFileToBase64(file);

    setClientEdsFileName(name);
    setClientEdsFileBase64(fileBase64);
  };

  const sendErrorCode = (message) => {
    setError(message);
    setLoading(false);
    setButtonLabel(BUTTON_STATES.SIGN_WITH_EDS);

    throw new Error(message);
  };

  return {
    loading,
    error,
    buttonLabel,
    clientEdsFileName,
    isButtonDisabled,
    handleClientSignature,
    handleClientEdsPassword,
    handleClientEdsFile,
  };
};

export default useEdsForm;
