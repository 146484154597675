import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
    boxSizing: 'content-box',
    margin: "12px 0"
  },
  title: {
    fontSize: 24
  }
}));
