import { Typography } from '@material-ui/core';
import React, { memo, ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from "react-i18next";

import { UserInfo } from 'components';

import useStyles from './useStyles';
import { useReadOnlyUser } from './useReadOnlyUser';

type ReadOnlyUserProps = {
  name: string;
  hint: string;
  params: {
    multiple: boolean;
    systemVariable: string;
    showInitiatorValue?: boolean;
    showEmptyValueMessage?: boolean;
    emptyValueMessage?: string;
    childrenAttributesMapping: string;
  };
  isTaskDetailsVariant: boolean;
};

export const ReadOnlyUser = memo(({
  name,
  hint,
  params,
  isTaskDetailsVariant = false,
}: ReadOnlyUserProps): ReactElement => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { register } = useFormContext();
  const {
    value,
    isEmptyValue,
    user,
    users
  } = useReadOnlyUser({
    name,
    params
  });

  const {
    multiple = false,
    showEmptyValueMessage = false,
    emptyValueMessage = ''
  } = params;

  if (isEmptyValue) {
    return (
      <div>
        <Typography gutterBottom>{hint}</Typography>
        <Typography className={classes.emptyValueMessage}>
          {showEmptyValueMessage ? emptyValueMessage : t('form_components.readOnly.emptyValue')}
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <Typography gutterBottom>{hint}</Typography>
      {multiple ? (
        <div className={classes.userList}>
          {users?.map((e, i) => (
            <div className={classes.multipleItem}>
              {isTaskDetailsVariant ? (
                <UserInfo key={i} user={e} avatarSize={24} />
              ) : (
                <UserInfo key={i} user={e} />
              )}
            </div>
          ))}
        </div>
      ) : user ? (
        isTaskDetailsVariant ? (
          <UserInfo user={user} avatarSize={24} />
        ) : (
          <UserInfo user={user} />
        )
      ) : (
        value ? t("task_data_view.employee_not_found") : null
      )}

      <input type="hidden" data-selenium={name} {...register(name)} />
    </div>
  );
});
