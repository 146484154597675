import { makeStyles } from '@material-ui/core';

interface IconStyleProps {
  size: number;
}

export default makeStyles(() => ({
  iconStatus: {
    position: 'relative',
    width: ({ size }: IconStyleProps) => `${size}px`,
    height: ({ size }: IconStyleProps) => `${size}px`,
    minWidth: ({ size }: IconStyleProps) => `${size}px`,
    minHeight: ({ size }: IconStyleProps) => `${size}px`,
    transform: 'rotateY(0deg) rotate(45deg)',
    backgroundColor: '#8754F6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
  },
  revertPlusIcon: {
    transform: 'rotateY(0deg) rotate(45deg)',
  },
  purpleBackground: {
    backgroundColor: '#8754F6'
  },
  greenBackground: {
    backgroundColor: '#3B854B'
  },
  grayBackground: {
    backgroundColor: '#A9A9B8'
  },
  plusIcon: {
    width: ({ size }: IconStyleProps) => `${size - 6}px`,
    height: ({ size }: IconStyleProps) => `${size - 6}px`
  },
  stepIcon: {
    position: 'relative',
    right: '1px',
    width: '20px',
    border: '3px solid #497CF6',
    height: '14px',
    borderRadius: '3px',
  },
  stepIconDisabled: {
    borderColor: '#A9A9B8'
  }
}));
