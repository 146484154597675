import React, { ReactElement } from 'react';
import {
  Box,
  Button,
  IconButton, Modal,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import useDeleteProcessDialogStyles from './DeleteProcessDialog.useStyles';

interface DeleteProcessDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteProcessDialog = ({ isOpen, onClose, onDelete }: DeleteProcessDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useDeleteProcessDialogStyles();

  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Modal open={isOpen}>
      <>
        <Box className={classes.modalBody}>
          <div className={classes.modalHeader}>
            <Typography className={classes.title}>
              {t('customProcesses.creationPage.deleteProcessModal.title')}
            </Typography>

            <IconButton onClick={onClose}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </div>

          <div className={classes.modalContent}>
            <Typography className={classes.description}>
              {t('customProcesses.creationPage.deleteProcessModal.description')}
            </Typography>
          </div>

          <div className={classes.modalFooter}>
            <Button color="secondary" variant="contained" onClick={onClose} className={classes.modalButton}>
              {t('comment_modal.button_cancel')}
            </Button>

            <Button color="primary" onClick={handleDelete} className={classes.modalButton}>
              {t('customProcesses.creationPage.buttons.delete')}
            </Button>
          </div>
        </Box>
      </>
    </Modal>
  );
};
