import React from 'react';
import {
  Box,
  Button,
  ButtonBase,
  Typography,
  DialogActions,
  Dialog,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import CloseIcon from 'assets/images/icons/close-white.svg';
import useStyles from './useStyles';
import { Spinner } from 'components';

export const DeletionDialog = ({
  open = false,
  onCancel,
  onDelete,
  deleting = false,
  templateId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
      <Box component="header" className={classes.header}>
        <Typography className={classes.headerTitle}>
          {t('template_delete_modal.title')}
        </Typography>
        <Typography className={classes.headerSubtitle}>
          {t('template_delete_modal.text')}
        </Typography>
        <ButtonBase className={classes.closeButton} onClick={onCancel}>
          <img src={CloseIcon} alt="Close" />
        </ButtonBase>
      </Box>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          {t('template_delete_modal.button_cancel')}
        </Button>
        <Button
          className={classes.deleteButton}
          onClick={() => onDelete(templateId)}
        >
          {deleting ? <Spinner size={16} /> : t('template_delete_modal.button_submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
