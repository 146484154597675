import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  ConditionStep,
  Language,
  ParallelBranchesGroup,
  ProcessStep,
  StepName,
} from '../../../TemplateProcesses.types';

type Props = {
  conditions: ConditionStep[],
  parallelBranchesGroups: ParallelBranchesGroup[],
  [key: string]: any
}

export const useProcessSteps = ({
  fields,
  allProcessStepsFields,
  performerSelectionSteps,
  conditions,
  setConditions,
  parallelBranchesGroups,
  setParallelBranchesGroups,
  isAutostartActive,
  languages,
  currentLanguage,
  nameFieldRef,
  gridPathRef,
  firstErrorStepEl,
  setSummaryFields,
  setIsSubmitAvailable,
  setCurrentProcessIndex,
  setCurrentConditionId,
  setSelectedItemType,
  setFocusedStepFieldId,
  setFocusedDocumentId,
  setSelectedStepField,
  setDirectManagerSteps,
  setPerformerSelectionSteps,
  setFirstErrorStepEl,
  setFirstErrorStepIndex,
  append,
  replace,
  handleConditionAdditionNotPossibleDialogOpen,
  currentTypeSummaryFields,
}: Props) => {
  const stepNameTemplate = useMemo((): StepName =>
      languages.reduce((result: StepName, language: Language) => ({
        ...result,
        [language]: '',
      }), {} as StepName),
    [languages]);

  const stepDescriptionTemplate = useMemo((): StepName =>
      languages.reduce((result: StepName, language: Language) => ({
        ...result,
        [language]: '',
      }), {} as StepName),
    [languages]);

  const handleStepDelete = (processStep: ProcessStep, isPerformerStep?: boolean): void => {
    setIsSubmitAvailable(true);
    const processSteps = fields as unknown as ProcessStep[];
    setCurrentProcessIndex(processStep.stepOrder - 2);
    setSelectedItemType('processStep');
    setFocusedStepFieldId('');
    const isPerformerSelectionStep = performerSelectionSteps.includes(processStep.stepOrder);

    const removedSteps = isPerformerSelectionStep
                         ? [processStep.stepOrder, processStep.stepOrder + 1] // to remove automatically added performer step
                         : [processStep.stepOrder];
    const fieldsAddedOnRemovedSteps = allProcessStepsFields
      .filter(item => removedSteps.includes(item?.firstAppearanceStep))
      .map(item => item?.field?.id);

    let remainingParallelGroupSteps = [];
    if (processStep?.isParallelBranchesGroupStep) {
      const stepParallelStepsGroup = parallelBranchesGroups.find(group => group.steps.flat().includes(processStep.stepOrder));
      const stepBranch = stepParallelStepsGroup.steps.find(b => b.includes(processStep.stepOrder));
      if (stepParallelStepsGroup?.steps.length === 2 && stepBranch.length === 1) {
        // remove this branch
        const modifiedParallelStepsGroupsList = parallelBranchesGroups
          .filter(group => group.id !== stepParallelStepsGroup.id)
          .map(group => {
            if (group.previousStepOrder >= processStep.stepOrder - 1) {
              group.previousStepOrder -= 1;
              group.steps = group.steps.map(b => b.map(v => v - 1));
              return group;
            } else {
              return group;
            }
          });
        setParallelBranchesGroups(() => modifiedParallelStepsGroupsList);
        remainingParallelGroupSteps = stepParallelStepsGroup.steps.flat().filter(v => v !== processStep.stepOrder);
      } else {
        const modifiedParallelStepsGroupsList = parallelBranchesGroups
          .map(group => {
            if (group.id === stepParallelStepsGroup.id) {
              group.steps = group.steps
                .map(b => b
                  .filter(order => order !== processStep.stepOrder)
                  .map(v => v < processStep.stepOrder ? v : v - 1),
                ).filter(b => b.length > 0);
              return group;
            }

            if (group.previousStepOrder >= processStep.stepOrder - 1) {
              group.previousStepOrder -= 1;
              group.steps = group.steps.map(b => b.map(v => v - 1));
              return group;
            }

            return group;
          });
        setParallelBranchesGroups(() => modifiedParallelStepsGroupsList);
      }
    } else {
      const modifiedParallelStepsGroupsList = parallelBranchesGroups.map(group => {
        if (group.previousStepOrder >= processStep.stepOrder) {
          group.previousStepOrder -= 1;
          group.steps = group.steps.map(b => b.map(v => v - 1));
          return group;
        } else {
          return group;
        }
      });
      setParallelBranchesGroups(() => modifiedParallelStepsGroupsList);
    }

    if (fieldsAddedOnRemovedSteps.length > 0) {
      const modifiedProcessFields = processSteps
        .map((processField, index) => {
          if (index < processStep.stepOrder) {
            return processField;
          }

          return {
            ...processField,
            fields: processField.fields
              .filter(field => !fieldsAddedOnRemovedSteps.includes(field.id))
              .map((field, index) => ({ ...field, order: index + 1 })),
            summaryFields: processField.summaryFields.filter(id => !fieldsAddedOnRemovedSteps.includes(id))
          };
        })
        .filter(({ stepOrder }) => !removedSteps.includes(stepOrder))
        .map((step, index) => ({
          ...step,
          stepOrder: index + 1,
          isParallelBranchesGroupStep: remainingParallelGroupSteps.includes(step.stepOrder)
            ? false
            : step?.isParallelBranchesGroupStep,
        }));
      replace(modifiedProcessFields);

      const modifiedConditions = conditions.map(c => {
        if (fieldsAddedOnRemovedSteps?.includes(c?.condition?.field)) {
          c.condition.field = '';
          c.condition.type = '';
          c.condition.value = '';
        }
        return c;
      });
      setConditions(() => modifiedConditions);

      setSelectedStepField('');
    } else {
      replace(
        processSteps
          .filter(({ stepOrder }) => !removedSteps.includes(stepOrder))
          .map((step, index) => ({
            ...step,
            stepOrder: index + 1,
            isParallelBranchesGroupStep: remainingParallelGroupSteps.includes(step.stepOrder)
              ? false
              : step?.isParallelBranchesGroupStep,
          })),
      );
    }

    if (isPerformerStep) {
      setPerformerSelectionSteps(steps => steps.filter(step => processStep.stepOrder - 1 !== step));
    }

    const modifiedConditionsList = conditions.map(c =>
      c.previousStepOrder >= processStep.stepOrder
      ? {
          ...c,
          previousStepOrder: c.previousStepOrder - removedSteps?.length,
          positiveBranch: c.positiveBranch.map(step => step - removedSteps?.length),
          negativeBranch: c.negativeBranch.map(step => step - removedSteps?.length),
        }
      : c);

    if (processStep?.isConditionBranchStep) {
      // если уделали последний шаг из ветки - поставить соответствующий boolean параметр в false
      // не забыть поставить его в true при добавлении шага - уже сделано
      // + сдвиг на количество удаленных шагов шагов в ветках и шага к которому привязано условие
      const stepCondition = conditions?.find(c => [
        ...(c?.hasPositiveBranch ? c?.positiveBranch : []),
        ...(c?.hasNegativeBranch ? c.negativeBranch : []),
      ].includes(processStep?.stepOrder));

      if (stepCondition) {
        const isPositiveBranchStep = stepCondition?.positiveBranch?.includes(processStep?.stepOrder);
        if (isPositiveBranchStep) {
          stepCondition.positiveBranch = stepCondition.positiveBranch
            .filter(v => !removedSteps?.includes(v))
            .map(v => v > Math.max(...removedSteps) ? v - removedSteps.length : v);
          stepCondition.negativeBranch = stepCondition.negativeBranch.map(v => v - removedSteps?.length);
          stepCondition.hasPositiveBranch = stepCondition.positiveBranch.length > 0;
        } else {
          stepCondition.negativeBranch = stepCondition.negativeBranch
            .filter(v => !removedSteps?.includes(v))
            .map(v => v > Math.max(...removedSteps) ? v - removedSteps.length : v);
          stepCondition.hasNegativeBranch = stepCondition.negativeBranch.length > 0;
        }

        const conditionIndex = modifiedConditionsList.findIndex(c => c.id === stepCondition.id);
        modifiedConditionsList[conditionIndex] = stepCondition;
      }
    }

    setConditions(() => modifiedConditionsList);

    setDirectManagerSteps(steps => steps
      .filter(step => step !== processStep.stepOrder)
      .map(step => step < processStep.stepOrder ? step : (isPerformerSelectionStep ? step - 2 : step - 1)),
    );
    setPerformerSelectionSteps(steps => steps
      .filter(step => step !== processStep.stepOrder)
      .map(step => step < processStep.stepOrder ? step : (isPerformerSelectionStep ? step - 2 : step - 1)),
    );
  };

  const handleAddPreviousStep = ({ stepOrder }: ProcessStep): void => {
    setIsSubmitAvailable(true);
    const stepsBefore = fields.slice(0, stepOrder - 1);
    const stepsAfter = fields.slice(stepOrder - 1).map((step, index) => ({
      ...step,
      stepOrder: stepOrder + 1 + index,
    }));
    let stepToCopySummaryFields: ProcessStep | undefined;
    for(let i = stepsBefore.length - 1;i >= 0;i--) {
      if(!currentTypeSummaryFields.includes(stepsBefore[i].id)) {
        stepToCopySummaryFields = fields[i];
        break;
      }
    }
    if(!stepToCopySummaryFields) {
      for(let i = stepOrder - 1;i < fields.length;i++) {
        if(!currentTypeSummaryFields.includes(fields[i].id)) {
          stepToCopySummaryFields = fields[i];
          break;
        }
      }
    }
    setIsSubmitAvailable(true);

    const previousStepsFields = fields
      .slice(0, stepOrder - 1)
      .reduce((acc, step) => {
        step.fields.forEach(field => {
          if (!acc.some(accField => accField.id === field.id)) {
            acc.push(field);
          }
        });
        return acc;
      }, [])
      .map((item, index) => ({
        ...item,
        order: index + 1,
        isRequired: false,
        isEditable: false,
      }));

    replace(
      [
        ...stepsBefore,
        {
          id: uuid(),
          stepOrder,
          stepName: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
          stepDescription: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
          assigneeId: [],
          fields: previousStepsFields,
          timer: '',
          hasTimer: false,
          parallel: false,
          isConditionBranchStep: false,
          isParallelBranchesGroupStep: false,
          summaryFields: stepToCopySummaryFields ? stepToCopySummaryFields.summaryFields : []
        },
        ...stepsAfter,
      ],
    );
    setDirectManagerSteps(steps => steps.map(step => step < stepOrder ? step : step + 1));
    setPerformerSelectionSteps(steps => steps.map(step => step < stepOrder ? step : step + 1));

    const modifiedConditionsList = conditions.map(c =>
      c.previousStepOrder >= stepOrder - 1
      ? {
          ...c,
          previousStepOrder: c.previousStepOrder + 1,
          positiveBranch: c.positiveBranch.map(step => step + 1),
          negativeBranch: c.negativeBranch.map(step => step + 1),
        }
      : c);

    setConditions(() => modifiedConditionsList);

    const modifiedParallelStepsGroupsList = parallelBranchesGroups.map(group => {
      if (group.previousStepOrder >= stepOrder - 1) {
        group.previousStepOrder += 1;
        group.steps = group.steps.map(b => b.map(v => v + 1));
        return group;
      } else {
        return group;
      }
    });
    setParallelBranchesGroups(() => modifiedParallelStepsGroupsList);

    setTimeout(() => {
      setCurrentProcessIndex(stepOrder - 1);
      setSelectedItemType('processStep');
      setFocusedStepFieldId('');
      nameFieldRef?.current?.focus();
    });
  };

  const handleAddProcessStep = (): void => {
    setIsSubmitAvailable(true);
    const newStepOrder = fields.length + 1;
    let stepToCopySummaryFields: ProcessStep | undefined;
    for(let i = fields.length - 1;i >= 0;i--) {
      if(!currentTypeSummaryFields.includes(fields[i].id)) {
        stepToCopySummaryFields = fields[i];
        break;
      }
    }
    append({
      id: uuid(),
      stepOrder: newStepOrder,
      stepName: stepNameTemplate,
      stepDescription: stepDescriptionTemplate,
      assigneeId: [],
      fields: allProcessStepsFields.map((item, index) =>
        ({ ...item?.field, order: index + 1, isRequired: false, isEditable: false })),
      timer: '',
      hasTimer: false,
      parallel: false,
      isConditionBranchStep: false,
      isParallelBranchesGroupStep: false,
      summaryFields: stepToCopySummaryFields ? stepToCopySummaryFields.summaryFields : [],
    });

    setTimeout(() => {
      setCurrentProcessIndex(newStepOrder - 1);
      setSelectedItemType('processStep');
      setFocusedStepFieldId('');
      gridPathRef?.current?.scroll({
        top: gridPathRef?.current?.scrollHeight,
        behavior: 'smooth',
      });
      nameFieldRef?.current?.focus();
    });
  };

  const handleAddProcessConditionStep = (processStep: ProcessStep): void => {
    if (isAutostartActive && (processStep?.stepOrder === 2 || (!processStep && fields?.length === 1))) {
      handleConditionAdditionNotPossibleDialogOpen();
      return;
    }
    setIsSubmitAvailable(true);

    const previousStepsFields = fields
      .slice(0, processStep ? processStep.stepOrder - 1 : fields.length)
      .reduce((acc, step) => {
        step.fields.forEach(field => {
          if (!acc.some(accField => accField.id === field.id)) {
            acc.push(field);
          }
        });
        return acc;
      }, [])
      .map((item, index) => ({
        ...item,
        order: index + 1,
        isRequired: false,
        isEditable: false,
      }));
    if (processStep === null) {
      // add condition at the end
      const newConditionStep = {
        id: uuid(),
        previousStepOrder: fields.length,
        stepName: stepNameTemplate,
        condition: {
          field: '',
          type: '',
          value: '',
        },
        positiveBranch: [fields.length + 1],
        negativeBranch: [fields.length + 2],
        hasPositiveBranch: true,
        hasNegativeBranch: true,
      } as ConditionStep;
      setConditions(list => [...list, newConditionStep]);
      let stepToCopySummaryFields: ProcessStep | undefined;
      for(let i = fields.length - 1;i >= 0;i--) {
        if(!currentTypeSummaryFields.includes(fields[i].id)) {
          stepToCopySummaryFields = fields[i];
          break;
        }
      }
      // add positive step
      append({
        id: uuid(),
        stepOrder: fields.length + 1,
        stepName: stepNameTemplate,
        stepDescription: stepDescriptionTemplate,
        assigneeId: [],
        fields: previousStepsFields,
        timer: '',
        hasTimer: false,
        parallel: false,
        isConditionBranchStep: true,
        isParallelBranchesGroupStep: false,
        summaryFields: stepToCopySummaryFields ? stepToCopySummaryFields.summaryFields : [],
      });
      // add negative step
      append({
        id: uuid(),
        stepOrder: fields.length + 2,
        stepName: stepNameTemplate,
        stepDescription: stepDescriptionTemplate,
        assigneeId: [],
        fields: previousStepsFields,
        timer: '',
        hasTimer: false,
        parallel: false,
        isConditionBranchStep: true,
        isParallelBranchesGroupStep: false,
        summaryFields: stepToCopySummaryFields ? stepToCopySummaryFields.summaryFields : [],
      });

      setTimeout(() => {
        setCurrentConditionId(newConditionStep.id);
        setSelectedItemType('condition');
        setFocusedStepFieldId('');
        gridPathRef?.current?.scroll({
          top: gridPathRef?.current?.scrollHeight,
          behavior: 'smooth',
        });
        nameFieldRef?.current?.focus();
      });
    } else {
      // add condition between step
      const newConditionStep = {
        id: uuid(),
        previousStepOrder: processStep.stepOrder - 1,
        stepName: stepNameTemplate,
        condition: {
          field: '',
          type: '',
          value: '',
        },
        positiveBranch: [processStep.stepOrder],
        negativeBranch: [processStep.stepOrder + 1],
        hasPositiveBranch: true,
        hasNegativeBranch: true,
      } as ConditionStep;
      const modifiedConditionsList = conditions.map(condition => {
        if (condition.previousStepOrder >= processStep.stepOrder - 1) {
          condition.previousStepOrder += 2;
          condition.positiveBranch = condition.positiveBranch.map(step => step + 2);
          condition.negativeBranch = condition.negativeBranch.map(step => step + 2);
          return condition;
        } else {
          return condition;
        }
      });
      setConditions(() => [...modifiedConditionsList, newConditionStep]);

      const modifiedParallelStepsGroupsList = parallelBranchesGroups.map(group => {
        if (group.previousStepOrder >= processStep.stepOrder - 1) {
          group.previousStepOrder += 2;
          group.steps = group.steps.map(b => b.map(v => v + 2));
          return group;
        } else {
          return group;
        }
      });
      setParallelBranchesGroups(() => modifiedParallelStepsGroupsList);
      const stepsBefore = fields.slice(0, processStep.stepOrder - 1);
      const stepsAfter = fields.slice(processStep.stepOrder - 1).map((step, index) => ({
        ...step,
        stepOrder: processStep.stepOrder + 2 + index,
      }));
      let stepToCopySummaryFields: ProcessStep | undefined;
      for(let i = stepsBefore.length - 1;i >= 0;i--) {
        if(!currentTypeSummaryFields.includes(stepsBefore[i].id)) {
          stepToCopySummaryFields = fields[i];
          break;
        }
      }
      if(!stepToCopySummaryFields) {
        for(let i = processStep.stepOrder - 1;i < fields.length;i++) {
          if(!currentTypeSummaryFields.includes(fields[i].id)) {
            stepToCopySummaryFields = fields[i];
            break;
          }
        }
      }
      // change step order of steps after added condition and
      // add two new steps with correct step order
      // TODO - check which fields are added there
      replace(
        [
          ...stepsBefore,
          // positive step
          {
            id: uuid(),
            stepOrder: processStep.stepOrder,
            stepName: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
            stepDescription: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
            assigneeId: [],
            fields: previousStepsFields,
            timer: '',
            hasTimer: false,
            parallel: false,
            isConditionBranchStep: true,
            isParallelBranchesGroupStep: false,
            summaryFields: stepToCopySummaryFields ? stepToCopySummaryFields.summaryFields : []
          },
          // negative step
          {
            id: uuid(),
            stepOrder: processStep.stepOrder + 1,
            stepName: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
            stepDescription: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
            assigneeId: [],
            fields: previousStepsFields,
            timer: '',
            hasTimer: false,
            parallel: false,
            isConditionBranchStep: true,
            isParallelBranchesGroupStep: false,
            summaryFields: stepToCopySummaryFields ? stepToCopySummaryFields.summaryFields : []
          },
          ...stepsAfter,
        ],
      );
      // modify direct manager steps and performer selection steps
      let filteredDMSteps = [processStep.stepOrder + 2];
      if (processStep?.isConditionBranchStep) {
        const nextStepCondition = modifiedConditionsList?.find(c => [
          ...(c?.hasPositiveBranch ? c.positiveBranch : []),
          ...(c?.hasNegativeBranch ? c.negativeBranch : []),
        ].includes(processStep?.stepOrder + 2));
        filteredDMSteps = [nextStepCondition?.hasPositiveBranch ? nextStepCondition?.positiveBranch[0] : -1,
          nextStepCondition?.hasNegativeBranch ? nextStepCondition?.negativeBranch[0] : -1];
      }
      if (processStep?.isParallelBranchesGroupStep) {
        const nextStepParallelStepsGroup = modifiedParallelStepsGroupsList?.find(g => g.steps.flat().includes(processStep?.stepOrder + 2));
        filteredDMSteps = nextStepParallelStepsGroup?.steps.map(b => b[0]) || [];
      }
      setDirectManagerSteps(steps => steps
        .map(step => step < processStep.stepOrder ? step : step + 2)
        .filter(step => !filteredDMSteps.includes(step)));
      setPerformerSelectionSteps(steps => steps.map(step => step < processStep.stepOrder ? step : step + 2));

      setTimeout(() => {
        setCurrentConditionId(newConditionStep.id);
        setSelectedItemType('condition');
      });
    }
  };

  const handleProcessSelect = (processStep: ProcessStep): void => {
    setCurrentProcessIndex(processStep.stepOrder - 1);
    setSelectedItemType('processStep');
    setFocusedStepFieldId('');
    setFocusedDocumentId('');
  };

  const handleConditionSelect = (conditionStep: ConditionStep): void => {
    setCurrentConditionId(conditionStep.id);
    setSelectedItemType('condition');
    setFocusedStepFieldId('');
    setFocusedDocumentId('')
  };

  useEffect(() => {
    if (!firstErrorStepEl) return;

    firstErrorStepEl?.scrollIntoView({
      behavior: 'smooth',
    });

    setFirstErrorStepEl(null);
    setFirstErrorStepIndex(null);
  }, [firstErrorStepEl]);

  return {
    handleStepDelete,
    handleAddPreviousStep,
    handleAddProcessStep,
    handleAddProcessConditionStep,
    handleProcessSelect,
    handleConditionSelect,
  };
};
