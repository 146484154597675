import React, { useState, useCallback, useEffect, Fragment, ChangeEvent, useMemo, memo, ReactElement } from 'react';
import { FormControl, TextField, Grid, FormControlLabel, Switch, Checkbox, Typography } from '@material-ui/core';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';
import { useTranslation } from 'react-i18next';

import {
  setBpmGlossaryId as setGlossaryId,
  useGlossaryState,
} from 'store/requests';

import { Styled } from './SelectGlossary.styles';

type GlossaryOptionItem = {
  id: string;
  value: string;
  label?: string;
  parentId: string;
  additionalFields?: {
    [key: string]: any;
  };
};

type GlossaryOptions = {
  parent: {
    additionalFields?: {
      icon?: string
    };
    value: string;
    label?: string;
  };
  children: GlossaryOptionItem[];
  parentList: GlossaryOptionItem[];
}

type Props = {
  params: {
    glossary: string;
    glossaryIndexName: string;
    saveAsId: boolean;
    accessType: string;
    attachAccessType: boolean;
    useActivityToggle: boolean;
    useMultiSelect: boolean;
    onlyParent: boolean;
    parameterAttribute: string;
    mappingAttr: string;
    placeholder?: string;
  };
  rules: { [key: string]: string };
  name: string;
  hint: string;
};

type MappingItem = {
  inProperty: string;
  outAttrSysName: string;
};

export const SelectGlossary = memo(({
  params: {
    glossary,
    glossaryIndexName,
    saveAsId = false,
    accessType,
    attachAccessType,
    useActivityToggle,
    useMultiSelect,
    onlyParent,
    parameterAttribute = '',
    mappingAttr,
    placeholder = '',
  },
  name,
  hint,
  rules,
}: Props): ReactElement => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { mapList: glossaryMap } = useGlossaryState();

  const {
    control,
    register,
    unregister,
    formState: {
      errors,
    },
    setValue: setFormValue,
    watch,
    trigger,
    clearErrors,
    getValues,
  } = useFormContext();

  const currentValue = watch(name);

  const [value, setValue] = useState(currentValue || '');
  const [inputValue, setInputValue] = useState(currentValue || '');
  const [activeSelect, setActiveSelect] = useState(!!currentValue || false);
  const [selectedOptions, setSelectedOptions] = useState<string[] | null>(null);
  const [options, setOptions] = useState<GlossaryOptionItem[]>([]);

  const glossaryOptions: GlossaryOptions = useMemo((): GlossaryOptions => {
    const initialState = {
      parent: null,
      children: [],
      parentList: [],
    };

    if ((glossary || glossaryIndexName) && glossaryMap[glossary || glossaryIndexName]?.length) {
      return glossaryMap[glossary || glossaryIndexName].reduce((acc, el) => {
        el.label = t(`glossary.${el.id}`, { defaultValue: el.value });
        if (glossaryIndexName) {
          el.label = el?.additionalFields[i18n.language] || el.value;
        }

        if (!el.parentId) {
          initialState.parentList.push(!accessType ? el : el.value);
        }

        if (!accessType || el.parentId === accessType) {
          acc.children.push(el);
        }

        if (el.id === accessType) {
          acc.parent = el;
        }

        return acc;
      }, initialState);
    }

    return initialState;
  }, [glossaryMap, glossary, accessType]);

  const icon = useMemo(() =>
      glossaryOptions?.parent?.additionalFields?.icon || '',
    [glossaryOptions]);

  const handleChangeActivity = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: {
          checked,
        },
      } = event;

      setActiveSelect(checked);
      if (attachAccessType) {
        const accessTypesList = getValues('accessType');

        if (!accessTypesList) {
          if (checked && glossaryOptions.parent?.value) {
            setFormValue('accessType', glossaryOptions.parent?.value);
          }
        } else {
          const list = Array.isArray(accessTypesList) ? accessTypesList : accessTypesList.split(',');

          if (checked) {
            setFormValue(
              'accessType',
              [...list, glossaryOptions.parent?.value].join(','),
            );
          } else {
            setFormValue(
              'accessType',
              list
                .filter((el: string) => el !== glossaryOptions.parent?.value)
                .join(','),
            );
          }
        }
      }

      if (!checked) {
        clearErrors(name);
        setValue('');
        setFormValue(name, '');
      }
    },
    [attachAccessType, clearErrors, name, setFormValue, getValues, glossaryOptions],
  );

  const getParameterAttributeValue = useCallback((): string => {
    const [, attributeName]: [string, string | undefined] =
      parameterAttribute.split('::') as [string, string | undefined];

    if (isUndefined(attributeName)) {
      return '';
    }

    const attributeValue = getValues(attributeName);
    if (isUndefined(attributeValue)) {
      return '';
    }

    return attributeValue;
  }, [parameterAttribute, getValues]);

  const parameterAttributeValue: string = getParameterAttributeValue();
  useEffect(() => {
    if (parameterAttributeValue) {
      const options: GlossaryOptionItem[] = glossaryOptions.children
        .filter(({ parentId }) => parentId === parameterAttributeValue);

      setOptions(options);
      setValue('');
      setFormValue(name, '');
    }
  }, [name, setFormValue, parameterAttributeValue, glossaryOptions.children]);

  useEffect(() => {
    if (glossary || glossaryIndexName) {
      dispatch(setGlossaryId(glossary || glossaryIndexName));
    }
  }, [dispatch, glossary]);

  useEffect(() => {
    if (currentValue && value !== currentValue) {
      setActiveSelect(true);
      const currentOptionValue = options.find(({ label }) => label === value)?.value;
      if (currentOptionValue && currentOptionValue !== currentValue) {
        setValue(currentValue);
      }
    }
  }, [currentValue]);

  useEffect(() => {
    const currentOption = options.find(({ value }) => value === currentValue);
    if (currentOption?.label && currentValue !== currentOption?.label) {
      setValue(currentOption?.label);
    }
  }, [currentValue, options]);

  const handleChange = (_: unknown, val: any) => {
    if (val && typeof val === 'object' && Object.prototype.hasOwnProperty.call(val, 'value')) {
      if (saveAsId) {
        setValue(val.id);
        setFormValue(name, val.id);
      } else {
        setValue(val.label);
        setFormValue(name, val.value);
      }

      // MAPPING VARIABLES
      if (mappingAttr) {
        const mappingItems = JSON.parse(mappingAttr);
        if (!mappingItems?.length) {
          return;
        }

        mappingItems.forEach((mappingItem: MappingItem) => {
          const doubleCurlyBracesRegex = /{{(.+?)}}/g;
          const property = doubleCurlyBracesRegex.test(mappingItem.inProperty) ?
                           eval(mappingItem.inProperty.replace(doubleCurlyBracesRegex, '$1')) :
                           val[mappingItem.inProperty];

          const attribute = mappingItem.outAttrSysName.split('::')[1];
          if (attribute) {
            setFormValue(attribute, property);
          }
        });
      }
    } else {
      setValue(val);
      setFormValue(name, val);
    }

    if (errors[name]) {
      trigger(name);
    }
  };

  const handleInputChange = (_: unknown, val: string) => {
    if (saveAsId) {
      const selectedOption = glossaryOptions?.children?.find(option => option.id === val || option.value === val || Object.values(option?.additionalFields || {}).includes(val));
      setInputValue(selectedOption?.additionalFields[i18n.language] || selectedOption?.value || '');
    } else {
      setInputValue(val);
    }
    setFormValue(name, value);

    if (errors[name]) {
      trigger(name);
    }
  };

  const isValueFromOptions = (value: string) =>
    !!sortedOptions.find((option) => {
      if (saveAsId) {
        return option?.id === value;
      }
      const optionValue = typeof option === 'string' ? option : option?.value;
      const optionLabel = typeof option === 'string' ? option : option?.label;
      return optionValue.toLowerCase() === value.toLowerCase() || optionLabel.toLowerCase() === value.toLowerCase();
    }) || t('form_components.validation_errors.listOption') as string;

  useEffect(() => {
    // validation rules are cached in Controller so need
    // to unregister field when activeSelect is changed to update rules
    // but only for SelectGlossary with active toggle
    if (useActivityToggle && !activeSelect) {
      unregister(name);
    }
  }, [name, useActivityToggle, activeSelect, unregister]);

  const renderInput = (inputParams: AutocompleteRenderInputParams) => (
    <Controller
      name={name}
      control={control}
      rules={
        activeSelect || rules?.required ? {
          required: t('form_components.validation_errors.listOption') as string,
          validate: isValueFromOptions,
        } : {}
      }
      render={() => (
        <TextField
          {...inputParams}
          label={hint}
          placeholder={placeholder}
          inputProps={{ ...inputParams.inputProps, 'data-selenium': name }}
          error={!!errors[name]}
          helperText={!!errors[name] && errors[name].message}
        />
      )}
    />
  );

  const changeOption = (option: GlossaryOptionItem) => (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = e;

    if (selectedOptions) {
      setSelectedOptions(
        e.target.checked
        ? [...selectedOptions, option.id]
        : selectedOptions.filter((el) => el !== option.id),
      );
    } else {
      setSelectedOptions(e.target.checked ? [option.id] : []);
    }

    const multiList = getValues(name) || '';

    if (useMultiSelect) {
      const filteredList =
        (isArray(multiList)
         ? multiList
         : multiList.split('\n'))
          .filter((e: string) => e !== '');
      const resultList = checked
                         ? [...filteredList, option.value]
                         : filteredList.filter((el: string) => el !== option.value);
      setFormValue(name, resultList.join('\n'));
    } else {
      setFormValue(name, checked ? `${option.value}\n` : '');
    }
  };

  const changeAllOptions = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedOptions(glossaryOptions.children.map((el) => el.id));
      setFormValue(
        name,
        glossaryOptions.children.map((el) => el.value).join('\n'),
      );
    } else {
      setSelectedOptions([]);
      setFormValue(name, '');
    }
  };

  useEffect(() => {
    setOptions(
      onlyParent ?
      glossaryOptions.parentList :
      glossaryOptions.children,
    );

    if (
      useMultiSelect &&
      getValues(name) &&
      !selectedOptions &&
      glossaryOptions.children.length
    ) {
      const values = Array.isArray(getValues(name)) ? getValues(name) : getValues(name).split('\n');

      setSelectedOptions(
        glossaryOptions.children.reduce((acc: string[], option: GlossaryOptionItem) => {
          if (values.includes(option.value)) {
            acc.push(option.id);
          }

          return acc;
        }, []),
      );
    }
  }, [
    glossaryOptions,
    useMultiSelect,
    onlyParent,
    selectedOptions,
    name,
    getValues,
  ]);

  const sortedOptions = useMemo(() =>
      options.sort((a, b) => a?.label.localeCompare(b?.label)),
    [options]);

  const filteredOptions = useMemo(() => {
    const isExactValue = sortedOptions.some((option) => {
      const value = typeof option === 'string' ? option : option?.value;
      const label = typeof option === 'string' ? option : option?.label;
      const localizedLabel = typeof option === 'string'
                             ? option
                             : (option?.additionalFields[i18n.language] || option?.value);
      return (
        !!value &&
        value?.toLowerCase() === inputValue?.toString().toLowerCase() ||
        label?.toLowerCase() === inputValue?.toString().toLowerCase() ||
        localizedLabel?.toLowerCase() === inputValue?.toString().toLowerCase()
      );
    });

    return isExactValue
           ? sortedOptions
           : sortedOptions.filter((option) => {
        const label = typeof option === 'string' ? option : option?.label;
        const localizedLabel = typeof option === 'string'
                               ? option
                               : (option?.additionalFields[i18n.language] || option?.value);
        return (
            !!label &&
            label
              ?.toLowerCase()
              ?.includes(inputValue?.toString().toLowerCase())) ||
          localizedLabel
            ?.toLowerCase()
            ?.includes(inputValue?.toString().toLowerCase());
      });
  }, [inputValue, value, sortedOptions]);

  useEffect(() => {
    if (saveAsId) {
      if (value && (!inputValue || inputValue === value)) {
        const optionObject = sortedOptions.find(v => v.id === value);
        setInputValue(() => optionObject?.additionalFields[i18n.language] || optionObject?.label || optionObject?.value || value);
      }
    }
  }, [saveAsId, inputValue, value, sortedOptions]);

  const getOptionLabel = (option: any) => {
    if (
      option &&
      typeof option === 'object' &&
      Object.prototype.hasOwnProperty.call(option, 'value')) {
      if (saveAsId) {
        return (option && (option?.additionalFields[i18n.language] || option?.label || option?.value)) || '';
      }
      return (option && (option?.label || option?.value)) || '';
    }

    if (saveAsId && typeof option === 'string') {
      const optionObject = sortedOptions.find(v => v.id === option);
      return (optionObject && (optionObject?.additionalFields[i18n.language] || optionObject?.label || optionObject?.value || option)) || '';
    }

    return option || '';
  };

  if (useMultiSelect) {
    return (
      <Fragment>
        <input type="hidden" data-selenium={name} {...register(name)} />

        <div>
          <FormControlLabel
            checked={
              selectedOptions?.length === glossaryOptions.children.length
            }
            control={<Checkbox onChange={changeAllOptions}/>}
            label={t('form_components.selectGlossary.selectAll')}
          />
        </div>

        {glossaryOptions.children.map((option, i) => (
          <div key={option.id}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    Array.isArray(selectedOptions)
                    ? selectedOptions.includes(option.id)
                    : false
                  }
                  onChange={changeOption(option)}
                />
              }
              label={t(`glossary.${option.id}`, { defaultValue: option['value'] })}
            />
          </div>
        ))}
      </Fragment>
    );
  }

  return (
    <Styled.SelectGlossary>
      <Grid container spacing={useActivityToggle ? 2 : 0}>
        {useActivityToggle && (
          <Grid item xs={5} container alignItems="center">
            <FormControlLabel
              label={
                <Typography className="label">
                  {!!icon && <img className="icon" alt="" src={icon}/>}
                  {glossaryOptions.parent?.label || glossaryOptions.parent?.value}
                </Typography>
              }
              control={
                <Switch
                  className="switch"
                  onChange={handleChangeActivity}
                  checked={activeSelect}
                  color="default"
                />
              }
            />
          </Grid>
        )}

        <Grid item xs={useActivityToggle ? 7 : 12}>
          <FormControl
            className="autoGlossary"
            fullWidth>
            <Autocomplete
              fullWidth
              forcePopupIcon
              value={value}
              inputValue={inputValue}
              disabled={useActivityToggle && !activeSelect}
              options={filteredOptions}
              renderInput={renderInput}
              onChange={handleChange}
              onInputChange={handleInputChange}
              getOptionLabel={getOptionLabel}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Styled.SelectGlossary>
  );
});
