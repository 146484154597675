import { useEffect, useMemo, useState, useCallback, useRef } from 'react';

import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  getApprovalsAction,
  setCurrentPage,
  setCurrentPageSize,
  setMassApproveActionStatus,
  toggleApprovalPanel,
  useApprovalsListState,
} from 'store/approvals';
import { setSubmitSnackbarParams } from 'store/requests';
import { setFilters, useFiltersState } from 'store/search';
import { useRequestParams, useUserProfile } from 'hooks';
import { getProfileCompanyDataFromLocalStorage } from 'utils/user';
import { SOCKET_ERROR_MESSAGE, SOCKET_EVENT_MESSAGE } from 'utils/websocket';
import { completeAllTasks, completeResultWS } from 'api/requests';

import { MASS_ACTIONS_SUCCESS_MESSAGES } from '../constants';

interface UseApprovalsControl {
  requests?: any,
  type: 'approvals' | 'drafts',
}

type ActionType = 'Approve' | 'Rework' | 'Reject' | 'Delete' | '';

const useApprovalsControl = ({ requests, type }: UseApprovalsControl) => {
  const dispatch = useDispatch();
  const { id: profileId } = useUserProfile();
  const { t, i18n } = useTranslation();
  const filterState = useFiltersState();
  
  const [massApprovals, setMassApprovals] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState({});
  const [massLoading, setMassLoading] = useState<ActionType>('');
  const [modalInfo, setModalInfo] = useState({ open: false, action: '' });

  const filtersParams = useFiltersState();
  const { pageSize: approvalsPageSize } = useApprovalsListState();

  const { getRequestParameters: getRequestParametersForApprovals, urlParams: urlParamsApprovals } = 
    useRequestParams({
      completed: false,
      currentPage: 1,
      pageSize: approvalsPageSize,
      type: 'approvals',
    });

  const actionsWS = useRef(null);

  useEffect(() => {
    if (requests) {
      setMassApprovals([]);
      setSelectedTickets({});
    }
  }, [requests]);

  useEffect(() => {
    setMassApprovals([]);
  }, [filterState])

  const handlePageChange = useCallback((_, newPage) => {
      dispatch(setCurrentPage({page: newPage}))

      const pagination = filterState.pagination || {}
      dispatch(setFilters({
        ...filterState,
        pagination: {
          ...pagination,
          page: newPage - 1,
          sort: 'taskStartDate,DESC'
        }
      }))
    },
    [dispatch]
  );

  const handlePageSizeChange = useCallback((event) => {
      dispatch(setCurrentPageSize(event.target.value));

      const pagination = filterState.pagination || {}

      dispatch(setFilters({
        ...filterState,
        pagination: {
          ...pagination,
          size: Number(event.target.value),
          sort: 'taskStartDate,DESC'
        }
      }))
    }, [dispatch]);

  const generateDataForApprove = (item, isRequest = false) => ({
    taskId: isRequest ? item.businessTask.taskId : item.id,
    processInstanceId: isRequest ? item.businessTask.processInstanceId : item.processInstanceId,
    processDefinitionName: isRequest ? item.businessTask.processDefinitionName : item.processName,
    processStep: isRequest ? item.currentAction.name : item.stepName,
  });

  const selectTicket = useCallback((ticketData): void => {
    if (selectedTickets[ticketData.id]) {
      const copy = { ...selectedTickets };
      delete copy[ticketData.id];
      setSelectedTickets(copy);
    } else {
      setSelectedTickets({
        ...selectedTickets,
        [ticketData.id]: generateDataForApprove(ticketData)
      });
    }
  }, [selectedTickets]);

  const allAvailableTickets = useMemo((): any[] => {
    if (!requests) {
      return [];
    }
    return requests.reduce((accumulator, currentValue) => {
      const canBeMassApproved = type === 'drafts' || !!(currentValue.massActivationValidation && Object.values(currentValue.massActivationValidation).every(v => !v));
      if (canBeMassApproved) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, []);
  }, [requests, type]);

  const selectAllTickets = useCallback((): void => {
    if (requests.length) {
      const allTickets = allAvailableTickets.reduce((accumulator, currentValue) => {
        accumulator[currentValue.businessTask.taskId] = generateDataForApprove(currentValue, true);
        return accumulator;
      }, {});

      setSelectedTickets(allTickets);
    }
  }, [selectedTickets, requests, allAvailableTickets]);

  const clearSelectedTickets = () => {
    setSelectedTickets({});
  }

  const setMassApprove = useCallback(ticketData => {
      if (!massApprovals.find(el => el.id === ticketData.id)) {
        setMassApprovals([...massApprovals, ticketData]);
      }
    },
    [massApprovals]
  );

  const selectTickets = useMemo(() => ({
    all: selectAllTickets,
    one: selectTicket
  }), [selectAllTickets, selectTicket]);

  const isAllSelected = useMemo((): boolean => 
    allAvailableTickets.length > 0 && allAvailableTickets.length === Object.keys(selectedTickets).length, 
  [allAvailableTickets, selectedTickets]);

  const showControls = useMemo((): boolean =>
    !!massApprovals.length,
  [massApprovals]);

  const completeApproveTasks = useCallback((action, approvedTasks, totalSelectedTasksCount) => {
      setMassLoading('');
      setSelectedTickets({});
      setMassApprovals(massApprovals.filter(el => !approvedTasks.includes(el.id)));
      dispatch(toggleApprovalPanel());
      if (approvedTasks.length > 0) {
        NotificationManager.success(t(
          MASS_ACTIONS_SUCCESS_MESSAGES[action.toLowerCase()],
          { approvalsCount: approvedTasks.length, totalCount: totalSelectedTasksCount }));
        if (type === 'approvals') {
          setTimeout(() => {
            const approvalsParametersTemp = getRequestParametersForApprovals(
              urlParamsApprovals,
            );

            const approvalsParameters = {
              ...approvalsParametersTemp, ...{
                ...filtersParams,
                pagination: {
                  ...approvalsParametersTemp.pagination,
                  ...(filtersParams?.pagination || {}),
                  size: approvalsPageSize,
                  ...(filtersParams?.pagination
                      ? { page: filtersParams?.pagination?.page }
                      : {}),

                },
              },
            };

            dispatch(getApprovalsAction({ params: approvalsParameters }));
          });
        }
      }
    },
    [requests, massApprovals, dispatch, setSubmitSnackbarParams],
  );

  const onClick = useCallback(({ action, comment }) => {
    const profileCompanyData = getProfileCompanyDataFromLocalStorage();

    const data = Object.values(selectedTickets).map((ticket: { approveStage: string }) => ({
      ...ticket,
      approveStage: action,
      companyId: profileCompanyData?.id
    }));

    let processedTaskCount = 0;
    let completeTaskCount = 0;
    const approvedOrRejectedTasks = [];
    const dataCount = Object.keys(data).length;

    if (comment) {
      data.forEach(e => {
        e['comment'] = comment;
        e['action'] = 'Mass ' + action;
      });
    }

    setMassLoading(action);

    const showFailedStatus = () => {
      const notApprovedRequestsCount = dataCount - completeTaskCount;
      let failedStatusKeyWord = 'massActions.failedStatus';
      if (i18n.language === 'ru') {
        if (notApprovedRequestsCount > 1) {
          failedStatusKeyWord = 'massActions.failedStatusMultipleRus';
        }
      }
      dispatch(setMassApproveActionStatus({
        loading: true,
        percentage: (completeTaskCount / dataCount) * 100,
        // write down here several variants for fixing translation
        statusText: t(failedStatusKeyWord, {notApprovedRequestsCount}),
        showErrorIcon: true,
        showSuccessIcon: false
      }));
      setTimeout(() => {
        dispatch(setMassApproveActionStatus({
          loading: false, 
          percentage: 0, 
          statusText: '',
          showErrorIcon: false,
          showSuccessIcon: false
        }));
      }, 5000);
    }

    const socket = completeResultWS();
    let statusKey = 'successStatus';
    let progressStatusText = 'approvingStatus';
    if (action.toLowerCase() === 'reject') {
      statusKey = 'rejectedStatus';
      progressStatusText = 'rejectingStatus';
    };
    const receiveHandler = (message) => {
      processedTaskCount++;
      if (message?.completed) {
        completeTaskCount++;
        approvedOrRejectedTasks.push(message?.taskId);
        dispatch(setMassApproveActionStatus({
          loading: true,
          percentage: (completeTaskCount / dataCount) * 100,
          statusText: t(`massActions.${progressStatusText}`, {completeTaskCount, dataCount}),
          showErrorIcon: false,
          showSuccessIcon: false
        }));
      } else {
        NotificationManager.error(t(message.message || 'errors.somethingIsWrong'));
      }

      if (processedTaskCount === dataCount) {
        completeApproveTasks(action, approvedOrRejectedTasks, dataCount);
        socket.eventEmitter.off(SOCKET_EVENT_MESSAGE, receiveHandler);
        socket.close();

        if (completeTaskCount !== dataCount) {
          showFailedStatus();
        } else {
          dispatch(setMassApproveActionStatus({
            loading: true,
            percentage: (completeTaskCount / dataCount) * 100,
            statusText: t(`massActions.${statusKey}`, {completeTaskCount, dataCount}),
            showErrorIcon: false,
            showSuccessIcon: true
          }));
          setTimeout(() => {
            dispatch(setMassApproveActionStatus({
              loading: false, 
              percentage: 0, 
              statusText: '',
              showErrorIcon: false,
              showSuccessIcon: false
            }));
          }, 5000)
        }
      }
    };

    const errorHandler = (_message) => {
      setMassLoading('');
      NotificationManager.error(t('errors.somethingIsWrong'));
      socket.close();
      showFailedStatus()
    }

    socket.eventEmitter.on(SOCKET_EVENT_MESSAGE, receiveHandler);
    socket.eventEmitter.on(SOCKET_ERROR_MESSAGE, errorHandler);

    const token = JSON.parse(localStorage.getItem('dms-auth'))?.id_token;
    socket.open(token);
    socket.eventEmitter
    actionsWS.current = socket;
    dispatch(setMassApproveActionStatus({
      loading: true,
      percentage: (completeTaskCount / dataCount) * 100,
      statusText: t(`massActions.${progressStatusText}`, {completeTaskCount, dataCount}),
      showErrorIcon: false,
      showSuccessIcon: false
    }));

    completeAllTasks(data).catch(e => {
      NotificationManager.error(t('errors.somethingIsWrong'));
      setMassLoading('');
    });
  }, [selectedTickets, profileId, completeApproveTasks, massLoading]);

  const closeSuccessModal = (): void => {
    setModalInfo({
      ...modalInfo,
      open: false
    });
  }

  return {
    allAvailableTickets,
    selectedTickets,
    selectTickets,
    selectAllTickets,
    clearSelectedTickets,
    setMassApprove,
    isAllSelected,
    showControls,
    massLoading,
    setMassLoading,
    onClick,
    modalInfo,
    closeSuccessModal,
    handlePageChange,
    handlePageSizeChange,
  };
}

export default useApprovalsControl;
