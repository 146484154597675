import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Button, Checkbox, ClickAwayListener, LinearProgress, TextField, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useDebounce } from 'hooks';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import cn from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';

import { initializeSelectedStates } from './utils';
import { FetchingState, ProjectChildSelectProps, TreeType } from 'pages/Task/TaskForm/types';

import {
  getDirectoriesV2Value,
  getDirectoryByIdV2,
  getDirectoryInfo,
  getGlossaryAsTree,
  getValuesByNameFragment,
} from 'api/requests';

import { useTreeItemStyles, useTreeStyles, useInputStyles } from './TreeGlossary.styles';
import Styled from './TreeGlossary.styles';

import dropdownIndicatorIcon from 'assets/images/icons/dropdown-indicator.svg';
import ChevronDown from 'assets/images/icons/chevron_down_grey.svg';
import ChevronRight from 'assets/images/icons/chevron_right_grey.svg';
import CheckboxUncheckedIcon from 'assets/images/icons/checkbox-unchecked.svg';
import CheckboxCheckedIcon from 'assets/images/icons/checkbox-checked.svg';
import CheckboxIndeterminateIcon from 'assets/images/icons/checkbox-indeterminate.svg';

type DirectoryInfo = {
  id: string;
  [key: string]: any;
}

export type TreeStructureNode = {
  data: DirectoryInfo;
  id: string;
  children: TreeStructureNode[];
  selectedChildCount?: number;
  totalChildCount?: number;
}

type Props = {
  name?: string;
  hint?: string;
  params?: { [key: string]: unknown };
  control: any;
  value: any;
  valueId?: string | string[];
  disabledItemsIds?: string[];
  errors: any[] | {};
  showErrorText?: boolean;
  onSelect: (value: any) => void;
  withoutFormController?: boolean;
  isBuilderVariant?: boolean;
  isConditionModalVariant?: boolean;
  forceEnglish?: boolean;
  placeholder?: string;
  rules?: { [key: string]: any }
  isDisabled?: boolean
}

const ENDPOINTS_TO_NAMES_MAP = {
  'projects': 'project',
};

const InfoBox = ({ children, ...rest }) => <Box width="100%" height={40} pt={4} px={2}
                                                pb={1} {...rest}>{children}</Box>;

const ProjectChildSelect: FC<ProjectChildSelectProps> = (props) => {
  const classes = useTreeItemStyles();
  const treeClasses = useTreeStyles();
  const { t, i18n } = useTranslation();
  const [childNodes, setChildNodes] = useState(null);
  const [expanded, setExpanded] = React.useState([]);
  const [fetchingState, setFetchingState] = useState(FetchingState.IDLE);
  const childType = props.isBuilderVariant ? TreeType.DIRECTORY_ITEM : props.type === TreeType.DIRECTORY
                                                                       ? TreeType.DIRECTORY_ITEM
                                                                       : TreeType.DIRECTORY;
  const label = props?.localization[i18n.language] || props.directoryName || props.value;

  const handleChange = (event, nodes) => {
    const expandingNodes = nodes.filter(x => !expanded.includes(x));
    setExpanded(nodes);
    if (expandingNodes[0]) {
      const child = expandingNodes[0];
      getChildNodes(child);
    }
  };

  const getChildNodes = (id: string) => {
    setChildNodes(
      props.children
        .filter(node => node.data.status === 'PUBLISHED')
        .map(
          node => <ProjectChildSelect
            type={childType}
            key={node.id}
            item={node.data}
            children={node.children}
            disabledItemsIds={props.disabledItemsIds}
            {...node.data}
            id={node.id}
            valueParentId={id}
            isBuilderVariant={props.isBuilderVariant}
            forceEnglish={props.forceEnglish}
            selectedIds={props.selectedIds}
            handleItemSelect={props.handleItemSelect}
            treeStructureNodes={props.treeStructureNodes}
          />,
        ),
    );
  };

  useEffect(() => {
    if (expanded) {
      // const child = expandingNodes[0];
      getChildNodes(null);
    }
  }, [props.selectedIds, props.treeStructureNodes]);

  const renderNodes = () => {
    switch (fetchingState) {
      case FetchingState.LOADING: {
        return props.valuesCount ? [<InfoBox><LinearProgress color="secondary"/></InfoBox>] : [];
      }
      case FetchingState.ERROR: {
        return props.valuesCount ? [
          <InfoBox display="flex" justifyContent="space-between" alignItems="center" pt={2} height={46}>
            <span>{t('form_components.tree_glossary.fetchingError')}</span>
            <Button onClick={() => getChildNodes(props?.id)}>{t('form_components.tree_glossary.tryAgain')}</Button>
          </InfoBox>,
        ] : [];
      }
      case FetchingState.SUCCESS:
      case FetchingState.IDLE:
      default: {
        return childNodes || [<div/>];
      }
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const newState = event.target.checked;
    props.handleItemSelect(props.id, newState);
  };

  const isChecked = useMemo(() =>
      props.selectedIds?.includes(props.item.id) ||
      (props.treeStructureNodes[props.id]?.totalChildCount > 0 && props.treeStructureNodes[props.id]?.totalChildCount === props.treeStructureNodes[props.id]?.selectedChildCount)
    , [props]);

  const handleItemClick = (event) => {
    event.stopPropagation();
    if (props.children?.filter(node => node.data.status === 'PUBLISHED')?.length === 0) {
      const newState = !isChecked;
      props.handleItemSelect(props.id, newState);
    }
  };

  return (
    <TreeView
      className={treeClasses.root}
      defaultCollapseIcon={<img src={ChevronDown}/>}
      defaultExpandIcon={<img src={ChevronRight}/>}
      expanded={expanded}
      onNodeToggle={handleChange}
    >
      <TreeItem
        nodeId={props.id}
        label={<div className={classes.labelRoot}>
          <Checkbox
            color={'default'}
            icon={<img src={CheckboxUncheckedIcon}/>}
            checkedIcon={<img src={CheckboxCheckedIcon}/>}
            indeterminateIcon={<img src={CheckboxIndeterminateIcon}/>}
            checked={isChecked}
            indeterminate={props.treeStructureNodes[props.id]?.selectedChildCount > 0 && props.treeStructureNodes[props.id]?.totalChildCount !== props.treeStructureNodes[props.id]?.selectedChildCount}
            onChange={handleCheckboxChange}
            onClick={e =>
              e.stopPropagation()
            }
          />
          <Typography
            variant="body2"
            className={cn(classes.labelText, {
              [classes.labelTextDisabled]: (props.isBuilderVariant && props?.item?.valuesCount === 0) || props?.disabledItemsIds?.includes(props?.item?.id),
            })}>
            {label}
          </Typography>
        </div>}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
          iconContainer: props.valuesCount ? classes.iconContainer : classes.hideIconContainer,
        }}
        onClick={handleItemClick}
      >
        {renderNodes()}
      </TreeItem>
    </TreeView>
  );
};

export const TreeGlossaryMultiSelect = ({
    name,
    hint,
    value,
    valueId = '',
    disabledItemsIds = [],
    params,
    control,
    errors,
    showErrorText = true,
    onSelect,
    withoutFormController = false,
    isBuilderVariant = false,
    isConditionModalVariant = false,
    isDisabled = false,
    forceEnglish = false,
    placeholder = '',
    rules,
  }: Props) => {
    const { i18n, t } = useTranslation();
    const selectContentRef = useRef();

    const treeClasses = useTreeStyles();
    const inputClasses = useInputStyles();
    const [selectedIds, setSelectedIds] = useState([]);
    const [treeStructure, setTreeStructure] = useState([]);
    const [treeStructureNodes, setTreeStructureNodes] = useState({});
    const [selectedProject, setSelectedProject] = useState(null);
    const [fetchingState, setFetchingState] = useState(FetchingState.LOADING);
    const [childNodes, setChildNodes] = useState(null);
    const [expanded, setExpanded] = React.useState([]);
    const [open, setOpen] = useState(false);
    const [isClickAwayListenerActive, setIsClickAwayListenerActive] = useState(false);
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
    const [popperWidth, setPopperWidth] = useState(300);
    const [inputValue, setInputValue] = useState('');
    const [projectDirectoryId, setProjectDirectoryId] = useState<string>();
    const debouncedInputValue = useDebounce(inputValue, 500);
    const [valueText, setValueText] = useState('');
    const [parentDirectoryId, setParentDirectoryId] = useState('');
    const [searchResultNodes, setSearchResultNodes] = useState([]);

    const getValueText = useCallback(async () => {
      if (valueId?.length) {
        try {
          let valueName = '';
          if (treeStructureNodes) {
            const node = treeStructureNodes[valueId[0]];
            if (node) {
              valueName = node?.data.localization[i18n.language] || node?.data.directoryName || node?.data.value;
            }
          }
          if (!valueName) {
            const v = await getDirectoryInfo(valueId[0], forceEnglish);
            valueName = v.localization[i18n.language] || v.directoryName || v.value;
          }
          const valuesCount = valueId?.length > 1 ? ` (+${valueId?.length - 1})` : '';
          setValueText(() => `${valueName}${valuesCount}`);
          return `${valueName}${valuesCount}`;
        } catch (error) {
          return '';
        }
      } else {
        setValueText('');
      }

      return '';
    }, [isConditionModalVariant, value, valueId, treeStructureNodes]);

    useEffect(() => {
      getValueText();
    }, [valueId, value, getValueText]);

    useEffect(() => {
      if (!open && !!valueId && selectedIds.length === 0) {
        setSelectedIds(valueId as string[]);
      }
    }, [valueId, open]);

    const handleSelect = (value) => {
      return;
      if (isBuilderVariant) {
        onSelect(value);
        setSelectedProject(value);
      } else {
        setOpen(false);
        setAnchorElement(null);
        setSelectedProject(value);
        const valueForForm = value ? {
          name: value?.value || value?.directoryName,
          fields: value?.additionalFields || {},
          id: params?.isAcceptedValueTypeId ? value?.id : undefined,
        } : null;
        onSelect(valueForForm);
      }
    };

    useEffect(() => {
      if (!open) {
        setInputValue('');
      }
      setTimeout(() => {
        setIsClickAwayListenerActive(open);
      }, 100);
    }, [open]);

    const handleItemSelect = (itemId, newState) => {
      const changedNodes = [];
      const parseNode = (node) => {
        if (node.children.length > 0) {
          node.children.forEach(childNode => {
            parseNode(childNode);
          });
        } else {
          changedNodes.push(node.data.id);
        }
      };
      treeStructureNodes[itemId]?.children.forEach(node => {
        parseNode(node);
      });
      const treeNode = Object.values(treeStructureNodes).find(node => (node as TreeStructureNode).id === itemId);
      if (changedNodes.length === 0 && treeNode) {
        changedNodes.push((treeNode as TreeStructureNode)?.data?.id);
      }
      if (newState) {
        setSelectedIds(ids => [...ids, ...changedNodes]);
      } else {
        setSelectedIds(ids => ids.filter(id => !changedNodes.includes(id)));
      }
    };

    useEffect(() => {
      const nodesList = initializeSelectedStates(treeStructure, selectedIds, true);
      setTreeStructureNodes(nodesList);
    }, [selectedIds, treeStructure]);

    useEffect(() => {
      setChildNodes((debouncedInputValue ? searchResultNodes : treeStructure)
        ?.filter(node => node.data.status === 'PUBLISHED')
        ?.map(
          directory => {
            return <ProjectChildSelect
              key={directory.id}
              onSelect={handleSelect}
              disabledItemsIds={disabledItemsIds}
              item={directory.data}
              children={directory.children}
              {...directory.data}
              id={directory.id}
              type={TreeType.DIRECTORY_ITEM}
              label={directory.data.value}
              isBuilderVariant={isBuilderVariant}
              forceEnglish={forceEnglish}
              selectedIds={selectedIds}
              handleItemSelect={handleItemSelect}
              treeStructureNodes={treeStructureNodes}
            />;
          },
        ));
    }, [treeStructureNodes, treeStructure, selectedIds, debouncedInputValue, searchResultNodes]);

    const init = async () => {
      const directoriesPathList = params?.directoryPath ? (params.directoryPath as string).split('/') : [null];
      const directoryId = directoriesPathList[directoriesPathList.length - 1];
      const directoryCode = params.directoryCode;
      const directoryGettingVariant = directoryCode ? 'code' : 'id';
      getGlossaryAsTree(null, true).then(directories => {
        setFetchingState(FetchingState.SUCCESS);
        const nodesList = initializeSelectedStates(directories.filter(node => node.data.status === 'PUBLISHED'), selectedIds, true);
        setTreeStructureNodes(nodesList);
        const directoryNode = Object.values(nodesList).find(node =>
          directoryGettingVariant === 'id'
          ? (node as TreeStructureNode)?.data?.id === directoryId
          : (node as TreeStructureNode)?.data?.directoryName === directoryCode);
        setTreeStructure((directoryNode as TreeStructureNode)?.children.filter(node => node.data.status === 'PUBLISHED'));
        setChildNodes((directoryNode as TreeStructureNode)?.children
          .filter(node => node.data.status === 'PUBLISHED')
          .map(
            directory => {
              return <ProjectChildSelect
                key={directory.id}
                onSelect={handleSelect}
                disabledItemsIds={disabledItemsIds}
                item={directory.data}
                children={directory.children}
                {...directory.data}
                id={directory.id}
                type={TreeType.DIRECTORY_ITEM}
                label={directory.data.value}
                isBuilderVariant={isBuilderVariant}
                forceEnglish={forceEnglish}
                selectedIds={selectedIds}
                handleItemSelect={handleItemSelect}
                treeStructureNodes={treeStructureNodes}
              />;
            },
          ));
      });
    };

    useEffect(() => {
      init();
    }, []);

    const searchHandler = async () => {
      if (debouncedInputValue === '') {
        setSearchResultNodes([]);
        return init();
      }
      setFetchingState(FetchingState.LOADING);
      getValuesByNameFragment(debouncedInputValue, 'PUBLISHED', forceEnglish)
        .then(
          hierarchy => {
            setFetchingState(FetchingState.SUCCESS);
            const resultNodes = hierarchy
              .flatMap(node => Object.values(treeStructureNodes)
                ?.filter(item => (item as TreeStructureNode).data.id === node.id),
              ).filter(Boolean);
            setSearchResultNodes(resultNodes);
            setChildNodes(resultNodes
              .filter(node => node.data.status === 'PUBLISHED')
              .map(
                directory => {
                  return <ProjectChildSelect
                    key={directory.id}
                    onSelect={handleSelect}
                    disabledItemsIds={disabledItemsIds}
                    item={directory.data}
                    children={directory.children}
                    {...directory.data}
                    id={directory.id}
                    type={TreeType.DIRECTORY_ITEM}
                    label={directory.data.value}
                    isBuilderVariant={isBuilderVariant}
                    forceEnglish={forceEnglish}
                    selectedIds={selectedIds}
                    handleItemSelect={handleItemSelect}
                    treeStructureNodes={treeStructureNodes}
                  />;
                },
              ));
          },
          () => setFetchingState(FetchingState.ERROR),
        );
    };

    useEffect(() => {
      searchHandler();
    }, [debouncedInputValue]);

    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };

    const fetchDirectories = (id?: string) => {
      setFetchingState(FetchingState.LOADING);
      return getDirectoryByIdV2(id || projectDirectoryId, undefined, 'PUBLISHED', forceEnglish).then(
        async data => {
          if (data?.content) {
            setFetchingState(FetchingState.SUCCESS);
            return data?.content;
          } else {
            const nodes = await getDirectoriesV2Value(id || projectDirectoryId, forceEnglish);
            setFetchingState(FetchingState.SUCCESS);
            return nodes;
          }
        },
        () => setFetchingState(FetchingState.ERROR),
      );
    };

    const handleRefetch = useCallback(() => {
      init();
    }, []);

    const handleChange = (event, nodes) => {
      const expandingNodes = nodes.filter(x => !expanded.includes(x));
      setExpanded(nodes);
      if (expandingNodes[0]) {
        if (!childNodes) {
          setFetchingState(FetchingState.LOADING);
          fetchDirectories().then(
            projects => {
              if (projects) {
                setFetchingState(FetchingState.SUCCESS);
                setChildNodes(projects
                  .filter(node => node.status === 'PUBLISHED')
                  .map(
                    project =>
                      <ProjectChildSelect
                        key={project.id}
                        onSelect={handleSelect}
                        disabledItemsIds={disabledItemsIds}
                        item={project}
                        {...project}
                        id={project.id}
                        type={TreeType.DIRECTORY_ITEM}
                        label={project.value}
                        isBuilderVariant={isBuilderVariant}
                        forceEnglish={forceEnglish}
                        selectedIds={selectedIds}
                        handleItemSelect={handleItemSelect}
                        treeStructureNodes={treeStructureNodes}
                      />,
                  ));
              } else {
                setFetchingState(FetchingState.ERROR);
              }
            },
            () => setFetchingState(FetchingState.ERROR),
          );
        }
      }
    };

    const renderNodes = () => {
      switch (fetchingState) {
        case FetchingState.LOADING: {
          return <InfoBox><LinearProgress color="secondary"/></InfoBox>;
        }
        case FetchingState.ERROR: {
          return <InfoBox display="flex" justifyContent="space-between" alignItems="center" pt={2} height={46}>
            <span>Error while fetching</span>
            <Button onClick={handleRefetch}>Try again</Button>
          </InfoBox>;
        }
        case FetchingState.SUCCESS:
        case FetchingState.IDLE:
        default: {
          return <TreeView
            className={treeClasses.root}
            defaultCollapseIcon={<ExpandMoreIcon/>}
            defaultExpandIcon={<ChevronRightIcon/>}
            expanded={expanded}
            onNodeToggle={handleChange}
          >
            {childNodes}
          </TreeView>;
        }
      }
    };

    const validateValue = (value) => {
      if (selectedIds.length === 0 && rules?.required) {
        return t('form_components.validation_errors.listOption');
      }

      return true;
    };

    const textField = useMemo(() => {
      if (withoutFormController) {
        return <TextField
          label={hint}
          placeholder={placeholder}
          disabled={isDisabled}
          fullWidth={!isConditionModalVariant}
          value={(valueId || (isConditionModalVariant && value)) ? valueText : value?.name || ''}
          onChange={() => {
          }}
          className={cn({ [inputClasses.conditionFieldText]: isConditionModalVariant })}
          error={!!errors[name]}
          helperText={(!!errors[name] && showErrorText) && errors[name].message}
          InputProps={{ endAdornment: <img src={dropdownIndicatorIcon}/> }}
          onClick={(event) => {
            if (!isDisabled) {
              setOpen(v => !v);
              setAnchorElement(anchorElement ? null : event.currentTarget);
              setPopperWidth((event.currentTarget?.offsetWidth - 16) || 300);
            }
          }}
        />;
      }

      return <Controller
        name={name}
        control={control}
        rules={{
          validate: validateValue,
        }}
        render={() => (
          <>
            <label>{hint}</label>
            <TextField
              // label={hint}
              placeholder={placeholder}
              fullWidth
              disabled={isDisabled}
              value={valueId ? valueText : value?.name || ''}
              onChange={() => {
              }}
              inputProps={{ 'data-selenium': name }}
              error={!!errors[name]}
              helperText={(!!errors[name] && showErrorText) && errors[name].message}
              InputProps={{ endAdornment: <img src={dropdownIndicatorIcon}/> }}
              onClick={(event) => {
                if (!isDisabled) {
                  setOpen(v => !v);
                  setAnchorElement(anchorElement ? null : event.currentTarget);
                  setPopperWidth((event.currentTarget?.offsetWidth - 16) || 300);
                }
              }}
            />
          </>
        )}
      />;

    }, [isConditionModalVariant, withoutFormController, name, control, validateValue, hint, placeholder, value, valueId, valueText, errors, showErrorText, anchorElement, isDisabled]);

    const handleDropdownSubmit = () => {
      onSelect(selectedIds);
      setOpen(v => !v);
      setAnchorElement(null);
    };

    const handleDropdownClear = () => {
      setSelectedIds([]);
      onSelect([]);
      setOpen(v => !v);
      setAnchorElement(null);
    };

    return (
      <>
        {textField}

        <ClickAwayListener onClickAway={() => {
          if (isClickAwayListenerActive) {
            setOpen(v => !v);
            setAnchorElement(null);
          }
        }}>
          <Styled.Popper
            open={open}
            style={{ width: popperWidth }}
            anchorEl={anchorElement}
            placement="bottom-start"
          >
            <Styled.ProjectSelectContainer
              ref={selectContentRef}
              visible={true}
              isSingleValueSelect={false}
            >
              <Styled.Input
                name="search"
                fullWidth
                value={inputValue}
                placeholder={t('form_components.select.search_placeholder')}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: <SearchIcon htmlColor="#000"/>,
                }}
              />
              <Styled.TreeViewParent visible={true}>
                <Styled.TreeViewContainer>
                  {renderNodes()}
                </Styled.TreeViewContainer>
              </Styled.TreeViewParent>
              <Styled.Footer>
                <Button variant="text" onClick={handleDropdownClear} disabled={!selectedIds?.length}>
                  {t('form_components.select.clear')}
                </Button>
                <Button variant="text" onClick={handleDropdownSubmit}
                        disabled={!selectedIds?.length}>
                  {t('form_components.select.apply')}
                </Button>
              </Styled.Footer>
            </Styled.ProjectSelectContainer>
          </Styled.Popper>
        </ClickAwayListener>
      </>);
  }
;
