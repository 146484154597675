import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { setBpmGlossaryId as setGlossaryId, useGlossaryState, useTaskState } from 'store/requests';
import { useUsersState } from 'store/users';
import { getProfileCompanyDataFromLocalStorage } from 'utils/user';
import { getValuesInsideDoubleCurly, transformDoubleCurlyValuesToString } from './utils';
import { getDirectoryInfo } from 'api/requests';
import { useDispatch } from 'react-redux';

type Props = {
  name?: string;
  type?: string;
  component?: string;
  params: { [key: string]: string };
  value?: unknown;
  systemVariableName?: string;
  profileCompanyFieldName?: string;
  isGlossaryValue?: boolean;
  isSelectGlossaryValueSavedById?: boolean;
  isValueFromValuesList?: boolean;
  showCustomValue?: boolean;
  displayValue?: string;
};

const useReadOnly = ({
  name,
  type,
  component,
  params,
  value,
  systemVariableName,
  profileCompanyFieldName,
  isGlossaryValue = false,
  isSelectGlossaryValueSavedById = false,
  isValueFromValuesList = false,
  showCustomValue = false,
  displayValue = ''
}: Props) => {
  const { t, i18n } = useTranslation();
  const {
    watch,
    getValues,
    setValue
  } = useFormContext();
  const dispatch = useDispatch();

  const { mapList: glossaryMap } = useGlossaryState();
  const { users } = useUsersState();
  const { data: bpmTask } = useTaskState();

  const currentValue = watch(name) || '';

  useEffect(() => {
    if (systemVariableName) {
      const systemVariableValue = bpmTask.businessTask[systemVariableName];
      setValue(name, systemVariableValue);
    }

    if (profileCompanyFieldName) {
      const companyData = getProfileCompanyDataFromLocalStorage();
      const companyDataFieldValue = companyData[profileCompanyFieldName] || '';
      setValue(name, companyDataFieldValue);
    }
  }, [systemVariableName, profileCompanyFieldName, bpmTask.businessTask, name, setValue]);

  const getFormValue = useCallback(formValue =>
    getValues(formValue) || '',
    [getValues]);

  const getLocalizedGlossaryValue = (value) => {
    const translationsEn = i18n.getResourceBundle('en', '');
    const translationsRu = i18n.getResourceBundle('ru', '');

    if (!(translationsRu && translationsEn)
      || !translationsEn.glossary || !translationsEn.glossary_1c
      || !translationsRu.glossary || !translationsRu.glossary_1c) {
      return value
    }

    const glossariesKeys = Object.keys(translationsEn.glossary)
      .reduce((acc, key) => {
        return {
          ...acc,
          [translationsEn.glossary[key]]: key,
          [translationsRu.glossary[key]]: key,
        };
      }, {});

    const glossaries1cKeys = Object.keys(translationsEn.glossary_1c)
      .reduce((acc, key) => {
        return {
          ...acc,
          [translationsEn.glossary_1c[key]]: key,
          [translationsRu.glossary_1c[key]]: key,
        };
      }, {});

    if (Object.keys(glossaries1cKeys).includes(value)) {
      const glossaryValueKey = glossaries1cKeys[value];
      return t(`glossary_1c.${glossaryValueKey}`, { defaultValue: value });
    } else {
      const glossaryValueKey = glossariesKeys[value];
      return t(`glossary.${glossaryValueKey}`, { defaultValue: value });
    }
  }

  // Получение переведенного значения на основе значений компонента select или
  // radio-button-group, настроенного для этого же атрибута на других шагах
  const getLocalizedValueFromValuesList = (value) => {
    const attributeId = bpmTask.getAttributeByName(name)?.typeAttributeId;
    const attributeSysName = bpmTask.getAttributeByName(name)?.sysName;

    // Поиск шагов с компонентом select или radio-button-group для данного атрибута
    const statesWithSelectComponents = bpmTask.states.filter(state => {
      const attributeParams = state.bsnParameters
        .find(v => v.attributeId === attributeId && ['select', 'radio-button-group'].includes(v.component));
      return !!attributeParams;
    })
    const stateForValuesGetting = statesWithSelectComponents.length
                                  ? statesWithSelectComponents.find(state => state.name === 'Request Formation') || statesWithSelectComponents[0]
                                  : null;
    const attributeWithEditableComponent = stateForValuesGetting?.bsnParameters
      .find(v => v.attributeId === attributeId && ['select', 'radio-button-group'].includes(v.component));

    // если найден - получение списка переведенных значений для выбора
    // и получение перевода значения данного атрибута
    if (attributeWithEditableComponent) {
      const attributeParams = JSON.parse(attributeWithEditableComponent.componentParams);
      const valuesList = attributeParams?.values?.split(',') || [];
      const valuesLocalizationKey = `constructor-${bpmTask.processSysName}.states.${stateForValuesGetting.sysName}.attributes.${attributeSysName.replaceAll('::', '-')}.params.values`;
      const localizedValuesString = t(valuesLocalizationKey, {defaultValue: attributeParams?.values});
      const localizedValuesList = localizedValuesString?.split(',') || [];
      const valueIndex = valuesList.indexOf(value);

      return valueIndex >= 0 && valueIndex < localizedValuesList.length
             ? localizedValuesList[valueIndex]
             : value;
    }

    return value;
  }

  const localizedGlossaryValueById = useMemo(() => {
    const glossaryValues = glossaryMap[params.glossaryIndexName];
    const selectedItem = glossaryValues?.find(item => item.id === value);
    return selectedItem?.additionalFields?.[i18n.language] || selectedItem?.value || value;
  }, [glossaryMap, params]);

  useEffect(() => {
    if (isSelectGlossaryValueSavedById) {
      dispatch(setGlossaryId(params.glossaryIndexName));
    }
  }, [isSelectGlossaryValueSavedById, params]);

  const [localizedGlossaryValue, setLocalizedGlossaryValue] = useState()

  const text = useMemo(() => {
    if (showCustomValue) {
      return displayValue;
    }

    const formattedType = type ? type.toString().toLowerCase() : 'text';

    // TODO: Убрать хардкод ( Проработать с аналитиками )
    if (formattedType === 'boolean') {
      if (currentValue === 'true' || currentValue === true) {
        return t("form_components.readonly.boolean.true", {defaultValue: "Yes"});
      }
      if (
        !currentValue ||
        currentValue === 'false' ||
        currentValue === false
      ) {
        return t("form_components.readonly.boolean.false", {defaultValue: "No"});
      }
    }

    if (formattedType === 'integer' && !value) {
      return 0;
    }

    // TODO: Создать несколько компонентов по readonly компонентам
    if (currentValue) {
      if (component === 'readonly-field') {
        if (isSelectGlossaryValueSavedById) {
          return localizedGlossaryValueById;
        }

        if (isGlossaryValue) {
          return getLocalizedGlossaryValue(currentValue);
        }

        if (isValueFromValuesList) {
          return getLocalizedValueFromValuesList(currentValue);
        }

        const initialText = currentValue.toString();
        const doubleCurlyValuesArray = getValuesInsideDoubleCurly(initialText);

        if (bpmTask && doubleCurlyValuesArray.length > 0) {
          const params = {
            bpmTask,
            doubleCurlyValuesArray,
            initialText,
            getFormValue,
            users,
          };

          return transformDoubleCurlyValuesToString(params);
        }

        return currentValue.toString();
      }

      if (component === 'readonly-date') {
        const format = params.format ? params.format : 'DD.MM.YYYY';
        return moment(new Date(currentValue)).format(format);
      }

      if (component === 'readonly-list') {
        return (value as string[]).map(item => getLocalizedGlossaryValue(item));
      }

      return currentValue;
    }

    return '';
  }, [currentValue, type, component, params.format, value, bpmTask, getFormValue, showCustomValue, displayValue, localizedGlossaryValueById]);

  const fetchLocalizedGlossaryValue = useCallback(async () => {
    try {
      if (currentValue) {
        const info = await getDirectoryInfo(currentValue);
        setLocalizedGlossaryValue(info?.value)
      }
    } catch (err) {
      //
    }
  }, [currentValue])

  useEffect(() => {
    if (params.directoryPath || params.directoryCode){
      fetchLocalizedGlossaryValue()
    }
  }, [fetchLocalizedGlossaryValue, params.directoryPath, params.directoryCode])

  return { text: localizedGlossaryValue || text };
};

export default useReadOnly;
