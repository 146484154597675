import React, { memo, ReactElement } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import cx from 'classnames';

import useStyles from './useStyles';

type SpinnerProps = {
  fullscreen?: boolean,
  absolute?: boolean
  size?: number,
  white?: boolean,
  buttonVersion?: boolean,
}

const Spinner = memo(({
  fullscreen = false,
  absolute = true,
  size = 32,
  white = false,
  buttonVersion = false
}: SpinnerProps): ReactElement => {
  const classes = useStyles();

  if (size > 40) size = 40;
  return (
    <div className={cx(classes.container, { fullscreen, absolute, buttonVersion })}>
      <div className="innerWrap">
        <CircularProgress
          size={size}
          className={cx(classes.circularProgress, { [classes.circularProgressWhite]: white })}
        />
      </div>
    </div>
  );
});

export default Spinner;
