import moment from 'moment';

import { TransformDoubleCurlyValuesToStringProps } from './types';
import { UserType } from 'types';

/**
 * Function that format number with spaces between thousands and 2 digits in fraction part
 * @param value a number to be formatted
 * @returns formatted number
 */
const formatNumber = (value): string => {
  const numberWithDecimalPoint = parseFloat(value).toFixed(2).toString();
  const numberParts = numberWithDecimalPoint.split('.');
  const thousandsSeparator = ' ';
  let formattedIntegerPart = '';
  let i = numberParts[0].length;
  while (i > 0) {
    const part =
      i > 3 ? numberParts[0].substr(i - 3, 3) : numberParts[0].substr(0, i);
    i = i - 3;
    formattedIntegerPart = thousandsSeparator + part + formattedIntegerPart;
  }
  return formattedIntegerPart + '.' + numberParts[1];
};

/**
 * Function that removes html tags from the passed string
 * @param value a string for processing
 * @returns value without html tags
 */
const clearFromHtmlTags = (value: string): string => {
  let resultValue = value;
  while (resultValue.includes('<') && resultValue.includes('>')) {
    const openingBracketPosition = resultValue.indexOf('<');
    const closingBracketPosition = resultValue.indexOf('>');
    const foundHtmlTag = resultValue.substring(
      openingBracketPosition,
      closingBracketPosition + 1
    );
    resultValue = resultValue.replace(foundHtmlTag, '');
  }
  return resultValue;
};

const TYPES = {
  DATETIME: 'DATETIME',
  DATE: 'DATE',
  BOOLEAN: 'BOOLEAN',
  DOUBLE: 'DOUBLE',
  INTEGER: 'INTEGER',
};

const HTML_REPLACEMENTS = { '&amp;': '&' };

/**
 * Function that format passed value according to its type and component
 * @param {string} attributeType type of the attribute
 * @param {string} attributeComponent component of the attribute
 * @param {string} formValue a number to be formatted
 * @param {User[]} users array of users
 * @returns formatted value
 */
const convertFormValueRelatedToType = ({
  attributeType,
  attributeComponent,
  formValue,
  users,
}): string => {
  if (
    ['user-select', 'readonly-user', 'hidden-field'].includes(
      attributeComponent
    )
  ) {
    const userInfo = Object.values(users).find(
      (user: UserType) => user.logName === formValue || user.id === formValue
    ) as UserType;
    if (userInfo) {
      return userInfo?.fullName;
    }
  }
  switch (attributeType) {
    case TYPES.DATETIME:
      return moment(formValue).format('DD.MM.YYYY').toString();
    case TYPES.DATE:
      return moment(formValue).format('DD.MM.YYYY').toString();
    case TYPES.BOOLEAN:
      return !!formValue ? 'Yes' : 'No';
    case TYPES.INTEGER:
    case TYPES.DOUBLE:
      if (!formValue) {
        return '0';
      }
      return formatNumber(formValue);
    default:
      return formValue || '(none)';
  }
};

const EXPRESSION_TYPES = {
  CONDITION: 'CONDITION',
  FIRST_TRUE: 'FIRST_TRUE',
  SHOW_IF_NOT_EMPTY: 'SHOW_IF_NOT_EMPTY',
};

/**
 * Function that returns type of special expression in passed string
 * @param value string to check for special expression presence
 * @returns special expression type as string (from EXPRESSION_TYPES) or null
 */
const detectSpecialExpression = (value: string) => {
  if (value.includes('?')) {
    return EXPRESSION_TYPES.CONDITION;
  }
  if (value.includes('||')) {
    return EXPRESSION_TYPES.FIRST_TRUE;
  }
  if (value.includes('&')) {
    return EXPRESSION_TYPES.SHOW_IF_NOT_EMPTY;
  }

  return null;
};

/**
 * Function that gets field value from ticketData
 * @param formField  Name of the field
 * @param ticketData  An object containing task ticket data
 * @returns value of the field
 */

const getValueFromTicketData = (formField, ticketData) =>
  ticketData?.attributes.find(({ name }) => name === formField)?.value;

/**
 * Function that inserts form values of fields in curly brackets into passed initial text
 * @param doubleCurlyValuesArray  Array of form fields in double curly brackets
 * @param initialText  Initial string in which form values will be inserted
 * @param users  An array of users
 * @param ticketData  An object containing task ticket data
 * @returns string with inserted form values
 */
export const transformDoubleCurlyValuesToString = ({
  bpmTask,
  doubleCurlyValuesArray,
  initialText,
  getFormValue,
  users,
  ticketData,
}: TransformDoubleCurlyValuesToStringProps): string => {
  return doubleCurlyValuesArray.reduce((accumulator, currentValue) => {
    const cleanedCurrentValue = Object.keys(HTML_REPLACEMENTS).reduce(
      (text, key) => text.replace(key, HTML_REPLACEMENTS[key]),
      currentValue
    );

    const currentValueExpressionType = detectSpecialExpression(
      cleanedCurrentValue
    );
    const curvedCurrentValue = `{{ ${currentValue} }}`;

    if (currentValueExpressionType === EXPRESSION_TYPES.CONDITION) {
      // expression format: {{ condition ? trueValueField : falseValueField }}
      const condition = clearFromHtmlTags(
        cleanedCurrentValue.split('?')[0]
      ).trim();
      const [trueValueField, falseValueField] = cleanedCurrentValue
        .split('?')[1]
        .split(':')
        .map((v) => v.trim());

      const clearedTrueValueField = clearFromHtmlTags(trueValueField);
      const clearedFalseValueField = clearFromHtmlTags(falseValueField);

      const conditionValue =
        (!!ticketData
          ? getValueFromTicketData(condition, ticketData)
          : getFormValue(condition)) || false;
      const resultField = conditionValue
        ? clearedTrueValueField
        : clearedFalseValueField;

      const params = {
        initialText: accumulator,
        valueToReplace: curvedCurrentValue,
        bpmTask,
        formField: resultField,
        replacementTemplate: conditionValue ? trueValueField : falseValueField,
        getFormValue,
        users,
        ticketData,
      };

      return replaceByFormFieldValue(params);
    }

    if (currentValueExpressionType === EXPRESSION_TYPES.FIRST_TRUE) {
      // expression format: {{ field1 || field2 || field3 }}
      const fields = cleanedCurrentValue.split('||').map((v) => v.trim());
      const clearedFields = fields.map(v => clearFromHtmlTags(v));
      const values = clearedFields.map((field) =>
        field === 'initiator'
          ? ticketData
            ? ticketData.initiator
            : bpmTask.initiator
          : (!!ticketData
              ? getValueFromTicketData(field, ticketData)
              : getFormValue(field)) || ''
      );
      const firstTrueValueIndex = values.findIndex((v) => !!v);
      const firstTrueValue =
        firstTrueValueIndex >= 0 ? values[firstTrueValueIndex] : null;

      if (!!firstTrueValue) {
        const params = {
          initialText: accumulator,
          valueToReplace: curvedCurrentValue,
          bpmTask,
          formField: clearedFields[firstTrueValueIndex],
          replacementTemplate: fields[firstTrueValueIndex],
          getFormValue,
          users,
          ticketData,
        };

        return replaceByFormFieldValue(params);
      }

      return accumulator.replace(curvedCurrentValue, '(none)');
    }

    if (currentValueExpressionType === EXPRESSION_TYPES.SHOW_IF_NOT_EMPTY) {
      // expression format: {{ condition & ' Title: { field } ' }}
      // if value of condition field is truly replace by expression in quotes
      // with inserted field value,
      // otherwise replace by an empty string
      const [conditionFieldName, displayExpression] = cleanedCurrentValue
        .split('&')
        .map((v) => v.trim());
      const clearedConditionFieldName = clearFromHtmlTags(conditionFieldName);

      const conditionAttributeValue = !!ticketData
        ? getValueFromTicketData(clearedConditionFieldName, ticketData)
        : getFormValue(clearedConditionFieldName);

      if (!!conditionAttributeValue) {
        const displayExpressionWithoutQuotes = displayExpression.substr(
          1,
          displayExpression.length - 2
        );
        const fieldNames = getValuesInsideBrackets(displayExpression, '{', '}');

        const finalString = fieldNames.reduce((text, fieldName) => {
          const params = {
            initialText: text,
            valueToReplace: `{ ${fieldName} }`,
            bpmTask,
            formField: fieldName,
            getFormValue,
            users,
            ticketData,
          };

          return replaceByFormFieldValue(params);
        }, displayExpressionWithoutQuotes);

        // to fix not found curvedCurrentValue when there are
        // double spaces in initial text
        accumulator = accumulator.replace('  ', ' ');
        return accumulator.replace(curvedCurrentValue, finalString);
      } else {
        accumulator = accumulator.replace('  ', ' ');
        return accumulator.replace(curvedCurrentValue, '');
      }
    }

    const params = {
      initialText: accumulator,
      valueToReplace: curvedCurrentValue,
      bpmTask,
      formField: currentValue,
      getFormValue,
      users,
      ticketData,
    };
    return replaceByFormFieldValue(params);
  }, initialText);
};

/**
 * Function that replaces valueToReplace in initial text with formatted formField field value
 * @param {string} initialText  Initial text
 * @param {string} valueToReplace  Value that will be replaced
 * @param {BpmTask} bpmTask   BpmTask object
 * @param {string} formField  Name of form field which value should be inserted
 * @param {string} replacementTemplate  A template for replacement (string to bubstituted with replaced formField by field value)
 * @param getFormValue  A function to get form value by field name
 * @param users  An array of users
 * @param ticketData  An object containing task ticket data
 * @returns string with replaced valueToReplace
 */
const replaceByFormFieldValue = ({
  initialText,
  valueToReplace,
  bpmTask,
  formField,
  replacementTemplate = null,
  getFormValue,
  users,
  ticketData,
}) => {
  if (formField === 'initiator') {
    const params = {
      attributeType: 'STRING',
      attributeComponent: 'readonly-user',
      formValue: !!ticketData ? ticketData?.initiator : bpmTask?.initiator,
      users,
    };
    const initiatorFullName = convertFormValueRelatedToType(params);
    return initialText.replace(valueToReplace, initiatorFullName);
  }

  const currentAttribute = !!ticketData
    ? ticketData.attributes.find((attr) => attr.name === formField)
    : bpmTask.getAttributeByName(formField);

  const attributeData = !!ticketData
    ? currentAttribute &&
      bpmTask?.entity?.currentState?.bsnParameters.find(
        (param) => param.attributeId === currentAttribute.id
      )
    : null;

  const attributeType = currentAttribute
    ? currentAttribute.type
    : attributeData
    ? attributeData.component
    : '';

  const attributeComponent = currentAttribute ? currentAttribute.component : '';

  const formValue = ticketData
    ? getValueFromTicketData(formField, ticketData)
    : getFormValue(formField);

  const params = { attributeType, attributeComponent, formValue, users };

  const convertedFormValue = convertFormValueRelatedToType(params);

  const replacementResult = replacementTemplate
    ? replacementTemplate.replace(formField, convertedFormValue)
    : convertedFormValue;

  return initialText.replace(valueToReplace, replacementResult);
};

export const getValuesInsideDoubleCurly = (text: string) =>
  getValuesInsideBrackets(text, '{{', '}}');

/**
 * Function that gets values in brackets
 * @param {string} text  String in which values in brackets will be found
 * @param {string} startBrackets  open brackets value
 * @param {string} endBrackets  close brackets value
 * @returns array of values inside passed brackets
 */
export const getValuesInsideBrackets = (
  text: string,
  startBrackets: string,
  endBrackets: string
): string[] =>
  text.includes(startBrackets) && text.includes(endBrackets)
    ? text
        .split(startBrackets)
        .filter((value) => value.includes(endBrackets))
        .map((value) => value.substring(0, value.indexOf(endBrackets)).trim())
    : [];
