import React, { useMemo } from 'react';
import cn from 'classnames';
import { Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useTaskState } from 'store/requests';
import { useReadStatus } from 'store/main';

import useStyles from './useStyles';
import { COMMENTS_TAB_NAME } from '../Tabs/CommentsTab/TaskComments/constants';
import { ATTACHMENTS_TAB_NAME, INFORMATION_TAB_NAME } from '../Tabs/FormTab/constants';
import { DataViewTab } from '../../types';

type Props = {
  tabsList: DataViewTab[];
  tabsWithErrors: string[];
  unreadTabs: string[];
  onClick?: (tabId: number | string) => void;
  activeTab?: number | string;
};

export const DataViewTabs = ({
  tabsList,
  tabsWithErrors,
  unreadTabs,
  onClick,
  activeTab,
}: Props) => {
  const classes = useStyles();
  const { data: bpmTask } = useTaskState();
  const { t } = useTranslation();
  const readStatuses = useReadStatus();

  const activeValue = useMemo(() => {
    if (activeTab !== undefined) return activeTab;
  }, [activeTab]);

  return (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      value={activeValue}
      className={classes.tabs}
      classes={{
        indicator: classes.tabIndicator,
      }}
    >
      {tabsList.map((el: DataViewTab) => {
        const isTabWithError = tabsWithErrors?.includes(el?.name?.toLowerCase());
        const isUnreadTab = unreadTabs?.includes(el?.name?.toLowerCase()) &&
          !((el.name === COMMENTS_TAB_NAME && readStatuses.comments.includes(bpmTask?.taskId)) ||
            (el.name === ATTACHMENTS_TAB_NAME && readStatuses.attachments.includes(bpmTask?.taskId)) ||
            (el.name === INFORMATION_TAB_NAME && readStatuses.tasks.includes(bpmTask?.taskId)));
        const tabLabelTranslationKey = `constructor-${bpmTask.processSysName}.custom-tabs.${el.id}`;

        return <Tab
          className={cn(classes.tab, {
            [classes.tabWithError]: isTabWithError,
            [classes.unreadTab]: isUnreadTab && !el.icon,
            [classes.unreadTabIcon]: isUnreadTab && !!el.icon,
          })}
          key={el.name}
          data-selenium={el.name + '-tab'}
          label={t(`task_data_view.tabs.${el.name}`, tabLabelTranslationKey, { defaultValue: el.label })}
          value={el.name}
          onClick={() => onClick(el.name)}
          disabled={!el.enabled}
        />;
      })}
    </Tabs>
  );
};
