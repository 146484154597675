import React, { useState, useEffect, Suspense } from 'react';
import {
  ThemeProvider,
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import { authService } from '@dar-dms/topbar';
import { ErrorBoundary } from '@dar-dms/utils';
import theme from 'theme';
import store from 'store';

import { MainLayout as BpmApp } from 'layouts';
import { Spinner } from 'components';

const generateClassName = createGenerateClassName({
  productionPrefix: 'styles',
});

const Root = () => {
  const [isAuthLoaded, setAuthLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (authService) {
      const subscribe = authService.loggedIn.subscribe((auth) => {
        if (auth) {
          setAuthLoaded(true);
        }
      });

      return () => {
        subscribe.unsubscribe();
      };
    }
  }, []);

  if (!isAuthLoaded) {
    return <Spinner absolute={false}/>;
  }

  return (
    <ErrorBoundary moduleName="dar-dms-processes">
      <React.StrictMode>
        <Suspense fallback={<Spinner fullscreen absolute={false}/>}>
          <Provider store={store}>
            <StylesProvider generateClassName={generateClassName}>
              <BrowserRouter basename="processes">
                <ThemeProvider theme={theme}>
                  <BpmApp/>
                </ThemeProvider>
              </BrowserRouter>
            </StylesProvider>
          </Provider>
        </Suspense>
      </React.StrictMode>
    </ErrorBoundary>
  );
};

export default Root;
