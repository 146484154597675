import get from 'lodash/get';
import { CancelToken } from "axios";

import {
  getBusinessEntity,
  createInstance,
  updateInstance,
  getInstance,
  getGoogleDriveAccessToken,
  getAttachmentById,
  uploadAttachmentToGoogleDrive,
  getAttachmentFromPS,
} from 'api/requests';
import { MIME_TYPES, STORAGE } from './constants';

const DEFAULT_MIME_TYPES = Object.keys(MIME_TYPES)
  .map((type) => MIME_TYPES[type])
  .join(',');

const IS_ANY_LETTER_REG_EXP = /[a-zA-Z]/g;

export const getGoogleDriveMimeTypes = (allowedTypes) =>
  allowedTypes
  ? allowedTypes
    .split(',')
    .map((type) => get(MIME_TYPES, type, ''))
    .filter((type) => !!type)
    .join(',')
  : DEFAULT_MIME_TYPES;

export const createFileInstance = async ({
  payload: {
    file,
    taskId,
    applicationNumber,
    creationDate,
    assignee,
    step,
  },
  cancelToken
}: {
  payload: {
    file: {
      id: number | string;
      name: string;
      mimeType: string;
      size?: number;
    },
    taskId: string;
    applicationNumber: string;
    creationDate?: string;
    assignee?: string;
    step?: string;
  },
  cancelToken?: CancelToken
}) => {
  try {
    const fileAttachmentBusinessEntityResponse = await getBusinessEntity({
      params: {
        sysName: 'file_attachment',
      },
      cancelToken
    });

    const fileAttachmentInstanceCreateResponse = await createInstance({
      params: {
        bsnTypeId: fileAttachmentBusinessEntityResponse.id,
        taskId,
        applicationNumber,
      },
      cancelToken
    });

    const fileAttachmentStringAttributes = fileAttachmentInstanceCreateResponse.stringAttributes.map(
      (attr) => {
        if (attr.name === 'Название') return { ...attr, value: file.name };
        if (attr.name === 'Владелец') return { ...attr, value: '(none)' };
        if (attr.name === 'Тип') return { ...attr, value: file.mimeType };
        if (attr.name === 'Файл') return { ...attr, value: file.id };
        if (attr.name === 'assignee') return { ...attr, value: assignee || '' };
        if (attr.name === 'creationDate') return { ...attr, value: creationDate };
        if (attr.name === 'step') return { ...attr, value: step };
        return attr;
      },
    );

    const fileAttachmentIntegerAttributes = fileAttachmentInstanceCreateResponse.integerAttributes.map(
      (attr) => {
        if (attr.name === 'size') return { ...attr, value: file.size };
        return attr;
      },
    );

    const fileAttachmentEntityInstance = {
      ...fileAttachmentInstanceCreateResponse,
      stringAttributes: fileAttachmentStringAttributes,
      integerAttributes: fileAttachmentIntegerAttributes,
    };

    await updateInstance({
      params: {
        taskId
      },
      data: fileAttachmentEntityInstance,
      cancelToken
    });

    return fileAttachmentInstanceCreateResponse.id;
  } catch (error) {
    throw new Error(error);
  }
};

export const getOldAttachmentInformation = async (fileId) => {
  try {
    const { fileAttributes, stringAttributes } = await getInstance(fileId);

    const byteAttribute = fileAttributes.find(({ name }) => name === 'Данные');
    const typeAttribute = stringAttributes.find(({ name }) => name === 'Тип');

    const type = typeAttribute ? typeAttribute.value : '';
    const blob = new Uint8Array(byteAttribute.value);

    return {
      blob,
      type,
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const isGoogleDocId = (id) => IS_ANY_LETTER_REG_EXP.test(`${id}`);

export const getAttachmentFile = async ({
  fileId,
  attachmentId,
  attachmentStorage,
  handleSuccess = attachmentBlob => null,
  handleError = error => null,
}) => {
  if (attachmentStorage === STORAGE.PS) {
    try {
      const attachmentBlob = await getAttachmentFromPS({fileId: attachmentId});

      handleSuccess(attachmentBlob);
    } catch (error) {
      handleError(error);
    }
  } else if (isGoogleDocId(fileId)) {
    try {
      const accessToken = await getGoogleDriveAccessToken();
      const attachmentBlob = await getAttachmentById(fileId, accessToken);

      handleSuccess(attachmentBlob);
    } catch (error) {
      handleError(error);
    }
    // TODO: Удалить после проверки
  } else {
    try {
      const attachmentInformation = await getOldAttachmentInformation(fileId);
      const { blob } = attachmentInformation;

      handleSuccess(blob);
    } catch (error) {
      handleError(error);
    }
  }
};

export const uploadAttachmentWithServiceAccount = async ({
  formData,
  handleSuccess = response => null,
  handleAuthError = () => {
  },
}) => {
  try {
    const accessToken = await getGoogleDriveAccessToken();
    const response = await uploadAttachmentToGoogleDrive(formData, accessToken);

    handleSuccess(response);
  } catch (error) {
    handleAuthError();
  }
};

export const getErrorMessage = (errors) => get(errors, 'message', '');

export const getChunkpot = (chunkSize: number, fileSize: number) => {
  var chunkPot = { total: fileSize, chunks: [] };
  if (fileSize > chunkSize) {
    var numE = chunkSize;
    var endS = (function(f, n) {
      var c = f % n;
      if (c == 0) {
        return 0;
      } else {
        return c;
      }
    })(fileSize, numE);
    var repeat = Math.floor(fileSize / numE);
    for (var i = 0; i <= repeat; i++) {
      var startAddress = i * numE;
      var c = { startByte: 0, endByte: 0, numByte: 0 };
      c.startByte = startAddress;
      if (i < repeat) {
        c.endByte = startAddress + numE - 1;
        c.numByte = numE;
        chunkPot.chunks.push(c);
      } else if (i == repeat && endS > 0) {
        c.endByte = startAddress + endS - 1;
        c.numByte = endS;
        chunkPot.chunks.push(c);
      }
    }
  } else {
    var chunk = {
      startByte: 0,
      endByte: fileSize - 1,
      numByte: fileSize,
    };
    chunkPot.chunks.push(chunk);
  }
  return chunkPot;
};
