import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Typography, ButtonBase } from '@mui/material';

import { EDS_FORM } from 'pages/Task/TaskForm/Eds/EdsConstants';

import useStyles from './useStyles';
import CloseIcon from 'assets/images/icons/close-white.svg';

type Props = {
  loading: boolean;
  handleModalState: (value: boolean) => void;
};

export const EdsFormHeader = ({ loading = false, handleModalState }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      component="header"
      bgcolor="primary.700"
      color="common.white"
      py={4}
      px={6}
      marginLeft={-6}
      marginRight={-6}
      marginTop={-5}
      display="flex"
      justifyContent="space-between"
    >
      <Typography variant="h4">{t(EDS_FORM.BLOCK_TITLE)}</Typography>
      <ButtonBase
        disabled={loading}
        onClick={() => handleModalState(false)}
        className={cn({
          [classes.disabledCloseButton]: loading,
        })}
      >
        <img src={CloseIcon} alt="Close" />
      </ButtonBase>
    </Box>
  );
};

