import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, spacing }) => ({
  analyticsFiltersVariant: {
    width: 'fit-content',
    paddingLeft: spacing(2),

    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: 'fit-content',
    },

    '& > div > label': {
      position: 'relative',
      fontSize: 14,
      fontWeight: 400,
      height: 40,
      display: 'flex',
      alignItems: 'center',
      paddingTop: 6,
      background: 'none',
    },

    '& > div > div': {
      border: '1px solid transparent',
      width: 150,
      padding: '9px 7px',
      margin: 0,
      marginLeft: 4,
      fontSize: 14,
      color: '#1B74B4',
      background: 'none',
    },
  },
  valueChanged: {
    backgroundColor: '#E4F6FE',
  },
}));

export default useStyles;
