import { createSignature, sendNCALayerData } from 'api/requests/document-sign.api';
import { NCALAYER } from 'pages/Task/TaskForm/Eds/EdsConstants';

const getNCALayerClientParameters = ({ methodName, clientParameters }) => {
  return {
    version: NCALAYER.MODULE_VERSION,
    method: methodName,
    params: clientParameters
  };
};

const sendNCALayerDataRequest = parameters => {
  return new Promise(async (resolve, reject) => {
    try {
      const { result, status, message } = await sendNCALayerData(parameters);
      if (status === 0 && message === '') {
        resolve(result);
      }

      reject(message);
    } catch (e) {
      reject(e);
    }
  });
};

export const getP12ClientKeyObject = async ({
  isInternalSignEmployeeInitiator,
  edsBase64,
  edsPassword,
  isOCSPVerified = true,
  handleError = error => {}
}) => {
  if (isInternalSignEmployeeInitiator) {
    return {
      subject: ''
    };
  }

  try {
    const clientParameters = {
      p12: edsBase64,
      password: edsPassword,
      verifyOcsp: isOCSPVerified
    };

    const NCALayerClientParameters = getNCALayerClientParameters({
      methodName: NCALAYER.METHODS.PKCS12Info,
      clientParameters: clientParameters
    });

    return await sendNCALayerDataRequest(NCALayerClientParameters);
  } catch (e) {
    handleError(e);
  }
};

export const signDocumentWithTSP = async ({
  documentBase64,
  edsBase64,
  edsPassword,
  handleError = errorMessage => {}
}) => {
  try {
    const clientParameters = {
      raw: documentBase64,
      p12: edsBase64,
      password: edsPassword,
      createTsp: NCALAYER.IS_TSP_CREATING,
      tspInCms: NCALAYER.IS_TSP_IN_CMS
    };

    const NCALayerClientParameters = getNCALayerClientParameters({
      methodName: NCALAYER.METHODS.RAWSign,
      clientParameters: clientParameters
    });

    return await sendNCALayerDataRequest(NCALayerClientParameters);
  } catch (e) {
    handleError(e.response?.data);
  }
};

export const sendSignatureToParagraph = async ({
  isExternal = false,
  userModelId,
  formData,
  handleSuccess = () => {},
  handleError = errorMessage => {}
}) => {
  try {
    const params = isExternal ? { isExternal, userModelId } : { userModelId };
    await createSignature({
      params,
      formData
    });
    handleSuccess();
  } catch (e) {
    handleError(e.response?.data?.Message);
  }
};

