import React from 'react';
import { Box, Button, IconButton, Typography, Modal } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './DeleteDocumentConfirmationModal.useStyles';

type Props = {
  isOpen: boolean,
  onClose: () => void;
  onConfirm: () => void;
};

export const DeleteDocumentConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Modal open={isOpen}>
      <Box className={classes.modalBody}>
        <div className={classes.modalHeader}>
          <Typography className={classes.title}>
            {t('customProcesses.creationPage.deleteDocumentConfirmationModal.title')}
          </Typography>

          <IconButton onClick={onClose}>
            <CloseIcon className={classes.closeIcon}/>
          </IconButton>
        </div>

        <div className={classes.modalContent}>
          <Typography className={classes.description}>
            {t('customProcesses.creationPage.deleteDocumentConfirmationModal.description')}
          </Typography>
        </div>

        <div className={classes.modalFooter}>
          <Button color="secondary" variant="contained" onClick={onClose} className={classes.modalButton}>
            {t('comment_modal.button_cancel')}
          </Button>

          <Button color="primary" variant="contained" className={classes.modalButton}
                  onClick={() => {
                    onConfirm();
                    onClose();
                  }}>
            {t('customProcesses.creationPage.buttons.delete')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};


