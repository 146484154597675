import { makeStyles } from "@material-ui/core";

export default makeStyles(() => {
  return {
    root: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,

      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      background: "rgba(38, 40, 66, 0.40)",
      zIndex: 99
    },
    content: {
      display: "flex",
      flexDirection: "column",

      width: 500,
      maxHeight: 500,

      backgroundColor: "#fff",
      borderRadius: 12
    },
    header: {
      padding: "12px 16px",
      borderBottom: "1px solid rgba(38, 40, 66, 0.08)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      "& h4": {
        color:  "#262842",
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "20px"
      },
      "& p": {
        marginTop: 4,
        color: "#6D6E85",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "16px"
      }
    },
    body: {
      padding: "12px 16px",
      display: "flex",
      flexDirection: "column",
      gap: 12,
      overflow: "auto"
    },
    footer: {
      padding: "12px 16px",
      background: "#F7F8FC",
      borderTop: "1px solid rgba(38, 40, 66, 0.08)",
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,

      "& button": {
        display: "block",
        marginLeft: "auto"
      }
    },
    userInfoSm: {
      width: "fit-content",
      cursor: "pointer",
    },
    userInfoMd: {
      padding: "16px",
      width: 400,

      "& h6:first-child": {
        color: "#000",
        fontSize: 18,
        fontWeight: 500,

        "& + h6": {
          color: "#6D6E85",
          fontSize: 14,
          marginTop: 4
        }
      }
    },
    mailBlock: {
      marginTop: 12,
      display: "flex",
      gap: 8,

      "& img": {
        width: 16
      },
      "& p": {
        fontSize: 12,
      }
    }
  }
})
