import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  tabs: {
    minHeight: 'auto',
    padding: theme.spacing(0, 6),
    borderBottom: '1px solid #CED7DF',
  },
  tabIndicator: {
    display: 'block',
    backgroundColor: '#007994',
  },
  tab: {
    color: '#6D6E85',
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'none',
    padding: theme.spacing(2.5, 1),
    minHeight: 'auto',
    opacity: 1,
    minWidth: 40,
    '&.Mui-selected': {
      border: 'none',
      color: '#007994',
    },
    '& span': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      '& img': {
        marginBottom: '0 !important',
        marginRight: theme.spacing(2),
      },
    },
    '&.Mui-disabled': {
      opacity: 0.3,
    },
  },
  tabWithError: {
    '& span:after': {
      content: '""',
      display: 'block',
      backgroundColor: '#ED4C36',
      height: 6,
      width: 6,
      borderRadius: '50%',
      position: 'absolute',
      right: 3,
      top: 9,
    },
  },
  unreadTab: {
    '& span:after': {
      content: '""',
      width: 10,
      height: 10,
      position: 'absolute',
      top: 4,
      right: 0,
      borderRadius: '50%',
      backgroundColor: '#E94E3A',
      border: '2px solid #FFFFFF',
    }
  },
  unreadTabIcon: {
    '& span:after': {
      content: '""',
      width: 10,
      height: 10,
      position: 'absolute',
      top: 4,
      right: 0,
      borderRadius: '50%',
      backgroundColor: '#D6331F',
      border: '2px solid #FFFFFF',
    }
  },

}));
