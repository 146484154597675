import React, { memo, ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UserInfo } from 'components';

import { useUsersState } from 'store/users';
import { getUserDataByIdOrLogName } from 'utils/user';

import { UserType } from 'types';
import { useUserProfile } from 'hooks';
import { Tooltip } from '@material-ui/core';

type CreatorProps = {
  initiator?: string;
  companyId?: string;
  maxWidth?: number;
};

export const Creator = memo(
  ({ initiator, companyId, maxWidth }: CreatorProps): ReactElement => {
    const { t } = useTranslation();
    const { users } = useUsersState();
    const { companyName, companyLogo } = useUserProfile();

    const [user, setUser] = useState<UserType | null>(null);

    const getUserData = async (assigneeKey: string) => {
      const assigneeId = Object.keys(users).find((hcmsUserId: string) => {
        const { logName, id }: UserType = users[hcmsUserId];

        // Данные от creator-a могут приходить ввиде logName-a либо griffon id
        return [logName, id].includes(initiator);
      });

      if (assigneeId) {
        setUser(users[assigneeId]);
        return;
      }

      const userInfo = await getUserDataByIdOrLogName(assigneeKey, companyId);
      if (userInfo) {
        setUser(userInfo);
      }
    };

    useEffect(() => {
      if (!(initiator === 'company' || initiator === '${company}')) {
        getUserData(initiator);
      }
    }, [initiator, users]);

    if (user) {
      return (
        <Tooltip title={user?.fullName || user?.logName || `${user?.firstName} ${user?.lastName}`}>
          <span>
            <UserInfo user={user} showRole={false} avatarSize={24} maxWidth={maxWidth} nameMarginLeft={0} hideName />
          </span>
        </Tooltip>
      );
    }

    if (initiator === 'company' || initiator === '${company}') {
      return (
        <Tooltip title={companyName}>
          <span>
            <UserInfo
              user={{
                id: '',
                avatar: companyLogo,
                email: '',
                firstName: '',
                fullName: companyName,
                fullNameRu: '',
                lastName: '',
                phoneNumber: '',
                role: [''],
                logName: companyName,
                jobTitle: '',
              }}
              showRole={false}
              avatarSize={24}
              maxWidth={maxWidth}
              hideName
            />
          </span>
        </Tooltip>
      );
    }

    return <div>{t('task_data_view.employee_not_found')}</div>;
  }
);
