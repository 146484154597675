import React, {memo, ReactElement, useMemo} from 'react';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

import { RequestsListLayout } from 'layouts/RequestsListLayout';
import { Spinner, Ticket, ErrorMessage, NoContentMessage, CustomPagination } from 'components';

import useRequestsList from './useRequestsList';

import useStyles from "./RequestsList.useStyles";

type RequestsListProps = {
  completed: boolean
}

const RequestsList = memo(({ completed }: RequestsListProps): ReactElement => {
  const classes = useStyles();

  const { t } = useTranslation();
  const {
    myRequests,
    loading,
    error,
    currentPage,
    totalPages,
    handlePageChange,
    handleTaskSelection,
    selectedRequest,
    pageSize,
    handlePageSizeChange,
    totalElements
  } = useRequestsList(completed);

  const listContent = useMemo((): ReactElement => {
    if (!myRequests || !myRequests.length) {
      return null;
    }

    return (
      <Box paddingRight={0}>
        <List className={classes.list}>
          {myRequests.map((request) => (
            <ListItem key={request.businessTask.taskId} disableGutters dense>
              <Ticket
                type="myRequest"
                request={request}
                active={selectedRequest === request.businessTask.taskId}
                onClick={() => handleTaskSelection(request.businessTask.taskId)}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    );
    // eslint-disable-next-line
  }, [myRequests, selectedRequest]);

  if (loading) {
    return <Spinner absolute />;
  }

  if (error) {
    return <ErrorMessage text={t('errors.somethingIsWrong')} />;
  }

  if (myRequests?.length) {
    return (
       // <Box className={classes.requestsBox}>

         <RequestsListLayout
           footerChild={
             <>
               <Box display="flex" justifyContent="end" alignItems="center" gridGap={24}>
                 <Typography className={classes.tableFooterHint}>
                   <Trans
                     i18nKey="customProcesses.table.pagination"
                     values={{
                       from: (currentPage - 1) * pageSize + 1,
                       to: Math.min(pageSize * (currentPage), totalElements),
                       total: totalElements,
                     }}/>
                 </Typography>

                 {totalElements > 10 &&
                   <CustomPagination
                     pageSize={pageSize}
                     totalPages={totalPages}
                     currentPage={currentPage}
                     handlePageChange={handlePageChange}
                     handlePageSizeChange={handlePageSizeChange}
                   />
                 }
               </Box>
             </>
           }>
        {listContent}
         </RequestsListLayout>
      // </Box>
    );
  }

  return <Box className={classes.emptyBox}>
            <NoContentMessage type='requests' message={t("Processes.no_requests")} additionalMessage={t("Processes.send_request_instruction")} />
        </Box>;
});

export default RequestsList;
