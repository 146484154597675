import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Slide, Typography } from "@material-ui/core";
import { CloseButton, ErrorMessage, Spinner } from "components";
import useStyles from "./useStyles";
import { useTranslation } from "react-i18next";
import { TemplatesTableRow } from "pages/Templates/components/TemplatesTable/TemplatesTable.types";
import { ProcessContextType } from "types";
import ProcessContext from "contexts/ProcessContext";
import { getTemplateById } from "api/requests";
import { TemplateInfo } from './TemplateInfo';

type Props = {
  close: () => void
  id: string;
  closeTemplateListDialog?: () => void
}

export const TemplatePreview = ({close, id, closeTemplateListDialog}: Props) => {
  const [isOpened, setIsOpened] = useState(true);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TemplatesTableRow>(null);
  const { groups }: Partial<ProcessContextType> = useContext(ProcessContext);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState(null)

  const handleClose = (hasToCloseTemplateListDialog?: boolean) => {
    setIsOpened(false);

    if (hasToCloseTemplateListDialog){
      // wait first animation (setIsOpened) to finish
      setTimeout(() => {
        closeTemplateListDialog?.()
      }, 300)
    }
  }

  const getData = async (id: string) => {
    setIsLoading(true);

    try {
      const data = await getTemplateById(id);
      setData(data);
    } catch (error) {
      setError(t(error?.message))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData(id)
  }, [id])

  const templateInfo = useMemo<{
    processName: string
  }>(() => {
    if (!data) return null;
    let info = data.languages[0]
    const foundLanguage = data.languages.find(attr => attr.language === i18n.language)

    if (foundLanguage){
      info = foundLanguage;
    }

    return info
  }, [data])

  return <Slide
    in={isOpened}
    onExited={close} // set render condition to false
    direction="left"
    timeout={300}>
    <div className={classes.root}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <div className={classes.headerInfo}>
            {templateInfo && <>
              <Typography component="h6">
                <img src={groups.find(g => g?.sysName === data.category)?.iconPath} className={classes.groupIcon} alt=""/>
                {t(`groups.${data.category}.name`, { defaultValue: groups.find(g => g?.sysName === data.category)?.name })}
              </Typography>

              <Typography component="p">{templateInfo.processName}</Typography>
            </>}
          </div>

          <CloseButton onClick={() => handleClose()}/>
        </div>

        {data && <TemplateInfo
          data={data}
          handleClose={handleClose}
          setIsLoading={setIsLoading}
        />}

        {error && <ErrorMessage text={error} />}
        {isLoading && <Spinner absolute/>}

      </div>
    </div>
  </Slide>;
}
