export const FINAL_DOCUMENTS = {
  BLOCK_TITLE: 'eds.finalDocuments.title',
  NO_DOCUMENTS: 'eds.finalDocuments.noDocuments'
};

export const EDS_FORM = {
  BLOCK_TITLE: 'eds.edsForm.title',
  UPLOAD_BUTTON_LABEL: 'eds.edsForm.button',
  EDS_OPEN_MODAL_LABEL: 'eds.edsForm.modalButton',
  PASSWORD_STORAGE_LABEL: 'eds.edsForm.passwordStorage',
  LOADING_TEXT: 'eds.edsForm.loading'
};

export const KEYS = {
  AUTH: 'AUTH'
};

export const ERROR_MESSAGES = {
  EDS_NOT_BEFORE_KEY: 'eds.errors.notBeforeKey',
  EDS_NOT_AFTER_KEY: 'eds.errors.notAfterKey',
  EDS_NOT_AUTH_KEY: 'eds.errors.notAuthKey',

  ATTACHMENT_KEY_NOT_FOUND: 'eds.errors.attachmentKeyNotFound',
  GET_ATTACHMENT_INFO_ERROR: 'eds.errors.getAttachmentInfoError',
  GET_ATTACHMENT_FILE_ERROR: 'eds.errors.getAttachmentFileError',
  GET_ATTACHMENT_TOKEN_ERROR: 'eds.errors.getAttachmentTokenError',

  BIN_EMPTY: 'eds.errors.binEmpty',

  GET_PASSWORD_ERROR: 'eds.errors.getPasswordError',
  DOCUMENT_SIGNING_ERROR: 'eds.errors.documentSigningError',
  SIGNATURE_ENDPOINT_ERROR:
    'eds.errors.signatureEndpointError',
  ORGANIZATIONS_EMPTY: 'eds.errors.organizationsEmpty',
  FETCH_ORGANIZATION_ERROR: 'eds.errors.fetchOrganizationError',
  BIN_DOES_NOT_MATCH: "eds.errors.binDoesNotMatch",
  INVALID_PARAM_P12: "eds.errors.invalidParamP12"
};

export const INVALID_PARAM_P12_ERROR = 'Invalid parameter "p12". Error: PKCS12 key store mac invalid - wrong password or corrupted file.'

export const BUTTON_STATES = {
  LOADING: 'eds.buttonStates.loading',
  SIGN_WITH_EDS: 'eds.buttonStates.signWithEDS',
  DATA_PROCESSING: 'eds.buttonStates.dataProcessing',
  SIGNING_DOCUMENT: 'eds.buttonStates.signingDocument',
  SENDING_REQUEST: 'eds.buttonStates.sendingRequest'
};

export const NCALAYER = {
  METHODS: {
    PKCS12Info: 'PKCS12.info',
    RAWSign: 'RAW.sign'
  },
  MODULE_VERSION: '1.0',
  IS_TSP_CREATING: true,
  IS_TSP_IN_CMS: true
};

export const EDS_DEFAULT_ATTRIBUTES = {
  binAttribute: "bin",
  companyAttribute: "company",
  individualNumberAttribute: "individualNumber",
  emailAttribute: "email",
  supplierEmailAttribute: "SupplierEmail",
  existingSupplierAttribute: "existingSupplier",
  supplierBinAttribute: "supplierBin",
}
