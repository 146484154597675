import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing }) => ({
  root: {
    width: '100%',
    display: 'flex',
    padding: 12,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 12,
    border: '1px solid rgba(38, 40, 66, 0.12)',
    background: '#262842',
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
  },
  selectedCountText: {
    color: '#F7F8FC',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
  },
  label: {
    marginLeft: 0
  },
  group: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 12,
  },
  groupWithDivider: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      right: -12,
      top: -7,
      width: 1,
      height: 32,
      borderRight: '1px solid  rgba(255, 255, 255, 0.12)'
    }
  },
  button: {
    marginLeft: 0,
    borderRadius: 8,
    border: '1px solid rgba(255, 255, 255, 0.12) !important',
    background: '#FFFFFF14 !important',
    color: '#F7F8FC !important',
    '& svg path': {
      fill: '#F7F8FC !important',
    },

    '&:hover': {
      border: '1px solid #57596D !important',
      background: '#404259 !important',
    },
    '&:active': {
      border: '1px solid #57596D !important',
      background: '#404259 !important',
    }
  },
  buttonFocused: {
    border: '2px solid #7CCCDE !important',
    background: '#262842 !important',
  },
  buttonDisabled: {
    border: '1px solid rgba(255, 255, 255, 0.12) !important',
    background: '#262842 !important',
    color: '#6D6E85 !important',
    '& svg path': {
      fill: '#6D6E85 !important',
    }
  },
  iconButton: {
    padding: '6px !important',
    borderRadius: '8px !important',
    '& svg path': {
      fill: '#F7F8FC !important',
    }
  },
  btnIcon: {
    marginRight: 10,
  },
  buttons: {
    borderRadius: 16,
    '& button': {
      width: 'calc(100% / 3)'
    }
  },
  checkbox: {
    marginRight: 10,
    "& span": {
      color: "#262842 !important",
    },
    '& svg path': {
      fill: '#6D6E85 !important'
    },
    "&.Mui-checked svg path": {
      fill: '#007994 !important'
    }
  }
}));
