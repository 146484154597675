import { makeStyles } from '@material-ui/core';

export default makeStyles(({spacing}) => ({
  actionButton: {
    borderRadius: "50%",
    '&:hover': {
      borderColor: 'transparent !important',
      backgroundColor: 'transparent !important'
    }
  },
  actionButtonIcon: {
    '& > path': {
      fill: '#1B74B4 !important'
    }
  },

  tableCell: {
    padding: 8,
    overflow: 'hidden'
  },
  titleBox: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  title: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#262842',
    whiteSpace: "nowrap",

    "&:hover":{
      color: '#007994',
    }
  },
  titleIcon: {
    width: 28,
    height: 28,
  },
  groupTitle: {
    color: "#6D6E85",
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: '50%',
    backgroundColor: '#99A5B1',
    flexShrink: 0,
  },
  statusActive: {
    backgroundColor: '#2DB77B',
    flexShrink: 0,
  },
  statusTitle: {
    marginLeft: spacing(2),
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.3,
    color: '#101F2B'
  },
  statusSubtitle: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#8B8C9E',
  },
  avatar: {
    "h6": {
      marginTop: 10
    }
  },
  templateName: {
    display: 'flex',
    alignItems: 'center'
  },
  templateNameHeader: {
    // backgroundColor: '#E4F2FF',
    borderRadius: spacing(2),

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    height: spacing(7),
    width: spacing(7),
  },
  templateNameHeaderImage: {
    height: 28,
    width: 28
  },
  templateNameTitle: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#007994',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    "&:hover":{
      color: '#007994',
    }
  },
  templateNameSubtitle: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#8A96A1'
  },
  templateProcessNameInfo: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 8,
    flexGrow: 1,
    overflow: "hidden"
  },
  templateIntegrationsCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 4,
  },
  integrationChip: {
    padding: '4px 6px',
    borderRadius: 6,
    background: '#F0F0F7',
    color: '#262842',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    whiteSpace: 'nowrap',
  },
  integrationsTooltipContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 4,
    flexWrap: 'wrap',
    maxWidth: 400,
  },
  tooltip: {
    background: "#262842",
  },
  tooltipArrow: {
    color: "#262842"
  },
  integrationTooltip: {
    borderRadius: 8,
  },
}));
