import { makeStyles } from '@material-ui/core';
export default makeStyles(() => ({
  root: {
    height: 48,
    marginBottom: 8,
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    background: '#F7F8FC',
    border: '1px solid rgba(38, 40, 66, 0.08)',
    borderRadius: 10
  },
  selectedText: {
    marginRight: 22,
    fontSize: 14
  },
  btn: {
    color: "#007994"
  }
}))
