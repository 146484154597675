import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { NewAttachments } from 'components';

import useStyles from './useStyles';
import { VariantSelect } from 'components/Attachments/attachments-component/attachments-list/attachments-list.component';

type Props = {
  name: string;
  label: string;
  isReadOnly?: boolean;
  fileNumberLimit?: number;
  allowedTypes?: string;
  hideAllowedTypes?: boolean;
  folderId?: string;
  params?: { [key: string]: string };
  storage?: string;
  fieldRules?: { [key: string]: string };
  variant?: VariantSelect;
};

const processEvaluatedData = (data, getValues) => {
  const doubleCurlyBracesRegex = /{{(.+?)}}/g;
  if (doubleCurlyBracesRegex.test(data)) {
    // eslint-disable-next-line no-unused-vars
    const $attrsFormValue = getValues();
    // eslint-disable-next-line no-eval
    return eval(data.replace(doubleCurlyBracesRegex, '$1'));
  }
  return data;
};

export const Attachment = ({
  name,
  label,
  isReadOnly,
  fileNumberLimit,
  allowedTypes,
  hideAllowedTypes = false,
  params,
  storage,
  fieldRules,
  variant = VariantSelect.Big,
  folderId,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    setValue,
    watch,
    clearErrors,
    getValues,
    formState: { errors },
  } = useFormContext();

  const { groupTitle, groupParams } = params;

  const propertyFolderId = processEvaluatedData(folderId, getValues);

  const parsedGroupParams = useMemo(() => {
    if (!groupParams) {
      return [];
    }

    return groupParams.split('|').map((fieldString) => {
      const splittedFieldString = fieldString.split(':').map((v) => v.trim());
      const [fieldName, fieldLabel] = splittedFieldString;

      return {
        name: fieldName,
        label: fieldLabel
      };
    });
  }, [groupParams]);

  const watchGroupParams = watch(parsedGroupParams.map((item) => item.name), []);
  const watchName = watch(name, '');

  const allowedTypesString = useMemo(
    () =>
      ' ' + allowedTypes
        ? allowedTypes
            .split(',')
            .map((fileType) => `.${fileType.trim()}`)
            .join(', ')
        : '',
    [allowedTypes]
  );

  if (parsedGroupParams.length > 0) {
    const attachmentsPropsList = parsedGroupParams.map((item, i) => {
      return {
        name: item.name,
        label: item.label,
        storage,
        isReadOnly,
        fileNumberLimit,
        allowedTypes,
        showAllowedFileTypes: false,
        variant,
        formErrors: errors[item.name],
        fieldRules,
        updateValue: (newValue) => {
          setValue(item.name, newValue);},
        clearFormErrors: () => clearErrors(item.name),
      };
    });

    return (
      <>
        {groupTitle && (
          <Typography className={classes.groupTitle}>{groupTitle}</Typography>
        )}
        {allowedTypes && !hideAllowedTypes && (
          <Typography className={classes.groupFileTypes}>
            {t('form_components.attachment.permitted_files_label')}: {allowedTypesString}
          </Typography>
        )}
        {attachmentsPropsList.map((attachmentsProps, i) => (
          <NewAttachments {...attachmentsProps} value={watchGroupParams[i] ? watchGroupParams[i].toString().split(',').filter(Boolean) : []} />
        ))}
      </>
    );
  }

  const attachmentsProps = {
    name,
    label,
    storage,
    isReadOnly,
    fileNumberLimit,
    allowedTypes,
    showAllowedFileTypes: !hideAllowedTypes,
    variant,
    formErrors: errors[name],
    fieldRules,
    updateValue: (newValue) => setValue(name, newValue),
    clearFormErrors: () => clearErrors(name),
    folderId: propertyFolderId,
  };

  return <NewAttachments {...attachmentsProps} value={watchName.toString().split(',').filter(Boolean)}/>;
};
