import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  popover: {
    position: 'absolute',
    top: '50vh',
    left: '50vw',
  },
  content: {},
  message: {
    fontSize: 20,
    marginBottom: 16
  }
});

export default useStyles;
