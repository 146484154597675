import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, palette }) => ({
  defaultCursor: {
    cursor: 'default',
  },
  label: {
    minWidth: 'max-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: '#6D6E85'
  },
  infoIcon: {
    height: 20,
    width: 20,
    marginLeft: 4,
  },
  tooltipPopper: {
    pointerEvents: 'all',
  },
  helpTooltip: {
    width: 'fit-content',
    height: 'fit-content',
  },
  helpTooltipContent: {
    width: 'fit-content',
    height: 'fit-content',
  },
  executionTimeInput: {
    width: 120,
    '& > div': {
      border: '1px solid rgba(38, 40, 66, 0.12)',
      borderRadius: 10,
    },
  },
  processStepFormErrorMessage: {
    height: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: spacing(1),
    width: 'max-content',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#D6331F',
    '& img': {
      marginRight: spacing(1.25),
      height: 13,
      width: 13
    },
    '& span': {
      width: 'max-content'
    }
  }
}));
