import React from 'react';

import classNames from 'classnames';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import isArray from 'lodash/isArray';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import { Assignee } from 'components/TaskDetails/components/Assignee';
import { COMPONENTS_DATA_TYPES, DATA_TYPES } from './constants';
import { CURRENCY_SYMBOLS } from '../FormFields/Fields/ReadOnlyAmountAndCurrency/constants';
import { NewAttachments } from 'components';
import { useGlossaryState, useTaskState } from 'store/requests';
import { UserList as UserListComponent } from '../FormFields/Fields';
import GlossaryValue from './GlossaryValue';
import useStyles from './useStyles';

interface FormattedValuesProps {
  component: string;
  hint: string;
  name: string;
  params: { [key: string]: any };
  sysName: string;
  value: string | boolean | Date | string[];
  values: { [key: string]: string };
}

const FormattedValues = (props: FormattedValuesProps): JSX.Element => {
  const {value, values, name, hint, component, params, sysName} = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dataType = COMPONENTS_DATA_TYPES[component] || COMPONENTS_DATA_TYPES['default'];
  const { mapList: glossaryMap } = useGlossaryState();

  const { data: bpmTask } = useTaskState();

  if (!value) {
    return <Typography className={classNames(classes.textValueWrapper, classes.emptyValueWrapper)}>{t('form_components.readOnly.emptyValue')}</Typography>;
  }

  switch (dataType) {
    case DATA_TYPES.USER_LIST: {
      let transformedValue = value;

      if (Array.isArray(value)) {
        transformedValue = value.join();
      }
      return <UserListComponent
        placeholder={params?.placeholder}
        value={transformedValue as string}
        isPreview
        disabled={true}
        clearSelectedOnComponentUnmount={false}
      />;
    }
    case DATA_TYPES.TEXT:
      if (['radio-button-group', 'select'].includes(component)) {
        const valuesLocalizationKey = `constructor-${bpmTask.processSysName}.states.${bpmTask.stepSysName}.attributes.${sysName.replaceAll('::', '-')}.params.values`;
        const localizedValues = t(valuesLocalizationKey, { defaultValue: params.values });

        if (!localizedValues) {
          return <Typography className={classes.textValueWrapper}>{value}</Typography>;
        }

        const valuesArray = params.values.split(',');
        const localizedValuesArray = localizedValues.split(',');
        const localizedValue = valuesArray.indexOf(value) >= 0
                               ? valuesArray.indexOf(value) < localizedValuesArray.length
                                 ? localizedValuesArray[valuesArray.indexOf(value)]
                                 : value
                               : value;
        return <Typography className={classes.textValueWrapper}>{localizedValue}</Typography>;
      }

      if (['glossary', 'selectGlossary'].includes(component)) {
        if (component === 'selectGlossary' && params.saveAsId && params.glossaryIndexName) {
          const glossaryValues = glossaryMap[params.glossaryIndexName];
          const selectedItem = glossaryValues?.find(item => item.id === value);
          if (selectedItem) {
            const localizedValue = selectedItem.additionalFields?.[i18n.language] || selectedItem.value;
            return <Typography className={classes.textValueWrapper}>{localizedValue}</Typography>;
          }
        }

        if(params.multiSelect){
          return <GlossaryValue id={(value as string)?.split(',')?.[0] as string} count={(value as string)?.split(',')?.length}/>;
        }

        if (params.isAcceptedValueTypeId) {
          return <GlossaryValue id={value as string}/>;
        }

        const translationsEn = i18n.getResourceBundle('en', '');
        const translationsRu = i18n.getResourceBundle('ru', '');
        if (!(translationsRu && translationsEn)
          || !translationsEn.glossary || !translationsEn.glossary_1c
          || !translationsRu.glossary || !translationsRu.glossary_1c) {
          return <>{value}</>;
        }

        const glossariesKeys = Object.keys(translationsEn.glossary)
          .reduce((acc, key) => {
            return {
              ...acc,
              [translationsEn.glossary[key]]: key,
              [translationsRu.glossary[key]]: key,
            };
          }, {});

        const glossaries1cKeys = Object.keys(translationsEn.glossary_1c)
          .reduce((acc, key) => {
            return {
              ...acc,
              [translationsEn.glossary_1c[key]]: key,
              [translationsRu.glossary_1c[key]]: key,
            };
          }, {});

        if (params['useMultiSelect']) {
          // eslint-disable-next-line no-extra-boolean-cast
          return !!value ? (
            <div className={classes.multipleSelectValue}>
              {(isArray(value) ? value : (value as string).split('\n'))
                .map((item) => {
                  if (Object.keys(glossaries1cKeys).includes(item as string)) {
                    const glossaryValueKey = glossaries1cKeys[item as string];
                    return (<Typography className={classes.multipleSelectValueItem}>
                      {t(`glossary_1c.${glossaryValueKey}`, { defaultValue: item })}
                    </Typography>);
                  } else {
                    const glossaryValueKey = glossariesKeys[item as string];
                    return (<Typography className={classes.multipleSelectValueItem}>
                      {t(`glossary.${glossaryValueKey}`, { defaultValue: item })}
                    </Typography>);
                  }
                })}
            </div>
          ) : (<>'-'</>);
        }

        let translatedValue = '';
        if (Object.keys(glossaries1cKeys).includes(value as string)) {
          const glossaryValueKey = glossaries1cKeys[value as string];
          translatedValue = t(`glossary_1c.${glossaryValueKey}`, { defaultValue: value });
        } else {
          const glossaryValueKey = glossariesKeys[value as string];
          translatedValue = t(`glossary.${glossaryValueKey}`, { defaultValue: value });
        }

        return <Typography className={classes.textValueWrapper}>
          {translatedValue}
        </Typography>;
      }
      return <Typography className={classes.textValueWrapper}>{value}</Typography>;
    case DATA_TYPES.NUMBER:
      return (
        <>
          <NumberFormat
            value={value as string}
            displayType="text"
            thousandSeparator=" "
            decimalSeparator="."
            fixedDecimalScale={false}
            decimalScale={2} />
        </>
      );
    case DATA_TYPES.DATE:
      return <>{moment(value as string).format('DD.MM.YYYY')}</>
    case DATA_TYPES.ATTACHMENTS:
      if (!params.groupParams) {
        return (
          <NewAttachments
            name={name}
            allowedTypes=""
            value={(value as string).split(',').filter(Boolean)}
            isReadOnly
            isSummaryStepVariant={!!hint}
            updateValue={() => null}
          />
        );
      } else {
        const groupParamsLocalizationKey = `constructor-${bpmTask.processSysName}.states.${bpmTask.stepSysName}.attributes.${sysName.replaceAll('::', '-')}.params.groupParams`;
        const localizedGroupParams = t(groupParamsLocalizationKey, { defaultValue: params.groupParams });

        const parsedGroupParams = localizedGroupParams.split(',').map((v) => v.split(':').map((v) => v.trim()));

        return (
          <>
            {
              parsedGroupParams.map((item) => {
                const [attachName, attachLabel] = item;
                return (
                  <>
                    <td>{attachLabel}</td>
                    <td>
                      <NewAttachments
                        name={attachName}
                        allowedTypes=""
                        value={(values[attachName] as string).split(',').filter(Boolean)}
                        isReadOnly
                        isSummaryStepVariant={true}
                        updateValue={() => null}
                      />
                    </td>
                  </>
                );
              })
            }
          </>
        )
      }
    case DATA_TYPES.USER: {
      if (!value) {
        return <Typography>-</Typography>;
      }

      return (
        <Box className={classes.summaryUserWrapper}>
          {params?.multiple ? (
            (value as string[]).map((logName: string, key: number) => (
              <Box key={key} className={classes.summaryUser}>
                <Assignee assignee={logName}/>
              </Box>
            ))
          ) : (
             <Assignee assignee={value as string}/>
           )}
        </Box>
      );
    }
    case DATA_TYPES.BOOLEAN:
      return <>{value ? t('new_request.summary.boolean.true', { defaultValue: 'Yes' }) : '-'}</>;
    case DATA_TYPES.AMOUNT_AND_CURRENCY: {
      const currency = values[params['currencyFieldName']];
      const currencyValue = Object.keys(CURRENCY_SYMBOLS).includes(currency) ? CURRENCY_SYMBOLS[currency] : currency;
      return (
        <>
          {currencyValue !== currency && <>{currencyValue}</>}
          <NumberFormat
            value={value as string}
            displayType="text"
            thousandSeparator=" "
            decimalSeparator="."
            fixedDecimalScale={false}
            decimalScale={2}
          />
          {currencyValue === currency && <>{currencyValue}</>}
        </>
      );
    }
    default:
      return <>{value}</>;
  }

};

export default FormattedValues;
