import React, { ReactElement, useContext, useMemo } from 'react';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import { NoContentMessage } from 'components';
import { NotificationManager } from 'react-notifications';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Spinner,
  Ticket,
  ErrorMessage,
  CustomPagination,
} from 'components';

import useDraftsList from './useDraftsList';
import { toggleApprovalPanel, useVisiblePanel } from 'store/approvals';

import useApprovalsControl from 'pages/Approvals/hooks/useApprovalsControl';
import { ApprovalsControls, TButton } from 'pages/Approvals';

import useStyles from './DraftsList.useStyles';
import { SearchValueContext } from '../Requests';
import { RequestsListLayout } from '../../../layouts/RequestsListLayout';

export const DraftsList = (): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const visiblePanel = useVisiblePanel();
  const { value: searchText } = useContext(SearchValueContext);

  const {
    savedDrafts,
    currentPage,
    totalPages,
    totalElements,
    pageSize,
    error,
    loading,
    handlePageChange,
    handlePageSizeChange,
    handleDraftsMassDelete,
  } = useDraftsList({ searchText: searchText?.trim().length > 0 ? searchText : undefined });

  const {
    allAvailableTickets,
    selectedTickets,
    selectTickets,
    selectAllTickets,
    clearSelectedTickets,
    setMassApprove,
    isAllSelected,
    showControls,
    massLoading,
    setMassLoading,
  } = useApprovalsControl({ requests: savedDrafts, type: 'drafts' });

  const handleDelete = async (action): Promise<void> => {
    try {
      setMassLoading(action);
      await handleDraftsMassDelete(action, selectedTickets);

      const selectedTicketsNumber = Object.keys(selectedTickets).length || 1;
      NotificationManager.success(`${selectedTicketsNumber} ${t('notifications.draftsDeletedSuccessfully')}`);
      dispatch(toggleApprovalPanel());
    } finally {
      setMassLoading('');
    }
  };

  const buttonsList = useMemo((): TButton[] => [
    {
      action: 'Delete',
      title: t('buttons.button_delete_drafts'),
      color: 'primary',
      icon: null,
    },
  ], []);

  if (loading) {
    return <Box className={classes.root}>
      <Spinner absolute={false}/>
    </Box>;
  }

  if (error) {
    return <Box className={classes.emptyBox}>
      <ErrorMessage text={t('errors.somethingIsWrong')}/>
    </Box>;
  }

  if (savedDrafts.length) {
    return (
      <Box className={classes.root}>
        <>
          {visiblePanel && (
            <div className={classes.massActionsPanelWrapper}>
              <ApprovalsControls
                type="drafts"
                isDisabled={loading}
                loading={massLoading}
                isControlsShown={showControls}
                isAllSelected={isAllSelected}
                selectTickets={selectTickets}
                selectAllTickets={selectAllTickets}
                clearSelectedTickets={clearSelectedTickets}
                availableTicketsCount={allAvailableTickets?.length}
                selected={selectedTickets}
                buttonList={buttonsList}
                onClick={handleDelete}
              />
            </div>
          )}

          <RequestsListLayout
            footerChild={
              <>
                <Box display="flex" justifyContent="end" alignItems="center" gridGap={24}>
                  <Typography className={classes.tableFooterHint}>
                    <Trans
                      i18nKey="customProcesses.table.pagination"
                      values={{
                        from: (currentPage - 1) * pageSize + 1,
                        to: Math.min(pageSize * (currentPage), totalElements),
                        total: totalElements,
                      }}/>
                  </Typography>

                  {totalElements > 10 &&
                    <Box display="flex" justifyContent="end">
                      <CustomPagination
                        pageSize={pageSize}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        handlePageSizeChange={handlePageSizeChange}
                      />
                    </Box>
                  }
                </Box>
              </>
            }>
            <List>
              {savedDrafts.map((draft) => (
                <ListItem
                  key={draft.businessTask?.taskId}
                  disableGutters
                  style={{ padding: 0 }}
                >
                  <Ticket
                    type="draft"
                    request={draft}
                    selected={selectedTickets}
                    selectTicket={selectTickets.one}
                    setMassApprove={setMassApprove}
                    className={classes.ticket}
                  />
                </ListItem>
              ))}
            </List>
          </RequestsListLayout>
        </>
      </Box>
    );
  }

  if (savedDrafts.length === 0 && searchText?.trim().length > 0) {
    return <Box className={classes.emptyBox}>
      <NoContentMessage noResultsWithFilters/>
    </Box>;
  }

  if (savedDrafts.length === 0 && searchText?.trim().length === 0) {
    return <Box className={classes.emptyBox}>
      <NoContentMessage message={t('Processes.drafts_empty_list')}
                        additionalMessage={t('Processes.send_request_instruction')} type="requests"/>
    </Box>;
  }

  return null;
};

export default DraftsList;
