import React, { memo, ReactElement, useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { FLOW_BTN_CLASSNAME, ProcessItem } from './ProcessItem';

import { useBilling, useUserProfile } from 'hooks';
import { ProcessGroupType, ProcessType, UserType } from 'types';
import { AbortControllerContext } from 'pages/CreateProcessDialog';
import {
  openCreateDialogAction as openDialog,
  setCreateRequestAction as setCreateRequest,
  useProcessDialogState,
} from 'store/requests';
import { NotificationManager } from 'react-notifications';
import { getStartAllowedAnswer, getTemplateProcessBySysName, startProcessById } from 'api/requests';
import { UPGRADE_REDIRECT_PATH } from 'router/constants';
import { User } from 'models/User.model';

import useStyles from '../useStyles';
import { FlowPreview } from '../FlowPreview';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useUsersState } from 'store/users';
import { AlertModal } from 'components/Modal/AlertModal';
import WarningIcon from 'assets/images/icons/warning-sign.svg';
import { DM_CHECK_ASSIGNEE, PERFORMER_STEP_ASSIGNEE } from '../../../TemplateProcesses/TemplateProcesses.constants';

type ProcessesListProps = {
  data: ProcessGroupType & {
    processes: ProcessType[]
  },
  openRequestStartErrorDialog: (payload: {
    message: string,
    isAdmin: boolean,
    admins: {
      user: UserType,
      title: string
    }[]
  }) => void;
}

export const ProcessesList = memo(({
  data: {
    sysName,
    name,
    description,
    processes = [],
  },
  openRequestStartErrorDialog,
}: ProcessesListProps): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { companyId } = useUserProfile();
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  const open = useProcessDialogState();

  const [processData, setProcessData] = useState(null);
  const [isFlowPreview, setIsFlowPreview] = useState(false);
  const [hasError, setHasError] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const abortControllerContext = useContext(AbortControllerContext);
  const { hasInternalMemoProcessesAccess, isInternalMemoProcess } = useBilling();
  const { users } = useUsersState();
  const [loadingSysName, setLoadingSysName] = useState(null);
  const [loading, setLoading] = useState(false);

  const showFlow = async (process: ProcessType) => {
    setProcessData(null);
    setIsFlowPreview(false);
    setLoading(true);

    if (abortController) {
      abortController.abort();
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerContext?.setAbortController(controller);
    setAbortController(controller);

    try {
      const processData = await getTemplateProcessBySysName(process.processSysName, signal);
      setProcessData(processData);
      setIsFlowPreview(true);
    } catch (error) {
      setProcessData(null);
      if (controller.signal.aborted) return;
      NotificationManager.error(error?.message);
    } finally {
      setHasError(false);
      setLoading(false);
    }
  };

  const handleStartProcess = async (e: any, selectedProcess: any): Promise<void> => {
    // clear
    if (abortController) {
      abortController.abort();
    }

    const currentProcess = processes.find(p => p.processSysName === selectedProcess.processSysName);
    const { creator, camundaId, isTemplateProcess, id, processSysName, name, integration = '' } = currentProcess;

    setLoadingSysName(processSysName);

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerContext?.setAbortController(controller);
    setAbortController(controller);

    try {
      if (isInternalMemoProcess(selectedProcess?.processSysName)) {
        if (!hasInternalMemoProcessesAccess) {
          dispatch(openDialog(false));
          history.push(UPGRADE_REDIRECT_PATH);
        }
      }

      if (isTemplateProcess) {
        const processData = await getTemplateProcessBySysName(processSysName, signal);
        setProcessData(processData);

        const allAssignees = processData.steps.filter(step => step.stepOrder !== 1).map(step => step.assigneeId).flat();

        let hasError = false;
        for (const assignee of allAssignees) {
          if (![DM_CHECK_ASSIGNEE, PERFORMER_STEP_ASSIGNEE].includes(assignee) && !users[assignee]) {
            hasError = true;
            break;
          }
        }

        if (hasError) {
          setHasError(true);
          return;
        }
      } else {
        const { admin, message, startAllowed, admins } = await getStartAllowedAnswer(processSysName, signal);

        if (!startAllowed && openRequestStartErrorDialog) {
          const adminUsers = admins.map(adminData => {
            const roleTitle = adminData.role[0].toUpperCase() + adminData.role.substring(1).toLowerCase();
            return {
              user: User.from(adminData).data(),
              title: roleTitle,
            };
          });

          openRequestStartErrorDialog({ message, isAdmin: admin, admins: adminUsers });
          setLoadingSysName(null);
          return;
        }
      }

      const variables = {};
      if (integration) {
        variables['integration'] = {
          'type': 'String',
          'value': integration,
        };
      }

      const taskId = await startProcessById({
        params: {
          creator: creator,
          id: id || camundaId,
          ...(isTemplateProcess && { processTemplate: true }),
        },
        data: {
          processId: processSysName,
          processDefinitionName: name,
          variables
        },
        abortSignal: signal,
      });

      dispatch(
        setCreateRequest({
          id: taskId,
          name: name,
          sysName: processSysName,
          isNewRequest: true,
        }),
      );
    } catch (error) {
      if (controller.signal.aborted) return;
      setLoadingSysName(null);
      NotificationManager.error(error?.message || t('new_request.processes.errors.start_process_error'));
    }
  };

  useEffect(() => {
    setLoadingSysName(null);
    abortController?.abort();
  }, [processes]);

  useEffect(() => {
    setLoadingSysName(null);
  }, [hasError, isFlowPreview, open]);

  return (
    <>
      <Typography variant="h2" className={classes.sectionTitle}>
        {t(`groups.${sysName}.name`, { defaultValue: t(`groups.${companyId}_${sysName}.name`, { defaultValue: name }) })}
      </Typography>

      <Box mt={3}>
        <Typography variant="body1" className={classes.sectionSubtitle}>
          {t(`groups.${sysName}.description`, { defaultValue: t(`groups.${companyId}_${sysName}.description`, { defaultValue: description }) })}
        </Typography>
      </Box>

      <Box py={6} display="flex" flexDirection="column" alignItems="center">
        {processes.map((process: ProcessType) => (
          <ProcessItem
            key={process?.processSysName}
            process={process}
            handleShowFlowPreview={showFlow}
            handleStartProcess={handleStartProcess}
            loadingSysName={loadingSysName}
          />
        ))}
      </Box>

      {
        isFlowPreview && processData && <FlowPreview
          data={processData}
          onClose={() => {
            setIsFlowPreview(false);
            setProcessData(null);
            abortController.abort();
          }}
          handleStartProcess={handleStartProcess}
          loading={loadingSysName === processData.processSysName}
        />
      }

      {hasError && <AlertModal
        title={<Box display="flex" alignItems="center" gridGap={8}>
          <img src={WarningIcon} width={16} alt=""/>
          {t('errors.processStartIsNotAvailable')}
        </Box>}
        description={t('errors.contactTheWorkspaceAdmin')}
        close={() => {
          setHasError(false);
          setProcessData(null);
          setLoadingSysName(null);
          setLoading(false);
          abortController?.abort();
        }}
        additionalButtons={[
          {
            text: t('buttons.showFlow'),
            action: async () => {
              showFlow(processData);
            },
            loading,
          },
        ]}
      />}
    </>
  );
});
