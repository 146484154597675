import React, { memo, useState } from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';

import countriesList from './countriesList';

type Props = {
  name?: string;
  hint?: string;
}

export const Countries = memo(({ name, hint }: Props) => {
  const { control, formState: { errors }, setValue: setFormValue, watch } = useFormContext();

  const [value, setValue] = useState(watch(name) || null);
  const [inputValue, setInputValue] = useState(watch(name) || '');

  const handleChange = (_, val) => {
    setValue(val);
    setFormValue(name, val);
  };

  const handleInputChange = (_, val) => {
    setInputValue(val);
    setFormValue(name, value);
  };

  const renderInput = inputParams => (
    <Controller
      name={name}
      control={control}
      rules={{ required: 'Please, select a country' }}
      render={() => (
        <TextField
          {...inputParams}
          label={hint}
          inputProps={{...inputParams.inputProps, 'data-selenium': name}}
          error={!!errors[name]}
          helperText={!!errors[name] && errors[name].message}
        />
      )}
    />
  );

  return (
    <FormControl fullWidth>
      <Autocomplete
        fullWidth
        options={countriesList}
        value={value}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        renderInput={renderInput}
        forcePopupIcon={true}
      />
    </FormControl>
  );
}, (prevProps, nextProps) =>
  prevProps.name === nextProps.name &&
  prevProps.hint === nextProps.hint);
