import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing }) => ({
  mainHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 72,
    padding: '16px 24px 16px 0',
  },
  mainHeaderLeftContent: {
    marginLeft: 16,
    overflow: 'hidden',
    minWidth: 150,
  },
  taskIcon: {
    width: 16,
  },
  subheader: {
    height: 48,
    padding: '0 24px',
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    borderTop: '1px solid rgba(38, 40, 66, 0.08)',
    borderBottom: '1px solid rgba(38, 40, 66, 0.08)',
  },
  progressTitle: {
    fontSize: 12,
    fontWeight: 400,
    marginRight: 6,
  },
  applicationNumber: {
    color: '#1B74B4',
    margin: '0 8px',
  },
  copyLinkButton: {
    width: 13.3,
    marginLeft: spacing(1),
    marginRight: spacing(1),
    marginBottom: spacing(0.25),
  },
  priorityIcon: {
    width: 20,
    height: 20,
    marginLeft: 8,
    marginRight: 4,
  },
  cardTitle: {
    margin: 0,
    padding: 0,
    fontSize: 18,
    fontWeight: 500,
    color: '#333333',

    marginTop: 6,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  remainingTimeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 0,
    fontWeight: 500,
    fontSize: 14,
  },
  clockIcon: {
    height: 16,
    marginRight: 4,
  },
  clockTitle: {
    color: '#262842',
    marginRight: 8,
    fontWeight: 500,
  },
  clockTime: {
    fontStyle: 'normal',
    color: '#262842',
  },
  clockTimeOverdue: {
    color: '#D6331F',
  },
  statusIcon: {
    display: 'inline-block',
    padding: 5,
    marginLeft: '8px',
    marginRight: '4px',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
  },
  textEllipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  watcherEye: {
    alignItems: 'center',
    backgroundColor: '#F0F0F7',
    border: '1px solid #F0F0F7',
    borderRadius: 10,
    cursor: 'pointer',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    minHeight: 40,
    minWidth: 40,
    position: 'relative',
    width: 40,
  },
  watcherEye__active: {
    border: '1px solid #2628421F',
    backgroundColor: '#FFFFFF',
  },
  watcherEye__count: {
    alignItems: 'center',
    backgroundColor: '#8B8C9E',
    border: '2px solid #F7F8FC',
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    fontSize: 12,
    minHeight: 24,
    justifyContent: 'center',
    position: 'absolute',
    right: -9,
    top: -9,
    minWidth: 24,
  },
  watcherEye__count__active: {
    backgroundColor: '#E94E3A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  watcherEye__icon: {},
}));
