import React, { useState, Ref } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core';
import * as userUtils from 'utils/user';
import { UserType } from 'types';

type Props = {
  user: UserType;
  className?: string;
  avatarSize?: number;
  roundedSquare?: boolean;
};

const useStyles = makeStyles({
  root: {
    margin: '0',
    fontSize: (props: Props) => (props.avatarSize > 25 ? '16px' : '12px'),
    height: (props: Props) => props.avatarSize,
    width: (props: Props) => props.avatarSize,
    '&.MuiAvatar-colorDefault': {
      background: 'linear-gradient(180deg, #0ca9ea 0%, #59c7f4 97.92%)',
      color: '#fff',
      justifyContent: 'center',
    },
    borderRadius: (props: Props) => (props.roundedSquare ? 6 : '50%'),
  },
});

export const UserAvatar = React.memo(
  React.forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
    const { user, className, roundedSquare } = props;
    const [loaded, setLoaded] = useState(false);
    const classes = useStyles(props);

    return (
      <Avatar
        ref={ref}
        alt={user?.name}
        src={user?.avatar}
        classes={classes}
        component="div"
        className={className}
        variant={roundedSquare ? 'rounded' : 'circular'}
        imgProps={{ onLoad: () => setLoaded(true) }}
      >
        {!loaded && userUtils.extractLetters(user)}
      </Avatar>
    );
  })
);
