import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing }) => ({
  root: {
    background: '#F5F8FA',

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    border: '1 solid #E0E3EA',
    borderRadius: 4,
    padding: spacing(4.5)
  },
  title: {
    fontSize: 14,
    color: '#465564'
  },
  button: {
    height: 24,
    width: 24
  }
}));
