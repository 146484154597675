import React from 'react';
import { Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import cn from 'classnames';

import Attachments from './attachments-component';

import { useAttachments } from './hooks/useAttachments';
import { getMimeTypes, getErrorMessage } from './utils';

import useStyles from './useStyles';
import { VariantSelect } from './attachments-component/attachments-list/attachments-list.component';

type Props = {
  allowedTypes?: string;
  clearFormErrors?: () => void;
  fieldRules?: any;
  fileNumberLimit?: number;
  formErrors?: Record<string, unknown>;
  isReadOnly?: boolean;
  isSummaryStepVariant?: boolean;
  label?: string;
  name: string;
  showAllowedFileTypes?: boolean;
  storage?: string;
  updateValue?: (val: any) => void;
  value: string[] | number[];
  variant?: VariantSelect;
};

const AttachmentsComponent = ({
  allowedTypes,
  clearFormErrors,
  fieldRules,
  fileNumberLimit,
  formErrors,
  isReadOnly,
  isSummaryStepVariant = false,
  label,
  name,
  showAllowedFileTypes = true,
  storage = 'PS',
  updateValue,
  value,
  variant = VariantSelect.Big,
}: Props) => {
  const classes = useStyles();
  const { control } = useFormContext();

  const {
    attachments,
    handleAttachmentUpload,
    handleAttachmentRemoval,
    handleAttachmentRemovalUndoing,
    handleAttachmentClick,
    errorMessage,
    setErrorMessage,
  } = useAttachments({
    value,
    formErrors,
    updateValue,
    clearFormErrors,
    isReadOnly,
    storage,
  });

  const mimeTypes = getMimeTypes(allowedTypes);
  const allowedFileTypes = Array.from(allowedTypes.split(','));

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={fieldRules}
        render={() => (
          <input type="hidden" data-selenium={name} />
        )}
      />

      {label && (
        <Typography
          component="label"
          className={cn(classes.label, {
            [classes.labelSummaryStep]: isSummaryStepVariant,
          })}
        >
          {label}
        </Typography>
      )}

      <Attachments
        allowedFileMimeTypes={mimeTypes}
        allowedFileTypes={allowedFileTypes}
        attachments={attachments}
        attachmentsOnRightSide={isSummaryStepVariant}
        error={errorMessage}
        fileNumberLimit={fileNumberLimit}
        onClick={handleAttachmentClick}
        onDelete={handleAttachmentRemoval}
        onFileSelect={handleAttachmentUpload}
        onUndo={handleAttachmentRemovalUndoing}
        readOnly={isReadOnly}
        setError={setErrorMessage}
        showAllowedFileTypes={showAllowedFileTypes}
        variant={variant}
      />
    </>
  );
};

export const NewAttachments = React.memo(
  AttachmentsComponent,
  (prevProps, nextProps) =>
    prevProps.name === nextProps.name &&
    prevProps.label === nextProps.label &&
    prevProps.isReadOnly === nextProps.isReadOnly &&
    prevProps.allowedTypes === nextProps.allowedTypes &&
    prevProps.fileNumberLimit === nextProps.fileNumberLimit &&
    isEqual(prevProps.value, nextProps.value) &&
    getErrorMessage(prevProps.formErrors) === getErrorMessage(nextProps.formErrors)
);
