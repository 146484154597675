import React, { memo, useMemo, useCallback, ReactElement } from 'react';
import { Box, Button, Typography, Link } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { useTaskState } from 'store/requests';
import { setCustomModalParams } from 'store/main';

import useStyles from './useStyles';

type Props = {
  name: string;
  hint: string;
  params: {
    icon?: string;
    isIconConnected?: boolean;
    isIconOnLeftSide?: boolean;
    isModalConnected?: boolean;
    isLinkOnIcon?: boolean;
    dependentModalAttributeSysName?: string;
    hasCounter?: boolean;
    countedAttributeId?: string;
    isTabConnected?: boolean;
    targetTabName?: string;
    showCustomValue?: boolean;
    displayValue?: string;
  };
  setActiveTab: (tabName: string) => void;
  isTaskDetailsVariant?: boolean;
};

export const LinkField = memo(({
  name,
  hint,
  params: {
    icon,
    isIconConnected = false,
    isIconOnLeftSide = false,
    isModalConnected = false,
    isLinkOnIcon = false,
    dependentModalAttributeSysName,
    hasCounter = false,
    countedAttributeId,
    isTabConnected = false,
    targetTabName,
    showCustomValue = false,
    displayValue = ''
  },
  setActiveTab,
  isTaskDetailsVariant = false,
}: Props): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, watch } = useFormContext();

  const { data: bpmTask } = useTaskState();

  const value = watch(name);

  const counterValue = useMemo(() => {
    if (!hasCounter || !countedAttributeId) return null;

    const countedAttribute = bpmTask.getAttributeBySysName(countedAttributeId.toString());

    if (!countedAttribute) return null;

    const counterAttributeName = countedAttribute?.name;
    const counterAttributeValue = watch(counterAttributeName);

    const parsedAttributeValue = counterAttributeValue
      ? typeof counterAttributeValue === 'string'
        ? counterAttributeValue?.split(',')
        : (counterAttributeValue as any[])
      : [];

    const parsedValueLength = parsedAttributeValue?.filter((v) => !!v)?.length;

    return parsedValueLength;
  }, [hasCounter, countedAttributeId]);

  const isIconExists = useMemo(() => icon && isIconConnected, [
    icon,
    isIconConnected,
  ]);

  const isModalExists = useMemo(
    () => isModalConnected && dependentModalAttributeSysName,
    [isModalConnected, dependentModalAttributeSysName]
  );

  const handleModalAction = useCallback(() => {
    isModalExists &&
      dispatch(
        setCustomModalParams({
          open: true,
          sysName: dependentModalAttributeSysName,
        })
      );
  }, [dispatch, dependentModalAttributeSysName, isModalExists]);

  const isTabConnectionExist = useMemo(() => isTabConnected && targetTabName, [
    isTabConnected,
    targetTabName,
  ]);

  const handleTabAction = useCallback(() => {
    isTabConnectionExist &&
      setActiveTab &&
      setActiveTab(targetTabName.toLowerCase());
  }, [isTabConnectionExist, setActiveTab]);

  if (isIconExists) {
    const linkWrapperClasses = cn({
      [classes.linkWrapper]: true,
      [classes.linkWrapperReverse]: isIconOnLeftSide,
      [classes.taskDetailsVariantWrapper]: isTaskDetailsVariant,
    });

    const buttonClass = cn({
      [classes.button]: true,
      [classes.buttonNoCursor]: !isModalExists || !isLinkOnIcon,
      [classes.buttonReverse]: isIconOnLeftSide,
    });

    return (
      <div>
        <Typography>{hint}</Typography>
        <Box className={linkWrapperClasses}>
          {isLinkOnIcon ? (
            <Typography component="span" className={classes.linkWithIconText}>
              {showCustomValue ? displayValue : value}
            </Typography>
          ) : (
            <Link href={showCustomValue ? displayValue : value} target="_blank">
              {showCustomValue ? displayValue : value}
            </Link>
          )}

          <Button
            variant="text"
            className={buttonClass}
            onClick={isLinkOnIcon && handleModalAction}
          >
            <img src={icon} className={classes.icon} alt="" />
          </Button>
        </Box>

        <input type="hidden" data-selenium={name} {...register(name)} />
      </div>
    );
  }

  if (isTabConnected) {
    return (
      <div>
        <Typography>{hint}</Typography>
        <Typography className={classes.linkText} onClick={handleTabAction}>
          {hasCounter && (
            <Typography component="span" className={classes.counter}>
              {counterValue}
            </Typography>
          )}
          {showCustomValue ? displayValue : value}
        </Typography>
        <input type="hidden" data-selenium={name} {...register(name)} />
      </div>
    );
  }

  return (
    <>
      <Typography>{hint}</Typography>
      <Link className={classes.link} href={showCustomValue ? displayValue : value} target="_blank">
        {showCustomValue ? displayValue : value}
      </Link>
      <input type="hidden" data-selenium={name} {...register(name)} />
    </>
  );
});
