import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing, palette }) => ({
  DocumentsTab: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    width: 410,
    maxWidth: 410,
  },
  header: {
    marginBottom: 16,
  },
  title: {
    color: '#262842',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    margin: 0,
    padding: 0,
    marginBottom: 8,
  },
  description: {
    color: '#6D6E85',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    margin: 0,
    padding: 0,
  },
}));
