import React from 'react';
import {
  Dialog,
  DialogContent,
} from '@mui/material';

import { EdsForm } from '../EdsForm';

type Props = {
  isInternalSign: boolean;
  isExternalSign: boolean;
  isInternalSignWithEmployee: boolean;
  isModalOpen: boolean;
  labelValue: string;
  attachmentName: string;
  attachmentStorage: string;
  binAttribute: string;
  companyAttribute: string;
  individualNumberAttribute: string;
  emailAttribute: string;
  supplierEmailAttribute: string;
  existingSupplierAttribute: string;
  supplierBinAttribute: string;
  userModelId: string;
  handleModalState: (state: boolean) => void;
  onClick: () => void;
};

export const EdsModal = ({
  isModalOpen,
  isInternalSign,
  isExternalSign,
  isInternalSignWithEmployee,
  labelValue,
  attachmentName,
  attachmentStorage,
  binAttribute,
  companyAttribute,
  individualNumberAttribute,
  emailAttribute,
  supplierEmailAttribute,
  existingSupplierAttribute,
  supplierBinAttribute,
  userModelId,
  handleModalState,
  onClick,
}: Props) => {

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => handleModalState(false)}
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown>
      <DialogContent>
        <EdsForm
          userModelId={userModelId}
          attachmentName={attachmentName}
          attachmentStorage={attachmentStorage}
          binAttribute={binAttribute}
          companyAttribute={companyAttribute}
          individualNumberAttribute={individualNumberAttribute}
          emailAttribute={emailAttribute}
          supplierEmailAttribute={supplierEmailAttribute}
          existingSupplierAttribute={existingSupplierAttribute}
          supplierBinAttribute={supplierBinAttribute}
          labelValue={labelValue}
          isInternalSign={isInternalSign}
          isExternalSign={isExternalSign}
          isInternalSignWithEmployee={isInternalSignWithEmployee}
          onClick={onClick}
          handleModalState={handleModalState}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EdsModal;
