import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  summaryStepWrapper: {
    marginLeft: theme.spacing(7.5),
  },
  group: {
    position: 'relative',
    width: '100%',
    minWidth: 405,
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      width: 405,
    },
    '& h5': {
      fontWeight: 'bold',
    },
  },
  table: {
    width: '100%',
    borderSpacing: 0,
    marginTop: theme.spacing(4),
    '& td': {
      verticalAlign: 'top',
      width: '50%',
      color: theme.palette.grey[600],
      fontWeight: 500,
      padding: theme.spacing(2, 0),
      '&:last-child': {
        textAlign: 'right',
        color: theme.palette.grey[500],
      },
    },
  },
  documentsTable: {
    width: '100%',
    borderSpacing: 0,
    marginTop: theme.spacing(4),
    '& td': {
      verticalAlign: 'top',
      color: theme.palette.grey[600],
      fontWeight: 500,
      padding: theme.spacing(2, 0),
    },
  },
  multipleSelectValue: {
    textAlign: 'left',
  },
  multipleSelectValueItem: {
    marginBottom: theme.spacing(2),
  },
  summaryUserWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  summaryUser: {
    '&:not(:first-child)': {
      marginTop: theme.spacing(1),
    },
  },
  textValueWrapper: {
    whiteSpace: 'pre-wrap'
  },
  emptyValueWrapper: {
    color: '#8B8C9E',
  },
  documentTableList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}));
