import { useCallback, useState } from 'react';
import sortBy from 'lodash/sortBy';
import { v4 as uuid } from 'uuid';

import { ProcessStep } from '../../../TemplateProcesses.types';
import { MAX_FIELDS_PER_PROCESS } from '../../../TemplateProcesses.constants';
import { COMPONENTS_PARAMS, FIELDS_DEFAULT_PARAMS } from '../../ProcessSetupSidePanel/ProcessSetupSidePanel.constants';
import { SavingType } from '../../StepPreviewSavingVariantSelectionDialog';

export const useStepFieldsActions = ({
  fields,
  conditions,
  parallelBranchesGroups,
  documents,
  currentProcessIndex,
  setIsSubmitAvailable,
  focusedStepFieldId,
  selectedStepField,
  allProcessStepsFields,
  summaryFields,
  languages,
  update,
  replace,
  setSelectedStepField,
  setFocusedStepFieldId,
  setFocusedDocumentId,
  setIsNewField,
  setConditions,
  setDocuments,
  setSummaryFields,
  handleMaximumFieldsDialogOpen,
  handleDeleteDialogOpen,
  handleCardPreviewSavingTypeDialogOpen,
  handleHidePreviewFieldConfirmationModalOpen,
  setCurrentTypeSummaryFields
}) => {
  const [selectedPreviewFields, setSelectedPreviewFields] = useState<string[]>([])
  const [selectedFieldForVisibilityChange, setSelectedFieldForVisibilityChange] = useState(null);

  const handleStepFieldsAdd = (fieldKeys: string[], order: number, fieldNames = null, fieldPlaceholders = null, documentId = '', focusOnField = true): string[] => {
    if (allProcessStepsFields.length + fieldKeys.length >= MAX_FIELDS_PER_PROCESS) {
      handleMaximumFieldsDialogOpen();
      return;
    }

    setIsSubmitAvailable(true);

    const fieldsIds = fieldKeys.map(fieldKey => fieldKey + '-' + uuid());

    const processFields = fields as unknown as ProcessStep[];
    const modifiedProcessFields = processFields.map((processField, index) => {
      if (index < currentProcessIndex) {
        return processField;
      }

      let isStepFieldHidden = false;

      if (processFields[currentProcessIndex].isParallelBranchesGroupStep || processFields[currentProcessIndex].isConditionBranchStep) {
        isStepFieldHidden = true;
      }

      if (processField?.isConditionBranchStep) {
        const branchCondition = conditions.find(c => [...(c?.hasPositiveBranch
                                                          ? c?.positiveBranch
                                                          : []), ...(c?.hasNegativeBranch
                                                                     ? c?.negativeBranch
                                                                     : [])].includes(currentProcessIndex + 1));
        const stepCondition = conditions.find(c => [...(c?.hasPositiveBranch
                                                        ? c?.positiveBranch
                                                        : []), ...(c?.hasNegativeBranch
                                                                   ? c?.negativeBranch
                                                                   : [])].includes(processField.stepOrder));
        if (branchCondition && stepCondition && stepCondition?.id === branchCondition?.id) {
          // Шаг того же условия что и текущий шаг, но другая ветка -> пропуск (поле добавлять не надо)
          const isCurrentStepPositiveBranchStep = branchCondition.positiveBranch.includes(currentProcessIndex + 1);
          const currentStepPositiveBranchPosition = isCurrentStepPositiveBranchStep
                                                    ? branchCondition.positiveBranch.findIndex(v => v === currentProcessIndex + 1)
                                                    : branchCondition.negativeBranch.findIndex(v => v === currentProcessIndex + 1);
          const isProcessStepPositiveBranchStep = branchCondition.positiveBranch.includes(processField.stepOrder);
          const processStepPositiveBranchPosition = isProcessStepPositiveBranchStep
                                                    ? branchCondition.positiveBranch.findIndex(v => v === processField.stepOrder)
                                                    : branchCondition.negativeBranch.findIndex(v => v === processField.stepOrder);
          if (isCurrentStepPositiveBranchStep !== isProcessStepPositiveBranchStep || (isProcessStepPositiveBranchStep === isCurrentStepPositiveBranchStep && currentStepPositiveBranchPosition > processStepPositiveBranchPosition)) {
            return processField;
          } else {
            isStepFieldHidden = false;
          }
        }
      }

      if (processField?.isParallelBranchesGroupStep) {
        const branchParallelBranchesGroup = parallelBranchesGroups.find(group => group?.steps?.flat()?.includes(currentProcessIndex + 1));
        const fieldParallelBranchesGroup = parallelBranchesGroups.find(group => group?.steps?.flat()?.includes(processField.stepOrder));
        if (branchParallelBranchesGroup && fieldParallelBranchesGroup && branchParallelBranchesGroup?.id === fieldParallelBranchesGroup?.id) {
          const currentStepBranchIndex = branchParallelBranchesGroup?.steps?.findIndex(b => b.includes(currentProcessIndex + 1));
          const currentStepBranchStepIndex = branchParallelBranchesGroup[currentStepBranchIndex]?.findIndex(v => v === currentProcessIndex + 1);
          const processFieldStepBranchIndex = fieldParallelBranchesGroup?.steps?.findIndex(b => b.includes(processField.stepOrder));
          const processFieldStepBranchStepIndex = fieldParallelBranchesGroup[processFieldStepBranchIndex]?.findIndex(v => v === processField.stepOrder);
          if (currentStepBranchIndex !== processFieldStepBranchIndex || (currentStepBranchIndex === processFieldStepBranchIndex && currentStepBranchStepIndex > processFieldStepBranchStepIndex)) {
            return processField;
          } else {
            isStepFieldHidden = false;
          }
        }
      }

      const newStepFields = fieldsIds.map((fieldId, index) => ({
        id: fieldId,
        component: fieldKeys[index],
        isEditable: true,
        isRequired: true,
        isHidden: isStepFieldHidden,
        hint: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
        placeholder: languages.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {}),
        params: COMPONENTS_PARAMS[fieldKeys[index]] || {},
        documentId,
        order: order + index,
      }));

      if (fieldNames) {
        for (let i = 0; i < fieldNames.length; i++) {
          newStepFields[i].hint[languages[0]] = fieldNames[i];
        }
      }
      if (fieldPlaceholders) {
        for (let i = 0; i < fieldPlaceholders.length; i++) {
          newStepFields[i].placeholder[languages[0]] = fieldPlaceholders[i];
        }
      }

      const newFieldsCount = newStepFields.length;

      return {
        ...processField,
        fields: [
          ...processField.fields.slice(0, order - 1),
          ...newStepFields.map((newStepField) => (
            {
              ...newStepField,
              isRequired: index === currentProcessIndex,
              isEditable: index === currentProcessIndex,
              ...(FIELDS_DEFAULT_PARAMS[newStepField.component] || {}),
            }
          )),
          ...processField.fields.slice(order - 1)
            .map((field, index) => ({
              ...field,
              order: order + newFieldsCount + index,
            })),
        ],
      };
    });
    console.log('modifiedProcessFields', modifiedProcessFields);
    replace(modifiedProcessFields);
    if (focusOnField) {
      setFocusedStepFieldId(() => fieldsIds[0]);
      setFocusedDocumentId('');
      setIsNewField(() => true);
    }
    return fieldsIds;
  };

  const handleStepFieldsMove = (oldPosition: number, newPosition: number): void => {
    if (oldPosition === newPosition) {
      return;
    }

    setIsSubmitAvailable(true);

    const processFields = fields as unknown as ProcessStep[];
    const stepFields = processFields[currentProcessIndex].fields;
    const activeFields = stepFields.filter(field => !field?.isHidden);
    const hiddenFields = stepFields.filter(field => field?.isHidden);
    const movedField = activeFields[oldPosition];
    const fieldAtNewPosition = activeFields[newPosition];
    movedField.order = fieldAtNewPosition.order;

    const delta = Math.abs(oldPosition - newPosition);
    const filteredStepFields = activeFields.filter((v, index) => index !== oldPosition);

    const modifiedFieldsFrom = Math.min(oldPosition, newPosition);
    const modifiedFieldsTo = modifiedFieldsFrom + delta - 1;
    const skippedActiveStepFields = filteredStepFields.filter((v, index) => index >= modifiedFieldsFrom && index <= modifiedFieldsTo);

    const fieldsBeforeModifiedPart = filteredStepFields.slice(0, modifiedFieldsFrom);
    const fieldsAfterModifiedPart = filteredStepFields.slice(modifiedFieldsTo + 1);
    const modifiedFieldsPart = oldPosition < newPosition
                               ? [...skippedActiveStepFields, movedField]
                               : [movedField, ...skippedActiveStepFields];

    const newActiveFields = [...fieldsBeforeModifiedPart, ...modifiedFieldsPart, ...fieldsAfterModifiedPart];
    let pointer = 0;
    for (let i = 1; i <= stepFields.length; i++) {
      if (!hiddenFields.find(field => field.order === i)) {
        newActiveFields[pointer].order = i;
        pointer++;
      }
    }

    const newStepFields = sortBy([...newActiveFields, ...hiddenFields], v => v.order);

    update(currentProcessIndex, {
      ...processFields[currentProcessIndex],
      fields: newStepFields,
    });
  };

  const toggleStepFieldVisibilityWithOptionalConfirmation = (field) => {
    const processFields = fields as unknown as ProcessStep[];
    const stepPreviewFields = processFields[currentProcessIndex].summaryFields;
    if (stepPreviewFields.includes(field.id) && !field.isHidden) {
      setSelectedFieldForVisibilityChange(field);
      handleHidePreviewFieldConfirmationModalOpen();
    } else {
      toggleStepFieldVisibility(field);
    }
  }

  const handleToggleSelectedFieldVisibilityConfirmation = () => {
    toggleStepFieldVisibility(selectedFieldForVisibilityChange);
  }

  const toggleStepFieldVisibility = (field) => {
    const processFields = fields as unknown as ProcessStep[];
    setIsSubmitAvailable(true);

    const newFieldValue = {
      ...field,
      isHidden: !field.isHidden,
    };

    update(currentProcessIndex, {
      ...processFields[currentProcessIndex],
      summaryFields: newFieldValue.isHidden
        ? processFields[currentProcessIndex].summaryFields.filter(v => v !== field.id)
        : processFields[currentProcessIndex].summaryFields,
      fields: [
        ...processFields[currentProcessIndex].fields.slice(0, field.order - 1),
        {
          ...newFieldValue,
          order: processFields[currentProcessIndex].fields[field.order - 1].order,
        },
        ...processFields[currentProcessIndex].fields.slice(field.order),
      ],
    });
  };

  const handleStepFieldValuesChange = (newFieldValue, propagateToAllSteps = false) => {
    const processFields = fields as unknown as ProcessStep[];
    setIsSubmitAvailable(true);

    if (propagateToAllSteps) {
      const modifiedProcessFields = processFields.map(processField => {
        const fieldFromThisStep = processField.fields.find(field => field.id === newFieldValue.id);
        if (!fieldFromThisStep) {
          return processField;
        }

        const modifiedFieldFromThisStep = {
          ...fieldFromThisStep,
          hint: newFieldValue.hint,
          placeholder: newFieldValue.placeholder,
          // NOTE - если в будущем появятся параметры которые могут быть разными на разных шагах, логику надо будет менять
          params: newFieldValue.params,
        };

        return {
          ...processField,
          fields: [
            ...processField.fields.slice(0, modifiedFieldFromThisStep.order - 1),
            modifiedFieldFromThisStep,
            ...processField.fields.slice(modifiedFieldFromThisStep.order),
          ],
        };
      });
      replace(modifiedProcessFields);
    } else {
      update(currentProcessIndex, {
        ...processFields[currentProcessIndex],
        fields: [
          ...processFields[currentProcessIndex].fields.slice(0, newFieldValue.order - 1),
          {
            ...newFieldValue,
            order: processFields[currentProcessIndex].fields[newFieldValue.order - 1].order,
          },
          ...processFields[currentProcessIndex].fields.slice(newFieldValue.order),
        ],
      });
    }
  };

  const handleStepFieldDeleteStart = useCallback((fieldId) => {
    setSelectedStepField(fieldId);
    handleDeleteDialogOpen();
  }, [setSelectedStepField]);

  const handleStepFieldDeleteConfirmation = useCallback((fieldsIds = []) => {
    let deletedFields = fieldsIds;
    if (deletedFields.length === 0) {
      deletedFields = [selectedStepField];
    }
    const deletedFieldsCount = deletedFields.length;

    const documentsWithDeletedFields = documents
      .filter(doc => doc.fields.some(field => deletedFields.includes(field.processFieldId)))
      .map(doc => doc.uniqueId);

    setDocuments(documents => documents.map(doc => ({
      ...doc,
      fields: doc.fields.map(field => ({
          ...field,
        processFieldId: deletedFields.includes(field.processFieldId) ? '' : field.processFieldId
        })),
      signatureSteps: documentsWithDeletedFields.includes(doc.uniqueId) ? [] : doc.signatureSteps
    })));

    setIsSubmitAvailable(true);
    if (focusedStepFieldId === selectedStepField) {
      setFocusedStepFieldId('');
    }
    const processFields = fields as unknown as ProcessStep[];
    const stepFields = processFields[currentProcessIndex].fields;
    const firstDeletedField = stepFields?.find(field => field.id === deletedFields[0]);

    const modifiedProcessFields = processFields.map((processField, index) => {
      if (index < currentProcessIndex) {
        return processField;
      }

      return {
        ...processField,
        fields: processField.fields.filter(field => !deletedFields.includes(field.id))
          .map(field => field.order < firstDeletedField.order ? field : { ...field, order: field.order - deletedFieldsCount }),
        summaryFields: processField.summaryFields.filter(id => !deletedFields.includes(id))
      };
    });
    replace(modifiedProcessFields);

    setSelectedStepField('');

    const modifiedConditions = conditions.map(c => {
      if (deletedFields.includes(c.condition.field)) {
        c.condition.field = '';
        c.condition.type = '';
        c.condition.value = '';
      }
      return c;
    });
    setConditions(() => modifiedConditions);
  }, [selectedStepField, allProcessStepsFields, setIsSubmitAvailable, conditions]);

  const handleProcessPreviewSavingStart = useCallback((previewFields: string[]) => {
    setSelectedPreviewFields(previewFields);
    handleCardPreviewSavingTypeDialogOpen()
  }, [setSelectedPreviewFields])

  const updateStepPreview = useCallback((previewSavingType: SavingType) => {
    setIsSubmitAvailable(true);
    const processFields = fields as unknown as ProcessStep[];
    const modifiedProcessFields = processFields.map((processField, index) => {
      if (index < currentProcessIndex) {
        return processField;
      }

      if (previewSavingType === 'current') {
        if (index === currentProcessIndex) {
          setCurrentTypeSummaryFields(prev => [...prev.filter(id => id !== processField.id), processField.id]);
          return {
            ...processField,
            summaryFields: selectedPreviewFields,
          };
        }

        return processField;
      }

      const stepPreviewFields = selectedPreviewFields.filter(fieldId => !processField.fields.find(field => field.id === fieldId)?.isHidden);

      if (processField?.isConditionBranchStep) {
        const branchCondition = conditions.find(c => [
          ...(c?.hasPositiveBranch
            ? c?.positiveBranch
            : []),
          ...(c?.hasNegativeBranch
            ? c?.negativeBranch
            : [])
        ].includes(currentProcessIndex + 1));
        const stepCondition = conditions.find(c => [
          ...(c?.hasPositiveBranch
            ? c?.positiveBranch
            : []),
          ...(c?.hasNegativeBranch
            ? c?.negativeBranch
            : [])
        ].includes(processField.stepOrder));
        if (branchCondition && stepCondition && stepCondition?.id === branchCondition?.id) {
          // Шаг того же условия что и текущий шаг, но другая ветка -> пропуск (превью не применяется на этот шаг)
          const isCurrentStepPositiveBranchStep = branchCondition.positiveBranch.includes(currentProcessIndex + 1);
          const currentStepPositiveBranchPosition = isCurrentStepPositiveBranchStep
            ? branchCondition.positiveBranch.findIndex(v => v === currentProcessIndex + 1)
            : branchCondition.negativeBranch.findIndex(v => v === currentProcessIndex + 1);
          const isProcessStepPositiveBranchStep = branchCondition.positiveBranch.includes(processField.stepOrder);
          const processStepPositiveBranchPosition = isProcessStepPositiveBranchStep
            ? branchCondition.positiveBranch.findIndex(v => v === processField.stepOrder)
            : branchCondition.negativeBranch.findIndex(v => v === processField.stepOrder);
          if (isCurrentStepPositiveBranchStep !== isProcessStepPositiveBranchStep || (isProcessStepPositiveBranchStep === isCurrentStepPositiveBranchStep && currentStepPositiveBranchPosition > processStepPositiveBranchPosition)) {
            return processField;
          } else {
            return {
              ...processField,
              summaryFields: stepPreviewFields,
            };
          }
        }
      }

      if (processField?.isParallelBranchesGroupStep) {
        const branchParallelBranchesGroup = parallelBranchesGroups.find(group => group?.steps?.flat()?.includes(currentProcessIndex + 1));
        const fieldParallelBranchesGroup = parallelBranchesGroups.find(group => group?.steps?.flat()?.includes(processField.stepOrder));
        if (branchParallelBranchesGroup && fieldParallelBranchesGroup && branchParallelBranchesGroup?.id === fieldParallelBranchesGroup?.id) {
          const currentStepBranchIndex = branchParallelBranchesGroup?.steps?.findIndex(b => b.includes(currentProcessIndex + 1));
          const currentStepBranchStepIndex = branchParallelBranchesGroup[currentStepBranchIndex]?.findIndex(v => v === currentProcessIndex + 1);
          const processFieldStepBranchIndex = fieldParallelBranchesGroup?.steps?.findIndex(b => b.includes(processField.stepOrder));
          const processFieldStepBranchStepIndex = fieldParallelBranchesGroup[processFieldStepBranchIndex]?.findIndex(v => v === processField.stepOrder);
          if (currentStepBranchIndex !== processFieldStepBranchIndex || (currentStepBranchIndex === processFieldStepBranchIndex && currentStepBranchStepIndex > processFieldStepBranchStepIndex)) {
            return processField;
          } else {
            return {
              ...processField,
              summaryFields: stepPreviewFields,
            };
          }
        }
      }

      return {
        ...processField,
        summaryFields: stepPreviewFields,
      };
    });

    if(previewSavingType === 'currentAndFuture') {
      const currentAndFutureStepIds = processFields.map(process => process?.id);
      setCurrentTypeSummaryFields(prev => prev.filter(id => !currentAndFutureStepIds.includes(id)));
    }

    replace(modifiedProcessFields);
  }, [currentProcessIndex, fields, selectedPreviewFields]);

  return {
    handleProcessPreviewSavingStart,
    updateStepPreview,
    handleStepFieldDeleteConfirmation,
    handleStepFieldDeleteStart,
    handleStepFieldsAdd,
    handleStepFieldsMove,
    handleStepFieldValuesChange,
    toggleStepFieldVisibility,
    toggleStepFieldVisibilityWithOptionalConfirmation,
    handleToggleSelectedFieldVisibilityConfirmation,
  };
};
