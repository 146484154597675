import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing }) => ({
  label: {
    display: 'inline-block',
    position: 'relative',
    fontWeight: 500,
    marginBottom: spacing(1),
    marginTop: spacing(2),
  },
  labelSummaryStep: {
    width: '100%',
    textAlign: 'left',
    marginTop: 0,
  },
}));
