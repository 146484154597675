import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
    boxShadow: `${theme.palette.grey[200]} 0 0 0 1px`,
    position: 'relative',
    marginBottom: theme.spacing(4),
    maxWidth: 666,
    width: '100%',
    borderRadius: theme.spacing(2),
    background: theme.palette.grey[100],
    transition: '.3s',
    '&:hover': {
      boxShadow: `${theme.palette.primary[200]} 0 0 0 2px`
    }
  },
  disabled: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    background: 'rgba(255,255,255, .5)'
  },
  errorText: {
    fontSize: 12,
    color: theme.palette.error.main
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: "16px",
      '& h4': {
        fontSize: 20
      }
    }
  },
  actions: {
    padding: theme.spacing(4, 0)
  },
  img: {
    width: 40,
    height: 40,
    flexShrink: 0,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginRight: theme.spacing(4),
    padding: 0,
    '& img': {
      display: 'block',
      width: "100%",
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%'
    }
  },
  button: {
    backgroundColor: '#465564',
    border: 'none',
    '&:hover': {
      backgroundColor: '#333'
    }
  },
  title: {
    fontSize: 16,
    marginBottom: theme.spacing(2)
  },
  caption: {
    fontSize: 12
  },
  flowBtn: {
    padding: "10px 16px",
    height: 40,
    flexShrink: 0,

    "& img": {
      marginRight: 8
    }
  }
}));

export default useStyles;
