import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({palette, spacing}) => ({
  root: {
    height: "auto",
    background: "#137149 ",
    color: "#FFFFFF",
    boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.04)",
    borderRadius: 12,
    marginTop: 8,
    padding: "12px 16px",
    fontSize: 14,
  },
  closeIcon: {
    height: 14,
    width: 14,
    marginLeft: spacing(6)
  }
}));

export default useStyles;
