import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  multipleItem: {
    marginBottom: '10px',
  },
  userList: {
    display: 'block !important',
  },
  emptyValueMessage: {
    fontSize: 14,
    color: '#8B8C9E',
    fontStyle: 'normal',
    fontWeight: 400,
  },
}));

export default useStyles;
