import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({spacing, palette}) => ({
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  card: {
    position: 'relative',
    margin: 0,
    borderRadius: spacing(1),
    cursor: 'pointer',
    width: '100%',
    padding: spacing(3, 4, 6),
    boxShadow: `${palette.grey[200]} 0 0 0 1px`,
    transition: '.3s',
    '&:hover': {
      boxShadow: `${palette.primary[200]} 0 0 0 2px`,
    },
  },
  header: {
    color: palette.grey[500],
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(2),
  },
  img: {
    width: spacing(7),
    height: spacing(7),
    marginRight: spacing(2),
    '& img': {
      width: '100%',
      objectFit: 'contain',
    },
  },
  caption: {
    paddingLeft: spacing(9),
  },
  processName: {
    color: '#FFB256',
  },
  deleteIcon: {
    position: 'absolute',
    top: spacing(2.5),
    right: spacing(2),
    width: 26,
    height: 26,
    '&:hover': {
      borderColor: '#F5F8FA',
      borderRadius: '50%',
      backgroundColor: '#F5F8FA',
    }
  },
}));

export default useStyles;
