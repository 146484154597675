import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette, spacing }) => ({
  pageSizeLabel: {
    marginRight: spacing(2),
    color: '#6D6E85'
  },
  pagination: {
    marginLeft: 12,

    '& ul li div, & ul li button': {
      height: 40,
      width: 40,
      border: "1px solid rgba(38, 40, 66, 0.12)",
      borderRadius: 10,

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: palette.grey[700],
    },

    '& ul li div.Mui-selected, & ul li button.Mui-selected': {
      backgroundColor: "rgba(38, 40, 66, 0.08);",
      color: "#262842"
    },

    '& ul li div:hover, & ul li button:hover': {
      backgroundColor: palette.grey[200],
      color: palette.grey[700],
    },
  },
  select: {
    border: "1px solid rgba(38, 40, 66, 0.12)",
    borderRadius: 10,
  },
  chevron: {
    pointerEvents: 'none',
    position: 'absolute',
    right: '7px'
  }
}));
