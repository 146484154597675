import { makeStyles } from '@material-ui/core';
import { white } from 'theme/palette';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  CommentsTab: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginLeft: -24,
  },
  commentsListWrapper: {
    flex: 1,
    height: 'auto',
  },
  commentInputWrapper: {
    paddingRight: spacing(4),
    paddingLeft: spacing(4),
    paddingBottom: spacing(4),
    width: '100%',
  },
  commentsSection: {
    position: 'relative',
  },
  commentInput: {
    width: '100%',
    height: '60px',
    outline: '0px solid transparent',
    overflowY: 'auto',
    margin: 0,
    padding: 0,
    '&[contentEditable=true]:empty:before': {
      content: 'attr(placeholder)',
      display: 'block',
      color: '#aaa',
    },
  },
  usersList: {
    width: 300,
    height: 'auto',
    overflowY: 'hidden',
    overflowX: 'hidden',
    position: 'absolute',
    bottom: 120,
    backgroundColor: white,
    borderRadius: 8,
    paddingBottom: 0,
    zIndex: 300,
    boxShadow: '0px 8px 16px 0px #2628421A',
  },
  usersListItem: {
    padding: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
    '&:focus': {
      backgroundColor: '#EBEBEB',
    },
    '&.selectedListItem': {
      backgroundColor: '#ddd',
    },
  },
  commentItem: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 8,
    '&:last-child': {
      paddingBottom: spacing(5),
    },
  },
  comment: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    color: palette.grey[600],
    maxWidth: 800,
    marginRight: spacing(4),
    '& p, & li': {
      margin: 0,
      marginTop: spacing(-4),
    },
    '& ul, ol': {
      marginTop: spacing(-2),
      marginBottom: spacing(-2),
      paddingLeft: spacing(6),
    },
  },
  commentDate: {
    color: palette.grey[500],
    fontSize: 12,
    paddingTop: 3,
  },
  commentsList: {
    marginRight: spacing(2),
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  commentInfoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  processStep: {
    fontSize: 12,
    fontWeight: 400,
    color: palette.grey[500],
  },
  commentedAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 0,
    height: 20,
    padding: spacing(0, 1),
    marginLeft: spacing(2),
    borderRadius: 4,

    '& img': {
      height: 8,
      width: 'auto',
      marginRight: spacing(1),
    },

    '& span': {
      fontSize: 12,
      fontWeight: 400,
    },
  },
  commentedActionRed: {
    backgroundColor: palette.error[100],
    '& span': {
      color: palette.error[600],
    },
  },
  commentedActionBlue: {
    backgroundColor: '#E9F5FE',
    '& span': {
      color: '#1B74B4',
    },
  },
  commentedActionGreen: {
    backgroundColor: palette.success[100],
    '& span': {
      color: palette.success[400],
    },
  },
  dateGroupsDelimiter: {
    display: 'table',
    whiteSpace: 'nowrap',
    width: '100%',
    textAlign: 'center',
    color: palette.grey[500],
    marginTop: spacing(6),
    marginBottom: spacing(2),

    '&:before, &:after': {
      borderTop: `1px solid ${palette.grey[300]}`,
      content: '""',
      display: 'table-cell',
      position: 'relative',
      top: '0.5em',
      width: '50%',
    },
    '&:before': { right: '1.5%' },
    '&:after': { left: '1.5%' },
  },

  commentEditorWrapper: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [breakpoints.up(1440)]: {
      marginLeft: 0,
      marginRight: 0,
    },
    border: '1px solid  rgba(38, 40, 66, 0.12)',
    borderRadius: 12,
    background: '#fff',
  },
  commentEditor: {
    minHeight: 16,
    maxHeight: 200,
    overflowY: 'auto',
    marginTop: spacing(3),
    marginBottom: spacing(3),
    marginLeft: spacing(4),
    marginRight: spacing(4),
    fontWeight: 400,
    color: palette.grey[700],

    '& .public-DraftEditorPlaceholder-root': {
      position: 'absolute',
      userSelect: 'none',
      pointerEvents: 'none',
      color: palette.grey[400],
    },
    '& .public-DraftStyleDefault-ul': {
      padding: 'revert',
    },
    '& .public-DraftStyleDefault-ol': {
      padding: 'revert',
    },
  },
  editorControls: {
    height: 56,
    marginLeft: spacing(4),
    marginRight: spacing(4),
    borderTop: `1px solid ${palette.grey[300]}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editorControlsLeft: {
    display: 'flex',
    flexDirection: 'row',
  },
  editorControlsRight: {},
  controlButtonsGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 24,
  },
  editorControlsButton: {
    width: 24,
    height: 24,
    marginRight: spacing(2),
    '&:last-child': {
      marginRight: 0,
    },

    '&:hover': {
      borderRadius: '2px !important',
      backgroundColor: `${palette.grey[200]} !important`,
    },
  },
  noCommentsBlock: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#6D6E85',

    '& h3': {
      marginTop: 16,
      marginBottom: 4,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '20px',
    },

    '& p': {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '18px',
    },
  },
  mentionButton: {
    width: 24,
    height: 24,
    border: 'none !important',
    '&:hover': {
      borderRadius: '2px !important',
      backgroundColor: `${palette.grey[200]} !important`,
    },
    '& img': {
      width: 24,
      height: 24,
    },
  },
}));
