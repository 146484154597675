import React, { useEffect, useState } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import arrowDownloadRegularIcon from 'assets/images/icons/arrow_download_regular.svg';
import arrowDownloadRegularGreyIcon from 'assets/images/icons/arrow_download_regular_grey.svg';
import deleteIconImage from 'assets/images/icons/delete-attachment.svg';
import { FileViewProps } from 'attachments-types';
import { limitStringLength } from 'utils/user';

import { Styled } from './attachment-item/attachment-item.styles';

const FileView = (props: FileViewProps) => {
  const {
    assignee,
    attachmentIcon,
    creationDate,
    fileSize,
    handleDownloadFile,
    handleDelete,
    isLoading,
    openAttachmentPreview,
    readOnly,
    step,
    title,
    progress,
  } = props;
  const { t } = useTranslation();

  const [userDownloading, setUserDownloading] = useState(false);
  const [userViewing, setUserViewing] = useState(false);
  const [userUploading, setUserUploading] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setUserDownloading(false);
      setUserViewing(false);
      setUserUploading(false);
    } else {
      if (!userDownloading && !userViewing) {
        setUserUploading(true);
      }
    }
  }, [isLoading, userDownloading, userViewing]);

  const viewAction = () => {
    openAttachmentPreview();
    setUserViewing(true);
  };

  const downloadAction = () => {
    handleDownloadFile();
    setUserDownloading(true);
    setUserViewing(false);
  };

  return (
    <div className="ContentWrapper">
      {isLoading ? (
        <div className="fileViewLoader">
          <CircularProgress size={25} className="Spinner" variant={progress ? 'determinate' : 'indeterminate'} value={progress} />
        </div>
      ) : (
        <img className="AttachmentIcon" src={attachmentIcon} alt="attachment icon" />
      )}

      <div className="AttachmentItemInfo">
        <div className={cn('AttachmentItemInfoTop', { AttachmentItemInfoTopOldAttachment: !creationDate })}>
          <span className="AttachmentItemInfoTitle" onClick={openAttachmentPreview}>
            {title}
          </span>
          {creationDate && (
            <span className="AttachmentItemInfoDateSize">
              {fileSize ? `${fileSize} • ` : ''}
              {moment(creationDate).format('DD.MM.YYYY, HH:mm')}
            </span>
          )}
        </div>

        {!!step && (
          <span className="AttachmentItemInfoText">
            <b>{t('form_components.attachment.step')}:</b> {step}
          </span>
        )}

        {!!assignee && (
          <span className="AttachmentItemInfoText" title={assignee}>
            <b>{t('form_components.attachment.assignee')}:</b> {limitStringLength(assignee, 25)}
          </span>
        )}
      </div>

      <div className="FileActions">
        <Button className="DownloadButton" variant="contained" color="secondary" onClick={downloadAction} disabled={userUploading}>
          <img
            className="DownloadButton__icon"
            src={userUploading ? arrowDownloadRegularGreyIcon : arrowDownloadRegularIcon}
            alt="arrow download regular icon"
          />
        </Button>

        <Button className="ViewButton" variant="contained" color="secondary" onClick={viewAction} disabled={userUploading}>
          {t('fileAttachments.view')}
        </Button>

        <Styled.ControlsWrapper className={cn({ ControlsWrapperOldAttachment: !creationDate })}>
          {!readOnly && (
            <Styled.DeleteIcon>
              <img src={deleteIconImage} alt="delete icon" onClick={handleDelete} />
            </Styled.DeleteIcon>
          )}
        </Styled.ControlsWrapper>
      </div>
    </div>
  );
};

export default FileView;
