import React, { FC, ReactElement } from 'react';

import { Box, List, ListItem, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';

import { CustomPagination, Ticket } from 'components';
import { TaskRequestType } from 'types';
import { RequestsListLayout } from '../../../layouts/RequestsListLayout';

import useStyles from './index.useStyles';

interface ApprovalsListProps {
  currentPage: number;
  handlePageChange: (_: any, page: number) => void;
  handlePageSizeChange: (event: any) => void;
  handleTaskSelection: (taskId: string, reviewedCurrentTaskId?: string | null) => void;
  list: TaskRequestType[];
  pageSize: number;
  reviewed?: boolean;
  selected: { [key: string]: any };
  selectedTaskId: string;
  setMassApprove: (ticketData: any) => void;
  selectTickets: {
    one: (ticketData: any) => void;
    all: () => void;
  };
  totalElements: number;
  totalPages: number;
};

export interface MonitoringListProps
  extends Omit<ApprovalsListProps, "reviewed" | "selected" | "selectedTaskId" | "setMassApprove" | "selectTickets"> {
    handleTaskSelection: (taskId: string) => void;
}

export const ApprovalsList: FC<ApprovalsListProps> = ({
  currentPage,
  handlePageChange,
  handlePageSizeChange,
  handleTaskSelection,
  list,
  pageSize,
  reviewed = false,
  selected,
  selectedTaskId,
  selectTickets,
  setMassApprove,
  totalElements,
  totalPages,
}): ReactElement => {
  const classes = useStyles();

  return (
    <RequestsListLayout
      footerChild={
        <>
          <Box display="flex" justifyContent="end" alignItems="center" gridGap={24}>
            <Typography className={classes.tableFooterHint}>
              <Trans
                i18nKey="customProcesses.table.pagination"
                values={{
                  from: (currentPage - 1) * pageSize + 1,
                  to: Math.min(pageSize * (currentPage), totalElements),
                  total: totalElements,
                }}/>
            </Typography>

            {totalElements > 10 &&
              <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                pageSize={pageSize}
                handlePageSizeChange={handlePageSizeChange}
              />
            }
          </Box>
        </>
      }>
      <List style={{ padding: 0 }}>
        {list.map((approval: TaskRequestType) => (
          <ListItem
            key={approval?.approvedTaskId || approval?.businessTask.taskId}
            disableGutters
            style={{ padding: 0 }}>
            <Ticket
              type={reviewed ? 'reviewedApproval' : 'approval'}
              request={approval}
              selected={selected}
              selectTicket={selectTickets.one}
              active={reviewed
                      ? selectedTaskId === approval.approvedTaskId
                      : selectedTaskId === approval.businessTask.taskId}
              setMassApprove={setMassApprove}
              onClick={() => handleTaskSelection(reviewed
                                                 ? approval.approvedTaskId
                                                 : approval.businessTask.taskId, reviewed
                                                                                 ? approval?.businessTask?.taskId
                                                                                 : '')
              }
            />
          </ListItem>
        ))}
      </List>
    </RequestsListLayout>
  );
};
