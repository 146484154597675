import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing, palette }) => ({
  wrapper: {
    position: 'relative',
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: spacing(10),

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '50%',

      height: 40,
      borderLeft: '1px dashed #A9A9B8',
    },

    '& > $processStepAddStep': {
      position: 'absolute',
      top: 8,
      left: 'calc(50% - 11px)',
      marginLeft: -0.5,
      zIndex: 1,

      display: 'block',
      height: 24,
      width: 24,

      backgroundColor: '#FFFFFF !important',
      border: '1px solid rgba(38, 40, 66, 0.12) !important',
      borderRadius: '50% !important',
      transition: '0.3s background-color ease',

      '&:hover': {
        backgroundColor: '#F5F8FA !important',
      },

      '&:focus': {
        backgroundColor: '#E6ECF1 !important',
      },
    },
  },
  wrapperError: {
    marginBottom: -6,
  },
  wrapperAddButtonNotShown: {
    paddingTop: '0 !important',
  },
  wrapperWithoutAddButton: {
    paddingTop: '20px !important',
  },
  wrapperWithoutLines: {
    '&:before, &:after': {
      display: 'none !important',
    },
  },
  processStepAddStep: {
    display: 'none',
  },
  processStepAddStepIcon: {
    height: 12,
    width: 12,
    // marginLeft: -0.5,
    // marginTop: -0.5
  },
  processStepCard: {
    background: '#FFFFFF',
    border: '1px solid rgba(38, 40, 66, 0.12)',
    borderRadius: 12,
    padding: spacing(4, 5, 4.5, 5),
    width: 280,
    position: 'relative',
    zIndex: 1,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',

    cursor: 'pointer',
    transition: '0.3s border-color ease, 0.3s box-shadow ease',

    '&:hover': {
      border: '1px solid #007994',
    },
  },
  processStepCardActive: {
    borderColor: '#007994',
    borderWidth: '2px !important',
  },
  processStepCardError: {
    borderColor: '#D6331F',
    borderWidth: 2,

    '&:hover': {
      border: '2px solid #D6331F',
    },
  },
  processStepCardErrorMessage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: spacing(1),
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#D6331F',
    position: 'relative',
    zIndex: 10,
    '& img': {
      marginRight: spacing(1.25),
      height: 13,
      width: 13,
    },
  },
  processStepCardTopRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
  },
  processStepCardTagsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    justifyContent: 'flex-start',
    gap: 8,
  },
  processStepCardTitle: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#262842',
    minWidth: 'fit-content',
  },
  processStepCardName: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.3,
    color: '#262842',
  },
  processStepCardNamePlaceholder: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#8B8C9E',
  },
  processStepCardText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#262842',
  },
  processStepCardTextEmpty: {
    color: '#8B8C9E',
  },
  processStepCardIcon: {
    height: 20,
    width: 20,
    '& > path': {
      fill: '#6D7C8B !important',
    },
  },
  processStepCardIconInTemplatePreview: {
    width: 16,
  },
  processStepCardAssigneeBlockWrapper: {
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',

    '& $processStepCardTitle': {
      color: '#6D6E85',
    },
  },
  processStepCardAssigneeBlockWrapperForFlowPreview: {
    flexWrap: 'wrap',

    '& $processStepCardAssigneeInfoWrapper': {
      paddingLeft: 0,
      paddingTop: 8,
    },
    '& $processStepCardTitle': {
      color: '#6D6E85',
    },
    '& $processStepAssigneeWrapper': {
      flexDirection: 'column',
      gap: 8,
    },
  },
  processStepCardAssigneeInfoWrapper: {
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  parallelAssigneesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#DEF7FC66',
    borderRadius: 8,
    width: 'calc(100% + 12px)',
    maxWidth: 'calc(100% + 12px)',
    overflow: 'hidden',
    padding: 8,
    marginLeft: -8,
    marginTop: 10,
  },
  parallelAssigneesItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 10,
    maxWidth: '100%',
    overflow: 'hidden',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  parallelAssigneesItemUser: {
    overflow: 'hidden',
  },
  parallelAssigneesTitle: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: '#6D6E85',
    marginBottom: 10,
    minWidth: 'fit-content',
  },
  processStepAssigneeWrapper: {
    display: 'flex',
  },
  processStepAssigneeCount: {
    color: '#101F2B',
    fontSize: 12,
    fontWeight: 500,
    marginLeft: spacing(2),
    paddingRight: spacing(2),
    paddingTop: spacing(1),
  },
  processStepAssigneesTooltipPopper: {
    pointerEvents: 'all',
  },
  processStepAssigneesTooltip: {
    maxHeight: 180,
    padding: 10,
    fontSize: 12,
    fontWeight: 500,
    color: palette.grey[50],
  },
  tooltipContent: {
    height: 160,
    width: 160,
    maxHeight: 160,
    maxWidth: 160,
    overflowY: 'auto',
    '& ul': {
      margin: 0,
      padding: 0,
      paddingLeft: 20,
    },
  },
  showAllAssigneeBtn: {
    width: 'max-content',
    fontSize: 12,
    fontWeight: 500,
    color: '#007994',
  },
  usersDeactivatedInfo: {
    marginTop: 8,
    display: 'flex',
    color: '#D6331F',
    fontSize: 12,
    fontWeight: 400,

    '& img': {
      marginRight: 4,
    },
  },
}));
