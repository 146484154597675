import React, { memo, ReactElement, useState } from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';

type Props = {
  name?: string;
  hint?: string;
  options: {[key: string]: string}[];
  localizedOptions: {label: string, value: string}[];
  isActive?: boolean;
}

export const Select = memo(({ name, hint, options, localizedOptions, isActive }: Props): ReactElement => {
  const { t } = useTranslation();
  const { control, formState: { errors }, setValue: setFormValue, watch, trigger } = useFormContext();

  const [value, setValue] = useState<string | null>(watch(name) || null);
  const [inputValue, setInputValue] = useState<string>(watch(name) || '');

  const handleChange = (_, val: string): void => {
    setValue(val);
    setFormValue(name, val);

    if (errors[name]) {
      trigger(name);
    }
  };

  const handleInputChange = (_, val: string): void => {
    setInputValue(val);
    setFormValue(name, value);

    if (errors[name]) {
      trigger(name);
    }
  };

  const isValueFromOptions = (value: string): boolean | string =>
    !!options.find((option) => {
      const optionValue = typeof option === 'string' ? option : option?.value;
      return optionValue.toLowerCase() === value.toLowerCase();
    }) || t('form_components.validation_errors.listOption') as string;

  const renderInput = inputParams => (
    <Controller
      name={name}
      control={control}
      rules={{
        required: t('form_components.validation_errors.listOption') as string,
        validate: isValueFromOptions
    }}
      render={() => (
        <TextField
          {...inputParams}
          label={hint}
          inputProps={{...inputParams.inputProps, 'data-selenium': name}}
          error={!!errors[name]}
          helperText={!!errors[name] && errors[name].message}
        />
      )}
    />
  );

  const getOptionLabel = (option) => {
    const optionValue = typeof option === 'string' ? option : option?.value;
    const localizedOption = localizedOptions.find(({value}) => value === optionValue);
    return localizedOption?.label || optionValue || '';
  }

  return (
    <FormControl fullWidth>
      <Autocomplete
        fullWidth
        forcePopupIcon
        value={value}
        options={options}
        disabled={!isActive}
        inputValue={inputValue}
        onChange={handleChange}
        onInputChange={handleInputChange}
        getOptionLabel={getOptionLabel}
        renderInput={renderInput}
      />
    </FormControl>
  );
});
