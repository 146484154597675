import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useRequestActiveStep, useSkippedTaskSteps, setActiveStep, useTaskSteps, createAfterSubmitAction } from 'store/requests';

export default (formMethods, _saveDraft: () => Promise<unknown>) => {
  const dispatch = useDispatch();
  const { activeStep } = useRequestActiveStep();
  const { skippedSteps } = useSkippedTaskSteps();
  const taskSteps = useTaskSteps();

  const validateStep = (errors: { [key: string]: unknown; }, attrs: { name?: string; }[]): boolean => {
    let stepValid = true;
    for (const attr of attrs) {
      if (attr?.name && errors[attr.name]) {
        stepValid = false;
        break;
      }
    }
    return stepValid;
  }

  const handleClickNext = () => {
    formMethods.trigger().then(() => {
      if (validateStep(formMethods.formState.errors, Object.values(taskSteps[activeStep].attrGroups).flat())) {
        formMethods.clearErrors();
        let nextStep = activeStep + 1;
        while (skippedSteps.includes(nextStep + 1)) {
          nextStep++;
        }
        dispatch(setActiveStep(nextStep));
      } else {
        formMethods.trigger();
      }
    });
  };

  const handleClickPrev = useCallback(() => {
    let previousStep = activeStep - 1;
    while (skippedSteps.includes(previousStep + 1)) {
      previousStep -= 1;
    }
    dispatch(setActiveStep(previousStep));
  }, [dispatch, skippedSteps, activeStep]);

  // Deprecated.
  const setCreateNewTask = useCallback(() => dispatch(createAfterSubmitAction(true)), [dispatch]);

  return {
    handleClickNext,
    handleClickPrev,
    setCreateNewTask
  }
}
