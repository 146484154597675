import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    gap: 12,
  },
  footerButton: {
    height: 40,
    padding: theme.spacing(1, 4),
    '&:first-child': {
      marginLeft: 0,
    },
  },
  footerEdsButtonWrapper: {
    height: 40,
    marginLeft: theme.spacing(6),
  },
  taskDetailsFooterButton: {
    height: 40,
    // padding: theme.spacing(1, 4),
    lineHeight: 1.25,
    borderRadius: 10,
    whiteSpace: 'nowrap',
  },
  taskDetailsFooterEdsButtonWrapper: {
    height: 40,
  },
  footerActionButton: {},
  inFormButton: {
    width: '100%',
    maxWidth: '100%',
  },
  inFormButtonWithHint: {
    marginLeft: 0,
  },
  opacityOn: {
    opacity: 0.5,
  },
}));
