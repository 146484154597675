import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useDeleteProcessStepFieldDialogStyles from './StepConditionModal.useStyles';
import CloseIcon from 'assets/images/icons/close-icon-grey.svg';
import {
  AllProcessFieldsListItem,
  ConditionStep,
  FormField,
  Language,
  ProcessStep,
} from '../../TemplateProcesses.types';
import {
  ATTACHMENT_FIELD_KEY,
  FIELDS, GLOSSARY_FIELD_DIRECTORY_CODE_PARAM,
  GLOSSARY_FIELD_DIRECTORY_PARAM, SYSTEM_GLOSSARY_FIELD_KEY,
} from '../ProcessSetupSidePanel/ProcessSetupSidePanel.constants';
import { CONDITION_TYPES } from './StepConditionModal.constants';
import { AppTextField, DatePicker } from 'components';
import NumberFormat from 'react-number-format';
import { UserDropdownWithDepartments } from '../UserDropdownWithDepartments';
import { TreeGlossary } from '../../../Task/TaskForm/FormFields/Fields/Glossary/TreeGlossary';
import { TreeGlossaryMultiSelect } from '../../../Task/TaskForm/FormFields/Fields/Glossary/TreeGlossaryMultiSelect';
import { useDispatch } from 'react-redux';
import { setBpmGlossaryId as setGlossaryId, useGlossaryState } from '../../../../store/requests';
import { getGlossaryByCode } from '../../../../api/requests';

interface StepConditionModalProps {
  isOpen: boolean;
  currentLanguage: Language;
  processSteps: ProcessStep[];
  currentStep: ProcessStep;
  condition: ConditionStep;
  departmentsList: any[];
  handleConditionValuesChange: (newValue: ConditionStep) => void;
  allProcessStepsFields: AllProcessFieldsListItem[];
  onClose: () => void;
}

export const StepConditionModal = ({
  isOpen,
  onClose,
  condition,
  currentLanguage,
  departmentsList,
  processSteps,
  currentStep,
  allProcessStepsFields,
  handleConditionValuesChange,
}: StepConditionModalProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { mapList: glossaryMap } = useGlossaryState();
  const classes = useDeleteProcessStepFieldDialogStyles();
  const [selectedField, setSelectedField] = useState('');
  const [selectedFieldComponent, setSelectedFieldComponent] = useState('');
  const [selectedConditionType, setSelectedConditionType] = useState('');
  const [conditionValue, setConditionValue] = useState('');
  const [glossaryToggle, setGlossaryToggle] = useState(true);
  const [systemGlossaryOptions, setSystemGlossaryOptions] = useState([]);

  const selectableFields = useMemo(() =>
      allProcessStepsFields
        .filter(fieldInfo =>
          fieldInfo?.firstAppearanceStep <= condition?.previousStepOrder && fieldInfo?.field.component !== ATTACHMENT_FIELD_KEY,
        )
    , [allProcessStepsFields, condition]);

  const availableConditionTypes = useMemo(() => {
    const fieldData = allProcessStepsFields.find(field => field?.field?.id === selectedField);
    if (fieldData) {
      setSelectedFieldComponent(fieldData.field.component);
      // TODO - найти лучшее решение для обновления доступных значений в списке при смене компонента
      if (fieldData.field.component === 'glossary') {
        setGlossaryToggle(() => false);
        setTimeout(() => {
          setGlossaryToggle(() => true);
        }, 1);
      }
      return CONDITION_TYPES[fieldData.field.component] || [];
    }
    return [];
  }, [selectedField, allProcessStepsFields]);

  const selectedFieldObject = useMemo(() =>
      allProcessStepsFields.find(field =>
        field?.field?.id === selectedField,
      ),
    [selectedField, allProcessStepsFields],
  );

  const loadAndSetGlossaryValues = async (glossaryIndexName) => {
    try {
      const values = await getGlossaryByCode(glossaryIndexName);
      const mappedValues = values.data.map(item => {
        const displayedValue = item?.additionalFields?.[i18n.language] || item.additionalFields?.['en'] || item?.value
        return {
          id: item.id,
          label: displayedValue
        }
      })
      setSystemGlossaryOptions(() => mappedValues);
    } catch (error) {
      console.log('Error loading system glossary options', error);
    }
  }

  useEffect(() => {
    if (isOpen && selectedField) {
      const fieldData = allProcessStepsFields.find(field => field?.field?.id === selectedField);
      if (fieldData?.field?.component === SYSTEM_GLOSSARY_FIELD_KEY) {
        setSystemGlossaryOptions(() => []);
        loadAndSetGlossaryValues(fieldData?.field?.params?.glossaryIndexName)
      }
    }
  }, [isOpen, selectedField]);

  useEffect(() => {
    if (isOpen) {
      setSelectedField(() => condition.condition.field);
      setSelectedConditionType(() => condition.condition.type);
      setConditionValue(() => condition.condition.value as string);
    }
  }, [isOpen, condition]);

  useEffect(() => {
    if (selectedField && !selectedConditionType && availableConditionTypes.length > 0) {
      setSelectedConditionType(availableConditionTypes[0]?.key);
    }
  }, [selectedField, selectedConditionType, availableConditionTypes]);

  const handleSave = () => {
    const newConditionValue = {
      ...condition,
      condition: {
        field: selectedField,
        type: selectedConditionType,
        value: conditionValue,
      },
    };
    handleConditionValuesChange(newConditionValue);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} classes={{ paper: classes.dialog }} disableEnforceFocus>
      <Box className={classes.dialogHeader}>
        <Typography className={classes.dialogTitle}>
          {t('customProcesses.creationPage.conditionSetupModal.title')}
        </Typography>
        <IconButton onClick={onClose}>
          <img src={CloseIcon} className={classes.dialogIcon}/>
        </IconButton>
      </Box>

      <DialogContent className={classes.dialogContent}>
        <Box className={classes.conditionFieldsWrapper}>
          <Select
            value={selectedField}
            onChange={event => {
              setSelectedField(event.target.value as string);
              setSelectedConditionType('');
              setConditionValue('');
            }}
            className={classes.conditionField}
            placeholder={t('customProcesses.creationPage.conditionSetupModal.selectField')}
            renderValue={() => selectedFieldObject?.field.hint[currentLanguage] || selectedFieldObject?.field.id}
          >
            {selectableFields.length === 0 &&
              <div className={classes.emptyFieldsListMessageWrapper}>
                <p>{t('customProcesses.creationForm.conditionModal.noFieldsMessage')}</p>
                <ul>
                  {
                    FIELDS
                      .filter(field => field.key !== ATTACHMENT_FIELD_KEY)
                      .map(field => <li>{t(field.title)}</li>)
                  }
                </ul>
              </div>}

            {selectableFields.map(field =>
              <MenuItem value={field.field.id} className={classes.fieldsMenuItem}>
                <span className={classes.fieldsMenuItemFieldName}>{field.field.hint[currentLanguage] || field.field.id}</span>
                <span className={classes.fieldsMenuItemStepInfo}>{t('customProcesses.creationPage.processStepTag.step')} {field.firstAppearanceStep} - {processSteps[field.firstAppearanceStep - 1]?.stepName[currentLanguage]}</span>
              </MenuItem>,
            )}
          </Select>

          {selectedField &&
            <Select
              value={selectedConditionType}
              onChange={event => setSelectedConditionType(event.target.value as string)}
              className={classes.conditionField}
              placeholder={t('customProcesses.creationPage.conditionSetupModal.selectValue')}
            >
              {availableConditionTypes.map(conditionType =>
                <MenuItem
                  value={conditionType.key}>{t(conditionType.label, { defaultValue: conditionType.key })}</MenuItem>,
              )}
            </Select>
          }

          {(selectedField && ['singleLineText', 'multiLineText'].includes(selectedFieldComponent)) &&
            <AppTextField
              className={classes.conditionFieldText}
              value={conditionValue}
              onChange={event => setConditionValue(event.target.value)}
              placeholder={t('customProcesses.creationPage.conditionSetupModal.enterValue')}
            />
          }

          {(selectedField && ['glossary'].includes(selectedFieldComponent) && glossaryToggle) &&
            <TreeGlossary
              onSelect={(selectedItem) => {
                if(!selectedItem?.id) return
                setConditionValue(selectedItem.id)}
              }
              name={''}
              value={null}
              valueId={conditionValue}
              params={{
                directoryCode: (selectedFieldObject?.field.params[GLOSSARY_FIELD_DIRECTORY_CODE_PARAM] as string),
                directoryPath: (selectedFieldObject?.field.params[GLOSSARY_FIELD_DIRECTORY_PARAM] as string),
                isAcceptedValueTypeId: true
              }}
              errors={[]}
              disabledItemsIds={[]}
              showErrorText={false}
              control={null}
              withoutFormController
              isConditionModalVariant
              placeholder={t('customProcesses.creationPage.conditionSetupModal.selectValue')}
            />
          }

          {(selectedField && ['glossaryMultiSelect'].includes(selectedFieldComponent) && glossaryToggle) &&
            <TreeGlossaryMultiSelect
              onSelect={(selectedItems) => {
                setConditionValue(selectedItems.join(','))}
              }
              name={''}
              value={null}
              valueId={conditionValue ? conditionValue?.split(',') : []}
              params={{
                directoryCode: (selectedFieldObject?.field.params[GLOSSARY_FIELD_DIRECTORY_CODE_PARAM] as string),
                directoryPath: (selectedFieldObject?.field.params[GLOSSARY_FIELD_DIRECTORY_PARAM] as string),
                // isAcceptedValueTypeId: true
              }}
              errors={[]}
              disabledItemsIds={[]}
              showErrorText={false}
              control={null}
              withoutFormController
              isConditionModalVariant
              placeholder={t('customProcesses.creationPage.conditionSetupModal.selectValue')}
            />
          }

          {(selectedField && ['systemGlossary'].includes(selectedFieldComponent) && glossaryToggle) &&
            <Select
              value={conditionValue}
              onChange={event => setConditionValue(event.target.value as string)}
              className={classes.conditionField}
              placeholder={t('customProcesses.creationPage.conditionSetupModal.selectValue')}
            >
              {systemGlossaryOptions.map(option =>
                <MenuItem
                  value={option.id}>{option.label}</MenuItem>,
              )}
            </Select>
          }

          {(selectedField && ['currency'].includes(selectedFieldComponent)) &&
            <NumberFormat
              customInput={AppTextField}
              className={classes.conditionFieldText}
              value={conditionValue}
              decimalScale={2}
              onChange={event => setConditionValue(event.target.value)}
              placeholder={t('customProcesses.creationPage.conditionSetupModal.enterValue')}
            />
          }

          {(selectedField && ['dateLineArea'].includes(selectedFieldComponent)) &&
            <DatePicker
              className={classes.conditionField}
              margin="none"
              value={conditionValue ? new Date(conditionValue) : null}
              placeholder={t('customProcesses.creationPage.conditionSetupModal.datePlaceholder')}
              onChange={(value) => {
                setConditionValue(value?.toISOString());
              }}
              disableKeyboardInput
            />
          }

          {(selectedField && ['userList'].includes(selectedFieldComponent)) &&
            <UserDropdownWithDepartments
              activeUsersId={conditionValue.split(',')}
              handleUsersSelect={(users) => setConditionValue(users.join(','))}
              currentStepOrder={1}
              departmentsList={departmentsList}
              isConditionModalVariant
            />
          }
        </Box>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!selectedField || !selectedConditionType || !conditionValue}
        >
          {t('buttons.button_save')}
        </Button>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t('template_delete_modal.button_cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
