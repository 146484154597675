import React, { ReactElement } from 'react';
import {Box, Button, Dialog, Typography} from "@material-ui/core";
import { useTranslation } from 'react-i18next';

import useStyles from './CreateSettingsCongratulationDialog.useStyles';
import RocketImage from 'assets/images/emojis/rocket.png';

interface CreateSettingsCongratulationDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateSettingsCongratulationDialog = ({
  isOpen,
  onClose
}: CreateSettingsCongratulationDialogProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <Box className={classes.createSettingsCongratulationDialog}>
        <img className={classes.createSettingsCongratulationDialogImage} src={RocketImage} />

        <Typography className={classes.createSettingsCongratulationDialogTitle}>
          {t('customProcesses.creationPage.successModal.title')}
        </Typography>

        <Typography className={classes.createSettingsCongratulationDialogSubtitle}>
          {t('customProcesses.creationPage.successModal.message')}
        </Typography>

        <Box pt={5}>
          <Button color="primary" onClick={onClose}>
            {t('customProcesses.creationPage.buttons.start')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
