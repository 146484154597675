import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  item: {
    borderLeft: '4px solid transparent',
    padding: theme.spacing(3.5, 4.5, 4.5, 8),
    '&:hover, &.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.grey[100],
      borderLeftColor: theme.palette.grey[800],
    },
  },
  text: {
    '& span': {
      fontSize: 16,
    },
  },
  icon: {
    width: 24,
    height: 24,
    minWidth: 24,
    marginRight: theme.spacing(3),
    // padding: theme.spacing(0.5),
    '& img': {
      display: 'block',
      maxWidth: '100%',
    },
  },
}));
