import React, { ReactElement, useContext } from 'react';
import {
  Box,
  Link,
  TableCell,
  Typography, Tooltip, ButtonBase
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import moment from 'moment';
import { UserInfo } from 'components';

import { CustomCheckbox } from 'components/CustomCheckbox/CustomCheckbox';
import { TableComponent } from './TableComponent'
import { useOldUsersListState, useUsersState } from 'store/users';
import { AdminPanelTableColumns } from './AdminPanelTable.constants';

import { RequestStatusesEnum } from '../../AdminPanel.types';
import { AdminPanelRow, AdminPanelTableColumn, SelectedAdminPanelRow } from './AdminPanelTable.types';

import useStyles from './AdminPanelTable.useStyles';
import { statuses } from '../Filter';
import ProcessContext from 'contexts/ProcessContext/context';
import { ProcessContextType } from 'types';
import { useUserProfile } from 'hooks';

type Props = {
  setCurrentRow: (row: AdminPanelRow) => void
  setAnchorElement: (el: HTMLElement) => void
  requests: AdminPanelRow[];
  anchorElement: HTMLElement;
  menuButtons: {
    title: string;
    visible: boolean;
    onClick: () => void
  }[];
  openDetailsWindow: (row?: AdminPanelRow) => void;
  selectRows: (selectedRows: SelectedAdminPanelRow[]) => void;
  selectedRows: SelectedAdminPanelRow[]
}

export const AdminPanelTable = (props: Props) => {
  const { groups }: Partial<ProcessContextType> = useContext(ProcessContext);
  const { companyId } = useUserProfile();

  const {
    setCurrentRow,
    setAnchorElement,
    anchorElement,
    requests,
    menuButtons,
    openDetailsWindow,
    selectRows,
    selectedRows
  } = props;

  const classes = useStyles();

  const { t } = useTranslation();
  const { users = [] } = useUsersState();
  const oldUsersList = useOldUsersListState();

  const renderCell = (column: AdminPanelTableColumn, row: AdminPanelRow): ReactElement => {
    const { businessTask, processIconPath, groupName } = row;

    switch (column.id) {
      case "checkbox": {
      const isChecked = selectedRows.findIndex(selectedRow => selectedRow.businessTask.taskId === businessTask.taskId) > -1;

        return <TableCell>
          <CustomCheckbox
            value={isChecked}
            onChange={checked => {
              if (checked){
                selectRows([...selectedRows, row])
              } else {
                selectRows(selectedRows.filter(selectedRow => selectedRow.businessTask.taskId !== row.businessTask.taskId))
              }
            }}
          />
        </TableCell>
      }
      case 'name': {
        const group = groups?.find(group => group.sysName === groupName);

        const title = t(`constructor-${businessTask.processSysName}.name`, {
          defaultValue: businessTask.processDefinitionName
        })

        return <TableCell
          key={column.id}
          className={classes.tableCell}
          style={{ width: column.width }}
        >
          <Box display="flex" alignItems="center">
            <img src={processIconPath} alt='icon' className={classes.titleIcon} />

            <Box ml={2} className={classes.titleBox}>
              <Tooltip title={title} placement='top-start'>
                <Link className={classes.title} onClick={() => {
                  setCurrentRow(row)
                  openDetailsWindow(row)
                }}>
                  {title}
                </Link>
              </Tooltip>

              <Typography variant="subtitle1" className={classes.groupTitle}>
                { group && t(`groups.${companyId}_${group.sysName}.name`, {defaultValue: t(`groups.${group.sysName}.name`, {defaultValue: group.name })})}
              </Typography>
            </Box>
          </Box>
        </TableCell>
      }
      case 'status': {
        const status = row?.businessTask?.status;

        return <TableCell
          key={column.id}
          className={classes.tableCell}
          style={{ width: column.width }}
        >
          {status in RequestStatusesEnum && <Box display="flex" alignItems="center">
            <div style={{marginRight: 8}}>
              {statuses?.[status].icon}
            </div>

            {t(statuses?.[status]?.label, {defaultValue: statuses?.[status]?.defaultTranslation })}
          </Box>}
        </TableCell>
      }
      case 'startDate': {
        const date = row?.businessTask?.taskStartDate ? moment(row?.businessTask?.taskStartDate).format("DD.MM.YY") : null

        return <TableCell
          key={column.id}
          className={classes.tableCell}
          style={{ width: column.width }}
        >{date}</TableCell>
      }
      case 'initiator': {
        const initiator = users[row.businessTask.initiator];

        return <TableCell
          key={column.id}
          className={classes.tableCell}
          style={{ width: column.width }}
        >

        <Box display="flex" alignItems="center" justifyContent="center">
            <Tooltip title={initiator?.fullName} placement='top-start'>
              <div>
              <UserInfo
                user={initiator}
                avatarSize={24}
                hideName
              />
              </div>
            </Tooltip>
          </Box>
        </TableCell>
      }
      case 'currentAssignee': {
        const userId = row.businessTask.assignee
        let assignee = users[userId];

        if(!assignee && userId){
          const oldUser = oldUsersList?.find(user => user.id === userId || user.userId === userId)


          if(oldUser) {
            assignee = oldUser
          }
        }

        return <TableCell
          key={column.id}
          className={classes.tableCell}
          style={{ width: column.width }}
        >

        <Box display="flex" alignItems="center" justifyContent="center">
            <Tooltip title={assignee?.fullName} placement='top-start'>
              <div>
              <UserInfo
                user={assignee}
                avatarSize={24}
                hideName
              />
              </div>
            </Tooltip>
          </Box>
        </TableCell>
      }
      case 'step': {
        const stepOrder = businessTask?.progressBar?.completedStepOrder || 0;
        const totalSteps = businessTask?.progressBar?.stepsCount || 0;

        return <TableCell
          key={column.id}
          className={classes.tableCell}
          style={{ width: column.width }}
        >
          {t("AdminPanel.table.step", {
            stepperOrder: stepOrder,
            totalCountOfSteps: totalSteps,
            defaultValue: `${stepOrder} - ${totalSteps}`
          })}
        </TableCell>
      }
      case 'taskType': {
        return <TableCell
        key={column.id}
        className={classes.tableCell}
        style={{ width: column.width }}
      >
        {row.businessTask?.parallel ? t("AdminPanel.table.taskParallelAssignee", {
          defaultValue: "Parallel"
        }) : t("AdminPanel.table.taskSingleAssignee", {
          defaultValue: "Single"
        })}
      </TableCell>
      }
      case 'processMenu': {
        return (
          <TableCell
            key={column.id}
            className={classes.tableCell}
            style={{ width: column.width }}
            width={column.width}
          >
            <ButtonBase
              className={classes.actionButton}
              onClick={event => {
                setCurrentRow(row);
                setAnchorElement(event.currentTarget);
              }}>
              <MoreHorizIcon
                fontSize="small"
                className={classes.actionButtonIcon}
              />
            </ButtonBase>
          </TableCell>
        );
      }
      default: {
        return (
          <TableCell key={column.id}
            className={classes.tableCell}
            style={{ width: 48 }}
          >
            <Typography>{row[column.id]}</Typography>
          </TableCell>
        );
      }
    }
  };

  return <TableComponent
  columns={AdminPanelTableColumns}
  rows={requests}
  renderCell={renderCell}
  menuButtons={menuButtons}
  anchorElement={anchorElement}
  onMenuClose={() => {
    setAnchorElement(null)
  }}
  selectRows={(rows) => {
    selectRows(rows)
  }}
  selectedRows={selectedRows}
/>
}
