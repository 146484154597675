import React, {
  ChangeEvent,
  useRef,
  useMemo,
  ReactElement,
} from 'react';

import { Button, ThemeProvider, Box } from '@material-ui/core';
import { theme } from '@dartech/dms-ui';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import UploadIcon from "assets/images/icons/cloud-upload.svg"

import { Styled } from './attachments-upload.styles';
import { VariantSelect } from '../attachments-list/attachments-list.component';

interface AttachmentsUploadProps {
  variant?: VariantSelect;
  attachmentsCount?: number;
  activeAttachmentsCount?: number;
  name?: string;
  allowedFileTypes?: string[];
  maximumFileSize?: number;
  showAllowedFileTypes?: boolean;
  isFilesLimitReached?: boolean;
  preventFileSelectOpen?: boolean;
  dynamicCompactVariantHeight?: boolean;
  error?: string;
  inputRef?: any;
  onFileSelect?: (file: File) => void | undefined;
}

const AttachmentsUpload = ({
  variant = VariantSelect.Big,
  attachmentsCount = 0,
  activeAttachmentsCount = 0,
  name = 'attachments',
  allowedFileTypes = [],
  showAllowedFileTypes = true,
  maximumFileSize = 50,
  isFilesLimitReached = false,
  preventFileSelectOpen = false,
  dynamicCompactVariantHeight = false,
  error = '',
  inputRef = null,
  onFileSelect = undefined,
}: AttachmentsUploadProps): ReactElement => {
  const { t } = useTranslation();
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);

  const mainText = useMemo(
    () =>
      isFilesLimitReached
        ? t("form_components.attachment.maximum_reached_hint")
        : t('form_components.attachment.drag_and_drop_label'),
    [isFilesLimitReached]
  );

  const secondaryText = useMemo(() => {
    const fileTypesString =
      allowedFileTypes.map((fileType) => `.${fileType}`).join(', ');
    const sizeLimitString = `${maximumFileSize}${t('form_components.attachment.mb')}`;
    if (maximumFileSize) {
      return t('form_components.attachment.permitted_files_label_with_max_size', {fileTypes: fileTypesString, maxSize: sizeLimitString})
    }
    return t('form_components.attachment.permitted_files_label', {fileTypes: fileTypesString});
  }, [allowedFileTypes, maximumFileSize]);

  const handleFileInputCLick = () => {
    if (preventFileSelectOpen) {
      return;
    }
    hiddenFileInputRef.current?.click();
  };

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target?.files ? e.target?.files[0] : null;
    if (selectedFile && onFileSelect) {
      onFileSelect(selectedFile);
    }
    hiddenFileInputRef.current.value = '';
  };

  const borderStylesClassNames = useMemo(() => classNames({
    AttachmentUploadCompactDashed: variant === 'compact-dashed',
    AttachmentUploadCompactDashedWithFiles: variant === 'compact-dashed' && attachmentsCount,
    AttachmentUploadCompact: variant === 'compact-dashed',
    AttachmentsUploadLowHeight: variant === 'compact' || (variant === 'big' && activeAttachmentsCount),
    AttachmentsUploadDynamicLowHeight: variant === 'compact' && dynamicCompactVariantHeight,
    AttachmentsUploadBigWithoutFiles: variant === 'big' && !activeAttachmentsCount,
    AttachmentsUploadCompactWithError: variant === 'compact' && error,
  }), [variant, attachmentsCount, activeAttachmentsCount, dynamicCompactVariantHeight, error]);

  const uploadFilesClassNames = useMemo(() => classNames('ContentWrapperCompactDashed', {
    ContentWrapperCompactDashedWithFileTypes: showAllowedFileTypes,
  }), [showAllowedFileTypes]);

  let applyAllowedFileTypes = useMemo(() => allowedFileTypes.map((type) => '.' + type).join(','), [allowedFileTypes]);
  applyAllowedFileTypes+=', mp4'

  return (
    <ThemeProvider theme={theme}>
      <Styled.AttachmentsUpload>
        <input type="hidden" name={name} ref={inputRef} />
        <div className={borderStylesClassNames}>
          {variant !== 'compact-dashed' ? (
            <div className="ContentWrapper">
              <div className="TextWrapper">
                <p className="MainText">{mainText}</p>
                <p className="SecondaryText" title={secondaryText}>{secondaryText}</p>
              </div>
              <input
                type="file"
                accept={applyAllowedFileTypes}
                hidden
                ref={hiddenFileInputRef}
                onChange={handleFileSelect}/>
              <Box whiteSpace="nowrap">
                <Button variant="contained" color="secondary" onClick={handleFileInputCLick} disabled={isFilesLimitReached}>
                  {t('form_components.attachment.choose_file_label')}
                </Button>
              </Box>
            </div>
          ) : (
            <div className={uploadFilesClassNames}>
              <input
                type="file"
                accept={applyAllowedFileTypes}
                hidden
                ref={hiddenFileInputRef}
                onChange={handleFileSelect} />
              <div className="TextWrapperCompactDashed">
                <img src={UploadIcon} alt='' />
                <Box>
                  <p className="MainText">{mainText}</p>
                  {showAllowedFileTypes && <p className="SecondaryText">{secondaryText}</p>}
                </Box>
              </div>
              <div className="ButtonWrapperCompactDashed">
                <Button
                  variant="contained"
                  className="ButtonCompactDashed"
                  color="secondary"
                  onClick={handleFileInputCLick}
                  disabled={isFilesLimitReached}>
                    {t('form_components.attachment.choose_file_label')}
                </Button>
              </div>
            </div>
          )}
        </div>
        {error && !activeAttachmentsCount && variant !== 'compact' && variant !== 'compact-dashed' && (
          <div className="Error">{error}</div>
        )}

      </Styled.AttachmentsUpload>
    </ThemeProvider>
  );
};

export default AttachmentsUpload;
