import React, { useState, useCallback } from 'react';
import {
  Button,
  TextField,
  DialogContent,
  Typography,
  DialogActions,
  Dialog,
  Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useStyles from './useStyles';

import {
  SAVE_TEMPLATE_AND_SUBMIT_FORM_ACTION,
  SAVE_TEMPLATE_ACTION,
} from '../constants';
import { CloseButton } from 'components';

type Props = {
  open: boolean;
  isSubmitButtonDisabled?: boolean;
  onClick: any;
};

export const TemplateCreationDialog = ({
  open = false,
  isSubmitButtonDisabled = false,
  onClick,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [templateName, setTemplateName] = useState('');

  const handleTemplateNameChange = useCallback((e) => {
    setTemplateName(e.target.value);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => onClick({ action: 'Close template creation popover' })}
      className={classes.root}
      PaperProps={{
        style: {
          borderRadius: 12
        }
      }}
    >
      <Box
        component="header"
        bgcolor="white"
        py={4}
        px={6}
        display="flex"
        justifyContent="space-between"
      >
        <Typography variant="h4" className={classes.title}>
          {t('One_step_internal_memo.process_button5')}
        </Typography>

        <CloseButton onClick={() => onClick({ action: 'Close template creation popover' })} />
      </Box>
      <DialogContent className={classes.content}>
        <TextField
          className={classes.templateNameInput}
          fullWidth
          value={templateName}
          label={t('One_step_internal_memo.template_title')}
          onChange={handleTemplateNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() =>
            onClick({ action: SAVE_TEMPLATE_ACTION, tplName: templateName })
          }
          disabled={templateName.trim().length === 0}
        >
          {t('One_step_internal_memo.template_button2')}
        </Button>
        <Button
          color="primary"
          onClick={() =>
            onClick({
              action: SAVE_TEMPLATE_AND_SUBMIT_FORM_ACTION,
              tplName: templateName,
            })
          }
          disabled={isSubmitButtonDisabled || templateName.trim().length === 0}
        >
          {t('One_step_internal_memo.template_button1')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
