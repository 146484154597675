import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  templateCard: {
    display: "flex",
    flexDirection: "column",
    minWidth: 253,
    width: '100%',
    minHeight: 172,
    background: "#fff",
    borderRadius: 10,
    border: "1px solid rgba(38, 40, 66, 0.12)",
    cursor: "pointer",
    padding: 16,
    alignSelf: 'start',

    "&:hover": {
      border: "1px solid #007994",
    }
  },
  templateCardInfo: {
    display: "flex",
    gap: 12,
    height: 58,
    alignItems: "center"
 },
  templateIcon: {
    width: 40,
    height: 40,
    borderRadius: 5,
    background: "#F7F8FC",
    flexShrink: 0,

    "& img": {
      maxWidth: "100%"
    }
  },
  templateTitle: {
    color: "#262842",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: "40px"
  },
  templateCategoryTitle: {
    color: "#6D6E85",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "16px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: 'fit-content',
    maxWidth: '100%',
  },
  templateDescription: {
    marginTop: 16,
    maxHeight: "36px",
    color: "#6D6E85",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "18px",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  templateTitleWrap: {
    overflow: "hidden"
  },
  templateFooter: {
    display:   "flex",
    justifyContent: "space-between",
    marginTop: "auto",
    alignItems: "center",

    color: "#6D6E85",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "18px"
  },
  goBtn: {
    width: 32,
    height: 32,
    borderRadius: "50% !important",
    background: "#F7F8FC !important"
  },
  templateIntegrations: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 4,
    marginTop: 12,
    marginBottom: 12,
  },
  integrationChip: {
    padding: '4px 6px',
    borderRadius: 6,
    background: '#F0F0F7',
    color: '#262842',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    whiteSpace: 'nowrap',
  },
  cardTooltip: {
    color: '#F7F8FC',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    maxWidth: 240,
    width: 'fit-content',
    padding: '8px 12px',
    borderRadius: 10,
  },
}))
