import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

type Props = {
  onTextChange: (val: string) => void;
  label: string;
  value: string;
}

export const SearchProcesses = ({ onTextChange, label, value }: Props) => {
  const { t } = useTranslation();

  return (
    <TextField
      label={label}
      value={value}
      placeholder={t("form_components.select.search_placeholder")}
      InputProps={{ endAdornment: <SearchIcon /> }}
      onChange={e => onTextChange(e.target.value)}
      fullWidth
    />
  );
};


export default SearchProcesses;
