import React, { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import classNames from 'classnames';
import { Button, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { limitStringLength } from 'utils/user';
import arrowDownloadRegularIcon from 'assets/images/icons/arrow_download_regular.svg';
import arrowDownloadRegularGreyIcon from 'assets/images/icons/arrow_download_regular_grey.svg';
import deleteIconImage from 'assets/images/icons/delete-attachment.svg';
import FileViewLoader from './FileViewLoader';
import {FileViewProps} from 'attachments-types';
import {Styled} from './attachment-item/attachment-item.styles'

const FileView = (props: FileViewProps) => {
  const {
    assignee,
    attachmentIcon,
    creationDate,
    fileSize,
    handleDownloadFile,
    handleDelete,
    isLoading,
    openAttachmentPreview,
    readOnly,
    step,
    title
  } = props;
  const { t } = useTranslation();

  const [userDownloading, setUserDownloading] = useState(false);
  const [userViewing, setUserViewing] = useState(false);
  const [userUploading, setUserUploading] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setUserDownloading(false);
      setUserViewing(false);
      setUserUploading(false);
    } else {
      if (!userDownloading && !userViewing) {
        setUserUploading(true);
      }
    }
  }, [isLoading, userDownloading, userViewing]);

  const FileTypeIcon = ({attachmentIcon}) => {
    return <img className="AttachmentIcon" src={attachmentIcon} alt="attachment icon" />;
  }

  const StepName = () => {
    if (!!step) {
      return (
        <span className="AttachmentItemInfoText">
          <b>{t('form_components.attachment.step')}:</b> {step}
        </span>
      )
    }
  }

  const Assignee = () => {
    if (!!assignee) {
      return (
        <span className="AttachmentItemInfoText" title={assignee}>
          <b>{t('form_components.attachment.assignee')}:</b> {limitStringLength(assignee, 25)}
        </span>
      )
    }
  }

  const BasicInformation = () => {
    const basicInfoClassnames = useMemo(() => 
      classNames("AttachmentItemInfoTop", { "AttachmentItemInfoTopOldAttachment": !creationDate }), [creationDate]);

    return (
      <div className={basicInfoClassnames}>
        <span className="AttachmentItemInfoTitle" onClick={openAttachmentPreview}>
          {title}
        </span>
        {creationDate && (
          <span className="AttachmentItemInfoDateSize">
            {fileSize ? `${fileSize} • ` : ''} 
            {moment(creationDate).format('DD.MM.YYYY, HH:mm')}
          </span>
        )}
      </div>
    )
  }

  const viewAction = () => {
    openAttachmentPreview();
    setUserViewing(true);
  }

  const downloadAction = () => {
    handleDownloadFile();
    setUserDownloading(true);
    setUserViewing(false);
  }

  const FileActions = () => {
    const controlsWrapperClassNames = useMemo(() => classNames({ "ControlsWrapperOldAttachment": !creationDate }), [creationDate]);
    return (
      <div className='FileActions'>
        <FileViewLoader actionLoading={userDownloading} isLoading={isLoading}>
          <Button className='DownloadButton' variant="contained" color="secondary" onClick={downloadAction} disabled={userUploading}>
            <img className="DownloadButton__icon" src={userUploading ? arrowDownloadRegularGreyIcon : arrowDownloadRegularIcon} alt="arrow download regular icon" />
          </Button>
        </FileViewLoader>
        <FileViewLoader actionLoading={userViewing} isLoading={isLoading}>
          <Button className='ViewButton' variant="contained" color="secondary" onClick={viewAction} disabled={userUploading}>
            {t("fileAttachments.view")}
          </Button>
        </FileViewLoader>
        <Styled.ControlsWrapper className={controlsWrapperClassNames}>
          {!readOnly && (
            <Styled.DeleteIcon>
              <img src={deleteIconImage} alt="delete icon" onClick={handleDelete} />
            </Styled.DeleteIcon>
          )}
        </Styled.ControlsWrapper>
      </div>
    )
  }

  return (
    <div className="ContentWrapper">
      <FileViewLoader actionLoading={userUploading} isLoading={isLoading}>
        <FileTypeIcon attachmentIcon={attachmentIcon} />
      </FileViewLoader>
      <div className="AttachmentItemInfo">
        <BasicInformation />
        <StepName />
        <Assignee />
      </div>
      <FileActions />
    </div>
  )
}

export default FileView;
