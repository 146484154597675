import axios from 'axios';

import { authService } from '@dar-dms/topbar';
import { environment } from 'environments/environment';
import { addTrackingHeaders } from './handlers';

const CLIENT_TIMEOUT = {
  DEFAULT: 30 * 1000,
  CAMUNDA: 60 * 1000,
};

const CLIENT_HEADERS = {
  DEFAULT: { 'Content-Type': 'application/json' },
  FORM_DATA: { 'Content-Type': 'multipart/form-data' },
};

export const baseV1ApiClient = authService.createHttpClient({
  baseURL: environment.NX_BASE_V1_API,
  timeout: CLIENT_TIMEOUT.DEFAULT,
  headers: CLIENT_HEADERS.DEFAULT,
})
baseV1ApiClient.interceptors.request.use(addTrackingHeaders);

export const baseV1ApiCamundaTimeoutClient = authService.createHttpClient({
  baseURL: environment.NX_BASE_V1_API,
  timeout: CLIENT_TIMEOUT.CAMUNDA,
  headers: CLIENT_HEADERS.DEFAULT,
});
baseV1ApiCamundaTimeoutClient.interceptors.request.use(addTrackingHeaders);

export const baseV1ApiFormDataClient = authService.createHttpClient({
  baseURL: environment.NX_BASE_V1_API,
  timeout: CLIENT_TIMEOUT.CAMUNDA,
  headers: CLIENT_HEADERS.FORM_DATA,
})
baseV1ApiFormDataClient.interceptors.request.use(addTrackingHeaders);

export const baseHcmsApiClient = authService.createHttpClient({
  baseURL: environment.NX_BASE_HCMS_API,
  timeout: CLIENT_TIMEOUT.DEFAULT,
  headers: CLIENT_HEADERS.DEFAULT,
})
baseHcmsApiClient.interceptors.request.use(addTrackingHeaders);

export const baseClient = authService.createHttpClient({
  timeout: CLIENT_TIMEOUT.DEFAULT,
  headers: CLIENT_HEADERS.DEFAULT,
})

export const googleApiClient = axios.create({
  baseURL: environment.NX_GOOGLE_BASE_API,
});
