import React, { ReactElement, useState } from 'react';
import { Box, Button, Fade, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications'

import useStyles from './AlertModal.useStyles';
import { Spinner } from 'components/Spinner';
import { CloseButton } from 'components/CloseButton';

type Props = {
  close: () => void;
  title: string | ReactElement;
  description: string;
  additionalButtons?: {
    text: string;
    action: () => Promise<void>
    loading: boolean;
  }[]
};

export const AlertModal = ({ close, title, description, additionalButtons = [] }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false)

  const [isOpened, setIsOpened] = useState(true);

  const handleClose = () => {
    setIsOpened(false);
  }

  const handleAction = async () => {
    setLoading(true)
    try {
      await handleClose()
    } catch (error) {
      NotificationManager.error(t(error.message));
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fade
      in={isOpened}
      onExited={close} // set render condition to false
      timeout={300}
  >
    <Box className={classes.modal}>
      <Box className={classes.modalBody}>
      <div className={classes.modalHeader}>
        <Typography className={classes.title}>
          {title}
        </Typography>

        <CloseButton onClick={handleClose} />
      </div>

      <div className={classes.modalContent}>
        {loading && <Spinner />}
        {description && <Typography className={classes.description}>
          {description}
        </Typography>}
      </div>

      <div className={classes.modalFooter}>
        <Button
          color="secondary"
          variant="contained"
          className={classes.modalButton}
          onClick={handleAction}
        >
          {t("filters.filter_button_close")}
        </Button>

        {additionalButtons.map(({ action, text, loading }) => {
          return <Button
          endIcon={loading ? <Spinner size={14} absolute={false} buttonVersion/> : null}
          disabled={loading}
          color="secondary" variant="contained" onClick={action} className={classes.modalButton}>
          {text}
        </Button>
        })}
      </div>
    </Box>
    </Box>
    </Fade>
  );
};


