import React, { useState, useCallback, useMemo, useEffect, memo } from 'react';
import {
  Typography,
  FormControl,
  TextField,
  ThemeProvider,
  Input,
  FormHelperText,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { theme } from '@dartech/dms-ui';
import NumberFormat from 'react-number-format';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useTaskState } from 'store/requests';
import { removeSpacesFromNumber } from 'utils/general';

import useStyles from './useStyles';

export const MAX_VALUE_LIMIT = 999999999999.99;

type Props = {
  name?: string;
  currencyFieldName?: string;
  hint?: string;
  currencies?: string;
  isTaskDetailsVariant?: boolean;
  disabled?: boolean;
  allowZeroAmount?: boolean;
};

export const AmountAndCurrency = memo(
  ({
    name,
    currencyFieldName,
    hint,
    currencies,
    isTaskDetailsVariant = false,
    disabled = false,
    allowZeroAmount,
  }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
      formState: { errors },
      control,
      watch,
      setValue,
    } = useFormContext();

    const { data: bpmTask, createdFromTemplate } = useTaskState();

    const [currencyValue, setCurrencyValue] = useState(
      watch(currencyFieldName) || null
    );
    const [currencyInputValue, setCurrencyInputValue] = useState(
      watch(currencyFieldName) || ''
    );

    const currencyOptions = useMemo(
      () => (currencies ? currencies?.split(',')?.sort() : []),
      [currencies]
    );

    const setDefaultValue = useCallback(() => {
      const isValueUndefined = !watch(name);
      const isTaskTemplate = bpmTask?.isFirstStep && createdFromTemplate;

      if (isValueUndefined && (isTaskTemplate || isTaskDetailsVariant)) {
        setValue(name, 0);
      }
      //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      setDefaultValue();
    }, [setDefaultValue]);

    const handleCurrencyChange = (_, val) => {
      setCurrencyValue(val);
      setValue(currencyFieldName, val);
    };

    const handleCurrencyInputChange = (_, val) => {
      setCurrencyInputValue(val);
      setValue(currencyFieldName, val);
    };

    const renderCurrencyInput = (inputParams) => (
      <Controller
        name={currencyFieldName}
        control={control}
        rules={{
          required: t('form_components.required_errors.default') as string,
          validate: validateCurrency,
        }}
        render={() => (
          <TextField
            {...inputParams}
            required
            inputProps={{
              ...inputParams.inputProps,
              'data-selenium': currencyFieldName,
            }}
            error={!!errors[currencyFieldName]}
          />
        )}
      />
    );

    const validateAmount = (value) => {
      if (!allowZeroAmount && removeSpacesFromNumber(value) === 0) {
        return t('form_components.amountAndCurrency.zeroIsNotAllowed');
      }

      if (removeSpacesFromNumber(value) > MAX_VALUE_LIMIT) {
        return t('customProcesses.processForm.timeMaxValueError').replace(
          '999',
          MAX_VALUE_LIMIT.toString()
        );
      }

      return true;
    };

    const validateCurrency = (value) =>
      !!currencyOptions.includes(value) ||
      (t('form_components.required_errors.default') as string);

    return (
      <ThemeProvider theme={theme}>
        <Typography component="label" className={classes.label}>
          {hint}
        </Typography>

        <FormControl
          fullWidth
          className={classes.Wrapper}
          error={!!errors[name] || !!errors[currencyFieldName]}
        >
          <div className={classes.InnerWrapper}>
            <Controller
              name={name}
              control={control}
              rules={{
                required: t(
                  'form_components.required_errors.default'
                ) as string,
                validate: validateAmount,
              }}
              render={({ field: { value, onChange } }) => (
                <NumberFormat
                  fullWidth
                  label={hint}
                  value={watch(name)}
                  defaultValue={value}
                  disabled={disabled}
                  decimalScale={2}
                  variant="outlined"
                  thousandSeparator=" "
                  decimalSeparator="."
                  allowNegative={false}
                  allowLeadingZeros={false}
                  inputProps={{ 'data-selenium': name }}
                  customInput={Input}
                  classes={{ root: classes.amountInput }}
                  className={classes.amountInput}
                  onChange={onChange}
                />
              )}
            />

            <Autocomplete
              className={classes.currencyAutocomplete}
              classes={{
                inputRoot: classes.currencyInputRoot,
                input: classes.currencyInput,
              }}
              options={currencyOptions}
              value={currencyValue}
              disabled={disabled}
              onChange={handleCurrencyChange}
              inputValue={currencyInputValue}
              onInputChange={handleCurrencyInputChange}
              renderInput={renderCurrencyInput}
              forcePopupIcon={true}
            />
          </div>
        </FormControl>

        {(!!errors[name] || !!errors[currencyFieldName]) && (
          <FormHelperText
            error={!!errors[name] || !!errors[currencyFieldName]}
            className={classes.errorMessage}
          >
            {!!errors[name] && errors[name].message}{' '}
            {!!errors[currencyFieldName] &&
              (errors[currencyFieldName].message ||
                (t('form_components.required_errors.default') as string))}
          </FormHelperText>
        )}
      </ThemeProvider>
    );
  },
  (prevProps, nextProps) =>
    prevProps.name === nextProps.name &&
    prevProps.hint === nextProps.hint &&
    prevProps.currencyFieldName === nextProps.currencyFieldName &&
    prevProps.currencies === nextProps.currencies &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.isTaskDetailsVariant === nextProps.isTaskDetailsVariant
);
