import { makeStyles } from '@material-ui/core';

export default makeStyles(({spacing}) => ({
  modalBody: {
    width: 500,
    background: '#fff',
    border: "1px solid rgba(38, 40, 66, 0.08)",
    boxShadow: "0px 16px 32px rgba(38, 40, 66, 0.08)",
    borderRadius: 12,

    overflow: 'hidden',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99
  },
  modalHeader: {
    padding: 24,
    paddingBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalContent: {
    padding: 20,
    paddingTop: 4,
    paddingBottom: 32,
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'end',
    gap: 8,
    padding: "12px 16px",
    background: '#F0F0F7',
    borderTop: '1px solid rgba(38, 40, 66, 0.08);'
  },
  modalButton: {
    borderRadius: 10,
    height: 40,
  },
  closeIcon: {
    '& > path': {
      fill: '#6D7C8B'
    }
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: '#262842'
  },
  description: {
    marginBottom: 60,
    fontSize: 16,
    color: '#8B8C9E',
  },
  radioButton: {
    border: 'none !important',
    backgroundColor: 'transparent !important',
    marginRight: spacing(2.5),
    '& svg path': {
      fill: '#8B8C9E !important',
    },
  },
  radioButtonChecked: {
    '& svg path': {
      fill: '#039BE6 !important',
    },
  },
  radioOptionLabel: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#101F2B',
    height: 40,
    marginRight: spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hiddenFieldsInfoBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    padding: '8px 12px',
    marginTop: 12,
    borderRadius: 10,
    border: '1px solid var(--color-main-primary-70, #8BD1F4)',
    backgroundColor: '#E6F5FD'
  },
  hiddenFieldsInfoBlockIcon: {
    height: 24,
    width: 24,
  },
  hiddenFieldsInfoBlockTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: '#262842',
    margin: 0,
    marginBottom: 6,
  },
  hiddenFieldsInfoBlockMessage: {
    fontSize: 12,
    fontWeight: 400,
    color: '#6D6E85',
    margin: 0,
  },
}))
