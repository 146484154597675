import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, palette }) => ({
  taskDetailsWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  taskDetailsLabelWrapper: {
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    marginRight: 16,
  },
  label: {
    position: 'relative',
  },
  taskDetailsInputWrapper: {
    width: 'calc(100% - 180px)',
  },
  addLinkWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  addLink: {
    cursor: 'pointer',
    padding: spacing(3),
    color: palette.primary[200],
    '& img': {
      height: 12,
      marginTop: 3.5,
      marginRight: spacing(3),
      cursor: 'pointer',
    },
  },
  error: {
    fontSize: 12,
    color: palette.error[500],
  },
  visible: {},
  hidden: {
    height: 0,
    width: 0,
    margin: 0,
    padding: 0,
    opacity: 0,
  },
  linkIcon: {
    marginRight: spacing(1.5),
  },
  linkValue: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '100%',
    '& a': {
      cursor: 'pointer',
      color: palette.primary[200],
      textDecoration: 'none',
      maxWidth: '85%',
      display: 'inline-block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  editButton: {
    cursor: 'pointer',
    marginLeft: spacing(3),
    height: 15,
    width: 15,
  },
}));

export default useStyles;
