import { makeStyles } from '@material-ui/core';

export default makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.grey[50],
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 1100,
    transitionProperty: 'width, height, margin, top, right, left, z-index',
    transitionDuration: '700ms',
    width: 960,
    maxWidth: '100vw',
    // overflowX: 'auto',
  },
  fullScreen: {
    width: 'calc(100% - 248px)',
    transition: '0.7 width',
  },
  DataViewFullScreen: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: '-24px !important',
    height: 'calc(100vh - 49px)',
    width: 'calc(100% + 26px) !important',
    margin: 0,
    zIndex: 150,
  },
  tabWrapper: {
    width: '100%',
    height: 'calc(100% - 230px)',
    margin: 0,
    padding: 0,
  },
  tabContentWrapper: {
    paddingLeft: spacing(6),
    height: '100%',
  },
  tabContentWrapperAttachmentsTab: {
    paddingRight: spacing(3),
    overflow: 'auto',
  },
  tabContentWrapperWithValidationError: {
    '& > div': {
      paddingBottom: 150,
    },
  },
  hiddenTabContent: {
    visibility: 'hidden',
    padding: '0 !important',
    margin: '0 !important',
    height: 0,
    opacity: 0,
    float: 'left',
    '& *': {
      padding: '0 !important',
      margin: '0 !important',
      height: '0 !important',
      minHeight: '0 !important',
      maxHeight: '0 !important',
      width: '0 !important',
      opacity: 0,
      visibility: 'hidden',
    },
  },
  dataViewSnackbar: {
    position: 'absolute',
    left: 0,
    bottom: 16,

    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    transition: '0.3s bottom ease',
  },
  isOpen: {
    bottom: 68,
  },
  dataViewFooter: {
    minHeight: 64,
    padding: 8,
    borderRadius: spacing(0, 0, 2, 2),
    backgroundColor: palette.grey[100],
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  errorMessage: {
    marginBottom: spacing(2),
  },
}));
