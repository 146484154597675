import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      maxWidth: 500
    },
    borderRadius: 12
  },
  popover: {
    position: 'absolute',
    top: '50vh',
    left: '50vw'
  },
  templateNameInput: {
    borderRadius: 12
  },
  content: {
    padding: theme.spacing(6),
    paddingTop: 0,
  },
  title: {
    fontSize: 20,
    color: "#262842"
  }

}));

export default useStyles;
