import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing }) => ({
  mainBox: {
    // height: `calc(100vh - 304px)`, // 100vh - header, pagination etc heights
    // overflowY: "scroll"
  },
  root: {
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    minWidth: 1048,
  },
  container: {
    width: '100%',
    borderColor: '#E0E3EA',
    borderRadius: '15px',
  },
  table: {
    borderCollapse: 'collapse',
    tableLayout: "fixed",

    "& tr:has(span input[type=checkbox]) td": {
      paddingLeft: 12
    },
    "& tr:has(span input[type=checkbox]) th": {
      paddingLeft: 12
    },

    "& tr:has(span input:not(:checked)) span": {
      visibility: "hidden",
    },
    "& tr:hover td:has(span input) span": {
      visibility: "visible"
    },
    "& tr:hover th:has(span input) span": {
      visibility: "visible"
    },
  },
  tableHeaderRow: {
    height: spacing(10),
    background: '#F5F8FA',
  },
  tableHeaderRowCell: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.3,
    color: '#465564',
    padding: 8,
    overflow: "hidden",
    width: "max-content",
  },
  tableBodyActionMenuListItem: {
    padding: spacing(2.75, 4),
    borderBottom: "1px solid rgba(38, 40, 66, 0.12)"
  },
  tableBodyActionMenuListItemText: {
    color: '#101F2B',
  },
  tableBodyActionMenuListItemIcon: {
    width: 20,
    height: 20,
    marginRight: spacing(2)
  }
}));
