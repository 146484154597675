import React, { useState } from 'react'
import { useUsersRole } from 'hooks';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { useRequests } from 'pages/Requests/useRequests';

import useStyles from './NewButtonRequestHeader.useStyles'
import ProcessSettingsButtonIcon from 'assets/images/icons/process-settings-button-icon.svg';
import CreateCustomProcessIcon from 'assets/images/icons/new_custom_process.svg';
import CreateProcessIcon from 'assets/images/icons/tasks_app_regular.svg';


export const NewButtonRequestHeader = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isGroupSettingPageAvailable, isTemplateProcessCreationAvailable } = useUsersRole();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const history = useHistory();

  const {
    openDialog,
    navigateToProcessSettings
  } = useRequests();

  return <>
    {isGroupSettingPageAvailable && (
      <Button onClick={navigateToProcessSettings} size="medium" className={classes.settingsButton}
        color="secondary">
        <img src={ProcessSettingsButtonIcon} alt="settings" />
      </Button>
    )}

    {
      isTemplateProcessCreationAvailable ? <Button
        size="large"
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={({currentTarget}) => setAnchorEl(currentTarget)}
        className={classes.newRequestButton}
      >
        {t('Processes.newButton')}
      </Button> : <Button
        size="large"
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={openDialog}
        className={classes.newRequestButton}
      >
        {t('Processes.Request')}
      </Button>
    }

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            width: "fit-content",
            maxWidth: "fit-content",
            border: '1px solid rgba(38, 40, 66, 0.08)',
            boxShadow: '0px 8px 16px rgba(38, 40, 66, 0.04)',
            borderRadius: 10,
            marginTop: 8,
          },
        }}
      >
        <MenuItem className={classes.menuItem} onClick={() => {
          openDialog();
          setAnchorEl(null);
        }}>
          <img src={CreateProcessIcon} alt="" />
          {t('Processes.NewRequest')}
        </MenuItem>

        <MenuItem className={classes.menuItem} onClick={() => {
          setAnchorEl(null);
          history.push('/template-processes', {
            openCreateCustomProcessDialog: true
          })
        }}>
          <img src={CreateCustomProcessIcon} alt='' />
          {t('Processes.NewCustomProcess')}
        </MenuItem>
      </Menu>

  </>
}
