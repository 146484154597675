import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useUserProfile } from 'hooks';
import { useUsersState } from 'store/users';
import { useTaskState } from 'store/requests';
import { getUserDataByIdOrLogName } from 'utils/user';
import { getTranslationKeyForTaskHistoryInPast } from 'utils/translations';

import { ReworkStepsListItem } from './ActionCommentDialog';
import { APPROVE_STAGES_TYPES } from '../TaskPdfGenerator/TaskPdfGenerator.constants';
import useStyles from './useStyles';
import { Tooltip } from '@material-ui/core';

type Props = {
  step: ReworkStepsListItem;
};

export const ReworkStepsSelectItem = ({
  step,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { companyId } = useUserProfile();
  const { users } = useUsersState();
  const { data: bpmTask } = useTaskState();

  const [assigneeFullName, setAssigneeFullName] = useState('');

  const getUserData = async (assigneeKey: string) => {
    if (users[assigneeKey]) {
      setAssigneeFullName(users[assigneeKey]?.fullName);
      return;
    }

    const userInfo = await getUserDataByIdOrLogName(assigneeKey, companyId);
    if (userInfo) {
      setAssigneeFullName(userInfo?.fullName);
      return;
    }

    setAssigneeFullName(t('task_data_view.employee_not_found'));
  };

  useEffect(() => {
    if (!step.parallelBranch) {
      const firstAssigneeKey = step?.assignee?.includes(',') ? step?.assignee?.split(',')[0] : step?.assignee;
      getUserData(firstAssigneeKey);
    }
  }, [step]);

  if (step.parallelBranch) {
    return (
      <>
        <Tooltip
          title={step?.label}
          arrow
          enterDelay={300}
          placement="bottom"
          classes={{
            tooltip: classes.reworkStepLabelTooltip,
          }}
        >
          <span>{step?.label}</span>
        </Tooltip>

        {t('task_data_view.history_tab.parallelBranchesCountTemplate', { count: step?.possibleSteps?.length })}
      </>
    );
  }

  return (
    <>
      <Tooltip
        title={step?.label}
        arrow
        enterDelay={300}
        placement="bottom"
        classes={{
          tooltip: classes.reworkStepLabelTooltip,
        }}
      >
        <span>{step?.label}</span>
      </Tooltip>

      {assigneeFullName}
      {' '}
      {step?.parallel && step?.assignee?.split(',').length > 1
       ? ` (+${step?.assignee?.split(',').length - 1})`
       : ''} - {step?.parallel ? t('task_history_past.parallelApprove') : t(getTranslationKeyForTaskHistoryInPast(step.approveStage?.substring(1, step.approveStage.length - 1) || APPROVE_STAGES_TYPES.DEFAULT_STEP))}
      {' '}
      {moment(step.taskStartDate).format('D MMMM Y, HH:mm')}
    </>
  );
};
