import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing }) => ({
  dialogHeader: {
    height: 80,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.12)',
    padding: spacing(5, 8)
  },
  dialogHeaderButtonIcon: {
    '& > path': {
      fill: '#6D7C8B !important'
    }
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',

    height: 'calc(100vh - 160px)',
    overflow: 'auto',
    padding: spacing(5, 6)
  },
  dialogContentHintBlockTitle: {
    color: '#8A96A1'
  },
  dialogContentHintBlockSubtitle: {
    fontSize: 16,
    lineHeight: 1.3,
    color: '#101F2B',

    marginLeft: spacing(0.5)
  },
  dialogContentForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'flex-start',
    flexGrow: 1,
    width: 490
  },
  dialogContentFormTitle: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.3,
    color: '#101F2B'
  },
  dialogContentFormSubtitle: {
    color: '#465564',
    marginTop: spacing(3)
  },
  dialogContentFormTabs: {
    borderBottom: '1px solid #E0E3EA',
    '& .MuiTabs-indicator': {
      display: 'block'
    },
  },
  dialogContentFormTabsTab: {
    width: 'auto',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3,
    color: '#101F2B',
    textTransform: 'none',
    minWidth: 80,
    borderBottom: 'none',
    '&.Mui-selected': {
      border: 'none !important',
    }
  },
  dialogContentFormTabsTabWithError: {
    '& span:after': {
      content: '""',
      display: 'block',
      backgroundColor: '#ED4C36',
      height: 6,
      width: 6,
      borderRadius: '50%',
      position: 'absolute',
      right: 3,
      top: 9,
    },
  },
  dialogContentLanguageButton: {
    backgroundColor: '#E4F6FE'
  },
  dialogContentLanguageButtonIcon: {
    '& > path': {
      fill: '#6D7C8B'
    }
  },
  dialogContentLanguageMenu: {
    borderRadius: spacing(1)
  },
  dialogContentLanguageMenuItem: {
    minWidth: 280,

    display: 'flex',
    alignItems: 'center',

    padding: spacing(3, 4)
  },
  dialogFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    height: 80,

    backgroundColor: '#FFFFFF',
    borderTop: '1px solid #E0E3EA',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.12)',
    padding: spacing(5, 6)
  },
  formErrorText: {
    display: "flex",
    gap: 5,

    marginLeft: 'auto',
    marginRight: 12,

    fontSize: 12,
    color: '#D6331F',
  },
  publishBtn: {
    borderRadius: 10
  }
}));
