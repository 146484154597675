import React, { memo, ReactNode, ReactElement, useMemo } from 'react';

import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import {
  ContentHeader,
  SubmitSnackbar,
  DarkOverlay,
  MainTabs,
  TaskDetails,
} from 'components';
import { useSelectedApproval } from 'store/approvals';
import { useSelectedRequest } from 'store/requests';
import useTaskForm from 'pages/Task/TaskForm/useTaskForm';

import useStyles from './useStyles';

type ContentLayoutProps = {
  children?: ReactNode;
  hasMainMenuTabs?: boolean;
  headerChild?: ReactNode;
  title?: string;
  zIndexContent?: number;
};

export const ContentLayout = memo(
  ({
    children,
    hasMainMenuTabs = true,
    headerChild,
    title = null,
    zIndexContent,
  }: ContentLayoutProps): ReactElement => {
    const classes = useStyles({ zIndexContent });
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const { startNewProcess } = useTaskForm({});
    const selectedApprovalId = useSelectedApproval();
    const selectedRequestId = useSelectedRequest();
    const isTaskDetailsOpen = Boolean(selectedApprovalId || selectedRequestId);

    const contentClass = useMemo(() => {
      switch (pathname) {
        case '/requests/active':
        case '/requests/completed':
        case '/requests/drafts':
        case '/approvals/active':
        case '/approvals/reviewed':
          return classes.contentRequests;
        default:
          return classes.content;
      }
    }, [pathname, classes]);

    const backgroundWrapperClass = useMemo(() => {
      switch (pathname) {
        case '/requests/active':
        case '/requests/completed':
        case '/requests/drafts':
          return classes.backgroundPartWrapperRequests;
        case '/approvals/active':
        case '/approvals/reviewed':
        case '/monitoring/active':
        case '/monitoring/completed':
          return classes.backgroundPartWrapperApprovals;
        default:
          return null;
      }
    }, [pathname, classes]);

    const headerClass = useMemo(() => {
      switch (pathname) {
        case '/requests/active':
        case '/requests/completed':
        case '/requests/drafts':
          return classes.headerRequests;
        default:
      }
    }, [pathname, classes]);

    const mainTabsClass = useMemo(() => {
      switch (pathname) {
        case '/requests/active':
        case '/requests/completed':
        case '/requests/drafts':
          return classes.mainTabsRequests;
        default:
      }
    }, [pathname, classes]);

    return (
      <>
        <Box className={classes.root}>
          <Box className={classes.contentWrapper}>
            <Box className={contentClass}>
              <ContentHeader
                className={headerClass}
                title={title || t('Processes.module')}
              >
                {headerChild}
              </ContentHeader>
              {hasMainMenuTabs && (
                <Box className={mainTabsClass}>
                  <MainTabs />
                </Box>
              )}
              <div
                className={cn({
                  [backgroundWrapperClass]: pathname !== '/analytics',
                })}
              >
                {children}
              </div>
            </Box>
            {isTaskDetailsOpen && (
              <Box className={classes.dataViewWrapper}>
                <TaskDetails />
              </Box>
            )}
          </Box>
        </Box>
        <DarkOverlay />
        <SubmitSnackbar startNewProcess={startNewProcess} />
      </>
    );
  }
);
