import React, { memo, useMemo } from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';

import { useSearchHelper } from './useSearchHelper';

import useStyles from "./useStyles";
import CloseIcon from 'assets/images/icons/close-icon.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  currentPage: number,
  pageSize: number,
  searchText: string,
  onClose: () => void
}

const HELPER_INITIAL_TEXT = "search.initial_text";
const HELPER_SEARCH_TEXT = "search.searching_for";

export const SearchHelper = memo(({
  currentPage,
  pageSize,
  searchText,
  onClose
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // const {
  //   onHistoryDelete
  // } = useSearchHelper({
  //   currentPage,
  //   pageSize
  // });

  const helperPlaceholder = useMemo(() =>
    searchText ?
      `${t(HELPER_SEARCH_TEXT)} "${searchText}"` :
      t(HELPER_INITIAL_TEXT),
    [searchText]);

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{helperPlaceholder}</Typography>

      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.button}
          onClick={onClose}>
          <img src={CloseIcon} alt="close" />
        </IconButton>
      )}
    </Box>
  )
});
