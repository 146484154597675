// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: true,
  env: 'PROD',
  baseUrl: 'https://dms.dar.io',
  appUrl: 'https://dms.dar.io',
  landingUrl: 'https://darlean.com/kz?region=kz',

  NX_BASE_V1_API: 'https://dms.dar.io/api/v1',
  NX_BASE_V1_WS_API: 'wss://dms.dar.io/api/v1/dms-web-socket',
  NX_BASE_HCMS_API: 'https://dms-hcms.dar.io/api',
  NX_GOOGLE_BASE_API: 'https://www.googleapis.com',

  /* Current Google credentials is from dmsbpm@dar.kz account.
  For accesses to dmsbpm@dar.kz, contact to dar tech support */
  NX_GOOGLE_CALENDAR_API_KEY: 'AIzaSyDs09kBnGIps7X1G3LQ7px-DS9dmMdxHUE',
  NX_DEFAULT_PARAGRAPH_ACCOUNT: '15abab88-0fe5-4353-b587-cff1ad3bc6ef',

  isDocumentAdditionInTemplateProcessesAvailable: true,
};
