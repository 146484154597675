import { makeStyles } from '@material-ui/core';

export default makeStyles(({ palette, spacing }) => ({
  root: {
    width: 'calc(100% - 24px - 24px - 2px)',
    zIndex: 100,
    position: 'absolute',
    right: 24,
    bottom: 24,
    padding: '8px 12px 8px 12px',
    color: '#262842',
    background: '#FAEEED',
    borderRadius: 10,
    border: '1px solid #FEC7C3',
  },
  closeIcon: {
    position: 'absolute',
    right: 12,
    top: 10,
    height: 16,
    width: 16,
    cursor: 'pointer'
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '18px',
  },
  errorsList: {
    marginTop: 8,
    paddingLeft: 20,
    listStyle: 'disc'
  },
  errorMessage: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
  },
}));
