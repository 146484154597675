import React from 'react';

import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button
} from '@material-ui/core';
import classNames from 'classnames';

import CloseIconGreyIcon from 'assets/images/icons/close.svg';
import useStyles from './index.styles';

interface PromptModalModalProps {
  open?: boolean;
  onClose: any;
  header: string;
  description: string;
  onCancel: any;
  onConfirm: any;
  leaveComment?: boolean;
  cancelText?: string;
  confirmText?: string;
  cancelColor?: string;
  confirmColor?: string;
};

export const PromptModal = ({
  open = false,
  onClose,
  description,
  header,
  onCancel,
  onConfirm,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
}: PromptModalModalProps) => {
  const classes = useStyles();
  
  return (
    <Dialog open={open} onClose={onClose} PaperProps={{
      style: {
        borderRadius: '12px'
      },
    }}>
      <DialogContent>
        <div className={classes.headerWrapper}>
          <Typography className={classes.header} variant="h3">{header}</Typography>
          <img onClick={onClose} src={CloseIconGreyIcon} className={classes.closeIcon} alt="Close icon" />
        </div> 
        <Typography className={classes.body} variant="body1">{description}</Typography>
      </DialogContent>
      <DialogActions className={classes.actionsSection}>
        <Button className={classNames(classes.cancelActionButton, classes.generalButtons)} color="secondary" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button className={classNames(classes.confirmActionButton, classes.generalButtons)} onClick={onConfirm}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
