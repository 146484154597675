import React, { ReactElement, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box, Typography,
  ButtonBase,
  TextField,
  InputAdornment,
  Modal,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';
import Search from '@material-ui/icons/Search';
import { NotificationManager } from 'react-notifications';


import {
  SetupTemplateProcessesDialog,
} from 'pages/TemplateProcesses/components';

import {
  useTemplateProcesses
} from 'pages/TemplateProcesses/useTemplateProcesses';


import { CustomPagination, ErrorMessage, MainTabs, Spinner, NewButtonRequestHeader, NoContentMessage } from 'components';

import { StatusType } from 'types';

import { TemplatesTable } from './components/TemplatesTable';

import { useTemplatesRequest } from './hooks/useTemplatesRequest';
import { useDebouncedSearchText } from './hooks/useDebouncedSearchText';
import { useFilter } from './hooks/useFilter';

import { TemplatesTableRow } from './components/TemplatesTable/TemplatesTable.types';
import useStyles from './Templates.useStyles';
import MenuEditIcon from 'assets/images/icons/template_processes_menu_edit_icon.svg';
import MenuDeleteIcon from 'assets/images/icons/template_processes_menu_delete_icon.svg';
import MenuArchiveIcon from 'assets/images/icons/template_processes_menu_archive_icon.svg';

import { useHistory, useParams } from 'react-router-dom';
import { useGetTemplateById } from './hooks/useGetTemplateById';
import { ConfirmationModal } from 'components/Modal/ConfirmationModal';

import { deleteTemplateBySysId, archieveTemplateBySysId } from "api/requests";


export const Templates = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { id: templateId } = useParams<{ id?: string }>();
  const {
    currentFilterValues,
    setCurrentFilterValues
  } = useFilter();

  const [anchorElement, setAnchorElement] = useState<HTMLElement>(null);

  const { handleSearchTextChange, searchFieldText } = useDebouncedSearchText({
    doWithNewValue: (searchText) => setCurrentFilterValues({ processName: searchText })
  });

  const { requests, loadingStatus, totalElements, totalPages, getTemplates } = useTemplatesRequest({
    filterValues: currentFilterValues,
    setFilterValues: setCurrentFilterValues
  })

  const {
    isSetupTemplateDialogOpen,
    templateSettings,
    departmentsList,
    availableLocalesList,
    currentRow,
    setCurrentRow,
    currentProcess,
    currentProcessPublishedVersion,
    areValuesChanged,
    needResetProcessForm,
    setNeedResetProcessForm,
    getSettingsProcessCompany,
    handleAutostartActionsClick,
    handleProcessNameClick,
    handleProcessEditClick,
    setTemplateSettings,
    handleSetupTemplateDialogClose,
    handleTemplateProcessDeactivate,
    handleBackButtonClick,
    handlePublishedProcessesLimitDialogOpen,
    handleProcessDeleteConfirmationDialogOpen,
    loading,
    onDuplicateProcessDialogOpen,
    handleTemplatesListDialogClose,
    onCopyProcessDialogOpen,
  } = useTemplateProcesses(null, templateId);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isArchieveDialogOpen, setIsArchiveDialogOpen] = useState(false)

  const  handleDeleteTemplate = async () => {
    const id = currentRow?.processSysName

    if(!id) return
    return deleteTemplateBySysId(id)
  }

  const handleArchieveTemplate = async () => {
    const id = currentRow?.processSysName

    if(!id) return
    return archieveTemplateBySysId(id)
  }

  const menuButtons = useMemo(() => {
    const menu = currentRow ? [
      {
        title: t('customProcesses.table.actions.delete'),
        visible: !currentRow?.published,
        onClick: () => {
          setIsDeleteDialogOpen(true)
        },
        icon: MenuDeleteIcon
      },
      {
        title: t('templates.actions.archieve'),
        visible: currentRow?.published,
        onClick: () => {
          setIsArchiveDialogOpen(true)
        },
        icon: MenuArchiveIcon
      },
      {
        title: t('buttons.edit'),
        visible: true,
        onClick: handleProcessEditClick,
        icon: MenuEditIcon
      },
    ].filter(({visible}) => visible) : []


    return menu
  },
    [t, currentRow]);

  const renderRequestsList = useMemo((): ReactElement => {
    switch (loadingStatus) {
      case StatusType.RESOLVED:
        // eslint-disable-next-line no-case-declarations
        let content = null;

        if (requests.length > 0) {
          content = (
            <Box pt={4}>
              <TemplatesTable
                requests={requests}
                setCurrentRow={setCurrentRow}
                setAnchorElement={setAnchorElement}
                anchorElement={anchorElement}
                menuButtons={menuButtons}
                handleProcessNameClick={handleProcessNameClick}
              />

              <Box display="flex" justifyContent="end" alignItems="center" gridGap={24}>

                <Typography className={classes.paginationText}>
                  <Trans
                    i18nKey="customProcesses.table.pagination"
                    values={{
                      from: currentFilterValues.pageSize * currentFilterValues.page + 1,
                      to: Math.min(currentFilterValues.pageSize * (currentFilterValues.page + 1), totalElements),
                      total: totalElements,
                    }} />
                </Typography>

                {totalElements > 10 &&
                  <CustomPagination
                    pageSize={currentFilterValues.pageSize}
                    currentPage={currentFilterValues.page + 1}
                    handlePageSizeChange={(e) => {
                      setCurrentFilterValues({
                        pageSize: Number(e.target.value),
                        page: 0,
                      });
                    }}
                    handlePageChange={(_, page) => {
                      setCurrentFilterValues({
                        page: page - 1,
                      });
                    }}
                    totalPages={totalPages}
                  />
                }
              </Box>
            </Box>
          )
        } else if (currentFilterValues.processName?.trim()){
          content = <NoContentMessage noResultsWithFilters />;
        } else {
          content = <NoContentMessage message={t("templates.no_requests")} additionalMessage={t("templates.no_requests_description")} />;
        }

        if(requests.length === 0 && totalElements !== 0){
          return  <Spinner absolute={false} />
        }

        return <Box display="flex" flexGrow={1} alignItems='center' justifyContent="center">
          {content}
        </Box>
      case StatusType.REJECTED: {
        return (
          <ErrorMessage text={t('errors.somethingIsWrong')} />
        );
      }
      case StatusType.PENDING:
      default: {
        return (
          <Spinner absolute={false} />
        );
      }
    }
  }, [loadingStatus, anchorElement]);

  return (
    <Box className={classes.root}>
      <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.headerWrapper}>
        <Typography className={classes.mainTitle}>
          {t('Processes.module')}
        </Typography>

        <Box>
          <NewButtonRequestHeader />
        </Box>
      </Box>

      <MainTabs />

      <Box display="flex" alignItems="center" gridGap={12}>
        <TextField
          size="medium"
          className={classes.searchInput}
          value={searchFieldText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={classes.searchIconWrapper}>
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={event => handleSearchTextChange(event.target.value)}
        />
      </Box>



      {renderRequestsList}

      {loading && <Spinner absolute={true} />}

      <Modal open={isDeleteDialogOpen}>
        <ConfirmationModal
          description={t('customProcesses.creationPage.deleteModal.description')}
          title={t("templates.actions.deleteTemplate")}
          okButtonText={t('customProcesses.creationPage.buttons.delete')}
          close={() => {
            setIsDeleteDialogOpen(false)
            setCurrentRow(null)
          }}
          onSuccessAction={() => {
            setIsDeleteDialogOpen(false)
            getTemplates();
            NotificationManager.success(t("templates.notifications.templatedDeleted"))
          }}
          action={handleDeleteTemplate}
          />
      </Modal>


      <Modal open={isArchieveDialogOpen}>
        <ConfirmationModal
          title={t("templates.actions.archieve")}
          description={t('templates.actions.archieveDescription')}
          okButtonText={t('templates.actions.archieve')}
          close={() => {
            setIsArchiveDialogOpen(false)
            setCurrentRow(null)
          }}
          onSuccessAction={() => {
            setIsArchiveDialogOpen(false)
            getTemplates();
            NotificationManager.success(t("templates.notifications.templateArchieved"))
          }}
          action={handleArchieveTemplate}
          />
      </Modal>

    <SetupTemplateProcessesDialog
        initialValues={currentProcess}
        publishedVersionValues={currentProcessPublishedVersion}
        departmentsList={departmentsList}
        availableLocales={availableLocalesList}
        isOpen={isSetupTemplateDialogOpen}
        templateSettings={templateSettings}
        haveTemplateSettingsChanged={areValuesChanged}
        onClose={() => {
          handleSetupTemplateDialogClose()
          handleTemplatesListDialogClose()
          history.replace("/templates",)
        }}
        onFormSubmit={async () => {
          getSettingsProcessCompany()
          getTemplates()
        }}
        onBackButtonClick={() => {
          handleBackButtonClick()
        }}
        onProcessArchive={handleTemplateProcessDeactivate}
        onDuplicateProcessDialogOpen={onDuplicateProcessDialogOpen}
        onCopyProcessDialogOpen={onCopyProcessDialogOpen}
        setTemplateSettings={setTemplateSettings}
        needResetProcessForm={needResetProcessForm}
        setNeedResetProcessForm={setNeedResetProcessForm}
        handleAutostartActionsClick={handleAutostartActionsClick}
        handlePublishedProcessesLimitDialogOpen={handlePublishedProcessesLimitDialogOpen}
        handleProcessDeleteConfirmationDialogOpen={handleProcessDeleteConfirmationDialogOpen}
      />

    </Box>
  );
};

export default Templates;
