import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Button, Typography } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import useEdsForm from '../EdsForm/useEdsForm';

type Props = {
  type: 'button';
  variant: 'contained';
  color: 'primary';
  className?: string;
  wrapperClassName?: string;
  label: string;
  userModelId: string;
  attachmentName: string;
  attachmentStorage?: string;
  binAttribute: string;
  companyAttribute: string;
  individualNumberAttribute: string;
  emailAttribute: string;
  supplierEmailAttribute: string;
  existingSupplierAttribute: string;
  supplierBinAttribute: string;
  disabled: boolean;
  isInternalSign: boolean;
  isExternalSign: boolean;
  isInternalSignWithEmployee: boolean;
  isInternalSignEmployeeInitiator: boolean;
  onClick: () => void;
  icon?: string | React.ReactNode;
};

export const EdsButtonCallHook = ({
  type,
  variant,
  label,
  color,
  className,
  wrapperClassName,
  disabled,
  userModelId,
  attachmentName,
  attachmentStorage,
  binAttribute,
  companyAttribute,
  individualNumberAttribute,
  emailAttribute,
  supplierEmailAttribute,
  existingSupplierAttribute,
  supplierBinAttribute,
  isInternalSign,
  isExternalSign,
  isInternalSignWithEmployee,
  isInternalSignEmployeeInitiator,
  onClick,
  icon,
}: Props) => {
  const { t } = useTranslation();

  const { trigger } = useFormContext();
  const { loading, error, buttonLabel, handleClientSignature } = useEdsForm({
    userModelId,
    labelValue: label,
    attachmentName,
    attachmentStorage,
    binAttribute,
    companyAttribute,
    individualNumberAttribute,
    emailAttribute,
    supplierEmailAttribute,
    existingSupplierAttribute,
    supplierBinAttribute,
    isInternalSign,
    isExternalSign,
    isInternalSignWithEmployee,
    isInternalSignEmployeeInitiator,
    onClick,
  });

  const handleClick = async () => {
    const isFormValid = await trigger();
    if (!isFormValid) {
      return;
    }
    handleClientSignature();
  };

  return (
    <Box display="flex" flexDirection="column" className={wrapperClassName}>
      <Button type={type} variant={variant} className={className} color={color} disabled={disabled || loading} onClick={handleClick}>
        {icon ? (
          typeof icon === 'string' ? (
            <img src={icon} style={{ width: 20, height: 20, marginRight: 6 }} alt="Icon" />
          ) : (
            <div style={{ marginRight: 6 }}>{icon}</div>
          )
        ) : null}
        <span>{t(buttonLabel)}</span>
        {loading && (
          <Box display="flex" alignItems="center" ml={1}>
            <CircularProgress size={24} />
          </Box>
        )}
      </Button>

      {!!error && (
        <Box mt={2}>
          <Typography color="secondary">{t(error)}</Typography>
        </Box>
      )}
    </Box>
  );
};
