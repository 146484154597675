import React from 'react';
import { LocalizationProvider, DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';
// import { SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { FormHelperText } from '@mui/material';
// import theme from 'theme';

// import { ReactComponent as CalendarIcon } from 'assets/images/emojis/calendar.svg';

const LOCALES = {
  en: 'en-GB',
  kz: 'kk',
  ru: 'ru',
  id: 'id',
};

type Props = {
  name?: string;
  [key: string]: any;
  value?: Date | string;
  onChange?: (arg: Date) => void;
  checkDateDisabled?: (arg: Date) => boolean;
  disabled?: boolean;
  disableKeyboardInput?: boolean;
  loading?: boolean;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
};

export const DatePicker = ({
  name,
  value,
  onChange,
  disabled,
  disableKeyboardInput = false,
  loading,
  checkDateDisabled = (arg: Date) => false,
  placeholder = null,
  error,
  helperText,
  ...props
}: Props) => {
  const { i18n } = useTranslation();

  moment.locale(LOCALES[i18n.language] || i18n.language);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker
        {...props}
        format="DD/MM/YYYY"
        value={value ? moment(value) : null}
        onChange={(value) => (value ? onChange(value.toDate()) : onChange(null))}
        shouldDisableDate={(value) => checkDateDisabled(value.toDate())}
        disabled={loading || disabled}
        slots={{
          textField: TextField,
        }}
        slotProps={{
          textField: {
            variant: 'outlined',
            color: 'primary',
            placeholder: placeholder,
          },
        }}
      />
      {error && helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </LocalizationProvider>
  );
};
