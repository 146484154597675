import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { Box, Button, ClickAwayListener, LinearProgress, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'hooks';
import {
  getValuesByNameFragment,
} from 'api/requests';

import { useInputStyles, useTreeItemStyles, useTreeStyles } from './TreeGlossary.styles';
import Styled from './TreeGlossary.styles';

import ChevronDown from 'assets/images/icons/chevron_down_grey.svg';
import ChevronRight from 'assets/images/icons/chevron_right_grey.svg';
import DropdownIndicatorIcon from 'assets/images/icons/dropdown-indicator.svg';
import ClearIcon from 'assets/images/icons/close-icon-thin-light-grey.svg';

import { initializeSelectedStates } from 'pages/Task/TaskForm/FormFields/Fields/Glossary/utils';
import cn from 'classnames';

type DirectoryInfo = {
  id: string;
  [key: string]: any;
}

export type TreeStructureNode = {
  data: DirectoryInfo;
  id: string;
  children: TreeStructureNode[];
  selectedChildCount?: number;
  totalChildCount?: number;
}

type Props = {
  rootNode: TreeStructureNode;
  onSelect: (value: any) => void;
  value: string | null;
  disabled?: boolean;
  leftSideInput?: boolean;
}

enum TreeType {
  DIRECTORY,
  DIRECTORY_ITEM,
}

enum FetchingState {
  SUCCESS,
  ERROR,
  LOADING,
  IDLE,
}

const InfoBox = ({ children, ...rest }) => <Box width="100%" height={40} pt={4} px={2}
                                                pb={1} {...rest}>{children}</Box>;

interface ProjectChildSelectProps {
  directoryName?: string;
  value?: string;
  type: TreeType;
  valueParentId?: string;
  valuesCount?: number;
  disabledItemsIds?: string[];
  id?: string;
  item?: any;
  localization?: { [key: string]: string };
  label?: string;
  isBuilderVariant?: boolean;
  forceEnglish?: boolean;
  children?: any[];
  selectedIds: string[];
  handleItemSelect: (id) => void;
  treeStructureNodes: { [key: string]: any };
  onSelect: (value: any) => void;
};

const ProjectChildSelect: FC<ProjectChildSelectProps> = (props) => {
  const classes = useTreeItemStyles();
  const treeClasses = useTreeStyles();
  const { t, i18n } = useTranslation();
  const [childNodes, setChildNodes] = useState(null);
  const [expanded, setExpanded] = React.useState([]);
  const [fetchingState, setFetchingState] = useState(FetchingState.IDLE);
  const childType = props.isBuilderVariant ? TreeType.DIRECTORY_ITEM : props.type === TreeType.DIRECTORY
                                                                       ? TreeType.DIRECTORY_ITEM
                                                                       : TreeType.DIRECTORY;
  const label = props?.localization[i18n.language] || props.directoryName || props.value;

  const handleChange = (event, nodes) => {
    const expandingNodes = nodes.filter(x => !expanded.includes(x));
    setExpanded(nodes);
    if (expandingNodes[0]) {
      const child = expandingNodes[0];
      getChildNodes(child);
    }
  };

  const getChildNodes = (id: string) => {
    setChildNodes(
      props.children
        .filter(node => node.data.status === 'PUBLISHED')
        .map(
          node => <ProjectChildSelect
            type={childType}
            key={node.id}
            item={node.data}
            children={node.children}
            disabledItemsIds={props.disabledItemsIds}
            {...node.data}
            id={node.id}
            valueParentId={id}
            isBuilderVariant={props.isBuilderVariant}
            forceEnglish={props.forceEnglish}
            selectedIds={props.selectedIds}
            handleItemSelect={props.handleItemSelect}
            treeStructureNodes={props.treeStructureNodes}
          />,
        ),
    );
  };

  useEffect(() => {
    if (expanded) {
      getChildNodes(null);
    }
  }, [props.selectedIds, props.treeStructureNodes]);

  const renderNodes = () => {
    switch (fetchingState) {
      case FetchingState.LOADING: {
        return props.valuesCount ? [<InfoBox><LinearProgress color="secondary"/></InfoBox>] : [];
      }
      case FetchingState.ERROR: {
        return props.valuesCount ? [
          <InfoBox display="flex" justifyContent="space-between" alignItems="center" pt={2} height={46}>
            <span>{t('form_components.tree_glossary.fetchingError')}</span>
            <Button onClick={() => getChildNodes(props?.id)}>{t('form_components.tree_glossary.tryAgain')}</Button>
          </InfoBox>,
        ] : [];
      }
      case FetchingState.SUCCESS:
      case FetchingState.IDLE:
      default: {
        return childNodes || [<div/>];
      }
    }
  };

  const handleItemClick = (event) => {
    event.stopPropagation();
    if (props.children?.filter(node => node.data.status === 'PUBLISHED')?.length === 0) {
      props.handleItemSelect(props.id);
    }
  };

  return (
    <TreeView
      className={treeClasses.root}
      defaultCollapseIcon={<img src={ChevronDown}/>}
      defaultExpandIcon={<img src={ChevronRight}/>}
      expanded={expanded}
      onNodeToggle={handleChange}
    >
      <TreeItem
        nodeId={props.id}
        label={<div className={classes.labelRoot}>
          <Typography
            variant="body2"
            className={classes.labelText}>
            {label}
          </Typography>
        </div>}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
          iconContainer: props.valuesCount ? classes.iconContainer : classes.hideIconContainer,
        }}
        onClick={handleItemClick}
      >
        {renderNodes()}
      </TreeItem>
    </TreeView>
  );
};

export const FiltersGlossaryInput = ({
  rootNode,
  onSelect,
  value,
  disabled = false,
  leftSideInput = false,
}: Props) => {
  const { t, i18n } = useTranslation();
  const selectContentRef = useRef();
  const textFieldRef = useRef();

  const treeClasses = useTreeStyles();
  const inputClasses = useInputStyles();
  const [selectedValue, setSelectedValue] = useState(null);
  const [treeStructure, setTreeStructure] = useState([]);
  const [treeStructureNodes, setTreeStructureNodes] = useState({});
  const [childNodes, setChildNodes] = useState(null);
  const [expanded, setExpanded] = React.useState([]);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, 500);
  const [searchResultNodes, setSearchResultNodes] = useState([]);
  const [open, setOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [isClickAwayListenerActive, setClickAwayListenerActive] = useState(false);

  const handleSelect = (value) => {
    onSelect(value);
    setSelectedValue(value);
    setOpen(false);
    setAnchorElement(null);
  };

  const handleItemSelect = (itemId) => {
    const treeNode = Object.values(treeStructureNodes).find(node => (node as TreeStructureNode).id === itemId);
    handleSelect(treeNode);
  };

  useEffect(() => {
    const nodesList = initializeSelectedStates(treeStructure, [], true);
    setTreeStructureNodes(nodesList);
  }, [treeStructure]);

  useEffect(() => {
    setChildNodes((debouncedInputValue ? searchResultNodes : treeStructure)
      ?.filter(node => node.data.status === 'PUBLISHED')
      ?.map(
        directory => {
          return <ProjectChildSelect
            key={directory.id}
            onSelect={handleSelect}
            disabledItemsIds={[]}
            item={directory.data}
            children={directory.children}
            {...directory.data}
            id={directory.id}
            type={TreeType.DIRECTORY_ITEM}
            label={directory.data.value}
            isBuilderVariant={false}
            forceEnglish={false}
            selectedIds={[]}
            handleItemSelect={handleItemSelect}
            treeStructureNodes={treeStructureNodes}
          />;
        },
      ));
  }, [treeStructureNodes, treeStructure, debouncedInputValue, searchResultNodes]);

  const init = async () => {
    const nodesList = initializeSelectedStates((rootNode as unknown as TreeStructureNode)?.children.filter(node => node.data.status === 'PUBLISHED'), [], false);
    setTreeStructureNodes(nodesList);
    setTreeStructure((rootNode as unknown as TreeStructureNode)?.children.filter(node => node.data.status === 'PUBLISHED'));
    setChildNodes((rootNode as unknown as TreeStructureNode)?.children
      .filter(node => node.data.status === 'PUBLISHED')
      .map(
        directory => {
          return <ProjectChildSelect
            key={directory.id}
            onSelect={handleSelect}
            disabledItemsIds={[]}
            item={directory.data}
            children={directory.children}
            {...directory.data}
            id={directory.id}
            type={TreeType.DIRECTORY_ITEM}
            label={directory.data.value}
            isBuilderVariant={false}
            forceEnglish={false}
            selectedIds={[]}
            handleItemSelect={handleItemSelect}
            treeStructureNodes={treeStructureNodes}
          />;
        },
      ));
  };

  useEffect(() => {
    init();
  }, [rootNode]);

  const searchHandler = async () => {
    if (debouncedInputValue === '') {
      setSearchResultNodes([]);
      return init();
    }
    getValuesByNameFragment(debouncedInputValue, 'PUBLISHED', false)
      .then(
        hierarchy => {
          const resultNodes = hierarchy
            .flatMap(node => Object.values(treeStructureNodes)
              ?.filter(item => (item as TreeStructureNode).data.id === node.id),
            ).filter(Boolean);
          setSearchResultNodes(resultNodes);
          setChildNodes(resultNodes
            .filter(node => node.data.status === 'PUBLISHED')
            .map(
              directory => {
                return <ProjectChildSelect
                  key={directory.id}
                  onSelect={handleSelect}
                  disabledItemsIds={[]}
                  item={directory.data}
                  children={directory.children}
                  {...directory.data}
                  id={directory.id}
                  type={TreeType.DIRECTORY_ITEM}
                  label={directory.data.value}
                  isBuilderVariant={false}
                  forceEnglish={false}
                  selectedIds={[]}
                  handleItemSelect={handleItemSelect}
                  treeStructureNodes={treeStructureNodes}
                />;
              },
            ));
        },
      );
  };

  useEffect(() => {
    searchHandler();
  }, [debouncedInputValue]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleChange = (event, nodes) => {
    setExpanded(nodes);
  };

  const renderNodes = () => {
    return <TreeView
      className={treeClasses.root}
      defaultCollapseIcon={<ExpandMoreIcon/>}
      defaultExpandIcon={<ChevronRightIcon/>}
      expanded={expanded}
      onNodeToggle={handleChange}
    >
      {childNodes}
    </TreeView>;
  };

  const textField = useMemo(() => {
    const displayValue = selectedValue?.data?.localization[i18n.language] || selectedValue?.data?.localization['en'] || selectedValue?.data?.value || '';
    return <TextField
      label={''}
      placeholder={t('form_components.select.any_placeholder')}
      disabled={disabled}
      fullWidth
      value={displayValue}
      onChange={() => {
      }}
      ref={textFieldRef}
      className={cn({ [inputClasses.leftSideInput]: leftSideInput, [inputClasses.rightSideInput]: !leftSideInput })}
      InputProps={{
        endAdornment: selectedValue
                      ? <img
                        src={ClearIcon}
                        alt="clear"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleSelect(null);
                        }}
                      />
                      : <img
                        className={inputClasses.endIcon}
                        src={DropdownIndicatorIcon}
                      />,
      }}
      onClick={(event) => {
        if (!disabled) {
          setOpen(v => !v);
          setAnchorElement(anchorElement ? null : textFieldRef.current);
        }
      }}
    />;
  }, [anchorElement, selectedValue, disabled, open, setAnchorElement, setOpen, value]);

  useEffect(() => {
    if (!open) {
      setInputValue('');
    }
    setTimeout(() => {
      setClickAwayListenerActive(open);
    }, 100);
  }, [open]);

  useEffect(() => {
    if (!value) {
      setSelectedValue(null);
    }
  }, [value]);

  return (
    <>
      {textField}

      <ClickAwayListener onClickAway={() => {
        if (isClickAwayListenerActive) {
          setOpen(v => !v);
          setAnchorElement(null);
        }
      }}>
        <Styled.Popper
          open={open}
          style={{ width: 300 }}
          anchorEl={anchorElement}
          placement="bottom-start"
        >
          <Styled.ProjectSelectContainer
            ref={selectContentRef}
            visible={true}
            isSingleValueSelect={false}
          >
            <Styled.Input
              name="search"
              fullWidth
              value={inputValue}
              placeholder={t('form_components.select.search_placeholder')}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: <SearchIcon htmlColor="#000"/>,
              }}
            />
            <Styled.TreeViewParent visible={true}>
              <Styled.TreeViewContainer>
                {renderNodes()}
              </Styled.TreeViewContainer>
            </Styled.TreeViewParent>
          </Styled.ProjectSelectContainer>
        </Styled.Popper>
      </ClickAwayListener>
    </>);
};
