import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing, palette }) => ({
  cardHeader: {
    width: '100%',
    padding: spacing(3, 6, 1, 6),
    backgroundColor: palette.grey[100],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: spacing(2, 2, 0, 0),
  },
  cardHeaderInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: spacing(3),
    overflow: "hidden"
  },
  applicationNumberWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    marginBottom: 4,
    fontSize: 12,
    fontWeight: 400,
    color: palette.grey[500],
  },
  applicationNumber: {
    color: '#1B74B4',
    marginLeft: spacing(1),
  },
  copyLinkButton: {
    width: 13.3,
    marginLeft: spacing(1),
    marginRight: spacing(1),
    marginBottom: spacing(0.25),
  },
  cardTitle: {
    margin: 0,
    padding: 0,
    fontSize: 18,
    fontWeight: 500,
    color: '#333333',

    overflow: "hidden",
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  InitiatorAndStatusWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: spacing(6),
    paddingRight: spacing(6),
    marginTop: 12,
    marginBottom: 8,
  },
  remainingTimeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: spacing(6),
    paddingRight: spacing(6),
    marginTop: 8,
    marginBottom: 4,
  },
  clockIcon: {
    height: 16,
    marginRight: spacing(1.5)
  },
  clockOverdueIcon: {
    height: 10,
    width: 'fit-content',
    marginBottom: spacing(-1),
    marginRight: spacing(1)
  },
  clockTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    color: '#262842',
    marginRight: spacing(2)
  },
  clockTime: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: '#262842',
  },
  clockTimeOverdue: {
    color: '#D6331F',
  },
  clockHelp: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#6D6E85',
    marginLeft: spacing(2),
    paddingTop: spacing(0.5)
  },
}));
