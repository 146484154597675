import React, { ReactElement, createContext, useState } from 'react';
import { Box } from '@material-ui/core';

import { ContentLayout } from 'layouts';
import { ContentActions, NewButtonRequestHeader } from 'components';
import { RequestsRouter } from 'router';

import { useRequests } from './useRequests';

import { PageType } from 'types';

export const SearchValueContext = createContext<{
  value: string;
  setValue: (value: string) => void
}>({
  value: '',
  setValue: null
});


export const RequestsPage = (): ReactElement => {
  const {
    menuList,
    requestPath,
    isMassSelectAvailable,
    isFiltersAvailable,
  } = useRequests();

  const [searchValue, setSearchValue] = useState("");

  return (
    <ContentLayout
      headerChild={
        <Box display="flex" alignItems="center">
          <NewButtonRequestHeader />
        </Box>
      }
    >
      <SearchValueContext.Provider value={{
        value: searchValue,
        setValue: setSearchValue
      }}>

        <ContentActions
          menuList={menuList}
          basePageUrl={requestPath}
          type={PageType.REQUESTS}
          isFiltersAvailable={isFiltersAvailable}
          isMassSelectAvailable={isMassSelectAvailable}
        />

       <RequestsRouter />

      </SearchValueContext.Provider>
    </ContentLayout>
  );
};

export default RequestsPage;
