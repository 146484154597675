import { makeStyles } from '@material-ui/core';

type mainStylesProps = {
  isSideMenuOpen: boolean;
};

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    flex: 1,
    minHeight: '100vh !important',
    flexDirection: 'column',
  },
  headerWrapper: {
    '& > div': {
      maxHeight: '48px !important',
    },
  },
  content: {
    width: '100%',
    background: '#fff',
    padding: theme.spacing(0, 0, 0, 6),
    transitionProperty: 'width',
    transitionDuration: '700ms',
    flexGrow: 1,
  },
  noPaddingContent: {
    width: '100%',
    background: '#fff',
    transitionProperty: 'width',
    transitionDuration: '700ms',
    flexGrow: 1,
  },
  requestsContent: {
    width: '100%',
    background: '#fff',
    transitionProperty: 'width',
    transitionDuration: '700ms',
    flexGrow: 1,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  notificationContainer: {
    flex: 0,
    position: 'relative',
    height: '100%',
    width: '100%',
    pointerEvents: 'none',
    zIndex: 999999,

    '& > div': {
      position: 'fixed',
      top: '65px',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 999999,
      paddingRight: theme.spacing(3),

      '& .notification': {
        borderRadius: 6,
        padding: theme.spacing(3, 4),
        marginTop: theme.spacing(2),
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
        pointerEvents: 'all',

        '& .title': {
          margin: 0,
          marginBottom: theme.spacing(1),
        },

        '&.notification-success': {
          color: '#FFFFFF',
          background: '#137149',
          boxShadow: '0px 16px 32px rgba(0, 0, 0, 0.04)',
          borderRadius: 12,
        },

        '&.notification-error': {
          color: '#FFFFFF',
          backgroundColor: '#D6331F',
          boxShadow: '0px 16px 32px rgba(0, 0, 0, 0.04)',
          borderRadius: 12,
        },

        '&.notification-enter': {
          visibility: 'hidden',
          transform: 'translate3d(0, -100%, 0)',
          '&.notification-enter-active': {
            visibility: 'visible',
            transform: 'translate3d(0, 0, 0)',
            transition: 'all 0.4s',
          },
        },

        '&.notification-exit': {
          visibility: 'visible',
          transform: 'translate3d(0, 0, 0)',
          '&.notification-exit-active': {
            visibility: 'hidden',
            transform: 'translate3d(0, -100%, 0)',
            transition: 'all 0.4s',
          },
        },
      },
    },
  },
}));
