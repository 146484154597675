import moment from 'moment';
import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { Box, Button, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Close from '@material-ui/icons/Close';

import { DatePeriodPicker, GroupSelect, UserAvatar } from 'components';
import { useUsersState } from 'store/users';
import usebpmProcess from 'contexts/ProcessContext/useProcessContext';

import {AdminPanelRequestsFilterValues, RequestStatusesEnum} from '../../AdminPanel.types'
import useStyle from './Filter.useStyle';

type ModalFilterValues = Omit<AdminPanelRequestsFilterValues, 'searchText' | 'sort' | 'pagination'>;

type TypeFilterProps = {
  setValue?: (value: ModalFilterValues, closeFilterStatus?: boolean) => void;
  value: ModalFilterValues,
  close?: () => void;
}

type TypeOption = {
  label: string;
  value: string;
  icon?: React.ReactElement;
  selected?: boolean;
};

type RequestStatus = {
  label: string;
  icon: ReactElement;
  value: any
  stringValue: RequestStatusesEnum;
  defaultTranslation?: string;
}

const circleStyles = (backgroundColor: string) => ({
  backgroundColor,
  width: 10,
  height: 10,
  borderRadius: "50%",
})


export const statuses: {
  [key: string]: RequestStatus
}  = {
  InProgress: {
    label: "form_components.votingResults.inProgress",
    icon: <div style={circleStyles("#497CF6")}></div>,
    value: {
      completed: false,
      taskCompleted: false,
      archive: false,
      pending: true,
    },
    stringValue: RequestStatusesEnum.InProgress
  },
  Completed: {
    label: "task_statuses.completed",
    icon: <div style={circleStyles("#2DB77B")}></div>,
    value: {
      completed: true,
      taskCompleted: true,
      archive: false,
      pending: false,
    },
    stringValue: RequestStatusesEnum.Completed
  },
  Deleted: {
    label: "task_statuses.deleted",
    icon: <div style={circleStyles("#D6331F")}></div>,
    value: {
      archive: true,
      completed: undefined,
      taskCompleted: undefined,
      pending: undefined,
    },
    defaultTranslation: "Deleted",
    stringValue: RequestStatusesEnum.Deleted
  },
}

export const Filter = (props: TypeFilterProps) => {
  const { value, setValue, close } = props;
  const classes = useStyle();
  const { t } = useTranslation();

  const { groups } = usebpmProcess();
  const { users: usersList } = useUsersState();

  const [status, setStatus] = useState(value.status);
  const [assignee, setAssignee] = useState(value.assignee || []);
  const [initiator, setInitiator] = useState(value.initiator || []);
  const [creationDateFrom, setCreationDateFrom] = useState(value.fromDate ? new Date(value.fromDate) : null);
  const [creationDateTo, setCreationDateTo] = useState(value.tillDate ? new Date(value.tillDate) : null);
  const [creationDatePeriod, setCreationDatePeriod] = useState('All time');

  const [selectedGroups, setSelectedGroups] = useState<
    {
      [key: string]: TypeOption[]
    }
  >(null);

  const [selectedProcesses, setSelectedProcesses] = useState<
    {
      [key: string]: string[]
    }
  >(null);

  const handleProcessesChange = useCallback((values) => {
    const newGroups= values.reduce((acc, v) => {
      const groupSysName = (v.value).slice(0, v.value.indexOf('___'));
      const processSysName = (v.value).slice(v.value.indexOf('___') + 3, v.value.length);

      const group = Object.values(groups).find(g => g.sysName === groupSysName);

      if(!group) return acc

      const processes =  group.processes.filter(p => p.processSysName === processSysName) || []

      return {
        ...acc,
        [groupSysName]: [...(acc[groupSysName]) || [], ...processes]
      };
    }, {});


    setSelectedGroups(newGroups);

    const newProcesses = {...newGroups}

    for (const obj in newProcesses) {
      newProcesses[obj] = newProcesses[obj].map(p => p.processSysName)
    }

    setSelectedProcesses(newProcesses);
}, [groups])

  const handleGroupsChange = useCallback(async (values) => {
    const newGroups = values.reduce((acc, v) => {
      const group = groups.find(g => g.sysName === v.value)
      if(!group) return acc

      acc[group.sysName] = group.processes || []
      return acc
    }, {})

    setSelectedGroups(newGroups)

    const newProcesses = {...newGroups}

    for (const obj in newProcesses) {
      newProcesses[obj] = newProcesses[obj].map(p => p.processSysName)
    }

    setSelectedProcesses(newProcesses);
  }, [groups, selectedGroups])

  const handleClear = () => {
    setSelectedProcesses(null);
    setSelectedGroups(null);

    setStatus(RequestStatusesEnum.InProgress);
    setAssignee([]);
    setInitiator([]);
    setCreationDatePeriod('');
    setCreationDateFrom(null);
    setCreationDateTo(null);
  }

  const handleApply = () => {
    setValue({
      processes: JSON.stringify(selectedProcesses) === "{}" ? null : selectedGroups,
      status,
      initiator,
      assignee,
      fromDate: creationDateFrom ? moment(creationDateFrom).toISOString() : null,
      tillDate: creationDateFrom ? moment(creationDateTo).toISOString() : null,
    })
  }

  const optionsGroups: TypeOption[] = useMemo(() => {
    return groups.map(g => ({
      label: t(`groups.${g.sysName}.name`, { defaultValue: g.name }),
      value: g.sysName,
      icon: <img src={g.iconPath} alt={t(`groups.${g.sysName}.description`, { defaultValue: g.description })} />,
    }))
  }, [groups])

  const optionsProcesses: TypeOption[] = useMemo(() => {
    return groups.map(group => group.processes).flat().map(({ name, processIconPath, processDescription, groupSysName, processSysName }) => ({
      value: `${groupSysName}___${processSysName}`,
      label: t(`constructor-${processSysName}.name`, { defaultValue: name }),
      icon: <img src={processIconPath} alt={t(`constructor-${processSysName}.description`, { defaultValue: processDescription })} />,
    }))
  }, [groups]);

  const optionsStatuses: TypeOption[] = useMemo(() => {
    return Object.values(statuses).map(({label, icon, stringValue, defaultTranslation}) => ({
      label: t(label, {defaultValue: defaultTranslation}),
      icon,
      value: stringValue
    }))
  }, [statuses])

  const selectedOptionsProcesses: TypeOption[] = useMemo(() => {
    if (!selectedProcesses) return [];

    return Object.keys(selectedProcesses).reduce((acc, value) => {
      const processesValues = selectedProcesses[value].map(process => `${value}___${process}`);
      const processesObjects = processesValues.map(v => {
        const option = optionsProcesses.find(option => option.value === v);
        if(option){
          return {
            ...option,
            selected: true
          }
        }

      }).filter(Boolean);
      return [...acc, ...processesObjects];
    }, [])

  }, [selectedProcesses]);


  const selectedOptionsGroups: TypeOption[] = useMemo(() => {
    if (!selectedGroups) return [];

    return Object.keys(selectedGroups).reduce((acc, k) => {
      const group = groups.find(g => g.sysName === k)

      if(group){
        return [
          ...acc,
          {
            label: t(`groups.${group.sysName}.name`, { defaultValue: group.name }),
            value: group.sysName,
            icon: <img src={group.iconPath} alt={t(`groups.${group.sysName}.description`, { defaultValue: group.description })} />,
            selected: true
          }
        ]
      }

      return acc
    }, [])
  }, [selectedGroups]);

  const users = useMemo(() => {
    return Object.values(usersList).map(({ fullName, avatar, id }: any) => ({
      label: fullName,
      value: id,
      icon: <UserAvatar user={{
        avatar,
        id,
        fullName,
        firstName: '',
        fullNameRu: '',
        lastName: '',
        logName: '',
        phoneNumber: '',
        jobTitle: '',
        email: '',
        role: '',
      }}
        avatarSize={24}
      />
    }));
  }, [usersList]);

  return <div className={classes.root}>
    <header className={classes.header}>
      <h3>{t('filters.title')}</h3>
      {typeof close === 'function' && <ButtonBase onClick={() => close()}>
        <Close />
      </ButtonBase>}
    </header>

    <Box padding="0 16px" className={classes.content}>
      <GroupSelect
        value={selectedOptionsGroups}
        title={t('groupsSettings.deleteModal.fieldLabel')}
        onChange={handleGroupsChange}
        options={optionsGroups}
        multiple
        className={classes.select}
      />

      <GroupSelect
        value={selectedOptionsProcesses}
        title={t('Processes.module')}
        onChange={handleProcessesChange}
        options={optionsProcesses}
        multiple
        className={classes.select}
      />

      <GroupSelect
        value={status ? [status] : [RequestStatusesEnum.InProgress]}
        title={t('customProcesses.table.status')}
        onChange={(values) => {
          setStatus(values[0]?.value || RequestStatusesEnum.InProgress)
        }}
        options={optionsStatuses}
        className={classes.select}
      />

      <GroupSelect
        title={t('customProcesses.creationPage.processForm.initiator')}
        options={users}
        onChange={values => {
          setInitiator(values.map(v => v.value));
        }}
        className={classes.select}
        value={initiator}
        multiple
        isShownSearch
      />

      <GroupSelect
        title={t('filters.current_step_assignee')}
        options={users}
        onChange={values => {
          setAssignee(values.map(v => v.value));
        }}
        className={classes.select}
        value={assignee}
        multiple
        isShownSearch
      />



      <DatePeriodPicker
        className={classes.datePicker}
        label={t('customProcesses.table.creationDate')}
        dateFromValue={creationDateFrom}
        dateToValue={creationDateTo}
        onDateFromChange={setCreationDateFrom}
        onDateToChange={setCreationDateTo}
        selectedDatePeriod={creationDatePeriod}
        onSelectedDatePeriodChange={setCreationDatePeriod}
        hideCustomDaysTo={true}
        mainFiltersVariant
        preventInitialValuesSet
      />

    </Box>

    <footer className={classes.footer}>
      <Box display='flex' justifyContent='end' gridGap={8}>
        <Button
          color='secondary'
          onClick={handleClear}>{t('form_components.select.clear')}</Button>
        <Button
          onClick={handleApply}>{t('filters.filter_button_apply')}</Button>
      </Box>
    </footer>
  </div>
}

